import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Popconfirm, Popover } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import {
  DeleteOutlined, EditOutlined, PlusCircleOutlined, CheckOutlined,
  CheckCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons';

import { StorageState } from '@app/helpers';
import { ExportBottom } from '@common/exportPopconfirm';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { STORAGE_FORM } from '@common/sidebarRoot/types';
import { RIGHT_ROUTE_STORAGES, MODE_EDIT, TABLE_SIZE } from '@globalConstants';
import { withPagination, withRowSelection } from '@hoc';
import api from '@services/api';
import { Storage } from '@services/api-dts';
import { InterfaceRightsConstants } from '@state/rights/types';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { RootState } from '@state/store';
import { Layout, CustomCard } from '@ui';

import { SidebarData } from './types';

import styles from './styles.module.less';
import { Warnings } from '@app/helpers/helpers';

interface Props extends WithTranslation {
  selectedItem: Storage;
  onSelectItem: (item: Storage | null) => void;
  setTableName: (tableName: string, callback?: Function) => void;
  loadByPage: (
    tableName: string|null,
    apiFunctionName: string,
    mainParams: {},
    okStatuses: number[] | number,
    callbackOk?: ((status: number, data: { results: Storage[]; count: number }) => void) | null,
    callbackError?: (() => void) | null
  ) => void;
  isSidebarOpen: boolean;
  openStorageForm: (type: string, data: SidebarData) => void;
  closeStorageForm: () => void;
  page: number;
  limit: number;
  changePage: (page: number, callback?: () => void) => void;
  onShowSizeChange: (current: number, size: number, name?: string, callback?: Function) => void;
  rights: InterfaceRightsConstants;
  settings: any
}

interface State {
  loading: boolean;
  isDeleting: boolean;
  storages: Storage[];
  count: number;
  hovered: boolean[];
}

class Storages extends Component<Props, State> {
  tableName = 'storages';

  state = {
    loading: false,
    isDeleting: false,
    storages: [],
    count: 0,
    hovered: [false],
  };

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName, () => this.loadStorages());
  }

  loadStorages = async (addParams = {}, callback?: Function) => {
    const { loadByPage } = this.props;
    this.setState({ loading: true });

    await loadByPage(this.tableName, 'getStorages', addParams, 200, (status, data) => {
      const hovered = [];
      for (let i = 0; i < data.results.length; i += 1) {
        hovered[i] = false;
      }
      this.setState({
        storages: data.results,
        count: data.count,
        hovered,
      }, () => {
        callback && callback();
      });
    });

    this.setState({ loading: false });
  };

  deleteStorage = async () => {
    const { selectedItem, onSelectItem } = this.props;

    if (!selectedItem.id) {
      return;
    }

    this.setState({ isDeleting: true });

    const { status } = await api.deleteStorage(selectedItem.id);

    this.setState({ isDeleting: false });

    if (status === 204) {
      onSelectItem(null);
      this.loadStorages();
    }
  };

  storageFormCallback = async (data: Storage) => {
    const { closeStorageForm, changePage, onSelectItem } = this.props;

    changePage(1, async () => {
      await this.loadStorages({ selected: data.id }, () => {
        const { storages } = this.state;
        onSelectItem(storages[0]);
      });
    });
    closeStorageForm();
  };

  openSidebarForm = async (isEdit = false) => {
    const {
      isSidebarOpen, openStorageForm, closeStorageForm, selectedItem,
    } = this.props;

    if (isSidebarOpen) await closeStorageForm();

    if (isEdit) {
      openStorageForm(
        STORAGE_FORM,
        {
          storage: selectedItem,
          callback: (data: Storage) => {
            this.storageFormCallback(data);
          },
        }
      );
    } else {
      openStorageForm(
        STORAGE_FORM,
        {
          callback: (data: Storage) => {
            this.storageFormCallback(data);
          },
        }
      );
    }
  };

  onHoverChange = (visible: boolean, i: number) => {
    const { hovered } = this.state;
    const newHovered = hovered.fill(false);
    newHovered[i] = visible;
    this.setState({
      hovered: newHovered,
    });
  };

  render() {
    const {
      t, selectedItem, onSelectItem, changePage, onShowSizeChange, limit, page, rights, settings
    } = this.props;
    const {
      storages, loading, isDeleting, count,
    } = this.state;
    const columns = [
      {
        title: t('DC'),
        dataIndex: ['dispatcher_center', 'name'],
        key: 'dispatcher_center',
        width: '15%',
      },
      {
        title: t('LINK'),
        dataIndex: 'url',
        key: 'url',
        width: '15%',
      },
      {
        title: t('TYPE'),
        dataIndex: 'type',
        key: 'type',
        width: '15%',
        render: (text: string) => t(`LIBRARY_TYPE_${text.toUpperCase()}`),
      },
      {
        title: t('STATUS'),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (text: string) => {
          if (text === 'available') {
            return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
          }

          return <CloseCircleOutlined style={{ color: '#f5222d' }} />;
        },
      },
      {
        title: t('ACTIVITY'),
        dataIndex: 'is_active',
        key: 'is_active',
        width: '10%',
        render: (val: string) => val && <CheckOutlined />,
      },
      {
        title: t('STORAGE_INFO'),
        dataIndex: 'host_data',
        key: 'host_data',
        width: '10%',
        className: 'storage-state',
        render: (_: object[], record: any) => <StorageState record={record} type='progress' withInfo={false} />,
      },
      {
        title: t('STORAGE_STATE'),
        dataIndex: 'storage_info',
        key: 'storage_info',
        width: '10%',
        className: 'storage-state',
        render: (_: object[], record: any) => <StorageState record={record} type='progress' withInfo noProgress />,
      },
      {
        title: t('STORAGE_WARNINGS'),
        dataIndex: 'warnings',
        key: 'warnings',
        width: '10%',
        className: 'storage-state',
        render: (_: object[], record: any) => {
          return <Warnings record={record} settings={settings}/>
        },
      },
      {
        title: t('DESCRIPTION'),
        dataIndex: 'note',
        key: 'note',
        width: '25%',
        className: 'description',
        render: (val: string, record: object, i: number) => (
          <Popover
            overlayClassName={styles.description}
            content={val}
            trigger="hover"
            visible={this.state.hovered[i]}
            onVisibleChange={(visible: boolean) => this.onHoverChange(visible, i)}
            placement='bottom'
            key={`popover_${i}`}
          >
            <div>{val}</div>
          </Popover>
        ),
      },
    ];
    const isDeleteConfirmMsg = `${t('STORAGE_DELETE_CONFIRM')} ${selectedItem.url}?`;
    const isEditRight = rights[RIGHT_ROUTE_STORAGES] === MODE_EDIT;
    const rowSelection: TableRowSelection<object> = {
      type: 'radio',
      selectedRowKeys: selectedItem.id ? [selectedItem.id] : [''],
      onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0] as Storage),
    };

    return (
      <Layout
        title={t('STORAGES')}
      >
        <CustomCard
          className={styles.card}
          extra={(
            <>
              <Button
                onClick={() => this.openSidebarForm()}
                className='control-button'
                size='small'
                icon={<PlusCircleOutlined />}
                disabled={!isEditRight}
              >
                {t('ADD')}
              </Button>
              <Button
                disabled={!selectedItem.id || !isEditRight}
                onClick={() => this.openSidebarForm(true)}
                className='control-button'
                size='small'
                icon={<EditOutlined />}
              >
                {t('EDIT')}
              </Button>
              <Popconfirm
                title={isDeleteConfirmMsg}
                okText={t('DELETE')}
                cancelText={t('CANCEL')}
                onConfirm={this.deleteStorage}
                disabled={!selectedItem.id || !isEditRight}
              >
                <Button
                  disabled={!selectedItem.id || !isEditRight}
                  className='control-button'
                  size='small'
                  loading={isDeleting}
                  icon={<DeleteOutlined />}
                >
                  {t('DELETE')}
                </Button>
              </Popconfirm>
            </>
          )}
        >
          <FixedYCommonTable
            size={TABLE_SIZE}
            tableName={this.tableName}
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={storages}
            rowSelection={rowSelection}
            pagination={{
              onChange: (newPage: number) => changePage(newPage, this.loadStorages),
              total: count,
              pageSize: limit,
              current: page,
            }}
            onShowSizeChange={(current: number, pageSize: number) => onShowSizeChange(
              current,
              pageSize,
              this.tableName,
              this.loadStorages
            )}
          />
          <ExportBottom
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            icon='file-excel'
            count={count}
            WSData={{
              url: '/v1/storages/',
              accept: 'application/xlsx',
            }}
          />
        </CustomCard>
      </Layout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isSidebarOpen: state.sidebar.isOpen,
  rights: state.rights.rightsData,
  settings: state.settings.data,
});

const mapDispatchToProps = {
  openStorageForm: openSidebar,
  closeStorageForm: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withPagination(
      withRowSelection(
        Storages
      )
    )
  )
);
