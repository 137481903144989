const constants = {
  VIEW_SYSTEM: 'VIEW_SYSTEM',
  VIEW_FUNCTIONS: 'VIEW_FUNCTIONS',
  VIEW_SETS: 'VIEW_SETS',
  FUNCTIONS_FIELD_INDEX: 2,
  VALUE_FIELD_NAME: 'value',
  FUNCTION_FIELD_NAME: 'protection_device_function',
};

export default constants;
