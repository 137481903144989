import React, { memo, useMemo } from 'react';
import { Button, Select } from 'antd';
import { SortableFields, SortDirections } from './sorting';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import styles from './styles.module.less';
import { SortField } from './constants';
import { withTranslation, WithTranslation } from 'react-i18next';

const { Option } = Select;

interface Props extends WithTranslation {
  fieldOptions: SortField[];
  direction: SortDirections;
  fieldname: SortableFields;
  onFieldnameChanged: (value: SortableFields) => void;
  onDirectionChanged: (direction: SortDirections) => void;
}

const SortPanel = ({ fieldOptions: fieldConfig, direction, fieldname, onDirectionChanged, onFieldnameChanged, t }: Props) => {
  const fieldOptions = useMemo(
    () =>
      fieldConfig.map((field) => (
        <Option key={field.value} value={field.value}>
          {t(field.label)}
        </Option>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldConfig]
  );

  const handleDirectionButtonClick = () => {
    if (direction === 'asc') onDirectionChanged('desc');
    else onDirectionChanged('asc');
  };

  const handleFieldNameSelectChange = (value: SortableFields) => {
    onFieldnameChanged(value);
  };

  return (
    <div className={styles.sortpanel_wrapper}>
      <div className={styles.sortpanel_wrapper_leftside}>
        <Text className={styles.sortpanel_wrapper_leftside_text}>{t('LETTER_ATTACH_TASK_SORT_BY')}: </Text>
        <Select
          className={styles.sortpanel_wrapper_select}
          size='small'
          onChange={handleFieldNameSelectChange}
          value={fieldname}
        >
          {fieldOptions}
        </Select>
      </div>
      <Button
        size='small'
        icon={direction === 'asc' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        onClick={handleDirectionButtonClick}
      />
    </div>
  );
};

export default withTranslation()(memo(SortPanel));
