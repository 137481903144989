import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import api from '@services/api';
import { MODE_EDIT, RIGHT_PASSPORT_SETPOINTS } from '@globalConstants';
import Nonperformance from '@common/nonperformance';
import SetpointsTree from './setpointsTree';

import Blanks from './blanks';
import componentConstants from './constants';

import './styles.less';

const {
  VIEW_SYSTEM, VIEW_FUNCTIONS
} = componentConstants;

class Setpoints extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: 'none',
      exportGroupByFunction: false,
      selectedBlank: null,
      compareMode: false,
      compareIds: [],
      compareBlanks: {},
      isBlocked: false,
      disabledChangeBlank: false,
      groupsIsChanged: false,
      staticRec: true
    };
  }

  async componentDidMount() {
    const { storeActiveBlank } = this.props;

    if (storeActiveBlank) {
      await this.loadSetpointsData(storeActiveBlank);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { passportId } = this.props;
    const {
      currentView, blankId, selectedBlank,
    } = this.state;

    if (prevProps.passportId !== passportId) {
      this.setState({
        blankId: undefined,
        selectedBlank: null,
      });
    }

    if (
      currentView !== prevState.currentView
      || selectedBlank !== prevState.selectedBlank
      || blankId !== prevState.blankId
    ) {
      if (!selectedBlank) {
        return;
      }

      switch (currentView) {
        case VIEW_SYSTEM:
          await this.loadSetpointsData(selectedBlank);
          break;
        default:
          break;
      }
    }
  }

  onSaveValues = async (values, onSavedAllItems) => {
    const res = await api.patchAllChangedSetpoints(values, values[0].calc_view_mode, values[0].static_rec);

    this.onModified(0);
    onSavedAllItems && await onSavedAllItems(res);
  };

  onChangeCompareMode = async (compareMode, compareIds) => {
    this.setState({ compareMode, compareIds });
    let { compareBlanks } = this.state;
    const { deviceId } = this.props;
    for (let i = 0, l = compareIds.length; i < l; i += 1) {
      const id = compareIds[i];
      if (!(id in compareBlanks)) {
        const blankRes = await api.getPassportBlankById(deviceId, id);
        if (blankRes.status === 200) {
          compareBlanks[id] = { name: blankRes.data.name, blank: blankRes.data };
        }
      }
      compareBlanks = { ...compareBlanks };
    }

    this.setState({ compareBlanks });
  };

  onSPTBlock = () => {
    this.setState({ isBlocked: true });
  };

  onSPTUnBlock = () => {
    this.setState({ isBlocked: false });
  };

  onModified = (count) => {
    const { disabledChangeBlank } = this.state;
    const newValue = (count !== 0);
    if (newValue !== disabledChangeBlank) {
      this.setState({ disabledChangeBlank: newValue });
    }
  };

  async loadSetpointsData(blank) {
    const blankId = blank && blank.id;

    this.setState({
      selectedBlank: blank,
      blankId,
      selectedRowKey: blankId,
    });
  }

  onSetpointsViewChanged = (value) => {
    const groupByFunction = value === VIEW_FUNCTIONS ? true : false;
    this.setState({
      exportGroupByFunction: groupByFunction
    })
  }

  handleParentStateChange = () => {
    const { staticRec } = this.state;
    this.setState({ staticRec: !staticRec });
  };

  render() {
    const {
      rights, t, getPassportData, passportId, deviceId, isArchived,
    } = this.props;
    const {
      selectedRowKey,
      selectedBlank,
      blankId,
      compareMode,
      compareIds,
      isBlocked,
      compareBlanks,
      disabledChangeBlank,
      groupsIsChanged,
      exportGroupByFunction,
      staticRec
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_SETPOINTS] === MODE_EDIT && !isArchived;

    const emptyProps = {};

    if (!selectedRowKey) {
      emptyProps.description = t('PASSPORT_SETPOINTS_BLANK_NOT_SELECTED');
    }

    const titleExtra = `(${selectedBlank && selectedBlank.id
      ? `${t('COLLAPSABLE_BLANK')} "${selectedBlank.name}"`
      : t('PASSPORT_SETPOINTS_BLANK_NOT_SELECTED')})`;

    return (
      <div className='passport-setpoints'>
        <Blanks
          isDefaultOpenPanel={true}
          enableCollapsableHasNoData={true}
          isArchived={isArchived}
          getPassportData={getPassportData}
          isBlocked={isBlocked}
          deviceId={deviceId}
          disabledChangeBlank={disabledChangeBlank}
          passportId={passportId}
          loadSetpointsData={(blank) => this.loadSetpointsData(blank)}
          onChangeCompareMode={this.onChangeCompareMode}
          onChangeGroupStart={() => this.setState({ isBlocked: true })}
          onChangeGroup={() => this.setState({ isBlocked: false, groupsIsChanged: true })}
          onBlock={this.onSPTBlock}
          onUnBlock={this.onSPTUnBlock}
          exportGroupByFunction={exportGroupByFunction}
          mode='setpoints'
          staticRec={staticRec}
        />

        <SetpointsTree
          title={t('PASSPORT_SETPOINTS_TITLE_SETPOINTS')}
          titleExtra={titleExtra}
          passportId={passportId}
          taskId={blankId}
          blank={selectedBlank}
          onSaveValues={this.onSaveValues}
          onBlock={this.onSPTBlock}
          onUnBlock={this.onSPTUnBlock}
          onModified={this.onModified}
          compareMode={compareMode}
          compareIds={compareIds}
          compareBlanks={compareBlanks}
          deviceId={deviceId}
          isEditRight={isEditRight}
          onViewChanged={this.onSetpointsViewChanged}
          isEditBlocked={
            selectedBlank
            && (
              selectedBlank.status !== 'in_work'
              || selectedBlank.is_doc === true
            )
          }
          groupsIsChanged={groupsIsChanged}
          onChangeGroupEnd={() => this.setState({ groupsIsChanged: false })}
          mode='setpoints'
          staticRec={staticRec}
          setStaticRec={this.handleParentStateChange}
        />
        <Nonperformance
          titleExtra={titleExtra}
          isArchived={isArchived}
          blank={selectedBlank}
          passportId={passportId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  storeActiveBlank: state.blanks.activeBlank,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Setpoints
  )
);
