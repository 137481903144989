import React, { memo } from 'react';
import { LetterTasksDispatcherCenterItem } from '@services/api-dts';
import { Space } from 'antd';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface Props extends LetterTasksDispatcherCenterItem, WithTranslation {}

const TaskRow = ({ name, status, date, t }: Props) => {
  return (
    <Space direction='vertical' size={1}>
      <Text>{name}</Text>
      <Text type='secondary'>
        {t('LETTER_ATTACH_TASK_ROW_STATUS')}: {status?.current_status.name || '---'}
      </Text>
      <Text type='secondary'>
        {t('LETTER_ATTACH_TASK_ROW_DATE_START')}:{' '}
        {date ? moment(date).format('DD.MM.YYYY') : '---'}
      </Text>
    </Space>
  );
};

export default withTranslation()(memo(TaskRow));
