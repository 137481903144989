import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Button, Popover } from 'antd';
import { FileDoneOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import Qs from 'qs';

import api from '@services/api';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { CREATE_COMPLEX, LIST_SETPOINT_MAP, CREATE_SETPOINT_MAP } from '@common/sidebarRoot/types';
import CommonTable from '@common/CommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { textHighlighter } from '@globalHelpers';

import { RIGHT_PASSPORT_SETPOINT_MAP, MODE_EDIT, TABLE_SIZE } from '@globalConstants';
import { CustomEmpty, CustomCard } from '@ui';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import styles from './styles.module.less';

class SetpointMap extends React.Component {
  tableName = 'passport_tab_setpointMap';

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      complexesForDevice: [],
      isComplexLoading: false,
      isBlankLoading: false,
      count: 0,
    };
  }

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName, () => this.loadData());
  }

  columns = () => {
    const { t, getColumnSearchFilter, filterValues } = this.props;

    return [
      {
        title: t('PASSPORT_SETPOINT_MAP_NAME'),
        dataIndex: 'name',
        width: '100%',
        ...getColumnSearchFilter('name', 'name__contains', null, this.handleResetCallback),
        render: (text) => {
          return text ? textHighlighter(filterValues['name__contains'], text) : t('WITHOUT_NAME');
        },
      },
    ];
  };

  loadData = async (addParams = {}, callback) => {
    const {
      deviceId, loadByPage, filterValues,
    } = this.props;

    const requestParams = { protection_device: deviceId, ...filterValues, ...addParams };

    this.setState({ isLoading: true });
    await loadByPage(this.tableName, 'getDevicesComplexes', requestParams, 200, (status, data) => {
      this.setState({
        complexesForDevice: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isLoading: false });
  };

  complexClick = async () => {
    const {
      t, passportId, selectedItem, socket, isSocketConnected,
    } = this.props;

    if (socket && isSocketConnected) {
      const params = {
        complex_id: selectedItem.id,
      };

      const request = `/v1/passport/${passportId}/setpoint-map?${Qs.stringify(params, { arrayFormat: 'repeat' })}`;

      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('PASSPORT_SETPOINT_MAP_COMPLEX_EXPORT')} "${selectedItem.name}"`,
        url: request,
        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }));
    }
  };

  deleteComplex = async () => {
    const { deviceId, onSelectItem, selectedItem } = this.props;
    const { complexesForDevice } = this.state;

    const newComplexes = complexesForDevice
      .filter((x) => !(selectedItem.id === x.id))
      .map((x) => x.id);
    this.setState({ isLoading: true });
    onSelectItem({});
    await api.patchProtectionDevice(deviceId, { id: deviceId, complexes: newComplexes });
    this.loadData();
  };

  openSetpointSidebar = async () => {
    const {
      deviceId, openSB, changePage, onSelectItem, closeSB,
    } = this.props;

    this.setState({ isLoading: true });
    const { status, data } = await api.getDevicesComplexes();
    this.setState({ isLoading: false });

    if (status === 200) {
      openSB(
        CREATE_COMPLEX,
        {
          complexes: data.results,
          deviceId: deviceId,
          onOk: async (newComplex) => {
            changePage(1, async () => {
              await this.loadData({ selected: newComplex.id }, () => {
                const { complexesForDevice } = this.state;
                onSelectItem(complexesForDevice[0], () => closeSB());
              });
            });
          },
        }
      );
    }
  };

  openSPMapForm = async () => {
    const { selectedItem, openSB, passportId } = this.props;

    openSB(
      CREATE_SETPOINT_MAP,
      {
        complexId: selectedItem.id,
        complexName: selectedItem.name,
        passportId,
      }
    );
  };

  openSPMapList = async () => {
    const { selectedItem, openSB } = this.props;

    openSB(
      LIST_SETPOINT_MAP,
      {
        complexId: selectedItem && selectedItem.id,
        complexName: selectedItem && selectedItem.name,
      }
    );
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      t, rights, deviceId, changePage, onShowSizeChange, limit, page,
      onSelectItem, selectedItem, isArchived,
    } = this.props;
    const {
      isLoading, complexesForDevice, isComplexLoading, isBlankLoading, count,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_SETPOINT_MAP] === MODE_EDIT && !isArchived;

    return (
      <div className={styles.setpointMap}>
        <CustomCard
          style={{ margin: '5px' }}
          title={t('COL_DEVICE_COMPLEXES')}
          extra={(
            <div>
              <Popover
                title={t('SPMAP_VIEW')}
                trigger='focus'
                disabled={!selectedItem.id || !isEditRight}
                content={(
                  <>
                    <Button
                      size='small'
                      icon={<FileDoneOutlined />}
                      disabled={!selectedItem.id}
                      key='1'
                      onClick={() => this.complexClick()}
                      loading={isComplexLoading}
                    >
                      {t('SPMAP_TABLE')}
                    </Button>
                    &nbsp;
                    <Button
                      size='small'
                      key='6'
                      onClick={this.openSPMapList}
                      disabled={!selectedItem.id || !isEditRight}
                    >
                      {t('SPMAP_GRAPH')}
                    </Button>
                  </>
                )}
              >
                <Button
                  size='small'
                  disabled={(complexesForDevice.length === 0) || !selectedItem.id}
                  icon={<FileDoneOutlined />}
                >
                  {t('DOWNLOAD')}
                </Button>
              </Popover>
              <Button
                size='small'
                icon={<PlusCircleOutlined />}
                key='3'
                onClick={() => this.openSetpointSidebar()}
                disabled={!isEditRight}
              >
                {t('PASSPORT_SETPOINT_MAP_ADD_COMPLEX')}
              </Button>
              <Button
                size='small'
                onClick={this.openSPMapForm}
                key='4'
                disabled={!selectedItem.id || !isEditRight}
              >
                {t('SETPOINT_CARD_ADD')}
              </Button>
              <Button
                size='small'
                icon={<DeleteOutlined />}
                disabled={!selectedItem.id || !isEditRight}
                key='5'
                onClick={() => this.deleteComplex()}
              >
                {t('PASSPORT_SETPOINT_MAP_DELETE_COMPLEX')}
              </Button>
            </div>
          )}
        >
          <CommonTable
            tableName={this.tableName}
            size={TABLE_SIZE}
            columns={this.columns()}
            dataSource={complexesForDevice}
            loading={isLoading || isComplexLoading || isBlankLoading}
            pagination={{
              onChange: (newPage) => changePage(newPage, this.loadData),
              total: count,
              pageSize: limit,
              current: page,
            }}
            rowSelection={{
              selectedRowKeys: [selectedItem.id],
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0]),
            }}
            onShowSizeChange={
              (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadData)
            }
            locale={{ emptyText: <CustomEmpty description={t('PASSPORT_SETPOINT_MAP_EMPTY_TEXT')} /> }}
          />
          <ExportBottom
            count={count}
            size='small'
            WSData={{
              url: '/v1/passport-protection-device-complexes/',
              params: { protection_device: deviceId },
              accept: 'application/xlsx',
            }}
          />
        </CustomCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  sidebarType: state.sidebar.element_type,
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});

const mapDispatchToProps = {
  openSB: openSidebar,
  closeSB: closeSidebar,
};
const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      SetpointMap
    )
  )
);
