import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Layout } from '@ui';

import Settings from './Settings';

class SettingsRoute extends PureComponent {
  render() {
    const { t } = this.props;

    return (
      <Layout
        title={t('SYSTEM_SETTINGS')}
      >
        <Settings />
      </Layout>
    );
  }
}

export default withTranslation()(
  SettingsRoute
);
