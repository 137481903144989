import React from 'react';
import { withTranslation } from 'react-i18next';

import store from '@state/store';
import { closeSidebar } from '@state/sidebar/actions';
import { TAB_ACTIONS, TAB_MESSAGES } from '@common/journal/constants';
import Journal from '@common/journal';
import { Layout } from '@ui';

class JournalRoute extends React.Component {
  componentDidMount() {
    store.dispatch(closeSidebar());
  }

  render() {
    const { t } = this.props;

    return (
      <Layout
        title={t('JOURNAL_JOURNAL_TITLE')}
        tabs={[
          { id: TAB_ACTIONS, name: t('JOURNAL_ACTIONS_TITLE') },
          { id: TAB_MESSAGES, name: t('JOURNAL_MESSAGES_TITLE') },
        ]}
      >
        <Journal />
      </Layout>
    );
  }
}

export default withTranslation()(
  JournalRoute
);
