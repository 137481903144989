import React from 'react';
import { DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons'

export const deleteButtonTemplate = {
    "delete": {
      icon: <DeleteOutlined />,
      caption: 'PASSPORT_DOCS_TABLE_DELETE',
      confirmCaption: 'PASSPORT_DOCS_TABLE_DELETE_CONFIRM'
     },
     "deattach": {
      icon: <MinusCircleOutlined />,
      caption: 'PASSPORT_DOCS_TABLE_DEATTACH',
      confirmCaption: 'PASSPORT_DOCS_TABLE_DEATTACH_CONFIRM'
     },
  }
  