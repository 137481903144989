import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Button, Form, Input, message,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import api from '@services/api';
import { SidebarLayout } from '@ui';
import { RuleObject } from 'rc-field-form/lib/interface'

const { TextArea } = Input;

type TTerms = {
  id: string,
  name: string,
};

type TChangeValue = {
  name: string,
}

interface Props extends WithTranslation {
  data: {
    terms: TTerms[];
    isEdit: boolean | undefined;
    title: string | false;
    selectedParamId: string | false;
    callback: Function;
    selectedTerm: {
      id: string;
      name: string;
    };
    blank: null | {
      id: string;
      name: string;
    };
  };
  width?: number;
}

class NonperformanceTermsSidebar extends Component<Props> {
  form = React.createRef<FormInstance>();

  state = {
    isSaving: false,
    isDuplicated: true,
  };

  componentDidMount() {
    const { data } = this.props;

    if (data.isEdit) {
      this.form.current && this.form.current.setFieldsValue({
        name: data.selectedTerm.name,
      });
    }
  }

  saveTerm = () => {
    const { t, data } = this.props;

    this.form.current && this.form.current.validateFields().then(async (values) => {
      let result;

      this.setState({ isSaving: true });

      if (data.isEdit) {
        result = await api.editNonperformanceTerm(
          data.selectedTerm.id,
          {
            name: values.name,
            parameter: data.selectedParamId,
            blank: data.blank && data.blank.id,
          }
        );
      } else {
        result = await api.addNonperformanceTerm(
          {
            name: values.name,
            parameter: data.selectedParamId,
            blank: data.blank && data.blank.id,
          }
        );
      }

      this.setState({ isSaving: false });

      if (result && result.status && [200, 201].includes(result.status)) {
        data.isEdit ? message.success(t('EDIT_SUCCESS')) : message.success(t('CREATE_SUCCESS'));
        data.callback && data.callback(result.data);
      } else {
        data.isEdit ? message.error(t('EDIT_ERROR')) : message.error(t('CREATE_ERROR'));
      }
    });
  };

  onFormChanged = (changeValues: TChangeValue) => {
    const { data } = this.props;

    const duplicationCheck =
      data.terms.filter(
        (item) =>
          item.name.toLowerCase().split(' ').join('') ===
          changeValues.name.toLowerCase().split(' ').join('')
      ).length > 0;

    if (this.state.isDuplicated !== duplicationCheck)
      this.setState({ isDuplicated: duplicationCheck });

    if (changeValues.name.split(' ').join('').length === 0) this.setState({ isDuplicated: true });
  };

  deduplicationValidator = async (_: RuleObject, value: string) => {
    const { t, data } = this.props;

    if (
      data.terms.filter(
        (item) =>
          item.name.toLowerCase().split(' ').join('') === value?.toLowerCase().split(' ').join('')
      ).length > 0
    ) {
      throw new Error(t('WARN_SAME_TREE_ITEM'));
    }
  };

  render() {
    const { data, t, width } = this.props;
    const { isSaving, isDuplicated } = this.state;

    return (
      <SidebarLayout
        withoutCard
        sidebarStyle={{ width: `${width ?? 350}px` }}
        header={
          <h4>{`${t(data.isEdit ? 'EDIT' : 'ADD')} ${data.title || t('CONDITION')}`}</h4>
        }
        footer={
          <Button
            block
            type="primary"
            onClick={this.saveTerm}
            loading={isSaving}
            disabled={isDuplicated}
          >
            {t(data.isEdit ? 'EDIT' : 'ADD')}
          </Button>
        }
      >
        <Form
          name='docClassificatorsForm'
          ref={this.form}
          onValuesChange={this.onFormChanged}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('ENTER_NAME'),
              },
              () => ({ validator: this.deduplicationValidator }),
            ]}
          >
            <TextArea
              style={{ height: 120 }}
              maxLength={1000}
              placeholder={t('ENTER_NAME')}
            />
          </Form.Item>
        </Form>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  NonperformanceTermsSidebar
);
