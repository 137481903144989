import i18n from 'i18next';

export const isInteger = (required = false) => ({
  validator(rule, value) {
    if ((!required && value) || required) {
      return (value % 1) === 0 ? Promise.resolve() : Promise.reject(new Error(i18n.t('ERROR_WHOLE_NUMBER')));
    } else {
      return Promise.resolve();
    }
  },
});

export const moreOrEqual = (num, required = false) => ({
  validator(rule, value) {
    if ((!required && value) || required) {
      return (value >= num) ? Promise.resolve() : Promise.reject(new Error(`${i18n.t('ERROR_MIN_VALUE')} ${num}`));
    } else {
      return Promise.resolve();
    }
  },
});

export const lessOrEqual = (num, required = false) => ({
  validator(rule, value) {
    if ((!required && value) || required) {
      return (value <= num) ? Promise.resolve() : Promise.reject(new Error(`${i18n.t('ERROR_MAX_VALUE')} ${num}`));
    } else {
      return Promise.resolve();
    }
  },
});
