import { CloseOutlined, NotificationOutlined } from '@ant-design/icons';
import api from '@services/api';
import { Maintenance, MaintenanceEdit } from '@services/api-dts';
import { Button, Input, message, Modal, Spin } from 'antd';
import React, { FC, memo, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './styles.module.less';

export interface IMaintenanceModal extends WithTranslation {
  visible: boolean;
  maintenance?: Maintenance;
  cancelCallback: () => void;
  submitCallback: (result: Maintenance) => void;
}

const { TextArea } = Input;

const MaintenanceModalForm: FC<IMaintenanceModal> = ({ visible, maintenance, cancelCallback, submitCallback, t }) => {
  const [content, setContent] = useState(maintenance?.content || '');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setContent(maintenance?.content || '');
  }, [maintenance]);

  const onCancel = () => {
    cancelCallback();
  };

  const onSubmit = async (published = false) => {
    setIsLoading(true);
    let newMaintenance: MaintenanceEdit = {
      is_published: published || false,
      content: content || '',
    };
    const { status, data } = await api.editMaintenance(newMaintenance);
    if (status === 200) {
      submitCallback(data);
      message.success(t('MAINTENANCE_SUCCESS_MESSAGE'));
    } else {
      message.error(t('MAINTENANCE_ERROR_MESSAGE'));
    }
    setIsLoading(false);
  };

  const onSubmitPublish = () => {
    if (maintenance) onSubmit(!maintenance.is_published);
    else onSubmit(true);
  };

  return (
    <Modal
      onCancel={onCancel}
      title={t('MAINTENANCE_MODAL_CAPTION')}
      visible={visible}
      footer={
        <div className={styles['modal-footer-buttons']}>
          <Button onClick={onCancel}>Отмена</Button>
          <Button
            type='primary'
            onClick={onSubmitPublish}
            icon={maintenance?.is_published ? <CloseOutlined /> : <NotificationOutlined />}
          >
            {maintenance?.is_published ? t('MAINTENANCE_BUTTON_UNPUBLISH') : t('MAINTENANCE_BUTTON_PUBLISH')}
          </Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <TextArea
          rows={6}
          onChange={(e) => setContent(e.target.value)}
          value={content}
          className={styles['form-input']}
          disabled={maintenance?.is_published}
        />
      </Spin>
    </Modal>
  );
};

export default withTranslation()(memo(MaintenanceModalForm));
