import React, { Component, Key } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  Layout,
  CustomCard,
} from '@ui';

import { EventModuleSerializer } from '@services/api-dts';

import Actions from './Actions';
import EventsTable from './Table';

import styles from './styles.module.less';

interface Props extends WithTranslation, RouteComponentProps {
  passportMode?: boolean 
 }

interface State {
  oSelectedEvent: EventModuleSerializer | boolean;
  selectedEvent: string | boolean;
}

interface WithLoadEvents {
  loadEvents: (event?: {[key: string]: string}) => EventModuleSerializer|boolean;
  clearFilter: (callback?: Function) => void;
}

interface TableRef extends Component, WithLoadEvents, WithTranslation { }

class EventsRoute extends Component<Props, State> {
  private componentRef: TableRef|{}|undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedEvent: '',
      oSelectedEvent: false,
    };
  }

  onSelectedEventChange = (selectedEvents: Key[], selectedEventRecords: object[]) => {
    this.setState({
      selectedEvent: typeof selectedEvents[0] === 'number'
        ? selectedEvents[0].toString()
        : (selectedEvents[0] === ''
          ? false
          : selectedEvents[0]),
      oSelectedEvent: selectedEventRecords[0] as EventModuleSerializer,
    });
  };

  setComponentRef = (ref?: any) => {
    this.componentRef = ref;
  };

  loadEvents = async (eventId: string) => {
    let event: boolean | EventModuleSerializer = false;

    if (this.componentRef
      && 'loadEvents' in this.componentRef
      && this.componentRef.loadEvents !== undefined
    ) {
      if (eventId) {
        event = await this.componentRef.loadEvents({ selected: eventId });
        this.setState({
          selectedEvent: eventId,
          oSelectedEvent: event,
        });
      } else {
        await this.componentRef.loadEvents();
        this.setState({
          selectedEvent: false,
          oSelectedEvent: false,
        });
      }
    }

    return event;
  };

  clearFilter = (callback?: Function) => {
    if (this.componentRef
      && 'clearFilter' in this.componentRef
      && this.componentRef.clearFilter !== undefined
    ) {
      this.componentRef.clearFilter(callback);
    }
  };

  render() {
    const { history, t, passportMode = false } = this.props;
    const {
      selectedEvent, oSelectedEvent,
    } = this.state;

    const tableProps = {
      setComponentRef: this.setComponentRef,
      t,
      onSelectedEventChange: this.onSelectedEventChange,
      selectedEvent: selectedEvent,
      history,
      passportMode
    };

    return (
      <Layout
        title={t('EVENTS_TITLE')}
      >
        <CustomCard
          className={styles.eventsList}
          noBottomMargin
          extra={(
            <Actions
              selectedEvent={selectedEvent}
              oSelectedEvent={oSelectedEvent}
              loadEvents={this.loadEvents}
              clearFilter={this.clearFilter}
            />
          )}
        >
          <EventsTable
            {...tableProps}
          />
        </CustomCard>
      </Layout>
    );
  }
}

export default withTranslation()(
  withRouter(
    EventsRoute
  )
);
