import React, { Component } from 'react';
import { Input } from 'antd';
import styles from './styles.module.less';

class FilterString extends Component {
  onValueChange(value) {
    const { onChange } = this.props;

    onChange(value);
  }

  render() {
    const { keyName, values } = this.props;

    return (
      <Input
        className={styles.string}
        key={keyName}
        onChange={(e) => this.onValueChange(e.target.value)}
        value={values}
      />
    );
  }
}

export default FilterString;
