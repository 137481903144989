import React from 'react';
import { Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

const ColumnRangeSearch = (props) => {
  const { t } = useTranslation();
  const {
    setRef, selectedKeys, setSelectedKeys, handleSearchRange, handleReset,
  } = props;

  return (
    <div style={{ padding: 8 }}>
      <div style={{ marginBottom: 8 }}>
        <RangePicker
          ref={setRef}
          onChange={(values) => setSelectedKeys(values)}
          value={selectedKeys}
          format='DD.MM.YYYY'
        />
      </div>
      <Button
        type='primary'
        icon={<SearchOutlined />}
        onClick={handleSearchRange}
        size='small'
        style={{ width: 90, marginRight: 8 }}
      >
        {t('TO_SEARCH')}
      </Button>
      <Button
        onClick={handleReset}
        size='small'
        style={{ width: 90 }}
      >
        {t('CANCEL')}
      </Button>
    </div>
  );
};

export default ColumnRangeSearch;
