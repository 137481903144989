import { ClearOutlined } from '@ant-design/icons';
import { OneNews } from '@services/api-dts';
import { Button, Input, Modal, Spin } from 'antd';
import React, { FC, memo, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { EDITOR_BUTTONS } from './constants';
import styles from './styles.module.less';

export type newsFormModalTypes = 'create' | 'edit';

export type NewsSubmitType = {
  content: string;
  release_version: string;
};

//Фикс для редактора - если все стереть, контент не будет на самом деле пустым
const isEditorValueReallyEmpty = (value: string) => {
  if (!value || value === '' || value === '<p><br></p>') return true;
  else return false;
};

export interface INewsFormModal extends WithTranslation {
  visible: boolean;
  type: newsFormModalTypes;
  initial?: OneNews;
  isLoading: boolean;
  cancelCallback: () => void;
  submitCallback: (record: NewsSubmitType) => void;
}

const titleCaptionMap = {
  create: 'Создать новость',
  edit: 'Редактировать новость'
}

const NewsFormModal: FC<INewsFormModal> = memo(
  ({ visible, type, initial, cancelCallback, submitCallback, isLoading = false, t }) => {
    const [release_version, setVersion] = useState<string>(initial?.release_version || '');
    const [content, setContent] = useState<string>(initial?.content || '');

    useEffect(() => {
      setVersion(initial?.release_version || '');
      setContent(initial?.content || '');
    }, [initial, visible]);

    const clear = () => {
      setVersion('');
      setContent('');
    };

    const onClear = () => {
      clear();
    };

    const onCancel = () => {
      clear();
      cancelCallback();
    };

    const onSubmit = () => {
      submitCallback({
        content: content,
        release_version: release_version,
      });
      clear();
    };

    const onEditorChange = (value: string) => {
      setContent(value);
    };

    const isSubmitButtonEnabled = release_version && !isEditorValueReallyEmpty(content);

    return (
      <Modal
        visible={visible}
        width={'1000px'}
        bodyStyle={{ height: '600px' }}
        title={titleCaptionMap[type]}
        onCancel={onCancel}
        footer={
          <div className={styles['news-form-footer-buttons']}>
            <div className={styles['left-buttons']}>
              <Button icon={<ClearOutlined />} onClick={onClear} loading={isLoading}>
                {t('NEWS_CLEAR')}
              </Button>
            </div>
            <div className={styles['right-buttons']}>
              <Button onClick={onCancel} loading={isLoading}>
                {t('NEWS_CANCEL')}
              </Button>
              <Button type='primary' loading={isLoading} disabled={!isSubmitButtonEnabled} onClick={onSubmit}>
                {t('NEWS_SUBMIT')}
              </Button>
            </div>
          </div>
        }
        centered
      >
        <Spin spinning={isLoading}>
          <div className={styles['news-form-modal']}>
            <div className={styles['news-form-inputs']}>
              <Input
                value={release_version}
                placeholder={t('NEWS_VERSION_INPUT_PLACEHOLDER')}
                onChange={(e) => setVersion(e.target.value)}
              />
            </div>
            <SunEditor
              setOptions={{
                imageUrlInput: true,
                imageFileInput: true,
                imageRotation: true,
                height: '440px',
                resizingBar: false,
                buttonList: [EDITOR_BUTTONS],
                placeholder: t('NEWS_CONTENT_EDITOR_PLACEHOLDER')
              }}
              lang='ru'
              setContents={content}
              onChange={onEditorChange}
            />
          </div>
        </Spin>
      </Modal>
    );
  }
);

export default withTranslation()(NewsFormModal);
