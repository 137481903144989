import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';

import DocumentsUpload from '@common/documentsUploadSidebar';
import NonperformanceTermsSidebar from '@common/nonperformance/nonperformanceTermsSidebar';
import PassportDetails from '@common/PassportDetailsSidebar';
import EquipmentTypesFormSidebar from '@routes/classificators/equipment_types/equipmentTypesFormSidebar';
import FilterSidebar from '@routes/globalSearch/filterSidebar';
import CreateBlankSidebar from '@routes/passport/tabs/setpoints/createBlankSidebar';
import UpdateBlankSidebar from '@routes/passport/tabs/setpoints/updateBlankSidebar';
import ImportBlankSidebar from '@routes/passport/tabs/setpoints/importBlankSidebar';
import CreateTaskSidebar from '@routes/passport/tabs/tasks/createTaskSidebar';
import SetpointsInfoSidebar from '@routes/passport/tabs/setpoints/setpointsInfo';
import SetpointMapSidebar from '@routes/passport/tabs/setpointMap/setpointMapSidebar';
import DocumentsBind from '@routes/passport/tabs/tasks/documents/documentsBindSidebar';
import ImportTemplateSidebar from '@routes/templates/importTemplateSidebar';
import ComplexesFormSidebar from '@routes/classificators/complexes/complexesFormSidebar';
import CommonClassificatorsFormSidebar from '@routes/classificators/commonClassificatorsFormSidebar';
import PassportFunctionsSidebar from '@routes/passport/tabs/functions/passportFunctionsSidebar';
import TaskDCInfo from '@routes/TaskDcMonitoring/Info';
import StorageSidebar from '@routes/Storages/StorageSidebar';
import EventsFormSidebar from '@routes/Events/Sidebar';
import AttachTaskSidebar from '@routes/passport/tabs/letters/AttachTaskSidebar';
import SPCMapFormSidebar from '@routes/passport/tabs/setpointMap/SPMapFormSidebar';
import SPCMapListSidebar from '@routes/passport/tabs/setpointMap/SPMapListSidebar';
import {
  closePrompt, closeSidebar, setIsChangedSidebar, showPrompt,
} from '@state/sidebar/actions';

import {
  PASSPORT_DETAILS,
  SEARCH_FILTER,
  DOCUMENTS_UPLOAD,
  DOCUMENTS_BIND,
  CREATE_BLANK,
  UPDATE_BLANK,
  IMPORT_BLANK,
  CREATE_TASK,
  SETPOINT_INFO,
  CREATE_COMPLEX,
  IMPORT_TEMPLATE,
  COMPLEXES_FORM,
  EQUIPMENT_TYPES_FORM,
  COMMON_CLASSIFICATORS_FORM,
  TASK_DC_INFO,
  PASSPORT_FUNCTION_EDIT,
  STORAGE_FORM,
  EVENTS_FORM,
  CREATE_SETPOINT_MAP,
  NONPERFORMANCE_TERM,
  ATTACH_TASK_TO_LETTER,
  LIST_SETPOINT_MAP,
} from './types';

const mapTypesToComponents = {
  [PASSPORT_DETAILS]: PassportDetails,
  [SEARCH_FILTER]: FilterSidebar,
  [DOCUMENTS_UPLOAD]: DocumentsUpload,
  [DOCUMENTS_BIND]: DocumentsBind,
  [CREATE_BLANK]: CreateBlankSidebar,
  [UPDATE_BLANK]: UpdateBlankSidebar,
  [IMPORT_BLANK]: ImportBlankSidebar,
  [CREATE_TASK]: CreateTaskSidebar,
  [SETPOINT_INFO]: SetpointsInfoSidebar,
  [CREATE_COMPLEX]: SetpointMapSidebar,
  [IMPORT_TEMPLATE]: ImportTemplateSidebar,
  [COMPLEXES_FORM]: ComplexesFormSidebar,
  [EQUIPMENT_TYPES_FORM]: EquipmentTypesFormSidebar,
  [EVENTS_FORM]: EventsFormSidebar,
  [COMMON_CLASSIFICATORS_FORM]: CommonClassificatorsFormSidebar,
  [TASK_DC_INFO]: TaskDCInfo,
  [PASSPORT_FUNCTION_EDIT]: PassportFunctionsSidebar,
  [STORAGE_FORM]: StorageSidebar,
  [CREATE_SETPOINT_MAP]: SPCMapFormSidebar,
  [LIST_SETPOINT_MAP]: SPCMapListSidebar,
  [NONPERFORMANCE_TERM]: NonperformanceTermsSidebar,
  [ATTACH_TASK_TO_LETTER]: AttachTaskSidebar,
};

class SidebarRoot extends Component {
  componentDidMount() {
    const { history, location, closeSidebarData } = this.props;

    this.historyListener = history.listen((newLocation) => {
      if (location.pathname !== newLocation.pathname) closeSidebarData();
    });
  }

  componentDidUpdate(prevProps) {
    const { sidebar } = this.props;

    if (prevProps.sidebar.isShowPrompt !== sidebar.isShowPrompt && sidebar.isShowPrompt) {
      this.showConfirm();
    }
  }

  componentWillUnmount() {
    this.historyListener();
  }

  showConfirm = () => {
    const {
      sidebar, t, setParamIsChangedSidebar, closeSidebarPrompt, closeSidebarData,
    } = this.props;
    const {
      isShowPrompt, actionOnCancel, promptTitleText, promptOkText, promptCancelText, promptWidth,
    } = sidebar;

    Modal.confirm({
      visible: isShowPrompt,
      className: 'closeSidebarConfirm',
      title: t(promptTitleText) || t('CHANGED_SIDEBAR_ON_CLOSE_CONFIRM'),
      okText: t(promptOkText) || t('CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_OK'),
      cancelText: t(promptCancelText) || t('CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL'),
      width: promptWidth || 700,
      onOk: () => {
        closeSidebarPrompt && closeSidebarPrompt();
      },
      onCancel: async () => {
        setParamIsChangedSidebar && await setParamIsChangedSidebar(false);
        closeSidebarPrompt && await closeSidebarPrompt();
        closeSidebarData && await closeSidebarData();
        actionOnCancel && await actionOnCancel();
      },
    });
  }

  render() {
    const { sidebar } = this.props;
    const { componentType, ...restProps } = sidebar;
    const CurrentSidebar = mapTypesToComponents[componentType];

    return <CurrentSidebar {...restProps} />;
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  closeSidebarData: closeSidebar,
  showSidebarPrompt: showPrompt,
  closeSidebarPrompt: closePrompt,
  setParamIsChangedSidebar: setIsChangedSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      SidebarRoot
    )
  )
);
