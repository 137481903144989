import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  UPDATE_SIDEBAR,
  IS_CHANGED_SIDEBAR,
  SHOW_PROMPT_SIDEBAR,
  CLOSE_PROMPT_SIDEBAR,
} from './types';

const initialState = {
  isOpen: false,
  isChanged: false,
  isShowPrompt: false,
  promptWidth: null,
  promptTitleText: null,
  promptOkText: null,
  promptCancelText: null,
  actionOnCancel: null,
  width: '',
  componentType: '',
  data: null,
  isNotDisableBySidebar: false,
};

function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        isOpen: true,
        isChanged: false,
        componentType: action.componentType,
        data: action.data,
        width: action.width || state.width,
        isNotDisableBySidebar: action.isNotDisableBySidebar,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: false,
        isChanged: false,
        componentType: null,
        data: null,
        isNotDisableBySidebar: false,
      };
    case UPDATE_SIDEBAR:
      return {
        ...state,
        data: { ...state.data, ...action.data },
      };
    case IS_CHANGED_SIDEBAR:
      return {
        ...state,
        isChanged: action.data,
      };
    case SHOW_PROMPT_SIDEBAR:
      return {
        ...state,
        isShowPrompt: true,
        promptWidth: action.data.promptWidth,
        promptTitleText: action.data.promptTitleText,
        promptOkText: action.data.promptOkText,
        promptCancelText: action.data.promptCancelText,
        actionOnCancel: action.data.function,
      };
    case CLOSE_PROMPT_SIDEBAR:
      return {
        ...state,
        isShowPrompt: false,
        promptWidth: null,
        promptTitleText: null,
        promptOkText: null,
        promptCancelText: null,
        actionOnCancel: null,
      };
    default:
      return state;
  }
}

export default sidebarReducer;
