import React from 'react';
import { Letter, PassportBlank } from '@services/api-dts';
import { AppThunk } from '@state/store';
import {
  blanksActionTypes,
  CLEAR_BLANKS,
  GET_BLANKS,
  SET_ACTIVE_BLANK,
  UNSET_ACTIVE_BLANK,
  SET_BLANK_VERIFICATION
} from './types';
import api from '@services/api';
import { message } from 'antd';

// TODO refactor types after refactoring HOC's
interface ComponentProps {
  loadByPage: (
    tableName: string|null,
    apiFunctionName: string,
    mainParams: {},
    okStatuses: number[] | number,
    callbackOk?: ((status: number, data: { results: Letter[]; count: number }) => void) | null,
    callbackError?: (() => void) | null
  ) => void;
}

export const getBlanks = (
  component: React.Component<ComponentProps, never>,
  tableName: string|null,
  params = {}
): AppThunk => {
  const { loadByPage } = component.props;

  return async (dispatch) => {
    dispatch({ type: `${GET_BLANKS}_START` });
    await loadByPage(tableName, 'getBlanks', params, 200, (status: number, data: object) => {
      dispatch({
        type: GET_BLANKS,
        data: data,
      });
    },
    () => {
      dispatch({ type: `${GET_BLANKS}_ERROR` });
    });
  };
};

export const setActiveBlank = (blank: PassportBlank): blanksActionTypes => {
  return {
    type: SET_ACTIVE_BLANK,
    blank,
  };
};

export const unsetActiveBlank = (): blanksActionTypes => {
  return {
    type: UNSET_ACTIVE_BLANK,
  };
};

export const clearBlanks = (): blanksActionTypes => {
  return {
    type: CLEAR_BLANKS,
  };
};

export const setBlankVerification = (
  blankId: string,
  value: boolean
): AppThunk => {
  return async (dispatch) => {
    const { status } = await api.verifyBlank(blankId, value);

    if (status === 200) {
      dispatch({
        type: SET_BLANK_VERIFICATION,
        blankId: blankId,
        value: value
      });
    } else { 
      message.error(`Ошибка при обновлении статуса верификации бланка, код ${status}`)
     }
  };
};