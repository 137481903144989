import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button, Form, Input, message, DatePicker, Popconfirm,
} from 'antd';

import api from '@services/api';
import { SidebarLayout } from '@ui';

import styles from './styles.module.less';

class UpdateBlankSidebar extends Component {
  form = React.createRef();

  state = {
    isLoading: false,
    isDisableSave: false,
    isViewWarning: false,
  };

  onSave = async () => {
    const { data, t } = this.props;
    const { blank } = data;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ isLoading: true });

      const fieldValues = {
        ...values,
        usage_start_date: values['usage_start_date'] ? moment(values['usage_start_date']).format('YYYY-MM-DD').concat('T00:00:00') : null,
        usage_finish_date: values['usage_finish_date'] ? moment(values['usage_finish_date']).format('YYYY-MM-DD').concat('T00:00:00') : null,
      };

      const { status, data: responseData } = await api.updateBlankV1(blank.id, fieldValues);

      this.setState({ isLoading: false });

      if (status === 200) {
        data.callback(responseData);
        message.success(t('BLANK_EDIT_SUCCESS'));
      } else {
        message.error(t('BLANK_EDIT_ERROR'));
      }
    });
  };

  onChangeValues = (changedValues, allValues) => {
    if (JSON.stringify(changedValues) !== JSON.stringify({})) {
      this.setState({ isViewWarning: true });
    } else {
      this.setState({ isViewWarning: false });
    }
    if (
      allValues.usage_start_date
      && allValues.usage_finish_date
      && allValues.usage_start_date >= allValues.usage_finish_date
    ) {
      this.setState({ isDisableSave: true });
    } else {
      this.setState({ isDisableSave: false });
    }
  }

  disabledDate = (current, name) => {
    const form = this.form.current;

    if (name === 'usage_finish_date' && form && form.getFieldValue('usage_start_date')) {
      return current && current <= moment(moment(form.getFieldValue('usage_start_date')).format('YYYY-MM-DD').concat('T23:59:59'));
    }

    if (name === 'usage_start_date' && form && form.getFieldValue('usage_finish_date')) {
      return current && current >= moment(moment(form.getFieldValue('usage_finish_date')).format('YYYY-MM-DD').concat('T00:00:00'));
    }

    return null;
  };

  render() {
    const { data, t } = this.props;
    const { isLoading, isDisableSave, isViewWarning } = this.state;

    return (
      <SidebarLayout
        withoutCard
        header={(<h4>{t('EDIT_BLANK')}</h4>)}
        footer={
          isViewWarning
            ? (
              <Popconfirm
                title={(
                  <div className={styles.confirm}>
                    <span>{t('PASSPORT_SETPOINTS_VALIDITY_CONFIRM_1')}</span>
                    <br />
                    <span>{t('PASSPORT_SETPOINTS_VALIDITY_CONFIRM_2')}</span>
                  </div>
                )}
                onConfirm={this.onSave}
                okText={t('YES')}
                cancelText={t('NO')}
              >
                <Button block type='primary' loading={isLoading} disabled={isDisableSave}>
                  {t('SAVE')}
                </Button>
              </Popconfirm>
            )
            : (
              <Button block type='primary' loading={isLoading} onClick={this.onSave} disabled={isDisableSave}>
                {t('SAVE')}
              </Button>
            )
        }
      >
        <Form
          className={styles.form}
          name='updateBlankForm'
          ref={this.form}
          initialValues={{
            name: data.blank && data.blank.name,
            usage_start_date: data.blank && data.blank.usage_start_date && moment(data.blank.usage_start_date),
            usage_finish_date: data.blank && data.blank.usage_finish_date && moment(data.blank.usage_finish_date),
          }}
          onValuesChange={this.onChangeValues}
        >
          <Form.Item
            name='name'
            label={t('BLANK_NAME')}
            rules={[{ required: true, message: t('ENTER_NAME') }]}
          >
            <Input placeholder={t('ENTER_NAME')} />
          </Form.Item>
          <Form.Item
            name='usage_start_date'
            label={t('PASSPORT_SETPOINTS_VALIDITY_START')}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || !getFieldValue('usage_finish_date') || getFieldValue('usage_finish_date') > value) {
                    return Promise.resolve();
                  } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(t('PASSPORT_SETPOINTS_VALIDITY_ERROR'));
                  }
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={(current) => this.disabledDate(current, 'usage_start_date')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
          <Form.Item
            name='usage_finish_date'
            label={t('PASSPORT_SETPOINTS_VALIDITY_END')}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || !getFieldValue('usage_start_date') || getFieldValue('usage_start_date') < value) {
                    return Promise.resolve();
                  } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(t('PASSPORT_SETPOINTS_VALIDITY_ERROR'));
                  }
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={(current) => this.disabledDate(current, 'usage_finish_date')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
        </Form>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  UpdateBlankSidebar
);
