import storage from '@services/storage';
import { AUTH_COOKIE_NAME } from '@globalConstants';
import {
  SET_TOKEN,
  SET_JWT_DATA,
  SET_USER_EXTRA_INFO,
  userActionTypes,
  userState,
} from './types';

const initialState: userState = {
  name: '',
  user_id: '',
  token: '',
  info: {
    id: '',
    username: '',
    last_name: '',
    first_name: '',
    email: '',
    dispatcher_center: {},
    roles: [],
    notify_settings: {},
    send_email: false,
    departament: '',
  },
};

function userReducer(
  state = initialState,
  action: userActionTypes
): userState {
  switch (action.type) {
    case SET_TOKEN:
      storage.set(AUTH_COOKIE_NAME, action.token);
      return {
        ...state,
        token: action.token,
      };
    case SET_JWT_DATA:
      return {
        ...state,
        name: action.data.username,
        user_id: action.data.user_id,
      };
    case SET_USER_EXTRA_INFO:
      return {
        ...state,
        info: { ...action.data },
      };
    default:
      return state;
  }
}

export default userReducer;
