import React, { PureComponent } from 'react';
import GlobalSearch from './GlobalSearch';

class GlobalSearchRoute extends PureComponent {
  render() {
    return <GlobalSearch />;
  }
}

export default GlobalSearchRoute;
