import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import PrivateRoute from '@common/privateRoute';
import store from '@state/store';
import api from '@services/api';
import { getRights } from '@state/rights/actions';
import {
  MODE_EDIT,
  MODE_READONLY,
  RIGHT_PASSPORT_DOCS,
  RIGHT_PASSPORT_EVENTS,
  RIGHT_PASSPORT_FUNCTIONS,
  RIGHT_PASSPORT_INFO,
  RIGHT_PASSPORT_LETTERS,
  RIGHT_PASSPORT_NONPERFORMANCE,
  RIGHT_PASSPORT_OSCILLOGRAM,
  RIGHT_PASSPORT_SETPOINTS,
  RIGHT_PASSPORT_SETPOINT_MAP,
  RIGHT_PASSPORT_TASKS,
  RIGHT_ROUTE_CLASSIFIERS,
  RIGHT_ROUTE_EVENTS,
  RIGHT_ROUTE_EXTERNAL_SYSTEMS,
  RIGHT_ROUTE_FUNCTIONS,
  RIGHT_ROUTE_GLOBAL_SEARCH,
  RIGHT_ROUTE_JOURNAL,
  RIGHT_ROUTE_REPORTS,
  RIGHT_ROUTE_ROLES,
  RIGHT_ROUTE_STORAGES,
  RIGHT_ROUTE_SYSTEM_SETTINGS,
  RIGHT_ROUTE_TASK_DC_MONITORING,
  RIGHT_ROUTE_TEMPLATES,
  RIGHT_ROUTE_TREE,
  RIGHT_NEWS,
  RIGHT_MAINTENANCE,
  RIGHT_METATAGS_DOCS_ADD,
  RIGHT_NETWORK_MODELS,
  RIGHT_LIBRARY_DOCS,
  MODE_RIGHTS,
  MODE_NO_ACCESS,
  MODE_EDIT_TIME,
  RIGHT_ROUTE_CHECK_SETPOINTS,
  RIGHT_ROUTE_RESERV,
  RIGHT_ROUTE_DISTRIBUTION
} from '@globalConstants';

import FunctionsTab from './tabs/functionsTab';
import RolesTab from './tabs/rolesTab';
import {
  TAB_FUNCTIONS,
  TAB_ROLES,
} from './constants';

import styles from './styles.module.less';

class Roles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      rights: [],
      rightsDataRaw: [],
      isFunctionsEdit: false,
      isRolesEdit: false,
    };
  }

  componentDidMount() {
    this.getInterfaceRights();
    this.getRoles();
  }

  componentDidUpdate(prevProps) {
    const { activeTab: prevActiveTab } = prevProps;
    const { activeTab } = this.props;

    if (prevActiveTab !== activeTab) {
      if (activeTab === TAB_FUNCTIONS) this.getInterfaceRights();
    }
  }

  prepareData = (data) => {
    const { t } = this.props;
    const preparedData = {};

    [
      RIGHT_PASSPORT_DOCS,
      RIGHT_PASSPORT_EVENTS,
      RIGHT_PASSPORT_FUNCTIONS,
      RIGHT_PASSPORT_INFO,
      RIGHT_PASSPORT_LETTERS,
      RIGHT_PASSPORT_NONPERFORMANCE,
      RIGHT_PASSPORT_OSCILLOGRAM,
      RIGHT_PASSPORT_SETPOINTS,
      RIGHT_PASSPORT_SETPOINT_MAP,
      RIGHT_PASSPORT_TASKS,
      RIGHT_ROUTE_CLASSIFIERS,
      RIGHT_ROUTE_EVENTS,
      RIGHT_ROUTE_EXTERNAL_SYSTEMS,
      RIGHT_ROUTE_FUNCTIONS,
      RIGHT_ROUTE_GLOBAL_SEARCH,
      RIGHT_ROUTE_JOURNAL,
      RIGHT_ROUTE_REPORTS,
      RIGHT_ROUTE_ROLES,
      RIGHT_ROUTE_STORAGES,
      RIGHT_ROUTE_SYSTEM_SETTINGS,
      RIGHT_ROUTE_TASK_DC_MONITORING,
      RIGHT_ROUTE_TEMPLATES,
      RIGHT_ROUTE_TREE,
      RIGHT_NEWS,
      RIGHT_MAINTENANCE,
      RIGHT_METATAGS_DOCS_ADD,
      RIGHT_NETWORK_MODELS,
      RIGHT_LIBRARY_DOCS,
      RIGHT_ROUTE_CHECK_SETPOINTS,
      RIGHT_ROUTE_RESERV,
      RIGHT_ROUTE_DISTRIBUTION
    ].forEach((component) => {
      preparedData[component] = [
        {
          access_label: t('RIGHTS'),
          mode: MODE_RIGHTS,
          component,
          key: `component_${MODE_RIGHTS}`,
          noAccess: [],
          readonly: [],
          edit: [],
          editTime: []
        },
      ];
    });

    data.forEach((dataItem) => {
      if (preparedData[dataItem.component_id]) {
        Object.keys(dataItem).forEach((item) => {
          if (dataItem[item] === MODE_NO_ACCESS) {
            preparedData[dataItem.component_id][0].noAccess.push(item);
          }
          if (dataItem[item] === MODE_READONLY) {
            preparedData[dataItem.component_id][0].readonly.push(item);
          }
          if (dataItem[item] === MODE_EDIT) {
            preparedData[dataItem.component_id][0].edit.push(item);
          }
          if (dataItem[item] === MODE_EDIT_TIME) {
            preparedData[dataItem.component_id][0].editTime.push(item);
          }
        });
      }
    });

    return preparedData;
  };

  getInterfaceRights = async () => {
    const { status, data } = await api.getInterfaceRights();
    store.dispatch(getRights());

    if (status === 200) {
      const preparedData = this.prepareData(data.results);

      this.setState({
        rights: preparedData,
        rightsDataRaw: data.results,
      });
    }
  };

  getRoles = async () => {
    const { status, data } = await api.getRoles();

    if (status === 200) {
      this.setState({
        roles: data.results,
      });
    }
  };

  toggleFunctionsEdit = (e) => {
    e.preventDefault();
    const { isFunctionsEdit } = this.state;

    this.setState({
      isFunctionsEdit: !isFunctionsEdit,
    });
  };

  toggleRolesEdit = (e) => {
    e.preventDefault();

    const { isRolesEdit } = this.state;

    this.setState({
      isRolesEdit: !isRolesEdit,
    });
  };

  render() {
    const {
      rights, rightsDataRaw, roles, isFunctionsEdit, isRolesEdit,
    } = this.state;
    const { offsetTop, storeRights } = this.props;

    return (
      <div className={styles.roles}>
        <Switch>
          <PrivateRoute
            key={TAB_FUNCTIONS}
            path='/roles/tab/functions'
            render={() => {
              return (
                <FunctionsTab
                  rights={rights}
                  storeRights={storeRights}
                  rightsDataRaw={rightsDataRaw}
                  getInterfaceRights={this.getInterfaceRights}
                  toggleFunctionsEdit={this.toggleFunctionsEdit}
                  onChangeRight={this.onChangeRight}
                  isEdit={isFunctionsEdit}
                  offsetTop={offsetTop}
                />
              );
            }}
          />
          <PrivateRoute
            key={TAB_ROLES}
            path='/roles/tab/roles'
            render={() => {
              return (
                <RolesTab
                  roles={roles}
                  storeRights={storeRights}
                  toggleRolesEdit={this.toggleRolesEdit}
                  isEdit={isRolesEdit}
                />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  storeRights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Roles
  )
);
