import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withPagination, withColumnFilters } from '@hoc';
import { compose } from 'redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  Spin, Popconfirm, message, Button, Divider, Select, Row, Popover, Tooltip, Checkbox
} from 'antd';
import Icon, {
  ArrowUpOutlined, DiffOutlined, DownloadOutlined, PlusCircleOutlined, ExportOutlined,
  ImportOutlined, PlusOutlined, DeleteOutlined, EditFilled, CheckCircleOutlined,
  FileTextOutlined, WarningOutlined, FileExcelOutlined, PartitionOutlined,
} from '@ant-design/icons';

import api from '@services/api';
import { getBlanks, setActiveBlank, unsetActiveBlank, setBlankVerification } from '@state/blanks/actions';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { getObjectFromUrl, textHighlighter } from '@globalHelpers';
import { RIGHT_PASSPORT_SETPOINTS, MODE_EDIT, TABLE_SIZE } from '@globalConstants';
import { CREATE_BLANK, IMPORT_BLANK, UPDATE_BLANK } from '@common/sidebarRoot/types';
import { ExportBottom } from '@common/exportPopconfirm';
import CommonTable from '@common/CommonTable';
import {
  CustomEmpty, CustomCard, CustomTag, PopoverEllipsis, CustomSelect,
} from '@ui';
import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';
import { ReactComponent as TransformerIcon } from "@ui/icons/transformer.svg";

import exportBlank from './exportBlank';
import {
  EXPORT_MODE_PF, EXPORT_MODE_WORD, TASK_BLANKS_DELETE, TASK_BLANKS_SPLIT,
} from './constants';

import '../styles.less';
import styles from './styles.module.less';

const { Option } = Select;
const CALCULATING_STATUS = 'calculating';

class Blanks extends Component {
  tableName = 'passport_tab_setpoints_blanks';

  state = {
    compareMode: false,
    compareMainBlank: null,
    compareIds: [],
    loadingCompareList: false,
    comparableList: [],
    isFileDownloading: false,
    updatingVersion: false,
    enableMultiselectBlanksForSplit: false,
    selectedBlanksForSplit: [],
    isVerificationUpdating: false,
    oldType: null,
    clicked: false
  };

  componentDidMount = () => {
    this.mount();
  }

  mount = async () => {
    const {
      setTableName, selectedTask, mode, selectedBlank, deviceId, passportId,
    } = this.props;

    if (deviceId && passportId) {
      await this.loadQueryParams();
      await this.loadBlanksData();
    }

    setTableName(this.tableName, async () => {
      if (selectedBlank && selectedTask && mode === 'task') {
        const el = document.getElementById('passport-task-blanks');
        el && el.scrollIntoView({ behavior: 'smooth' });
      }
    });
  } 

  componentDidUpdate = async (prevProps) => {
    const {
      passportId, unselectBlankData, selectedTask, mode, selectedBlank,
      deviceId, location, onBlock, onUnBlock, isDefaultOpenPanel,
    } = this.props;

    if (
      (
        prevProps.passportId === passportId
        && (
          !isEqual(location, prevProps.location)
          || prevProps.deviceId !== deviceId
        )
      )
      || (
        prevProps.passportId
        && !passportId
      )
    ) {
      unselectBlankData();
      prevProps.deviceId !== deviceId && onBlock();
    }

    if (passportId && prevProps.passportId !== passportId) {
      unselectBlankData();
      const res = await this.loadQueryParams();
      if (!res) {
        await this.loadBlanksData();
      }
    }

    if (mode === 'task' && prevProps.deviceId === deviceId) {
      if (!isEqual(prevProps.selectedTask, selectedTask)) {
        const res = await this.loadQueryParams();
        if (!res) {
          if (selectedBlank && selectedBlank.id) {
            await this.filterBlanksByIdName(selectedBlank.id);
          } else {
            await this.loadBlanksData();
          }
        }
      } else if (
        isDefaultOpenPanel
        && selectedTask
        && selectedTask.id
        && !isEqual(prevProps.selectedBlank, selectedBlank)
      ) {
        const el = document.getElementById('passport-task-blanks');
        el && el.scrollIntoView({ behavior: 'smooth' });
        onUnBlock && onUnBlock();
      }
    }
  }

  componentWillUnmount() {
    const { unselectBlankData } = this.props;

    unselectBlankData();
  }

  loadQueryParams = async () => {
    const { location } = this.props;

    const blankFromUrl = getObjectFromUrl(location, ['id', 'name']);

    if (blankFromUrl.id && location.pathname.includes('/tab/setpoints')) {
      await this.filterBlanksByIdName(blankFromUrl.id);
      return true;
    }
    return false;
  };

  filterBlanksByIdName = async (id) => {
    const { changePage, resetFilters } = this.props;

    resetFilters(
      changePage(1, async () => {
        await this.loadBlanksData({ selected: id }, () => {
          const blank = this.getBlankInfo(id);
          if (blank) {
            this.onSelectChange(blank, blank.active_group);
          }
        });
      })
    );
  };

  getBlankInfo = (blankId) => {
    const { blanksData } = this.props;

    return (
      blanksData
      && blanksData.length
      && blanksData.find((item) => item.id === blankId)
    ) || null;
  };

  loadBlanksData = async (addParams = {}, callback) => {
    const {
      passportId, getBlanksData, filterValues, selectedTask,
    } = this.props;

    let requestParams = { type: ['V1', 'V1V2'] };
    if (selectedTask && selectedTask.id) {
      requestParams.task_dc = selectedTask.id;
      delete requestParams.type
    } else {
      requestParams.passport = passportId === null ? undefined : passportId;
    }
    requestParams = { ...requestParams, ...filterValues, ...addParams };
    await getBlanksData(this, this.tableName, requestParams);
    callback && callback();
  };

  onSelectChange = (blank, activeGroup) => {
    const { selectBlankData, loadSetpointsData } = this.props;

    if (blank) {
      selectBlankData(blank);
      loadSetpointsData(blank);
      this.setState({
        selectedGroup: activeGroup,
        oldType: blank.old_type
      });
    } else {
      this.setState({
        selectedGroup: null,
      });
    }
  };

  deleteFile = async () => {
    const {
      t, passportId, selectedBlank, unselectBlankData, loadSetpointsData, resetFilters,
    } = this.props;

    this.setState({ isRemovingFile: true });

    const { status, data } = await api.deleteBlank(passportId, selectedBlank.id);

    if (status === 204) {
      message.success(t('DELETING_FILE_SUCCESS'));

      unselectBlankData();
      loadSetpointsData();
      this.onSelectChange(null, null);

      await this.loadBlanksData();

      const { blanksData } = this.props;

      if (blanksData.length === 0) {
        resetFilters(
          await this.loadBlanksData()
        );
      }
    } else {
      if (data && data.error) {
          message.error(data.error);
      } else {
        message.error(t('DELETING_FILE_ERROR'));
      }
    }

    this.setState({ isRemovingFile: false });
  };

  onExportBlank = async (e, mode, calcViewMode, staticRec) => {
    e.stopPropagation();
    e.preventDefault();

    const {
      blanksData, selectedBlank, exportGroupByFunction, t
    } = this.props;

    this.setState({ isFileDownloading: true });

    if (!selectedBlank.id) {
      message.error(t('FILE_NOT_SELECTED'));
      this.setState({ isFileDownloading: false });
      return;
    }

    const currentBlank = blanksData.find((blank) => blank.id === selectedBlank.id);

    await exportBlank(currentBlank, this, mode, exportGroupByFunction || false, calcViewMode, staticRec);

    this.setState({ isFileDownloading: false, clicked: false });
  };

  changeBlankCallback = async (blank) => {
    const { resetFilters, changePage } = this.props;

    resetFilters(
      changePage(1, async () => {
        await this.loadBlanksData({ selected: blank }, () => {
          const { blanksData } = this.props;
          this.onRowClick(blanksData[0])();
        });
      })
    );
  };

  onImportBlank = async () => {
    const { passportId, openSidebarData } = this.props;

    openSidebarData(
      IMPORT_BLANK,
      {
        type: 'blank',
        passport__id: passportId,
        callback: (blank) => {
          this.changeBlankCallback(blank.id);
        },
      }
    );
  };

  onCreateBlank = () => {
    const {
      passportId, openSidebarData, closeSidebarData,
    } = this.props;

    openSidebarData(
      CREATE_BLANK,
      {
        passport_id: passportId,
        callback: async (blank) => {
          await this.changeBlankCallback(blank.id);
          closeSidebarData();
        },
      }
    );
  };

  updateBlank = () => {
    const {
      passportId, openSidebarData, closeSidebarData, selectedBlank,
    } = this.props;
    const currentBlank = this.getBlankInfo(selectedBlank.id);

    openSidebarData(
      UPDATE_BLANK,
      {
        passport_id: passportId,
        blank: currentBlank,
        callback: async (blank) => {
          await this.changeBlankCallback(blank.id);
          closeSidebarData();
        },
      }
    );
  };

  onCompare = async () => {
    let { compareMode } = this.state;
    const { onChangeCompareMode, selectedBlank } = this.props;
    compareMode = !compareMode;
    if (compareMode) {
      // load compare list
      this.setState({ loadingCompareList: true });

      const comparableListAnswer = await api.getBlankComparableList(selectedBlank.id);
      let comparableList = [];

      if (comparableListAnswer.status === 200) {
        comparableList = comparableListAnswer.data;
      }

      this.setState({ comparableList, compareMainBlank: selectedBlank, loadingCompareList: false });
    } else {
      this.setState({ comparableList: [], compareMainBlank: null });
    }
    const compareIds = [];
    await onChangeCompareMode(compareMode, compareIds);
    this.setState({ compareIds, compareMode });
  };

  onRowClick = (blank) => {
    const { onChangeCompareMode } = this.props;

    return () => {
      let { compareIds } = this.state;
      const { compareMode, comparableList } = this.state;

      if (compareMode) {
        if (comparableList.filter((id) => id === blank.id).length) {
          if (compareIds.filter((id) => id === blank.id).length) {
            compareIds = [...compareIds.filter((id) => id !== blank.id)];
          } else {
            compareIds = [...compareIds, blank.id];
          }
          this.setState({ compareIds });
          onChangeCompareMode(compareMode, compareIds);
        }
      } else {
        this.onSelectChange(blank, blank.active_group);
      }
    };
  };

  onAddGroup = async (blankId, groupCount) => {
    const {
      passportId, t, onChangeGroupStart, onChangeGroup,
    } = this.props;

    if (!blankId) {
      return false;
    }

    onChangeGroupStart();

    const res = await api.addGroupToBlank(passportId, blankId);

    if (!res || res.status !== 200) {
      message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
      return false;
    }

    await this.loadBlanksData();

    const blank = this.getBlankInfo(blankId);
    this.onSelectChange(blank, blank.active_group);

    this.setState({
      selectedGroup: groupCount + 1,
    });

    onChangeGroup(true);

    return true;
  };

  onDeleteGroup = async (blankId, groupId) => {
    const {
      passportId, t, onChangeGroupStart, onChangeGroup,
    } = this.props;

    if (!blankId || !groupId) {
      return false;
    }

    onChangeGroupStart();

    const res = await api.deleteGroupFromBlank(passportId, blankId, groupId);

    if (!res || res.status !== 200) {
      message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
      return false;
    }

    await this.loadBlanksData();

    const blank = this.getBlankInfo(blankId);
    this.onSelectChange(blank, blank.active_group);

    this.setState({
      selectedGroup: blank.active_group,
    });

    onChangeGroup(true);

    return true;
  };

  onSetActiveGroup = async (blankId, groupId) => {
    const {
      t, onChangeGroupStart, onChangeGroup,
    } = this.props;

    if (!blankId || !groupId) {
      return false;
    }

    onChangeGroupStart();

    const res = await api.updateBlankV1(blankId, { active_group: groupId });

    if (!res || res.status !== 200) {
      message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
      return false;
    }

    await this.loadBlanksData();

    const blank = this.getBlankInfo(blankId);
    this.onSelectChange(blank, blank.active_group);

    onChangeGroup(true);

    return true;
  };

  handleSplitBlanks = async (action) => {
    const {
      t, selectedTask, onBlock, onUnBlock, updateTasks,
    } = this.props;
    const { selectedBlanksForSplit } = this.state;

    onBlock && onBlock();

    const params = new FormData();
    if (selectedBlanksForSplit && selectedBlanksForSplit.length) {
      selectedBlanksForSplit.forEach((x) => {
        params.append('blanks', x);
      });
    }

    switch (action) {
      case TASK_BLANKS_SPLIT: {
        const splitResult = await api.splitTaskBlanks(selectedTask.id, params);
        if (splitResult && splitResult.status !== 200) {
          message.error(t('PASSPORT_TASKS_SPLIT_BLANKS_ERROR_SPLIT'));
        } else {
          message.info(t('PASSPORT_TASKS_SPLIT_BLANKS_SUCCESS_SPLIT'));
          updateTasks && updateTasks(true);
          await this.loadBlanksData();
        }
        break;
      }
      case TASK_BLANKS_DELETE: {
        const deleteResult = await api.deleteTaskBlanks(selectedTask.id, params);
        if (deleteResult && deleteResult.status !== 200) {
          if (deleteResult.error) {
            message.error(deleteResult.error);
          } else {
            message.error(t('PASSPORT_TASKS_SPLIT_BLANKS_ERROR_DELETE'));
          }

        } else {
          message.info(t('PASSPORT_TASKS_SPLIT_BLANKS_SUCCESS_DELETE'));
          await this.loadBlanksData();
        }
        break;
      }
      default:
        message.warn(t('PASSPORT_TASKS_SPLIT_BLANKS_UNKNOWN_ACTION'));
    }

    onUnBlock && onUnBlock();

    this.setState({
      enableMultiselectBlanksForSplit: false,
      selectedBlanksForSplit: [],
    });
  }

  handleVersionUp = async () => {
    const {
      t, selectedBlank, changePage,
    } = this.props;

    if (selectedBlank && selectedBlank.template && selectedBlank.template.has_update) {
      this.setState({ updatingVersion: true });
      const { status, data } = await api.updateBlankTemplateVersion(selectedBlank.id);
      this.setState({ updatingVersion: false });

      if (status === 200) {
        message.success(t('SUCCESS_CLONE'));
        changePage(1, async () => {
          await this.loadBlanksData({ selected: selectedBlank.id }, () => {
            const blank = this.getBlankInfo(data.blank_id);
            this.onSelectChange(blank, blank.active_group);
          });
        });
      } else {
        message.error(t('ERROR_CLONE'));
      }
    }
  };

  navigateToTasks = (id, name) => {
    const {
      deviceId,
      history,
    } = this.props;

    history.push(`/passport/${deviceId}/tab/tasks/?id=${id}&name=${name}`);
  };

  handleResetCallback = () => {
    const { unselectBlankData, loadSetpointsData } = this.props;
    const { compareMainBlank } = this.state;

    unselectBlankData();
    loadSetpointsData();
    if (compareMainBlank) {
      this.onSelectChange(compareMainBlank, null);
    } else {
      this.onSelectChange(null, null);
    }
  };

  handleVerifyBlank = async (id, value) => {
    const { changeBlankVerification } = this.props;
    await changeBlankVerification(id, value);
  }

  handleClickChange = visible => {
    this.setState({ clicked: visible });
  };

  render() {
    const {
      isBlocked, t, blanksData, rights, disabledChangeBlank, passportId, count,
      isBlanksLoading, selectedBlank, changePage, onShowSizeChange, limit, page,
      getColumnSearchFilter, filterValues, isArchived, mode, selectedTask, onBlock,
      isDefaultOpenPanel, titleExtra, enableCollapsableHasNoData = false, staticRec
    } = this.props;
    const {
      isRemovingFile, isFileDownloading, isExecutingActions, compareMode, compareIds, loadingCompareList,
      comparableList, selectedGroup, updatingVersion, enableMultiselectBlanksForSplit, selectedBlanksForSplit, oldType
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_SETPOINTS] === MODE_EDIT && !isArchived;

    const selectedBlankId = selectedBlank && selectedBlank.id;
    const isSelectedDocBlank = selectedBlank && selectedBlank.is_doc;
    const isSelectedActiveBlank = selectedBlank && selectedBlank.is_active;
    const isSelectedActiveGroup = (
      selectedGroup
      && selectedBlank
      && selectedBlank.active_group === selectedGroup
    );
    const isCalculating = selectedBlank && selectedBlank.status === CALCULATING_STATUS;
    const disableRmButton = !selectedBlankId
      || isRemovingFile
      || (selectedBlank && selectedBlank.is_active);
    const disabledDownload = isExecutingActions || isFileDownloading || isRemovingFile;
    const disabledChange = (disabledChangeBlank && disabledChangeBlank === true);
    const disableVersionUp = (selectedBlank
        && ((selectedBlank.template
        && !selectedBlank.template.has_update)
        || isCalculating
        || selectedBlank.is_active));
    const disabledGroupButtons = (
      !selectedBlank
      || !isEditRight
      || isSelectedActiveBlank
      || selectedBlank.status !== 'in_work'
      || isSelectedDocBlank
    );
    const disabledGroupAdd = disabledGroupButtons;
    const disabledGroupDelete = disabledGroupButtons || selectedBlank.groups_count === 1 || isSelectedActiveGroup;
    const disabledGroupActivate = disabledGroupButtons || selectedBlank.groups_count === 1 || isSelectedActiveGroup;
    const disabledSplitButtons = (
      !selectedBlanksForSplit
      || selectedBlanksForSplit.length === 0
      || (
        blanksData
        && blanksData.length
        && selectedBlanksForSplit
        && blanksData.length === selectedBlanksForSplit.length
      )
    );

    const groups = [];
    if (selectedBlank) {
      for (let i = 1; i <= selectedBlank.groups_count; i += 1) {
        groups.push(
          <Option value={i} key={i}>
            {`${t('GROUP')} ${i} `}
            {
              i === selectedBlank.active_group && !disabledGroupButtons
                ? <CheckCircleOutlined className='check-circle--active' />
                : ''
            }
          </Option>
        );
      }
    }

    const columnsBlanks = [
      {
        title: t('BLANK'),
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
        render: (item, record) => {
          let className;
          if (record.id === selectedBlankId) {
            className = 'main';
          } else if (compareMode) {
            if (comparableList.filter((id) => id === record.id).length) {
              className = 'for_compare';
              if (compareIds.filter((id) => id === record.id).length) {
                className += ' selected';
              }
            } else {
              className = 'inactive';
            }
          }

          let wrappedText = textHighlighter(filterValues['name'], item);

          if (record.passport !== passportId
            && selectedTask
            && record.protection_device
            && record.protection_device.id
          ) {
            wrappedText = (
              <Link
                className='link'
                to={
                  ''.concat(
                    `/passport/${record.protection_device.id}/tab/tasks/`,
                    `?id=${selectedTask.id}`,
                    `&name=${selectedTask.name}`,
                    `&blank_id=${record.id}`,
                    `&blank_name=${record.name}`,
                    '&openBlankPanel=true',
                    '&openSetpointsPanel=true',
                    ''
                  )
                }
                title={t('PASSPORT_TASKS_BLANK_LINK_TO_OTHER_PASSPORT')}
                onClick={() => (onBlock && onBlock())}
              >
                {wrappedText}
              </Link>
            );
          }
          return (
            <div className='blank-icons'>
              {!record.is_doc && <FileTextOutlined />}
              {record.is_doc && <FileExcelOutlined />}
              {
                record && record.is_active
                  ? <CheckCircleOutlined className='isActive' title={t('CURRENT_ACTIVE_BLANK')} />
                  : ''
              }
              &nbsp;
              <PopoverEllipsis
                content={wrappedText}
                containerStyles={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  maxWidth: '95%',
                }}
              >
                <span className={className}>{wrappedText}</span>
              </PopoverEllipsis>
            </div>
          );
        },
      },
      {
        title: t('PASSPORT_SETPOINTS_VALIDITY'),
        dataIndex: 'validity',
        key: 'validity',
        width: '15%',
        render: (item, record) => {
          const dt1 = record.usage_start_date ? moment(record.usage_start_date).format('DD.MM.YYYY') : null;
          const dt2 = record.usage_finish_date ? moment(record.usage_finish_date).format('DD.MM.YYYY') : null;
          return (
            dt1 && dt2
              ? `${dt1} - ${dt2}`
              : (
                dt1
                  ? `${dt1} - ...`
                  : (
                    dt2
                      ? `... - ${dt2}`
                      : '-'
                  )
              )
          );
        },
      },
      {
        title: t('STATUS'),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (item) => (item && item.length ? t(`STATUS_${item.toUpperCase()}`) : '-'),
      },
      {
        title: t('TEMPLATE'),
        dataIndex: 'template',
        key: 'template',
        width: '15%',
        render: (template, record) => {
          if (!template) {
            return '-';
          }

          return (
            <CustomTag
              className='link'
              key={record.id}
              getPopupContainer={(triggerNode) => triggerNode.closest('td').parentNode}
            >
              <Link 
              className='link'
              to={`/templates?id=${template.id}&name=${template.name}`}>
                {template.name}
              </Link>
            </CustomTag>
          );
        },
      },
      {
        title: t('TEMPLATE_VERSION'),
        dataIndex: ['template', 'version'],
        key: 'template_version',
        width: '12%',
        render: (text, record) => {
          if (!record.template) {
            return '-';
          }

          return (
            <>
              {record.template.version}
              &nbsp;
              {
                record.template.has_update
                && record.status !== CALCULATING_STATUS
                && !record.is_active
                && (
                  <Popover content={`${record.template.has_update.name} v. ${record.template.has_update.version}`}>
                    <ArrowUpOutlined className='icon-link' />
                  </Popover>
                )
              }
            </>
          );
        },
      },
      {
        title: t('ACTIVE_GROUP'),
        dataIndex: 'active_group',
        key: 'active_group',
        width: '12%',
        render: (active_group, record) => (record.is_doc ? '-' : `${t('GROUP')} ${active_group}`),
      },
      {
        title: t('TAB_TASKS'),
        dataIndex: 'task_dcs',
        key: 'task_dcs',
        width: '16%',
        render: (tasks, record) => (
          tasks.map((task) => (
            <CustomTag
              className='link'
              key={task.id}
              getPopupContainer={(triggerNode) => triggerNode.closest('td').parentNode}
            >
              <Link
                className='link'
                to={
                  ''.concat(
                    `/passport/${record.protection_device.id}/tab/tasks/`,
                    `?id=${task.id}`,
                    `&name=${task.name}`,
                    mode === 'task' && record.passport === passportId ? '&scroll_to_task=true' : '',
                    ''
                  )
                }
              >
                {task.name}
              </Link>
            </CustomTag>
          ))
        ),
      },
      {
        title: t('VERIFICATION'),
        dataIndex: 'is_verify',
        key: 'is_verify',
        width: '12%',
        render: (is_verify, record) => (
          <Tooltip title={is_verify ? t('VERIFICATION_READY') : t('VERIFICATION_NEED_CORRECTION')}>
            <Checkbox
              key={`verification_checkbox_${record.id}`}
              className={styles.verificationCheckbox}
              checked={is_verify}
              onChange={(e) => this.handleVerifyBlank(record.id, e.target.checked)}
              onClick={e => e.stopPropagation()} />
          </Tooltip>
        ),
      },
    ];

    const emptyProps = {};

    if (!selectedBlankId && blanksData && blanksData.length) {
      emptyProps.description = t('BLANK_NOT_SELECTED');
    }

    const listProps = {
      locale: {
        emptyText: <CustomEmpty {...emptyProps} />,
      },
    };

    let selectedRowKeys;

    if (compareMode) {
      selectedRowKeys = [selectedBlankId, ...compareIds];
    } else {
      selectedRowKeys = selectedBlankId ? [selectedBlankId] : [];
    }
    const isComparing = compareMode || loadingCompareList;

    const rowSelection = (
      enableMultiselectBlanksForSplit
        ? {
          type: 'checkbox',
          selectedRowKeys: selectedBlanksForSplit,
          onChange: (keys) => {
            this.setState({
              selectedBlanksForSplit: keys,
            });
          },
        }
        : {
          type: 'radio',
          selectedRowKeys,
          onChange: (Keys, selectedRows) => {
            this.onRowClick(selectedRows[0])();
          },
          getCheckboxProps: (record) => ({
            disabled: record.passport !== passportId,
          }),
        }
    );

    const rowSelectionInCompareModeClassName = (record) => {
      return compareMode && record && record.id && compareIds.includes(record.id)
        ? 'selected_for_compare'
        : '';
    };

    const spinDefault = null;
    const spinWarning = <WarningOutlined />;
    const disabledVersionUp = (
      !isEditRight
      || !selectedBlankId
      || disableVersionUp
      || isComparing
      || selectedBlank.is_doc
    );

    return (
      <Spin
        spinning={isBlocked || disabledChange || isFileDownloading || updatingVersion}
        tip={disabledChange ? t('DISABLE_CHANGE_BLANK') : ''}
        indicator={disabledChange ? spinWarning : spinDefault}
      >
        <CustomCard
          collapsable
          noPadding
          noBottomMargin
          titleExtra={titleExtra}
          loading={isBlanksLoading}
          count={blanksData && blanksData.length}
          isDefaultOpenPanel={isDefaultOpenPanel}
          enableCollapsableHasNoData={enableCollapsableHasNoData}
          hasData={!!(blanksData && blanksData.length)}
          title={
            mode === 'task'
              ? `${t('PASSPORT_TASKS_BLANKS')} (${selectedTask && selectedTask.name})`
              : t('SETPOINT_BLANKS')
          }
          className={styles.blanks}
          extra={
            mode === 'setpoints'
              ? (
                <>
                  <Row>
                    <Button
                      disabled={disabledVersionUp}
                      size='small'
                      icon={<ArrowUpOutlined />}
                      onClick={this.handleVersionUp}
                    >
                      {t('TABLE_COL_VERSION_UP')}
                    </Button>
                    <Button
                      disabled={loadingCompareList || !selectedBlankId || disabledDownload || isSelectedDocBlank}
                      onClick={this.onCompare}
                      size='small'
                      loading={loadingCompareList}
                      type={compareMode ? 'primary' : null}
                      className='control-button'
                      icon={<DiffOutlined />}
                    >
                      {t('COMPARE')}
                    </Button>
                    {oldType || !selectedBlankId ? (
                      <Button
                        disabled={!selectedBlankId || disabledDownload || isComparing}
                        onClick={(e) => this.onExportBlank(e, EXPORT_MODE_WORD)}
                        loading={isFileDownloading}
                        size="small"
                        className="control-button"
                        icon={<DownloadOutlined />}
                      >
                        {t('DOWNLOAD')}
                      </Button>
                    ) : (
                      <Popover
                        content={
                          <div>
                            <Button onClick={(e) => this.onExportBlank(e, EXPORT_MODE_WORD, 'primary', staticRec)}>
                              Первичные
                            </Button>
                            <Icon
                              component={TransformerIcon}
                              style={{ padding: '0 5px' }}
                            />
                            <Button onClick={(e) => this.onExportBlank(e, EXPORT_MODE_WORD, 'secondary')}>
                              Вторичные
                            </Button>
                          </div>
                        }
                        trigger="click"
                        visible={this.state.clicked}
                        onVisibleChange={this.handleClickChange}
                      >
                        <Button
                          disabled={!selectedBlankId || disabledDownload || isComparing}
                          loading={isFileDownloading}
                          size="small"
                          className="control-button"
                          icon={<DownloadOutlined />}
                        >
                          {t('DOWNLOAD')}
                        </Button>
                      </Popover>
                    )}
                    {selectedBlank && selectedBlank.status === 'archived' ? (
                      <Tooltip placement='topLeft' title={t('ARCHIVED_BLANK_NOT_EDITABLE')}>
                        <Button
                          disabled={true}
                          size='small'
                          className='control-button'
                          icon={<EditFilled />}
                        >
                          {t('EDIT')}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        disabled={!selectedBlankId
                        || !isEditRight
                        || isComparing
                        || isCalculating}
                        onClick={this.updateBlank}
                        size='small'
                        className='control-button'
                        icon={<EditFilled />}
                      >
                        {t('EDIT')}
                      </Button>
                    )}

                    <Divider type='vertical' />

                    <Button
                      disabled={!isEditRight || isComparing}
                      onClick={this.onCreateBlank}
                      size='small'
                      className='control-button'
                      icon={<PlusCircleOutlined />}
                    >
                      {t('NEW_BLANK')}
                    </Button>

                    <Popconfirm
                      title={t('DELETE_FILE_CONFIRM')}
                      onConfirm={this.deleteFile}
                      okText={t('YES')}
                      cancelText={t('NO')}
                      disabled={disableRmButton || !isEditRight || isComparing}
                      size='small'
                    >
                      <Button
                        disabled={disableRmButton || !isEditRight || isComparing}
                        size='small'
                        className='control-button'
                      >
                        <Icon component={BucketIcon} className={disableRmButton && 'svg-icon--disabled'} />
                        {t('DELETE')}
                      </Button>
                    </Popconfirm>

                    <Divider type='vertical' />

                    <Button
                      disabled={!selectedBlankId || disabledDownload || isComparing || isSelectedDocBlank}
                      onClick={(e) => this.onExportBlank(e, EXPORT_MODE_PF)}
                      id='button_export_pf_protection'
                      type='secondary'
                      size='small'
                      className='control-button'
                      icon={<ExportOutlined />}
                    >
                      {t('PFPROTECTION_EXPORT')}
                    </Button>

                    <Button
                      disabled={!isEditRight || isComparing}
                      id='button_import_pf_protection'
                      type='primary'
                      onClick={this.onImportBlank}
                      size='small'
                      className='control-button'
                      icon={<ImportOutlined />}
                    >
                      {t('PFPROTECTION_IMPORT')}
                    </Button>
                  </Row>
                  <Row className='passport-setpoints__row2'>
                    <span className='control-button'>
                      {t('GROUPS_WORKING')}
                    </span>
                    <CustomSelect
                      className='control-button passport-setpoints__select_groups'
                      size='small'
                      key={selectedBlankId}
                      value={selectedGroup}
                      disabled={disabledGroupButtons || isComparing}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
                      onChange={(value) => this.setState({ selectedGroup: value })}
                    >
                      {groups}
                    </CustomSelect>

                    <Button
                      size='small'
                      icon={<PlusOutlined />}
                      className='control-button'
                      disabled={disabledGroupAdd || isComparing}
                      onClick={() => this.onAddGroup(selectedBlank.id, selectedBlank.groups_count)}
                    >
                      {t('GROUP_ADD')}
                    </Button>

                    <Popconfirm
                      title={t('GROUP_DELETE_CONFIRM')}
                      onConfirm={() => this.onDeleteGroup(selectedBlank.id, selectedGroup)}
                      okText={t('YES')}
                      cancelText={t('NO')}
                      disabled={disabledGroupDelete || isComparing}
                    >
                      <Button
                        size='small'
                        icon={<DeleteOutlined />}
                        className={`control-button button-delete ${disabledGroupDelete ? 'disabled-button' : ''}`}
                        disabled={disabledGroupDelete || isComparing}
                      >
                        {t('GROUP_DELETE')}
                      </Button>
                    </Popconfirm>

                    <Button
                      size='small'
                      className='control-button'
                      disabled={disabledGroupActivate || isComparing}
                      onClick={() => this.onSetActiveGroup(selectedBlank.id, selectedGroup)}
                    >
                      <CheckCircleOutlined
                        className={
                          disabledGroupActivate
                            ? ''
                            : 'check-circle--active'
                        }
                      />
                      {t('GROUP_SET_ACTIVE')}
                    </Button>
                  </Row>
                </>
              )
              : (
                <Row>
                  {!enableMultiselectBlanksForSplit && (
                    <Button
                      size='small'
                      icon={<PartitionOutlined />}
                      onClick={() => {
                        this.setState({
                          enableMultiselectBlanksForSplit: true,
                          selectedBlanksForSplit: selectedBlankId ? [selectedBlankId] : [],
                        });
                      }}
                    >
                      {t('PASSPORT_TASKS_SPLIT_BLANKS_START')}
                    </Button>
                  )}
                  {enableMultiselectBlanksForSplit && (
                    <>
                      <Popconfirm
                        title={t('PASSPORT_TASKS_SPLIT_BLANKS_DELETE_CONFIRM')}
                        onConfirm={() => this.handleSplitBlanks(TASK_BLANKS_DELETE)}
                        okText={t('YES')}
                        cancelText={t('NO')}
                        disabled={disabledSplitButtons}
                      >
                        <Button
                          size='small'
                          disabled={disabledSplitButtons}
                        >
                          <Icon component={BucketIcon} className={disabledSplitButtons && 'svg-icon--disabled'} />
                          {t('PASSPORT_TASKS_SPLIT_BLANKS_DELETE')}
                        </Button>
                      </Popconfirm>

                      <Divider type='vertical' />

                      <Button
                        size='small'
                        onClick={() => {
                          this.setState({
                            enableMultiselectBlanksForSplit: false,
                            selectedBlanksForSplit: [],
                          });
                        }}
                      >
                        {t('PASSPORT_TASKS_SPLIT_BLANKS_CANCEL')}
                      </Button>
                      <Button
                        size='small'
                        icon={<PartitionOutlined />}
                        onClick={() => this.handleSplitBlanks(TASK_BLANKS_SPLIT)}
                        disabled={disabledSplitButtons}
                      >
                        {t('PASSPORT_TASKS_SPLIT_BLANKS_END')}
                      </Button>
                    </>
                  )}
                </Row>
              )
          }
        >
          <CommonTable
            {...listProps}
            size={TABLE_SIZE}
            tableName={this.tableName}
            columns={columnsBlanks}
            dataSource={blanksData}
            loading={isBlanksLoading}
            pagination={{
              onChange: (newPage) => changePage(newPage, this.loadBlanksData),
              pageSize: limit,
              current: page,
              total: count,
            }}
            onShowSizeChange={
              (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadBlanksData)
            }
            rowSelection={rowSelection}
            onRow={(blank) => {
              const enableClick = (blank && blank.passport === passportId);
              return !enableMultiselectBlanksForSplit && enableClick
                ? {
                  onClick: this.onRowClick(blank),
                }
                : {};
            }}
            rowClassName={(record) => `passport-setpoints__blanks-row ${rowSelectionInCompareModeClassName(record)}`}
          />
          <ExportBottom
            count={blanksData && blanksData.length}
            size='small'
            WSData={{
              url: '/v1/blanks/',
              params: { passport: passportId, ordering: 'startup_date' },
              accept: 'application/xlsx',
            }}
          />
        </CustomCard>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  blanksData: state.blanks.blanks,
  count: state.blanks.count,
  selectedBlank: state.blanks.activeBlank,
  isBlanksLoading: state.blanks.isBlanksLoading,
  rights: state.rights.rightsData,
  wsState: state.websocket,
});

const mapDispatchToProps = {
  openSidebarData: openSidebar,
  closeSidebarData: closeSidebar,
  getBlanksData: getBlanks,
  selectBlankData: setActiveBlank,
  unselectBlankData: unsetActiveBlank,
  changeBlankVerification: setBlankVerification
};

const enhance = compose(withPagination, withColumnFilters);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      withRouter(
        Blanks
      )
    )
  )
);
