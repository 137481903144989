import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { FileDoneOutlined, RedoOutlined } from '@ant-design/icons';

import moment from 'moment';
import Qs from 'qs';
import CommonTable from '@common/CommonTable';
import { textHighlighter, viewFileSize } from '@globalHelpers';
import {
  DATE_FORMAT, MODE_EDIT, RIGHT_PASSPORT_OSCILLOGRAM, TABLE_SIZE,
} from '@globalConstants';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';
import { CustomEmpty, CustomCard } from '@ui';

import styles from './styles.module.less';

class OscillogramList extends React.Component {
  tableName = 'passport_tab_oscillogramList';

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      list: [],
      isDownloading: false,
      lastDate: undefined,
      count: 0,
    };
  }

  componentDidMount() {
    const { setTableName, deviceCimId } = this.props;
    setTableName(this.tableName, () => deviceCimId && this.loadData());
  }

  componentDidUpdate = async (prevProps) => {
    const { deviceCimId } = this.props;

    if (prevProps.deviceCimId !== deviceCimId && deviceCimId) {
      await this.loadData();
    }
  }

  columns = () => {
    const {
      t, getColumnSearchFilter, getColumnSearchRange, filterValues,
    } = this.props;

    return [
      {
        title: t('PASSPORT_OSCILLOGRAM_TITLE'),
        dataIndex: 'ftpConnectionTitle',
        key: 'ftpConnectionTitle',
        width: '10%',
        ...getColumnSearchFilter('ftpConnectionTitle', 'ftpConnectionTitle', null, this.handleResetCallback),
        render: (text) => {
          return text ? textHighlighter(filterValues['ftpConnectionTitle'], text) : t('PASSPORT_OSCILLOGRAM_WITHOUT_VALUE');
        },
      },
      {
        title: t('PASSPORT_OSCILLOGRAM_PATH'),
        dataIndex: 'path',
        key: 'path',
        width: '60%',
        ...getColumnSearchFilter('path', 'path', null, this.handleResetCallback),
        render: (text) => {
          return text ? textHighlighter(filterValues['path'], text) : t('PASSPORT_OSCILLOGRAM_WITHOUT_VALUE');
        },
      },
      {
        title: t('PASSPORT_OSCILLOGRAM_TIME'),
        dataIndex: 'pathTimeStamp',
        key: 'pathTimeStamp',
        width: '13%',
        ...getColumnSearchRange('pathTimeStamp', 'pathTimeStamp', null, this.handleResetCallback),
        render: (text) => {
          const textDate = text && moment(text).format('DD.MM.YYYY');
          return text ? textDate : t('PASSPORT_OSCILLOGRAM_WITHOUT_VALUE');
        },
      },
      {
        title: t('PASSPORT_OSCILLOGRAM_SIZE'),
        dataIndex: 'fileSize',
        key: 'fileSize',
        render: (text) => {
          if (!text) {
            return t('PASSPORT_OSCILLOGRAM_WITHOUT_VALUE');
          }
          return viewFileSize(text);
        },
      },
    ];
  };

  loadData = async (addParams = {}, callback) => {
    const {
      deviceCimId, loadByPage, filterValues,
    } = this.props;

    const newFilterValues = { ...filterValues };
    if (
      'pathTimeStamp' in newFilterValues
      && newFilterValues.pathTimeStamp
      && Array.isArray(newFilterValues.pathTimeStamp)
      && newFilterValues.pathTimeStamp.length === 2
    ) {
      newFilterValues.path_strtime_stamp_start = (
        filterValues['pathTimeStamp'][0]
          ? filterValues['pathTimeStamp'][0].format(DATE_FORMAT).concat(' ', '00:00:00')
          : undefined
      );
      newFilterValues.path_strtime_stamp_end = (
        filterValues['pathTimeStamp'][1]
          ? filterValues['pathTimeStamp'][1].format(DATE_FORMAT).concat(' ', '23:59:59')
          : undefined
      );
    }

    'pathTimeStamp' in newFilterValues && delete newFilterValues.pathTimeStamp;

    const requestParams = {
      cim_id: deviceCimId,
      cache: true,
      ...newFilterValues,
      ...addParams,
    };

    this.setState({ isLoading: true });
    await loadByPage(this.tableName, 'getDevicesOscillogramList', requestParams, 200, (status, data) => {
      this.setState({
        list: data.data,
        lastDate: moment(data.receipt_date),
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isLoading: false });
  };

  downloadClick = async () => {
    const {
      t, socket, isSocketConnected, selectedItem,
    } = this.props;

    if (
      socket
      && isSocketConnected
      && selectedItem
      && selectedItem.connectionId
      && selectedItem.path
    ) {
      const params = {
        connection_id: selectedItem.connectionId,
        path: selectedItem.path,
      };

      const request = `/v1/oscillogram/get_ftp_file/?${Qs.stringify(params, { arrayFormat: 'repeat', encode: false })}`;

      await socket.send(JSON.stringify({
        message_type: 'export',
        title: t('PASSPORT_OSCILLOGRAM_DOWNLOAD_DESCRIPTION_EXPORT'),
        url: request,
        accept: 'application/.aura',
      }));
    }
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      t, changePage, onShowSizeChange, limit, page,
      onSelectItem, selectedItem, rights, isArchived,
    } = this.props;
    const {
      isLoading, list, isDownloading, count, lastDate,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_OSCILLOGRAM] === MODE_EDIT && !isArchived;

    return (
      <div className={styles.setpointMap}>
        <CustomCard
          style={{ margin: '5px' }}
          title={`
            ${t('OSCILLOGRAM')}
            ${
            lastDate
              ? `(${t('PASSPORT_OSCILLOGRAM_LAST_UPDATE')}: ${lastDate.format('DD.MM.YYYY HH:mm')})`
              : ''
            }
          `}
          extra={(
            <div>
              <Button
                size='small'
                disabled={!list || list.length === 0 || !selectedItem.id}
                icon={<FileDoneOutlined />}
                onClick={this.downloadClick}
              >
                {t('PASSPORT_OSCILLOGRAM_DOWNLOAD')}
              </Button>
              <Button
                size='small'
                icon={<RedoOutlined />}
                key='3'
                onClick={() => this.loadData({ cache: false })}
                disabled={!isEditRight}
              >
                {t('PASSPORT_OSCILLOGRAM_UPDATE')}
              </Button>
            </div>
          )}
        >
          <CommonTable
            tableName={this.tableName}
            size={TABLE_SIZE}
            loading={isLoading || isDownloading}
            columns={this.columns()}
            dataSource={list}
            rowSelection={{
              selectedRowKeys: [selectedItem.id],
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0]),
            }}
            pagination={{
              onChange: (newPage) => changePage(newPage, this.loadData),
              total: count,
              pageSize: limit,
              current: page,
            }}
            onShowSizeChange={
              (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadData)
            }
            locale={{ emptyText: <CustomEmpty description={t('PASSPORT_OSCILLOGRAM_EMPTY_TEXT')} /> }}
          />
        </CustomCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});

const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps)(
  withTranslation()(
    enhance(
      OscillogramList
    )
  )
);
