import { LIBRARY_GLOBAL, LIBRARY_LOCAL } from '@app/constants';

export const UPLOAD_TYPE_FILE = 'UPLOAD_TYPE_FILE';
export const UPLOAD_TYPE_LINK = 'UPLOAD_TYPE_LINK';

export const DOC_SIDEBAR_DOC = 'DOC_SIDEBAR_DOC';
export const DOC_SIDEBAR_TASK = 'DOC_SIDEBAR_TASK';
export const DOC_SIDEBAR_TASK_CREATE_TASK = 'DOC_SIDEBAR_TASK_CREATE_TASK';

export const PRODUCERS = 'Producers';
export const IMPLEMENTATION_TYPES = 'ImplementationTypes';
export const TRADE_DEVICES = 'TradeDevices';
export const SOFTWARE_VERSION = 'Versions';
export const FUNCTIONS = 'Functions';

export const EMPTY_DOCUMENT = {
  external_links: [],
  deleted_external_links: [],
  name: '',
  document_type: undefined,
  document_status: undefined,
  tags: [],
  library: LIBRARY_LOCAL,
};

export const NO_ADMINISTRATORIA = {
  external_links: [],
  deleted_external_links: [],
  name: '',
  document_type: undefined,
  document_status: undefined,
  tags: [],
  library: LIBRARY_GLOBAL,
};

export const TASK_DOCUMENT = {
  external_links: [],
  deleted_external_links: [],
  name: '',
  document_type: undefined,
  document_status: undefined,
  tags: [],
  library: LIBRARY_GLOBAL,
  is_storage: true,
};
