import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.less';

const CustomEmpty = (props) => {
  const { className, ...restProps } = props;
  const { t } = useTranslation();
  const classes = `${styles.customEmpty} ${className || ''} `;

  return (
    <Empty
      className={classes}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{t('CUSTOM_EMPTY_NO_DATA')}</span>}
      {...restProps}
    />
  );
};

export default CustomEmpty;
