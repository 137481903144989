import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Tag, Popover } from 'antd';

import { outerWidth } from '@app/helpers';
import { MAX_TAGS_CNT } from '@globalConstants';

import styles from './styles.module.less';

class PopoverTags extends Component {
  container = {};

  state = {
    visibleEllipsis: false,
    hiddenElementsCounter: 0,
    hiddenElementsWidth: {},
  };

  componentDidUpdate(prevProps, prevState) {
    const { children, disableChildrenHiding } = this.props;
    const { hiddenElementsCounter } = this.state;

    if (Array.isArray(children) && this.container.children && this.container.children.length > 1) {
      let totalElementsWidth = 0;

      Array.from(this.container.children).forEach((item) => {
        totalElementsWidth += outerWidth(item);
      });

      if (
        this.container.clientWidth < totalElementsWidth &&
        this.container.children.length - prevState.hiddenElementsCounter > 1
      ) {
        const hiddenIndex = this.container.children.length - (1 + prevState.hiddenElementsCounter);
        const elToHide = this.container.children[hiddenIndex];

        if (elToHide) {
          this.setState({
            hiddenElementsCounter: prevState.hiddenElementsCounter + 1,
            hiddenElementsWidth: {
              ...prevState.hiddenElementsWidth,
              [hiddenIndex]: outerWidth(elToHide),
            },
          });

          if (!disableChildrenHiding) elToHide.style.display = 'none';
        }
      } else {
        const showIndex = this.container.children.length - hiddenElementsCounter;
        const elToShowWidth = prevState.hiddenElementsWidth[showIndex];

        if (elToShowWidth) {
          if (this.container.clientWidth > totalElementsWidth + elToShowWidth) {
            this.container.children[showIndex].style.display = 'inline-block';

            this.setState({
              hiddenElementsCounter: prevState.hiddenElementsCounter - 1,
              hiddenElementsWidth: {
                ...prevState.hiddenElementsWidth,
                [showIndex]: undefined,
              },
            });
          }
        }
      }
    }
  }

  handleVisibleChange = (visible) => {
    const { hiddenElementsCounter } = this.state;
    if (hiddenElementsCounter > 0) {
      this.setState({
        visibleEllipsis: visible,
      });
    }
  };

  render() {
    const {
      children,
      size,
      t,
      getPopupContainer,
      popoverPlacement = 'topRight',
      disableChildrenHiding = false,
      ...restProps
    } = this.props;
    const { visibleEllipsis, hiddenElementsCounter } = this.state;

    const popoverProps = {
      overlayClassName: styles.popover,
      content: children,
      placement: `${popoverPlacement}`,
      ...restProps,
    };

    if (size <= MAX_TAGS_CNT) {
      return (
        <Popover
          {...popoverProps}
          visible={visibleEllipsis}
          onVisibleChange={this.handleVisibleChange}
          getPopupContainer={(triggerNode) => (getPopupContainer ? getPopupContainer(triggerNode) : triggerNode)}
        >
          <div
            ref={(node) => {
              if (node) this.container = node;
              return this.container;
            }}
            className={styles.container}
          >
            {children}
            {hiddenElementsCounter > 0 && !disableChildrenHiding && '...'}
          </div>
        </Popover>
      );
    }

    return (
      <Popover
        getPopupContainer={(triggerNode) => (getPopupContainer ? getPopupContainer(triggerNode) : triggerNode)}
        {...popoverProps}
      >
        <Tag>{`${t('ALL')}: ${size}`}</Tag>
      </Popover>
    );
  }
}

export default withTranslation()(PopoverTags);
