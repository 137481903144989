import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MODEL_BLANK, MODEL_TASK, MODEL_TEMPLATE, TEXT_PREFIX,
} from './constants';
import styles from './styles.module.less';

class Details extends PureComponent {

  getTranslation = (value) => {
    const { t } = this.props;

    if (value === null || value === undefined || (value.id && !value.model_name)) {
      return '';
    }

    const result = `${value.model_name || value}`;

    return t(`${TEXT_PREFIX}${result.replace(/\s/g, '_').toUpperCase()}`, result);
  };

  renderInstanceName = (record) => {
    const { deviceId, rights } = this.props;

    let result = record.instance_name;

    const passport = (
      deviceId
      || (
        record.protection_devices
        && record.protection_devices.length
        && record.protection_devices[0]
        && record.protection_devices[0].id
      )
    );

    const isTheLinkAllowed = (rightName) => {
      if (!rightName) return true;
      if (!rights[rightName]) return false;
      return true;
    }

    const renderLink = (to = '', label = '', rightName = null) => {
      if (isTheLinkAllowed(rightName)) {
        return (
          <Link to={to}>
            <span className={styles.bold}>{label}</span>
          </Link>
        );
      } else {
        return <span className={styles.bold}>{label}</span>;
      }
    };

    switch (record.model_name) {
      case MODEL_BLANK:
        result = renderLink(
          `/passport/${passport}/tab/setpoints/?id=${record.instance_id}&name=${record.instance_name}`,
          record.instance_name,
          'RIGHT_PASSPORT_SETPOINTS'
        );
        break;
      case MODEL_TASK:
        result = renderLink(
          `/passport/${passport}/tab/tasks/?id=${record.instance_id}&name=${record.instance_name}`,
          record.instance_name,
          'RIGHT_PASSPORT_TASKS'
        );
        break;
      case MODEL_TEMPLATE:
        result = renderLink(
          `/templates/?id=${record.instance_id}&name=${record.instance_name}`,
          record.instance_name,
          'RIGHT_ROUTE_TEMPLATES'
        );
        break;
      default:
        break;
    }

    return result;
  }

  render() {
    const { record, t } = this.props;

    const renderParents = (x, isMultipleRecords) => (
      <>
        {isMultipleRecords ? <>&nbsp;&mdash;&nbsp;</> : ''}
        <span className={styles.bold}>
          {t(`${TEXT_PREFIX}${x.class_name.toUpperCase()}`)}
          {' '}
          {x.name}
        </span>
        <br />
      </>
    );
    const renderLinkToPassport = (x, isMultipleRecords) => (
        <>
        {isMultipleRecords ? <>&nbsp;&mdash;&nbsp;</> : ''}
          {x.hidden === true ? '' :
              <Link to={`/passport/${x.id}`}>
                <span className={styles.bold}>{x.name}</span>
              </Link>
          }  <br />
      </>
    );

    const renderTextToPassportOver100 = (len) => (
      <div className='ant-alert-warning'>
        &nbsp;&mdash;&nbsp;
        {t('JOURNAL_PROTECTIONDEVICE_OVER_100_1')}
        {len - 100}
        {t('JOURNAL_PROTECTIONDEVICE_OVER_100_2')}
        <br />
      </div>
    );

    return (
      <span className={`${styles.tableText} ${styles.small}`} key={record.id || toString(Math.random())}>
        {record.protection_devices
        && record.protection_devices.length > 0
        && (
          <>
            {record.protection_devices.length > 1 ? t('JOURNAL_PROTECTIONDEVICE').concat(': ') : ''}
            {record.protection_devices.length > 1 ? <br /> : ''}
            {record.protection_devices.map((x) => renderLinkToPassport(x, record.protection_devices.length > 1))}
            {
              record.number_of_protection_devices
              && record.number_of_protection_devices > 100
                ? renderTextToPassportOver100(record.number_of_protection_devices)
                : ''
            }
          </>
        )}
        {record.instance_name
        && (
          <>
            {t('JOURNAL_INSTANCE').concat(': ')}
            <span className={styles.bold}>
              {this.renderInstanceName(record)}
            </span>
            {record.action === 'REVERTED'
            && (
              <>
                {'. '}
                {t('REVERTED')}
              </>
            )}
            <br />
          </>
        )}
        {record.parents
        && record.parents.length > 0
        && (
          <>
            {t('JOURNAL_PARENT_INSTANCE').concat(': ')}
            {record.parents.length > 1 ? <br /> : ''}
            {record.parents.map((x) => renderParents(x, record.parents.length > 1))}
          </>
        )}
        {record.changed_fields && Object.keys(record.changed_fields).map((item) => {
          const { new_value, prev_value, verbose_name } = record.changed_fields[item];
          const newValue = this.getTranslation(
            (new_value && (typeof new_value === 'object' && new_value.name))
            ? new_value.name
            : new_value
          );
          const prevValue = this.getTranslation(
            (prev_value && (typeof prev_value === 'object' && prev_value.name))
            ? prev_value.name
            : prev_value
          );
          const attrName = this.getTranslation(verbose_name);

          if (new_value === null) {
            return (
              <div key={`info_${item}`}>
                {t('JOURNAL_ATTRIBUTE')}
                {' '}
                <span className={styles.bold}>{attrName}</span>
                {' '}
                {t('JOURNAL_WITH_VALUE')}
                {' '}
                <span className={styles.bold}>{`${prevValue}`}</span>
                {' '}
                {t('JOURNAL_DELETED_VALUE')}
              </div>
            );
          } else if (item === 'is_deleted' && !!new_value) {
            return (
              <div key={`info_${item}`}>
                {t('JOURNAL_OBJECT_DELETED')}
              </div>
            );
          } else if (prev_value === null) {
            const isNewVal = !!newValue;

            return (
              <div key={`info_${item}`}>
                {t('JOURNAL_ATTRIBUTE')}
                {' '}
                <span className={styles.bold}>{attrName}</span>
                {isNewVal && (` ${t('JOURNAL_WITH_VALUE')} `)}
                {isNewVal && (<span className={styles.bold}>{`${newValue}`}</span>)}
                {' '}
                {t('JOURNAL_ADDED')}
              </div>
            );
          } else {
            return (
              <div key={`info_${item}`}>
                {t('JOURNAL_ATTRIBUTE')}
                {' '}
                <span className={styles.bold}>{attrName}</span>
                :
                {' '}
                {t('JOURNAL_VALUE')}
                {' '}
                <span className={styles.bold}>{`${prevValue}`}</span>
                {' '}
                {t('JOURNAL_CHANGED_VALUE')}
                {' '}
                <span className={styles.bold}>{`${newValue}`}</span>
              </div>
            );
          }
        })}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Details
  )
);