import { TaskStatus, LetterTasksDispatcherCenterItem } from '@services/api-dts';
import moment, { Moment } from 'moment';

export type SortDirections = 'asc' | 'desc';
export type SortableFields = 'status' | 'date' | 'name';
export type CompareTaskFieldType = string | TaskStatus | undefined | null;

/**Аргументы для функции сортировки писем */
export type SortTasksArgs = {
  /**Массив писем для сортировки */
  items: LetterTasksDispatcherCenterItem[];
  /**Имя поля, по которому будет проходить сортировка */
  fieldname: SortableFields;
  /**Формат даты, если сортировка по полю даты */
  currentDateFormat?: string;
  /**Направление сортировки asc - по возрастанию, desc - по убыванию */
  direction?: SortDirections;
};

/**Сортирует письма */
export const sortTasks = ({ items, fieldname, currentDateFormat = 'YYYY-MM-DD', direction = 'asc' }: SortTasksArgs) => {
  if (items.length === 0) return [];

  const doubleItems = [...items];
  const isReverse = direction !== 'asc';

  doubleItems.sort((a, b) => compareTasks(currentDateFormat, fieldname, a[fieldname], b[fieldname]));
  if (isReverse) doubleItems.reverse();
  return doubleItems;
};

/**Сравнивает значения */
const compareTasks = (
  currentDateFormat: string,
  fieldname: SortableFields,
  a?: CompareTaskFieldType,
  b?: CompareTaskFieldType
) => {
  const checkForEmptiesResult = checkForEmpties(a, b);
  if (checkForEmptiesResult !== 0) return checkForEmptiesResult;

  switch (fieldname) {
    case 'date':
      return compareDates(a as string, b as string, currentDateFormat);
    case 'name':
      return compareText(a as string, b as string);
    case 'status':
      return compareStatuses(a as TaskStatus, b as TaskStatus)
  }
  return 0;
};

/**Сравнивает две даты по возрастанию */
const compareDates = (a: string, b: string, currentDateFormat: string) => {
  const momentA = moment(a, currentDateFormat);
  const momentB = moment(b, currentDateFormat);
  const checkforInvalidDatesResult = checkforInvalidDates(momentA, momentB);
  if (checkforInvalidDatesResult !== 0) return checkforInvalidDatesResult;

  return momentA.diff(momentB);
};

/**Сравнивает текст по возрастанию*/
const compareText = (a: string, b: string) => {
  return a.localeCompare(b);
};

const compareStatuses = (a: TaskStatus, b: TaskStatus) => {
  const _a = a.current_status.name || '';
  const _b = b.current_status.name || '';

  return _a.localeCompare(_b);
}

//**Проверяет значения сравниваемых строк на пустоту */
const checkForEmpties = (a: CompareTaskFieldType, b: CompareTaskFieldType) => {
  if (!a) return -1;
  if (!b) return 1;
  return 0;
};

//**Проверяет даты на корректность */
const checkforInvalidDates = (a: Moment, b: Moment) => {
  if (!a.isValid()) return -1;
  if (!b.isValid()) return 1;
  return 0;
};
