import React from 'react';
import moment, { Moment } from 'moment';
import { CheckCircleTwoTone, ClockCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';

import DateSpot from './Spot';
import type { Data } from '../types';

type Props = {
  dateTo: Moment;
  dateFrom: Moment;
  record: Data;
  offsetX?: number;
  className?: string;
}

const SpotsOutside = ({
  dateFrom,
  dateTo,
  record,
  offsetX = 0,
  className = '',
}: Props) => {
  const aLeftDates = [];
  const aRightDates = [];

  if (record.date_planned_start && moment(record.date_planned_start).isBefore(dateFrom, 'day')) {
    aLeftDates.push({
      date: record.date_planned_start,
      type: ClockCircleTwoTone,
      key: `left_${record.date_planned_start}_date_planned_start_${record.id}`,
    });
  }
  if (record.date_planned_completion && moment(record.date_planned_completion).isBefore(dateFrom, 'day')) {
    aLeftDates.push({
      date: record.date_planned_completion,
      type: ClockCircleTwoTone,
      key: `left_${record.date_planned_completion}_date_planned_completion_${record.id}`,
    });
  }
  if (record.date_actual_start && moment(record.date_actual_start).isBefore(dateFrom, 'day')) {
    aLeftDates.push({
      date: record.date_actual_start,
      type: RightCircleTwoTone,
      key: `left_${record.date_actual_start}_date_actual_start_${record.id}`,
    });
  }
  if (record.date_actual_completion && moment(record.date_actual_completion).isBefore(dateFrom, 'day')) {
    aLeftDates.push({
      date: record.date_actual_completion,
      type: CheckCircleTwoTone,
      key: `left_${record.date_actual_completion}_date_actual_completion_${record.id}`,
    });
  }

  if (record.date_planned_start && moment(record.date_planned_start).isAfter(dateTo, 'day')) {
    aRightDates.push({
      date: record.date_planned_start,
      type: ClockCircleTwoTone,
      key: `right_${record.date_planned_start}_date_planned_start_${record.id}`,
    });
  }
  if (record.date_planned_completion && moment(record.date_planned_completion).isAfter(dateTo, 'day')) {
    aRightDates.push({
      date: record.date_planned_completion,
      type: ClockCircleTwoTone,
      key: `right_${record.date_planned_completion}_date_planned_completion_${record.id}`,
    });
  }
  if (record.date_actual_start && moment(record.date_actual_start).isAfter(dateTo, 'day')) {
    aRightDates.push({
      date: record.date_actual_start,
      type: RightCircleTwoTone,
      key: `right_${record.date_actual_start}_date_actual_start_${record.id}`,
    });
  }
  if (record.date_actual_completion && moment(record.date_actual_completion).isAfter(dateTo, 'day')) {
    aRightDates.push({
      date: record.date_actual_completion,
      type: CheckCircleTwoTone,
      key: `right_${record.date_actual_completion}_date_actual_completion_${record.id}`,
    });
  }

  const daysPeriodCnt = moment(dateTo).diff(dateFrom, 'days');

  return (
    <>
      {aLeftDates.map(({ date, type, key }, index) => (
        <DateSpot
          className={className}
          key={key}
          spotNumber={index + 1}
          type={type}
          left={0}
          date={date}
          tooltipDate={date}
          offsetX={-1 * offsetX}
        />
      ))}
      {aRightDates.map(({ date, type, key }, index) => (
        <DateSpot
          className={className}
          key={key}
          spotNumber={index + 1}
          type={type}
          left={daysPeriodCnt + 2}
          date={date}
          tooltipDate={date}
          offsetX={offsetX}
        />
      ))}
    </>
  );
};

export default SpotsOutside;
