import { CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { memo } from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { assembleTitle, assembleTargetLink } from './helpers';

const EntityLinkTag = memo(({ entity, entityNameSingle, t, link, currentDeviceId, isAllowed = false, record }) => {
    const { protection_device, id, name, is_active } = entity;

    const title = assembleTitle({ protection_device: protection_device, currentDeviceId, t: t });
    const tagClassname = isAllowed ? 'link' : '';

    const tag = (
        <Tag className={tagClassname} key={id} title={title}>
            {is_active && <CheckCircleOutlined className='active' title={t('CURRENT_ACTIVE_BLANK')} />}
            {' '}
            {name}
        </Tag>
    );

    if (!isAllowed) return tag;

    const targetLink = assembleTargetLink({ link, protection_device, entity, entityNameSingle, record });

    return (
        <Link key={`link_${record.id}_${entity.id}`} to={targetLink}>
            {tag}
        </Link>
    );
})


export default withTranslation()(EntityLinkTag)