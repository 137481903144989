import React from 'react';
import {
  Select,
} from 'antd';

import { CustomSelect } from '@ui';

import styles from './styles.module.less';
import type { Item } from './types';

type Props = {
  isDataLoading?: boolean;
  t: Function;
  aStatuses: Item[];
  isLoading?: boolean;
  handleCheckStatus: Function;
  callback: Function;
};

const SelectDC = ({
  isDataLoading, t, aStatuses, isLoading, handleCheckStatus, callback,
}: Props) => {
  return (
    <CustomSelect
      mode='multiple'
      className={styles.statuses}
      size='small'
      placeholder={t('SELECT_STATUS')}
      onDropdownVisibleChange={(open: boolean) => callback(open)}
      onChange={handleCheckStatus}
      disabled={isDataLoading}
      loading={isLoading}
      maxTagCount={3}
      popoverPlacement='left'
    >
      {aStatuses && aStatuses.map((status) => (
        <Select.Option key={status.id} value={status.id}>{status.name}</Select.Option>
      ))}
    </CustomSelect>
  );
};

export default SelectDC;
