import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import {
  Form, Button, message,
} from 'antd';
import isEqual from 'lodash/isEqual';

import PrivateRoute from '@common/privateRoute';
import api from '@services/api';
import {
  INPUT_TYPE_PASSWORD,
  INPUT_TYPE_NUMBER,
} from '@ui/formItem/constants';
import { isInteger, moreOrEqual } from '@globalHelpers';

import { CustomCard, FormItem } from '@ui';
import { MAP_RIGHTS_TO_PATHS, ROUTE_ROLES, MODE_EDIT } from '@globalConstants';

import {
  AIP_TAB,
  SNMP_TAB,
  LANDOCS,
  SMTP,
  AIP_TEST_TAB,
} from './constants';

import styles from './styles.module.less';

class Integrations extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isSyncing: false,
      isIntegrationsLoading: false,
      isTestAipSyncButtonEnabled: false,
      isUpdating: false,
      integrations: {},
      isFormChanged: {
        AIP_TAB: false,
        AIP_TEST: false,
        SNMP_TAB: false,
        LANDOCS: false,
        SMTP: false,
      },
      formHasError: {
        AIP_TAB: false,
        AIP_TEST: false,
        SNMP_TAB: false,
        LANDOCS: false,
        SMTP: false,
      },
    };
  }

  componentDidMount() {
    this.loadIntegrations();
  }

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props;

    if (prevProps.activeTab !== activeTab) this.handleCancel();
  }

  loadIntegrations = async () => {
    this.setState({ isIntegrationsLoading: true });
    const { data, status } = await api.getIntegrations();
    this.setState({ isIntegrationsLoading: false });

    if (status === 200) {
      this.setState({
        integrations: data,
      });
    }
  };

  onSyncHandle = async () => {
    const { t } = this.props;

    this.setState({ isSyncing: true });

    try {
      const response = await api.startAipIntegration();

      if (response.status === 200) {
        message.success(t('SYNC_SUCCESS'));
      } else {
        message.error(t('SYNC_ERROR'));
      }
    } catch (e) {
      message.error(t('SYNC_ERROR'));
    }

    this.setState({ isSyncing: false });
  };

  onTestSyncHandle = async () => {
    const { t } = this.props;

    this.setState({ isSyncing: true });

    const values = this.form.current.getFieldsValue();

    const params = values;

    try {
      const response = await api.startAipTestIntegration(params);

      if (response.status === 200) {
        message.success(t('SYNC_SUCCESS'));
      } else {
        message.error(t('SYNC_ERROR'));
      }
    } catch (e) {
      message.error(t('SYNC_ERROR'));
    }

    this.setState({ isSyncing: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { activeTab, t } = this.props;
    const { integrations } = this.state;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ isUpdating: true });

      if (activeTab === (AIP_TAB) && !(+values.ttl_passport)) {
        // eslint-disable-next-line no-param-reassign
        delete values.ttl_passport;

        // eslint-disable-next-line no-param-reassign
        values = {
          ...integrations.AIP,
          ...values,
        };
      }

      if (activeTab === (AIP_TEST_TAB) && !(+values.ttl_passport)) {
        // eslint-disable-next-line no-param-reassign
        delete values.ttl_passport;

        // eslint-disable-next-line no-param-reassign
        values = {
          ...integrations.AIP_TEST,
          ...values,
        };
      }

      const { status } = await api.saveIntegrations({
        ...integrations,
        [activeTab]: { ...values },
      });

      if (status === 201 || status === 200) {
        message.success(t('SUCCESS_SAVE'));

        this.setState({ isFormChanged: { [activeTab]: false } });
        await this.loadIntegrations();
      } else {
        message.error(t('ERROR_SAVE'));
      }

      this.setState({ isUpdating: false });
    }).catch(({ errorFields }) => this.form.current.scrollToField(errorFields[0].name));
  };

  handleCancel = () => {
    const { activeTab } = this.props;

    this.form.current.resetFields();
    this.setState({ isFormChanged: { [activeTab]: false } });
  };

  changeFormValues = () => {
    const { activeTab } = this.props;
    const { integrations } = this.state;
    let isFormChanged = false;

    if (Object.keys(integrations).length) {
      const updatedValues = this.form.current.getFieldsValue();
      const updatedIntegrations = { ...integrations[activeTab] };

      if (!updatedIntegrations.ttl_passport && updatedValues.ttl_passport) {
        updatedIntegrations.ttl_passport = 0;
      }

      if (!updatedIntegrations.ttl_passport && !updatedValues.ttl_passport) {
        updatedIntegrations.ttl_passport = updatedValues.ttl_passport; // 0 || ""
      }

      isFormChanged = !isEqual(updatedValues, updatedIntegrations);
    }

    this.form.current.validateFields().then(() => { // to update form values
      this.setState({
        isFormChanged: { [activeTab]: isFormChanged },
        formHasError: { [activeTab]: false }
      });
    }).catch((errorInfo) => {
      if (errorInfo && errorInfo.errorFields.length) {
        this.setState({
          isFormChanged: { [activeTab]: isFormChanged },
          formHasError: { [activeTab]: true },
        });
      }
    });

  };

  render() {
    const {
      isIntegrationsLoading,
      isUpdating,
      isFormChanged,
      integrations,
      formHasError,
      isSyncing,
    } = this.state;
    const {
      offsetTop,
      t,
      rights,
      activeTab,
    } = this.props;

    const aipData = integrations.AIP || { Credentials: {} };
    const aipTestData = integrations?.AIP_TEST;
    const snmpData = integrations.SNMP || {};
    const landocsData = integrations.LANDOCS || {};
    const smtpData = integrations.SMTP || {};
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_ROLES]] !== MODE_EDIT || isUpdating;
    const disableForAip = activeTab === AIP_TAB;
    const isTestAipSyncButtonEnabled = activeTab === AIP_TEST_TAB && aipTestData && !formHasError?.AIP_TEST;


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    return (
      <CustomCard
        title={null}
        className={styles.servicesForm}
        loading={isIntegrationsLoading}
        actions={[
          <Button
            disabled={!isFormChanged[activeTab] || isReadOnly || disableForAip}
            className='bordered'
            onClick={this.handleCancel}
          >
            {t('CANCEL')}
          </Button>,
          <Button
            type='primary'
            disabled={!isFormChanged[activeTab] || isReadOnly || formHasError[activeTab] || disableForAip}
            onClick={this.handleSubmit}
            loading={isUpdating}
          >
            {t('SAVE')}
          </Button>,
        ]}
        style={{ minHeight: `calc(100vh - ${offsetTop}px)` }}
      >
        <Form
          {...formItemLayout}
          name='integrations'
          ref={this.form}
          onChange={this.changeFormValues}
          labelAlign='left'
          initialValues={{
            SyncUrl: aipData.SyncUrl,
            // BaseUrl: aipData.BaseUrl,
            // Credentials: aipData.Credentials,
            // Model: aipData.Model,
            // Version: aipData.Version,
            // Interval: aipData.Interval,
            ttl_passport: aipData.ttl_passport,
            CtrlAddress: snmpData.CtrlAddress,
            CtrlPort: snmpData.CtrlPort,
            Community: snmpData.Community,
            landocs_url: landocsData.landocs_url,
            HOST: smtpData.HOST,
            PORT: smtpData.PORT,
            USER: smtpData.USER,
            PASSWORD: smtpData.PASSWORD,
            DEFAULT: smtpData.DEFAULT,
            PREFIX: smtpData.PREFIX,
            USE_SSL: smtpData.USE_SSL,
            USE_TLS: smtpData.USE_TLS,
            FILE_CHARSET: smtpData.FILE_CHARSET,
            informationalModelUid: aipTestData?.informationalModelUid,
            modelVersionId: aipTestData?.modelVersionId
          }}
        >
          <Switch>
            <PrivateRoute
              key={AIP_TAB}
              path='/aip/tab/aip'
              render={() => {
                return (
                  <>
                    <FormItem
                      id='SyncUrl'
                      label={t('ADDRESS')}
                      form={this.form.current}
                      initialValue={aipData.SyncUrl}
                      isReadOnly={isReadOnly || disableForAip}
                      placeholder='http(s)://<DNS-or-IP>[:PortNum]'
                      highlight
                      rules={[
                        {
                          type: 'url',
                          message: t('ERROR_TYPE_URL'),
                        },
                      ]}
                    />
                    
                    <Button
                      type='primary'
                      loading={isSyncing}
                      onClick={this.onSyncHandle}
                    >
                      {t('START_SYNC')}
                    </Button>
                    {/* DBRZA-2641 */}
                    {/* <FormItem */}
                    {/*  id='BaseUrl' */}
                    {/*  label={t('ADDRESS')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.BaseUrl} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  placeholder='http(s)://<DNS-or-IP>[:PortNum]' */}
                    {/*  highlight */}
                    {/*  rules={[ */}
                    {/*    { */}
                    {/*      type: 'url', */}
                    {/*      message: t('ERROR_TYPE_URL'), */}
                    {/*    }, */}
                    {/*  ]} */}
                    {/* /> */}
                    {/* <FormItem */}
                    {/*  id={['Credentials', 'UserName']} */}
                    {/*  label={t('USERNAME')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.Credentials.UserName} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  highlight */}
                    {/* /> */}
                    {/* <FormItem */}
                    {/*  id={['Credentials', 'Password']} */}
                    {/*  label={t('PASSWORD')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.Credentials.Password} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  FormItemType={INPUT_TYPE_PASSWORD} */}
                    {/*  highlight */}
                    {/* /> */}
                    {/* <FormItem */}
                    {/*  id={['Credentials', 'Domain']} */}
                    {/*  label={t('DOMAIN')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.Credentials.Domain} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  highlight */}
                    {/* /> */}
                    {/* <FormItem */}
                    {/*  id='Model' */}
                    {/*  label={t('MODEL')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.Model} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  highlight */}
                    {/* /> */}
                    {/* <FormItem */}
                    {/*  id='Version' */}
                    {/*  label={t('VERSION')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.Version} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  highlight */}
                    {/* /> */}
                    {/* <FormItem */}
                    {/*  id='Interval' */}
                    {/*  label={t('INTERVAL')} */}
                    {/*  form={this.form.current} */}
                    {/*  initialValue={aipData.Interval} */}
                    {/*  isReadOnly={isReadOnly} */}
                    {/*  inputStyle={{ width: 80 }} */}
                    {/*  highlight */}
                    {/*  rules={[ */}
                    {/*    { */}
                    {/*      pattern: new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm), */}
                    {/*      message: `${t('ERROR_TIME_FORMAT')} 00:00`, */}
                    {/*    }, */}
                    {/*  ]} */}
                    {/* /> */}
                  </>
                );
              }}
            />
            <PrivateRoute
              key={AIP_TEST_TAB}
              path='/aip/tab/aip_test'
              render={() => {
                return (
                  <>
                  <FormItem
                      id='informationalModelUid'
                      label={t('AIP_INFORMATION_MODEL')} 
                      form={this.form.current} 
                      isReadOnly={isReadOnly} 
                      initialValue={aipTestData?.informationalModelUid || ''}
                      highlight 
                      rules = {[
                        {
                          type: 'string',
                          required: true,
                          message: `Требуется заполнить поле ${t('AIP_INFORMATION_MODEL')}`
                        }
                      ]}
                     /> 
                     <FormItem 
                      id='modelVersionId' 
                      label={t('VERSION')} 
                      form={this.form.current}  
                      isReadOnly={isReadOnly} 
                      initialValue={aipTestData?.modelVersionId || ''}
                      highlight
                      rules={[
                        isInteger,
                        () => moreOrEqual(0),
                        {
                          required: true,
                          message: `Требуется заполнить поле ${t('VERSION')}`
                        },
                      ]}
                    />
                    <Button
                      type='primary'
                      loading={isSyncing}
                      onClick={this.onTestSyncHandle}
                      disabled={!isTestAipSyncButtonEnabled}
                    >
                      {t('START_SYNC')}
                    </Button>
                  </>
                );
              }}
            />
            <PrivateRoute
              key={SNMP_TAB}
              path='/aip/tab/snmp'
              render={() => {
                return (
                  <>
                    <FormItem
                      id='CtrlAddress'
                      label={t('SNMP_ADDRESS')}
                      form={this.form.current}
                      initialValue={snmpData.CtrlAddress}
                      isReadOnly={isReadOnly}
                      placeholder='<DNS-or-IP>'
                      highlight
                      rules={[
                        {
                          type: 'string',
                          message: t('ERROR_TYPE_IP'),
                        },
                      ]}
                    />
                    <FormItem
                      id='CtrlPort'
                      label={t('SNMP_PORT')}
                      form={this.form.current}
                      inputStyle={{ width: '100%' }}
                      initialValue={snmpData.CtrlPort}
                      isReadOnly={isReadOnly}
                      highlight
                      FormItemType={INPUT_TYPE_NUMBER}
                      min={0}
                      formChangeHandle={this.changeFormValues}
                      rules={[
                        isInteger,
                        () => moreOrEqual(0),
                      ]}
                    />
                    <FormItem
                      id='Community'
                      label={t('SNMP_COMMUNITY')}
                      form={this.form.current}
                      initialValue={snmpData.Community}
                      isReadOnly={isReadOnly}
                      highlight
                    />
                  </>
                );
              }}
            />
            <PrivateRoute
              key={LANDOCS}
              path='/aip/tab/landocs'
              render={() => {
                return (
                  <FormItem
                    id='landocs_url'
                    label='URL'
                    form={this.form.current}
                    initialValue={landocsData.landocs_url}
                    isReadOnly={isReadOnly}
                    placeholder='http(s)://<DNS-or-IP>[:PortNum]'
                    highlight
                    rules={[
                      {
                        type: 'url',
                        message: t('ERROR_TYPE_URL'),
                      },
                    ]}
                  />
                );
              }}
            />
            <PrivateRoute
              key={SMTP}
              path='/aip/tab/smtp'
              render={() => {
                return (
                  <>
                    <FormItem
                      id='HOST'
                      label={t('HOST')}
                      form={this.form.current}
                      initialValue={smtpData.HOST}
                      isReadOnly={isReadOnly}
                      placeholder='http(s)://<DNS-or-IP>'
                      highlight
                    />
                    <FormItem
                      id='PORT'
                      label={t('PORT')}
                      form={this.form.current}
                      initialValue={smtpData.PORT}
                      isReadOnly={isReadOnly}
                      rules={[
                        isInteger,
                        () => moreOrEqual(0),
                        {
                          required: false,
                        },
                      ]}
                      highlight
                    />
                    <FormItem
                      id='USER'
                      label={t('USERNAME')}
                      form={this.form.current}
                      initialValue={smtpData.USER}
                      isReadOnly={isReadOnly}
                      highlight
                    />
                    <FormItem
                      id='PASSWORD'
                      label={t('PASSWORD')}
                      form={this.form.current}
                      initialValue={smtpData.PASSWORD}
                      FormItemType={INPUT_TYPE_PASSWORD}
                      isReadOnly={isReadOnly}
                      highlight
                    />
                    <FormItem
                      id='USE_SSL'
                      label={t('USE_SSL')}
                      form={this.form.current}
                      initialValue={smtpData.USE_SSL}
                      checked={smtpData.USE_SSL}
                      isReadOnly={isReadOnly}
                      FormItemType='checkbox'
                      highlight
                    />
                    <FormItem
                      id='USE_TLS'
                      label={t('USE_TLS')}
                      form={this.form.current}
                      initialValue={smtpData.USE_TLS}
                      checked={smtpData.USE_TLS}
                      isReadOnly={isReadOnly}
                      FormItemType='checkbox'
                      highlight
                    />
                    <FormItem
                      id='FILE_CHARSET'
                      label={t('FILE_CHARSET')}
                      form={this.form.current}
                      initialValue={smtpData.FILE_CHARSET}
                      isReadOnly={isReadOnly}
                      highlight
                    />
                    <FormItem
                      id='DEFAULT'
                      label={t('DEFAULT')}
                      form={this.form.current}
                      initialValue={smtpData.DEFAULT}
                      isReadOnly={isReadOnly}
                      highlight
                    />
                    <FormItem
                      id='PREFIX'
                      label={t('PREFIX')}
                      form={this.form.current}
                      initialValue={smtpData.PREFIX}
                      isReadOnly={isReadOnly}
                      highlight
                    />
                  </>
                );
              }}
            />
          </Switch>
        </Form>
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Integrations
  )
);
