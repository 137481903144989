import React from 'react';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ColumnSearch = (props) => {
  const { t } = useTranslation();
  const {
    setRef, selectedKeys, setSelectedKeys, handleSearch, confirm, dataIndex, handleReset,
  } = props;

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={setRef}
        placeholder={t('SEARCH')}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type='primary'
        onClick={handleSearch}
        icon={<SearchOutlined />}
        size='small'
        style={{ width: 90, marginRight: 8 }}
      >
        {t('TO_SEARCH')}
      </Button>
      <Button onClick={handleReset} size='small' style={{ width: 90 }}>
        {t('CANCEL')}
      </Button>
    </div>
  );
};

export default ColumnSearch;
