import React from 'react';
import {
  Card, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { withTranslation } from 'react-i18next';

import store from '@state/store';
import { loadMoreNotifications, clearNotifications, deleteNotification } from '@state/notifications/actions';
import { CloseOutlined } from '@ant-design/icons';
import { notificationText } from '@globalHelpers';
import { InfiniteScroll } from '@ui';

import styles from './styles.module.less';

const LOAD_LIMIT = 10;

class Notifications extends React.Component {
  state = {
    loading: false,
    offset: 1,
  };

  componentDidMount() {
    this.loadNotifications(LOAD_LIMIT);
  }

  componentWillUnmount() {
    store.dispatch(clearNotifications());
  }

  getCard(item) {
    const { t } = this.props;

    const date = new Date(item.changed_date);
    const notification = notificationText(item);
    const hasPassports = item.details.passports && item.details.passports.length > 0;
    const hasMultiplePassports = item.details.passports && item.details.passports.length > 1;
    const task = item.details.task_dc;

    const getLink = (protectionDeviceId) => (
      `/passport/${protectionDeviceId}`.concat(
        (!task || task.is_deleted)
          ? ''
          : ''.concat(
            '/tab/tasks/',
            `?id=${task.id}`,
            `&name=${task.name}`
          )
      )
    );

    const renderLink = (protectionDevice, isMultipleRecords) => (
      <>
        {isMultipleRecords ? <>&nbsp;&mdash;&nbsp;</> : ''}
        <Link to={getLink(protectionDevice.id)} className={styles.headerButton}>
          <span className={styles.bold}>{protectionDevice.name}</span>
        </Link>
        <br />
      </>
    );

    return (
      <Card
        bordered={false}
        key={item.id}
        title={item.title}
        className={item.read ? styles.item : [styles.item, styles.unread]}
      >
        <CloseOutlined
          className={styles.closeNotification}
          onClick={(e) => this.deleteNotificationItem(e, item.id)}
        />
        {hasPassports
        && (
          <>
            {hasMultiplePassports ? t('NOTIFICATIONS_DEVICE').concat(': ') : ''}
            {hasMultiplePassports ? <br /> : ''}
            {item.details.passports.map((passport) => renderLink(passport.protection_device, hasMultiplePassports))}
          </>
        )}
        <div className={styles.content}>{notification}</div>
        <div className={styles.content}>
          {date.toLocaleDateString()}
          {', '}
          {date.toLocaleTimeString()}
        </div>
      </Card>
    );
  }

  renderTopTitle = () => {
    const { t, notifications } = this.props;
    const today = new Date();
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

    if (notifications.length) {
      if (this.isSameDay(notifications[0], today)) return t('NOTIFICATIONS_TODAY');

      if (this.isSameDay(notifications[0], yesterday)) return t('NOTIFICATIONS_YESTERDAY');

      if (!this.isSameDay(notifications[0], today) && !this.isSameDay(notifications[0], yesterday)) {
        return t('NOTIFICATIONS_OLDER');
      }
    }

    return '';
  };

  renderNotificationsWithTitles = () => {
    const { t, notifications } = this.props;
    const today = new Date();
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

    const notificationsWithTitles = cloneDeep(notifications);
    let amountOfTitles = 0;

    for (let i = 0; i < notifications.length; i += 1) {
      if (this.isSameDay(notifications[i], today) && this.isSameDay(notifications[i + 1], yesterday)) {
        notificationsWithTitles.splice(i + 1, 0, t('NOTIFICATIONS_YESTERDAY'));
        amountOfTitles += 1;
      }

      if ((this.isSameDay(notifications[i], today) || this.isSameDay(notifications[i], yesterday))
        && notifications[i + 1]
        && !this.isSameDay(notifications[i + 1], today)
        && !this.isSameDay(notifications[i + 1], yesterday)) {
        notificationsWithTitles.splice(i + 1 + amountOfTitles, 0, t('NOTIFICATIONS_OLDER'));
        amountOfTitles += 1;
      }
    }

    return notificationsWithTitles;
  };

  deleteNotificationItem = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ loading: true });
    await store.dispatch(deleteNotification(id));
    this.setState({ loading: false });
  };

  loadNotifications = async (limit, offset) => {
    this.setState({ loading: true });
    await store.dispatch(loadMoreNotifications(limit, offset));
    this.setState({ loading: false });
  };

  loadMoreHandle = () => {
    const { notificationsCount } = this.props;
    const { offset } = this.state;

    if (notificationsCount > LOAD_LIMIT * offset) {
      this.loadNotifications(LOAD_LIMIT, LOAD_LIMIT * offset);
      this.setState((prevState) => ({ offset: prevState.offset + 1 }));
    }
  };

  isSameDay(notification, date) {
    if (!notification) {
      return false;
    }

    const notificationDate = new Date(notification.changed_date);

    return (
      date.getDate() === notificationDate.getDate()
      && date.getMonth() === notificationDate.getMonth()
      && date.getFullYear() === notificationDate.getFullYear()
    );
  }

  render() {
    const { disabled, notificationsCount } = this.props;
    const { loading, offset } = this.state;

    const notificationsList = this.renderNotificationsWithTitles();

    return (
      <Spin
        spinning={disabled}
        indicator={null}
        wrapperClassName={styles.notificationMainSpinWrapper}
      >
        <div className={styles.notifications}>
          <div className={styles.list}>
            <InfiniteScroll
              scrollbarProps={{
                style: { height: '100%' },
                autoHide: true,
              }}
              loadMoreEnabled={
                !loading
                && notificationsList
                && (notificationsCount > notificationsList.length)
                && (notificationsCount > LOAD_LIMIT * offset)
              }
              loadMore={this.loadMoreHandle}
            >
              <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
                {this.renderTopTitle()}
                {
                  this.renderNotificationsWithTitles().map((item) => {
                    if (typeof item === 'string') return item;
                    return this.getCard(item);
                  })
                }
              </Spin>
            </InfiniteScroll>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notificationsData,
  notificationsCount: state.notifications.count,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Notifications
  )
);
