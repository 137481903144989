import React, { memo } from 'react';
import { CustomTag, PopoverTags } from '@ui';
import styles from '../styles.module.less';

const PassportHeaderParamTags = ({ name = '', content = [] }) => {
    if (!content && !content.length) return '';

    return <div className={styles.paramContainer}>
            <div key={name} className={styles.paramLabel}>
                <div className={styles.paramName}>{`${name}:`}</div>
                <PopoverTags
                    size={content.length}
                    popoverPlacement='bottom'
                    getPopupContainer={(triggerNode) => (
                        triggerNode.closest('.ant-tag')
                            ? triggerNode.closest('.ant-tag').parentNode
                            : triggerNode
                    )}
                >
                    {content.map((item) => (
                        <CustomTag
                            key={item.id}
                            disablePopover={content.length > 1}
                            maxChars={30}
                            getPopupContainer={(triggerNode) => (
                                triggerNode.closest('.ant-tag')
                                    ? triggerNode.closest('.ant-tag').parentNode
                                    : triggerNode
                            )}
                        >
                            {item.name}
                        </CustomTag>
                    ))}
                </PopoverTags>
            </div>
    </div>


}

export default memo(PassportHeaderParamTags)