export const GET_RIGHTS = 'GET_RIGHTS';
export const GET_PASSPORT_RIGHTS = 'GET_PASSPORT_RIGHTS';
export const GET_RIGHT_PASSPORT_DOCS = 'GET_RIGHT_PASSPORT_DOCS';

export interface InterfaceRightsConstants {
  NOTIFICATION_TASK_DC_DEADLINE: string;
  NOTIFICATION_TASK_DC_EXPIRATION: string;
  NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE: string;
  NOTIFICATION_TASK_DC_STATUS_CHANGE: string;
  RIGHT_DOCUMENT_GLOBAL_STORAGE: string;
  RIGHT_PASSPORT_DOCS: string;
  RIGHT_PASSPORT_EVENTS: string;
  RIGHT_PASSPORT_FUNCTIONS: string;
  RIGHT_PASSPORT_INFO: string;
  RIGHT_PASSPORT_SETPOINTS: string;
  RIGHT_PASSPORT_SETPOINT_MAP: string;
  RIGHT_PASSPORT_TASKS: string;
  RIGHT_PASSPORT_LETTERS: string;
  RIGHT_PASSPORT_NONPERFORMANCE: string;
  RIGHT_ROUTE_CLASSIFIERS: string;
  RIGHT_ROUTE_EVENTS: string;
  RIGHT_ROUTE_EXTERNAL_SYSTEMS: string;
  RIGHT_ROUTE_FUNCTIONS: string;
  RIGHT_ROUTE_GLOBAL_SEARCH: string;
  RIGHT_ROUTE_JOURNAL: string;
  RIGHT_ROUTE_REPORTS: string;
  RIGHT_ROUTE_ROLES: string;
  RIGHT_ROUTE_STORAGES: string;
  RIGHT_ROUTE_SYSTEM_SETTINGS: string;
  RIGHT_ROUTE_TEMPLATES: string;
  RIGHT_ROUTE_TREE: string;
  RIGHT_NEWS: string,
  RIGHT_MAINTENANCE: string
}

export interface IRightPassportDocs {
  RIGHT_PASSPORT_DOCS: string;
}

export interface rightsState {
  rightsDataGeneral: InterfaceRightsConstants;
  rightsData: InterfaceRightsConstants;
  rightPassportDocs: IRightPassportDocs;
}

export interface getRightsAction {
  type: string;
  rightsDataGeneral: InterfaceRightsConstants;
  rightsData: InterfaceRightsConstants;
  rightPassportDocs: IRightPassportDocs;
}

export type rightsActionTypes = getRightsAction;
