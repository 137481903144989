import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Select } from 'antd';
import debounce from 'lodash/debounce';

import api from '@services/api';
import { SelectInfiniteScroll } from '@ui';
import { RootState } from '@state/store';

import styles from './styles.module.less';
import type { Data, Item } from './types';

interface Props extends WithTranslation {
  info?: { dispatcher_center: Item };
  data?: {
    selectedTask: Data;
  };
  handleSelectDC: Function;
  isDataLoading?: boolean;
  callback: Function;
}

interface State {
  aDC: Item[];
  nextDC: string | null;
  selectedDC?: string;
  isDCLoading?: boolean;
}

class SelectDC extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { info } = props;

    let selectedDC;
    const aDC = [];

    if (info?.dispatcher_center) {
      aDC.push(info?.dispatcher_center);
      selectedDC = info?.dispatcher_center?.id;
    }

    this.state = {
      aDC: [],
      nextDC: null,
      selectedDC,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async (name = '') => {
    const { info, t } = this.props;

    const options: { limit: number; name?: string } = {
      limit: 30,
    };
    if (name) {
      options.name = name;
    }

    this.setState({ isDCLoading: true });
    const { status, data } = await api.getDispatcherCenters(options);
    this.setState({ isDCLoading: false });

    if (status === 200) {
      const aDC = [
        {
          id: '',
          name: t('ALL_DC'),
        },
        ...data.results,
      ];

      if (info?.dispatcher_center && !aDC.some((dc) => dc.id === info?.dispatcher_center?.id)) {
        aDC.push(info?.dispatcher_center);
      }

      this.setState({
        aDC,
        nextDC: data.next,
      });
    }
  };

  loadMoreData = async () => {
    const { aDC, nextDC } = this.state;
    const url = nextDC ? new URL(nextDC) : null;

    if (url && url.pathname && url.search) {
      const request = url.pathname + url.search;

      this.setState({ isDCLoading: true });
      const { status, data } = await api.urlGetRequest(request);
      this.setState({ isDCLoading: false });

      if (status === 200) {
        this.setState({
          aDC: [
            ...aDC,
            ...data.results,
          ],
          nextDC: data.next,
        });
      }
    }
  };

  handleSelectDC = (selectedDC: string) => {
    const { handleSelectDC } = this.props;

    this.setState({ selectedDC });
    handleSelectDC(selectedDC);
  };

  handleSearch = (name: string) => {
    this.loadData(name);
  };

  handleChange = () => {
    this.loadData();
  };

  render() {
    const {
      t, isDataLoading, callback,
    } = this.props;
    const {
      aDC,
      isDCLoading,
      selectedDC,
    } = this.state;

    return (
      <SelectInfiniteScroll
        size='small'
        showSearch
        allowClear
        className={styles.filter}
        getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
        popoverPlacement='left'
        placeholder={t('PASSPORT_FUNCTIONS_CHOOSE_DC')}
        loading={isDCLoading}
        loadMoreHandle={this.loadMoreData}
        onSelect={this.handleSelectDC}
        defaultValue={selectedDC}
        disabled={isDataLoading}
        filterOption={false}
        onDropdownVisibleChange={(open: boolean) => callback(open)}
        onChange={this.handleChange}
        onSearch={debounce(this.handleSearch, 800)}
      >
        {aDC.map((item: Item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </SelectInfiniteScroll>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  info: state.user.info,
});

export default connect(mapStateToProps, null)(
  withTranslation()(
    SelectDC
  )
);
