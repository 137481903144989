import api from '@services/api';
import store from '@state/store';
import { LIMIT } from '@globalConstants';

import {
  UPDATE_BUCKET,
  UPDATE_BUCKET_SHORT_DATA,
  UPDATE_BUCKET_IDS_DATA,
  CLEAR_BUCKET,
  GET_MORE_BUCKET,
} from './types';

const fullRequest = (params) => {
  return new Promise(
    (resolve) => {
      resolve(api.getBucket(params));
    }
  );
};

const fullRequestResult = (result, dispatch) => {
  const { status, data } = result;

  if (status === 200 && data) {
    dispatch({
      type: UPDATE_BUCKET,
      bucketData: data.results || [],
      count: data.count,
    });
  } else {
    dispatch({
      type: CLEAR_BUCKET,
    });
  }
};

export const getShortBucket = (withFullInfo = false) => {
  return async (dispatch) => {
    const { status, data } = await api.getBucket({ view_mode: 'short' });

    if (status === 200 && data) {
      dispatch({
        type: UPDATE_BUCKET_SHORT_DATA,
        bucketShortData: data.results || [],
        count: data.count,
      });

      if (withFullInfo) {
        const request = fullRequest();
        request.then(
          (result) => fullRequestResult(result, dispatch)
        );
      }
    } else {
      dispatch({
        type: CLEAR_BUCKET,
      });
    }
  };
};

export const getBucket = (page = 1, isAwait = true, params = {}) => {
  return async (dispatch) => {
    if (page > 0) {
      params.limit = LIMIT;
      params.offset = LIMIT * (page - 1);
    }

    if (isAwait) {
      const { status, data } = await api.getBucket(params);

      if (status === 200 && data) {
        dispatch({
          type: UPDATE_BUCKET,
          bucketData: data.results || [],
          count: data.count,
        });
      } else {
        dispatch({
          type: CLEAR_BUCKET,
        });
      }
      dispatch(getShortBucket());
    } else {
      const request = fullRequest(params);
      request.then(
        (result) => {
          fullRequestResult(result, dispatch);
          dispatch(getShortBucket());
        }
      );
    }
  };
};

export const getMoreBucket = (page) => {
  return async (dispatch) => {
    const params = {};

    if (page) {
      params.limit = LIMIT;
      params.offset = LIMIT * (page - 1);
    }

    const { status, data } = await api.getBucket(params);

    if (status === 200) {
      dispatch({
        type: GET_MORE_BUCKET,
        bucketData: data.results,
        count: data.count,
      });
    } else {
      dispatch({
        type: GET_MORE_BUCKET,
        bucketData: [],
        count: 0,
      });
    }
  };
};

/* Нет необходимости после каждого добавления с корзиной делать дополнительный GET запрос на бэк,
тк он долго обрабатывается */
/* При этом важно помнить, что чтобы получить дополнительные данные */
export const addToBucketLocally = (deviceIds = [], withLoadFullInfo = false, isAwait = false) => {
  const DeviceIdsList = !Array.isArray(deviceIds)
    ? [deviceIds]
    : [...deviceIds];
  const { bucket: { bucketIdsData } } = store.getState();
  // Добавляем айдишники в массив и на всякий случай чистим его от дублей
  const addedDevicesData = DeviceIdsList.filter((x) => bucketIdsData.indexOf(x) === -1);
  const newBucketIdsData = [...bucketIdsData, ...addedDevicesData];

  store.dispatch({
    type: UPDATE_BUCKET_IDS_DATA,
    bucketIdsData: newBucketIdsData,
  });

  if (withLoadFullInfo) {
    store.dispatch(getBucket(1, isAwait));
  }
};

/* Нет необходимости после удаления делать дополнительный GET-запрос на бэк, */
/* Достаточно просто в локальном хранилище обновлять данные, если удаление на бэке завершилось успешно */
export const removeFromBucketLocally = (deviceIds = [], withLoadFullInfo = false, isAwait = false) => {
  const DeviceIdsList = !Array.isArray(deviceIds)
    ? [deviceIds]
    : [...deviceIds];
  const { bucket: { bucketIdsData } } = store.getState();
  const newBucketIdsData = bucketIdsData.filter((x) => DeviceIdsList.indexOf(x) === -1);

  store.dispatch({
    type: UPDATE_BUCKET_IDS_DATA,
    bucketIdsData: newBucketIdsData,
  });

  if (withLoadFullInfo) {
    store.dispatch(getBucket(1, isAwait));
  }
};
