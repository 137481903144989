export const TAB_FUNCTIONS = 'TAB_FUNCTIONS';
export const TAB_ROLES = 'TAB_ROLES';

export const ADMIN_IA = 'admin_ia';
export const ADMIN_ODU = 'admin_odu';
export const ADMIN_RDU = 'admin_rdu';
export const ADMIN_TECH = 'admin_tech';
export const TECH = 'tech';
export const GUEST = 'guest';

export const NOTIFICATION_TASK_DC_STATUS_CHANGE = 'NOTIFICATION_TASK_DC_STATUS_CHANGE';
export const NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE = 'NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE';
export const NOTIFICATION_TASK_DC_DEADLINE = 'NOTIFICATION_TASK_DC_DEADLINE';
export const NOTIFICATION_TASK_DC_EXPIRATION = 'NOTIFICATION_TASK_DC_EXPIRATION';
