import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Button, Select, Form, message, Input, Popconfirm, TreeSelect, Typography } from 'antd';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { renderFunctionsSelect } from '@app/helpers';
import api from '@services/api';
import { SelectInfiniteScroll } from '@ui';

import styles from './styles.module.less';

const selectTypes = {
  DC: 'DispatcherCenters',
  SUBSTATIONS: 'Substations',
  EQUIPMENT_BASE: 'EquipmentBase',
};

const { Text } = Typography;

class TermDescription extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);
    this.onSelectSearch = debounce(this.onSelectSearch, 800);

    this.state = {
      [selectTypes.DC]: [],
      [selectTypes.SUBSTATIONS]: [],
      [selectTypes.EQUIPMENT_BASE]: [],
      showActions: false,
      isEdit: false,
      isSaving: false,
      selectedFunctions: [this.emptyItem()],
      isDuplicatedChangeValues: false,
      isDuplicatedAllValues: false,
    };
  }

  componentDidMount = async () => {
    const { isAdding, descriptionType, isEdit } = this.props;

    if (isAdding && !descriptionType) {
      await this.loadDataForSelect(selectTypes.DC);
    }
    if (isAdding && !isEdit) {
      this.setState({
        selectedFunctions: [this.emptyItem()],
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { descriptionType, description } = this.props;
    const {
      isEdit,
      [`selected${selectTypes.DC}`]: selectedDC,
      [`selected${selectTypes.SUBSTATIONS}`]: selectedSubstation,
    } = this.state;

    if (!prevState.isEdit && isEdit) {
      if (!descriptionType) {
        for (const type of Object.keys(selectTypes)) {
          let addParam = {};

          if (type === selectTypes.SUBSTATIONS) {
            addParam = {
              dispatcher_center: description && description.dispatcher_center && description.dispatcher_center.id,
            };
          }
          if (type === selectTypes.EQUIPMENT_BASE) {
            addParam = { substation: description && description.substation && description.substation.id };
          }

          if (type === selectTypes.DC || (addParam && Object(addParam).keys && Object(addParam).keys.length)) {
            await this.loadDataForSelect(selectTypes[type], addParam);
          }
        }

        this.setState({
          [`selected${selectTypes.DC}`]:
            description && description.dispatcher_center && description.dispatcher_center.id,
          [`selected${selectTypes.SUBSTATIONS}`]: description && description.substation && description.substation.id,
          selectedFunctions:
            description && description.protection_device_function && description.protection_device_function.length
              ? description.protection_device_function
              : [this.emptyItem()],
        });
      }

      this.form.current.setFieldsValue({
        dispatcher_center: description && description.dispatcher_center && description.dispatcher_center.id,
        substation: description && description.substation && description.substation.id,
        equipment: description && description.equipment && description.equipment.id,
        risks_default: description && description.risks_default,
        event: description && description.event,
      });
    }

    if (prevState[`selected${selectTypes.DC}`] !== selectedDC && selectedDC) {
      await this.loadDataForSelect(selectTypes.SUBSTATIONS, { dispatcher_center: selectedDC });
    }

    if (prevState[`selected${selectTypes.SUBSTATIONS}`] !== selectedSubstation && selectedSubstation) {
      await this.loadDataForSelect(selectTypes.EQUIPMENT_BASE, { substation: selectedSubstation });
    }
  };

  emptyItem = () => {
    return {
      id: undefined,
      name: undefined,
    };
  };

  addFunction = (idx) => {
    const { selectedFunctions } = this.state;

    const newSelectedFunctions = cloneDeep(selectedFunctions);
    newSelectedFunctions.splice(idx + 1, 0, this.emptyItem());
    this.setState({
      selectedFunctions: newSelectedFunctions,
    });
  };

  deleteFunction = (idx) => {
    const { selectedFunctions } = this.state;

    const newSelectedFunctions = cloneDeep(selectedFunctions);
    newSelectedFunctions.splice(idx, 1);
    this.setState({
      selectedFunctions: newSelectedFunctions,
    });
  };

  handleActionsVisibility = (visibility) => {
    this.setState({ showActions: visibility });
  };

  loadDataForSelect = async (type, addParams = {}) => {
    const params = {
      limit: 50,
    };

    this.setState({ [`${type}Loading`]: true });
    const { status, data } = await api[`get${type}`]({ ...params, ...addParams });
    this.setState({ [`${type}Loading`]: false });

    if (status === 200) {
      this.setState({
        [type]: data.results,
        [`next${type}`]: data.next,
      });
    }
  };

  loadMoreDataForSelect = async (type) => {
    const { [type]: selectData, [`next${type}`]: nextUrl } = this.state;
    const url = nextUrl ? new URL(nextUrl) : {};

    if (url.pathname && url.search) {
      const request = url.pathname + url.search;

      this.setState({ [`${type}Loading`]: true });
      const { status, data } = await api.urlGetRequest(request);
      this.setState({ [`${type}Loading`]: false });

      if (status === 200) {
        this.setState({
          [type]: [...selectData, ...data.results],
          [`next${type}`]: data.next,
        });
      }
    }
  };

  handleEdit = () => {
    this.setState({ isEdit: true });
  };

  handleDelete = async () => {
    const { t, description, term, onSelectTerm } = this.props;
    const { status } = await api.deleteTermDescriptions(description.id);

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));
      onSelectTerm(term);
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  onSelectSearch = async (value, optionType) => {
    const { [`selected${selectTypes.DC}`]: selectedDC, [`selected${selectTypes.SUBSTATIONS}`]: selectedSubstation } =
      this.state;
    const searchNameParam = 'name';
    let addParam = {};

    if (optionType === selectTypes.SUBSTATIONS) addParam = { dispatcher_center: selectedDC };
    if (optionType === selectTypes.EQUIPMENT_BASE) addParam = { substation: selectedSubstation };

    await this.loadDataForSelect(optionType, { [searchNameParam]: value, ...addParam });
  };

  onChangeSelect = async (value, optionType) => {
    const { [`selected${selectTypes.DC}`]: selectedDC, [`selected${selectTypes.SUBSTATIONS}`]: selectedSubstation } =
      this.state;

    if (optionType === selectTypes.DC) {
      this.form.current.resetFields(['substation', 'equipment']);
      this.setState({ [`selected${selectTypes.SUBSTATIONS}`]: null });
    }

    if (optionType === selectTypes.SUBSTATIONS) {
      this.form.current.resetFields(['equipment']);
    }

    if (!value || (Array.isArray(value) && !value.length)) {
      let addParam = {};

      if (optionType === selectTypes.SUBSTATIONS) {
        addParam = { dispatcher_center: selectedDC };
      }
      if (optionType === selectTypes.EQUIPMENT_BASE) {
        addParam = { substation: selectedSubstation };
      }
      await this.loadDataForSelect(optionType, addParam);
    }

    this.setState({ [`selected${optionType}`]: value });
  };

  getSelectDescription = () => {
    const { t, description, isAdding, index, deviceFns, functionsCount, isStepShowing } = this.props;

    const {
      showActions,
      isEdit,
      selectedFunctions,
      [`${selectTypes.DC}Loading`]: DCLoading,
      [`${selectTypes.SUBSTATIONS}Loading`]: substationsLoading,
      [`${selectTypes.EQUIPMENT_BASE}Loading`]: equipmentBaseLoading,
      [`selected${selectTypes.DC}`]: selectedDC,
      [`selected${selectTypes.SUBSTATIONS}`]: selectedSubstation,
    } = this.state;

    let {
      [selectTypes.DC]: DC,
      [selectTypes.SUBSTATIONS]: substations,
      [selectTypes.EQUIPMENT_BASE]: equipmentBase,
    } = this.state;

    let initOptionDC = false;
    let initOptionSubstations = false;
    let initOptionEquipmentBase = false;

    if (description && description?.dispatcher_center) {
      initOptionDC = !(DC && DC.find((dc) => dc.id === description.dispatcher_center.id));
    }
    if (description && description?.dispatcher_center?.id === selectedDC && description?.substation) {
      initOptionSubstations = !(
        substations && substations.find((substation) => substation?.id === description?.substation?.id)
      );
    }
    if (
      description &&
      description?.dispatcher_center?.id === selectedDC &&
      description?.substation?.id === selectedSubstation &&
      description?.equipment
    ) {
      initOptionEquipmentBase = !(
        equipmentBase && equipmentBase.find((equipment) => equipment?.id === description?.equipment?.id)
      );
    }

    if (initOptionDC) {
      DC = DC.concat([
        {
          id: description?.dispatcher_center?.id,
          name: description?.dispatcher_center?.name,
        },
      ]);
    }

    if (initOptionSubstations) {
      substations = substations?.concat([
        {
          id: description?.substation?.id,
          name: description?.substation?.name,
        },
      ]);
    }

    if (initOptionEquipmentBase) {
      equipmentBase = equipmentBase.concat([
        {
          id: description?.equipment?.id,
          name: description?.equipment?.name,
        },
      ]);
    }

    let disableSubstations = true;
    let disableEquipmentBase = true;

    if (selectedDC) disableSubstations = false;
    if (selectedSubstation) disableEquipmentBase = false;

    const required = (
      <span className={styles.required} title={t('PASSPORT_TASKS_CREATE_REQUIRED_FIELD')}>
        *
      </span>
    );

    return (
      <>
        <Row gutter={7}>
          <Col span={8}>
            <div className={styles.label}>
              {t('DC')} {required}
            </div>
            <div className={styles.descValue}>
              {isAdding || isEdit ? (
                <Form.Item name='dispatcher_center' rules={[{ required: true, message: t('REQUIRED_FIELD') }]}>
                  <SelectInfiniteScroll
                    placeholder={t('SELECT_LIST_VALUE')}
                    loading={DCLoading}
                    style={{ width: '100%' }}
                    loadMoreHandle={() => this.loadMoreDataForSelect(selectTypes.DC)}
                    allowClear
                    showSearch
                    onSearch={(value) => this.onSelectSearch(value, selectTypes.DC)}
                    onChange={(value) => this.onChangeSelect(value, selectTypes.DC)}
                    filterOption={false}
                  >
                    {DC &&
                      DC.length > 0 &&
                      DC.map((opt) => (
                        <Select.Option
                          key={opt.id}
                          value={opt.id}
                          className={opt.is_archived ? 'isArchivedTableRow' : ''}
                        >
                          {opt.name}
                        </Select.Option>
                      ))}
                  </SelectInfiniteScroll>
                </Form.Item>
              ) : (
                description && description.dispatcher_center && description.dispatcher_center.name
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.label}>
              {t('SUBSTATION')} {required}
            </div>
            <div className={styles.descValue}>
              {isAdding || isEdit ? (
                <Form.Item name='substation' rules={[{ required: true, message: t('REQUIRED_FIELD') }]}>
                  <SelectInfiniteScroll
                    placeholder={t('SELECT_LIST_VALUE')}
                    loading={substationsLoading}
                    style={{ width: '100%' }}
                    loadMoreHandle={() => this.loadMoreDataForSelect(selectTypes.SUBSTATIONS)}
                    allowClear
                    showSearch
                    onSearch={(value) => this.onSelectSearch(value, selectTypes.SUBSTATIONS)}
                    onChange={(value) => this.onChangeSelect(value, selectTypes.SUBSTATIONS)}
                    filterOption={false}
                    disabled={disableSubstations}
                  >
                    {substations &&
                      substations.length > 0 &&
                      substations.map((opt) => (
                        <Select.Option
                          key={opt.id}
                          value={opt.id}
                          className={opt.is_archived ? 'isArchivedTableRow' : ''}
                        >
                          {opt.name}
                        </Select.Option>
                      ))}
                  </SelectInfiniteScroll>
                </Form.Item>
              ) : (
                description && description.substation && description.substation.name
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.label}>
              {t('EQUIPMENT_BASE')} {required}
            </div>
            <div className={styles.descValue}>
              {isAdding || isEdit ? (
                <Form.Item name='equipment' rules={[{ required: true, message: t('REQUIRED_FIELD') }]}>
                  <SelectInfiniteScroll
                    placeholder={t('SELECT_LIST_VALUE')}
                    loading={equipmentBaseLoading}
                    style={{ width: '100%' }}
                    loadMoreHandle={() => this.loadMoreDataForSelect(selectTypes.EQUIPMENT_BASE)}
                    allowClear
                    showSearch
                    onSearch={(value) => this.onSelectSearch(value, selectTypes.EQUIPMENT_BASE)}
                    onChange={(value) => this.onChangeSelect(value, selectTypes.EQUIPMENT_BASE)}
                    filterOption={false}
                    disabled={disableEquipmentBase}
                  >
                    {equipmentBase &&
                      equipmentBase.length > 0 &&
                      equipmentBase.map((opt) => (
                        <Select.Option
                          key={opt.id}
                          value={opt.id}
                          className={opt.is_archived ? 'isArchivedTableRow' : ''}
                        >
                          {opt.name}
                        </Select.Option>
                      ))}
                  </SelectInfiniteScroll>
                </Form.Item>
              ) : (
                description && description.equipment && description.equipment.name
              )}
            </div>
          </Col>
          {!isAdding && !isEdit && showActions && (
            <Col span={2} className={styles.actions}>
              <Button icon={<EditOutlined />} onClick={this.handleEdit} />
              <Popconfirm
                placement='bottomRight'
                title={t('DELETE_CONFIRM')}
                okText={t('YES')}
                cancelText={t('NO')}
                onConfirm={this.handleDelete}
              >
                <Button icon={<DeleteOutlined />} style={{ color: '#FF0000' }} />
              </Popconfirm>
            </Col>
          )}
        </Row>
        {isAdding || isEdit ? (
          <>
            {selectedFunctions.map((x, idx) => (
              <Row
                key={`${index}_protection_device_function_${x.id || idx}`}
                justify="space-between"
                gutter={7}
                className={styles.wrapperFunction}
              >
                <Col span={isStepShowing ? 16 : 22}>
                  <div className={styles.label}>{t('PROTECTION_DEVICE_FUNCTION')}</div>
                  <TreeSelect
                    treeDefaultExpandAll
                    allowClear
                    defaultValue={x.id}
                    value={x.id}
                    onChange={(value, labelList) => {
                      const newSelectedFunctions = cloneDeep(selectedFunctions);
                      newSelectedFunctions[idx] = {
                        id: value,
                        name: labelList && labelList[0],
                        step: newSelectedFunctions[idx].step,
                      };
                      this.setState({
                        selectedFunctions: newSelectedFunctions,
                      });
                    }}
                  >
                    {renderFunctionsSelect(deviceFns, selectedFunctions, x.id, t, undefined, false)}
                  </TreeSelect>
                </Col>
                {isStepShowing && <Col flex={'auto'}>
                  <div className={styles.label}>{t('STEP')}</div>
                  <Input
                    style={{ width: '100%' }}
                    value={x.step}
                    maxLength={20}
                    placeholder={t('STEP_INPUT_PLACEHOLDER')}
                    disabled={!x.id}
                    onChange={(e) => {
                      const newSelectedFunctions = cloneDeep(selectedFunctions);
                      newSelectedFunctions[idx].step = e.target.value;
                      this.setState({
                        selectedFunctions: newSelectedFunctions,
                      });
                    }}
                  />
                </Col>}
                <Col flex={'none'} style={{ paddingTop: 29 }}>
                  <Button
                    icon={<PlusOutlined />}
                    className={styles.buttonPlus}
                    onClick={() => this.addFunction(idx)}
                    disabled={functionsCount && selectedFunctions?.length === functionsCount}
                  />

                  {x.id ? (
                    <Popconfirm
                      title={t('DELETE_ROW_CONFIRM')}
                      onConfirm={() => this.deleteFunction(idx)}
                      okText={t('YES')}
                      cancelText={t('NO')}
                      disabled={selectedFunctions.length <= 1}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        className={cn(styles.buttonDelete, selectedFunctions.length <= 1 ? 'disabled-button' : '')}
                        disabled={selectedFunctions.length <= 1}
                      />
                    </Popconfirm>
                  ) : (
                    <Button
                      icon={<DeleteOutlined />}
                      className={cn(styles.buttonDelete, selectedFunctions.length <= 1 ? 'disabled-button' : '')}
                      disabled={selectedFunctions.length <= 1}
                      onClick={() => this.deleteFunction(idx)}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <>
            {description?.protection_device_function?.length ? (
              description.protection_device_function.map((x) =>
                x.id ? (
                  <Row key={`row_func_${x.id}`} gutter={7}>
                    <Col span={24 + (-8*isStepShowing)}>
                      <div className={styles.label}>{t('PROTECTION_DEVICE_FUNCTION')}</div>
                      <div className={styles.functionName}>{x.name}</div>
                    </Col>
                    {isStepShowing && <Col span={8}>
                      <div className={styles.label}>{t('STEP')}</div>
                      <div key={`STEP_${x.id}`} className={styles.functionName}>
                        {x?.step || '-'}
                      </div>
                    </Col>}
                  </Row>
                ) : (
                  ''
                )
              )
            ) : (
              <>
                <div className={styles.label}>{t('PROTECTION_DEVICE_FUNCTION')}</div>
                <div>&mdash;</div>
              </>
            )}
          </>
        )}
        {/* </Col> */}
      </>
    );
  };

  getTextDescription = () => {
    const { t, isAdding, index, description, descriptions } = this.props;
    const { showActions, isEdit } = this.state;

    const deduplicationValidator = async (_, value) => {
      if (
        descriptions.filter(
          (item) =>
            item.event.toLowerCase().split(' ').join('') === value?.toLowerCase().split(' ').join('')
        ).length > 0
      ) {
        throw new Error(t('WARN_SAME_TREE_ITEM'));
      }
    };

    return (
      <>
        <Row>
          <Col span={21}>
            <div className={styles.descTitle}>{`${index + 1}. ${t('POSSIBLY_RISKS_ESTIMATION')}:`}</div>
            <div className={styles.label}>{t('RISK_ESTIMATION_DESCRIPTION')}:</div>
            <div className={styles.descValue}>
              {isAdding || isEdit ? (
                <Form.Item name='risks_default' rules={[{ required: true, message: t('REQUIRED_FIELD') }]}>
                  <Input.TextArea maxLength={1000} placeholder={t('DESCRIBE_RISK_ESTIMATION')} rows={4} />
                </Form.Item>
              ) : (
                description && description.risks_default
              )}
            </div>
            <div className={styles.label}>{t('EVENT_DESCRIPTION')}:</div>
            <div className={styles.descValue}>
              {isAdding || isEdit ? (
                <Form.Item
                  name="event"
                  rules={[
                    { required: true, message: t('REQUIRED_FIELD') },
                    () => ({ validator: deduplicationValidator }),
                  ]}
                >
                  <Input.TextArea
                    maxLength={1000}
                    placeholder={t('DESCRIBE_EVENT')}
                    rows={4}
                  />
                </Form.Item>
              ) : (
                description && description.event
              )}
            </div>
          </Col>
          {!isAdding && !isEdit && showActions && (
            <Col span={3} className={styles.actions}>
              <Button icon={<EditOutlined />} onClick={this.handleEdit} />
              <Popconfirm
                placement='topLeft'
                title={t('DELETE_CONFIRM')}
                okText={t('YES')}
                cancelText={t('NO')}
                onConfirm={this.handleDelete}
              >
                <Button icon={<DeleteOutlined />} style={{ color: '#FF0000' }} />
              </Popconfirm>
            </Col>
          )}
        </Row>
      </>
    );
  };

  handleCancel = () => {
    const { cancelAddingDescription } = this.props;

    cancelAddingDescription && cancelAddingDescription();

    this.setState({
      isEdit: false,
      showActions: false,
      isDuplicatedChangeValues: false,
      isDuplicatedAllValues: false,
    });
  };

  handleSave = async () => {
    const { t, term, onSelectTerm, description } = this.props;
    const { isEdit, selectedFunctions } = this.state;

    this.form.current.validateFields().then(async (values) => {
      const filteredFuncsForSteps = selectedFunctions.filter(
        (x) => x.id !== undefined && x.id !== null && x.step !== null
      );
      let steps = {};
      filteredFuncsForSteps.forEach((func) => {
        steps[func.id] = func.step;
      });

      const params = {
        ...values,
        term: term.id,
        protection_device_function: selectedFunctions
          .filter((x) => x.id !== undefined && x.id !== null)
          .map((x) => x.id),
        step: steps,
      };

      this.setState({ isSaving: true });

      const { status } = isEdit
        ? await api.editTermDescriptions(description.id, params)
        : await api.addTermDescriptions(params);

      this.setState({
        isSaving: false,
        isDuplicatedChangeValues: false,
        isDuplicatedAllValues: false,
      });

      if ([200, 201].includes(status)) {
        status === 200 ? message.success(t('UPDATE_SUCCESS')) : message.success(t('CREATE_SUCCESS'));
        onSelectTerm(term);
        this.setState({ isEdit: false });
      } else {
        message.error(t('CREATE_ERROR'));
      }
    });
  };

  getPrimaryEquipment = (descriptions) => {
    return descriptions.map(
      (description) =>
        description?.dispatcher_center?.id +
        description?.substation?.id +
        description?.equipment?.id
    );
  };

  onFormChanged = (changeValues, allValues) => {
    const { descriptions } = this.props;
    const { isDuplicatedChangeValues, isDuplicatedAllValues } = this.state;

    let resultValues = false;
    let resultAllValues = false;

    const duplicationCheck =
      descriptions?.filter(
        (item) =>
          item.event?.toLowerCase().split(' ').join('') ===
          changeValues?.event?.toLowerCase().split(' ').join('')
      ).length > 0;

    if (changeValues.event && this.state.isDuplicatedChangeValues !== duplicationCheck) {
      resultValues = duplicationCheck;
    }

    if (changeValues?.event?.split(' ').join('').length === 0) {
      resultValues = true;
    }

    const primaryEquipmentValue = Object.values(allValues)
      .map((value) => value)
      .filter((i) => i !== undefined)
      .join('');

    if (
      !changeValues.event &&
      this.getPrimaryEquipment(descriptions).includes(primaryEquipmentValue)
    ) {
      resultAllValues = true;
    }

    const isStateValuesChanged =
      isDuplicatedChangeValues !== resultValues || isDuplicatedAllValues !== resultAllValues;

    if (isStateValuesChanged) {
      this.setState({
        isDuplicatedChangeValues: resultValues,
        isDuplicatedAllValues: resultAllValues,
      });
    }
  };

  render() {
    const { t, isAdding, descriptionType } = this.props;
    const { isEdit, isSaving, isDuplicatedChangeValues, isDuplicatedAllValues } = this.state;

    return (
      <div
        className={styles.descriptionWrapper}
        onMouseEnter={() => this.handleActionsVisibility(true)}
        onMouseLeave={() => this.handleActionsVisibility(false)}
      >
        <Form ref={this.form} onValuesChange={this.onFormChanged}>
          {descriptionType ? this.getTextDescription() : this.getSelectDescription()}
          {isDuplicatedAllValues && (
            <Text
              type="danger"
              className={styles.warning}
            >
              {t('WARN_EQUIPMENT_BASE')}
            </Text>
          )}
          {(isAdding || isEdit) && (
            <Row className={styles.btnBlock}>
              <Col>
                <Button onClick={this.handleCancel}>{t('CANCEL')}</Button>
                <Button
                  onClick={this.handleSave}
                  type="primary"
                  loading={isSaving}
                  disabled={isSaving || isDuplicatedChangeValues || isDuplicatedAllValues}
                >
                  {t('SAVE')}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    );
  }
}

export default withTranslation()(TermDescription);
