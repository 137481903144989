import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  Button, Input, message, Checkbox,
} from 'antd';
import { CloseOutlined, PaperClipOutlined } from '@ant-design/icons';
import api from '@services/api';
import { PassportContext } from '@routes/passport/PassportContext';
import { DOC_SIDEBAR_DOC } from '@common/documentsUploadSidebar/constants';
import { DOCUMENTS_UPLOAD, SETPOINT_INFO } from '@common/sidebarRoot/types';
import { openSidebar } from '@state/sidebar/actions';

import styles from '../../styles.module.less';

const { TextArea } = Input;

class ThreadForm extends Component {
  state = {
    note: '',
    attachFile: false,
    isCommentSending: false,
  };

  sendComment = async () => {
    const {
      selectedNotification, dcSetpointValue, t, setpointValueId, updateComments,
    } = this.props;
    const { attachFile } = this.state;

    if (!selectedNotification) {
      return;
    }

    this.setState({ isCommentSending: true });

    if (attachFile) {
      const {
        openSb,
      } = this.props;

      openSb(
        DOCUMENTS_UPLOAD,
        {
          type: DOC_SIDEBAR_DOC,
          header: t('COMMENT_DOC_TYPE'),
          callback: async (doc) => {
            await this.saveComment(doc.id);

            openSb(
              SETPOINT_INFO,
              {
                id: dcSetpointValue,
                setpointValueId,
                currentNotification: selectedNotification,
                callback: updateComments,
              }
            );
          },
        }
      );
    } else {
      await this.saveComment();
      updateComments();
    }
  };

  saveComment = async (fileId) => {
    const {
      selectedNotification, dcSetpointValue, selectedComment, t,
    } = this.props;
    const { note } = this.state;

    if (!selectedNotification) {
      return;
    }

    this.setState({ isCommentSending: true });

    const dataToSend = {
      commentary: note,
      task_dc_notification: selectedNotification.id,
      task_dispatcher_center_setpoint_value: dcSetpointValue,
    };
    if (selectedComment) {
      dataToSend.parent = selectedComment.id;
    }
    const { status, data } = await api.sendSetpointNotes(dataToSend);

    if (status !== 201) {
      this.setState({ isCommentSending: false });
      message.error(t('ERROR_CREATE_NOTE'));

      return;
    }

    if (fileId) {
      await api.bindSetpointNoteFile({
        note: data.id,
        document: fileId,
      });

      this.context.loadTaskDocuments && this.context.loadTaskDocuments();
    }

    this.setState({ note: '', isCommentSending: false });
    message.success(t('SUCCESS_CREATE_NOTE'));
  };

  handleChangeMessage = (e) => {
    const { target } = e;

    this.setState({ note: target.value }, () => this.handleChangeSize({ target }));
  };

  handleChangeSize = (e) => {
    const { updateFooterH } = this.props;
    const { target } = e;

    updateFooterH(target.scrollHeight);
  };

  onValueChange = (e) => {
    const { target: { checked } } = e;

    this.setState({ attachFile: checked });
  };

  render() {
    const { selectedComment, handleAnswer, t } = this.props;
    const { isCommentSending, note } = this.state;

    return (
      <div className={styles.formArea}>
        {selectedComment.created_by && (
          <div
            tabIndex={0}
            role='button'
            className={styles.formAreaAnswer}
            onClick={() => handleAnswer({})}
          >
            {t('ANSWER')}
            :
            <b>{selectedComment.created_by}</b>
            <CloseOutlined />
          </div>
        )}
        <TextArea
          className={styles.formText}
          value={note}
          placeholder={t('ENTER_NOTE')}
          autoSize={true}
          onChange={this.handleChangeMessage}
          onKeyUp={this.handleChangeSize}
          disabled={isCommentSending}
        />
        <div className={styles.formActions}>
          <Checkbox onChange={this.onValueChange}>
            <PaperClipOutlined />
            &nbsp;
            {t('ATTACH_FILE')}
          </Checkbox>
          <Button
            type='link'
            loading={isCommentSending}
            disabled={!note || isCommentSending}
            onClick={this.sendComment}
          >
            {t('ADD_NOTE')}
          </Button>
        </div>
      </div>
    );
  }
}

ThreadForm.contextType = PassportContext;

const mapDispatchToProps = {
  openSb: openSidebar,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    ThreadForm
  )
);
