import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Empty, Input, message, Modal, Typography } from 'antd';
import { withPagination } from '@hoc';
import { CustomCard, PopoverEllipsis } from '@ui';

import api from '@services/api';
import CommonTable from '@common/CommonTable';
import { EditOutlined } from "@ant-design/icons";
import { cloneDeep } from "lodash";

const { Text } = Typography;

interface EditLabelsFieldData {
  fieldName: string;
  id: string;
  record: object;
  value: string;
}

interface NewParams {
  name: string;
}

interface Labels {
  id: string;
  name: string;
}

interface Windings {
  id: string;
  name: string;
  kt: string;
  secondary: string;
  winding_connection: string;
  type: string;
  winding_labels: Labels[];
}

interface NewWindings {
  id: string;
  name: string;
  kt: string;
  secondary: string;
  winding_connection: string;
  type: string;
  winding_labels: string;
  winding_labelsId: string;
}

interface Transformers extends Object {
  id: string;
  name: string;
  type: string;
  windings: Windings[];
}

interface Props extends WithTranslation {
  functionId: string;
  modalVisibility: boolean;
  closeModal: Function;
  passportId: string;
  changePage: Function;
  page: number;
  limit: number;
  count: number;
}

interface State {
  [key: string]: any,
  data: Transformers[];
  isLoading: boolean,
  modalVisibility: boolean;
  selectedTransformers: string | null;
  editWinding_labelsField: boolean;
  editWinding_labelsFieldData: EditLabelsFieldData;
}

class ModalShowTransformers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      modalVisibility: false,
      selectedTransformers: null,
      editWinding_labelsField: false,
      editWinding_labelsFieldData: {
        fieldName: '',
        id: '',
        record: {},
        value: '',
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { functionId, modalVisibility } = this.props;

    if (prevProps.functionId !== functionId && functionId) {
      this.loadDataSpin(functionId);
    }
    if (prevProps.modalVisibility !== modalVisibility) {
      this.setState({
        modalVisibility,
      });
    }
  }

  loadDataSpin = async (functionId: string) => {
    this.setState({ isLoading: true });
    await this.loadData(functionId);
    this.setState({ isLoading: false });
  };

  loadData = async (functionId: string) => {
    const { page, limit, passportId } = this.props;
    const params = {
      limit,
      offset: limit * (page - 1),
      protection_device_function: functionId,
      passport: passportId,
    };

    const { status, data } = await api.getTransformers(params);

    if (status === 200 && data && data.results) {
      this.setState({
        data: data.results,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  renderWindings = (windings: Windings[]) => (
    <CommonTable
      size='small'
      tableName='transformersWindingsTable'
      // dataSource={windings as object[]}
      dataSource={windings}
      showHeader={false}
      columns={[
        {
          // title: t('NAME'),
          dataIndex: 'name',
          key: 'name2',
          width: '60%',
        },
        {
          // title: t('TYPE'),
          dataIndex: 'kt',
          key: 'kt',
          width: '15%',
        },
        {
          // title: t('COL_TRANSFORMER_WINDINGS'),
          dataIndex: 'secondary',
          key: 'secondary',
          width: '15%',
        },
        {
          // title: t('COL_TRANSFORMER_WINDINGS'),
          dataIndex: 'winding_connection',
          key: 'winding_connection',
          width: '10%',
        },
      ]}
    />
  )

  onChangeEditTextField = async (attributeName: string) => {
    const capitalize = (s: string): string => (s && s[0].toUpperCase() + s.slice(1)) || '';
    const capitalizedFieldName: string = `edit${capitalize(attributeName)}Field`;
    const capitalizedFieldDataName: string = `${capitalizedFieldName}Data`;

    const fieldData = this.state[capitalizedFieldDataName] || null;

    if (!fieldData) return;
    const { id, fieldName, value } = fieldData;
    const { t, functionId } = this.props;
    const { data } = this.state;

    this.setState({ isLoading: true });

    const newParams: NewParams = { name: value };

    const res = await api.patchWindingLabels(id, newParams);

    if (res.status === 200 && res.data) {
      const newData = cloneDeep(data);
      newData.forEach((x: any) => {
        if (x.id === id) {
          x[fieldName] = res.data[fieldName] || '';
        }
      });
      this.setState({
        data: newData,
      });
      message.success(t('FUNCTIONS_LABELS_CREATE_SUCCESS_PATCH'));
    } else {
      message.error(`
        ${t('FUNCTIONS_LABELS_CREATE_ERROR_PATCH')}
        ${res.data && res.data.error ? res.data.error : ''}
        ${res.data && res.data.detail ? res.data.detail : ''}
      `);
      console.log({
        _f: 'Error --- api.patchWindingLabels(id, newParams)',
        newParams,
        result: res,
      });
    }

    const defaultValues = {
      [capitalizedFieldName]: false,
      [capitalizedFieldDataName]: {},
    };

    await this.loadData(functionId);

    this.setState({
      isLoading: false,
      ...defaultValues,
    });
  };

  getColumnsWindings = () => {
    const { t } = this.props;
    const { editWinding_labelsField, editWinding_labelsFieldData } = this.state;

    return [
      {
        title: t('NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '50%',
      },
      {
        title: t('COL_TRANSFORMER_WINDINGS_TRANSFORMATION_RATIO'),
        dataIndex: 'secondary',
        key: 'secondary',
        width: '30%',
        render: (text: string, record: object) => {
          const newRecord = record as Windings;
          const first = parseFloat(parseFloat(newRecord.kt).toFixed(3));
          const second = parseFloat(parseFloat(newRecord.secondary).toFixed(3));
          let resLeft;
          let resRight;
          if (newRecord.type === 'Трансформатор тока') {
            resLeft = first * second;
            resRight = second;
          } else {
            resLeft = first * second * 1000;
            resRight = second * 1000;
          }

          return (
            resLeft
              .toFixed(3)
              .toString()
              .replace(/0*$/, '')
              .replace(/\.$/, '')
              .concat(' / ')
              .concat(
                resRight
                  .toFixed(3)
                  .toString()
                  .replace(/0*$/, '')
                  .replace(/\.$/, '')
              )
          );
        },
      },
      // {
      //   title: t('COL_TRANSFORMER_WINDINGS_KT'),
      //   dataIndex: 'kt',
      //   key: 'kt',
      //   width: '10%',
      // },
      // {
      //   title: t('COL_TRANSFORMER_WINDINGS_SECONDARY'),
      //   dataIndex: 'secondary',
      //   key: 'secondary',
      //   width: '10%',
      // },
      {
        title: t('COL_TRANSFORMER_WINDINGS_CONNECTION'),
        dataIndex: 'winding_connection',
        key: 'winding_connection',
        width: '20%',
      },
      {
        title: t('LABEL'),
        dataIndex: 'winding_labels',
        key: 'winding_labels',
        width: '20%',

        render: (text: any, record: any) => {
          const { t } = this.props;

          const editHandler = () => {
            this.setState({
              editWinding_labelsField: true,
              editWinding_labelsFieldData: {
                id: record.winding_labelsId,
                value: text,
                fieldName: 'winding_labels',
                record,
              },
            });
          };

          const onEditConfirm = (event: any) => {
            this.setState(
              {
                editWinding_labelsField: true,
                editWinding_labelsFieldData: {
                  id: record.winding_labelsId,
                  value: event.target.value || '',
                  fieldName: 'winding_labels',
                  record,
                },
              },
              () => this.onChangeEditTextField('winding_labels')
            );
          };

          const handleBlur = () => {
            this.setState({
              editWinding_labelsField: true,
              editWinding_labelsFieldData: {
                fieldName: '',
                id: '',
                record: {},
                value: '',
              },
            });
          };

          const result =
            editWinding_labelsField &&
            editWinding_labelsFieldData &&
            editWinding_labelsFieldData.id === record.winding_labelsId ? (
              <Input
                autoFocus
                size="small"
                defaultValue={text}
                onPressEnter={onEditConfirm}
                onBlur={handleBlur}
              />
            ) : text ? (
              <PopoverEllipsis
                content={text}
                onClick={editHandler}
              >
                {text}
              </PopoverEllipsis>
            ) : (
              <Text
                type="secondary"
                //@ts-expect-error
                onClick={editHandler}
              >
                <EditOutlined /> {t('EDIT')}
              </Text>
            );

          return result;
        },
      },
    ];
  }

  getColumnsTransformers = () => {
    const { t } = this.props;

    return [
      {
        title: t('NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '50%',
      },
      {
        title: t('TYPE'),
        dataIndex: 'type',
        key: 'type',
        width: '50%',
        render: (text: string) => {
          return text === 'Трансформатор тока'
            ? t('TRANSFORMERS_TT_TYPE')
            : t('TRANSFORMERS_TN_TYPE');
        },
      },
      // {
      //   title: t('COL_TRANSFORMER_WINDINGS'),
      //   dataIndex: 'windings',
      //   key: 'windings',
      //   width: '60%',
      //   className: 'windings',
      //   render: (windings: Windings[]) => this.renderWindings(windings),
      // },
    ];
  };

  handleCancel = () => {
    const { closeModal } = this.props;

    this.setState({
      modalVisibility: false,
    });

    closeModal();
  };

  onRow = (record: Transformers) => {
    return {
      onClick: () => {
        this.setState({
          selectedTransformers: record.id,
        });
      },
    };
  }

  getRowClassName = (record: Transformers) => {
    const { selectedTransformers } = this.state;
    return record.id === selectedTransformers
      ? 'rowSelected'
      : '';
  }

  render() {
    const { t } = this.props;
    const { modalVisibility, data, selectedTransformers, isLoading } = this.state;

    const selectedTransformer: Transformers|undefined|null = (
      selectedTransformers
      && data
      && data.length
        ? data.find((x) => x.id === selectedTransformers)
        : undefined
    );

    const windings: NewWindings[] | undefined = selectedTransformer
      ? selectedTransformer.windings.map((winding) => {
        winding.type = selectedTransformer.type;

        const mapType = (original: Windings): NewWindings => ({
          id: original.id,
          name: original.name,
          kt: original.kt,
          secondary: original.secondary,
          winding_connection: original.winding_connection,
          type: original.type,
          winding_labels: original?.winding_labels[0]?.name,
          winding_labelsId: original?.winding_labels[0]?.id,
        });

        const resultWindings = mapType(winding);

        return resultWindings;
      })
      : undefined;

    const isShowWindings = !!(windings && windings.length);

    return (
      <Modal
        className='transformer-modal'
        width='80%'
        title={t('MODAL_TRANSFORMERS_TITLE')}
        visible={modalVisibility}
        onOk={this.handleCancel}
        onCancel={this.handleCancel}
        footer={[
          <Button key="close" type="primary" onClick={this.handleCancel}>
            {t('MODAL_TRANSFORMERS_CANCEL')}
          </Button>,
        ]}
      >
        <CustomCard
          hasData={!!(data && data.length)}
          title={t('TRANSFORMERS_LIST')}
          style={{ height: '48%', maxHeight: '48%', minHeight: '48%' }}
        >
          <CommonTable
            size='small'
            tableName='transformersTable'
            loading={isLoading}
            columns={this.getColumnsTransformers()}
            dataSource={data}
            onRow={(record) => this.onRow(record as Transformers)}
            rowClassName={(record) => this.getRowClassName(record as Transformers)}
            locale={{ emptyText: <Empty description={(<span>{t('NOT_FOUND_BY_PARAMETERS')}</span>)} /> }}
          />
        </CustomCard>
        <CustomCard
          hasData={isShowWindings}
          title={isShowWindings ? t('TRANSFORMERS_WINDINGS_LIST') : t('TRANSFORMERS_MUST_BE_SELECTED')}
          style={{ height: '48%', maxHeight: '48%', minHeight: '48%' }}
        >
          {
            isShowWindings
              ? (
                <CommonTable
                  size='small'
                  tableName='transformersWindingTable'
                  loading={isLoading}
                  columns={this.getColumnsWindings()}
                  dataSource={windings}
                  locale={{ emptyText: <Empty description={(<span>{t('NOT_FOUND_BY_PARAMETERS')}</span>)} /> }}
                />
              )
              : ''
          }
        </CustomCard>
      </Modal>
    );
  }
}

export default withTranslation()(
  withPagination(
    ModalShowTransformers
  )
);
