import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { TableProps, TablePaginationConfig } from 'antd/lib/table/Table';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import isEqual from 'lodash/isEqual';
import storage from '@services/storage';

import { PAGE_CHANGER_STEPS } from '@globalConstants';

import ResizableHeaderCell from './ResizableHeaderCell';

import { Column } from './types';

import styles from './styles.module.less';

interface Props extends TableProps<object> {
  scrollX?: boolean;
  columns: Column[];
  tableName: string|null;
  width?: number;
  handleActivateResetTableBtn?: (val: boolean) => void;
  onShowSizeChange?: (current: number, size: number) => void;
}

type State = {
  tableWidth: number;
  initialWidths: { [key: string]: number };
  widths: { [key: string]: number };
};

class CommonTable extends PureComponent<Props, State> {
  tableRef = React.createRef<HTMLDivElement>();

  state: State = {
    tableWidth: 0,
    initialWidths: {},
    widths: {},
  };

  components = {
    header: {
      cell: ResizableHeaderCell,
    },
  };

  componentDidMount() {
    this.setColumnsWidths();
    window.addEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { tableWidth, initialWidths, widths } = prevState;
    const {
      columns, tableName, handleActivateResetTableBtn,
    } = this.props;
    const tableElement = this.tableRef.current?.getElementsByTagName('table')[0];

    if (tableWidth !== tableElement?.clientWidth) {
      this.setState({
        tableWidth: tableElement?.clientWidth || 0,
      });
    }

    if (columns?.length !== prevProps.columns.length) {
      this.setInitialColumnsWidths();
      this.setColumnsWidths();
    }

    this.resizeHandler();

    const storageColumnWidthName = `columns_width_${tableName}`;

    // сброс значений ширины колонок
    if (!storage.get(storageColumnWidthName)
      && !isEqual(this.state.initialWidths, this.state.widths)
    ) {
      this.setColumnsWidths();
      storage.set(storageColumnWidthName, '{}');
    }

    if (isEqual(initialWidths, widths) && !isEqual(this.state.initialWidths, this.state.widths)) {
      handleActivateResetTableBtn && handleActivateResetTableBtn(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler = () => {
    const { width } = this.props;
    const tableElement = this.tableRef.current?.getElementsByTagName('table')[0];

    if (tableElement
      && this.tableRef.current?.clientWidth
      && width
    ) {
      if (this.tableRef.current?.clientWidth < width) {
        tableElement.style.width = `${width}px`;
      } else {
        tableElement.style.width = '100%';
      }
    }
  };

  setInitialColumnsWidths = () => {
    const { columns } = this.props;
    const nextWidths: { [key: string]: number } = {};

    columns && columns.forEach((column) => {
      if (column.width) nextWidths[column.key] = parseFloat(column.width);
    });

    this.setState({
      initialWidths: nextWidths,
    });
  };

  setColumnsWidths = () => {
    const { tableName, columns } = this.props;
    let nextWidths: { [key: string]: number } = {};

    const storageColumnWidth = storage.get(`columns_width_${tableName}`);

    columns && columns.forEach((column) => {
      if (column.width) nextWidths[column.key] = parseFloat(column.width);
    });

    if (storageColumnWidth) {
      nextWidths = {
        ...nextWidths,
        ...storageColumnWidth,
      };
    }

    this.setState({
      widths: nextWidths,
    });
  };

  handleResize = (key: number | string | undefined, index: number) => (e: any) => {
    const { columns } = this.props;
    const { tableWidth, widths } = this.state;
    const rightColumn = columns[index + 1];

    if (rightColumn && key) {
      const nextwidths: { [key: string]: number } = { ...widths };

      nextwidths[key] = widths[key] + (e.movementX * 100) / tableWidth;
      nextwidths[rightColumn.key] = widths[rightColumn.key] - (e.movementX * 100) / tableWidth;

      const colMinWidth: number = columns[index].minWidth || 50;
      const rightColMinWidth: number = rightColumn.minWidth || 50;

      if (
        colMinWidth
        && rightColMinWidth
        && ((tableWidth * (nextwidths[key] / 100)) > colMinWidth)
        && (tableWidth * (nextwidths[rightColumn.key] / 100)) > rightColMinWidth
      ) {
        this.setState({ widths: nextwidths });
      }
    }
  };

  handleResizeStop = (key: number | string | undefined, index: number) => () => {
    const { columns } = this.props;
    const { widths } = this.state;
    const stringKey = key?.toString();
    const rightColumn = columns[index + 1];

    if (stringKey) this.saveColumnWidthToLocalStorage(stringKey, widths[stringKey]);
    if (rightColumn) this.saveColumnWidthToLocalStorage(rightColumn.key, widths[rightColumn.key]);
  };

  saveColumnWidthToLocalStorage = (key: string, width: number) => {
    const { tableName } = this.props;
    const storageColumnWidthName = `columns_width_${tableName}`;
    const storageColumnWidth = storage.get(storageColumnWidthName);

    if (storageColumnWidth) {
      storage.set(storageColumnWidthName, {
        ...storageColumnWidth,
        [key]: width,
      });
    } else {
      storage.set(storageColumnWidthName, {
        [key]: width,
      });
    }
  };

  saveTableRowsSizeToLocalStorage = (size: number|string) => {
    const { tableName } = this.props;

    const numberSize = typeof size === 'number'
      ? size
      : parseInt(size, 10);

    storage.set(`table_rows_size__${tableName}`, numberSize);
  };

  rowClassNameForArchivedRecord = (rec: any) => {
    return rec && (rec.is_archived || rec.is_archived === 'true' || rec?.status === 'archived')
      ? 'isArchivedTableRow'
      : '';
  }

  onShowSizeChange = (current: number, size: number) => {
    const { onShowSizeChange } = this.props;

    this.saveTableRowsSizeToLocalStorage(size);
    onShowSizeChange && onShowSizeChange(current, size);
  }

  render() {
    const {
      dataSource, scrollX, width, columns, pagination,
      className, rowClassName, rowKey, ...restProps
    } = this.props;
    const { tableWidth, widths } = this.state;

    const nextColumns: ColumnsType<object> = columns?.map((col, index: number) => {
      let result = {
        ...col,
        ellipsis: true,
        width: widths[col.key] ? `${widths[col.key]}%` : col.fixedWidth,
        minWidth: col.minWidth || 50,
      };
      // тип onHeaderCell не соответствует примеру в документации
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
     
      if (col?.resizable !== false) // @ts-ignore
        result.onHeaderCell = (column: ColumnType<object>) => ({
          width: column.width ? tableWidth * (parseFloat(column.width.toString()) / 100) : col.fixedWidth,
          onResize: this.handleResize(column.key, index),
          onResizeStop: this.handleResizeStop(column.key, index),
          resizable: true,
        });

      return result;
    });

    let paginationChanger: TablePaginationConfig | false;

    if (pagination) {
      paginationChanger = { ...pagination };
      paginationChanger.showSizeChanger = true;
      paginationChanger.pageSizeOptions = PAGE_CHANGER_STEPS;
      paginationChanger.onShowSizeChange = this.onShowSizeChange;
      paginationChanger.showQuickJumper = true;

      if (Array.isArray(dataSource) && pagination?.pageSize) {
        const dataLength = dataSource.length;

        if (dataLength > pagination.pageSize) paginationChanger.pageSize = dataLength;
        else paginationChanger.pageSize = pagination.pageSize;
      }
    } else {
      paginationChanger = false;
    }

    const newRowClassName = (rec: any, idx: number, idt: number) => {
      const oldRowClass = (rowClassName && typeof rowClassName === 'function'
        ? rowClassName(rec, idx, idt)
        : (typeof rowClassName === 'string'
          ? rowClassName
          : ''
        )
      );
      const newRowClass = this.rowClassNameForArchivedRecord(rec);
      return `${oldRowClass} ${newRowClass}`.trim();
    };
    return (
      <div ref={this.tableRef}>
        <Table
          {...restProps}
          rowClassName={newRowClassName}
          rowKey={rowKey || 'id'}
          bordered={restProps.bordered || true}
          tableLayout='fixed'
          className={`${styles.table} ${className} ${scrollX ? styles.scrolledX : ''}`}
          columns={nextColumns}
          dataSource={dataSource}
          components={this.components}
          pagination={paginationChanger}
          size='small'
        />
      </div>
    );
  }
}

export default CommonTable;
