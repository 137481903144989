import React, { ReactChild } from 'react';
import c from 'classnames';
import Content from './content';
import Label from './label';
import Parameter from './parameter';

import './styles.less';

interface Props {
  className?: string;
  children?: ReactChild;
}

const SidebarCard = (props: Props) => {
  const { className, children } = props;
  const classes = c('sidebar-layout__card', className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

SidebarCard.content = Content;
SidebarCard.label = Label;
SidebarCard.parameter = Parameter;

export default SidebarCard;
