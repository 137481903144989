import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import api from '@services/api';

import {
  Button, Popconfirm, message,
} from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { COMMON_CLASSIFICATORS_FORM } from '@common/sidebarRoot/types';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { TABLE_SIZE, RIGHT_ROUTE_CLASSIFIERS, MODE_EDIT } from '@globalConstants';
import { textHighlighter } from '@globalHelpers';
import { CustomCard } from '@ui';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import styles from './styles.module.less';

const methods = {
  tags: 'Tags',
  status: 'DocumentStatuses',
  type: 'DocumentTypes',
};
const mapClassificatorToRequestURL = {
  tags: '/v1/tags/',
  status: '/v1/documents-statuses/',
  type: '/v1/documents-types/',
};

class DocClassificators extends Component {
  tableNameTemplate = 'classificators_tab__documents_';

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      count: 0,
      activeTab: 'tags',
      tableName: `${this.tableNameTemplate}_tags`,
    };
  }

  componentDidMount() {
    const { setTableName } = this.props;
    const { tableName } = this.state;
    setTableName(tableName, () => this.loadData());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedItem, isSidebarOpen, closeFormSidebar, archived, changePage,
    } = this.props;
    const { activeTab, sidebarTypeMode } = this.state;

    if (
      prevProps.selectedItem !== selectedItem
      && isSidebarOpen
      && prevState.activeTab === activeTab
    ) {
      this.openForm(sidebarTypeMode);
    }

    if (prevState.activeTab !== activeTab && isSidebarOpen) {
      closeFormSidebar();
    }

    if (prevProps.archived !== archived) {
      changePage(1, () => this.loadData());
    }
  }

  componentWillUnmount() {
    const { isSidebarOpen, closeFormSidebar } = this.props;

    if (isSidebarOpen) closeFormSidebar();
  }

  loadData = async (addParams = {}, callback) => {
    const { loadByPage, filterValues } = this.props;
    const { activeTab, tableName } = this.state;
    const requestParams = { ...filterValues, ...addParams };

    this.setState({ isLoading: true });
    await loadByPage(tableName, `get${methods[activeTab]}`, requestParams, 200, (status, data) => {
      this.setState({
        data: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isLoading: false });
  };

  openForm = async (isEditMode = false) => {
    const {
      isSidebarOpen, t, openFormSidebar, closeFormSidebar, selectedItem, changePage, onSelectItem,
    } = this.props;
    const { data, activeTab } = this.state;
    let item;

    if (isSidebarOpen) {
      await closeFormSidebar();
      this.setState({ sidebarTypeMode: '' });
    }

    let method = `create${methods[activeTab]}`;

    if (isEditMode) {
      item = data.find((x) => x.id === selectedItem.id);
      method = `update${methods[activeTab]}`;
    }

    openFormSidebar(
      COMMON_CLASSIFICATORS_FORM,
      {
        item,
        method,
        label: t(`CLASSIFICATORS_DOCUMENTS_${activeTab.toUpperCase()}`),
        callback: async (response) => {
          closeFormSidebar();

          changePage(1, async () => {
            await this.loadData({ selected: response.id }, () => {
              const { data: updatedData } = this.state;
              onSelectItem(updatedData[0]);
            });
          });
        },
      }
    );

    this.setState({ sidebarTypeMode: isEditMode });
  };

  deleteItem = async () => {
    const { t, selectedItem, onSelectItem } = this.props;
    const { activeTab } = this.state;

    this.setState({ isDeleting: true });
    const { status } = await api[`delete${methods[activeTab]}`](selectedItem.id);
    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('CLASSIFICATORS_DOCUMENTS_DELETE_SUCCESS'));
      this.loadData();
      onSelectItem({});
    } else {
      message.error(t('CLASSIFICATORS_DOCUMENTS_DELETE_ERROR'));
    }
  };

  onTabChange = (activeTab) => {
    const {
      onSelectItem, changePage, setTableName, resetFilters,
    } = this.props;

    onSelectItem({});
    resetFilters(
      changePage(1, () => {
        this.setState(
          {
            activeTab,
            tableName: `${this.tableNameTemplate}_${activeTab}`,
          },
          () => setTableName(`${this.tableNameTemplate}_${activeTab}`, () => this.loadData())
        );
      })
    );
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      t, changePage, onShowSizeChange, page, limit, getColumnSearchFilter, filterValues,
      onSelectItem, selectedItem, rights,
    } = this.props;
    const {
      isLoading, isDeleting, data, count, activeTab, tableName,
    } = this.state;

    const isEditRight = rights[RIGHT_ROUTE_CLASSIFIERS] === MODE_EDIT;
    const isSystem = selectedItem && selectedItem.is_system === true;

    const tabList = [
      {
        key: 'tags',
        tab: t('CLASSIFICATORS_DOCUMENTS_TAGS'),
      },
      {
        key: 'status',
        tab: t('CLASSIFICATORS_DOCUMENTS_STATUSES'),
      },
      {
        key: 'type',
        tab: t('CLASSIFICATORS_DOCUMENTS_TYPES'),
      },
    ];

    const columns = [
      {
        title: t('CLASSIFICATORS_DOCUMENTS_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '100%',
        minWidth: 150,
        ...getColumnSearchFilter('name', 'name__icontains', null, this.handleResetCallback),
        render: (text, record) => (
          text
            ? (record.is_system
              ? (
                <span className={styles.systemRecord} title={t('CLASSIFICATORS_DOCUMENTS_SYSTEM_TYPE')}>
                  {textHighlighter(filterValues['name__icontains'], text)}
                </span>
              )
              : textHighlighter(filterValues['name__icontains'], text)
            )
            : ''
        ),
      },
    ];

    return (
      <CustomCard
        className='documents-list'
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={this.onTabChange}
        tabBarExtraContent={(
          <>
            <Button
              onClick={() => this.openForm()}
              className='control-button'
              size='small'
              icon={<PlusCircleOutlined />}
              disabled={!isEditRight}
            >
              {t('CLASSIFICATORS_DOCUMENTS_ADD')}
            </Button>
            <Button
              onClick={() => this.openForm(true)}
              disabled={!selectedItem.id || isDeleting || !isEditRight || isSystem}
              className='control-button'
              size='small'
              icon={<EditOutlined />}
            >
              {t('CLASSIFICATORS_DOCUMENTS_EDIT')}
            </Button>
            <Popconfirm
              title={t('CLASSIFICATORS_DOCUMENTS_CONFIRM_DELETE')}
              onConfirm={this.deleteItem}
              okText={t('CLASSIFICATORS_DOCUMENTS_DELETE')}
              cancelText={t('CLASSIFICATORS_DOCUMENTS_CANCEL')}
              disabled={!isEditRight}
            >
              <Button
                disabled={!selectedItem.id || isDeleting || !isEditRight || isSystem}
                loading={isDeleting}
                className='control-button'
                size='small'
                icon={<DeleteOutlined />}
              >
                {t('CLASSIFICATORS_DOCUMENTS_DELETE')}
              </Button>
            </Popconfirm>
          </>
        )}
      >
        <FixedYCommonTable
          tableName={tableName}
          size={TABLE_SIZE}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={{
            current: page,
            onChange: (newPage) => changePage(newPage, this.loadData),
            total: count,
            pageSize: limit,
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedItem.id],
            onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0]),
          }}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, tableName, this.loadData)
          }
        />
        <ExportBottom
          count={count}
          className='control-button'
          size='small'
          WSData={{
            url: mapClassificatorToRequestURL[activeTab],
            accept: 'application/xlsx',
          }}
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isOpen,
  sidebarType: state.sidebar.element_type,
  rights: state.rights.rightsData,
  archived: state.archived,
});
const mapDispatchToProps = {
  openFormSidebar: openSidebar,
  closeFormSidebar: closeSidebar,
};
const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      DocClassificators
    )
  )
);
