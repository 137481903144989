import React from 'react';
import { withTranslation } from 'react-i18next';

import store from '@state/store';
import { closeSidebar } from '@state/sidebar/actions';
import { Layout } from '@ui';

import ChosenDevices from './chosenDevices';

import styles from './styles.module.less';

export class ChosenDevicesRoute extends React.Component {
  componentDidMount() {
    store.dispatch(closeSidebar());
  }

  render() {
    const { t } = this.props;
    return (
      <Layout childrenClassName={styles.chosenDevicesWrapper} title={t('CHOSEN_DEVICES_TITLE')}>
        <ChosenDevices {...this.props} />
      </Layout>
    );
  }
}

export default withTranslation()(
  ChosenDevicesRoute
);
