import React from 'react';
import { Spin } from 'antd';

import api from '@services/api';

import CommentCard from './commentCard';
import NotificationCard from '../notificationCard';

import styles from '../styles.module.less';

class ThreadCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
    };
  }

  componentDidMount = async () => {
    this.setState({ isCommentsLoading: true });
    await this.loadComments();
    this.setState({ isCommentsLoading: false });
  };

  makeTreeComments = (comments = [], parentId = null) => {
    const result = [];

    comments.forEach((comment) => {
      if (comment.parent === parentId) {
        const newComment = { ...comment };
        newComment.answers = this.makeTreeComments(comments, newComment.id);
        result.push(newComment);
      }
    });

    return result;
  };

  loadComments = async () => {
    const { selectedNotification, updateNotificationNoteCnt } = this.props;

    const { status, data } = await api.getTaskDcSetpointValueNotes({
      task_dc_notification__id: selectedNotification.id,
    });

    if (status === 200) {
      this.setState({ comments: this.makeTreeComments(data.results) });

      updateNotificationNoteCnt(data.count);
    }
  };

  updateComments = () => {
    this.setState({ isCommentsLoading: true });
    this.loadComments();
    this.setState({ isCommentsLoading: false });
  };

  handleAnswer = (selectedComment) => {
    const { setSelectedComment } = this.props;

    setSelectedComment(selectedComment);
  };

  render() {
    const { selectedNotification, selectedComment } = this.props;

    const { comments, isCommentsLoading } = this.state;

    return (
      <Spin spinning={isCommentsLoading}>
        <NotificationCard key={selectedNotification.id} {...selectedNotification} />
        {!isCommentsLoading && comments && !!comments.length && (
          <div className={styles.setpointDcCard}>
            {comments
              && comments.map((comment) => (
                <CommentCard
                  {...comment}
                  key={comment.id}
                  isSelected={selectedComment.id === comment.id}
                  selectedComment={selectedComment}
                  handleAnswer={this.handleAnswer}
                />
              ))}
          </div>
        )}
      </Spin>
    );
  }
}

export default ThreadCard;
