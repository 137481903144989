import React, { ReactNode } from 'react';
import c from 'classnames';

import './styles.less';

interface Props {
  className?: string;
  children?: ReactNode;
}

const SidebarCardContent = (props: Props) => {
  const { className, children } = props;
  const classes = c('sidebar-layout__content', className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default SidebarCardContent;
