import React, { Component, Key } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { History } from 'history';

import { RootState } from '@state/store';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { Column } from '@common/CommonTable/types';
import { TABLE_SIZE } from '@globalConstants';
import { CustomTag, PopoverTags } from '@ui';

import { withColumnFilters, withPagination, withRowSelection } from '@hoc';
import api from '@services/api';
import { EventModuleSerializer } from '@services/api-dts';
import { getLinkForDocument } from '@routes/passport/helpers';
import styles from './styles.module.less';

interface Props extends WithTranslation{
  setComponentRef?: (ref: any) => void;
  deviceId?: string|null;
  onSelectedEventChange: (selectedRowKeys: Key[], selectedRows: object[] | EventModuleSerializer | Item) => void;
  selectedEvent: string | boolean;
  setTableName: (tableName: string|null, callback?: Function) => void;
  tableName?: string|null;
  history: History;
  t: TFunction;
  selectedItem: EventModuleSerializer;
  onSelectItem: (item: EventModuleSerializer | Item) => void;
  page: number;
  limit: number;
  changePage: (page: number, callback?: () => void) => void;
  onShowSizeChange: (current: number, size: number, name?: string, callback?: Function) => void;
  loadByPage: (
    tableName: string|null,
    apiFunctionName: string,
    mainParams: {},
    okStatuses: number[] | number,
    callbackOk?: ((status: number, data: { results: EventModuleSerializer[]; count: number }) => void) | null,
    callbackError?: (() => void) | null
  ) => void;
  resetFilters: (callback?: Function) => void;
  filterValues: filterRecord;
  getColumnSearchFilterList: (
    t: TFunction,
    dataIndex: string,
    getList: (name: string, selected: string[], filterValuesList: filterRecord) => void,
    searchCallback: (filterValuesList: filterRecord) => void,
    resetCallback?: () => void,
  ) => object;
  passportMode?: boolean;
}

type filterRecord = Record<string, Key[] | null>;

type Item = {
  id: string;
  name: string;
}

interface State {
  count: number;
  events: Item[]|EventModuleSerializer[];
  isLoading: boolean;
}

type Document = {
  id: string;
  name: string;
  external_links: string;
};

class EventsTable extends Component<Props, State> {
  tableName = 'classificators_tab_events';

  constructor(props: Props) {
    super(props);

    this.state = {
      count: 0,
      events: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { setComponentRef, tableName, setTableName } = this.props;

    setComponentRef && setComponentRef(this);

    if (tableName && tableName.length) {
      this.tableName = tableName;
    }

    setTableName(this.tableName, () => this.loadEvents());
  }

  componentWillUnmount() {
    const { setComponentRef } = this.props;
    setComponentRef && setComponentRef(undefined);
  }

  getListOwners = (name: string, selected: string[]) => {
    const filter = {
      name: name || undefined,
      selected: selected || undefined,
    };

    return api.getDataForFilter('/v1/owners/', filter);
  };

  getListSubstation = (name: string, selected: string[]) => {
    const filter = {
      name: name || undefined,
      selected: selected || undefined,
    };

    return api.getDataForFilter('/v1/substations/tree', filter);
  };

  loadEvents = async (addParams = {}, callback?: Function) => {
    const { deviceId, loadByPage } = this.props;

    const params: { [key: string]: string | number } = {
      ...addParams,
    };

    if (deviceId) {
      params.protection_devices = deviceId;
    }

    this.setState({ isLoading: true });

    await loadByPage(this.tableName, 'getEvents', params, 200, (status, data) => {
      this.setState({
        events: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });
    });

    this.setState({ isLoading: false });

    const { events } = this.state;
    if (params && params.selected) {
      let selected: Item|EventModuleSerializer|boolean = false;

      if (events && events.length) {
        events.forEach((x: Item|EventModuleSerializer) => {
          if (x.id === params.selected) {
            selected = x;
          }
        });
      }

      return selected;
    }
  };

  updateEvents = async (eventId: string) => {
    const { changePage, onSelectedEventChange, resetFilters } = this.props;

    this.setState({ isLoading: true });

    resetFilters(async () => {
      await changePage(1, async () => {
        await this.loadEvents({ selected: eventId }, () => {
          const { events } = this.state;

          onSelectedEventChange([events[0].id as Key], events[0]);
        });
      });
    });

    this.setState({ isLoading: false });
  };

  removeEvent = async () => {
    const { changePage, onSelectedEventChange, resetFilters } = this.props;

    this.setState({ isLoading: true });

    resetFilters(async () => {
      await changePage(1, async () => {
        await this.loadEvents({}, () => {
          onSelectedEventChange([], []);
        });
      });
    });

    this.setState({ isLoading: false });
  };

  getFilter = (value: Item) => {
    return { text: value.name, value: value.id };
  };

  getFiltersForRequest = () => {
    const { filterValues } = this.props;

    return {
      substation: filterValues['substation'] && filterValues['substation'].length
        ? filterValues['substation']
        : undefined,
      coordination_subject: filterValues['subject'] && filterValues['subject'].length
        ? filterValues['subject']
        : undefined,
    };
  }

  clearFilter = (callback?: Function) => {
    const { resetFilters } = this.props;

    resetFilters(callback);
  }

  handleTableChange = () => {
    const { changePage } = this.props;

    changePage(0, () => this.loadEvents(this.getFiltersForRequest()));
  };

  render() {
    const {
      t, history, onSelectedEventChange, selectedEvent, deviceId, passportMode = false,
      limit, page, changePage, onShowSizeChange, getColumnSearchFilterList,
    } = this.props;
    const { isLoading, events, count } = this.state;

    const columns: Column[] = [
      {
        title: t('EVENTS_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '10%',
      },
      {
        title: t('EVENTS_COMPLETION'),
        dataIndex: 'date_planned_completion',
        key: 'date_planned_completion',
        width: '10%',
      },
      {
        title: t('EVENTS_RISKS'),
        dataIndex: 'risks_default',
        key: 'risks_default',
        width: '10%',
      },
      {
        title: t('EVENTS_NOTE'),
        dataIndex: 'documentary_note',
        key: 'documentary_note',
        width: '10%',
      },
      {
        title: t('EVENTS_DOCUMENTS'),
        dataIndex: 'documentary_documents',
        key: 'documentary_documents',
        width: '10%',
        render: (items: Document[]) => {
          const document = items && items.length && items[0];
          return document
            ? getLinkForDocument(this, document, true)
            : '';
        },
      },
      {
        title: t('EVENTS_IMPLEMENTATION_YEAR'),
        dataIndex: 'implementation_year',
        key: 'implementation_year',
        width: '10%',
      },
      {
        title: t('EVENTS_PROGRAM_DOCUMENTS'),
        dataIndex: 'executive_program_documents',
        key: 'executive_program_documents',
        width: '10%',
        render: (items: Document[]) => {
          const document = items && items.length && items[0];
          return document
            ? getLinkForDocument(this, document, true)
            : '';
        },
      },
      {
        title: t('EVENTS_OWNER'),
        dataIndex: 'subject',
        key: 'subject',
        width: '10%',
        ...getColumnSearchFilterList(
          t,
          'subject',
          this.getListOwners,
          this.handleTableChange
        ),
        render: (item: Item) => ((item && item.name) || ''),
      },
      {
        title: t('EVENTS_SUBSTATION'),
        dataIndex: 'substation',
        key: 'substation',
        width: '10%',
        ...getColumnSearchFilterList(
          t,
          'substation',
          this.getListSubstation,
          this.handleTableChange
        ),
        render: (item: Item) => ((item && item.name) || ''),
      },
      {
        title: t('EVENTS_VOLTAGE_LEVEL'),
        dataIndex: 'voltage_level',
        key: 'voltage_level',
        width: '10%',
        render: (item: Item) => ((item && item.name) || ''),
      },
    ];

    if (history) {
      columns.push(
        {
          title: t('ATTACHED_DEVICES'),
          dataIndex: 'protection_devices',
          key: 'protection_devices',
          minWidth: 200,
          render: (items: Document[]) => {
            if (!items) {
              return null;
            }

            const content = items.map((device: Document) => (
              <React.Fragment key={device.id}>
                <CustomTag
                  className='link'
                  key={device.id}
                  onClick={() => history.push(`/passport/${device.id}/tab/reports`)}
                  disablePopover={true}
                  getPopupContainer={(triggerNode: HTMLElement) => (
                    triggerNode && triggerNode?.closest('table')
                      ? triggerNode?.closest('table')?.parentNode
                      : triggerNode
                  )}
                >
                  {device.name}
                </CustomTag>
                <br />
              </React.Fragment>
            ));

            return (
              <PopoverTags
                {...{ size: items.length, className: 'link' }}
                getPopupContainer={(triggerNode: HTMLElement) => (
                  triggerNode && triggerNode?.closest('table')
                    ? triggerNode?.closest('table')?.parentNode
                    : triggerNode
                )}
              >
                {content}
              </PopoverTags>
            );
          },
        }
      );

      columns.forEach((column) => {
        column.width = `${100 / columns.length}%`;
      });
    }

    return (
      <>
        <FixedYCommonTable
          tableName={this.tableName}
          size={TABLE_SIZE}
          columns={columns}
          dataSource={events}
          loading={isLoading}
          className={styles[`table${passportMode ? '_forPassport' : '_common'}`]}
          pagination={{
            onChange: (newPage: number) => changePage(newPage, () => this.loadEvents(this.getFiltersForRequest())),
            total: count,
            pageSize: limit,
            current: page,
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: typeof selectedEvent === 'string' && selectedEvent ? [selectedEvent] : [],
            onChange: onSelectedEventChange,
          }}
          onShowSizeChange={
            (current: number, pageSize: number) => onShowSizeChange(
              current,
              pageSize,
              this.tableName,
              () => this.loadEvents(this.getFiltersForRequest())
            )
          }
          scroll={{ x: 1200, y: 480 }}
        />
        <ExportBottom
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          size='small'
          count={count}
          WSData={{
            url: deviceId
              ? '/v1/passport-events/'
              : '/v1/events/',
            accept: 'application/xlsx',
            params: { protection_devices: deviceId },
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  wsState: state.websocket,
});

export default connect(mapStateToProps)(
  withPagination(
    withRowSelection(
      withColumnFilters(
        withTranslation()(
          EventsTable
        )
      )
    )
  )
);
