import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Card, Button, Badge } from 'antd';

import api from '@services/api';
import { ExportBottom } from '@common/exportPopconfirm';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { PopoverLoadingTags, CustomTag, PopoverTags  } from '@ui';
import { getLinkForDocument } from '@routes/passport/helpers';

import AbstractTab from '../../abstractTab';
import ResetTableBtn from '../../ResetTableBtn';

import styles from './styles.module.less';
import { globalSearchTableNamePrefix } from '../../constants';

const prefix = 'DOCUMENT';
const filters = [
  {
    name: 'DOCUMENT.COL_NAME',
    field: 'name',
    queryFiled: 'name',
    type: 'STRING',
    width: '13%',
    fixed: 'left',
    initialWidth: 190,
    visible: true,
    showVisibleButton: false,
  },
  {
    name: 'DOCUMENT.COL_DOCUMENT_TYPE',
    field: 'document_type',
    queryFiled: 'document_type',
    type: 'CHECK_LIST',
    width: '17%',
    initialWidth: 270,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/documents-types/', { name, selected });
    },
  },
  {
    name: 'DOCUMENT.COL_TAG',
    field: 'tags',
    queryFiled: 'tag',
    type: 'CHECK_LIST',
    width: '7.25%',
    initialWidth: 100,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/tags/', { name, selected });
    },
  },
  {
    name: 'DOCUMENT.COL_STATUS',
    field: 'document_status',
    queryFiled: 'document_status',
    type: 'CHECK_LIST',
    width: '9.5%',
    initialWidth: 135,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/documents-statuses/', { name, selected });
    },
  },
  {
    name: 'DOCUMENT.COL_ATTACHED_TO',
    field: 'passports',
    queryFiled: '',
    type: 'ONLY_VIEW_COLUMN',
    width: '7%',
    initialWidth: 90,
    visible: true,
    render: (arr, record) => (
      <PopoverLoadingTags
        size={-1}
        title='PASSPORTS'
        className='link'
        passportsEntity={true}
        loadTags={async (name, offset, limitQuery) => {
          const result = await api.getPassports({
            document: record.id,
            offset,
            limit: limitQuery,
            noChangePage: true,
          });
          if (result.status === 200 && result.data.results[0]) {
            return result.data.results;
          } else {
            return [];
          }
        }}
        link={(passportId) => `/passport/${passportId}/tab/documents`}
      />
    ),
  },
  {
    name: 'DOCUMENT.COL_IMPLEMENTATION_TYPE',
    field: 'meta_tags.implementation_types',
    queryFiled: 'implementation_type',
    type: 'CHECK_LIST',
    width: '10%',
    initialWidth: 145,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/implementation-types/', { name, selected });
    },
  },
  {
    name: 'DOCUMENT.COL_PRODUCER',
    field: 'meta_tags.producers',
    queryFiled: 'producer',
    type: 'CHECK_LIST',
    width: '9%',
    initialWidth: 140,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/producers/', { name, selected });
    },
  },
  {
    name: 'DOCUMENT.COL_TRADE_DEVICE',
    field: 'meta_tags.trade_devices',
    queryFiled: 'trade_device',
    type: 'CHECK_LIST',
    width: '13%',
    initialWidth: 200,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        implementation_type: filterValuesList['DOCUMENT.COL_IMPLEMENTATION_TYPE'],
        producer: filterValuesList['DOCUMENT.COL_PRODUCER'],
      };
      return api.getDataForFilter('/v1/trade-devices/', params);
    },
  },
  {
    name: 'DOCUMENT.COL_SOFTWARE_VERSION',
    field: 'meta_tags.versions',
    queryFiled: 'device_version',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 90,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        trade_device: filterValuesList['DOCUMENT.COL_TRADE_DEVICE'],
        custom_name: true
      };
      return api.getDataForFilter('/v1/versions/', params);
    },
    render: (content) => {
      if (!content?.length) return '';
      let tags = [];

      tags = content.map((tag) => (
        <CustomTag
          style={{ marginBottom: 5 }}
          key={tag.id}
          disablePopover
          disableEllipsis
          getPopupContainer={(triggerNode) => triggerNode.closest('tr')}
        >
          {tag.name}
        </CustomTag>
      ));

      return (
        <PopoverTags
          size={content?.length}
          getPopupContainer={(triggerNode) => triggerNode.closest('tr')}
          disableChildrenHiding
          style={{ width: 'min-content' }}
        >
          {tags}
        </PopoverTags>
      );
    },
  },
  {
    name: 'DOCUMENT.COL_FUNCTION',
    field: 'meta_tags.functions',
    queryFiled: 'func',
    type: 'CHECK_LIST',
    width: '7.25%',
    initialWidth: 105,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: async (name, selected) => {
      return api.getDataForFilter('/v1/functions/', { name__icontains: name, selected });
    },
  },
];

class Documents extends Component {
  tableName = `${globalSearchTableNamePrefix}${prefix.toLowerCase()}`;

  state = {
    isLocalLoading: false,
  };

  componentDidMount() {
    const { setTableName, setInitialFilters } = this.props;
    setTableName(this.tableName, () => {
      setInitialFilters();
    });
  }

  render() {
    const {
      t, data, count, isLoading, countFiltersWithValues, getListProps, searchValue,
      getColumns, onShowSizeChange, toggleFilter, filterValuesForExport, loadData, id,
      isActiveResetTableBtn, handleActivateResetTableBtn, handleResetTable,
    } = this.props;
    const { isLocalLoading, isDocDownloading } = this.state;

    const listProps = getListProps();
    const columns = getColumns(prefix);
    const isEditRight = true;

    if (columns && columns.length && columns[0] && columns[0].field === filters[0].field) {
      columns[0].render = (name, record) => getLinkForDocument(this, record, isEditRight, searchValue);
    }

    const searchParams = { ...filterValuesForExport };
    if (searchValue) {
      searchParams.search = searchValue;
    }

    let tableWidth = 0;

    columns.forEach((item) => {
      if (item.initialWidth) tableWidth += item.initialWidth;
    });

    const columnsWithWidth = columns.filter((item) => !!item.width);

    if (columnsWithWidth.length) {
      const hiddenColumns = filters
        .filter((filter) => !columnsWithWidth.find((column) => {
          const name = filter.field.indexOf('.') > 0 ? filter.field.substr(0, filter.field.indexOf('.')) : filter.field;
          const fieldKeys = filter.field.split('.');
          return column.key === ((fieldKeys.length > 1) ? fieldKeys[fieldKeys.length - 1] : name);
        }));

      if (hiddenColumns.length) {
        let hiddenWidth = 0;

        hiddenColumns.forEach((col) => {
          hiddenWidth += parseFloat(col.width);
        });

        columnsWithWidth.forEach((column) => {
          column.width = parseFloat(column.width) + hiddenWidth / columnsWithWidth.length;
        });
      }
    }

    return (
      <Card
        className={styles.card}
        extra={(
          <>
            <Badge offset={[-6, 3]} className={styles.badge} count={countFiltersWithValues} size='small'>
              <Button onClick={toggleFilter} size='small'>{t('FILTERS_AND_CONFIGURATIONS')}</Button>
            </Badge>
            <ResetTableBtn
              disabled={!isActiveResetTableBtn}
              onClick={handleResetTable}
            />
          </>
        )}
        bordered={false}
      >
        <FixedYCommonTable
          {...listProps}
          tableName={this.tableName}
          className={styles.table}
          columns={columns}
          dataSource={data}
          width={tableWidth}
          loading={isLoading || isLocalLoading || isDocDownloading}
          handleActivateResetTableBtn={handleActivateResetTableBtn}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, loadData)
          }
        />
        <ExportBottom
          count={count}
          WSData={{
            url: `/v1/${id}/`,
            params: searchParams,
            accept: 'application/xlsx',
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  wsState: state.websocket,
});

const Tab = AbstractTab(
  connect(mapStateToProps)(
    withTranslation()(
      withRouter(
        Documents
      )
    )
  )
);

export {
  Tab,
  filters,
  prefix,
};
