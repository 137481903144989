import React from 'react';
import './styles.less';

import { Popover } from 'antd';

export default class PopoverResizable extends React.Component {
  defaultHeight = 300;

  constructor(props) {
    super(props);

    this.state = {
      baseHeight: props.height || this.defaultHeight,
      height: props.height || this.defaultHeight,
      drag: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onUpdateHeight } = this.props;
    const { height } = this.state;

    if (height !== prevState.height && onUpdateHeight) {
      onUpdateHeight(height);
    }
  }

  componentWillUnmount() {
    this.onUp();
  }

  onMove = (e) => {
    const { height } = this.props;
    const { baseHeight } = this.state;

    this.setState((state) => ({ height: Math.max(baseHeight + e.clientY - state.drag, height || this.defaultHeight) }));
  };

  onUp = () => {
    window.removeEventListener('mousemove', this.onMove);
    window.removeEventListener('mouseup', this.onUp);

    this.setState((state) => ({ baseHeight: state.height }));
  };

  onMouseDown = (e) => {
    this.setState({ drag: e.clientY }, () => {
      window.addEventListener('mousemove', this.onMove);
      window.addEventListener('mouseup', this.onUp);
    });
  };

  getContent() {
    const { content, visible } = this.props;
    const { height } = this.state;

    return (
      visible && (
        <div>
          <div style={{ height }} className='popover-resizable-content'>
            {content}
          </div>
          <div
            role='button'
            tabIndex={0}
            className='popover-resizable-bottom'
            onMouseDown={this.onMouseDown}
          >
            <div className='popover-resizable-bottom-line' />
            <div className='popover-resizable-bottom-line' />
            <div className='popover-resizable-bottom-line' />
          </div>
        </div>
      )
    );
  }

  onVisibleChange = (e) => {
    const { onVisibleChange } = this.props;
    onVisibleChange && onVisibleChange(e);
  };

  render() {
    const { children } = this.props;

    return (
      <Popover
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        destroyTooltipOnHide={true}
        overlayClassName='popover-resizable'
        trigger='click'
        {...this.props}
        content={this.getContent()}
        onVisibleChange={this.onVisibleChange}
      >
        {children}
      </Popover>
    );
  }
}
