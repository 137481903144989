import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Tree, message, Row, Col, Button, Divider, Typography, Popover, Tag,
} from 'antd';
import Icon, { UnorderedListOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import api from '@services/api';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { PASSPORT_FUNCTION_EDIT } from '@common/sidebarRoot/types';
import CommonTable from '@common/CommonTable';
import { CustomCard, PopoverEllipsis } from '@ui';
import { RIGHT_PASSPORT_FUNCTIONS, MODE_EDIT, TABLE_SIZE } from '@globalConstants';
import { ReactComponent as TransformerIcon } from '@ui/icons/transformer.svg';

import { ROOT_ID } from './constants';
import ActionsComp from './actions';
import ModalShowTransformers from './ModalShowTransformers';

import styles from './styles.module.less';

const { TreeNode } = Tree;
const { Text } = Typography;

class Functions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoExpandParent: true,
      isHideEmpty: true,
      activeItem: ROOT_ID,
      treeValueData: {
        [ROOT_ID]: [],
      },
      treeData: [],
      existsFunctions: [],
      expandedKeys: [],
      showTree: true,
      modalVisible: false,
    };
  }

  componentDidMount() {
    const { passportId } = this.props;

    passportId && this.loadFunctions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { passportId, archived } = this.props;
    const { isHideEmpty } = this.state;

    if (
      passportId
      && (
        prevState.isHideEmpty !== isHideEmpty
        || prevProps.archived !== archived
        || prevProps.passportId !== passportId
      )
    ) {
      this.loadFunctions();
    }
  }

  loop = (data, callback) => {
    data.forEach((item, index, arr) => {
      callback(item, index, arr);
      if ('children' in item && item.children) {
        this.loop(item.children, callback);
      }
    });
  };

  loadFunctions = async () => {
    const { passportId } = this.props;
    const { isHideEmpty } = this.state;

    this.setState({ isLoading: true });

    const queryGroup = await api.getFunctionsTree(isHideEmpty, { passport: passportId }, true);
    const queryAll = await api.getProtectionDeviceFunctions({ passport: passportId });

    this.setState({ isLoading: false });

    if (queryGroup.status !== 200 || queryAll.status !== 200) {
      return;
    }

    const treeData = queryGroup.data.results;
    const parents = {};
    this.loop(treeData, (item) => {
      if (item.functions) {
        item.functions.forEach((x) => {
          parents[x.id] = item.id;
        });
      }
    });
    const valueData = this.getFullView(queryAll.data.results);
    const { treeValueData, existsFunctions } = this.filterAccountingGroups(valueData, parents);

    this.setState({ treeData, treeValueData, existsFunctions });
  };

  getFullView = (items) => {
    return items.map((item) => ({
      ...item,
      execute_calculations: item.execute_calculations.map((x) => ({
        ...x, name: x.execute_calculations_name, id: x.execute_calculations_id, link_id: x.id,
      })),
      reconcile_calculations: item.reconcile_calculations.map((x) => ({
        ...x, name: x.reconcile_calculations_name, id: x.reconcile_calculations_id, link_id: x.id,
      })),
      compose_task: item.compose_task.map((x) => ({
        ...x, name: x.compose_task_name, id: x.compose_task_id, link_id: x.id,
      })),
      reconcile_task: item.reconcile_task.map((x) => ({
        ...x, name: x.reconcile_task_name, id: x.reconcile_task_id, link_id: x.id,
      })),
      issue_task: item.issue_task.map((x) => ({
        ...x, name: x.issue_task_name, id: x.issue_task_id, link_id: x.id,
      })),
      inefficiency: !!item.inefficiency,
      reason: item.reason,
    }));
  };

  filterAccountingGroups = (items, parents) => {
    const rootArr = [];
    const treeValueData = {};
    const existsFunctions = [];
    items.forEach((item) => {
      if (item.compose_task) {
        rootArr.push(item);
        if (item.function && parents[item.function.id]) {
          if (!treeValueData[parents[item.function.id]]) {
            treeValueData[parents[item.function.id]] = [];
          }
          treeValueData[parents[item.function.id]].push(item);
          existsFunctions.push(item.function.id);
          // existsFunctions.push(item.function.parent);
        }
      }
    });
    treeValueData[ROOT_ID] = rootArr;
    return { treeValueData, existsFunctions };
  };

  renderTreeNodes = (treeData) => {
    if (!treeData) {
      return undefined;
    }

    return treeData.map((item) => (
      <TreeNode title={item.name || item.function.name} key={`${item.id}`}>
        {this.renderTreeNodes(item.children)}
      </TreeNode>
    ));
  };

  onSelect = (selectedKeys) => {
    if (!selectedKeys) {
      return;
    }

    this.setState({
      activeItem: selectedKeys[0],
      autoExpandParent: false,
    });
  };

  onRowSelect = (selectedKeys = [], selectedRecord = []) => {
    const { closeFunctionSidebar } = this.props;
    const { currentFunction } = this.state;

    if (!selectedKeys || currentFunction === selectedKeys[0]) {
      return;
    }

    closeFunctionSidebar();

    this.setState({
      currentFunction: selectedKeys[0],
      selectedRecord: selectedRecord[0],
    });
  };

  openFunctionSidebar = () => {
    const {
      currentDC, openFunctionSidebar, closeFunctionSidebar,
    } = this.props;
    const { selectedRecord } = this.state;

    closeFunctionSidebar();

    openFunctionSidebar({
      elementType: PASSPORT_FUNCTION_EDIT,
      selected: selectedRecord,
      onOk: this.updateFunction,
      onCancel: closeFunctionSidebar,
      defaultDC: currentDC,
    });
  }

  renderDcs = (items, fieldName, record) => {
    if (!items) {
      return null;
    }

    return (
      <div>
        {items.map((item) => 
          {
            const nameComposite = item[`${fieldName}_name`] || '';
            const itemName = item.name || nameComposite;

            return <div className={styles.tagWrapper} key={`div_${itemName}_${record.id}`}>
            <Tag key={`custom_${itemName}_${record.id}`}>
              <PopoverEllipsis content={itemName}>{itemName}</PopoverEllipsis>
            </Tag>
          </div>;}
        )}
      </div>
    );
  };

  updateFunction = async (values) => {
    const { t, closeFunctionSidebar } = this.props;
    const {
      currentFunction, activeItem,
    } = this.state;

    this.setState({ isUpdating: true });
    const { status } = await api.updatePassportFunction(currentFunction, values);
    this.setState({ isUpdating: false });

    if (status === 200) {
      message.success(t('EDIT_SUCCESS'));
      await this.loadFunctions();

      const { treeValueData } = this.state;

      this.setState({
        selectedRecord: { ...treeValueData[activeItem].find(({ id }) => id === currentFunction) },
      });
    } else {
      message.error(t('EDIT_ERROR'));
    }

    closeFunctionSidebar();
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  findActive = (treeData, activeItem) => {
    return treeData && treeData.reduce((result, item) => {
      if (result) return result;

      if (item.id === activeItem) return item;

      if (item.children) return this.findActive(item.children, activeItem);

      return null;
    }, null);
  };

  showModal = (functionId) => {
    this.setState({
      modalVisible: true,
      modalFunctionId: functionId,
    });
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  }

  renderTransformerImage = (record) => {
    const { t } = this.props;
    return (
      <Icon
        component={TransformerIcon}
        onClick={() => this.showModal(record.id)}
        className={styles.transformerIcon}
        title={t('MODAL_TRANSFORMERS_TITLE')}
      />
    );
  }

  render() {
    const {
      rights, passportId, deviceId, t, currentDC, isArchived,
    } = this.props;
    const {
      treeValueData,
      treeData,
      isLoading,
      activeItem,
      currentFunction,
      isUpdating,
      expandedKeys,
      autoExpandParent,
      existsFunctions,
      showTree,
      modalVisible,
      modalFunctionId,
      selectedRecord,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_FUNCTIONS] === MODE_EDIT && !isArchived;

    const isArchivedRow = selectedRecord && selectedRecord.is_archived;
    const editableFunctions = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP === undefined && !isArchived;
    const tableData = treeValueData[activeItem || ROOT_ID];
    const activeObject = this.findActive(treeData, activeItem);
    const showLeftTree = showTree && treeData && !!treeData.length;

    return (
      <CustomCard
        loading={isLoading}
        className={styles.passportFunctions}
        bodyStyle={{ display: 'flex' }}
      >
        <Row className={styles.passportTableRow}>
          {showLeftTree && (
            <Col span={6} className={(treeData && !!treeData.length && styles.treeFunctions) || ''}>
              {treeData && !!treeData.length && (
                <Tree
                  onSelect={this.onSelect}
                  onExpand={this.onExpand}
                  expandedKeys={[...expandedKeys]}
                  selectedKeys={[activeItem]}
                  autoExpandParent={autoExpandParent}
                >
                  <TreeNode title={t('FUNCTIONS_TREE_ROOT_GROUP_NAME')} key={ROOT_ID}>
                    {this.renderTreeNodes(treeData)}
                  </TreeNode>
                </Tree>
              )}
            </Col>
          )}
          <Col span={showLeftTree ? 18 : 24} style={{ minHeight: 280 }}>
            <Row className={styles.actions}>
              <Button
                type={showTree ? 'primary' : 'default'}
                size='small'
                icon={<UnorderedListOutlined />}
                onClick={() => this.setState((state) => ({ showTree: !state.showTree }))}
              />
              &nbsp;
              <Divider type='vertical' />
              &nbsp;
              <ActionsComp
                t={t}
                deviceId={deviceId}
                passportId={passportId}
                refreshData={this.loadFunctions}
                currentItem={currentFunction}
                isEditRight={isEditRight && !isArchivedRow}
                editableFunctions={editableFunctions}
                isUpdating={isUpdating}
                openFunctionSidebar={this.openFunctionSidebar}
                updateFunction={this.updateFunction}
                resetSelection={this.onRowSelect}
                existsFunctions={existsFunctions}
                currentDC={currentDC}
              />
            </Row>
            {treeData && (
              <Row className={styles.tableHeader}>
                {activeObject ? activeObject.name : t('FUNCTIONS_TREE_ROOT_GROUP_NAME')}
                {tableData && (<Text code>{tableData.length}</Text>)}
              </Row>
            )}
            <Row className={styles.tableRow}>
              <CommonTable
                tableName='passport_tab_functions'
                size={TABLE_SIZE}
                showHeader={!!(tableData && tableData.length)}
                columns={[
                  {
                    // title: t('NAME'),
                    dataIndex: 'has_transformers',
                    key: 'has_transformers',
                    width: '3%',
                    minWidth: '20px',
                    maxWidth: '20px',
                    resizable: false,
                    render: (item, record) => (item
                      ? this.renderTransformerImage(record)
                      : ''
                    ),
                  },
                  {
                    title: t('NAME'),
                    dataIndex: 'name',
                    key: 'name',
                    width: '11.1%',
                  },
                  {
                    title: t('FUNCTION_TYPE'),
                    dataIndex: ['function', 'name'],
                    key: 'function.name',
                    width: '11.1%',
                  },
                  {
                    title: t('PASSPORT_FUNCTIONS_EXECUTE'),
                    dataIndex: 'execute_calculations',
                    key: 'execute_calculations',
                    width: '11.1%',
                    render: (items, record) => this.renderDcs(items, 'execute_calculations', record),
                  },
                  {
                    title: t('PASSPORT_FUNCTIONS_RECONCILE'),
                    dataIndex: 'reconcile_calculations',
                    key: 'reconcile_calculations',
                    width: '11.1%',
                    render: (items, record) => this.renderDcs(items, 'reconcile_calculations', record),
                  },
                  {
                    title: t('PASSPORT_FUNCTIONS_TASK'),
                    dataIndex: 'compose_task',
                    key: 'compose_task',
                    width: '11.1%',
                    render: (items, record) => this.renderDcs(items, 'compose_task', record),
                  },
                  {
                    title: t('PASSPORT_FUNCTIONS_RECONCILE_TASK'),
                    dataIndex: 'reconcile_task',
                    key: 'reconcile_task',
                    width: '11.1%',
                    render: (items, record) => this.renderDcs(items, 'reconcile_task', record),
                  },
                  {
                    title: t('PASSPORT_FUNCTIONS_ISSUE_TASK'),
                    dataIndex: 'issue_task',
                    key: 'issue_task',
                    width: '11.1%',
                    render: (items, record) => this.renderDcs(items, 'issue_task', record),
                  },
                  {
                    title: t('PASSPORT_FUNCTIONS_TECHNICAL_ACCOUNTING'),
                    dataIndex: 'technical_accounting',
                    key: 'technical_accounting',
                    width: '11.1%',
                    render: (items, record) => this.renderDcs(items, 'dispatcher_center', record),
                  },
                  {
                    title: t('NOT_EFFECTIVE'),
                    dataIndex: 'inefficiency',
                    key: 'inefficiency',
                    width: '11.1%',
                    render: (item, record) => (
                      item && (
                        <Popover content={record.reason}>
                          <ExclamationCircleOutlined />
                        </Popover>
                      )
                    ),
                  },
                ]}
                bordered={false}
                dataSource={tableData}
                pagination={{
                  hideOnSinglePage: true,
                }}
                onRow={
                  (record) => ({
                    onClick: () => {
                      this.onRowSelect([record.id], [record]);
                    },
                  })
                }
                rowSelection={
                  isEditRight
                    ? {
                      selectedRowKeys: [currentFunction],
                      type: 'radio',
                      onChange: this.onRowSelect,
                    }
                    : null
                }
              />
            </Row>
          </Col>
        </Row>
        <ModalShowTransformers
          functionId={modalFunctionId}
          modalVisibility={modalVisible}
          closeModal={this.closeModal}
          passportId={passportId}
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  archived: state.archived,
});

const mapDispatchToProps = {
  openFunctionSidebar: (data) => openSidebar(PASSPORT_FUNCTION_EDIT, data),
  closeFunctionSidebar: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Functions
  )
);
