import React, { memo, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Spin, message, Table } from 'antd';

import api from '@services/api';
import { SidebarLayout } from '@ui';

import {
  LetterTasksDispatcherCenterItem as TasksDispatcherCenterItem,
  TaskDispatcherCenterSearch,
} from '@services/api-dts';
import { AttachTaskSidebarData } from '../types';
import { cloneDeep } from 'lodash';
import { ColumnsType } from 'antd/lib/table';

import TaskRow from './taskRow';
import { SortableFields, SortDirections, sortTasks } from './sorting';
import SortPanel from './sortPanel';
import { sortingConfig } from './constants';

interface Props extends WithTranslation {
  data: AttachTaskSidebarData;
}

type SelectedTasksType = React.Key[] | undefined;

const AttachTaskSidebar = ({ t, data }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTasks, setSelectedTasks] = useState<SelectedTasksType>([]);
  const [tasksData, setTasksData] = useState<TasksDispatcherCenterItem[]>([]);
  const [cachedTasks, setCachedTasks] = useState<TasksDispatcherCenterItem[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirections>('desc');
  const [sortFieldname, setSortFieldname] = useState<SortableFields>('date');

  useEffect(() => {
    loadTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTasks = async () => {
    const letterTasks = data.letter?.task_dispatcher_centers || [];
    const alreadySelectedTasksIds = letterTasks.map((task) => task.id || '');

    setIsLoading(true);

    const { status, data: requestData } = await api.getTasksDCV2({ passports: data.passportId });

    if (status === 200) {
      let loadedTasks = requestData.results as TaskDispatcherCenterSearch[];

      //кэширование заданий, привязанных к другим паспортам
      let cached = cloneDeep(letterTasks);
      for (let i = cached.length; i--; ) {
        if (loadedTasks.map((task) => task.id).includes(cached[i].id)) cached.splice(i, 1);
      }

      const results = [...loadedTasks, ...cached] as TasksDispatcherCenterItem[];

      results.forEach((item) => {
        if (item?.date_planned_start) item.date = item.date_planned_start;
        if (item?.date_actual_start) item.date = item.date_actual_start;
      });

      const sortingLocalStorageValues = getSortingDataFromStorage();
      setTasksData(results);
      setSelectedTasks(alreadySelectedTasksIds || []);
      setCachedTasks(cached);
      sortTaskData({
        data: results as TasksDispatcherCenterItem[],
        direction: sortingLocalStorageValues.direction as SortDirections,
        fieldname: sortingLocalStorageValues.fieldname as SortableFields,
      });

    } else {
      message.error(t('LETTER_ATTACH_LOADING_ERROR'));
    }

    setIsLoading(false);
  };

  const attachTasks = async () => {
    const params = {
      task_dispatcher_centers: selectedTasks,
    };

    const { status, data: response } = await api.editLetter(data.letter?.id, params);

    if (status === 200) {
      message.success(t('ATTACH_SUCCESS'));
      data.callback(response);
    } else {
      message.error(t('ATTACH_ERROR'));
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedTasks(newSelectedRowKeys);
  };

  const getCheckboxProps = (record: TasksDispatcherCenterItem) => ({
    disabled: cachedTasks.map((task) => task.id).includes(record.id),
    name: record.name,
  });

  const sortTaskData = ({ data = tasksData, direction = sortDirection, fieldname = sortFieldname }) => {
    const sorted = sortTasks({
      items: data,
      fieldname: fieldname,
      currentDateFormat: 'YYYY-MM-DD',
      direction: direction,
    });

    setTasksData(sorted);
  };

  const getSortingDataFromStorage = () => {
    const localStorageDirection = localStorage.getItem(sortingConfig.localStorageKeys.direction);
    const localStorageFieldname = localStorage.getItem(sortingConfig.localStorageKeys.fieldname);

    if (localStorageDirection) setSortDirection(localStorageDirection as SortDirections);
    if (localStorageFieldname) setSortFieldname(localStorageFieldname as SortableFields);

    return { direction: localStorageDirection || 'desc', fieldname: localStorageFieldname || 'date' };
  };

  const handleSortDirectionChanged = (value: SortDirections) => {
    setSortDirection(value);
    sortTaskData({ direction: value });
    localStorage.setItem(sortingConfig.localStorageKeys.direction, value);
  };

  const handleSortFieldnameChanged = (value: SortableFields) => {
    setSortFieldname(value);
    sortTaskData({ fieldname: value });
    localStorage.setItem(sortingConfig.localStorageKeys.fieldname, value);
  };

  const columns: ColumnsType<TasksDispatcherCenterItem> = [
    {
      title: t('NAME_FILTER'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <TaskRow {...record} />;
      },
    },
  ];

  const attachedTask = data.letter?.task_dispatcher_center?.id;

  const rowSelection = {
    selectedRowKeys: selectedTasks,
    onChange: onSelectChange,
    getCheckboxProps: getCheckboxProps,
  };

  return (
    <SidebarLayout
      withoutCard
      noBounds
      header={<h4>{t('ATTACHING_TO_TASK_DC')}</h4>}
      footer={
        <Button htmlType='submit' block type='primary' disabled={attachedTask === selectedTasks} onClick={attachTasks}>
          {t('SAVE')}
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <SortPanel
          fieldOptions={sortingConfig.fields}
          direction={sortDirection}
          fieldname={sortFieldname}
          onDirectionChanged={handleSortDirectionChanged}
          onFieldnameChanged={handleSortFieldnameChanged}
        />
        <Table
          style={{ paddingRight: 10 }}
          columns={columns}
          dataSource={tasksData}
          key='letter_task_attach_table'
          pagination={false}
          rowSelection={rowSelection}
          rowKey='id'
          size='small'
        />
      </Spin>
    </SidebarLayout>
  );
};

export default withTranslation()(memo(AttachTaskSidebar));
