import React, { Component } from 'react';
import { Button } from 'antd/lib';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import CheckList from '@routes/globalSearch/filterSidebar/checkList';
import { ColumnRangeSearch, ColumnSearch } from '@ui';

const withColumnFilters = (WrappedComponent) => {
  return class withColumnFiltersHOC extends Component {
    state = {
      filterValues: {},
      filterVisibility: {},
      isClearOfSubmit: false,
    };

    addExtraFilters = (filters) => {
      this.setState((prevState) => ({
        filterValues: {
          ...prevState.filterValues,
          ...filters,
        },
      }));
    };

    resetFilters = (callback) => {
      this.setState({
        filterValues: {},
        filterVisibility: {},
      }, () => {
        callback && callback();
      });
    };

    handleSearch = (selectedKeys, confirm, requestParam, callback) => {
      const { filterValues, filterVisibility } = this.state;
      let value;

      confirm && confirm();

      if (selectedKeys && Array.isArray(selectedKeys)) {
        value = selectedKeys[0];
      } else {
        value = selectedKeys;
      }

      setTimeout(() => {
        this.searchInput && this.searchInput.setValue('');
      }, 300);

      this.setState({
        filterValues: {
          ...filterValues,
          [requestParam]: value,
        },
        filterVisibility: {
          ...filterVisibility,
          [requestParam]: false,
        },
      }, callback);
    };

    handleSearchRange = (selectedKeys, confirm, requestParam, callback) => {
      const { filterValues, filterVisibility } = this.state;

      confirm && confirm();

      this.setState({
        filterValues: {
          ...filterValues,
          [requestParam]: selectedKeys,
        },
        filterVisibility: {
          ...filterVisibility,
          [requestParam]: false,
        },
      }, callback);
    };

    handleReset = (clearFilters, confirm, requestParam, callback) => {
      const { filterValues, filterVisibility } = this.state;

      clearFilters && clearFilters();
      confirm && confirm();

      setTimeout(() => {
        this.searchInput && this.searchInput.setValue('');
      }, 300);

      this.setState({
        filterValues: {
          ...filterValues,
          [requestParam]: '',
        },
        filterVisibility: {
          ...filterVisibility,
          [requestParam]: false,
        },
      }, callback);
    };

    getColumnSearchFilter = (dataIndex, requestParam, searchCallback, resetCallback) => {
      const { filterValues, filterVisibility } = this.state;

      return (
        {
          filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
          }) => (
            <ColumnSearch
              setRef={(ref) => { this.searchInput = ref; }}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              confirm={confirm}
              dataIndex={dataIndex}
              clearFilters={clearFilters}
              handleSearch={
                () => this.handleSearch(selectedKeys, confirm, requestParam, searchCallback)
              }
              handleReset={
                () => this.handleReset(clearFilters, confirm, requestParam, resetCallback)
              }
            />
          ),
          filterIcon: (filtered) => (
            <div
              style={{
                height: '100%',
                backgroundColor: filtered && '#1890ff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <SearchOutlined style={{ color: filtered && '#fff' }} />
            </div>
          ),
          filteredValue: filterValues[requestParam] ? [filterValues[requestParam]] : '',
          filterDropdownVisible: filterVisibility[requestParam],
          onFilterDropdownVisibleChange: (visible) => {
            this.setState({
              filterVisibility: {
                ...filterVisibility,
                [requestParam]: visible,
              },
            });
            if (visible) {
              setTimeout(() => this.searchInput.select && this.searchInput.select());
            }
          },
        }
      );
    };

    getColumnSearchRange = (dataIndex, requestParam, searchCallback, resetCallback) => {
      const { filterValues, filterVisibility } = this.state;

      return (
        {
          filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
          }) => (
            <ColumnRangeSearch
              setRef={(ref) => { this.rangeInput = ref; }}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              handleSearchRange={
                () => this.handleSearchRange(selectedKeys, confirm, requestParam, searchCallback)
              }
              handleReset={
                () => this.handleReset(clearFilters, confirm, requestParam, resetCallback)
              }
            />
          ),
          filterIcon: (filtered) => (
            <div
              style={{
                height: '100%',
                backgroundColor: filtered && '#1890ff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <SearchOutlined style={{ color: filtered && '#fff' }} />
            </div>
          ),
          filteredValue: filterValues[requestParam] ? filterValues[requestParam] : [],
          filterDropdownVisible: filterVisibility[requestParam],
          onFilterDropdownVisibleChange: (visible) => {
            this.setState({
              filterVisibility: {
                ...filterVisibility,
                [requestParam]: visible,
              },
            });
            if (visible) {
              setTimeout(() => this.rangeInput.focus());
            }
          },
        }
      );
    };

    onChangeFilter = (name, checked, id) => {
      const { filterValues } = this.state;

      const oldValue = filterValues[name] || [];
      const index = oldValue.indexOf(id);
      const newValue = (index === -1 && checked)
        ? [...oldValue, ...[id]]
        : oldValue;
      if (index >= 0 && !checked) {
        newValue.splice(index, 1);
      }
      this.setState({
        filterValues: {
          ...filterValues,
          [name]: newValue,
        },
      });
    }

    getColumnSearchFilterList = (
      t,
      name,
      getList,
      searchCallback,
      resetCallback,
      isShowSearchBlock = true
    ) => {
      const { filterValues, filterVisibility, isClearOfSubmit } = this.state;

      return (
        {
          filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
          }) => {
            const keysFilterValues = filterValues
              ? Object.keys(filterValues)
              : [];
            return (
              <div style={{ padding: 8 }}>
                <CheckList
                  setRef={(ref) => { this.searchInput = ref; }}
                  filterItem={{
                    name: name,
                    getList: getList,
                  }}
                  keyName={`checkList_${name}`}
                  isShowSearchBlock={isShowSearchBlock}
                  values={filterValues[name] || null}
                  filters={filterValues}
                  onChange={(checked, id) => this.onChangeFilter(name, checked, id, setSelectedKeys, selectedKeys)}
                  isReloadData={isClearOfSubmit}
                  offReload={() => this.setState({ isClearOfSubmit: false })}
                />
                <Button
                  type='primary'
                  onClick={() => {
                    this.handleSearch(selectedKeys, confirm, name, () => {
                      this.setState({
                        isClearOfSubmit: true,
                      }, () => {
                        searchCallback && searchCallback();
                      });
                    });
                  }}
                  icon={<SearchOutlined />}
                  size='small'
                  style={{ width: 90, marginRight: 8 }}
                >
                  {t('TO_SEARCH')}
                </Button>
                <Button
                  onClick={() => {
                    this.handleReset(clearFilters, confirm, name, () => {
                      this.setState({
                        isClearOfSubmit: true,
                      }, () => {
                        searchCallback && searchCallback();
                        resetCallback && resetCallback();
                      });
                    });
                  }}
                  disabled={!keysFilterValues || !keysFilterValues.length}
                  size='small'
                  style={{ width: 90 }}
                >
                  {t('RESET')}
                </Button>
              </div>
            );
          },
          filterIcon: (filtered) => (
            <div
              style={{
                height: '100%',
                backgroundColor: filtered && '#1890ff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {
                isShowSearchBlock
                  ? <SearchOutlined style={{ color: filtered && '#fff' }} />
                  : <FilterFilled style={{ color: filtered ? '#fff' : 'rgba(0, 0, 0, 0.65)' }} />
              }
            </div>
          ),
          filteredValue: filterValues[name] ? [filterValues[name]] : '',
          filterDropdownVisible: filterVisibility[name],
          onFilterDropdownVisibleChange: (visible) => {
            this.setState({
              filterVisibility: {
                ...filterVisibility,
                [name]: visible,
              },
            });
            if (visible) {
              setTimeout(() => {
                if (this.searchInput) {
                  this.searchInput.select();
                  this.searchInput.focus();
                }
              }, 300);
            }
          },
        }
      );
    };

    render() {
      return (
        <WrappedComponent
          getColumnSearchFilter={this.getColumnSearchFilter}
          getColumnSearchFilterList={this.getColumnSearchFilterList}
          getColumnSearchRange={this.getColumnSearchRange}
          handleSearch={this.handleSearch}
          handleSearchRange={this.handleSearchRange}
          handleReset={this.handleReset}
          addExtraFilters={this.addExtraFilters}
          resetFilters={this.resetFilters}
          {...this.props}
          {...this.state}
        />
      );
    }
  };
};

export default withColumnFilters;
