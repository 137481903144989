import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { OneNews } from '@services/api-dts';
import { Button } from 'antd';
import moment from 'moment';
import React, { memo } from 'react';
import { WithTranslation } from 'react-i18next';
import { HEADER_DATE_FORMAT } from './constants';
import styles from './styles.module.less';

export interface INewsItem extends WithTranslation, OneNews {
  ref?: React.Ref<HTMLDivElement>;
  editCallback?: (id: string) => void;
  deleteCallback?: (id: string) => void;
}

const NewsItem: React.ForwardRefExoticComponent<INewsItem> = React.forwardRef(
  ({ id, content, created_at, release_version, editCallback, deleteCallback, t }, ref: React.Ref<HTMLDivElement>) => {
    const handleEdit = () => {
      editCallback && editCallback(id);
    };

    const handleDelete = () => {
      deleteCallback && deleteCallback(id);
    };

    return (
      <div className={styles['news-item']} ref={ref}>
        <div className={styles['news-item-header']}>
          <div>
            {created_at && moment(created_at).format(HEADER_DATE_FORMAT)}
            {release_version && `, ${t('VERSION')} ${release_version}`}
          </div>
          <div className={styles['news-item-header-buttons']}>
            {editCallback && (
              <Button size='small' onClick={handleEdit} icon={<EditOutlined />}>
                {t('EDIT')}
              </Button>
            )}
            {deleteCallback && (
              <Button size='small' onClick={handleDelete} icon={<DeleteOutlined style={{ color: 'red' }} />}>
                {t('DELETE')}
              </Button>
            )}
          </div>
        </div>
        <div
          className={styles['news-content']}
          style={{ overflow: 'hidden' }}
          dangerouslySetInnerHTML={{ __html: content || '' }}
        />
      </div>
    );
  }
);

export default memo(NewsItem);
