import React from 'react';
import {
  Form, Select, Spin, TreeSelect,
} from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import api from '@services/api';
import { SelectInfiniteScroll } from '@ui';

import {
  MetaTags,
  ProtectionDevicePrimarySerializer,
  ProtectionDeviceSerializer,
} from '@services/api-dts';

import { FormInstance } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';

import {
  PRODUCERS, IMPLEMENTATION_TYPES, TRADE_DEVICES, SOFTWARE_VERSION, FUNCTIONS,
} from './constants';
import { DataNode } from 'antd/lib/tree';
import { LegacyDataNode } from 'rc-tree-select/lib/interface';

enum SelectTypes {
  Producers = 'Producers',
  ImplementationTypes = 'ImplementationTypes',
  TradeDevices = 'TradeDevices',
  Versions = 'Versions',
  Functions = 'Functions',
}

interface Option {
  id: string;
  name: string;
}

interface Functions {
  id: string;
  name: string;
  function: Option;
  children: Functions[];
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
interface ProtectionDevice extends ProtectionDevicePrimarySerializer, ProtectionDeviceSerializer { }

interface Props extends WithTranslation {
  protectionDeviceData?: ProtectionDevice;
  setExtraDocumentProps: (extraProps: Store) => void;
  metaTags?: MetaTags;
}

interface State {
  isLoading: boolean;
  [key: string]: string | boolean | Option[] | string[] | Functions[];
}

class DocumentExtraPropsForm extends React.Component<Props, State> {
  form = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      [PRODUCERS]: [],
      [IMPLEMENTATION_TYPES]: [],
      [TRADE_DEVICES]: [],
      [SOFTWARE_VERSION]: [],
      [FUNCTIONS]: [],
      [`${PRODUCERS}Loading`]: false,
      [`${IMPLEMENTATION_TYPES}Loading`]: false,
      [`${TRADE_DEVICES}Loading`]: false,
      [`${SOFTWARE_VERSION}Loading`]: false,
      [`${FUNCTIONS}Loading`]: false,
      [`next${PRODUCERS}`]: '',
      [`next${IMPLEMENTATION_TYPES}`]: '',
      [`next${TRADE_DEVICES}`]: '',
      [`next${SOFTWARE_VERSION}`]: '',
      [`next${FUNCTIONS}`]: '',
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.loadInitialSelectOptions();
    await this.loadInitialSelectValues();
    this.setState({ isLoading: false });
  }

  onSelectSearch = debounce(async (value: string, type: string) => {
    const {
      [`selected${PRODUCERS}`]: selectedProducers,
      [`selected${IMPLEMENTATION_TYPES}`]: selectedImplementationTypes,
      [`selected${TRADE_DEVICES}`]: selectedTradeDevices,
    } = this.state;

    let requestParamName = 'name';

    if ([FUNCTIONS].includes(type)) {
      requestParamName = 'name__icontains';
    }

    switch (type) {
      case TRADE_DEVICES: {
        await this.loadDataForSelect(
          type,
          {
            [requestParamName]: value,
            producer: selectedProducers,
            implementation_type: selectedImplementationTypes,
          }
        );
        break;
      }
      case SOFTWARE_VERSION: {
        await this.loadDataForSelect(
          type,
          {
            [requestParamName]: value,
            trade_device: selectedTradeDevices,
          }
        );
        break;
      }
      default:
        await this.loadDataForSelect(type, { [requestParamName]: value });
        break;
    }
  }, 800);

  loadInitialSelectOptions = async () => {
    const promises = [
      PRODUCERS,
      IMPLEMENTATION_TYPES,
      TRADE_DEVICES,
      FUNCTIONS,
    ].map(async (type) => await this.loadDataForSelect(type));

    await Promise.all(promises);
  };

  loadInitialSelectValues = async () => {
    const { protectionDeviceData, setExtraDocumentProps, metaTags } = this.props;
    const producers: string[] = [];

    if (!metaTags && protectionDeviceData?.producer?.id) {
      producers.push(protectionDeviceData.producer.id);
    }
    if (metaTags?.producers?.length) {
      producers.push(...metaTags.producers);
    }

    const uniqueProducers = [...Array.from(new Set(producers))];

    if (uniqueProducers.length) {
      await this.loadDataForSelect(PRODUCERS, { selected: uniqueProducers });
      this.form.current && this.form.current.setFieldsValue({ [PRODUCERS]: uniqueProducers });
      await this.onChangeSelect(uniqueProducers, PRODUCERS);
    }

    const implementationTypes: string[] = [];

    if (!metaTags && protectionDeviceData?.implementation_type?.id) {
      implementationTypes.push(protectionDeviceData.implementation_type.id);
    }
    if (metaTags?.implementation_types?.length) {
      implementationTypes.push(...metaTags.implementation_types);
    }

    const uniqueImplementationTypes = [...Array.from(new Set(implementationTypes))];

    if (uniqueImplementationTypes.length) {
      await this.loadDataForSelect(IMPLEMENTATION_TYPES, { selected: uniqueImplementationTypes });
      this.form.current && this.form.current.setFieldsValue({ [IMPLEMENTATION_TYPES]: uniqueImplementationTypes });
      await this.onChangeSelect(uniqueImplementationTypes, IMPLEMENTATION_TYPES);
    }

    const tradeDevices: string[] = [];

    if (!metaTags && protectionDeviceData?.trade_device?.id) {
      tradeDevices.push(protectionDeviceData.trade_device?.id);
    }
    if (metaTags?.trade_devices?.length) {
      tradeDevices.push(...metaTags.trade_devices);
    }

    const uniqueTradeDevices = [...Array.from(new Set(tradeDevices))];

    if (uniqueTradeDevices.length) {
      await this.loadDataForSelect(
        TRADE_DEVICES,
        {
          selected: uniqueTradeDevices,
          producer: protectionDeviceData?.producer?.id,
          implementation_type: protectionDeviceData?.implementation_type?.id,
        }
      );
      this.form.current && this.form.current.setFieldsValue({ [TRADE_DEVICES]: uniqueTradeDevices });
      await this.onChangeSelect(uniqueTradeDevices, TRADE_DEVICES);
    }

    const versions: string[] = [];

    if (!metaTags && protectionDeviceData?.device_version?.id) {
      versions.push(protectionDeviceData?.device_version.id);
    }
    if (metaTags?.versions?.length) {
      versions.push(...metaTags.versions);
    }

    const uniqueVersions = [...Array.from(new Set(versions))];

    if (uniqueVersions.length) {
      await this.loadDataForSelect(
        SOFTWARE_VERSION,
        {
          selected: uniqueVersions,
          trade_device: protectionDeviceData?.trade_device?.id,
        }
      );
      this.form.current && this.form.current.setFieldsValue({ [SOFTWARE_VERSION]: uniqueVersions });
      await this.onChangeSelect(uniqueVersions, SOFTWARE_VERSION);
    }

    const functions: string[] = [];

    if (!metaTags && protectionDeviceData?.functions?.length) {
      const funcIds: string[] = protectionDeviceData.functions
        .map((item) => item.id)
        .filter((item) => item !== undefined) as string[];

      functions.push(...funcIds);
    }
    if (metaTags?.functions?.length) {
      functions.push(...metaTags.functions);
    }

    const uniqueFunctions = [...Array.from(new Set(functions))];

    if (uniqueFunctions.length) {
      await this.loadDataForSelect(FUNCTIONS, { selected: uniqueFunctions });
      this.form.current && this.form.current.setFieldsValue({ [FUNCTIONS]: uniqueFunctions });
      await this.onChangeSelect(uniqueFunctions, FUNCTIONS);
    }

    const values = this.form.current?.getFieldsValue() as Store;

    setExtraDocumentProps(values);
  };

  loadDataForSelect = async (type: string, addParams = {}) => {
    const params = {
      limit: 10,
    };
    
    this.setState({ [`${type}Loading`]: true });
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { status, data } = await api[`get${type}`](
      {
        ...params,
        ...addParams,
        ...type === SOFTWARE_VERSION && { custom_name: true }
      },
      api.getOptsForArray()
    );
    this.setState({ [`${type}Loading`]: false });

    if (status === 200) {
      this.setState({
        [type]: data.results,
        [`next${type}`]: data.next,
      });
    }
  };

  loadMoreDataForSelect = async (type: string) => {
    const { [type]: selectData, [`next${type}`]: nextUrl } = this.state;
    const url: URL | null = nextUrl ? new URL(nextUrl as string) : null;

    if (url) {
      const request = url.pathname + url.search;

      this.setState({ [`${type}Loading`]: true });
      const { status, data } = await api.urlGetRequest(request);
      this.setState({ [`${type}Loading`]: false });

      if (status === 200) {
        this.setState({
          [type]: [
            ...selectData as Option[],
            ...data.results,
          ],
          [`next${type}`]: data.next,
        });
      }
    }
  };

  onChangeSelect = async (value: string[], type: string) => {
    const { setExtraDocumentProps } = this.props;
    const {
      [`selected${PRODUCERS}`]: selectedProducers,
      [`selected${IMPLEMENTATION_TYPES}`]: selectedImplementationTypes,
    } = this.state;

    this.setState({ [`selected${type}`]: value });

    switch (type) {
      case PRODUCERS: {
        this.form.current && this.form.current.resetFields([TRADE_DEVICES]);

        const values = this.form.current?.getFieldsValue() as Store;
        setExtraDocumentProps(values);

        await this.onChangeSelect([], TRADE_DEVICES);
        await this.loadDataForSelect(
          TRADE_DEVICES,
          {
            producer: value,
            implementation_type: selectedImplementationTypes,
          }
        );
        break;
      }
      case IMPLEMENTATION_TYPES: {
        this.form.current && this.form.current.resetFields([TRADE_DEVICES]);

        const values = this.form.current?.getFieldsValue() as Store;
        setExtraDocumentProps(values);

        await this.onChangeSelect([], TRADE_DEVICES);
        await this.loadDataForSelect(
          TRADE_DEVICES,
          {
            producer: selectedProducers,
            implementation_type: value,
          }
        );
        break;
      }
      case TRADE_DEVICES: {
        if (value.length) {
          await this.loadDataForSelect(SOFTWARE_VERSION, { trade_device: value });
        } else {
          this.form.current && this.form.current.resetFields([SOFTWARE_VERSION]);

          const values = this.form.current?.getFieldsValue() as Store;
          setExtraDocumentProps(values);

          await this.onChangeSelect([], SOFTWARE_VERSION);
          this.setState({
            [SOFTWARE_VERSION]: [],
            [`next${SOFTWARE_VERSION}`]: '',
          });
        }
        break;
      }
      default:
        break;
    }
  };

  getFunctionsTreeData = (items: Functions[], topLevel: boolean = false): DataNode[] => {
    if (!items || !items.length) return [];

    return items.map((item: Functions) => {
      return {
        key: item.id,
        value: item.id,
        title: item.function ? item.function.name : item.name,
        selectable: !topLevel,
        children: item.children && this.getFunctionsTreeData(item.children)
      }
    })
  }

  filterTreeNodes = (inputValue: string, treeNode?: LegacyDataNode) => {
    if (!treeNode?.selectable) return false;

    const title = treeNode.title as string || '';
    const titleLowerCase = title.toLowerCase();
    if (titleLowerCase.includes(inputValue.toLowerCase())) return true;
    
    return false;
  }

  render() {
    const { t, setExtraDocumentProps } = this.props;
    const { isLoading } = this.state;

    return (
      <Spin spinning={isLoading}>
        <Form
          ref={this.form}
          name='FormDocumentsUploadExtra'
          onValuesChange={
            (changedFields: Store, allFields: Store) => setExtraDocumentProps(allFields)
          }
        >
          {
            [
              PRODUCERS,
              IMPLEMENTATION_TYPES,
              TRADE_DEVICES,
              SOFTWARE_VERSION,
            ].map((type) => (
              <Form.Item
                name={type}
                key={type}
              >
                <SelectInfiniteScroll
                  placeholder={t(`SELECT_${type.toLocaleUpperCase()}`)}
                  loading={this.state[`${type}Loading`]}
                  mode='multiple'
                  allowClear
                  showSearch
                  filterOption={false}
                  onChange={(value: string[]) => this.onChangeSelect(value, type)}
                  loadMoreHandle={() => this.loadMoreDataForSelect(type)}
                  onSearch={(value: string) => this.onSelectSearch(value, type)}
                  onBlur={() => this.onSelectSearch('', type)}
                >
                  {(this.state[type as SelectTypes] as Option[]).map((opt: Option) => (
                    <Select.Option key={opt.id} value={opt.id}>
                      {opt.name}
                    </Select.Option>
                  ))}
                </SelectInfiniteScroll>
              </Form.Item>
            ))
          }
          {
            [
              FUNCTIONS
            ].map((type) => {
              const isLoading = this.state[`${type}Loading`] as boolean;
              const items = this.state[type] as Functions[];

              return <Form.Item
                name={type}
                key={type}
              >
                <TreeSelect
                  multiple={true}
                  loading={isLoading || false}
                  treeDefaultExpandAll
                  allowClear
                  autoClearSearchValue
                  treeNodeFilterProp='title'
                  placeholder={t(`SELECT_${type.toLocaleUpperCase()}`)}
                  onChange={(value: string[]) => this.onChangeSelect(value, type)}
                  treeData={this.getFunctionsTreeData(items, true)}
                  filterTreeNode={this.filterTreeNodes}
                />
              </Form.Item>
            })
          }
        </Form>
      </Spin>
    );
  }
}

export default withTranslation()(
  DocumentExtraPropsForm
);
