export const COMPLEXES = 'COMPLEXES';
export const DEVICE = 'DEVICE';
export const DOCUMENTS = 'DOCUMENTS';
export const EQUIPMENT_TYPES = 'EQUIPMENT_TYPES';

export const OWNERS = 'Owners';
export const PRODUCERS = 'Producers';
export const IMPLEMENTATION_TYPES = 'ImplementationTypes';
export const TRADE_DEVICES = 'TradeDevices';
export const TERMINAL_TYPES = 'TerminalTypes';
export const SOFTWARE_VERSION = 'Versions';
