import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Collapse, Button, Badge,
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { closeSidebar } from '@state/sidebar/actions';
import { applyGlobalSearchParams, clearCheckedItems } from '@state/search/actions';

import { SidebarLayout } from '@ui';
import FilterRange from './range';
import CheckList from './checkList';
import FilterString from './string';
import FilterDateRange from './date';

import styles from './styles.module.less';

const { Panel } = Collapse;

class FilterSidebar extends Component {
  enabledTypes = ['CHECK_LIST', 'RANGE', 'STRING', 'ONLY_VIEW_COLUMN', 'DATE_RANGE'];

  typesMultiValuesOnBadge = ['CHECK_LIST'];

  state = {
    changedFilters: {},
    changedVisible: {},
    isReload: false,
  };

  componentDidMount() {
    const { data } = this.props;
    const { values } = data;
    const changedFilters = {};
    const changedVisible = {};

    Object.keys(values).forEach((index) => {
      if (values[index]) {
        if (values[index].value) {
          changedFilters[index] = values[index].value;
        }

        changedVisible[index] = values[index].visible;
      }
    });

    this.setState({
      changedFilters: changedFilters,
      changedVisible: changedVisible,
    });
  }

  componentDidUpdate(prevProps) {
    const { archived } = this.props;

    if (prevProps.archived !== archived) {
      this.setState({
        isReload: true,
      });
    }
  }

  onApplyClick = () => {
    const { data, closeFilter, setApplyParams } = this.props;
    const { changedFilters, changedVisible } = this.state;

    if (typeof data.onApply === 'function') {
      closeFilter();
      setApplyParams(true);
      data.onApply(changedFilters, changedVisible);
    }
  };

  onChangeVisible = (name, event) => {
    event.stopPropagation();
    const { changedVisible } = this.state;
    const newValue = { ...changedVisible };

    newValue[name] = newValue[name] === 0 ? 1 : 0;
    this.setState({
      changedVisible: newValue,
    });
  };

  onChangeFilter(name, type, checked, id) {
    const { changedFilters } = this.state;
    const oldValue = changedFilters[name] || [];
    const newValue = (() => {
      switch (type) {
        case 'CHECK_LIST': {
          const index = oldValue.indexOf(id);
          if (index === -1 && checked) {
            return [...oldValue, ...[id]];
          } else if (index >= 0 && !checked) {
            oldValue.splice(index, 1);
          }
          return oldValue;
        }
        case 'RANGE':
          return null;
        case 'STRING':
          return id;
        case 'DATE_RANGE':
          return id && Array.isArray(id) && id.length === 2 && id[0] === '' && id[1] === '' ? ['DELETE_VALUE'] : id;
        default:
          return null;
      }
    })();

    if (newValue && Array.isArray(newValue) && newValue.length === 1 && newValue[0] === 'DELETE_VALUE') {
      delete changedFilters[name];
      this.setState({
        changedFilters: changedFilters,
      });
    } else {
      this.setState({
        changedFilters: {
          ...changedFilters,
          [name]: newValue,
        },
      });
    }
  }

  resetFilters() {
    const { setApplyParams, clearItems } = this.props;

    clearItems();
    setApplyParams(false);
    this.setState({
      changedFilters: {},
      isReload: true,
    });
  }

  renderItems = () => {
    const { data } = this.props;
    const { changedFilters, isReload } = this.state;

    return (
      <>
        {data.filters
          .filter((item) => this.enabledTypes.indexOf(item.type) >= 0)
          .map((item) => (item.type === 'ONLY_VIEW_COLUMN' ? (
            <Panel
              header={this.renderHeader(item.name, item.type)}
              key={`panel_${item.name}`}
              showArrow={false}
              onItemClick={(e) => {
                e.stopPropagation();
                return false;
              }}
              disabled={true}
            />
          ) : (
            <Panel
              header={this.renderHeader(item.name, item.type)}
              key={item.name}
            >
              {(() => {
                switch (item.type) {
                  case 'CHECK_LIST':
                    return (
                      <CheckList
                        filterItem={item}
                        keyName={`checkList_${item.name}`}
                        values={changedFilters[item.name] || null}
                        filters={changedFilters}
                        onChange={(checked, id) => this.onChangeFilter(item.name, item.type, checked, id)}
                        isReloadData={isReload}
                        offReload={() => this.setState({ isReload: false })}
                      />
                    );
                  case 'RANGE':
                    return (
                      <FilterRange
                        keyName={`filterRange_${item.name}`}
                        onChange={(key) => this.onChangeFilter(item.name, item.type, null, key)}
                      />
                    );
                  case 'STRING':
                    return (
                      <FilterString
                        keyName={`filterString_${item.name}`}
                        values={
                          changedFilters[item.name]
                          && changedFilters[item.name].length > 0
                            ? changedFilters[item.name]
                            : ''
                        }
                        onChange={(key) => this.onChangeFilter(item.name, item.type, null, key)}
                      />
                    );
                  case 'DATE_RANGE':
                    return (
                      <FilterDateRange
                        keyName={`filterDateRange_${item.name}`}
                        values={
                          changedFilters[item.name]
                          && changedFilters[item.name].length === 2
                            ? [
                              moment(changedFilters[item.name][0], 'DD.MM.YYYY'),
                              moment(changedFilters[item.name][1], 'DD.MM.YYYY'),
                            ]
                            : []
                        }
                        onChange={
                          (dateRange, dateRangeString) => this.onChangeFilter(
                            item.name,
                            item.type,
                            null,
                            dateRangeString
                          )
                        }
                      />
                    );
                  default:
                    return '';
                }
              })()}
            </Panel>
          )))}
      </>
    );
  };

  renderHeader(name, type) {
    const { changedFilters, changedVisible } = this.state;
    const { t, data } = this.props;
    const currentFilter = data.filters.find((item) => item.name === name);
    const isField = currentFilter && currentFilter.field !== '';
    const isShowVisibleButton = currentFilter && currentFilter.showVisibleButton !== false;
    const isVisibleClassName = changedVisible[name] && changedVisible[name] === 1
      ? styles.filterIconEnabled
      : '';

    return (
      <div>
        {t(name)}
        {changedFilters && changedFilters[name] && changedFilters[name].length > 0 ? (
          <Badge
            offset={[10, 0]}
            className='dbrza-core-menu-item-badge'
            count={this.typesMultiValuesOnBadge.indexOf(type) >= 0 ? changedFilters[name].length : 1}
          />
        ) : (
          ''
        )}
        <div className={styles.icons}>
          {isField && isShowVisibleButton ? (
            <EyeOutlined
              className={`${styles.filterIcon} ${isVisibleClassName}`}
              onClick={(event) => this.onChangeVisible(name, event)}
            />
          ) : (
            <span style={{ marginLeft: '22px' }}>&nbsp;</span>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <SidebarLayout
        withoutCard
        noBounds
        header={<h4>{t('FILTERS_AND_CONFIGURATIONS')}</h4>}
        footer={(
          <>
            <Button block onClick={() => this.resetFilters()} className='bordered'>
              {t('RESET_FILTERS')}
            </Button>
            <Button block type='primary' onClick={this.onApplyClick}>
              {t('APPLY')}
            </Button>
          </>
        )}
      >
        <Collapse
          bordered={false}
          expandIconPosition='right'
          className={styles.content}
          destroyInactivePanel
          accordion
        >
          {this.renderItems()}
        </Collapse>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  archived: state.archived,
});

const mapDispatchToProps = {
  closeFilter: closeSidebar,
  setApplyParams: applyGlobalSearchParams,
  clearItems: clearCheckedItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    FilterSidebar
  )
);
