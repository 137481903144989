export const PASSPORT_DETAILS = 'PASSPORT_DETAILS';
export const SEARCH_FILTER = 'SEARCH_FILTER';
export const DOCUMENTS_UPLOAD = 'DOCUMENTS_UPLOAD';
export const DOCUMENTS_BIND = 'DOCUMENTS_BIND';
export const CREATE_BLANK = 'CREATE_BLANK';
export const UPDATE_BLANK = 'UPDATE_BLANK';
export const IMPORT_BLANK = 'IMPORT_BLANK';
export const CREATE_TASK = 'CREATE_TASK';
export const SETPOINT_INFO = 'SETPOINT_INFO';
export const CREATE_COMPLEX = 'CREATE_COMPLEX';
export const IMPORT_TEMPLATE = 'IMPORT_TEMPLATE';
export const COMPLEXES_FORM = 'COMPLEXES_FORM';
export const EQUIPMENT_TYPES_FORM = 'EQUIPMENT_TYPES_FORM';
export const COMMON_CLASSIFICATORS_FORM = 'COMMON_CLASSIFICATORS_FORM';
export const TASK_DC_INFO = 'TASK_DC_INFO';
export const PASSPORT_FUNCTION_EDIT = 'PASSPORT_FUNCTION_EDIT';
export const STORAGE_FORM = 'STORAGE_FORM';
export const EVENTS_FORM = 'EVENTS_FORM';
export const CREATE_SETPOINT_MAP = 'CREATE_SETPOINT_MAP';
export const NONPERFORMANCE_TERM = 'NONPERFORMANCE_TERM';
export const ATTACH_TASK_TO_LETTER = 'ATTACH_TASK_TO_LETTER';
export const LIST_SETPOINT_MAP = 'LIST_SETPOINT_MAP';
