import React, { ReactNode } from 'react';
import c from 'classnames';

import './styles.less';

interface Props {
  className?: string;
  children?: ReactNode;
  required?: boolean;
}

const SidebarCardLabel = (props: Props) => {
  const { className, children, required } = props;
  const classes = c('sidebar-layout__label', className);

  return (
    <span className={classes}>
      {required && '* '}
      {children}
    </span>
  );
};

export default SidebarCardLabel;
