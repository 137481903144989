import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import { withTranslation } from 'react-i18next';
import './styles.less';

import api from '@services/api';
import cloneDeep from 'lodash/cloneDeep';

import {
  Input, Button, Spin, message, Empty, Popconfirm, Select, Divider, Tooltip, TreeSelect,
} from 'antd';
import {
  CheckCircleOutlined, ExportOutlined, ImportOutlined, LeftOutlined, RightOutlined,
} from '@ant-design/icons';

import { ReactComponent as TransformerIcon } from '@ui/icons/transformer.svg';

import {
  onDropTreeValueItemIntoNewTreeItem,
  onViewTree,
} from '@common/tree';
import TemplateTree from "@common/tree";
import TemplateTreeValues from "@common/treeValues";
import {
  onChangeTreeValueData,
  onClearDragTreeValue,
  onDragStartTreeValueItem,
  onDropTreeValueItemInside,
} from '@common/treeValues';

import store from '@state/store';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { IMPORT_BLANK, SETPOINT_INFO } from '@common/sidebarRoot/types';
import {
  getDeviceFunctions, onChangeSelectedFn, renderFunctionsSelect, withPrefix,
} from '@globalHelpers';
import { CustomCard, CustomSelect } from '@ui';

import exportBlank from '@routes/passport/tabs/setpoints/blanks/exportBlank';
import { EXPORT_MODE_PF } from '@routes/passport/tabs/setpoints/blanks/constants';
import { READY_TO_FORMED, STATUS_CALCULATING, STATUS_NEW } from '@routes/passport/tabs/tasks/constants';

import { ActionsForTree } from './actions/actionsForTree';
import { ActionsForTreeValues } from './actions/actionsForTreeValues';
import Icon from '@ant-design/icons';
import { REDIRECT_URL_NEW_FRONT } from "@app/constants";

const { Option } = Select;

class SetpointsTree extends Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;

    const t = withPrefix('PASSPORT_SETPOINTS_', props.t);
    this.actionsForTree = new ActionsForTree({ mainProps: props, t, component: this });
    this.actionsForTreeValues = new ActionsForTreeValues({ mainProps: props, t, component: this });

    this.APPROVED_STATUS = 'is_approved';
    this.VIEW_FUNCTIONS = 'VIEW_FUNCTIONS';
    this.VIEW_SETS = 'VIEW_SETS';

    this.state = {
      /** *************** state for Tree ************** */
      treeData: [],
      selectedTreeItem: {},
      expandedTreeItems: ['0'],
      isViewTree: true,
      categoryBlockIsLoading: false,

      /** *************** state for Tree Values ************** */
      treeValueData: [],
      oldTreeValueData: [],
      selectedStatusId: null,
      dragTreeValueItem: {},
      setPointsBlockIsLoading: false,

      /** *************** state for this component ************** */
      saveInProgress: false,
      editMode: false,
      valuesViewMode: 'primary',

      setPointsNameFilter: '',

      modifiedTreeValueData: [],
      modifiedTreeValueDataId: [],

      // eslint-disable-next-line react/no-unused-state
      loadedCompareCategory: {}, // используется в this.actionsForTreeValues и в this.actionsForTree
      loadedCompareData: {},

      isBlocking: false,
      isFileDownloading: false,
      showButtons: false,

      selectedTreeValueData: [],
      isEditItem: false,

      taskId: null,
      currentView: this.VIEW_SETS,
      compareMode: false,
      compareIds: [],

      groupsCount: null,
      activeGroup: null,
      selectedGroup: null,
      selectedCategoryId: undefined,

      deviceFns: [],
      treeTableComponent: null,
      setpointsId: [],
    };
  }

  async componentDidMount() {
    const {
      onBlock, onUnBlock, taskId, mode, compareMode, compareIds, blank, onViewChanged
    } = this.props;

    const currentView = mode === 'task' ? this.VIEW_FUNCTIONS : this.VIEW_SETS;
    
    this.setState({
      isBlocking: true,
      taskId: taskId,
      currentView: currentView,
      compareMode: compareMode,
      compareIds: compareIds,
      groupsCount: (blank && blank.groups_count) || (mode === 'task' ? 1 : null),
      activeGroup: (blank && blank.active_group) || (mode === 'task' ? 1 : null),
      selectedGroup: (blank && blank.active_group) || (mode === 'task' ? 1 : null),
    });

    onViewChanged && onViewChanged(currentView);
    await onBlock();
    await this.loadCategories();
    await onUnBlock();
    this.setState({ isBlocking: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      compareMode: propsCompareMode,
      compareIds: propsCompareIds,
      taskId: propsTaskId,
      taskStatus,
      onBlock,
      onUnBlock,
      blank,
      mode,
      groupsIsChanged,
      onChangeGroupEnd,
    } = this.props;
    const {
      taskId: stateTaskId,
      currentView,
      setPointsNameFilter,
      compareMode: stateCompareMode,
      compareIds: stateCompareIds,
      selectedTreeItem,
    } = this.state;

    if (
      (propsTaskId !== stateTaskId)
      || (prevProps.taskStatus !== taskStatus)
      || (!isEqual(prevProps.blank, blank))
    ) {
      this.setState({
        taskId: propsTaskId,
        groupsCount: (blank && blank.groups_count) || (mode === 'task' ? 1 : null),
        activeGroup: (blank && blank.active_group) || (mode === 'task' ? 1 : null),
        selectedGroup: (blank && blank.active_group) || (mode === 'task' ? 1 : null),
        isBlocking: true,
        editMode: false,
        selectedTreeItem: {},
        treeData: [],
        treeValueData: [],
        oldTreeValueData: [],
      }, async () => {
        if (
          (!isEqual(prevProps.blank, blank) && blank && !blank.is_doc)
          || (prevProps.taskStatus !== taskStatus)
        ) {
          await onBlock();
          await this.loadCategories();

          const { treeData } = this.state;

          if (selectedTreeItem && selectedTreeItem.id) {
            const findCategory = treeData.find((x) => x.id === selectedTreeItem.id);
            if (findCategory) {
              await this.actionsForTreeValues.onSelectTreeData(selectedTreeItem);
            }
          }
          await onUnBlock();
        }
        this.setState({ isBlocking: false });
      });
    }

    if (groupsIsChanged) {
      this.setState({
        groupsCount: (blank && blank.groups_count) || (mode === 'task' ? 1 : null),
        activeGroup: (blank && blank.active_group) || (mode === 'task' ? 1 : null),
        selectedGroup: (blank && blank.active_group) || (mode === 'task' ? 1 : null),
        treeValueData: [],
      }, async () => {
        if (selectedTreeItem && selectedTreeItem.id) {
          await this.actionsForTreeValues.onSelectTreeData(selectedTreeItem);
        }
      });
      onChangeGroupEnd();
    }

    if (prevState.currentView !== currentView) {
      this.setState({
        isBlocking: true,
        selectedTreeItem: {},
        treeValueData: [],
        oldTreeValueData: [],
      });
      await onBlock();
      await this.loadCategories();
      await onUnBlock();
      this.setState({ isBlocking: false });
    }

    if (propsCompareMode !== stateCompareMode || propsCompareIds !== stateCompareIds) {
      const findTaskIndex = propsCompareIds.indexOf(propsTaskId);

      if (findTaskIndex !== -1) {
        propsCompareIds.splice(findTaskIndex, 1);
      }
      this.setState({
        compareMode: propsCompareMode,
        compareIds: propsCompareIds,
      });
      await this.loadCategories();
      if (selectedTreeItem && selectedTreeItem.id) {
        await this.actionsForTreeValues.onSelectTreeData(selectedTreeItem);
      }
    }

    if (prevState.setPointsNameFilter !== setPointsNameFilter) {
      this.setState({
        categoryBlockIsLoading: true,
      });
      await this.loadCategories();
      this.setState({
        categoryBlockIsLoading: false,
      });
    }
  }

  additionalComponentDidMount = async (component) => {
    const { passportId, blank, mode } = this.props;

    if (blank && !blank.is_doc && mode !== 'task') {
      const tree = await getDeviceFunctions(passportId);
      component.setState({ deviceFns: tree });
      this.setState({ deviceFns: tree, treeTableComponent: component });
    } else {
      this.setState({ treeTableComponent: component });
    }
  };

  additionalComponentWillUnmount = (component) => {
    const { deviceFns, ...state } = component.state;
    component.setState(state);
  };

  changeStatus = async (id, taskValueId, newStatus, selectedGroup) => {
    const { t, onChangeStatus } = this.props;
    const { treeValueData } = this.state;

    this.setState({
      isBlocking: true,
    });

    const answerSetStatus = await api.setTaskDcSetpointValueStatusMass([taskValueId], newStatus);

    if (answerSetStatus.status === 400) {
      message.error(t('PASSPORT_SETPOINTS_STATUS_CHANGE_PERMISSION_ERROR'));
    } else if (answerSetStatus.status !== 200) {
      message.error(t('PASSPORT_SETPOINTS_STATUS_CHANGE_DEFAULT_ERROR'));
    } else {
      const item = treeValueData.find((el) => el.id === id);
      const answerGetStatus = await api.getTaskDcSetpointValueStatus(taskValueId);

      if (answerGetStatus.status === 200) {
        item.status[selectedGroup] = answerGetStatus.data;
        this.setState({
          treeValueData,
        });
      }
    }

    onChangeStatus(taskValueId, newStatus);

    this.setState({
      isBlocking: false,
    });
  };

  openSetpointComments = (row, selectedGroup) => {
    const { isSidebarOpen, blank } = this.props;
    const { selectedCategoryId } = this.state;

    if (isSidebarOpen) {
      store.dispatch(closeSidebar());
    }

    if (row.task_dc_setpoint_value_id[selectedGroup]) {
      store.dispatch(openSidebar(
        SETPOINT_INFO,
        {
          id: row.task_dc_setpoint_value_id[selectedGroup],
          setpointValueId: row.value && row.value.id,
          blank: blank && blank.id,
          groupsCount: blank && blank.groups_count,
          functionId: selectedCategoryId,
          setpointId: row.id,
        }
      ));
    }
  };

  onMyChangeTreeValueData = async (newTreeValueData, operation, itemForOperation, component) => {
    const { onModified } = this.props;

    component.setState((state) => {
      const { oldTreeValueData, modifiedTreeValueData, modifiedTreeValueDataId } = state;

      const oldValue = oldTreeValueData.find((x) => x.id === itemForOperation.id);
      const indexModifiedValue = modifiedTreeValueDataId.indexOf(itemForOperation.id);
      const isSearchModifiedValue = modifiedTreeValueData[indexModifiedValue]
        && modifiedTreeValueData[indexModifiedValue].id === itemForOperation.id;
      const newModifiedTreeValueData = cloneDeep(modifiedTreeValueData);
      const newModifiedTreeValueDataId = cloneDeep(modifiedTreeValueDataId);

      if (indexModifiedValue >= 0 && isSearchModifiedValue) {
        newModifiedTreeValueData.splice(indexModifiedValue, 1);
        newModifiedTreeValueDataId.splice(indexModifiedValue, 1);
      }
      if (
        (oldValue && JSON.stringify(oldValue.value) !== JSON.stringify(itemForOperation.value)) ||
        JSON.stringify(oldValue.s2p_factor) !== JSON.stringify(itemForOperation.s2p_factor)
      ) {
        newModifiedTreeValueData.push(itemForOperation);
        newModifiedTreeValueDataId.push(itemForOperation.id);
      }

      return {
        modifiedTreeValueData: newModifiedTreeValueData,
        modifiedTreeValueDataId: newModifiedTreeValueDataId,
      };
    }, () => {
      const { modifiedTreeValueDataId } = component.state;

      onChangeTreeValueData(newTreeValueData, operation, itemForOperation, component);
      onModified && onModified(modifiedTreeValueDataId.length);

      if (modifiedTreeValueDataId.length) {
        component.onShowButtonsSaveCancel(true);
      } else {
        component.onShowButtonsSaveCancel(false);
      }

      this.setState({});
    });
  };

  getGroupsValues = (id, updatedGroupsValues) => {
    const result = updatedGroupsValues.find((item) => item.id === id);
    return result ? result.groups_values : null;
  };

  onEditSave = async () => {
    const {
      onBlock, onSaveValues, onUnBlock, onModified, t, mode, staticRec
    } = this.props;
    const { taskId, selectedTreeItem } = this.state;
    let { editMode, valuesViewMode } = this.state;

    if (!taskId) return;

    if (editMode) {
      this.setState({ isBlocking: true });
      await onBlock();

      this.setState({ saveInProgress: true });

      const { oldTreeValueData, modifiedTreeValueData, modifiedTreeValueDataId } = this.state;

      const selectedModifiedItem = modifiedTreeValueData.filter((item) =>
        modifiedTreeValueDataId.includes(item.id)
      );

      const selectedModifiedOldItem = oldTreeValueData.filter((item) =>
        modifiedTreeValueDataId.includes(item.id)
      );

      const groupsValuesOld = selectedModifiedOldItem.map((item) => ({
        id: item.value.id,
        groups_values: item.value.groups_values,
      }));

      const groupsValuesNew = selectedModifiedItem.map((item) => ({
        id: item.value.id,
        groups_values: item.value.groups_values,
      }));

      const updatedGroupsValues = groupsValuesNew.filter((newObj) => {
        const oldObj = groupsValuesOld.find((obj) => obj.id === newObj.id);
        if (!oldObj || newObj.groups_values === null) return false;

        newObj.groups_values = Object.entries(newObj.groups_values).reduce((acc, [key, value]) => {
          if (value !== oldObj.groups_values[key]) acc[key] = value;
          return acc;
        }, {});

        return Object.keys(newObj.groups_values).length > 0;
      });

      const values = [];
      modifiedTreeValueData.forEach((setpointValue) => {
        if (setpointValue && setpointValue.value) {
          values.push(
            {
              id: setpointValue.value.id,
              value: setpointValue.value.value,
              range_values: setpointValue.value.range_values,
              groups_values: this.getGroupsValues(setpointValue.value.id, updatedGroupsValues),
              protection_device_function: (
                setpointValue.value.protection_device_function
                && 'id' in setpointValue.value.protection_device_function
                  ? setpointValue.value.protection_device_function.id
                  : null
              ),
              view_complex: setpointValue.value.view_complex,
              view_task: mode !== 'task' ? setpointValue.value.view_task : undefined,
              s2p_factor: setpointValue.s2p_factor,
              calc_view_mode: valuesViewMode,
              static_rec: staticRec
            }
          );
        }
      });

      await onSaveValues(
        values,
        async ({ status, data }) => {
          if (status !== 200) {
            if (data && data.error) {
              if (data.error.indexOf('Numeric value') !== false
                && data.error.indexOf('must be in range') !== false
              ) {
                message.error(t('PASSPORT_SETPOINTS_SAVE_ERROR_VALUE_RANGE'));
              } else {
                message.error(t('PASSPORT_SETPOINTS_SAVE_ERROR_VALUE_OTHER'));
              }
              console.warn('!!! ERROR !!!'.concat('\n', data.error));
            } else {
              message.error(t('PASSPORT_SETPOINTS_SAVE_ERROR_VALUE_OTHER'));
              console.warn('!!! ERROR !!!'.concat('\n'), data);
            }
          } else {
            message.success(t('PASSPORT_SETPOINTS_SAVE_OK'));
          }
          this.setState({
            saveInProgress: false,
          });
        }
      );

      this.setState({
        editMode: false,
        modifiedTreeValueData: [],
        modifiedTreeValueDataId: [],
        showButtons: false,

        treeValueData: [],
        oldTreeValueData: [],
        dragTreeValueItem: {},
      });

      await this.loadCategories();
      await this.actionsForTreeValues.loadSetPoints(selectedTreeItem);

      this.setState({
        categoryBlockIsLoading: false,
        setPointsBlockIsLoading: false,
      });

      await onUnBlock();
      this.setState({ isBlocking: false });
      onModified && await onModified(0);
    } else {
      editMode = true;
      this.setState({ editMode });
    }

    this.setState({
      selectedTreeValueData: [],
    });
  };

  onClickButtonReset = async () => {
    const { onModified } = this.props;
    const { selectedTreeItem } = this.state;

    this.setState({

      editMode: false,

      categoryBlockIsLoading: true,
      setPointsBlockIsLoading: true,

      modifiedTreeValueData: [],
      modifiedTreeValueDataId: [],

      treeValueData: [],
      oldTreeValueData: [],
      dragTreeValueItem: {},
    });

    await this.loadCategories();
    await this.actionsForTreeValues.loadSetPoints(selectedTreeItem);

    this.setState({
      categoryBlockIsLoading: false,
      setPointsBlockIsLoading: false,
    });

    onModified && await onModified(0);
  };

  changeView = async (value) => {
    const { onModified, onViewChanged } = this.props;

    this.setState({
      currentView: value,
      modifiedTreeValueData: [],
      modifiedTreeValueDataId: [],
      // eslint-disable-next-line react/no-unused-state
      loadedCompareCategory: {}, // используется в this.actionsForTreeValues и в this.actionsForTree
      loadedCompareData: {},
    });
    onModified && onModified(0);
    onViewChanged && onViewChanged(value);
  };

  onShowButtonsSaveCancel = (visible) => {
    if (visible) {
      this.setState({
        showButtons: true,
      });
    } else {
      this.setState({
        showButtons: false,
      });
    }
  };

  setSelectedFunction = (func) => {
    const {
      treeValueData, treeTableComponent, selectedTreeValueData, selectedTreeItem,
    } = this.state;

    if (treeTableComponent) {
      const newData = cloneDeep(treeValueData);

      newData.forEach(
        (x, i) => {
          if (x.category
            && x.category.id === selectedTreeItem.id
            && selectedTreeValueData
            && selectedTreeValueData.includes(x.id)
            && x.value
          ) {
            newData[i].value.protection_device_function = func;
            this.onMyChangeTreeValueData(newData, 'batch-update', newData[i], this);
          }
        }
      );

      this.setState({
        selectedTreeValueData: [],
      });
      treeTableComponent.setState({
        selectedRowKeys: [],
        selectedRows: [],
      });
    }
  }

  renderChoiceView = () => {
    const { mode, t } = this.props;
    const { currentView, modifiedTreeValueData } = this.state;

    const currentViewSets = (currentView === this.VIEW_SETS);
    const currentViewFunctions = (currentView === this.VIEW_FUNCTIONS);
    const modifyCount = modifiedTreeValueData ? modifiedTreeValueData.length : 0;

    return mode === 'task'
      ? ''
      : (
        modifyCount
          ? (
            <>
              <div className='button-extra'>
                <Popconfirm
                  title={t('PASSPORT_SETPOINTS_CONFIRM_CHANGE_VIEW')}
                  onConfirm={() => this.changeView(this.VIEW_SETS)}
                  okText={t('YES')}
                  cancelText={t('NO')}
                >
                  <Button className={currentViewSets ? 'active' : ''} size='small'>
                    {t('PASSPORT_SETPOINTS_TABLE_OPTIONS_SETS')}
                  </Button>
                </Popconfirm>
              </div>
              <div className='button-extra'>
                <Popconfirm
                  title={t('PASSPORT_SETPOINTS_CONFIRM_CHANGE_VIEW')}
                  onConfirm={() => this.changeView(this.VIEW_FUNCTIONS)}
                  okText={t('YES')}
                  cancelText={t('NO')}
                >
                  <Button className={currentViewFunctions ? 'active' : ''} size='small'>
                    {t('PASSPORT_SETPOINTS_TABLE_OPTIONS_FUNCTIONS')}
                  </Button>
                </Popconfirm>
              </div>
            </>
          )
          : (
            <>
              <div className='button-extra'>
                <Button
                  size='small'
                  className={currentViewSets ? 'active' : ''}
                  onClick={() => this.changeView(this.VIEW_SETS)}
                >
                  {t('PASSPORT_SETPOINTS_TABLE_OPTIONS_SETS')}
                </Button>
              </div>
              <div className='button-extra'>
                <Button
                  size='small'
                  className={currentViewFunctions ? 'active' : ''}
                  onClick={() => this.changeView(this.VIEW_FUNCTIONS)}
                >
                  {t('PASSPORT_SETPOINTS_TABLE_OPTIONS_FUNCTIONS')}
                </Button>
              </div>
            </>
          )
      );
  };

  onSelectCategory = (selectedItem) => {
    this.setState({
      selectedCategoryId: selectedItem && selectedItem.id,
      selectedStatusId: null,
      setpointsId: [],
      prevStatus: null,
      nextStatus: null,
    },
    () => {
      this.actionsForTreeValues.onSelectTreeData(selectedItem);
    });
  }

  onSelectItem = (item) => {
    const { setpointsId, selectedGroup, selectedStatusId } = this.state;

    if (get(item, `status.${selectedGroup}.current_status.id`) !== selectedStatusId) {
      return undefined;
    }

    if (setpointsId.includes(item.task_dc_setpoint_value_id[selectedGroup])) {
      this.setState({
        setpointsId: without(setpointsId, item.task_dc_setpoint_value_id[selectedGroup]),
      });
    } else {
      this.setState({
        setpointsId: [...setpointsId, item.task_dc_setpoint_value_id[selectedGroup]],
      });
    }

    return undefined;
  }

  selectStatus = (selectedStatusId) => {
    const { treeValueData, selectedGroup, selectedCategoryId } = this.state;

    const index = treeValueData.findIndex((item) => (
      selectedStatusId === get(item, `status.${selectedGroup}.current_status.id`)
    ));

    const setpointsId = treeValueData.reduce((memo, item) => {
      const itemStatus = get(item, `status.${selectedGroup}.current_status.id`);
      const itemCategory = get(item, 'category.id');

      if (itemStatus === selectedStatusId && itemCategory === selectedCategoryId) {
        return [...memo, item.task_dc_setpoint_value_id[selectedGroup]];
      }

      return memo;
    }, []);

    this.setState({
      prevStatus: get(
        treeValueData,
        `${index}.status.${selectedGroup}.prev_status`, null
      ),
      selectedStatusId,
      setpointsId,
      nextStatus: get(treeValueData, `${index}.status.${selectedGroup}.next_status`, null),
    });
  }

  onChangeStatus = async (status) => {
    const { t, onChangeStatus } = this.props;
    const { treeValueData, setpointsId, selectedGroup } = this.state;

    this.setState({ isBlocking: true });

    const response = await api.setTaskDcSetpointValueStatusMass(setpointsId, status);

    this.setState({ isBlocking: false });

    if (response.status === 400) {
      message.error(t('PASSPORT_SETPOINTS_STATUS_CHANGE_PERMISSION_ERROR'));
    } else if (response.status !== 200) {
      message.error(t('PASSPORT_SETPOINTS_STATUS_CHANGE_DEFAULT_ERROR'));
    } else {
      const answerGetStatus = await api.getTaskDcSetpointValueStatus(setpointsId[0]);

      if (answerGetStatus.status === 200) {
        const newTreeValueData = [...treeValueData];

        setpointsId.forEach((id) => {
          const setpoint = newTreeValueData.find((item) => (
            item.task_dc_setpoint_value_id[selectedGroup] === id
          ));

          if (setpoint) {
            setpoint.status = { ...setpoint.status, [selectedGroup]: answerGetStatus.data };
          }
        });

        this.setState({
          treeValueData: newTreeValueData,
          selectedStatusId: null,
          setpointsId: [],
          prevStatus: answerGetStatus.data.prev_status,
          nextStatus: answerGetStatus.data.next_status,
        });

        onChangeStatus(setpointsId[0], status);
      }
    }
  }

  onExportBlank = async (e, mode) => {
    e.stopPropagation();
    e.preventDefault();

    const { blank } = this.props;

    this.setState({ isFileDownloading: true });

    const groupByFunction = this.state.currentView === this.VIEW_FUNCTIONS ? true : false;
    await exportBlank(blank, this, mode, groupByFunction);

    this.setState({ isFileDownloading: false });
  };

  onImportBlank = async () => {
    const { task, blank, openSidebarData } = this.props;
    const { selectedTreeItem } = this.state;

    openSidebarData(
      IMPORT_BLANK,
      {
        type: 'task',
        task__id: task.id,
        blank__id: blank.id,
        callback: async () => {
          await this.loadCategories();
          if (selectedTreeItem && selectedTreeItem.id) {
            await this.onClickButtonReset();
          }
        },
      }
    );
  };

  async loadCategories() {
    const {
      taskId, currentView, setPointsNameFilter, loadedCompareData, valuesViewMode
    } = this.state;

    const {
      mode, passportId, t, blank, task,
    } = this.props;

    if (!taskId || !currentView || (blank && blank.is_doc)) return;

    this.setState({ categoryBlockIsLoading: true });

    if (this.VIEW_SETS === currentView) {
      const functionsRes = await this.actionsForTree.compareCategoriesForFunctionOptionSet(
        passportId,
        taskId,
        setPointsNameFilter,
        'v1_getCategoriesForBlank',
        (res) => cloneDeep(res.results),
        null,
        null,
        valuesViewMode
      );

      if (functionsRes !== null) {
        this.setState({
          treeData: functionsRes,
        });
      }
    } else if (this.VIEW_FUNCTIONS === currentView) {
      const withoutFunction = this.actionsForTree.getNoFunctionCategory();
      if (mode === 'task') {
        if (task && blank && !blank.is_doc && task.status.current_status.id !== 'planned') {
          const functionsRes = await api.v1_getFunctionsForTaskDC(taskId, blank.id, setPointsNameFilter);

          if (functionsRes.status !== 200) {
            this.setState({ categoryBlockIsLoading: false });
            message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
            return;
          }

          const newTree = [...cloneDeep(functionsRes.data.results), withoutFunction];

          this.setState({
            treeData: newTree,
          });
        }
      } else {
        const newData = [];
        const oldData = [];

        const functionsRes = await this.actionsForTree.compareCategoriesForFunctionOptionSet(
          passportId,
          taskId,
          setPointsNameFilter,
          'v1_getFunctionsForBlank',
          (res) => [...cloneDeep(res.results), withoutFunction],
          null,
          null,
          valuesViewMode
        );
        if (functionsRes !== null) {
          this.setState({
            treeData: functionsRes,
            oldTreeValueData: oldData,
            treeValueData: newData,
            loadedCompareData,
          });
        }
      }
    }
    this.setState({ categoryBlockIsLoading: false });
  }

  onClickButtonValuesChange = (calcViewMode) => {
    this.setState({
      valuesViewMode: calcViewMode
    }, () => {
      this.onClickButtonReset();
    })
  }

  onClickButtonValuesPrimary = () => this.onClickButtonValuesChange('primary');
  onClickButtonValuesSecondary = () => this.onClickButtonValuesChange('secondary');

  onClickButtonRecalculation = () => {
    this.props.setStaticRec();
    this.onClickButtonReset();
  };

  render() {
    const {
      /** *************** state for Tree ************** */
      treeData,
      selectedTreeItem,
      expandedTreeItems,
      isViewTree,
      categoryBlockIsLoading,

      /** *************** state for Tree Values ************** */
      treeValueData,
      dragTreeValueItem,
      setPointsBlockIsLoading,
      selectedStatusId,

      /** *************** state for this component ************** */
      setPointsNameFilter,

      saveInProgress,
      editMode,

      modifiedTreeValueData,
      modifiedTreeValueDataId,

      currentView,

      taskId,
      compareMode,

      isBlocking,
      valuesViewMode,
      showButtons,
      isFileDownloading,

      selectedTreeValueData,
      isEditItem,

      groupsCount,
      activeGroup,
      selectedGroup,

      deviceFns,
      nextStatus,
      prevStatus,
      setpointsId,
      selectedCategoryId,
    } = this.state;

    const {
      deviceId,
      title,
      mode,
      isEditRight,
      isEditBlocked,
      t,
      taskStatus,
      blank,
      task,
      isDefaultOpenPanel,
      titleExtra,
      staticRec
    } = this.props;

    const modifyCount = modifiedTreeValueData
      ? modifiedTreeValueData.length
      : 0;

    const disableEditSave = (
      saveInProgress
      || !taskId
      || !isEditRight
      || isEditBlocked
      || (editMode && modifyCount === 0)
      || (editMode && !showButtons)
      || (editMode && isEditItem)
      || (blank && blank.is_doc)
    );

    const enableImportExport = (
      !disableEditSave
      && !editMode
      && task
      && task.status
      && task.status.current_status
      && task.status.current_status.id
      && [STATUS_CALCULATING, READY_TO_FORMED, STATUS_NEW].includes(task.status.current_status.id)
    );

    const filteredTreeValueData = setPointsNameFilter === ''
      ? treeValueData
      : treeValueData.filter((item) => item.name.toLowerCase().indexOf(setPointsNameFilter.toLowerCase()) !== -1);

    const disabledGroupButtons = (
      !taskId
      || !isEditRight
      || isEditBlocked
      || editMode
      || groupsCount === 1
      || compareMode
    );

    const statuses = filteredTreeValueData.reduce((memo, { status, category }) => {
      if (!get(status, `${selectedGroup}.current_status`) || (selectedCategoryId !== get(category, 'id'))) {
        return memo;
      }

      return {
        ...memo,
        [get(status, `${selectedGroup}.current_status.id`)]: (
          <Option value={get(status, `${selectedGroup}.current_status.id`)} key={`select_${get(status, `${selectedGroup}.current_status.id`)}`}>
            {get(status, `${selectedGroup}.current_status.name`)}
          </Option>
        ),
      };
    }, {});

    const selectedTreeValueCount = selectedTreeValueData && selectedTreeValueData.length;
    const isVersionBlankV2 = blank?.type === 'V2';
    const digitalBlankId = blank?.digital_blank_id;
    const blankId = blank?.id;
    const protectionDeviceId =
      typeof blank?.protection_device === 'object'
        ? blank?.protection_device?.id
        : blank?.protection_device;

    const groups = [];
    if (selectedGroup) {
      for (let i = 1; i <= groupsCount; i += 1) {
        groups.push(
          <Option value={i} key={i}>
            {`${t('GROUP')} ${i} `}
            {
              i === activeGroup && !disabledGroupButtons
                ? <CheckCircleOutlined className='check-circle--active' />
                : ''
            }
          </Option>
        );
      }
    }

    const getOldType = (blank, task) => {
      if (blank && task) {
        if (task?.blanks.length === 1) {
          return task?.blanks[0]?.old_type;
        } else {
          return blank?.old_type;
        }
      }
    };

    return (
      <Spin spinning={isBlocking || isFileDownloading}>
        <CustomCard
          collapsable
          noPadding
          noBottomMargin
          titleExtra={titleExtra}
          loading={isBlocking}
          count={treeData && treeData.length}
          isDefaultOpenPanel={isDefaultOpenPanel}
          hasData={(treeData && treeData.length > 0) || (setPointsNameFilter && setPointsNameFilter.length > 0)}
          className='setpoints-tree templates'
          title={title}
          extra={(
            <div className='template'>
              <span className='passport-setpoints__actions'>
                {
                  saveInProgress
                    ? (
                      <>
                        <span className='modifiedCount'>
                          {t('PASSPORT_SETPOINTS_SAVING_VALUES')}
                          &nbsp;
                        </span>
                        <Spin />
                        &nbsp;
                      </>
                    )
                    : null
                }
                {
                  !saveInProgress && modifyCount
                    ? (
                      <span className='modifiedCount'>
                        {`${t('PASSPORT_SETPOINTS_AMOUNT_CHANGED_VALUES')}: ${modifyCount}`}
                      </span>
                    )
                    : null
                }
                {
                  !saveInProgress && editMode
                    ? (
                      <Popconfirm
                        title={t('PASSPORT_SETPOINTS_CONFIRM_RESET_CHANGES')}
                        onConfirm={this.onClickButtonReset}
                        okText={t('YES')}
                        cancelText={t('NO')}
                        disabled={editMode && isEditItem}
                      >
                        <Button
                          className='control-button'
                          size='small'
                          disabled={editMode && isEditItem}
                        >
                          {t('PASSPORT_SETPOINTS_RESET_CHANGES')}
                        </Button>
                      </Popconfirm>
                    )
                    : null
                }
                {mode === 'task' && !isVersionBlankV2 && (
                  <>
                    {prevStatus && prevStatus.map && prevStatus.map((prevStatusItem) => (
                      <Tooltip title={prevStatusItem.name}>
                        <Button
                          key={`status_change_${prevStatusItem.id}`}
                          disabled={
                            saveInProgress
                            || !selectedStatusId
                            || isBlocking
                            || !prevStatusItem
                            || !prevStatusItem.id
                            || !taskId
                            || !isEditRight
                            || !setpointsId.length
                          }
                          onClick={() => this.onChangeStatus(prevStatusItem.id)}
                          type='primary'
                          className='control-button'
                          size='small'
                          shape='circle'
                          icon={<LeftOutlined />}
                        />
                      </Tooltip>
                    ))}
                    {(!prevStatus || !prevStatus.map) && (
                      <Tooltip title={prevStatus && prevStatus.name}>
                        <Button
                          disabled={
                            saveInProgress
                            || !selectedStatusId
                            || isBlocking
                            || !prevStatus
                            || !prevStatus.id
                            || !taskId
                            || !isEditRight
                            || !setpointsId.length
                          }
                          onClick={() => this.onChangeStatus(prevStatus.id)}
                          type='primary'
                          className='control-button'
                          size='small'
                          shape='circle'
                          icon={<LeftOutlined />}
                        />
                      </Tooltip>
                    )}
                    <CustomSelect
                      className='control-button passport-setpoints__select_status'
                      size='small'
                      value={selectedStatusId}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
                      onChange={this.selectStatus}
                      placeholder={t('SELECT_STATUS')}
                      disabled={
                        Object.values(statuses).length === 0
                        || (
                          task
                          && task.source_blank
                          && task.source_blank.is_doc === true
                        )
                      }
                      allowClear
                    >
                      {Object.values(statuses)}
                    </CustomSelect>
                    <Tooltip title={nextStatus && nextStatus.name}>
                      <Button
                        disabled={
                          saveInProgress
                          || !selectedStatusId
                          || isBlocking
                          || !nextStatus
                          || !nextStatus.id
                          || !taskId
                          || !isEditRight
                          || !setpointsId.length
                        }
                        onClick={() => this.onChangeStatus(nextStatus.id)}
                        type='primary'
                        className='control-button'
                        size='small'
                        shape='circle'
                        icon={<RightOutlined />}
                      />
                    </Tooltip>
                    <Divider type='vertical' />
                  </>
                )}
                <Button
                  disabled={disableEditSave || isVersionBlankV2}
                  onClick={this.onEditSave}
                  type='primary'
                  className='control-button'
                  size='small'
                >
                  {
                    editMode
                      ? t('PASSPORT_SETPOINTS_SAVE_VALUES')
                      : t('PASSPORT_SETPOINTS_EDIT_VALUES')
                  }
                </Button>
                {mode === 'setpoints' && (
                  <Button
                    disabled={!taskId || !blank || blank.status === 'in_work'}
                    type='primary'
                    className='control-button'
                    size='small'
                  >
                    <Link
                      to={
                        ''.concat(
                          `/passport/${deviceId}/tab/tasks/`,
                          blank && blank.task_dcs && blank.task_dcs.length > 0
                            ? ''.concat(
                              `?id=${blank.task_dcs[0].id}`,
                              `&name=${blank.task_dcs[0].name}`,
                              `&blank_id=${blank.id}`,
                              `&blank_name=${blank.name}`,
                              '&openBlankPanel=true',
                              '&openSetpointsPanel=true',
                              ''
                            )
                            : ''
                        )
                      }
                    >
                      {t('PASSPORT_SETPOINTS_TASK_NAVIGATE')}
                    </Link>
                  </Button>
                )}
              </span>

              {
                mode === 'task'
                  ? (
                    <>
                      <Divider type='vertical' />

                      <Button
                        disabled={!enableImportExport || isVersionBlankV2}
                        onClick={(e) => this.onExportBlank(e, EXPORT_MODE_PF)}
                        id='button_export_pf_protection'
                        type='secondary'
                        size='small'
                        className='control-button'
                        icon={<ExportOutlined />}
                      >
                        {t('PFPROTECTION_EXPORT')}
                      </Button>

                      <Button
                        disabled={!isEditRight || !enableImportExport || isVersionBlankV2}
                        id='button_import_pf_protection'
                        type='primary'
                        onClick={this.onImportBlank}
                        size='small'
                        className='control-button'
                        icon={<ImportOutlined />}
                      >
                        {t('PFPROTECTION_IMPORT')}
                      </Button>
                    </>
                  )
                  : ''
              }

              {!isVersionBlankV2 && (
                <>
                  <Divider type="vertical" />
                  <span className="passport-setpoints__name-filter">
                    <Input
                      id="filter"
                      size="small"
                      value={setPointsNameFilter}
                      placeholder={t(
                        'PASSPORT_SETPOINTS_SETPOINTS_NAME_FILTER'
                      )}
                      onChange={(x) =>
                        this.setState({
                          setPointsNameFilter: x.target.value,
                        })
                    }
                    />
                  </span>
                </>
              )}
            </div>
          )}
        >
          <div className='template'>
            {isVersionBlankV2 ? (
              <div className='wrapperLink'>
                <a
                  href={`${REDIRECT_URL_NEW_FRONT}/passport/${protectionDeviceId}/setpoints?form=passport-setpoint-preview&form_id=${digitalBlankId}&form_base_id=${blankId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Ссылка на просмотр бланка уставок на новый интерфейс</span>
                </a>
              </div>
            ) : (
              <div
                className="template__card template__card_body"
                style={{ marginTop: '32px' }}
              >
                {treeData && treeData.length ? (
                  <div className="template-wrapper">
                    <div
                      className={`template-left ${
                        !isViewTree && 'hidden'
                      }`}
                    >
                      <TemplateTree
                        /** **** Все поля являются обязательными для передачи ***** */
                        isLoading={categoryBlockIsLoading}
                        t={withPrefix('PASSPORT_SETPOINTS_', t)}
                        groups={treeData}
                        isRootTreeItem={true}
                        newItem={
                          this.actionsForTree.newTreeItem
                        }
                        onSelectTreeData={this.onSelectCategory}
                        onChangeTreeData={() => false}
                        dragTreeValueItem={dragTreeValueItem}
                        onDropTreeValueItemIntoNewTreeItem={(
                          dragObject,
                          dropObject
                        ) =>
                          onDropTreeValueItemIntoNewTreeItem(
                            dragObject,
                            dropObject,
                            this.onMyChangeTreeValueData,
                            this
                          )
                        }
                        onClearDragTreeValue={() =>
                          onClearDragTreeValue(this)
                        }
                        onShowButtonsSaveCancel={
                          this.onShowButtonsSaveCancel
                        }
                        isCanEdit={false}
                        isCanDragAndDrop={false}
                        isShowButtonsPanel={mode !== 'task'}
                        /** **** (необязательно) ***** */
                        /** **** Список стандартных кнопок, которые разрешены/могут быть активны ***** */
                        /** **** ['add','delete']. Если все активны - можно не передавать ***** */
                        enableStandardButtons={[]}
                        visibleStandardButtons={[]}
                        /** **** Использование дополнительных кнопок ***** */
                        onExpandTreeData={(expandedKeys) =>
                          this.actionsForTree.onExpandTreeData(
                            expandedKeys
                          )
                        }
                        /** **** Использование дополнительных кнопок дерева ***** */
                        extraButtons={this.renderChoiceView}
                        /** **** Переопределение отмеченных элементов дерева (при изменении дерева) ***** */
                        selectedKeys={
                          selectedTreeItem &&
                          'id' in selectedTreeItem
                            ? [selectedTreeItem.id]
                            : []
                        }
                        /** **** Переопределение открытых элементов дерева (при изменении дерева) ***** */
                        expandedKeys={expandedTreeItems}
                      />
                    </div>
                    <div
                      className={`template-right ${
                        !isViewTree && 'full-size'
                      }`}
                    >
                      <TemplateTreeValues
                        /** **** Все поля являются обязательными для передачи ***** */
                        originComponent={this}
                        t={withPrefix('PASSPORT_SETPOINTS_', t)}
                        isViewTree={isViewTree}
                        onViewTree={(isShow) =>
                          onViewTree(this, isShow)
                        }
                        values={filteredTreeValueData}
                        renderColumns={(
                          component,
                          dataFiltered
                        ) =>
                          this.actionsForTreeValues.renderTreeValueColumns(
                            component,
                            this,
                            currentView === this.VIEW_SETS,
                            dataFiltered
                          )
                        }
                        onSelectItem={this.onSelectItem}
                        setpointsId={setpointsId}
                        selectedGroup={selectedGroup}
                        onAddItem={
                          this.actionsForTreeValues
                            .onAddTreeValueItem
                        }
                        newItem={
                          this.actionsForTreeValues
                            .newTreeValueItem
                        }
                        onEditItem={
                          this.actionsForTreeValues
                            .onEditTreeValueData
                        }
                        onSaveEditedData={
                          this.actionsForTreeValues
                            .onSaveEditedTreeValueData
                        }
                        selectedTreeItem={selectedTreeItem}
                        onChangeTreeValueData={(
                          newTreeValueData,
                          operation,
                          item
                        ) =>
                          this.onMyChangeTreeValueData(
                            newTreeValueData,
                            operation,
                            item,
                            this
                          )
                        }
                        dragTreeValueItem={dragTreeValueItem}
                        onDragStartTreeValueItem={(item) =>
                          onDragStartTreeValueItem(item, this)
                        }
                        onDropTreeValueItemInside={(
                          dragObject,
                          dropAfterObject
                        ) =>
                          onDropTreeValueItemInside(
                            dragObject,
                            dropAfterObject,
                            this
                          )
                        }
                        onShowButtonsSaveCancel={
                          this.onShowButtonsSaveCancel
                        }
                        isCanEdit={
                          isEditRight &&
                          editMode &&
                          !selectedTreeValueCount
                        }
                        isCanDragAndDrop={false}
                        isShowButtonsPanel={true}
                        /** **** необязательные параметры ***** */
                        isLoading={setPointsBlockIsLoading}
                        /** **** Название переменной для сохранения ширины колонок ***** */
                        tableName={`setpointTree_${
                          mode === undefined ? 'blank' : mode
                        }`}
                        /** **** Список стандартных кнопок, которые разрешены/могут быть активны ***** */
                        /** **** ['tree','add','edit','delete','copy/paste','settings']. ***** */
                        /** **** Если все активны - можно не передавать ***** */
                        enableStandardButtons={[
                          'tree',
                          'edit',
                          mode === 'setpoints'
                            ? 'settings'
                            : '',
                        ]}
                        visibleStandardButtons={[
                          'tree',
                          'edit',
                          mode === 'setpoints'
                            ? 'settings'
                            : '',
                        ]}
                        saveSettings={mode === 'setpoints'}
                        /** **** Загрузка и использование доп.переменных в state-е компонента "Значений" ***** */
                        additionalComponentDidMount={
                          this.additionalComponentDidMount
                        }
                        additionalComponentWillUnmount={
                          this.additionalComponentWillUnmount
                        }
                        /** **** Использование дополнительных кнопок ***** */
                        extraButtons={() => (
                          <>
                            {this.VIEW_SETS ===
                            currentView ? (
                              <div className="setpoints-tree-select-function-wrapper">
                                <div className="setpoints-tree-select-function-label">
                                  {t(
                                    'PASSPORT_SETPOINTS_SELECT_FUNCTION_LABEL'
                                  )}
                                </div>
                                <TreeSelect
                                  size="small"
                                  treeDefaultExpandAll
                                  disabled={
                                    !editMode ||
                                    !selectedTreeValueCount
                                  }
                                  className="setpoints-tree-select-function-select"
                                  value={null}
                                  onChange={(
                                    selectedFunctionId
                                  ) =>
                                    onChangeSelectedFn(
                                      selectedFunctionId,
                                      deviceFns,
                                      (found) =>
                                        this.setSelectedFunction(
                                          found
                                        )
                                    )
                                  }
                                >
                                  {renderFunctionsSelect(
                                    deviceFns,
                                    [],
                                    undefined,
                                    t
                                  )}
                                </TreeSelect>
                              </div>
                            ) : (
                              ''
                            )}
                            <div className="units-change-buttons">
                              <Tooltip
                                title={t(
                                  'SETPOINTS_BUTTON_TIP_CHANGE_UNITS_PRIMARY'
                                )}
                              >
                                <Button
                                  size="small"
                                  onClick={
                                    this
                                      .onClickButtonValuesPrimary
                                  }
                                  disabled={
                                    getOldType(
                                      blank,
                                      task
                                    ) ||
                                    valuesViewMode ===
                                    'primary' ||
                                    blank?.old_type ||
                                    editMode ||
                                    isEditItem
                                  }
                                >
                                  1
                                </Button>
                              </Tooltip>
                              <Icon
                                component={
                                  TransformerIcon
                                }
                                style={{
                                  margin: 'auto',
                                }}
                              />
                              <Tooltip
                                title={t(
                                  'SETPOINTS_BUTTON_TIP_CHANGE_UNITS_SECONDARY'
                                )}
                              >
                                <Button
                                  size="small"
                                  onClick={
                                    this
                                      .onClickButtonValuesSecondary
                                  }
                                  disabled={
                                    getOldType(
                                      blank,
                                      task
                                    ) ||
                                    valuesViewMode ===
                                    'secondary' ||
                                    blank?.old_type ||
                                    editMode ||
                                    isEditItem
                                  }
                                >
                                  2
                                </Button>
                              </Tooltip>
                              <Select
                                value={
                                  staticRec
                                    ? t('STATIC')
                                    : t('DYNAMIC')
                                }
                                style={{ width: 140 }}
                                onChange={
                                  this
                                    .onClickButtonRecalculation
                                }
                                size="small"
                                disabled={
                                  getOldType(
                                    blank,
                                    task
                                  ) ||
                                  blank?.old_type ||
                                  valuesViewMode ===
                                  'secondary' ||
                                  editMode ||
                                  isEditItem
                                }
                                options={[
                                  {
                                    value: t(
                                      'STATIC'
                                    ),
                                    label: (
                                      <Tooltip
                                        title={t(
                                          'RECALCULATION_BLANK'
                                        )}
                                      >
                                        {t(
                                          'STATIC'
                                        )}
                                      </Tooltip>
                                    ),
                                  },
                                  {
                                    value: t(
                                      'DYNAMIC'
                                    ),
                                    label: (
                                      <Tooltip
                                        title={t(
                                          'RECALCULATION_TEMPLATE'
                                        )}
                                      >
                                        {t(
                                          'DYNAMIC'
                                        )}
                                      </Tooltip>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          </>
                        )}
                        /** **** Список из ID измененных элементов, для выделения в редакторе ***** */
                        modifiedTreeItemsList={
                          modifiedTreeValueDataId
                        }
                        /** **** Выделение строки данных при определенных условиях (возвращается className) ***** */
                        checkErrorValueAndGetClassName={
                          this.actionsForTreeValues
                            .checkErrorValueAndGetClassName
                        }
                        /** **** Разрешение на редактирование конкретной записи ***** */
                        checkRecordForEdit={
                          this.actionsForTreeValues
                            .checkRecordForEdit
                        }
                        /** **** Дополнительная обработка элемента после копирования ***** */
                        // onCopyTreeValueData={this.actionsForTreeValues.onCopyTreeValueData}
                        /** **** Настройки выделения строк в таблице ***** */
                        rowSelection={
                          this.VIEW_SETS === currentView &&
                          editMode &&
                          !isEditItem
                            ? this.actionsForTreeValues.rowSelection()
                            : undefined
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span>
                        {taskStatus === 'planned'
                          ? t('NOT_SETPOINTS_FOR_PLANNED')
                          : t('NOT_FOUND_BY_PARAMETERS')}
                      </span>
                    }
                  />
                )}
              </div>
            )}
          </div>
        </CustomCard>
      </Spin>
    );
  }
}

const mapDispatchToProps = {
  openSidebarData: openSidebar,
};

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isOpen,
  wsState: state.websocket,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    SetpointsTree
  )
);
