import React from 'react';
import { withTranslation } from 'react-i18next';

import { Layout } from '@ui';
import FunctionsComponent from './functions';

export const FunctionsRoute = (props) => {
  const { t } = props;
  return (
    <Layout title={t('LEFT_MENU_ITEM_FUNCTIONS')}>
      <FunctionsComponent />
    </Layout>
  );
};

export default withTranslation()(
  FunctionsRoute
);
