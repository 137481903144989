import React from 'react';

import { Select } from 'antd';
import { closeAllPopups } from '@app/helpers';

import PopoverEllipsis from '../popoverEllipsis';

const onSelectHandler = (value, option, onSelect) => {
  closeAllPopups(null, 'CustomSelect');
  onSelect && onSelect(value, option);
};

const CustomSelect = (props) => {
  const {
    children, getPopupContainerDefault, popoverPlacement, onSelect, ...restProps
  } = props;

  return (
    <Select
      getPopupContainer={
        !getPopupContainerDefault && ((trigger) => trigger)
      }
      onSelect={(value, option) => onSelectHandler(value, option, onSelect)}
      {...restProps}
    >
      {children && children.length > 0 && children.map((option) => {
        return (
          <Select.Option
            key={option.key}
            {...option.props}
          >
            {option.props && (
              <PopoverEllipsis
                content={option.props?.children?.toString()}
                popoverPlacement={popoverPlacement}
              >
                {option.props?.children}
              </PopoverEllipsis>
            )}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
