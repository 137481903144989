import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Input, Popconfirm, Spin, Tree, message,
} from 'antd';
import { ApartmentOutlined, DeleteOutlined, EnterOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import { MAP_RIGHTS_TO_PATHS, MODE_EDIT, ROUTE_TEMPLATES } from "@app/constants";

import styles from './style.module.less';

class TemplateTree extends Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;

    this.state = {
      selectedKeys: [],
      expandedKeys: ['0'],
      edited: '',
      editedKey: '',
      data: [],
    };
  }

  componentDidMount() {
    const { groups, selectedKeys, expandedKeys } = this.props;
    const { selectedKeys: stateSelectedKeys, expandedKeys: stateExpandedKeys } = this.state;
    this.setState({
      data: groups,
      selectedKeys: selectedKeys || stateSelectedKeys,
      expandedKeys: expandedKeys || stateExpandedKeys,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      groups, selectedKeys, expandedKeys, onSelectTreeData,
    } = this.props;
    const { selectedKeys: stateSelectedKeys, expandedKeys: stateExpandedKeys } = this.state;

    if (JSON.stringify(prevProps.groups) !== JSON.stringify(groups)) {
      const newStateSelectedKeys = [];
      if (stateSelectedKeys && stateSelectedKeys.length) {
        this.loop(groups, stateSelectedKeys[0], () => {
          newStateSelectedKeys.push(stateSelectedKeys[0]);
        });
      }
      this.setState({
        data: groups,
        selectedKeys: selectedKeys || newStateSelectedKeys,
        expandedKeys: expandedKeys || stateExpandedKeys,
      });
      if (JSON.stringify(stateSelectedKeys) !== JSON.stringify(newStateSelectedKeys)
        && newStateSelectedKeys.length === 0
      ) {
        onSelectTreeData({});
      }
    }
  }

  onDblClick(event, node, component) {
    const { onShowButtonsSaveCancel, onEditItem } = this.props;

    let editedItem = (
      node.props.dataRef
        ? node.props.dataRef.name
        : node.props.title
    ) || null;

    const editedKey = node.props.eventKey;
    onShowButtonsSaveCancel(true);
    if (onEditItem) {
      editedItem = onEditItem(editedKey, editedItem);
    }

    component.setState({
      editedKey: editedKey,
      edited: editedItem,
    });
  }

  onSaveTreeNodeTitle(event, editedItem) {
    const {
      onShowButtonsSaveCancel, onChangeTreeData, t, onSelectTreeData,
    } = this.props;
    const {
      data, edited, editedKey, selectedKeys,
    } = this.state;
    const newGroups = cloneDeep(data);
    onShowButtonsSaveCancel(true);
    let newEditedItem = null;
    let newSelectedKeys = selectedKeys;

    const findSameName = (items = []) => {
      if (!items || !items.length) {
        return false;
      }

      items.forEach((item) => {
        if (item.name === event.target.value) {
          message.warn(t('WARN_SAME_TREE_ITEM'));
          return true;
        }

        findSameName(item.children);
      });

      return false;
    };

    findSameName(data);

    if (edited) {
      this.loop(newGroups, editedItem.id, (item, index, arr) => {
        newEditedItem = {
          ...arr[index],
          name: edited,
        };
        // eslint-disable-next-line no-param-reassign
        arr[index] = newEditedItem;
      });
      if (newEditedItem.isNewItem) {
        onChangeTreeData(newGroups, 'insert', newEditedItem);
      } else {
        onChangeTreeData(newGroups, 'edit', newEditedItem);
        onSelectTreeData(newEditedItem);
      }
    } else if (!(editedItem && editedItem.name && editedItem.name.length)) {
      this.loop(newGroups, editedKey, (item, index, arr) => {
        arr.splice(index, 1);
        newSelectedKeys = newSelectedKeys.filter((x) => x !== editedKey);
      });
      onChangeTreeData(newGroups, 'revertInsert', {});
      message.warn(t('WARN_EMPTY_TREE_ITEM'));
    }
    this.setState({
      edited: '',
      editedKey: '',
      data: newGroups,
      selectedKeys: newSelectedKeys,
    });
  }

  getIcon = (props) => (props.isLeaf ? <EnterOutlined /> : '');

  getTreeNodeTitle(item, component) {
    const { edited } = this.state;
    const { t } = this.props;

    return (
      item.id !== component.state.editedKey
        ? item.name + (item.children && item.children.length ? ` (${item.children.length})` : '')
        : (
          <Input
            type='text'
            defaultValue={item.name}
            value={edited}
            size='small'
            placeholder={t('TREE_NEW_TREE_ITEM_VALUE')}
            key={`input_${item.id}`}
            onChange={(event) => this.setState({ edited: event.target.value })}
            onBlur={(event) => this.onSaveTreeNodeTitle(event, item)}
            onPressEnter={(event) => this.onSaveTreeNodeTitle(event, item)}
            autoFocus={true}
          />
        )
    );
  }

  loop = (data, id, callback) => {
    data.forEach((item, index, arr) => {
      if (item.id === id) {
        callback(item, index, arr);
      }
      if ('children' in item && item.children) {
        this.loop(item.children, id, callback);
      }
    });
  };

  onSelect = (selectedKeys, event) => {
    const { onSelectTreeData } = this.props;

    this.setState({ selectedKeys });
    onSelectTreeData(event.node.dataRef);
  };

  onExpand = (expandedKeys) => {
    const { onExpandTreeData } = this.props;

    this.setState({ expandedKeys });

    if (onExpandTreeData) {
      onExpandTreeData(expandedKeys);
    }
  };

  onAddItemTemplate = (component) => {
    const { newItem: originalNewItem, onAddItem, onShowButtonsSaveCancel } = this.props;
    const { selectedKeys, expandedKeys } = this.state;
    const newGroups = component.state.data;
    let item;

    if (selectedKeys && selectedKeys.length === 1 && selectedKeys[0] !== '0') {
      this.setState({
        expandedKeys: [...expandedKeys, selectedKeys[0]],
      });
      item = originalNewItem(selectedKeys[0]);
      if (item) {
        this.loop(newGroups, selectedKeys[0], (x, index, arr) => {
          if ('children' in arr[index]) {
            // eslint-disable-next-line no-param-reassign
            arr[index].children = [...arr[index].children, item];
          } else {
            // eslint-disable-next-line no-param-reassign
            arr[index].children = [item];
          }
        });
      }
    } else {
      item = originalNewItem(null);
      if (item) {
        newGroups.push(item);
      }
    }
    if (item) {
      if (onAddItem) {
        item = onAddItem(item);
      }
      onShowButtonsSaveCancel(true);
      component.setState({
        data: newGroups,
        edited: item.name,
        editedKey: item.id,
      });
    }
  };

  onDeleteItemTemplate = (component) => {
    const { onChangeTreeData, onSelectTreeData, onShowButtonsSaveCancel } = this.props;
    const { selectedKeys, data } = component.state;

    if (selectedKeys && selectedKeys.length === 1) {
      const newGroups = cloneDeep(data);
      let deletedItem = {};
      this.loop(newGroups, selectedKeys[0], (item, index, arr) => {
        deletedItem = cloneDeep(item);
        arr.splice(index, 1);
      });
      onChangeTreeData(newGroups, 'delete', deletedItem);
      component.setState({
        data: newGroups,
        selectedKeys: [],
      });
      onSelectTreeData(null);
      onShowButtonsSaveCancel(true);
    }
  };

  renderTreeElement = (element) => {
    if (element && Array.isArray(element) && element.length > 0) {
      return element.map((item) => {
        const className = `${item.wasDelete ? 'wasDelete' : ''} ${item.wasAdd ? 'wasAdd' : ''}`;
        const el = {
          title: this.getTreeNodeTitle(item, this),
          key: item.id,
          dataRef: item,
          dataKey: item.id,
          className: className,
        };
        if (item.children && item.children.length > 0) {
          el.expanded = true;
          el.children = this.renderTreeElement(item.children);
        } else {
          el.isLeaf = true;
        }
        return el;
      });
    }
    return [];
  }

  onDragStart = (ev) => {
    const { event, node } = ev;
    const nativeEvent = event.nativeEvent;

    if (node.key === '0') {
      nativeEvent.preventDefault && nativeEvent.preventDefault();
    }
  }

  onDragOverHandle = (name, ev) => {
    const { event, node } = ev;
    const nativeEvent = event && event.nativeEvent;

    if (node && node.key !== '0') {
      nativeEvent && nativeEvent.preventDefault && nativeEvent.preventDefault();
      nativeEvent && nativeEvent.stopPropagation && nativeEvent.stopPropagation();
    }
  }

  onDrop = (info) => {
    const { data: originalData, expandedKeys } = this.state;
    const {
      onShowButtonsSaveCancel,
      onChangeTreeData,
      dragTreeValueItem,
      onDropTreeValueItemIntoNewTreeItem,
      onClearDragTreeValue,
    } = this.props;
    const { node: { props } } = info;

    const dropKey = props && props.eventKey;
    const newExpandedKeys = expandedKeys;

    if (!dropKey || dropKey === '0') {
      return false;
    }

    if (info.dragNode && info.dragNode.props && info.dragNode.props.eventKey) {
      const dragKey = info.dragNode.props.eventKey;
      const dropPos = info.node.props.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      if (!newExpandedKeys.includes(dropKey)) {
        newExpandedKeys.push(dropKey);
      }

      const data = cloneDeep(originalData);

      // Find and delete dragObject
      let dragObj = null;
      this.loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (dragObj && 'parent' in dragObj) {
        dragObj.parent = dropKey === '0' ? null : dropKey;
      }

      if (!info.dropToGap) {
        if (dropKey === '0') {
          data.push(dragObj);
        } else {
          // Drop on the content
          this.loop(data, dropKey, (item) => {
            // eslint-disable-next-line no-param-reassign
            item.children = item.children || [];
            // where to insert
            item.children.push(dragObj);
          });
        }
      } else if (
        (info.node.props.children || []).length > 0 // Has children
        && info.node.props.expanded // Is expanded
        && dropPosition === 1 // On the bottom gap
      ) {
        this.loop(data, dropKey, (item) => {
          // eslint-disable-next-line no-param-reassign
          item.children = item.children || [];
          // where to insert
          item.children.unshift(dragObj);
        });
      } else {
        let ar = [];
        let i = 0;
        this.loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dragObj && 'parent' in dragObj && ar[i] && 'parent' in ar[i]) {
          dragObj.parent = ar[i].parent;
        }
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.setState({
        data,
      });
      onShowButtonsSaveCancel(true);
      onChangeTreeData(data, 'edit', dragObj);
    } else if (dragTreeValueItem && dragTreeValueItem.id) {
      // Search for Drop object
      let dropObject = {};
      this.loop(originalData, dropKey, (item) => {
        dropObject = item;
      });

      if (dropObject && dropObject.id) {
        onDropTreeValueItemIntoNewTreeItem(dragTreeValueItem, dropObject);
      }
      onShowButtonsSaveCancel(true);
      onClearDragTreeValue();
    }
  };

  render() {
    const {
      isLoading,
      isCanEdit,
      isCanDragAndDrop,
      isShowButtonsPanel,
      enableStandardButtons,
      visibleStandardButtons,
      extraButtons,
      isRootTreeItem,
      isEditTreeValueItem,
      t,
      rights
    } = this.props;
    const {
      selectedKeys,
      editedKey,
      expandedKeys,
      data,
    } = this.state;

    const isSelectedRoot = !selectedKeys
      || selectedKeys.length === 0
      || (selectedKeys.length === 1 && selectedKeys[0] === '0');

    const enableAddButton = !enableStandardButtons || enableStandardButtons.indexOf('add') !== -1;
    const enableDeleteButton = !enableStandardButtons || enableStandardButtons.indexOf('delete') !== -1;

    const visibleAddButton = !visibleStandardButtons || visibleStandardButtons.indexOf('add') !== -1;
    const visibleDeleteButton = !visibleStandardButtons || visibleStandardButtons.indexOf('delete') !== -1;

    const children = this.renderTreeElement(data);
    const treeData = isRootTreeItem
      ? [{
        title: t('TREE_ROOT_GROUP_NAME') + (data && data.length > 0 ? ` (${data.length})` : ''),
        key: '0',
        isDragDisabled: true,
        children: children,
        draggable: false,
        dragging: false,
        isCanDragAndDrop: false,
      }]
      : children;

    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    return (
      <div className={styles.templateTree}>
        <div className={`${styles.top} ${!isShowButtonsPanel && 'hidden'}`}>
          <div className={styles.buttonAdd}>
            <Button
              size='small'
              icon={<ApartmentOutlined />}
              className={visibleAddButton ? '' : 'hidden'}
              onClick={(event) => this.onAddItemTemplate(this, event)}
              disabled={!isCanEdit || editedKey || isEditTreeValueItem || !enableAddButton || isReadOnly}
            >
              {t('TREE_ADD_TREE_ITEM')}
            </Button>
          </div>
          <div className={styles.buttonDelete}>
            {
              !isCanEdit || isEditTreeValueItem || editedKey || isSelectedRoot
              || !enableDeleteButton || !visibleDeleteButton || isReadOnly
                ? (
                  <Button
                    size='small'
                    icon={<DeleteOutlined />}
                    disabled={true}
                    className={'disabled-button'.concat(visibleDeleteButton ? '' : ' hidden')}
                  >
                    {t('TREE_DELETE_TREE_ITEM')}
                  </Button>
                )
                : (
                  <Popconfirm
                    title={t('TREE_DELETE_CONFIRM')}
                    onConfirm={(event) => this.onDeleteItemTemplate(this, event)}
                    okText={t('TREE_YES')}
                    cancelText={t('TREE_NO')}
                  >
                    <Button size='small' icon={<DeleteOutlined />}>
                      {t('TREE_DELETE_TREE_ITEM')}
                    </Button>
                  </Popconfirm>
                )
            }
          </div>
          {extraButtons ? extraButtons() : ''}
        </div>
        <div className={styles.body}>
          <Spin spinning={!!isLoading}>
            <Tree
              treeData={treeData}
              onExpand={this.onExpand}
              onSelect={this.onSelect}
              defaultExpandAll={true}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              showIcon={true}
              onDoubleClick={
                (event, node) => {
                  return (
                    isCanEdit && !isEditTreeValueItem
                      ? this.onDblClick(event, node, this)
                      : false
                  );
                }
              }
              icon={this.getIcon}
              draggable={isCanEdit && isCanDragAndDrop && !isReadOnly}
              blockNode={true}
              onDragStart={this.onDragStart}
              onDragOver={(event) => this.onDragOverHandle('onDragOver', event)}
              onDrop={isCanEdit && isCanDragAndDrop && !isEditTreeValueItem ? this.onDrop : null}
            />
          </Spin>
        </div>
      </div>
    );
  }
}

const onDropTreeValueItemIntoNewTreeItem = (dragObject, dropObject, onChangeTreeValueDataFunction, component) => {
  if (dragObject && dragObject.id && dropObject && dropObject.id && dropObject.name) {
    let newItem = null;
    const newTreeValueData = component.state.treeValueData.map((item) => {
      if (item.id === dragObject.id) {
        newItem = { ...item, category: { id: dropObject.id, name: dropObject.name }, parent: dropObject.id };
        return newItem;
      }
      return item;
    });
    if (newItem && typeof onChangeTreeValueDataFunction === 'function') {
      onChangeTreeValueDataFunction(newTreeValueData, 'edit', newItem, component);
    }
    component.setState({
      treeValueData: newTreeValueData,
    });
  }
};

const onChangeTreeData = (newTreeData, operation, itemForOperation, component) => {
  if (JSON.stringify(newTreeData) !== JSON.stringify(component.state.treeData)) {
    component.setState({
      treeData: newTreeData,
    });
  }
  component.setState({
    isEditTreeItem: false,
  });
};

const onSelectTreeData = (selectedTreeItem, component) => {
  component.setState({
    selectedTreeItem,
  });
};

const onViewTree = (component, isShow = true) => {
  component.setState({
    isViewTree: isShow,
  });
};

const mapStateToProps = state => ({
  rights: state.rights.rightsData
});

export {
  onDropTreeValueItemIntoNewTreeItem,
  onChangeTreeData,
  onSelectTreeData,
  onViewTree,
};

export default connect(mapStateToProps)(TemplateTree);