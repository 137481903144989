import React from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Upload, Radio, TreeSelect,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { validateFile } from '@globalHelpers';
import { SelectInfiniteScroll } from '@ui';

export const allowedExtensions = ['JSON'];

class BlanksForm extends React.Component {
  form = React.createRef();

  constructor(props) {
    super(props);
    this.onSelectSearch = debounce(props.setSearchValue, 800);

    this.state = {
      fileList: [],
      disableSelect: false,
      treeExpandedKeys: [],
    };
  }

  getLastFile = (e) => {
    return e && e.fileList[e.fileList.length - 1];
  };

  validateFileExt = async (rule, value) => {
    const { t, settings } = this.props;

    validateFile(t, value, allowedExtensions, settings.MAX_UPLOAD_FILE_SIZE);
  };

  changeLoadType = (e) => {
    this.form.current.resetFields(['template']);
    this.setState({
      disableSelect: e.target.value === 3,
    });
  };

  onTreeExpandHandle = (keys) => {
    this.setState({
      treeExpandedKeys: keys,
    });
  };

  resetSelectValue = () => {
    this.onSelectSearch('');
    this.onTreeExpandHandle([]);
  };

  render() {
    const {
      fields, fileList, disableSelect, treeExpandedKeys,
    } = this.state;
    const {
      isUploading, templates, t, isTemplatesLoading, loadMoreTemplates, type,
    } = this.props;
    const propsPrime = {
      onRemove: () => {
        this.setState({ fileList: [] });
      },
      beforeUpload: (file) => {
        this.setState({ fileList: [file] });
        return false;
      },
      fileList,
    };

    return (
      <Form ref={this.form} name='BlankModalForm' initialValues={{ loadType: 1 }} {...fields}>
        <Form.Item
          name='file'
          getValueFromEvent={this.getLastFile}
          rules={[{ validator: this.validateFileExt }]}
          label={t('PASSPORT_SETPOINTS_UPLOADED_FILE')}
        >
          <Upload {...propsPrime} disabled={isUploading}>
            <Button size='small'>
              <UploadOutlined />
              {t('PASSPORT_SETPOINTS_UPLOAD')}
            </Button>
          </Upload>
        </Form.Item>
        {
          type === 'blank'
            ? (
              <>
                <Form.Item name='loadType'>
                  <Radio.Group onChange={(e) => this.changeLoadType(e)}>
                    <Radio id='1' value={1}>
                      {t('PASSPORT_SETPOINTS_WITH_TEMPLATE')}
                    </Radio>
                    <Radio id='2' value={2}>
                      {t('PASSPORT_SETPOINTS_AUTO_TEMPLATE')}
                    </Radio>
                    <Radio id='3' value={3}>
                      {t('PASSPORT_SETPOINTS_EXISTED_TEMPLATE')}
                      :
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  name='template'
                  dependencies={['loadType']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue('loadType') === 3, message: t('PASSPORT_SETPOINTS_REQUIRED'),
                    }),
                  ]}
                >
                  <SelectInfiniteScroll
                    style={{ width: '100%' }}
                    treeMode
                    getPopupContainer={(triggerNode) => triggerNode}
                    placeholder={t('PASSPORT_SETPOINTS_CHOOSE_TEMPLATE')}
                    loading={isTemplatesLoading}
                    loadMoreHandle={loadMoreTemplates}
                    allowClear
                    filterOption={false}
                    disabled={!disableSelect}
                    showSearch
                    onSearch={(value) => this.onSelectSearch(value)}
                    onBlur={this.resetSelectValue}
                    treeExpandedKeys={treeExpandedKeys}
                    onTreeExpand={(expandedKeys) => this.onTreeExpandHandle(expandedKeys)}
                    onChange={(value) => !value && this.resetSelectValue()}
                    popoverPlacement='left'
                  >
                    {templates.map((item) => (
                      <TreeSelect.TreeNode key={item.id} id={item.id} value={item.id} title={`${item.name} (v. ${item.dbrza_version})`}>
                        {item.children && item.children.map((child) => (
                          <TreeSelect.TreeNode key={child.id} id={child.id} value={child.id} title={`${child.name} (v. ${child.dbrza_version})`} />
                        ))}
                      </TreeSelect.TreeNode>
                    ))}
                  </SelectInfiniteScroll>
                </Form.Item>
              </>
            )
            : ''
        }
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings.data,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withTranslation('translation', { withRef: true })(
    BlanksForm
  )
);
