/* eslint-disable no-param-reassign */
import * as React from 'react';
import {
  Button, Popconfirm, Table,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import { TABLE_SIZE } from '@app/constants';
import { getId } from '@app/helpers';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { onAddInnerTableRow, onDeleteInnerTableRow } from '@common/treeValues/renderFields';

const checkDefaultValueIntoSelector = (defaultValue, selector) => {
  let diff = [];
  const selectorArray = [...selector].map((x) => x.value);
  const isMultiple = defaultValue && Array.isArray(defaultValue);
  if (Array.isArray(defaultValue)) {
    diff = defaultValue.filter((x) => !selectorArray.includes(x));
  }
  return (
    typeof defaultValue === 'undefined'
    || defaultValue === null
    || (!isMultiple && selectorArray.indexOf(defaultValue) !== -1)
    || (isMultiple && diff && diff.length === 0)
  );
};

const renderSelector = (component) => {
  const { t } = component.props;
  const { editItem } = component.state;
  const editSelector = editItem.selector;
  if (
    editItem
    && editItem.setpoint_type
    && ['selector', 'multiple_selector'].indexOf(editItem.setpoint_type.name) !== -1
  ) {
    const columns = [
      {
        dataIndex: 'value',
        className: 'template-tree-value__selector_value',
        key: 'selector_value',
        render: (text, record, index) => (
          <input
            key='selector_input-min_value'
            size='small'
            // defaultValue={text || localeMessage("WITHOUT_NAME"}
            defaultValue={text}
            placeholder={t('TREE_NEW_TREE_ITEM_VALUE')}
            onChange={(e) => {
              const newValue = editSelector && editSelector.map((el, elIndex) => {
                if (elIndex === index) {
                  // eslint-disable-next-line no-param-reassign
                  el.value = e.target.value;
                }
                return el;
              });
              component.setState({
                editItem: {
                  ...editItem,
                  selector: newValue,
                },
              });
            }}
          />
        ),
      },
      {
        className: 'template-tree-value__selector-fix',
        key: 'selector-fix',
        render: (text, record, index) => (
          <div>
            <Button
              size='small'
              icon={<PlusOutlined />}
              onClick={() => onAddInnerTableRow(
                component,
                index,
                editSelector || [],
                'selector',
                { key: getId(10), value: '' }
              )}
            />
            {editSelector && editSelector.length && editSelector.length > 1 ? (
              <Popconfirm
                title={t('DELETE_ROW_CONFIRM')}
                onConfirm={() => onDeleteInnerTableRow(component, index, editSelector, 'selector')}
                okText={t('YES')}
                cancelText={t('NO')}
                disabled={index === 0
                && record.min_value === null
                && record.max_value === null && record.step === null}
              >
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  className='disabled-button'
                  disabled={
                    index === 0 && record.min_value === null && record.max_value === null && record.step === null
                  }
                />
              </Popconfirm>
            ) : (
              ''
            )}
          </div>
        ),
      },
    ];

    return (
      <Table
        size={TABLE_SIZE}
        key='table_edit-selector_values'
        className='edit-selector_values'
        columns={columns}
        dataSource={editSelector}
        rowKey='key'
        showHeader={false}
        bordered={false}
        pagination={false}
      />
    );
  } else {
    return t('TREE_VALUES_WITHOUT_VALUE');
  }
};

const getSelectorForEdit = (item) => {
  let selected;
  if (item) {
    const newItem = cloneDeep(item);
    selected = newItem.map((el) => {
      return { key: getId(10), value: el };
    });
  } else {
    selected = [{ key: getId(10), value: '' }];
  }
  return selected;
};

export {
  checkDefaultValueIntoSelector,
  renderSelector,
  getSelectorForEdit,
};
