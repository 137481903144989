import React, { Component } from 'react';
import moment from 'moment';
import { Spin } from 'antd';

import api from '@services/api';

import { notificationText } from '@globalHelpers';

import { CustomEmpty } from '@ui';

import NotificationCard from './notificationCard';

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      currentNotification: null,
    };
  }

  componentDidMount = async () => {
    this.setState({ isNotificationsLoading: true });
    await this.loadNotifications();
    this.setState({ isNotificationsLoading: false });
  };

  componentDidUpdate = async (prevProps) => {
    const { id, toggleHeaderMode } = this.props;
    const { currentNotification } = this.state;

    if (prevProps.id !== id) {
      this.setState({ isNotificationsLoading: true, currentNotification: null });
      currentNotification && toggleHeaderMode();
      await this.loadNotifications();
      this.setState({ isNotificationsLoading: false });
    }
  };

  loadNotifications = async () => {
    const { id, t } = this.props;

    const { status, data } = await api.getNotifications({ task_dc_setpoint_value__id: id, is_history: true });

    const notifications = data.results.map((note) => {
      const notification = notificationText(note);

      return {
        id: note.id,
        title: '',
        notification,
        changed_date: moment(note.changed_date)
          .locale('ru')
          .format(`LL ${t('IN')} HH:mm`),
        notes_number: note.notes_number,
      };
    });

    if (status === 200) {
      this.setState({ notifications });
    }
  };

  openThread = (item) => {
    const { toggleHeaderMode } = this.props;

    toggleHeaderMode(item);

    this.setState({ currentNotification: item });
  };

  closeThread = () => {
    this.setState({ currentNotification: null });
  };

  render() {
    const { notifications, isNotificationsLoading } = this.state;

    return (
      <Spin spinning={isNotificationsLoading}>
        {notifications
          && notifications.map((item) => (
            <NotificationCard key={item.id} onClick={() => this.openThread(item)} {...item} />
          ))}
        {(!notifications || !notifications.length) && <CustomEmpty />}
      </Spin>
    );
  }
}

export default Notifications;
