import { getId } from '@globalHelpers';

export class ActionsForTree {
  newTreeItem = (parent) => {
    return {
      id: getId(5),
      name: null,
      parent,
      children: [],
      isNewItem: true,
    };
  };
}
