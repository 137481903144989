import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Button, message, Popconfirm } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import api from '@services/api';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { EQUIPMENT_TYPES_FORM } from '@common/sidebarRoot/types';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { TABLE_SIZE, RIGHT_ROUTE_CLASSIFIERS, MODE_EDIT } from '@globalConstants';
import { textHighlighter } from '@globalHelpers';
import { CustomCard } from '@ui';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import styles from './styles.module.less';

class EquipmentTypes extends Component {
  tableName = 'classificators_tab__equipment_types';

  state = {
    count: 0,
    data: [],
    sidebarTypeMode: '',
    isDeleting: false,
  };

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName, () => this.loadData());
  }

  componentDidUpdate(prevProps) {
    const {
      selectedItem, isSidebarOpen, archived, changePage,
    } = this.props;
    const { sidebarTypeMode } = this.state;

    if (prevProps.selectedItem !== selectedItem && isSidebarOpen) {
      this.openForm(sidebarTypeMode);
    }

    if (prevProps.archived !== archived) {
      changePage(1, () => this.loadData());
    }
  }

  componentWillUnmount() {
    const { isSidebarOpen, closeFormSidebar } = this.props;

    if (isSidebarOpen) closeFormSidebar();
  }

  loadData = async (addParams = {}, callback) => {
    const { loadByPage, filterValues } = this.props;
    const requestParams = {
      ...filterValues,
      ...addParams,
    };

    this.setState({ isLoading: true });
    await loadByPage(this.tableName, 'getEquipmentTypes', requestParams, 200, (status, data) => {
      this.setState({
        data: data.results.filter((x) => x.id !== api.getNullUuid()),
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isLoading: false });
  };

  openForm = async (isEditMode = false) => {
    const {
      isSidebarOpen, openFormSidebar, closeFormSidebar, selectedItem, changePage, onSelectItem, t,
    } = this.props;
    const { data, sidebarTypeMode } = this.state;
    let item;

    if (isSidebarOpen && sidebarTypeMode === isEditMode) {
      await closeFormSidebar();
      this.setState({ sidebarTypeMode: '' });
    }

    let method = 'addEquipmentType';

    if (isEditMode) {
      item = data.find((x) => x.id === selectedItem.id);
      method = 'updateEquipmentType';
    }

    openFormSidebar(
      EQUIPMENT_TYPES_FORM,
      {
        item,
        method,
        label: t('CLASSIFICATORS_EQUIPMENT_TYPES_TITLE'),
        callback: async (response) => {
          closeFormSidebar();

          changePage(1, async () => {
            await this.loadData({ selected: response.id }, () => {
              const { data: updatedData } = this.state;
              onSelectItem(updatedData[0]);
            });
          });
        },
      }
    );

    this.setState({ sidebarTypeMode: isEditMode });
  };

  deleteItem = async () => {
    const { t, selectedItem, onSelectItem } = this.props;

    if (!selectedItem.id) {
      return;
    }

    this.setState({ isDeleting: true });
    const { status } = await api.deleteEquipmentType(selectedItem.id);
    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));
      this.loadData();
      onSelectItem({});
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      t, changePage, onShowSizeChange, page, limit, getColumnSearchFilter, filterValues,
      onSelectItem, selectedItem, rights,
    } = this.props;
    const {
      isLoading, isDeleting, data, count,
    } = this.state;
    const isEditRight = rights[RIGHT_ROUTE_CLASSIFIERS] === MODE_EDIT;

    const columns = [
      {
        title: t('CLASSIFICATORS_EQUIPMENT_TYPES_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        minWidth: 150,
        ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
        render: (text) => (text ? textHighlighter(filterValues.name, text) : ''),
      },
      {
        title: t('CLASSIFICATORS_EQUIPMENT_TYPES_EQUIPMENT'),
        dataIndex: 'name_en',
        key: 'name_en',
        width: '50%',
        minWidth: 150,
        ...getColumnSearchFilter('name_en', 'name_en', null, this.handleResetCallback),
        render: (text) => (text ? textHighlighter(filterValues.name_en, text) : ''),
      },
    ];

    return (
      <CustomCard
        className={styles.complexesList}
        extra={(
          <>
            <Button
              onClick={() => this.openForm()}
              className='control-button'
              size='small'
              icon={<PlusCircleOutlined />}
              disabled={!isEditRight}
            >
              {t('CLASSIFICATORS_EQUIPMENT_TYPES_ADD_TYPE')}
            </Button>
            <Button
              onClick={() => this.openForm(true)}
              disabled={!selectedItem.id || !isEditRight}
              className='control-button'
              size='small'
              icon={<EditOutlined />}
            >
              {t('CLASSIFICATORS_EQUIPMENT_TYPES_EDIT_TYPE')}
            </Button>
            <Popconfirm
              title={t('CLASSIFICATORS_EQUIPMENT_TYPES_CONFIRM_DELETE')}
              okText={t('DELETE')}
              cancelText={t('CANCEL')}
              onConfirm={this.deleteItem}
              placement='topRight'
              disabled={!isEditRight || !selectedItem.id || !selectedItem.is_can_delete}
            >
              <Button
                disabled={!selectedItem.id || isDeleting || !isEditRight || !selectedItem.is_can_delete}
                loading={isDeleting}
                className='control-button'
                size='small'
                icon={<DeleteOutlined />}
              >
                {t('DELETE')}
              </Button>
            </Popconfirm>
          </>
        )}
      >
        <FixedYCommonTable
          tableName={this.tableName}
          size={TABLE_SIZE}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={{
            current: page,
            onChange: (newPage) => changePage(newPage, this.loadData),
            total: count,
            pageSize: limit,
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedItem.id],
            onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0]),
          }}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadData)
          }
        />
        <ExportBottom
          count={count}
          className='control-button'
          size='small'
          WSData={{
            url: '/v1/equipment-base-types/tree',
            accept: 'application/xlsx',
          }}
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isOpen,
  rights: state.rights.rightsData,
  archived: state.archived,
});
const mapDispatchToProps = {
  openFormSidebar: openSidebar,
  closeFormSidebar: closeSidebar,
};
const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      EquipmentTypes
    )
  )
);
