import React, { ReactChild } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { CloseOutlined } from '@ant-design/icons';
import { closeSidebar } from '@state/sidebar/actions';

import styles from './styles.module.less';

interface Props {
  className?: string;
  children?: ReactChild;
  header?: ReactChild;
  footer?: ReactChild;
  onClose?: () => {};
  withoutCard?: boolean;
  noBounds?: boolean;
  contentStyle?: {};
  sidebarStyle?: {};
}

const SidebarLayout = (props: Props) => {
  const {
    header, footer, children, onClose, withoutCard, className, contentStyle, sidebarStyle, noBounds
  } = props;

  return (
    <div
      className={`${styles.wrapper} ${className || ''}`}
      style={sidebarStyle}
    >
      <div className={styles.header}>
        {header}
        <CloseOutlined onClick={onClose} />
      </div>
      <div
        className={`${styles.contentWrapper} ${!footer ? styles.contentWrapperFull : ''} ${noBounds ? styles.noBounds : ''}`}
        style={contentStyle || {}}
      >
        <Scrollbars autoHide style={{ width: '100%' }}>
          <div className={withoutCard ? styles.withoutCard : styles.content}>{children}</div>
        </Scrollbars>
      </div>
      {footer && (<div className={styles.footer}>{footer}</div>)}
    </div>
  );
};

const mapDispatchToProps = {
  onClose: closeSidebar,
};

export default connect(null, mapDispatchToProps)(
  SidebarLayout
);
