import { ToolOutlined } from '@ant-design/icons';
import api from '@services/api';
import { Maintenance } from '@services/api-dts';
import { setMaintenanceAlertData } from '@state/maintenanceAlert/actions';
import { maintenanceAlertActionTypes } from '@state/maintenanceAlert/types';
import { RootState } from '@state/store';
import { Button, Tooltip } from 'antd';
import React, { Dispatch, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { MAINTENANCE_UNREAD_CHECK_INTERVAL } from './constants';
import MaintenanceModalForm from './maintenanceModalForm';

export interface IMaintenanceButton extends WithTranslation {
  className?: string;
}

type MaintenanceButtonProps = ConnectedProps<typeof connector>;

const MaintenanceButton: FC<MaintenanceButtonProps> = ({ className = '', rights, setAlertData, t }) => {
  const [maintenance, setMaintenance] = useState<Maintenance | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const openAlert = useCallback((data: Maintenance) => {
    setAlertData(data.content || '', true);
  }, [setAlertData]);

  const isEditingAllowed = useMemo(() => rights.RIGHT_MAINTENANCE === 'edit', [rights]);

  const onButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    (event.currentTarget as HTMLButtonElement).blur();
    if (isEditingAllowed) {
      openModal();
    }
  }, [isEditingAllowed]);

  const onSubmit = (result: Maintenance) => {
    setMaintenance(result);
  };

  const checkMaintenance = useCallback(async () => {
    const { status, data } = await api.getMaintenance();
    if (status === 200) setMaintenance(data);

    if (data) {
      if (data.reading_status && data.is_published) {
        openAlert(data);
      }
    }
  }, [openAlert]);

  useEffect(() => {
    checkMaintenance();

    const interval = setInterval(() => {
      checkMaintenance();
    }, MAINTENANCE_UNREAD_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [checkMaintenance]);

  const tooltip = useMemo(
    () => (
      <Tooltip title={maintenance?.is_published ? maintenance?.content : t('MAINTENANCE')}>
        <Button
          icon={<ToolOutlined />}
          type={maintenance?.is_published ? 'primary' : 'default'}
          onClick={(e) => onButtonClick(e)}
        />
      </Tooltip>
    ),
    [maintenance, onButtonClick, t]
  );

  return (
    <div className={className}>
      {tooltip}
      <MaintenanceModalForm
        visible={isModalVisible}
        maintenance={maintenance}
        cancelCallback={closeModal}
        submitCallback={onSubmit}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState, props: IMaintenanceButton) => ({
  rights: state.rights.rightsData,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<maintenanceAlertActionTypes>) => ({
  setAlertData: (content: string, visible: boolean) => dispatch(setMaintenanceAlertData(content, visible)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MaintenanceButton));
