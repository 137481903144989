import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Spin, Button, List, Checkbox,
} from 'antd';

import api from '@services/api';

import { SidebarLayout, SidebarCard } from '@ui';

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDocs: [],
      documents: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { data: { passportId, documentsId } } = this.props;

    this.setState({ isLoading: true });
    const { status, data } = await api.getPassportDocs({ passport: passportId });
    this.setState({ isLoading: false });

    if (status === 200) {
      this.setState({
        documents: (
          data
            .results
            .filter((doc) => !documentsId.includes(doc.id))
            .filter((doc) => Object.keys(doc.meta_tags).length === 0)
        ),
      });
    }
  };

  bindDocuments = async () => {
    const {
      data: {
        passportId, documentsId, taskId, callback,
      },
    } = this.props;
    const { selectedDocs } = this.state;

    this.setState({ isSending: true });
    const { status } = await api.patchTaskDCV2(taskId, {
      documents: [...selectedDocs, ...documentsId],
      passport: passportId,
    });
    this.setState({ isSending: false });

    if (status === 200) {
      callback && callback();
    }
  };

  onValueChange = (e, id) => {
    const { selectedDocs } = this.state;
    const { target: { checked } } = e;
    const selectedDocsChanged = [...selectedDocs];

    if (checked && !selectedDocsChanged.includes(id)) {
      selectedDocsChanged.push(id);
    }

    if (!checked && selectedDocsChanged.includes(id)) {
      const index = selectedDocsChanged.indexOf(id);
      selectedDocsChanged.splice(index, 1);
    }

    this.setState({
      selectedDocs: selectedDocsChanged,
    });
  };

  render() {
    const { t } = this.props;
    const {
      documents, isLoading, isSending,
    } = this.state;

    return (
      <SidebarLayout
        withoutCard
        noBounds
        header={(
          <h4>
            {t('ADD_DOCUMENT')}
          </h4>
        )}
        footer={(
          <Button
            block
            type='primary'
            onClick={this.bindDocuments}
            disabled={isLoading || isSending || !documents || !documents.length}
            loading={isSending}
          >
            {t('SAVE')}
          </Button>
        )}
      >
        <Spin spinning={isLoading}>
          <SidebarCard>
            <SidebarCard.content>
              <List
                locale={{
                  emptyText: t('NO_FREE_ATTACH_DATA'),
                }}
                dataSource={documents}
                renderItem={(element) => (
                  <List.Item>
                    <Checkbox
                      key={`document_${element.id}`}
                      onChange={(e) => this.onValueChange(e, element.id)}
                    >
                      {element.name}
                    </Checkbox>
                  </List.Item>
                )}
              />
            </SidebarCard.content>
          </SidebarCard>
        </Spin>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  Documents
);
