import React, { FC, memo, ReactNode } from 'react';
import { Badge, Button } from 'antd';
import { ButtonType } from 'antd/lib/button';

export interface IBadgeButton {
  badge?: { color?: string; showZero?: boolean };
  type?: ButtonType;
  label: string;
  icon?: ReactNode;
  count?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const defaultBadge = {
  color: undefined,
  showZero: false,
};

export const BadgeButton: FC<IBadgeButton> = memo(
  ({ badge = defaultBadge, type = 'default', label, icon, count = 0, onClick }) => {
    return (
      <Badge count={count || 0} color={badge.color} showZero={badge.showZero} offset={[3, 3]} size='small'>
        <Button type={type} onClick={onClick}>
          {label}
        </Button>
      </Badge>
    );
  }
);