import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import api from '@services/api';

import { Button, message, Popconfirm } from 'antd';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { EVENTS_FORM } from '@common/sidebarRoot/types';
import { RIGHT_ROUTE_EVENTS, MODE_EDIT } from '@globalConstants';
import { RootState } from '@state/store';
import { InterfaceRightsConstants } from '@state/rights/types';

interface Props extends WithTranslation {
  isSidebarOpen: boolean;
  closeEventsForm: Function;
  selectedEvent: string | boolean;
  oSelectedEvent: {} | boolean;
  loadEvents: Function;
  clearFilter: Function;
  rights: InterfaceRightsConstants;
  openEventsForm: (type: string, data: {
    mainCallback: Function;
    eventItem: {} | boolean;
    selectedEvent: string | boolean;
    isEditMode: boolean;
  }) => void;
}

interface State {
  sidebarType: boolean;
  isDeleting: boolean;
}

class EventsRoute extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sidebarType: false,
      isDeleting: false,
    };
  }

  openEventForm = (isEditMode = false) => {
    const {
      isSidebarOpen, openEventsForm, closeEventsForm, selectedEvent, oSelectedEvent, loadEvents, clearFilter,
    } = this.props;
    const { sidebarType } = this.state;

    if (isSidebarOpen && sidebarType === isEditMode) {
      closeEventsForm();
      this.setState({ sidebarType: false });

      return;
    }

    openEventsForm(
      EVENTS_FORM,
      {
        eventItem: isEditMode ? oSelectedEvent : false,
        selectedEvent: isEditMode ? selectedEvent : false,
        isEditMode,
        mainCallback: async (eventId: string) => {
          await clearFilter();
          return loadEvents(eventId);
        },
      }
    );

    this.setState({ sidebarType: isEditMode });
  };

  deleteEvent = async () => {
    const {
      t, loadEvents, selectedEvent, clearFilter,
    } = this.props;

    if (!selectedEvent) {
      return;
    }

    this.setState({ isDeleting: true });
    const { status } = await api.deleteEvents(selectedEvent);
    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));
      await clearFilter();
      loadEvents();
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  render() {
    const { selectedEvent, t, rights } = this.props;
    const { isDeleting } = this.state;

    const isEditRight = rights[RIGHT_ROUTE_EVENTS] === MODE_EDIT;

    return (
      <>
        <Button
          onClick={() => this.openEventForm()}
          className='control-button'
          size='small'
          icon={<PlusCircleOutlined />}
          disabled={isDeleting || !isEditRight}
        >
          {t('EVENTS_ADD')}
        </Button>
        <Button
          onClick={() => this.openEventForm(true)}
          disabled={!selectedEvent || isDeleting || !isEditRight}
          className='control-button'
          size='small'
          icon={<EditOutlined />}
        >
          {t('EVENTS_EDIT')}
        </Button>
        <Popconfirm
          title={`${t('DELETE_EVENT')}?`}
          okText={t('DELETE')}
          cancelText={t('CANCEL')}
          onConfirm={this.deleteEvent}
          disabled={!selectedEvent || isDeleting || !isEditRight}
        >
          <Button
            disabled={!selectedEvent || isDeleting || !isEditRight}
            loading={isDeleting}
            className='control-button'
            size='small'
            icon={<DeleteOutlined />}
          >
            {t('DELETE_EVENT')}
          </Button>
        </Popconfirm>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isSidebarOpen: state.sidebar.isOpen,
  rights: state.rights.rightsData,
});

const mapDispatchToProps = {
  openEventsForm: openSidebar,
  closeEventsForm: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    EventsRoute
  )
);
