import React, { FC, memo, useMemo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { CloseCircleTwoTone, CloudTwoTone, SyncOutlined } from '@ant-design/icons';
import DetailsPopover from '@ui/detailsPopover';
import { DocumentTrayItemType } from '.';
import styles from './styles.module.less';

type DocumentsTrayDownloadAreaProps = WithTranslation & {
  onDocumentDownloadClick: () => void;
  document: DocumentTrayItemType;
};

const DocumentTrayDownloadArea: FC<DocumentsTrayDownloadAreaProps> = memo(({ document, onDocumentDownloadClick, t }) => {
  const { error, url } = document;

  const errorArea = useMemo(
    () => (
      <Space>
        <CloseCircleTwoTone twoToneColor='red' />
        <Text type='danger' className={styles.documentationTrayCaption}>
          {t('DOWNLOAD_LINK_ERROR')}
        </Text>
        <DetailsPopover content={error} maxWidth={500} />
      </Space>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error]
  );

  const loadingArea = useMemo(() => (
    <Space>
        <SyncOutlined spin style={{ color: '#1890ff' }}/>
        <Text className={styles.documentationTrayCaption}>
            {t('DOWNLOAD_LINK_IS_NOT_READY')}
        </Text>
    </Space>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [])

  const linkArea = useMemo(
    () => (
      <Space>
        <CloudTwoTone />
        <Button type='link' onClick={onDocumentDownloadClick}>
          {t('DOWNLOAD_LINK_IS_READY')}
        </Button>
      </Space>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  if (error) return errorArea;
  if (url) return linkArea;
  return loadingArea;
});

export default withTranslation()(DocumentTrayDownloadArea);
