import { Button, Popover } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { FC, memo, useMemo, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

type DetailsPopoverProps = WithTranslation & {
  content?: string;
  label?: string;
  title?: string;
  minWidth?: number;
  maxWidth?: number;
};

const DetailsPopover: FC<DetailsPopoverProps> = memo(({ content, label, title, minWidth = 400, maxWidth = 1200, t }) => {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const popoverContent = useMemo(
    () => (
      <>
        <Paragraph
          style={{ minWidth: minWidth, maxWidth: maxWidth }}
          title={title}
          copyable={{
            tooltips: ['Скопировать', 'Скопировано в буфер обмена'],
          }}
        >
          {content}
        </Paragraph>
        <Button type='link' onClick={hide}>
          {t('DETAILS_POPOVER_CLOSE_CAPTION')}
        </Button>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content]
  );

  return (
    <Popover
      content={popoverContent}
      trigger='click'
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement='bottomRight'
    >
      <Button type='link'>{label || t('DETAILS_POPOVER_CAPTION')}</Button>
    </Popover>
  );
});

export default withTranslation()(DetailsPopover);
