import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Spin, Button,
} from 'antd';

import api from '@services/api';

import {
  SidebarLayout, SidebarCard, PopoverEllipsis,
} from '@ui';

import styles from './styles.module.less';
import type { Data, DataInfo, Item } from './types';

interface Props extends WithTranslation {
  info: { dispatcher_center: Item };
  data: {
    selectedTask: Data;
  };
}

interface State {
  taskInfo?: DataInfo;
  isLoading?: boolean;
}

class Info extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    const { data: { selectedTask } } = this.props;

    if (selectedTask.id !== prevProps.data.selectedTask.id) {
      this.loadData();
    }
  }

  loadData = async () => {
    const {
      data: { selectedTask },
    } = this.props;

    this.setState({ isLoading: true });
    const { status, data } = await api.getTaskDCV2(selectedTask.id);
    this.setState({ isLoading: false });

    if (status === 200) {
      this.setState({
        taskInfo: data,
      });
    }
  };

  render() {
    const {
      t, data: { selectedTask },
    } = this.props;
    const {
      isLoading, taskInfo,
    } = this.state;

    return (
      <SidebarLayout
        withoutCard
        header={(
          <h4>
            {t('TASK_DC_INFO')}
          </h4>
        )}
      >
        <Spin spinning={isLoading}>
          <SidebarCard>
            <SidebarCard.content>
              <SidebarCard.parameter label={t('DC_TASK_NAME')}>
                {taskInfo?.name}
              </SidebarCard.parameter>
              <SidebarCard.parameter label={t('REASON')}>
                {taskInfo?.reason}
              </SidebarCard.parameter>
              {taskInfo?.date_planned_start && (
                <SidebarCard.parameter label={t('START_DATE_PLANNED')}>
                  {taskInfo?.date_planned_start}
                </SidebarCard.parameter>
              )}
              {taskInfo?.date_actual_start && (
                <SidebarCard.parameter label={t('START_DATE_ACTUAL')}>
                  {taskInfo?.date_actual_start}
                </SidebarCard.parameter>
              )}
              {taskInfo?.date_planned_completion && (
                <SidebarCard.parameter label={t('END_DATE_PLANNED')}>
                  {taskInfo?.date_planned_completion}
                </SidebarCard.parameter>
              )}
              {taskInfo?.date_actual_completion && (
                <SidebarCard.parameter label={t('END_DATE_ACTUAL')}>
                  {taskInfo?.date_actual_completion}
                </SidebarCard.parameter>
              )}
              {taskInfo?.passports && taskInfo.passports.length > 0 && (
                <SidebarCard.parameter label={t('PASSPORTS_SHORT')}>
                  <>
                    {
                      taskInfo.passports.map((x) => (
                        x.protection_device && x.protection_device.id
                          ? (
                            <PopoverEllipsis
                              placement='left'
                              content={`
                                ${x.protection_device?.substation?.name}
                                /
                                ${x.protection_device?.name}
                              `}
                            >
                              &mdash;&nbsp;
                              <Button
                                className={styles.passportLink}
                                type='link'
                              >
                                <Link
                                  to={`/passport/${x.protection_device.id}/tab/tasks/?id=${selectedTask.id}&name=${selectedTask.name}`}
                                >
                                  {x.protection_device?.substation?.name}
                                  &nbsp;/&nbsp;
                                  {x.protection_device?.name}
                                </Link>
                              </Button>
                            </PopoverEllipsis>
                          )
                          : undefined
                      ))
                    }
                  </>
                </SidebarCard.parameter>
              )}
              {taskInfo?.blanks && taskInfo?.blanks.length > 0 && (
                <SidebarCard.parameter label={t('BLANKS')}>
                  <>
                    {
                      taskInfo?.blanks.map((x) => (
                        <>
                          &mdash;&nbsp;
                          {x.name}
                          <br />
                        </>
                      ))
                    }
                  </>
                </SidebarCard.parameter>
              )}
              {taskInfo?.source_blanks && taskInfo?.source_blanks.length > 0 && (
                <SidebarCard.parameter label={t('SOURCE_BLANKS')}>
                  <>
                    {
                      taskInfo?.source_blanks.map((x) => (
                        <>
                          &mdash;&nbsp;
                          {x.name}
                          <br />
                        </>
                      ))
                    }
                  </>
                </SidebarCard.parameter>
              )}
            </SidebarCard.content>
          </SidebarCard>
        </Spin>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  Info
);
