import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Switch, withRouter } from 'react-router-dom';
import {
  Tabs, Button, Row, Col, message, Spin,
} from 'antd';
import { isEqual } from 'lodash';

import store from '@state/store';
import { getRights, getPassportRights } from '@state/rights/actions';
import { removeFromBucketLocally, addToBucketLocally } from '@state/bucket/actions';
import {
  openSidebar,
  closeSidebar,
  updateSidebar,
  showPrompt,
} from '@state/sidebar/actions';
import { getActionsLogs } from '@state/journal/actions';
import api from '@services/api';

import PrivateRoute from '@common/privateRoute';
import { PASSPORT_DETAILS } from '@common/sidebarRoot/types';
import { CheckCircleOutlined, InfoCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import {
  RIGHT_PASSPORT_INFO,
  RIGHT_PASSPORT_DOCS,
  RIGHT_PASSPORT_SETPOINTS,
  RIGHT_PASSPORT_SETPOINT_MAP,
  RIGHT_PASSPORT_FUNCTIONS,
  RIGHT_PASSPORT_TASKS,
  RIGHT_PASSPORT_LETTERS,
  RIGHT_PASSPORT_EVENTS,
  ROUTE_404, RIGHT_PASSPORT_OSCILLOGRAM,
} from '@globalConstants';

import { findIndexOfId, setUnBlockSidebar } from '@globalHelpers';

import TabFunctions from './tabs/functions';
import TabSetpoints from './tabs/setpoints';
import TabDocuments from './tabs/documents';
import TabSetpointMap from './tabs/setpointMap';
import TabTasks from './tabs/tasks';
import PassportJournal from './tabs/journal';
import TabEvents from './tabs/events';
import TabLetters from './tabs/letters';
import TabOscillogram from './tabs/oscillogram';

import styles from './styles.module.less';
import RolesParameter from './headerParams/rolesParameter';
import PassportHeaderParameterTags from './headerParams/headerParameter';

const { TabPane } = Tabs;

const TAB_DOCUMENTS = 'DOCUMENTS';
const TAB_SETPOINTS = 'SETPOINTS';
const TAB_FUNCTIONS = 'FUNCTIONS';
const TAB_TASKS = 'TASKS';
const TAB_SETPOINT_MAP = 'SETPOINT_MAP';
const TAB_JOURNAL = 'JOURNAL';
const TAB_EVENTS = 'EVENTS';
const TAB_LETTERS = 'LETTERS';
const TAB_OSCILLOGRAM = 'OSCILLOGRAM';

const mapTabsToComponents = [
  {
    id: 1,
    name: TAB_DOCUMENTS,
    nameLower: TAB_DOCUMENTS.toLowerCase(),
    component: TabDocuments,
  },
  {
    id: 2,
    name: TAB_SETPOINTS,
    nameLower: TAB_SETPOINTS.toLowerCase(),
    component: TabSetpoints,
  },
  {
    id: 3,
    name: TAB_FUNCTIONS,
    nameLower: TAB_FUNCTIONS.toLowerCase(),
    component: TabFunctions,
  },
  {
    id: 4,
    name: TAB_TASKS,
    nameLower: TAB_TASKS.toLowerCase(),
    component: TabTasks,
  },
  {
    id: 5,
    name: TAB_SETPOINT_MAP,
    nameLower: TAB_SETPOINT_MAP.toLowerCase(),
    component: TabSetpointMap,
  },
  {
    id: 6,
    name: TAB_JOURNAL,
    nameLower: TAB_JOURNAL.toLowerCase(),
    component: PassportJournal,
  },
  {
    id: 7,
    name: TAB_EVENTS,
    nameLower: TAB_EVENTS.toLowerCase(),
    component: TabEvents,
  },
  {
    id: 8,
    name: TAB_LETTERS,
    nameLower: TAB_LETTERS.toLowerCase(),
    component: TabLetters,
  },
  {
    id: 9,
    name: TAB_OSCILLOGRAM,
    nameLower: TAB_OSCILLOGRAM.toLowerCase(),
    component: TabOscillogram,
  },
];

class Passport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPassportLoading: false,
      tabs: {},
      oldDeviceId: null,
      passportId: null,
      passportData: {},
      filteredTabs: [],
      protectionDeviceData: {},
    };
  }

  componentDidMount = async () => {
    const { id, history } = this.props;
  
    this.setState({
      oldDeviceId: id,
      passportId: null,
      protectionDeviceData: {},
    });

    await this.loadPassportId();
    this.setInnerTabs();
    await store.dispatch(getPassportRights(id));
    this.getRightsToTabs();
    await this.loadData();

    this.unblock = setUnBlockSidebar(history, 'CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL_PASSPORT_CHANGE_LOCATION');
  }

  componentDidUpdate = async (prevProps) => {
    const { id, rights, history, t } = this.props;
    const { filteredTabs } = this.state;

    document.title = t('SYSTEM_TITLE');

    if (prevProps.id !== id) {
      this.setState({
        passportId: null,
        isPassportLoading: true,
        protectionDeviceData: {},
        oldDeviceId: id,
      });
      this.loadPassportId();
      store.dispatch(getPassportRights(id));
      await this.loadData();
    }

    if (!this.getCurrentTab(this.props)) {
      if (rights && rights[RIGHT_PASSPORT_SETPOINTS]) {
        history.replace(`/passport/${id}/tab/${TAB_SETPOINTS.toLowerCase()}`);
      } else if (filteredTabs && filteredTabs.length) {
        this.onChangeTab(filteredTabs[0].id);
      }
    }

    if (!isEqual(prevProps.rights, rights)) {
      this.getRightsToTabs();
    }
  }

  componentWillUnmount() {
    this.setState({
      passportId: null,
      protectionDeviceData: {},
    });
    store.dispatch(getRights());
    this.unblock && this.unblock();
  }

  getCurrentTab(props) {
    const { match } = props;

    return match.params && match.params.tab
      ? mapTabsToComponents.find((t) => t.nameLower === match.params.tab)
      : null;
  }

  getPassportData = async () => {
    const { passportId } = this.state;
    const { status, data } = await api.getPassport(passportId);

    if (status === 200) {
      this.setState({ passportData: data });
    }
  };

  setInnerTabs = () => {
    const { t } = this.props;
    const innerTabs = {};
    mapTabsToComponents.forEach((item) => {
      innerTabs[item.id] = {
        name: t(item.name),
      };
    });

    this.setState({
      tabs: innerTabs,
    });
  };

  loadData = async (callback) => {
    const { history, id, t } = this.props;
    this.setState({ isPassportLoading: true });

    const { status, data } = await api.getProtectionDevice(id);

    if (status !== 200) {
      this.setState({ isPassportLoading: false });
      history.replace(ROUTE_404);
      return;
    }

    let passportId = null;

    if (data.passports && data.passports.length) {
      passportId = data.passports[0].id;
    }

    if (data.equipment_base.length) {
      data.equipment_name = { name: data.equipment_base[0].name };
    }

    data.role_dc_management = [];
    data.role_dc_usage = [];
    if (data.roles && data.roles.length) {
      data.roles.forEach(
        (x) => {
          if (x.name === t('DEVICE.COL_ROLE_DC_MANAGEMENT')) {
            data.role_dc_management.push(x.dc);
          }
          if (x.name === t('DEVICE.COL_ROLE_CD_USAGE')) {
            data.role_dc_usage.push(x.dc);
          }
        }
      );
    }

    this.setState({
      protectionDeviceData: data,
      passportId,
      isPassportLoading: false,
    }, () => {
      callback && callback();
    });
  };

  loadPassportId = async () => {
    const { id } = this.props;

    const { status, data } = await api.getProtectionDevicePassportId(id);

    if (
      status === 200
      && data
      && data.passport_ids
      && data.passport_ids.length
      && data.passport_ids[0]
    ) {
      this.setState({
        passportId: data.passport_ids[0],
      });
    }
  };

  onChangeTab = (tab) => {
    const { id, history } = this.props;
    const tabId = parseInt(tab, 10);
    const tabObject = mapTabsToComponents.find((t) => t.id === tabId);

    if (tabObject.disabled) return;

    history.push(`/passport/${id}/tab/${tabObject.nameLower}`);
  };

  openDetail = async () => {
    const {
      id, sidebar, openPassportSidebar, closePassportSidebar, getActionsJournal,
    } = this.props;
    const {
      protectionDeviceData,
    } = this.state;

    if (sidebar.isOpen && sidebar.componentType === PASSPORT_DETAILS) {
      closePassportSidebar();
    } else {
      openPassportSidebar(
        PASSPORT_DETAILS,
        {
          ...protectionDeviceData,
          callback: async () => {
            await this.loadData();
            this.updateSidebarDetails();
            getActionsJournal(id);
          },
        },
        undefined,
        true,
      );
    }
  };

  updateSidebarDetails = () => {
    const { updatePassportSidebar } = this.props;
    const { protectionDeviceData } = this.state;

    updatePassportSidebar(protectionDeviceData);
  };

  onAdd2Bucket = async () => {
    const { t, id, bucketIdsData } = this.props;

    if (bucketIdsData.includes(id)) {
      const { status } = await api.clearBucket(id);

      if (status === 200 || status === 204) {
        removeFromBucketLocally(id);
        message.success(t('REMOVE_FROM_BUCKET_MESSAGE'));
      } else {
        message.error(t('REMOVE_FROM_BUCKET_FAIL_MESSAGE'));
      }

      return;
    }

    const { status } = await api.addProtectionDevice2Bucket(id);

    if (status === 200) {
      addToBucketLocally(id);
      message.success(t('ADD2BUCKET_MESSAGE'));
    } else {
      message.error(t('ADD2BUCKET_FAIL_MESSAGE'));
    }
  };

  getRightsToTabs = () => {
    const { rights } = this.props;

    const currentTab = this.getCurrentTab(this.props);
    let filteredTabs = mapTabsToComponents;

    if (!rights[RIGHT_PASSPORT_DOCS]) filteredTabs = filteredTabs.filter((item) => item.name !== TAB_DOCUMENTS);
    if (!rights[RIGHT_PASSPORT_SETPOINTS]) filteredTabs = filteredTabs.filter((item) => item.name !== TAB_SETPOINTS);
    if (!rights[RIGHT_PASSPORT_FUNCTIONS]) filteredTabs = filteredTabs.filter((item) => item.name !== TAB_FUNCTIONS);
    if (!rights[RIGHT_PASSPORT_TASKS]) filteredTabs = filteredTabs.filter((item) => item.name !== TAB_TASKS);
    if (!rights[RIGHT_PASSPORT_LETTERS]) filteredTabs = filteredTabs.filter((item) => item.name !== TAB_LETTERS);
    if (!rights[RIGHT_PASSPORT_SETPOINT_MAP]) {
      filteredTabs = filteredTabs.filter((item) => item.name !== TAB_SETPOINT_MAP);
    }
    if (!rights[RIGHT_PASSPORT_EVENTS]) {
      filteredTabs = filteredTabs.filter((item) => item.name !== TAB_EVENTS);
    }
    if (!rights[RIGHT_PASSPORT_OSCILLOGRAM]) {
      filteredTabs = filteredTabs.filter((item) => item.name !== TAB_OSCILLOGRAM);
    }

    if (currentTab && !findIndexOfId(currentTab.id, filteredTabs) && filteredTabs.length) {
      this.onChangeTab(filteredTabs[0].id);
    }

    this.setState({ filteredTabs });
  };

  render() {
    const {
      isPassportLoading,
      protectionDeviceData,
      passportId,
      passportData,
      tabs,
      filteredTabs,
      oldDeviceId,
    } = this.state;
    const {
      t, id, rights, bucketIdsData,
    } = this.props;

    const isArchived = protectionDeviceData.is_archived;

    const tabProps = {
      deviceId: id,
      passportId: id !== oldDeviceId ? null : passportId,
      passportData,
      protectionDeviceData: id !== oldDeviceId ? {} : protectionDeviceData,
      isArchived,
      getPassportData: this.getPassportData,
      currentDC: protectionDeviceData.dispatcher_center,
      deviceCimId: protectionDeviceData.cim_id,
      loadData: this.loadData,
    };
    const currentTab = this.getCurrentTab(this.props);

    const recordInBucket = bucketIdsData.includes(id);

    return (
      <Spin spinning={isPassportLoading}>
        <div className={styles.wrp}>
          <div className={styles.passport}>
            <Row className={styles.breadCrumbsRow}>
              <Row gutter={10} style={{ marginTop: 2 }}>
                <Col>
                  <div className={`${styles.breadCrumbsCol} ant-breadcrumb`}>
                    <>
                      <span className={styles.breadCrumbs}>
                        {(protectionDeviceData.region && protectionDeviceData.region.name) || '—'}
                      </span>
                      <span className={styles.delimiter}>\</span>
                      <span className={styles.breadCrumbs}>
                        {(protectionDeviceData.substation && protectionDeviceData.substation.name) || '—'}
                      </span>
                      <span className={styles.delimiter}>\</span>
                      <span className={styles.breadCrumbs}>
                        {(protectionDeviceData.equipment_type && protectionDeviceData.equipment_type.name) || '—'}
                      </span>
                    </>
                  </div>
                </Col>
                <Col className={isArchived && styles.archiveStatus}>
                  <div className={styles.status}>
                    {
                      isArchived
                        ? (
                          <span>
                            {t('DEVICE_STATUS_IS_ARCHIVED')}
                          </span>
                        )
                        : (
                          <>
                            <span>
                              {t('DEVICE_STATUS_ONLINE')}
                            </span>
                            <CheckCircleOutlined className={styles.circleActive} />
                          </>
                        )
                    }
                  </div>
                </Col>
              </Row>
              <Row className={styles.trayButtons} gutter={10}>
                <Col>
                  <Button size='small'
                    icon={recordInBucket ? <StarFilled /> : <StarOutlined />}
                    type={recordInBucket ? 'primary' : 'default'}
                    onClick={this.onAdd2Bucket}>
                    {recordInBucket ? t('REMOVE_DEVICE') : t('ADD_DEVICE')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    size='small'
                    icon={<InfoCircleOutlined />}
                    onClick={this.openDetail}
                    disabled={!rights[RIGHT_PASSPORT_INFO]}>
                    {t('GOTO_DEVICE')}
                  </Button>
                </Col>
              </Row>
            </Row>
            <Col className={styles.info}>
              <Row>
                <div className={styles.name}>{protectionDeviceData.name}</div>
              </Row>
              <div className={styles.params}>
                  <PassportHeaderParameterTags name={t('DEVICE_LABEL_EQUIPMENT_BASE')} content={protectionDeviceData?.equipment_base || []} />
                  <RolesParameter roles={protectionDeviceData.roles} />
              </div>
            </Col>
            {/* <Row className={styles.info} gutter={16}>
              <Col xs={24} sm={24} lg={10}>
                <div className={styles.status}>
                  {
                    isArchived
                      ? (
                        <>
                          <span>
                            {t('DEVICE')}
                            &nbsp;&ndash;&nbsp;
                            <span className='isArchivedBlock'>
                              {t('DEVICE_STATUS_IS_ARCHIVED')}
                            </span>
                          </span>
                        </>
                      )
                      : (
                        <>
                          <span>
                            {t('DEVICE')}&nbsp;&ndash;&nbsp;{t('DEVICE_STATUS_ONLINE')}
                            &nbsp;
                          </span>
                          <CheckCircleOutlined className={styles.circleActive} />
                        </>
                      )
                  }
                </div>
                <div className={styles.name}>{protectionDeviceData.name}</div>
                <div>
                  <div className={styles.bucketStatus}>
                    {
                      recordInBucket
                        ? (
                          <>
                            <CheckCircleOutlined theme='filled' className={styles.successIcon} />
                            &nbsp;
                            {t('IN_BUCKET')}
                          </>
                        )
                        : (
                          <>
                            <CheckCircleOutlined />
                            &nbsp;
                            {t('NOT_IN_BUCKET')}
                          </>
                        )
                    }
                  </div>
                  <Button className={styles.button} onClick={this.openDetail} disabled={!rights[RIGHT_PASSPORT_INFO]}>
                    <InfoCircleOutlined />
                    <span>{t('GOTO_DEVICE')}</span>
                  </Button>
                  <Button
                    className={styles.button}
                    onClick={this.onAdd2Bucket}
                    icon={recordInBucket ? <StarFilled /> : <StarOutlined />}
                    type={recordInBucket && 'primary'}
                  >
                    <span>
                      {
                      recordInBucket
                        ? t('REMOVE_DEVICE')
                        : t('ADD_DEVICE')
                    }
                    </span>
                  </Button>
                </div>
              </Col>
              <Col xs={0} sm={0} lg={4}>
                <div className={styles.caption}>{t('DEVICE.COL_ROLE_DC_MANAGEMENT')}</div>
                <div className={styles.value}>
                  {
                    protectionDeviceData.role_dc_management && protectionDeviceData.role_dc_management.length
                      ? (
                        <PopoverTags size={protectionDeviceData.role_dc_management.length}>
                          {
                            protectionDeviceData.role_dc_management.map(
                              (x) => <CustomTag key={x.id}>{x.name}</CustomTag>
                            )
                          }
                        </PopoverTags>
                      )
                      : '—'
                  }
                </div>

                <div className={styles.caption}>{t('DEVICE.COL_ROLE_CD_USAGE')}</div>
                <div className={styles.value}>
                  {
                    protectionDeviceData.role_dc_usage && protectionDeviceData.role_dc_usage.length
                      ? (
                        <PopoverTags size={protectionDeviceData.role_dc_usage.length}>
                          {
                            protectionDeviceData.role_dc_usage.map(
                              (x) => <CustomTag key={x.id}>{x.name}</CustomTag>
                            )
                          }
                        </PopoverTags>
                      )
                      : '—'
                  }
                </div>
              </Col>

              <Col xs={0} sm={0} lg={4}>
                <div className={styles.caption}>{t('DEVICE.COL_OWNER')}</div>
                <div className={styles.value}>
                  {(protectionDeviceData.owner && protectionDeviceData.owner.name) || '—'}
                </div>

                <div className={styles.caption}>{t('DEVICE.COL_TERRITORY')}</div>
                <div className={styles.value}>{(protectionDeviceData.region && protectionDeviceData.region.name) || '—'}</div>
              </Col>

              <Col xs={0} sm={0} lg={4}>
                <div className={styles.caption}>{t('DEVICE_LABEL_EQUIPMENT_BASE')}</div>
                <div className={styles.value}>
                  {
                    protectionDeviceData.equipment_base && protectionDeviceData.equipment_base.length
                      ? (
                        <PopoverTags size={protectionDeviceData.equipment_base.length}>
                          {protectionDeviceData.equipment_base.map((contentItem) => (
                            <CustomTag key={contentItem.id}>{contentItem.name}</CustomTag>
                          ))}
                        </PopoverTags>
                      )
                      : '—'
                  }
                </div>

                <div className={styles.caption}>{t('DEVICE_LABEL_SUBSTATION')}</div>
                <div className={styles.value}>
                  {(protectionDeviceData.substation && protectionDeviceData.substation.name) || '—'}
                </div>
              </Col>
            </Row> */}

            <div className={styles.tabs}>
              <Tabs
                activeKey={`${currentTab && currentTab.id}`}
                defaultActiveKey={`${currentTab && currentTab.id}`}
                onChange={this.onChangeTab}
              >
                {filteredTabs.map(
                  (item) => (
                    <TabPane
                      tab={(
                        <div id={`passport-tab--${item.id}`} className={styles[`${item.disabled ? 'tabDisabled' : 'tab'}`]}>
                          {tabs[item.id] && tabs[item.id].name}
                        </div>
                      )}
                      key={item.id}
                    />
                  )
                )}
              </Tabs>
            </div>
          </div>
        </div>
        <div>
          <Switch>
            {mapTabsToComponents.map(
              (item) => (
                <PrivateRoute
                  key={item.nameLower}
                  path={`/passport/${id}/tab/${item.nameLower}`}
                  render={() => {
                    const TabComponent = item.component;
                    return <TabComponent {...tabProps} />;
                  }}
                />
              )
            )}
          </Switch>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  bucketIdsData: state.bucket.bucketIdsData,
  rights: state.rights.rightsData,
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  openPassportSidebar: openSidebar,
  closePassportSidebar: closeSidebar,
  updatePassportSidebar: updateSidebar,
  getActionsJournal: getActionsLogs,
  showPromptSidebar: showPrompt,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      Passport
    )
  )
);
