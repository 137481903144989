import api from '@services/api';

const componentConstants = {
  TEXTAREA: 'TEXTAREA',
  INPUT: 'INPUT',
  DATEPICKER: 'DATEPICKER',
  SELECT: 'SELECT',
  TAGS_ROLES: 'TAGS_ROLES',
  SELECT_SEARCH: 'SELECT_SEARCH',
};

const componentFilters = {
  SUBSTATIONS: 'substations',
  REGIONS: 'regions',
  PRODUCERS: 'producers',
  TRADE_DEVICES: 'trade_devices',
  OWNERS: 'owners',
  VOLTAGE_LEVELS: 'voltage_levels',
  DEVICES: 'devices',
  IMPLEMENTATION_TYPES: 'implementation_types',
  SOFTWARE_VERSIONS: 'software_versions',
};

const componentFiltersGet = {
  substations: async (params) => await api.getSubstations(params),
  regions:
    async (params, propsData) => {
      const newParams = {
        ...params,
      };
      // TODO: потом надо включить обратно, когда появится связка ДЦ <=> Территория
      // if (propsData && propsData.dispatcher_center && propsData.dispatcher_center.id) {
      //   newParams.dispatcher_center = propsData.dispatcher_center.id;
      // }
      return await api.getRegions(newParams);
    },
  producers: async (params) => await api.getProducers(params),
  trade_devices: async (params) => await api.getTradeDevices(params),
  owners: async (params) => await api.getOwners(params),
  voltage_levels: async (params) => await api.getVoltageLevels(params),
  devices: async (params, propsData, component) => {
    const newParams = {
      ...params,
    };
    if (component.form.current) {
      newParams.substation = component.form.current.getFieldValue('substation');
    }
    return await api.getEquipmentBase(newParams);
  },
  implementation_types: async (params) => await api.getImplementationTypes(params),
  software_versions:
    async (params, propsData, component) => {
      const newParams = {
        ...params,
      };
      if (component.form.current) {
        newParams.trade_device = component.form.current.getFieldValue('trade_device');
      }
      return await api.getVersions(newParams);
    },
};

export {
  componentConstants,
  componentFilters,
  componentFiltersGet,
};
