import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import EventsTable from '@routes/Events/Table';
import {
  CustomCard,
} from '@ui';
import { RIGHT_PASSPORT_EVENTS, MODE_EDIT } from '@globalConstants';

import Actions from './actions';
import styles from './styles.module.less';

class EventsRoute extends Component {
  componentRef;

  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: '',
      oSelectedEvent: {},
    };
  }

  onSelectedEventChange = (selectedEvent, record) => {
    this.setState({
      selectedEvent: selectedEvent[0],
      oSelectedEvent: record[0],
    });
  };

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  onDeselectedEvent = () => {
    this.setState({
      selectedEvent: '',
      oSelectedEvent: {},
    });
  };

  updateEvents = (eventId) => {
    this.componentRef
      && 'updateEvents' in this.componentRef
      && this.componentRef.updateEvents !== undefined
      && this.componentRef.updateEvents(eventId);
  };

  removeEvent = (eventId) => {
    // this.tableRef.current && this.tableRef.current.removeEvent(eventId);
    this.componentRef
      && 'removeEvent' in this.componentRef
      && this.componentRef.removeEvent !== undefined
      && this.componentRef.removeEvent(eventId);
  };

  render() {
    const {
      t, deviceId, rights, isArchived,
    } = this.props;
    const {
      selectedEvent, oSelectedEvent,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_EVENTS] === MODE_EDIT && !isArchived;

    return (
      <CustomCard
        title={t('EVENTS_CARD_TITLE')}
        className={styles.reportsCard}
        extra={(
          <Actions
            selectedEvent={selectedEvent}
            oSelectedEvent={oSelectedEvent}
            deviceId={deviceId}
            updateEvents={this.updateEvents}
            removeEvent={this.removeEvent}
            onDeselectedEvent={this.onDeselectedEvent}
            isEditRight={isEditRight}
          />
        )}
      >
        <EventsTable
          tableName='passport_tab_report'
          setComponentRef={this.setComponentRef}
          t={t}
          onSelectedEventChange={this.onSelectedEventChange}
          selectedEvent={selectedEvent}
          deviceId={deviceId}
          passportMode
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    EventsRoute
  )
);
