import { maintenanceAlertActionTypes, MAINTENANCE_ALERT_SET_DATA } from './types';

export const setMaintenanceAlertData = (content: string, visible: boolean): maintenanceAlertActionTypes => {
  return {
    type: MAINTENANCE_ALERT_SET_DATA,
    content,
    visible
  };
};




