import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  Button, Checkbox, Form, message, Popconfirm, Radio, Select, Spin, Upload, Popover, Tooltip, Input
} from 'antd';
import { CheckCircleOutlined, DeleteOutlined, UploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { debounce, isEqual } from 'lodash';

import {saveFileToStorage, validateFile} from '@app/helpers';
import api from '@services/api';
import { getBucket } from '@state/bucket/actions';
import {
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_SELECT,
  INPUT_TYPE_DATEPICKER,
} from '@ui/formItem/constants';
import { FormItem, PopoverEllipsis } from '@ui';
import { LIBRARY_GLOBAL, LIBRARY_LOCAL, LIMIT } from '@app/constants';
import { ADDITIONAL_INSTRUCTIONS, OPTIONS_CHECKBOX_GROUP } from "./constants";
import styles from './styles.module.less';
import {
  STATUS_NEW, STATUS_PLANNED, TASK_MODE_AGREEMENT, TASK_MODE_CREATE, TASK_MODE_EDIT,
} from '../constants';
import cn from 'classnames';
import { getPopoverOptionValue } from "./getPopoverOptionValue";

const allowedExtensions = ['DOCX'];

class TaskForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskType:
        props.mode === TASK_MODE_CREATE || props.selectedTask.status
          ? STATUS_PLANNED
          : STATUS_NEW,
      protectionDeviceStatus: props.selectedTask?.additional_attributes
        ?.protection_device_status
        ? props.selectedTask.additional_attributes.protection_device_status
        : 'NEW',
      networkModel: 'bmms',
      searchDeviceValue: '',
      isLoading: true,
      currentPassportsList: [],
      currentPassportsCount: 0,
      allPassportsList: [],
      allPassportsCount: 0,
      selectedPassportsList: [],
      selectedPassportsCount: 0,
      onlySelected: false,
      isAddPassport: false,
      addButtonNumber: 0,
      fileList: {
        [props.passportId]: [],
      },
      isUploading: {
        [props.passportId]: false,
      },
      nonDigitalBlankDocType: {},
      datePickerMode: 'date',
      passportsBmmsData: [],
      passportsBmmsDataCount: 0,
      categoryMmnId: '',
      modelsBmmsData: [],
      modelsBmmsDataCount: 0,
      modelsRmmsData: [],
      modelsRmmsDataCount: 0,
      selectedModelRmmsId: '',
      selectedModelBmmsId: '',
      selectedPassportBmmsName: null,
      selectedModelBmmsName: null,
      selectedModelRmmsName: null
    };
  }

  componentDidMount = async () => {
    const {
      bucketCount, bucketList, getSelectedPassports,
    } = this.props;

    this.loadDocumentsTypesForTaskDC();

    if (bucketCount || (bucketList && bucketList.length)) {
      getSelectedPassports(-1, false, { limit: 0, offset: 0 });
      this.setState({
        selectedPassportsList: bucketList,
        selectedPassportsCount: bucketCount,
        onlySelected: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { passports, bucketCount, bucketList } = this.props;
    const { onlySelected } = this.state;

    if (
      (!isEqual(prevProps.bucketCount, bucketCount))
      || (!isEqual(prevProps.bucketList, bucketList))
      || (!isEqual(prevState.onlySelected, onlySelected))
    ) {
      this.updateButtonNumber();
      this.onSearchDevice('', false);
    }

    if (!isEqual(passports, prevProps.passports)) {
      this.onSearchDevice('', true);
    }
  }

  getPassportsBmmsData = async (offset = 0) => {
    const { setBlocked } = this.props;

    setBlocked(true);

    const data = {};
    let limit = 9999;
    const result = await api.getDataForFilter(
      '/v1/category-mmn/short/',
      data,
      limit,
      offset
    );

    if (result.status === 200 && result.data.results) {
      this.setState((prevState) => ({
        passportsBmmsData:
          offset === 0
            ? result.data.results
            : [
              ...prevState.passportsBmmsData,
              ...result.data.results,
            ],
        passportsBmmsDataCount: result.data.count,
      }));
    }

    setBlocked(false);
  };

  getModelsData = async (type, offset = 0) => {
    const { setBlocked } = this.props;

    setBlocked(true);

    let limit = 9999;
    const filterData =
      type === 'bmms'
        ? { category_mmn: this.state.categoryMmnId, type: 'BASIC' }
        : { type: 'CALCULATED' };

    const result = await api.getDataForFilter(
      '/v2/task-dc/mmn',
      filterData,
      limit,
      offset
    );

    if (result.status === 200 && result.data.results) {
      this.setState((prevState) => ({
        [type === 'bmms' ? 'modelsBmmsData' : 'modelsRmmsData']:
          offset === 0
            ? result.data.results
            : [
              ...prevState[
                type === 'bmms'
                  ? 'modelsBmmsData'
                  : 'modelsRmmsData'
                ],
              ...result.data.results,
            ],
        [type === 'bmms'
          ? 'modelsBmmsDataCount'
          : 'modelsRmmsDataCount']: result.data.count,
      }));
    }

    setBlocked(false);
  };

  onSearchCurrentDevices = async (value, offset = 0) => (
    this.onSearchDevice(value, true, offset)
  );

  onSearchAllDevices = async (value, offset = 0) => (
    this.onSearchDevice(value, false, offset)
  );

  onSearchSelectedDevices = async (value, offset = 0) => (
    this.onSearchDevice(value, false, offset)
  );

  onSearchDevice = async (value, isCurrentPassport, offset = 0) => {
    const {
      setBlocked, passportId, passports, bucketList,
    } = this.props;
    const {
      currentPassportsList, allPassportsList, searchDeviceValue, onlySelected, fileList, isUploading,
    } = this.state;

    const newValue = (value === ''
      ? undefined
      : value
    );

    const isSearchValueChanged = newValue !== searchDeviceValue || (offset === 0 && value === undefined);

    setBlocked(true);

    if (isCurrentPassport || !onlySelected) {
      let limit = LIMIT;
      const data = {
        ordering: 'name',
      };
      if (newValue) {
        data.name = newValue;
      }
      if (isCurrentPassport) {
        data.passport = passports && passports.length
          ? passports
          : [passportId];
        limit = 0;
      }
      const result = await api.getDataForFilter(
        '/v1/protection-devices/short/',
        data,
        limit,
        offset
      );

      if (result.status === 200 && result.data.results) {
        const res = {};
        const newState = {
          isLoading: false,
          searchDeviceValue: newValue,
          fileList: fileList,
          isUploading: isUploading,
        };
        for (const x of result.data.results) {
          const key = x.passports && x.passports[0] && x.passports[0].id;
          res[key] = x;

          if (x?.substation?.name) res[key].name += ` (${x.substation.name})`;
          
          newState.fileList[key] = [];
          newState.isUploading[key] = false;
        }

        if (isCurrentPassport) {
          newState.currentPassportsCount = result.data.count;
          newState.currentPassportsList = isSearchValueChanged
            ? res
            : {
              ...currentPassportsList,
              ...res,
            };
        } else {
          newState.allPassportsCount = result.data.count;
          newState.allPassportsList = isSearchValueChanged
            ? res
            : {
              ...allPassportsList,
              ...res,
            };
        }

        this.setState(newState);
      }
    } else {
      const devices = newValue
        ? bucketList.filter((x) => x.name.includes(newValue))
        : bucketList;
      const devicesList = {};
      devices.forEach((x) => devicesList[x.id] = x);
      this.setState({
        searchDeviceValue: newValue,
        selectedPassportsList: devicesList,
        selectedPassportsCount: devices.length,
        isLoading: false,
      });
    }
    setBlocked(false);
  }

  changeOnlySelected = (value) => {
    this.setState({
      onlySelected: value,
    });
  }

  updateButtonNumber = () => {
    this.setState({
      addButtonNumber: moment.now().toFixed(0),
    });
  }

  onScrollDeviceSelector = (event, isCurrentPassport) => {
    const {
      allPassportsList,
      allPassportsCount,
      currentPassportsList,
      currentPassportsCount,
      searchDeviceValue,
      isLoading,
    } = this.state;

    const { target } = event;
    const scrollTopMax = target.scrollHeight - target.offsetHeight;
    const list = isCurrentPassport ? currentPassportsList : allPassportsList;
    const countAll = isCurrentPassport ? currentPassportsCount : allPassportsCount;
    const countList = Object.keys(list).length;

    if (
      !isLoading
      && target.scrollTop.toFixed() === scrollTopMax.toFixed()
      && countAll > countList
    ) {
      this.onSearchDevice(searchDeviceValue, isCurrentPassport, countList);
    }
  }

  onChangeDevice = (value, option, oldValue) => {
    const { onChangePassport } = this.props;
    const { fileList, isUploading } = this.state;
    if (oldValue !== value && typeof onChangePassport === 'function') {
      onChangePassport(oldValue, value);
    }
    const newState = {};
    if (oldValue === undefined || oldValue === null) {
      newState.isAddPassport = false;
    }
    if (value) {
      newState.fileList = {
        ...fileList,
        [value]: [],
      };
      newState.isUploading = {
        ...isUploading,
        [value]: false,
      };
    }
    this.setState({
      ...newState,
    });
  }

  onChangePassportBmms = (value, option) => {
    this.setState({
      categoryMmnId: option.key,
      selectedPassportBmmsName: value,
    });
  };

  onChangeModel = (value, option) => {
    const { networkModel } = this.state;

    if (networkModel === 'rmms') {
      this.setState({
        selectedModelRmmsId: option.key,
        selectedModelRmmsName: value,
      });
    } else {
      this.setState({
        selectedModelBmmsId: option.key,
        selectedModelBmmsName: value,
      });
    }
  };

  onClickAttachModel = (id) => {
    const {
      networkModel,
      selectedPassportBmmsName,
      selectedModelBmmsName,
      selectedModelRmmsName,
    } = this.state;

    const { updateMetaMmnData, metaMmnData } = this.props;

    const data = [];

    data.push({
      modelId: id,
      name:
        networkModel === 'bmms'
          ? `${selectedPassportBmmsName} / ${selectedModelBmmsName}`
          : selectedModelRmmsName,
    });

    if (metaMmnData) {
      updateMetaMmnData([...metaMmnData, ...data]);
    } else {
      updateMetaMmnData(data);
    }

    this.setState({
      selectedPassportBmmsName: null,
      selectedModelBmmsName: null,
      selectedModelRmmsName: null,
      categoryMmnId: '',
    });
  };

  addPassport = () => {
    this.setState({
      isAddPassport: true,
      addButtonNumber: moment.now().toFixed(0),
    });
  }

  handleTaskTypeChange = (e) => {
    const { form } = this.props;
    form.resetFields(['source_blank']);

    this.setState({
      taskType: e.target.value,
    });
  };

  handleProtectionDeviceStatusChange = (e) => {
    const { form } = this.props;
    form.resetFields(['documentation_requisites']);

    this.setState({
      protectionDeviceStatus: e.target.value,
    });
  };

  disabledDate = (current, name) => {
    const { form } = this.props;
    const { taskType, datePickerMode } = this.state;

    if (name === 'date_planned_completion' && taskType === STATUS_PLANNED && form.getFieldValue('date_planned_start')) {
      return current < moment(form.getFieldValue('date_planned_start')).startOf(datePickerMode === 'date' ? 'day' : datePickerMode);
    }

    if (name === 'date_planned_start' && form.getFieldValue('date_planned_completion')) {
      return current > moment(form.getFieldValue('date_planned_completion')).endOf(datePickerMode === 'date' ? 'day' : datePickerMode);
    }

    return false;
  };

  getBlankName = (item) => {
    return (
      <PopoverEllipsis
        content={item.name}
        placement='left'
      >
        {item.is_active && (
          <>
            <CheckCircleOutlined className='check-circle--active' />
            &nbsp;
          </>
        )}
        <span className={item.type === 'V2' ? styles.newBlank : ''}>
          {item.name}
        </span>
      </PopoverEllipsis>
    );
  }

  loadDocumentsTypesForTaskDC = async () => {
    const { t } = this.props;

    const { status, data } = await api.getDocumentTypes({ name: t('NON_DIGITAL_SETPOINT_BLANK') });

    if (status === 200 && data.results && data.results.length) {
      const docType = data.results.filter((x) => x.name === t('NON_DIGITAL_SETPOINT_BLANK'));
      this.setState({
        nonDigitalBlankDocType: docType && docType.length && docType[0],
      });
    }
  };

  onUploadNotDigitalBlank = async (currentPassportId, blanksFieldName) => {
    const {
      t, form, localStorage, onUpdateBlanksListByPassport,
    } = this.props;
    const { fileList, nonDigitalBlankDocType } = this.state;

    if (
      fileList
      && currentPassportId
      && fileList[currentPassportId]
      && fileList[currentPassportId].length > 0
      && nonDigitalBlankDocType
      && nonDigitalBlankDocType.id
      && localStorage
      && localStorage.id
    ) {
      this.setState((state) => ({
        isUploading: {
          ...state.isUploading,
          [currentPassportId]: true,
        },
      }));

      const newFilesForRevert = [];
      const formDataPrime = {
        passports: [currentPassportId],
        deleted_external_links: [],
        external_links: [],
        is_storage: true,
        library: LIBRARY_GLOBAL,
        library_type: LIBRARY_LOCAL,
        document_type: nonDigitalBlankDocType && nonDigitalBlankDocType.id,
        name: '',
      };

      for (const file of fileList[currentPassportId]) {
        const storageSavedLink = await saveFileToStorage(t, localStorage, file);

        if (storageSavedLink) {
          formDataPrime.external_links.push(storageSavedLink);
          newFilesForRevert.push(storageSavedLink);

          if (formDataPrime.name === '') {
            formDataPrime.name = file.name;
          }
        } else {
          // Удаляем только что загруженные на хранилище файлы, поскольку пришла ошибка сохранения очередного файла.
          for (const fileRevert of newFilesForRevert) {
            await api.request(fileRevert, 'delete', undefined, {}, {}, true);
          }

          this.setState((state) => ({
            isUploading: {
              ...state.isUploading,
              [currentPassportId]: false,
            },
          }));

          return;
        }
      }

      const { status, data: responseData } = await api.uploadDocument(formDataPrime);

      if (
        status === 201
        && responseData
        && responseData.new_blanks
        && responseData.new_blanks.length > 0
      ) {
        onUpdateBlanksListByPassport && onUpdateBlanksListByPassport(currentPassportId, () => {
          const oldBlanksList = form && form.getFieldValue(blanksFieldName);
          form && form.setFieldsValue({
            [blanksFieldName]: oldBlanksList.concat(
              responseData.new_blanks.map((x) => ({ value: x.id, key: x.id, label: x.name }))
            ),
          });
          this.setState((state) => ({
            isUploading: {
              ...state.isUploading,
              [currentPassportId]: false,
            },
            fileList: {
              ...fileList,
              [currentPassportId]: [],
            },
          }));
          message.success(t('UPDATE_SUCCESS'));
        });
      } else {
        this.setState((state) => ({
          isUploading: {
            ...state.isUploading,
            [currentPassportId]: false,
          },
        }));

        if (responseData && responseData.error) {
          message.error(responseData.error);
        } else {
          message.error(t('UPDATE_ERROR'));
        }
      }
    } else {
      message.error(t('UPDATE_ERROR'));
    }
  };

  renderBlanksListOptions = (blanksList, key, disableMainFields) => {
    return blanksList && blanksList.length
      ? blanksList.map((item) => (
        <Select.Option
          value={item.id}
          key={key.concat('_', item.id)}
          disabled={disableMainFields}
        >
          {this.getBlankName(item)}
        </Select.Option>
      ))
      : [];
  };

  renderPassportsAndBlanks = (highlight, passportBlanksList, currentPassportId, idx, disableMainFields) => {
    const {
      t, blanksList, selectedBlanksList, passports, localStorage,
    } = this.props;
    const {
      currentPassportsList, allPassportsList, fileList, isUploading,
      onlySelected, selectedPassportsList, selectedPassportsCount,
    } = this.state;

    const passportFieldName = `passport[${currentPassportId || idx}]`;
    const blanksFieldName = `blanks_for_passport[${currentPassportId || idx}]`;

    const selectedBlanksIds = [];
    if (selectedBlanksList && selectedBlanksList[currentPassportId]) {
      selectedBlanksList[currentPassportId].forEach(
        (x) => selectedBlanksIds.push({ key: x.id, label: this.getBlankName(x) })
      );
    }
    const isCurrentPassport = !!currentPassportId;
    const passportsList = (
      currentPassportId
        ? currentPassportsList
        : (
          onlySelected
            ? selectedPassportsList
            : allPassportsList
        )
    );
    const currentPassportsListLength = passports.length;
    const disableUploadNotDigitalBlank = !localStorage || !localStorage.id;
    const disableSaveNotDigitalBlank = (
      disableUploadNotDigitalBlank
      || !(
        fileList
        && fileList[currentPassportId]
        && fileList[currentPassportId].length > 0
      )
    );

    const uploadProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList[currentPassportId].indexOf(file);
          const newFileList = state.fileList[currentPassportId].slice();
          newFileList.splice(index, 1);
          return {
            fileList: {
              ...state.fileList,
              [currentPassportId]: newFileList,
            },
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: {
            ...state.fileList,
            [currentPassportId]: [...state.fileList[currentPassportId], file],
          },
        }));
        return false;
      },
      fileList: fileList && fileList[currentPassportId],
    };

    return passportBlanksList
      ? (
        <div key={`div_${currentPassportId}_${idx}`} className={styles.itemPassport}>
          <Form.Item
            label={t('PASSPORT_TASKS_CREATE_FIELD_DEVICE')}
            key={`passport_${currentPassportId}`}
            name={passportFieldName}
            initialValue={currentPassportId}
            disabled={disableMainFields}
          >
            <Select
              key={`passport_${currentPassportId}_${idx}_select`}
              size='small'
              disabled={disableMainFields || currentPassportsList[currentPassportId] !== undefined}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              title={t('PASSPORT_TASKS_CREATE_FIELD_DEVICE_SEARCH')}
              placeholder={t('PASSPORT_TASKS_CREATE_FIELD_DEVICE_SELECTED')}
              defaultActiveFirstOption={false}
              filterOption={false}
              listItemHeight={10}
              showSearch={true}
              onChange={
                (value, option) => this.onChangeDevice(
                  value,
                  option,
                  currentPassportId
                )
              }
              onDropdownVisibleChange={(x) => x && this.onSearchDevice('', isCurrentPassport)}
              onPopupScroll={(event) => (
                onlySelected
                  ? {}
                  : this.onScrollDeviceSelector(event, isCurrentPassport)
              )}
              onSearch={debounce(
                isCurrentPassport
                  ? this.onSearchCurrentDevices
                  : (
                    onlySelected
                      ? this.onSearchSelectedDevices
                      : this.onSearchAllDevices
                  ),
                800
              )}
            >
              {
                Object.keys(passportsList).map((key) => {
                  const item = passportsList[key];
                  const itemPassport = !isCurrentPassport && onlySelected
                    ? item.passport && item.passport[0] && item.passport[0].id
                    : item.passports && item.passports[0] && item.passports[0].id;
                  return (
                    <Select.Option
                      key={`passport_${currentPassportId}_${idx}_select_${itemPassport}`}
                      value={itemPassport}
                      disabled={disableMainFields || (passports && passports.includes(itemPassport))}
                    >
                      <PopoverEllipsis
                        content={item.name}
                        placement='left'
                      >
                        {item.name}
                      </PopoverEllipsis>
                    </Select.Option>
                  );
                })
              }
            </Select>
          </Form.Item>
          {
            currentPassportId
              ? (
                <>
                  <Popconfirm
                    key={`passport_${currentPassportId}_confirm`}
                    title={t('PASSPORT_TASKS_CREATE_FIELD_DEVICE_CONFIRM')}
                    onConfirm={
                      () => this.onChangeDevice(
                        undefined,
                        idx,
                        currentPassportId
                      )
                    }
                    okText={t('YES')}
                    cancelText={t('NO')}
                    disabled={currentPassportsListLength <= 1}
                  >
                    <Button
                      key={`passport_${currentPassportId}_confirm_button`}
                      size='small'
                      icon={<DeleteOutlined />}
                      className={styles.deletePassport}
                      disabled={disableMainFields || currentPassportsListLength <= 1}
                      title={t('PASSPORT_TASKS_CREATE_FIELD_DEVICE_DEL')}
                    />
                  </Popconfirm>
                  <Form.Item
                    label={t('PASSPORT_TASKS_CREATE_FIELD_INITIAL_BLANKS')}
                    key={`blanks_${currentPassportId}`}
                    name={blanksFieldName}
                    initialValue={selectedBlanksIds}
                    className={styles.itemBlank}
                    disabled={disableMainFields}
                  >
                    <Select
                      key={`blanks_${currentPassportId}_select`}
                      size='small'
                      mode='multiple'
                      disabled={disableMainFields || currentPassportsList[currentPassportId] === undefined}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      placeholder={t('PASSPORT_TASKS_CREATE_FIELD_INITIAL_BLANK_SELECTED')}
                      autoClearSearchValue={true}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      labelInValue={true}
                      showSearch={false}
                    >
                      {this.renderBlanksListOptions(blanksList[currentPassportId], blanksFieldName.concat('_select'), disableMainFields)}
                    </Select>
                  </Form.Item>
                  <Upload
                    {...uploadProps}
                    multiple={true}
                    disabled={disableMainFields || disableUploadNotDigitalBlank}
                  >
                    <Button
                      key={`blanks_${currentPassportId}_upload_ndb_blank`}
                      size='small'
                      icon={<UploadOutlined />}
                      className={styles.uploadNotDigitalBlank}
                      disabled={disableMainFields || disableUploadNotDigitalBlank}
                      title={t('PASSPORT_TASKS_CREATE_FIELD_UPLOAD_NOT_DIGITAL_BLANK')}
                    />
                  </Upload>
                  {
                    fileList
                    && fileList[currentPassportId]
                    && fileList[currentPassportId].length > 0
                      ? (
                        <>
                          <Button
                            key={`blanks_${currentPassportId}_save_ndb_blank`}
                            size='small'
                            onClick={() => this.onUploadNotDigitalBlank(currentPassportId, blanksFieldName)}
                            className={styles.saveNotDigitalBlank}
                            disabled={
                              disableMainFields
                              || disableSaveNotDigitalBlank
                              || isUploading[currentPassportId]
                            }
                            loading={isUploading[currentPassportId]}
                          >
                            {t('PASSPORT_TASKS_CREATE_FIELD_SAVE_NOT_DIGITAL_BLANK')}
                          </Button>
                          <Button
                            key={`blanks_${currentPassportId}_upload_ndb_blank`}
                            size='small'
                            onClick={() => (
                              this.setState((state) => ({
                                isUploading: {
                                  ...state.isUploading,
                                  [currentPassportId]: false,
                                },
                                fileList: {
                                  ...fileList,
                                  [currentPassportId]: [],
                                },
                              }))
                            )}
                            className={styles.saveNotDigitalBlank}
                            disabled={
                              disableMainFields
                              || disableUploadNotDigitalBlank
                              || isUploading[currentPassportId]
                            }
                          >
                            {t('CANCEL')}
                          </Button>
                        </>
                      )
                      : ''
                  }
                </>
              )
              : (
                <Button
                  key={`passport_${idx}_confirm_button`}
                  size='small'
                  icon={<DeleteOutlined />}
                  className={styles.deletePassport}
                  title={t('PASSPORT_TASKS_CREATE_FIELD_DEVICE_DEL')}
                  onClick={() => this.setState({ isAddPassport: false })}
                  disabled={disableMainFields}
                />
              )
          }
          {
            !isCurrentPassport && selectedPassportsCount && !disableMainFields
              ? (
                <div>
                  <Checkbox
                    checked={onlySelected === true}
                    onChange={() => this.changeOnlySelected(!onlySelected)}
                  >
                    {t('PASSPORT_DOCS_TABLE_UPLOADS_ONLY_SELECTED')}
                  </Checkbox>
                </div>
              )
              : ''
          }
          <hr />
        </div>
      )
      : (
        <div key={`div_passport_loading_${currentPassportId}_${idx}`} className={styles.itemPassport}>
          <div className={styles.itemPassportLoading}> </div>
          <hr />
        </div>
      );
  }

  renderButtonAddPassport = (highlight, disableMainFields) => {
    const { isAddPassport, addButtonNumber } = this.state;

    return isAddPassport
      ? this.renderPassportsAndBlanks(
        highlight,
        [],
        null,
        'add_'.concat(addButtonNumber),
        disableMainFields
      )
      : (
        <>
          <Button
            type='button'
            className={styles.addPassport}
            key='addPassport'
            onClick={() => this.addPassport()}
            disabled={disableMainFields}
          >
            Добавить паспорт
          </Button>
          <hr />
        </>
      );
  }

  handleCheckboxGroupChange = (checkedValues) => {
    const { onChangeChekboxGroupValues } = this.props;
    onChangeChekboxGroupValues(checkedValues);
  };

  handleNetworkModelChange = (e) => {
    this.setState({
      networkModel: e.target.value,
      selectedPassportBmmsName: null,
      selectedModelBmmsName: null,
      selectedModelRmmsName: null,
      categoryMmnId: '',
    });
  };

  handleClickDeleteModel = (id) => {
    const { updateMetaMmnData, metaMmnData } = this.props;
    const updatedMetaMmnData = metaMmnData?.filter(
      (item) => item.modelId !== id
    );
    updateMetaMmnData(updatedMetaMmnData);
  };

  filterNetworkModel = (value, option) => {
    const valueParsed = value.toLowerCase().trim().replace(/\s/g, '');
    const optionNameParsed = option.value
      .toLowerCase()
      .trim()
      .replaceAll(' ', '');

    const result = optionNameParsed.includes(valueParsed);
    return result;
  };

  handleChangeUploadFile = async (info) => {
    const { updateInstructionsNewFileList } = this.props;

    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);

    updateInstructionsNewFileList(newFileList);
  };

  handleChangeRemoveFile = () => {
    const {
      updateInstructionsDeletedFileList,
      updateInstructionsCurrentFileList,
      instructionsCurrentFileList,
    } = this.props;
    updateInstructionsDeletedFileList(instructionsCurrentFileList);
    updateInstructionsCurrentFileList([]);
  };

  beforeUploadFile = () => {
    return false;
  };

  validateFileExt = async (rule, value) => {
    const { t, settings } = this.props;

    if (!value?.fileList?.length > 0) return;

    validateFile(
      t,
      value.file,
      allowedExtensions,
      settings.MAX_UPLOAD_FILE_SIZE
    );
  };

  render() {
    const {
      t,
      selectedTask,
      form,
      blanksList,
      passportId,
      passports,
      mode,
      isBlocked,
      uploadElement,
      selectedCheckboxValues,
      metaMmnData,
      instructionsNewFileList,
      instructionsCurrentFileList,
      isLocalStorageLoading,
      isLocalStorageError,
      isLocalStorageEmpty,
      uploadErrorComponent,
      uploadLoadingComponent,
      getTaskDocumentName,
      onChangeTaskDocumentNameValue,
      currentTaskDocumentNameValue
    } = this.props;
    const {
      taskType,
      currentPassportsList,
      protectionDeviceStatus,
      networkModel,
      passportsBmmsData,
      categoryMmnId,
      modelsRmmsData,
      modelsBmmsData,
      selectedModelRmmsId,
      selectedModelBmmsId,
      selectedPassportBmmsName,
      selectedModelBmmsName,
      selectedModelRmmsName,
      isLoading
    } = this.state;
    const highlight = !!selectedTask.id;

    const otherPassports = passports.filter((x) => x !== passportId);

    const disableMainFields = mode === TASK_MODE_AGREEMENT;

    const blankListIsEmpty = blanksList && blanksList[passportId] && blanksList[passportId].length === 0;
    const blankListIsNotEmpty = blanksList && blanksList[passportId] && blanksList[passportId].length > 0;

    return (
      <>
        <FormItem
          highlight={highlight}
          id='name'
          label={t('PASSPORT_TASKS_CREATE_FIELD_NAME')}
          form={form}
          initialValue={selectedTask.name}
          isReadOnly={disableMainFields}
          rules={
            disableMainFields
              ? []
              : [
                  {
                    required: true,
                    message: t('PASSPORT_TASKS_CREATE_REQUIRED_FIELD'),
                  },
                ]
          }
        />
        <FormItem
          highlight={highlight}
          id='reason'
          label={t('PASSPORT_TASKS_CREATE_FIELD_REASON')}
          form={form}
          initialValue={selectedTask.reason}
          isReadOnly={disableMainFields}
          rules={
            disableMainFields
              ? []
              : [
                  {
                    required: true,
                    message: t('PASSPORT_TASKS_CREATE_REQUIRED_FIELD'),
                  },
                ]
          }
          FormItemType={INPUT_TYPE_TEXTAREA}
          rows={6}
        />
        {mode === TASK_MODE_CREATE && (
          <Form.Item name="status" disabled={disableMainFields}>
            <Radio.Group
              disabled={!!selectedTask.status}
              onChange={this.handleTaskTypeChange}
            >
              <Radio value={STATUS_NEW} disabled={blankListIsEmpty}>
                {t('FOR_CALC')}
              </Radio>
              <Radio value={STATUS_PLANNED}>{t('PLANNED')}</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {taskType === STATUS_PLANNED && (
          <FormItem
            highlight={highlight}
            id='date_planned_start'
            label='Дата выдачи:'
            placeholder={t('DATE_START')}
            inputStyle={{ width: '100%' }}
            form={form}
            initialValue={selectedTask.date_planned_start && moment(selectedTask.date_planned_start)}
            isReadOnly={disableMainFields}
            rules={
              disableMainFields
                ? []
                : [
                    {
                      required: true,
                      message: t('PASSPORT_TASKS_CREATE_REQUIRED_FIELD'),
                    },
                  ]
            }
            FormItemType={INPUT_TYPE_DATEPICKER}
            locale={locale}
            disabledDate={(current) => this.disabledDate(current, 'date_planned_start')}
            getPopupContainer={(trigger) => trigger}
            onPanelChange={(value, mode) => this.setState({ datePickerMode: mode })}
          />
        )}
        <div className={styles['plannedDataContainer']}>
          <div style={{ width: 215 }}>
            <FormItem
              highlight={highlight}
              id='date_planned_completion'
              label='Срок реализации:'
              placeholder={t('DATE_END')}
              inputStyle={{ width: '100%' }}
              form={form}
              initialValue={selectedTask.date_planned_completion && moment(selectedTask.date_planned_completion)}
              isReadOnly={disableMainFields}
              rules={
                disableMainFields
                  ? []
                  : [
                      {
                        required: true,
                        message: t('PASSPORT_TASKS_CREATE_REQUIRED_FIELD'),
                      },
                    ]
              }
              FormItemType={INPUT_TYPE_DATEPICKER}
              locale={locale}
              disabledDate={(current) => this.disabledDate(current, 'date_planned_completion')}
              getPopupContainer={(trigger) => trigger}
              onPanelChange={(value, mode) => this.setState({ datePickerMode: mode })}
            />
          </div>
          <div style={{ width: '100%', paddingTop: 32 }}>
            <FormItem
              highlight={highlight}
              id='timeframe'
              placeholder={'Событие'}
              maxLength={300}
              form={form}
              initialValue={selectedTask.timeframe}
              isReadOnly={disableMainFields}
            />
          </div>
        </div>
        {taskType === STATUS_NEW && blanksList && blanksList[passportId] && blanksList[passportId].length > 0 && (
          <FormItem
            highlight={highlight}
            id='source_blank'
            label={t('PASSPORT_TASKS_CREATE_FIELD_INITIAL_BLANK')}
            form={form}
            initialValue={selectedTask.source_blank && selectedTask.source_blank.id}
            isReadOnly={disableMainFields}
            rules={
              disableMainFields
                ? []
                : [
                    {
                      required: taskType !== STATUS_PLANNED,
                      message: t('PASSPORT_TASKS_CREATE_REQUIRED_FIELD'),
                    },
                  ]
            }
            FormItemType={INPUT_TYPE_SELECT}
            getPopupContainer={(trigger) => trigger}
          >
            {this.renderBlanksListOptions(blanksList[passportId], 'source_blank_select', disableMainFields)}
          </FormItem>
        )}
        {mode === TASK_MODE_EDIT &&
          taskType === STATUS_PLANNED &&
          !blankListIsEmpty &&
          !blankListIsNotEmpty && (
            <div className={styles.mt}>
              Загрузка паспорта...
              <Spin />
            </div>
          )}
        {taskType === STATUS_PLANNED && (blankListIsEmpty || blankListIsNotEmpty) && (
          <>
            <hr />
            {isBlocked ? (
              <div className={styles.itemPassport}>
                <div className={styles.itemPassportLoading}>
                  <Spin />
                </div>
                <hr />
              </div>
            ) : currentPassportsList &&
            currentPassportsList[passportId] ? (
              this.renderPassportsAndBlanks(
                highlight,
                blanksList[passportId],
                passportId,
                0,
                disableMainFields
              )
            ) : (
              ''
            )}
            {otherPassports && otherPassports.length
              ? otherPassports.map((otherPassportId, idx) =>
                this.renderPassportsAndBlanks(
                  highlight,
                  blanksList[otherPassportId],
                  otherPassportId,
                  idx + 1,
                  disableMainFields
                )
              )
              : ''}
            {this.renderButtonAddPassport(
              highlight,
              disableMainFields
            )}
          </>
        )}
        <div className={cn(styles.title, styles.mt)}>{t('PASSPORT_TASKS_CREATE_TASK_DOCUMENT')}:</div>
        {uploadElement}
        <FormItem
          highlight={highlight}
          id="task_document"
          label="Название документа"
          form={form}
          value={currentTaskDocumentNameValue}
          taskDocumentName={getTaskDocumentName}
          FormItemType={INPUT_TYPE_TEXTAREA}
          isLoading={isLoading}
          onChange={onChangeTaskDocumentNameValue}
          rules={
            getTaskDocumentName?.length > 0
              ? [
                {
                  required: true,
                  message: t(
                    'PASSPORT_TASKS_CREATE_REQUIRED_FIELD'
                  ),
                },
              ]
              : [{ required: false }]
          }
        />
        <div className={cn(styles.title, styles.mt)}>Используемые модели сети:</div>
        <div className={cn(styles.wrapper, styles.mt)}>
          <div className={styles.wrapper}>
            <div className={styles.typeModel}>Тип модели:</div>
            <Form.Item name="network_model">
              <Radio.Group
                onChange={this.handleNetworkModelChange}
                value={this.state.network_model}
                disabled={disableMainFields}
              >
                <Radio value="bmms">БММС</Radio>
                <Radio value="rmms">РММС</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={cn(styles.wrapper, styles.passportWrapper)}>
            <Form.Item label="Паспорт:">
              <Select
                value={selectedPassportBmmsName}
                size="small"
                disabled={networkModel === 'rmms'}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode
                }
                placeholder="Выберите паспорт БММС"
                defaultActiveFirstOption={false}
                filterOption={this.filterNetworkModel}
                listItemHeight={10}
                showSearch={true}
                onChange={(value, option) =>
                  this.onChangePassportBmms(value, option)
                }
                onDropdownVisibleChange={(x) =>
                  x && this.getPassportsBmmsData()
                }
                disabled={disableMainFields}
              >
                {passportsBmmsData.map((item) => {
                  return (
                    <Select.Option
                      key={item.id}
                      value={`${item.dispatcher_center.name} / ${item.program_complex.name}`}
                      disabled={disableMainFields}
                    >
                      <PopoverEllipsis
                        content={`${item.dispatcher_center.name} / ${item.program_complex.name}`}
                        placement="left"
                      >
                        {`${item.dispatcher_center.name} / ${item.program_complex.name}`}
                      </PopoverEllipsis>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.inputModel}>
            <Form.Item label="Модель:">
              <Select
                value={
                  networkModel === 'rmms'
                    ? selectedModelRmmsName
                    : selectedModelBmmsName
                }
                size="small"
                disabled={
                  networkModel === 'bmms' &&
                  categoryMmnId.length === 0 || disableMainFields
                }
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode
                }
                placeholder={
                  networkModel === 'rmms'
                    ? 'Выберите модель ММС'
                    : 'Выберите модель БММС'
                }
                defaultActiveFirstOption={false}
                filterOption={this.filterNetworkModel}
                listItemHeight={10}
                showSearch={true}
                onChange={(value, option) =>
                  this.onChangeModel(value, option)
                }
                onDropdownVisibleChange={(x) =>
                  x && this.getModelsData(networkModel)
                }

              >
                {(networkModel === 'rmms'
                    ? modelsRmmsData
                    : modelsBmmsData
                ).map((item) => (
                  <Select.Option
                    key={item.meta_mmn_id}
                    value={item.name}
                    disabled={metaMmnData?.some(
                      (obj) =>
                        obj.modelId === item.meta_mmn_id || disableMainFields
                    )}
                  >
                    <PopoverEllipsis
                      content={item.name}
                      placement="left"
                    >
                      {item.name}
                    </PopoverEllipsis>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.onClickAttachModel(
                networkModel === 'rmms'
                  ? selectedModelRmmsId
                  : selectedModelBmmsId
              )
            }
            disabled={
              (networkModel === 'rmms' &&
                selectedModelRmmsName === null) ||
              (networkModel === 'bmms' &&
                (selectedPassportBmmsName === null ||
                  selectedModelBmmsName === null)) || disableMainFields
            }
          >
            Прикрепить
          </Button>
        </div>
        <div className={styles.attachContainer}>
          {metaMmnData?.map((item) => (
            <div key={item.modelId} className={styles.attachWrapper}>
              <div>{item.name}</div>
              <Tooltip title="Удалить">
                <Button
                  type="text"
                  shape="circle"
                  icon={
                    <DeleteOutlined style={{ color: 'red' }} />
                  }
                  onClick={() =>
                    this.handleClickDeleteModel(item.modelId)
                  }
                  disabled={disableMainFields}
                />
              </Tooltip>
            </div>
          ))}
        </div>
        <div className={styles.container}>
          <div className={styles.title}>
            Поля заполняются, если задание не сформировано:
          </div>
          <div className={styles.wrapper}>
            <div className={cn(styles.title, styles.status)}>
              Статус устройства:
            </div>
            <Form.Item name="protection_device_status">
              <Radio.Group
                onChange={this.handleProtectionDeviceStatusChange}
                disabled={disableMainFields}
              >
                <Radio value="IN_SERVICE">
                  Находящееся в эксплуатации
                </Radio>
                <Radio value="NEW">Вновь вводимое</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          {protectionDeviceStatus === 'NEW' && (
            <>
              <div className={styles.title}>
                Реквизиты согласованных диспетчерским центром томов
                рабочей документации:
              </div>
              <FormItem
                id="documentation_requisites"
                highlight={highlight}
                form={form}
                initialValue={selectedTask.documentation_requisites}
                FormItemType={INPUT_TYPE_TEXTAREA}
                rows={6}
                placeholder="Введите реквизиты согласованных диспетчерским центром томов рабочей документации"
                rules={[
                  {
                    max: 2048,
                    message: "Убедитесь, что это значение содержит не более 2048 символов."
                  }
                ]}
                isReadOnly={disableMainFields}
              />
            </>
          )}
          <Popover
            overlayStyle={{ width: 500 }}
            content={ADDITIONAL_INSTRUCTIONS}
          >
            <div
              className={styles.title}
              style={{ width: 185, cursor: 'pointer' }}
            >
              Дополнительные указания:
            </div>
          </Popover>
          {isLocalStorageLoading && uploadLoadingComponent}
          {isLocalStorageError && uploadErrorComponent}
          {!isLocalStorageLoading &&
            !isLocalStorageError &&
            !isLocalStorageEmpty && (
              <>
                <span>Загруженный файл:</span>
                {instructionsCurrentFileList.length === 0 ? (
                  <>
                    <Form.Item
                      name="additional_external_link"
                      rules={[{ validator: this.validateFileExt }]}
                    >
                      <Upload
                        className={styles.upload}
                        fileList={instructionsNewFileList}
                        onChange={this.handleChangeUploadFile}
                        beforeUpload={this.beforeUploadFile}
                        multiple={true}
                        disabled={disableMainFields}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={disableMainFields}
                        >
                          Загрузить
                        </Button>
                      </Upload>
                    </Form.Item>
                  </>
                ) : (
                  <div className={styles.uploadedFileWrapper}>
                    <div className={styles.uploadedFileName}>
                      <PaperClipOutlined />
                      <div>
                        {
                          instructionsCurrentFileList[0]
                            .name
                        }
                      </div>
                    </div>
                    <Tooltip title={disableMainFields ? '' : 'Удалить'}>
                      <Button
                        type="text"
                        shape="circle"
                        icon={
                          <DeleteOutlined
                            style={{ color: disableMainFields ? 'gray' : 'red' }}
                            disabled={disableMainFields}
                          />
                        }
                        onClick={
                          this.handleChangeRemoveFile
                        }
                        disabled={disableMainFields}
                      />
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          <div className={styles.title}>Состав бланка задания:</div>
          <div className={styles.wrapper}>
            <div className={styles.form}>Форма:</div>
            <Form.Item name="form">
              <Radio.Group disabled={disableMainFields}>
                <Radio value="SHORT">Сокращенная</Radio>
                <Radio value="FULL">
                  <Popover content='Для заданий, направляемых в Филиалы ПАО "Федеральная сетевая компания - Россети"'>
                    Полная
                  </Popover>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item name="checkbox_group">
            <Checkbox.Group
              className={styles.checkboxGroup}
              options={OPTIONS_CHECKBOX_GROUP.map((option) => ({
                label: (
                  <Popover
                    overlayStyle={{ width: 500 }}
                    content={getPopoverOptionValue(
                      option.value
                    )}
                  >
                    {option.label}
                  </Popover>
                ),
                value: option.value,
              }))}
              onChange={this.handleCheckboxGroupChange}
              value={selectedCheckboxValues}
              disabled={disableMainFields}
            />
          </Form.Item>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
  bucketList: state.bucket.bucketData,
  bucketCount: state.bucket.count,
  settings: state.settings.data
});

const mapDispatchToProps = {
  getSelectedPassports: getBucket,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    TaskForm
  )
);
