import React from 'react';
import { connect } from 'react-redux';
import {
  Input, Button, DatePicker,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import {
  getActionsLogs, getMessagesLogs,
  setDateRage, setFiltersActions, setFiltersMessages,
  getLimit, setLimit, setPage,
  clearJournalState,
} from '@state/journal/actions';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { CustomCard, PopoverEllipsis } from '@ui';
import { TABLE_SIZE } from '@globalConstants';
import { textHighlighter } from '@globalHelpers';

import Details from './Details';
import CancelChanges from './cancelChanges';

import {
  TAB_ACTIONS,
  TAB_MESSAGES,
  MODEL_DOCUMENT,
  MODEL_TEMPLATE,
  MODEL_SETPOINT_VALUE,
  MODEL_SETPOINT,
  MODEL_BLANK,
  MODEL_PRODUCER,
  MODEL_PROTECTION_DEVICE,
  MODEL_TASK,
  MODEL_OWNER,
  MODEL_VERSION,
  MODEL_IMPLEMENTATION_TYPE,
  MODEL_LETTER,
  MODEL_TRADE_DEVICE,
  MODEL_TAG,
  MODEL_DOCUMENT_STATUS,
  MODEL_DOCUMENT_TYPE,
  MODEL_EVENT,
  MODEL_PROTECTION_DEVICE_COMPLEX,
  MODEL_STORAGE,
  MODEL_SEARCH_REQUEST,
  MODEL_TERM,
  MODEL_DESCRIPTION,
  // MODEL_PASSPORT,
  // MODEL_BLANK_STATUS,
} from './constants';

import styles from './styles.module.less';

const allowedRecoverRecordModels = [
  MODEL_BLANK,
  MODEL_TASK,
  MODEL_DOCUMENT,
  MODEL_OWNER,
  MODEL_VERSION,
  MODEL_IMPLEMENTATION_TYPE,
  MODEL_LETTER,
  MODEL_PRODUCER,
  MODEL_TRADE_DEVICE,
  MODEL_TAG,
  MODEL_DOCUMENT_STATUS,
  MODEL_DOCUMENT_TYPE,
  MODEL_EVENT,
  MODEL_PROTECTION_DEVICE_COMPLEX,
  // MODEL_TEMPLATE,
];

class JournalComponent extends React.Component {
  tableName = {
    TAB_ACTIONS: 'journal_tab__actions',
    TAB_MESSAGES: 'journal_tab__messages',
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: TAB_ACTIONS,
    };
  }

  componentDidMount() {
    const {
      deviceId, getActions, getLimitByTab, tableName,
    } = this.props;

    if (tableName) {
      this.tableName[TAB_ACTIONS] = tableName;
    }
    getLimitByTab(this.tableName[TAB_ACTIONS], TAB_ACTIONS);
    getLimitByTab(this.tableName[TAB_MESSAGES], TAB_MESSAGES);
    getActions(deviceId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      deviceId, getActions, getMessages,
      activeTab, dateStart, dateEnd, actionFilters, messageFilters,
      limit, page,
    } = this.props;

    if (prevProps.activeTab !== activeTab) {
      this.changeTab(activeTab);
    }

    if (dateStart !== prevProps.dateStart
      || dateEnd !== prevProps.dateEnd) {
      getActions(deviceId);
    }

    if (prevProps.actionFilters !== actionFilters) {
      getActions(deviceId);
    }

    if (prevProps.messageFilters !== messageFilters) {
      getMessages();
    }

    if (prevProps.limit[TAB_ACTIONS] !== limit[TAB_ACTIONS]) {
      this.changeLimit();
    }

    if (prevProps.page[TAB_ACTIONS] !== page[TAB_ACTIONS]) {
      getActions(deviceId);
    }
  };

  changeLimit = async () => {
    const { getActions, deviceId } = this.props;

    await getActions(deviceId);

    const { page } = this.props;

    if (page[TAB_ACTIONS] !== 1) this.changePage(1, TAB_ACTIONS);
  }

  componentWillUnmount() {
    const { clearJournalSettings } = this.props;

    clearJournalSettings();
  }

  getFilter = (value) => {
    const { t } = this.props;
    const translation = t(`JOURNAL_${value.toUpperCase()}`);
    return { text: translation, value: value };
  };

  getColumnCheckBoxProps = (dataIndex, filters) => {
    return {
      filters: filters,
      onFilter: (value, record) => record[dataIndex].indexOf(value) === 0,
    };
  };

  getColumnSearchProps = (dataIndex) => {
    const { t } = this.props;

    return {
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={t(`JOURNAL_${dataIndex.toUpperCase()}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            {t('JOURNAL_SEARCH')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            {t('JOURNAL_RESET')}
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => {
        return (record[dataIndex] || '')
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) => <span className={styles.tableText}>{text}</span>,
    };
  };

  getActionColumns = () => {
    const { t, deviceId, actionFilters } = this.props;

    return [
      {
        title: (
          <div>
            <DatePicker.RangePicker
              size='small'
              format='DD.MM.YYYY'
              onChange={this.handleChangeDateFilter}
              value={(actionFilters['date'] || []).map((x) => x && moment(x))}
            />
          </div>
        ),
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '20%',
        minWidth: 230,
        resizable: false,
        align: 'center',
        render: (date) => moment(date).format('HH:mm:ss DD.MM.YYYY'),
      },
      {
        title: t('JOURNAL_ACTION'),
        dataIndex: 'action',
        key: 'action',
        width: '15%',
        ...this.getColumnCheckBoxProps(
          'action',
          ['CHANGED', 'CREATED', 'DELETED', 'REVERTED'].map((x) => this.getFilter(x))
        ),
        filteredValue: actionFilters['action'],
        render: (text, record) => {
          return (
            <PopoverEllipsis content={text ? t(`JOURNAL_${text.toUpperCase()}`) : ''}>
              {text ? t(`JOURNAL_${text.toUpperCase()}`) : ''}
              {' '}
              {
                record
                && record.action === 'DELETED'
                && allowedRecoverRecordModels.includes(record.model_name)
                && (
                  <CancelChanges
                    record={record}
                    deviceId={deviceId}
                  />
                )
              }
            </PopoverEllipsis>
          );
        },
      },
      {
        title: t('JOURNAL_NAME'),
        dataIndex: 'model_name',
        key: 'model_name',
        width: '20%',
        ...this.getColumnCheckBoxProps(
          'model_name',
          deviceId
            ? (
              [
                MODEL_PROTECTION_DEVICE,
                MODEL_DOCUMENT,
                MODEL_BLANK,
                MODEL_SETPOINT_VALUE,
                MODEL_TASK,
                MODEL_EVENT,

                // MODEL_TEMPLATE,
                // MODEL_PASSPORT,
                // MODEL_SETPOINT,
                // MODEL_BLANK_STATUS,
                // MODEL_PRODUCER,
                // MODEL_DOCUMENT_TYPE,
                // MODEL_PROTECTION_DEVICE_COMPLEX,

                // MODEL_OWNER,
                // MODEL_IMPLEMENTATION_TYPE,
                // MODEL_LETTER,
                // MODEL_TRADE_DEVICE,
                // MODEL_VERSION,
                // MODEL_TAG,
                // MODEL_DOCUMENT_STATUS,
              ].map((x) => this.getFilter(x))
            )
            : (
              [
                MODEL_PROTECTION_DEVICE,
                MODEL_DOCUMENT,
                MODEL_BLANK,
                MODEL_SETPOINT_VALUE,
                MODEL_TASK,
                MODEL_EVENT,
                MODEL_TEMPLATE,
                MODEL_SETPOINT,
                MODEL_PRODUCER,
                MODEL_OWNER,
                MODEL_IMPLEMENTATION_TYPE,
                MODEL_TRADE_DEVICE,
                MODEL_VERSION,
                MODEL_TAG,
                MODEL_DOCUMENT_STATUS,
                MODEL_DOCUMENT_TYPE,
                MODEL_LETTER,
                MODEL_PROTECTION_DEVICE_COMPLEX,
                MODEL_STORAGE,
                MODEL_SEARCH_REQUEST,
                MODEL_TERM,
                MODEL_DESCRIPTION,

                // MODEL_PASSPORT,
                // MODEL_BLANK_STATUS,
              ].map((x) => this.getFilter(x))
            )
        ),
        filteredValue: actionFilters['model_name'],
        render: (text) => (text ? t(`JOURNAL_${text.toUpperCase()}`) : ''),
      },
      {
        title: t('JOURNAL_AUTHOR'),
        dataIndex: 'user_name',
        key: 'user_name',
        width: '15%',
        ...this.getColumnSearchProps('user_name'),
        filteredValue: actionFilters['user_name'],
        render: (text) => (
          text
            ? textHighlighter(actionFilters.user_name && actionFilters.user_name[0], text)
            : ''
        ),
      },
      {
        title: t('JOURNAL_DETAILS'),
        dataIndex: 'changed_fields',
        key: 'changed_fields',
        width: '30%',
        render: (text, record, index) => (
          <Details key={`journal_details_row_${deviceId}_${record?.instance_name || Math.random()}_${index || Math.random()}`} record={record} deviceId={deviceId} />
        ),
      },
    ];
  };

  getMessageColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('JOURNAL_DATE'),
        dataIndex: 'dateTime',
        key: 'dateTime',
        width: '20%',
        align: 'center',
        render: (date) => moment(date).format('HH:mm:ss DD.MM.YYYY'),
      },
      {
        title: t('JOURNAL_SYSTEM'),
        dataIndex: 'system',
        key: 'system',
        width: '20%',
      },
      {
        title: t('JOURNAL_LOG_LEVEL'),
        dataIndex: 'logLevel',
        key: 'logLevel',
        width: '20%',
      },
      {
        title: t('JOURNAL_MESSAGE_TITLE'),
        dataIndex: 'title',
        key: 'title',
        width: '20%',
      },
      {
        title: t('JOURNAL_MESSAGE_DESCR'),
        dataIndex: 'description',
        key: 'description',
        width: '20%',
      },
    ];
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleChangeDateFilter = (dates) => {
    const { handleDateRange, actionFilters, handleActionFilters } = this.props;

    handleActionFilters({
      ...actionFilters,
      date: (dates || []).map((x) => x && x.toISOString()),
    });

    if (dates) {
      handleDateRange(dates[0], dates[1]);
    } else {
      handleDateRange(undefined, undefined);
    }
  };

  handleTableChange = (pagination, filters) => {
    const { handleActionFilters, handleMessageFilters } = this.props;
    const { activeTab } = this.state;
    if (activeTab === TAB_ACTIONS) handleActionFilters(filters);
    if (activeTab === TAB_MESSAGES) handleMessageFilters(filters);
  };

  changeTab = (activeTab) => {
    const { deviceId, getActions, getMessages } = this.props;

    this.setState({ activeTab });

    if (activeTab === TAB_ACTIONS) {
      getActions(deviceId);
    }
    if (activeTab === TAB_MESSAGES) {
      getMessages();
    }
  };

  changePage = (newPage, activeTab) => {
    const { page, pageChange } = this.props;

    // changePage вызывается при изменении числа эл-ов, нужна проверка
    if (page[activeTab] !== newPage) {
      pageChange(newPage, activeTab);
    }
  };

  onShowSizeChange = (current, pageSize, tableName, activeTab) => {
    const { ShowSizeChange } = this.props;

    ShowSizeChange(pageSize, tableName, activeTab);
  };

  render() {
    const {
      offsetTop, actionsLogs, messagesLogs, isActionsLoading, isMessagesLoading,
      count, limit, page, passportMode = false
    } = this.props;
    const { activeTab } = this.state;

    const actionColumns = this.getActionColumns();
    const messageColumns = this.getMessageColumns();

    return (
      <CustomCard style={{ maxHeight: `calc(100vh - ${offsetTop}px)` }}>
        <div className={styles.journal}>
          <FixedYCommonTable
            tableName={this.tableName[activeTab]}
            size={TABLE_SIZE}
            rowKey='key'
            columns={activeTab === TAB_ACTIONS ? actionColumns : messageColumns}
            dataSource={activeTab === TAB_ACTIONS ? actionsLogs : messagesLogs}
            loading={activeTab === TAB_ACTIONS ? isActionsLoading : isMessagesLoading}
            totalSize={count && count[activeTab]}
            className={styles[`table${passportMode ? '_forPassport' : '_common'}`]}
            pagination={{
              onChange: (p) => this.changePage(p, activeTab),
              total: count && count[activeTab],
              pageSize: limit[activeTab],
              current: page[activeTab],
            }}
            onShowSizeChange={(current, pageSize) => this.onShowSizeChange(
              current,
              pageSize,
              this.tableName[activeTab],
              activeTab
            )}
            onChange={this.handleTableChange}
          />
        </div>
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  actionsLogs: state.journal.actionsLogs,
  messagesLogs: state.journal.messagesLogs,
  isActionsLoading: state.journal.isActionsLoading,
  isMessagesLoading: state.journal.isMessagesLoading,
  dateStart: state.journal.start,
  dateEnd: state.journal.end,
  actionFilters: state.journal.actionFilters,
  messageFilters: state.journal.messageFilters,
  limit: state.journal.limit,
  page: state.journal.page,
  count: state.journal.count,
});
const mapDispatchToProps = {
  getActions: getActionsLogs,
  getMessages: getMessagesLogs,
  handleDateRange: setDateRage,
  handleActionFilters: setFiltersActions,
  handleMessageFilters: setFiltersMessages,
  getLimitByTab: getLimit,
  ShowSizeChange: setLimit,
  pageChange: setPage,
  clearJournalSettings: clearJournalState,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    JournalComponent
  )
);
