import {
  GET_BLANKS,
  CLEAR_BLANKS,
  SET_ACTIVE_BLANK,
  SET_BLANK_VERIFICATION,
  UNSET_ACTIVE_BLANK,
  blanksState,
  blanksActionTypes,
} from './types';

const initialState: blanksState = {
  blanks: [],
  isBlanksLoading: false,
  activeBlank: null,
  count: 0,
};

function blanksReducer(state = initialState, action: blanksActionTypes): blanksState {
  switch (action.type) {
    case `${GET_BLANKS}_START`:
      return {
        ...state,
        isBlanksLoading: true,
      };
    case GET_BLANKS:
      return {
        ...state,
        blanks: action.data.results,
        count: action.data.count,
        isBlanksLoading: false,
      };
    case `${GET_BLANKS}_ERROR`:
      return {
        ...state,
        blanks: [],
        count: 0,
        isBlanksLoading: false,
        activeBlank: null,
      };
    case CLEAR_BLANKS:
      return {
        ...state,
        blanks: [],
        count: 0,
        isBlanksLoading: false,
        activeBlank: null,
      };
    case SET_ACTIVE_BLANK:
      return {
        ...state,
        activeBlank: action.blank,
      };
    case UNSET_ACTIVE_BLANK:
      return {
        ...state,
        activeBlank: null,
      };
    case SET_BLANK_VERIFICATION:
      const { blankId, value } = action;

      const index = state.blanks.findIndex(blank => blank.id === blankId);
      const modifiedBlanks = [ ...state.blanks ];
      modifiedBlanks[index].is_verify = value;
      
      return {
        ...state,
        blanks: modifiedBlanks
      }
    default:
      return state;
  }
}

export default blanksReducer;
