import { SortableFields } from './sorting';

export type SortField = {
  label: string;
  value: SortableFields;
};

export type SortingConfig = {
  fields: SortField[];
  localStorageKeys: {
    [key in 'direction' | 'fieldname']: string;
  };
};

export const sortingConfig: SortingConfig = {
  localStorageKeys: {
    direction: 'sorting_attach_letter_direction',
    fieldname: 'sorting_attach_letter_fieldname',
  },
  fields: [
    {
      label: 'LETTER_ATTACH_TASK_NAME_OPTION',
      value: 'name',
    },
    {
      label: 'LETTER_ATTACH_TASK_DATE_OPTION',
      value: 'date',
    },
    {
      label: 'LETTER_ATTACH_TASK_STATUS_OPTION',
      value: 'status',
    },
  ],
};
