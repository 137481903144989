import { message } from 'antd';
import { openAllDocumentLinks } from '@routes/passport/helpers';
import { EXPORT_MODE_PF, EXPORT_MODE_WORD } from './constants';

const exportBlank = async (blank, component, mode, groupByFunction = false, calcViewMode , staticRec) => {
  const { t, wsState } = component && component.props;

  const fileName = (blank && blank.name) || 'blank';
  const socket = wsState && wsState.instance;
  const isSocketConnected = wsState && wsState.connected;

  if (blank && blank.is_doc) {
    if (blank.document_links) {
      const document = {
        external_links: blank.document_links,
        is_storage: blank.is_storage,
      };
      openAllDocumentLinks(document, component);
    } else {
      message.error(t('DOWNLOADING_FILE_ERROR'));
    }
  } else if (mode === EXPORT_MODE_WORD) {
    if (socket && isSocketConnected) {
      const groupBy = groupByFunction ? 'func_group' : 'group';
      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('SETPOINT_BLANKS_EXPORT')} "${fileName}"`,
        url: `/v1/blanks/${
          blank.id
        }/word/?view_mode=full&group_by=${groupBy}&is_archived=false&calc_view_mode=${
          blank.old_type ? 'primary' : calcViewMode
        }${!blank.old_type && calcViewMode === 'primary' ? `&static_rec=${staticRec}` : ''}`,
        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }));
    }
  } else if (mode === EXPORT_MODE_PF) {
    if (socket && isSocketConnected) {
      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('SETPOINT_BLANKS_EXPORT_PFPROTECTION')} "${fileName}"`,
        url: `/v1/blanks/${blank.id}/export/?file_name=${fileName}.json`,
        accept: 'application/json',
      }));
    }
  }
};

export default exportBlank;
