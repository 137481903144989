const defaultConfig = {
  ajax_request_timeout: 100000,
  main_api_prefix: '/api',
  websocket_prefix: 'ws://localhost:8100',
  logger_prefix: '/logger',
  lang: 'ru',
};

const gitlabBranchName = 'kaiten-16763312';
const gitlabMachineAdress = `${gitlabBranchName.toLowerCase()}.dbrza-qa.intelab.local`

// eslint-disable-next-line
const devConfig = {
  websocket_prefix: 'ws://develop.dbrza-staging.intelab.local:8100',
  main_api_prefix: 'http://develop.dbrza-staging.intelab.local:8000',
  ajax_request_timeout: 100000,
}

// eslint-disable-next-line
const devBranchConfig = {
  websocket_prefix: `ws://${gitlabMachineAdress}:80/ws`,
  main_api_prefix: `http://${gitlabMachineAdress}:80`,
  ajax_request_timeout: 100000,
}

const branches = {
  production: {
    main_api_prefix: '',
    websocket_prefix: `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window?.location?.host}/ws`,
  },
  development: devConfig,
  test: {},
};

const branch = process.env.NODE_ENV;

const config = {
  ...defaultConfig,
  ...branches[branch],
};

export default config;
