import React, { Component } from 'react';
// import { Button, Popconfirm, Popover } from 'antd';
import { Button, Popconfirm } from 'antd';
// import { ExclamationCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { RightCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { STATUS_PLANNED } from './constants';

// import styles from './styles.module.less';

class GetStarted extends Component {
  renderButton = (disabled, onClick) => {
    const { t } = this.props;

    return (
      <Button
        size='small'
        type='primary'
        icon={<RightCircleOutlined />}
        disabled={disabled}
        onClick={onClick || null}
      >
        {t('GET_STARTED')}
      </Button>
    );
  };

  render() {
    const {
      t, isEdit, selectedTask, changeStatus, sidebarIsOpen, loading
    } = this.props;
    const disableGetStarted = !isEdit
      || loading
      || !selectedTask.id
      || sidebarIsOpen
      || (
        selectedTask.status
        && selectedTask.status.current_status.id !== STATUS_PLANNED
        && selectedTask.status.next_status
      );

    let popconfirmMessage;
    // Сюда надо еще проверку на task_document сделать, если он есть и сурсбланков нет, то тоже норм
    // if (selectedTask && (!selectedTask.source_blanks || !selectedTask.source_blanks.length)) {
    //   popconfirmMessage = (
    //     <>
    //       <ExclamationCircleOutlined className={styles.error} />
    //       {' '}
    //       {t('PASSPORT_TASKS_GET_STARTED_NO_BLANK')}
    //     </>
    //   );

    //   return (
    //     <Popover
    //       content={(
    //         popconfirmMessage
    //       )}
    //       trigger={disableGetStarted ? '' : 'click'}
    //       disabled={disableGetStarted}
    //     >
    //       {this.renderButton(disableGetStarted)}
    //     </Popover>
    //   );
    // } else
    if (selectedTask
      && selectedTask.source_blank
      && !selectedTask.source_blank.is_active) {
      popconfirmMessage = t('PASSPORT_TASKS_GET_STARTED_NOT_ACTIVE');

      return (
        <Popconfirm
          title={popconfirmMessage}
          okText="OK"
          cancelText={t('CANCEL')}
          disabled={disableGetStarted}
          onConfirm={changeStatus}
        >
          {this.renderButton(disableGetStarted)}
        </Popconfirm>
      );
    }

    return this.renderButton(disableGetStarted, changeStatus);
  }
}

export default withTranslation()(
  GetStarted
);
