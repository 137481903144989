import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button, DatePicker, Form, Input, Radio, Select, Spin, Switch, Typography,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import { OptionType } from 'antd/lib/select';
import { Location } from 'history';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import Qs from 'qs';

import { DATE_FORMAT } from '@globalConstants';
import { loopTree } from '@globalHelpers';
import api from '@services/api';
import { RootState } from '@state/store';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { CustomCard, CustomSelect, Layout } from '@ui';

import {
  DispatcherCenterShort,
  Function as FunctionTree,
  Owner,
  SubstationTree,
  User,
  VoltageLevel,
} from '@services/api-dts';

import {
  OptionsType,
  queryParams,
  ReportFormatType,
  ReportSettingsParameters,
  ReportSettingsParameterType,
  ReportSettingsParameterValuesOptionsType,
  ReportSettingsParameterValuesType,
  ReportSettingsType,
  ReportSubType,
  ReportType,
  TaskSubType,
} from './types';

import {
  DISPATCHER_CENTER,
  FIELD_TYPE_INPUT,
  FIELD_TYPE_PICKER,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_SELECT_MULTIPLE,
  FIELD_TYPE_SWITCH,
  FIELD_TYPE_TEXTAREA,
  OPTIONS_DATE_RANGE,
  OPTIONS_DC,
  OPTIONS_FUNCTION_GROUPS,
  OPTIONS_OWNERS,
  OPTIONS_SUBSTATIONS,
  OPTIONS_TASK_DC_STATUSES,
  OPTIONS_VOLTAGE_LEVELS,
  OWNER,
  TEMPLATE_FORMAT_DOCX,
  TEMPLATE_FORMAT_HTML,
  TEMPLATE_FORMAT_PDF,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_READY,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_REGISTRY,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_SUMMARY,
  TEMPLATE_GROUP_TYPE_EVENTS,
  TEMPLATE_GROUP_TYPE_FUNCTIONS,
  TEMPLATE_GROUP_TYPE_NONPERFORMANCE,
  TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY,
  TEMPLATE_GROUP_TYPE_SETPOINT_PLANS,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_LIGHTLOAD,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_NONSELECTIVITY,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY,
} from './constants';

import styles from './styles.module.less';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const mapTemplateGroupToRequest = {
  [TEMPLATE_GROUP_TYPE_FUNCTIONS]: '/v1/functions/report',
  [TEMPLATE_GROUP_TYPE_EVENTS]: '/v1/events/report',
  [TEMPLATE_GROUP_TYPE_NONPERFORMANCE]: '/v1/functions/inefficiency',
  [TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY]: '/v1/functions/inefficiency',
  [TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY]: '/v1/setpoint-values/redundancy/',
  [TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_LIGHTLOAD]: '/v1/setpoint-values/lightload/',
  [TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_NONSELECTIVITY]: '/v1/setpoint-values/nonselectivity/',
  [TEMPLATE_GROUP_TYPE_SETPOINT_PLANS]: '/v1/task-dc/report/planned',
  [TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS]: '/v1/task-dc/report/planned',
  [TEMPLATE_GROUP_SUBTYPE_SETPOINT_READY]: '/v1/task-dc/report/completed',
  [TEMPLATE_GROUP_SUBTYPE_SETPOINT_REGISTRY]: '/v1/task-dc/report/registry',
  [TEMPLATE_GROUP_SUBTYPE_SETPOINT_SUMMARY]: '/v1/task-dc/report/summary',
};
const reportTemplates = [
  { id: TEMPLATE_GROUP_TYPE_FUNCTIONS, name: 'FUNCTIONS' },
  { id: TEMPLATE_GROUP_TYPE_EVENTS, name: 'EVENTS' },
  { id: TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY, name: 'PROTECTION_DEVICES_INEFFICIENCY' },
  { id: TEMPLATE_GROUP_TYPE_NONPERFORMANCE, name: 'NONPERFORMANCE_TERMS' },
  { id: TEMPLATE_GROUP_TYPE_SETPOINT_PLANS, name: 'SETPOINT_PLANS' },
];
const reportFormat = [
  { id: TEMPLATE_FORMAT_HTML, name: 'SELECT_REPORT_FORMAT_HTML' },
  { id: TEMPLATE_FORMAT_DOCX, name: 'SELECT_REPORT_FORMAT_DOCX' },
  { id: TEMPLATE_FORMAT_PDF, name: 'SELECT_REPORT_FORMAT_PDF' },
];
const reportFormatType = [
  { id: TEMPLATE_FORMAT_HTML, name: 'text/html' },
  { id: TEMPLATE_FORMAT_DOCX, name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { id: TEMPLATE_FORMAT_PDF, name: 'application/pdf' },
];
const nonperformanceReports = [
  TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY,
  TEMPLATE_GROUP_TYPE_NONPERFORMANCE,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_LIGHTLOAD,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_NONSELECTIVITY,
];
const nonperformanceTermsReports = [
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_LIGHTLOAD,
  TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_NONSELECTIVITY,
];
const TaskReports = [
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_READY,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_REGISTRY,
  TEMPLATE_GROUP_SUBTYPE_SETPOINT_SUMMARY,
];
const initialReportSettings: ReportSettingsType = {
  [TEMPLATE_GROUP_TYPE_FUNCTIONS]: {
    initial_dc: {
      name: 'initial_dc',
      label: 'INITIAL_DC',
      type: FIELD_TYPE_SELECT,
      options: [],
      optionType: OPTIONS_DC,
      required: true,
    },
    coordination_dc: {
      name: 'coordination_dc',
      label: 'COORDINATION_DC',
      type: FIELD_TYPE_SELECT,
      options: [],
      optionType: OPTIONS_DC,
    },
    function_group: {
      name: 'function_group',
      label: 'FUNCTION_ACCOUNT_GROUP',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_FUNCTION_GROUPS,
    },
    with_revert_roles: {
      name: 'with_revert_roles',
      label: 'REVERT_DC_ROLES',
      type: FIELD_TYPE_SWITCH,
      valuePropName: 'checked',
      disabled: true,
    },
  },
  [TEMPLATE_GROUP_TYPE_EVENTS]: {
    coordination_subject: {
      name: 'coordination_subject',
      label: 'DEVICE.COL_OWNER',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_OWNERS,
    },
    initial_dc: {
      name: 'initial_dc',
      label: 'INITIAL_DC',
      type: FIELD_TYPE_SELECT,
      options: [],
      optionType: OPTIONS_DC,
    },
    voltage_level: {
      name: 'voltage_level',
      label: 'VOLTAGE_LEVEL',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_VOLTAGE_LEVELS,
    },
    substation: {
      name: 'substation',
      label: 'SUBSTATION',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_SUBSTATIONS,
    },
  },
  [TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY]: {
    owner: {
      name: 'owner',
      label: 'OWNER',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_OWNERS,
    },
    substations: {
      name: 'substations',
      label: 'SUBSTATION',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_SUBSTATIONS,
    },
    groups: {
      name: 'groups',
      label: 'FUNCTION_ACCOUNT_GROUP',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_FUNCTION_GROUPS,
    },
  },
  [TEMPLATE_GROUP_TYPE_NONPERFORMANCE]: {
    owner: {
      name: 'owner',
      label: 'OWNER',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_OWNERS,
    },
    group: {
      name: 'group',
      label: 'FUNCTION_ACCOUNT_GROUP',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_FUNCTION_GROUPS,
    },
  },
  [TEMPLATE_GROUP_TYPE_SETPOINT_PLANS]: {
    owner: {
      name: 'owner',
      label: 'OWNER',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_OWNERS,
    },
    group: {
      name: 'group',
      label: 'FUNCTION_ACCOUNT_GROUP',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_FUNCTION_GROUPS,
    },
    [OPTIONS_TASK_DC_STATUSES]: {
      name: 'status',
      label: 'TASK_DC_STATUSES',
      type: FIELD_TYPE_SELECT_MULTIPLE,
      options: [],
      optionType: OPTIONS_TASK_DC_STATUSES,
    },
    [OPTIONS_DATE_RANGE]: {
      name: OPTIONS_DATE_RANGE,
      label: 'DATE',
      type: FIELD_TYPE_PICKER,
      optionType: OPTIONS_DATE_RANGE,
    },
  },
};

interface Props extends WithTranslation, RouteComponentProps {
  user: User;
  socket: WebSocket | null | undefined;
  isSocketConnected: boolean;
  data: {
    callback: () => void;
  };
}

interface State {
  selectedType: ReportType;
  selectedFormat: ReportFormatType;
  selectedSubType: ReportSubType | TaskSubType | null;
  reportSettings: ReportSettingsType;
  dispatcherCenters: DispatcherCenterShort[];
  functionGroups: FunctionTree[];
  voltageLevels: VoltageLevel[];
  substations: SubstationTree[];
  owners: Owner[];
  loading: boolean;
  currentRequester: string;
  taskDcStatuses: DispatcherCenterShort[];
}

class Reports extends Component<Props, State> {
  form = React.createRef<FormInstance>();

  state: State = {
    selectedType: TEMPLATE_GROUP_TYPE_FUNCTIONS,
    selectedFormat: TEMPLATE_FORMAT_HTML,
    selectedSubType: null,
    reportSettings: initialReportSettings,
    dispatcherCenters: [],
    functionGroups: [],
    voltageLevels: [],
    substations: [],
    owners: [],
    loading: false,
    currentRequester: OWNER,
    taskDcStatuses: [],
  };

  componentDidMount() {
    this.setInitialValues();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { selectedType } = this.state;

    if (prevState.selectedType !== selectedType) {
      this.form.current && this.form.current.resetFields();
      this.setState({ currentRequester: OWNER });

      if (selectedType === TEMPLATE_GROUP_TYPE_NONPERFORMANCE) {
        this.setState(
          { selectedSubType: TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY },
          () => this.form.current?.setFieldsValue(
            { nonperformance_term: TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY }
          )
        );
      } else if (selectedType === TEMPLATE_GROUP_TYPE_SETPOINT_PLANS) {
        this.setState(
          { selectedSubType: TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS },
          () => this.form.current?.setFieldsValue(
            { task_type: TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS }
          )
        );
      } else {
        this.setState({ selectedSubType: null });
      }
    }
  }

  onSelectSearch = debounce(
    async (value: string, key: ReportSettingsParameters, optionType: string | undefined) => {
      switch (optionType) {
        case OPTIONS_DC: {
          await this.loadDispatcherCenters({ name: value });

          const { selectedType, dispatcherCenters } = this.state;

          this.setSelectOptions(selectedType, dispatcherCenters, [key]);
          break;
        }
        case OPTIONS_FUNCTION_GROUPS: {
          const selectedIds = (this.form.current && this.form.current.getFieldValue(key)) || [];

          await this.loadFunctionGroups({ name__icontains: value }, selectedIds);

          const { selectedType, functionGroups } = this.state;

          this.setSelectOptions(selectedType, functionGroups, [key]);
          break;
        }
        case OPTIONS_VOLTAGE_LEVELS: {
          const selectedIds = (this.form.current && this.form.current.getFieldValue(key)) || [];

          await this.loadVoltageLevels({ name: value }, selectedIds);

          const { selectedType, voltageLevels } = this.state;

          this.setSelectOptions(selectedType, voltageLevels, [key]);
          break;
        }
        case OPTIONS_SUBSTATIONS: {
          const selectedIds = (this.form.current && this.form.current.getFieldValue(key)) || [];

          await this.loadSubstations({ name: value }, selectedIds);

          const { selectedType, substations } = this.state;

          this.setSelectOptions(selectedType, substations, [key]);
          break;
        }
        case OPTIONS_OWNERS: {
          await this.loadOwners({ name: value });

          const { selectedType, owners } = this.state;

          this.setSelectOptions(selectedType, owners, [key]);
          break;
        }
        default:
          break;
      }
    },
    800
  );

  setInitialValues = async () => {
    const { location } = this.props;
    const { reportSettings } = this.state;
    const searchParams = this.getSearchParams(location);

    if (searchParams.template_group) {
      if (searchParams.template_group === TEMPLATE_GROUP_TYPE_FUNCTIONS
        && searchParams.initial_dc && searchParams.coordination_dc
      ) {
        this.setState({
          reportSettings: {
            ...reportSettings,
            [TEMPLATE_GROUP_TYPE_FUNCTIONS]: {
              ...reportSettings[TEMPLATE_GROUP_TYPE_FUNCTIONS],
              with_revert_roles: {
                ...reportSettings[
                  TEMPLATE_GROUP_TYPE_FUNCTIONS
                ]?.with_revert_roles as ReportSettingsParameterValuesType,
                disabled: false,
              },
            },
          },
        });
      }

      if (nonperformanceTermsReports.includes(searchParams.template_group as string)) {
        await this.handleSelectTemplate(TEMPLATE_GROUP_TYPE_NONPERFORMANCE, null, true);

        this.setState(
          { selectedSubType: searchParams.template_group as ReportSubType },
          () => this.form.current?.setFieldsValue({ nonperformance_term: searchParams.template_group })
        );
      } else if (TaskReports.includes(searchParams.template_group as string)) {
        await this.handleSelectTemplate(TEMPLATE_GROUP_TYPE_SETPOINT_PLANS, null, true);

        this.setState(
          { selectedSubType: searchParams.template_group as TaskSubType },
          () => this.form.current?.setFieldsValue({ task_type: searchParams.template_group })
        );
      } else {
        await this.handleSelectTemplate(searchParams.template_group as ReportType, null, true);
      }

      if (
        nonperformanceReports.includes(searchParams.template_group as string)
        || TaskReports.includes(searchParams.template_group as string)
      ) {
        if (searchParams.owner) {
          this.setState(
            { currentRequester: OWNER },
            () => this.form.current?.setFieldsValue({
              [OWNER]: (typeof searchParams.owner === 'string') && searchParams.owner.split(','),
            })
          );
        }

        if (searchParams.dispatcher_center) {
          this.setState(
            { currentRequester: DISPATCHER_CENTER },
            async () => {
              await this.handleRequesterChange(DISPATCHER_CENTER, true);
              this.form.current && this.form.current.setFieldsValue({
                [DISPATCHER_CENTER]:
                (typeof searchParams.dispatcher_center === 'string') && searchParams.dispatcher_center.split(','),
              });
            }
          );
        }
      }

      delete searchParams.template_group;

      const newValues: { [key: string]: string | boolean | string[] | moment.Moment[] } = {};

      Object.keys(searchParams).forEach((key) => {
        if (typeof searchParams[key] === 'string' && (searchParams[key] as string).includes(',')) {
          newValues[key] = (searchParams[key] as string).split(',');
        } else {
          newValues[key] = searchParams[key];
        }

        if (key === 'date_from' || key === 'date_to') {
          newValues[OPTIONS_DATE_RANGE] = [moment(searchParams['date_from'] as string), moment(searchParams['date_to'] as string)];
        }
      });

      this.form.current && this.form.current.setFieldsValue(newValues);
    } else {
      this.handleSelectTemplate(TEMPLATE_GROUP_TYPE_FUNCTIONS, null, true);
    }
  };

  getSearchParams = (location: Location) => {
    const urlSearchParams = new URLSearchParams(location.search).entries();
    const searchParams: { [key: string]: string | boolean } = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const param of urlSearchParams) {
      switch (param[1]) {
        case 'true':
          searchParams[param[0]] = true;
          break;
        case 'false':
          searchParams[param[0]] = false;
          break;
        default:
          searchParams[param[0]] = param[1];
          break;
      }
    }

    return searchParams;
  };

  loadDispatcherCenters = async (addParams = {}) => {
    const params = {};
    const { status, data } = await api.getDispatcherCenters(
      { ...params, ...addParams },
      api.getOptsForArray()
    );

    if (status === 200) {
      this.setState({ dispatcherCenters: data.results });
    }
  };

  loadFunctionGroups = async (addParams = {}, selected: string[] = []) => {
    const { functionGroups } = this.state;
    const params = { ordering: 'name' };
    const { status, data } = await api.getFunctionsTree(true, { ...params, ...addParams }, true);

    if (status === 200) {
      const selectedFunctionGroups = functionGroups.filter(
        (item: FunctionTree) => selected.includes(item.id as string)
      ) || [];
      const res: FunctionTree[] = [];
      loopTree(data.results, null, (item: FunctionTree) => {
        res.push({
          id: item.id as string,
          name: item.name as string,
        });
      });
      res.sort((a: FunctionTree, b: FunctionTree) => (a.name && b.name && a.name > b.name ? 1 : -1));
      let newFunctionGroups = [...selectedFunctionGroups, ...res];

      newFunctionGroups = uniqBy(newFunctionGroups, 'id');

      this.setState({ functionGroups: newFunctionGroups });
    }
  };

  loadVoltageLevels = async (addParams = {}, selected: string[] = []) => {
    const { voltageLevels } = this.state;

    const { status, data } = await api.getVoltageLevels(
      { ...addParams },
      api.getOptsForArray()
    );

    if (status === 200) {
      const selectedVoltageLevels = voltageLevels.filter((item) => selected.includes(item.id as string)) || [];
      let newVoltageLevels = [...selectedVoltageLevels, ...data.results];

      newVoltageLevels = uniqBy(newVoltageLevels, 'id');

      this.setState({ voltageLevels: newVoltageLevels });
    }
  };

  loadSubstations = async (addParams = {}, selected: string[] = []) => {
    const { substations } = this.state;
    const params = { limit: 50 };
    const { status, data } = await api.getSubstations(
      { ...params, ...addParams },
      api.getOptsForArray()
    );

    if (status === 200) {
      const selectedSubstations = substations.filter((item) => selected.includes(item.id as string)) || [];
      let newSubstations = [...selectedSubstations, ...data.results];

      newSubstations = uniqBy(newSubstations, 'id');

      this.setState({ substations: newSubstations });
    }
  };

  loadOwners = async (addParams = {}, selected: string[] = []) => {
    const { owners } = this.state;
    const params = {};
    const { status, data } = await api.getOwners(
      { ...params, ...addParams },
      api.getOptsForArray()
    );

    if (status === 200) {
      const selectedOwners = owners.filter((item) => selected.includes(item.id as string)) || [];
      let newOwners = [...selectedOwners, ...data.results];

      newOwners = uniqBy(newOwners, 'id');

      this.setState({ owners: newOwners });
    }
  };

  loadTaskDCStatuses = async (addParams = {}) => {
    const params = {};
    const { status, data } = await api.getTaskDCStatuses({ ...params, ...addParams });

    if (status === 200) {
      this.setState({ taskDcStatuses: data.results });
    }
  };

  setSelectOptions = (
    selectedType: ReportType,
    data: OptionsType,
    keys: ReportSettingsParameters[] = []
  ) => {
    const { reportSettings } = this.state;
    const nextSettings = cloneDeep(reportSettings[selectedType]) as ReportSettingsParameterType;

    keys.forEach((key) => {
      if (nextSettings[key] !== undefined) {
        (nextSettings[key] as ReportSettingsParameterValuesOptionsType).options = data;
      }
    });

    this.setState({
      reportSettings: {
        ...reportSettings,
        [selectedType as ReportType]: nextSettings,
      },
    });
  };

  handleSelectTemplate = async (selectedType: ReportType, option: OptionType | null, initial = false) => {
    const { location } = this.props;
    const searchParams = this.getSearchParams(location);

    this.setState({ selectedType });

    switch (selectedType) {
      case TEMPLATE_GROUP_TYPE_FUNCTIONS: {
        const dcParams: queryParams = {
          selected: [],
        };

        if (initial) {
          if (searchParams.initial_dc && typeof searchParams.initial_dc === 'string') {
            dcParams.selected = [...dcParams.selected, ...searchParams.initial_dc.split(',')];
          }

          if (searchParams.coordination_dc && typeof searchParams.coordination_dc === 'string') {
            dcParams.selected = [...dcParams.selected, ...searchParams.coordination_dc.split(',')];
          }
        }

        this.setState({ loading: true });

        await Promise.all([
          this.loadDispatcherCenters(dcParams),
          this.loadFunctionGroups(),
        ]);

        this.setState({ loading: false });

        const { dispatcherCenters, functionGroups } = this.state;

        this.setSelectOptions(TEMPLATE_GROUP_TYPE_FUNCTIONS, dispatcherCenters, ['initial_dc', 'coordination_dc']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_FUNCTIONS, functionGroups, ['function_group']);
        break;
      }
      case TEMPLATE_GROUP_TYPE_EVENTS: {
        const voltageLevelsParams: queryParams = {};
        const dcParams: queryParams = {};
        const substationsParams: queryParams = {};
        const ownersParams: queryParams = {};

        if (initial) {
          if (searchParams.voltage_level && typeof searchParams.voltage_level === 'string') {
            voltageLevelsParams.selected = searchParams.voltage_level.split(',');
          }

          if (searchParams.initial_dc && typeof searchParams.initial_dc === 'string') {
            dcParams.selected = searchParams.initial_dc.split(',');
          }

          if (searchParams.substation && typeof searchParams.substation === 'string') {
            substationsParams.selected = searchParams.substation.split(',');
          }

          if (searchParams.coordination_subject && typeof searchParams.coordination_subject === 'string') {
            ownersParams.selected = searchParams.coordination_subject.split(',');
          }
        }

        this.setState({ loading: true });

        await Promise.all([
          this.loadDispatcherCenters(dcParams),
          this.loadVoltageLevels(voltageLevelsParams),
          this.loadSubstations(substationsParams),
          this.loadOwners(ownersParams),
        ]);

        this.setState({ loading: false });

        const {
          dispatcherCenters, voltageLevels, substations, owners,
        } = this.state;

        this.setSelectOptions(TEMPLATE_GROUP_TYPE_EVENTS, dispatcherCenters, ['initial_dc']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_EVENTS, voltageLevels, ['voltage_level']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_EVENTS, substations, ['substation']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_EVENTS, owners, ['coordination_subject']);
        break;
      }
      case TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY: {
        const ownersParams: queryParams = {};
        const substationsParams: queryParams = {};

        if (initial) {
          if (searchParams.substations && typeof searchParams.substations === 'string') {
            substationsParams.selected = searchParams.substations.split(',');
          }

          if (searchParams.owner && typeof searchParams.owner === 'string') {
            ownersParams.selected = searchParams.owner.split(',');
          }
        }

        this.setState({ loading: true });

        await Promise.all([
          this.loadOwners(ownersParams),
          this.loadSubstations(substationsParams),
          this.loadFunctionGroups(),
        ]);

        this.setState({ loading: false });

        const {
          owners, substations, functionGroups,
        } = this.state;

        this.setSelectOptions(TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY, owners, ['owner']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY, substations, ['substations']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY, functionGroups, ['groups']);
        break;
      }
      case TEMPLATE_GROUP_TYPE_NONPERFORMANCE: {
        const ownersParams: queryParams = {};

        if (initial) {
          if (searchParams.owner && typeof searchParams.owner === 'string') {
            ownersParams.selected = searchParams.owner.split(',');
          }
        }

        this.setState({ loading: true });

        await Promise.all([
          this.loadOwners(ownersParams),
          this.loadFunctionGroups(),
        ]);

        this.setState({ loading: false });

        const { owners, functionGroups } = this.state;

        this.setSelectOptions(TEMPLATE_GROUP_TYPE_NONPERFORMANCE, owners, ['owner']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_NONPERFORMANCE, functionGroups, ['group']);
        break;
      }
      case TEMPLATE_GROUP_TYPE_SETPOINT_PLANS: {
        const ownersParams: queryParams = {};

        if (initial) {
          if (searchParams.owner && typeof searchParams.owner === 'string') {
            ownersParams.selected = searchParams.owner.split(',');
          }
        }

        this.setState({ loading: true });

        await Promise.all([
          this.loadOwners(ownersParams),
          this.loadFunctionGroups(),
          this.loadTaskDCStatuses(),
        ]);

        this.setState({ loading: false });

        const { owners, functionGroups, taskDcStatuses } = this.state;

        this.setSelectOptions(TEMPLATE_GROUP_TYPE_SETPOINT_PLANS, owners, ['owner']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_SETPOINT_PLANS, functionGroups, ['group']);
        this.setSelectOptions(TEMPLATE_GROUP_TYPE_SETPOINT_PLANS, taskDcStatuses, [OPTIONS_TASK_DC_STATUSES]);
        break;
      }
      default:
        break;
    }
  };

  handleSelectSubTemplate = (selectedSubType: ReportSubType | TaskSubType) => {
    this.setState({ selectedSubType });
  };

  onFormValuesChange = (changedValues: Store, allValues: Store) => {
    const { selectedType, reportSettings } = this.state;

    if (selectedType === TEMPLATE_GROUP_TYPE_FUNCTIONS) {
      if (!(allValues.initial_dc && allValues.coordination_dc && allValues.initial_dc !== allValues.coordination_dc)) {
        this.form.current && this.form.current.setFieldsValue({ with_revert_roles: false });
      }

      this.setState({
        reportSettings: {
          ...reportSettings,
          [TEMPLATE_GROUP_TYPE_FUNCTIONS]: {
            ...reportSettings[TEMPLATE_GROUP_TYPE_FUNCTIONS],
            with_revert_roles: {
              ...reportSettings[TEMPLATE_GROUP_TYPE_FUNCTIONS]?.with_revert_roles as ReportSettingsParameterValuesType,
              disabled: !(allValues.initial_dc && allValues.coordination_dc),
            },
          },
        },
      });
    }
  };

  handleRequesterRadioChange = async (e: RadioChangeEvent) => {
    const value = e.target.value;

    this.setState(
      { currentRequester: value },
      () => this.handleRequesterChange(value)
    );
  };

  handleRequesterChange = async (value: string, initial = false) => {
    const { selectedType, reportSettings } = this.state;
    const { location } = this.props;
    const searchParams = this.getSearchParams(location);

    if (nonperformanceReports.includes(selectedType) || selectedType === TEMPLATE_GROUP_TYPE_SETPOINT_PLANS) {
      const currentSetting = Object.keys(
        reportSettings[selectedType] as ReportSettingsParameterType
      )[0] as ReportSettingsParameters;

      switch (value) {
        case OWNER: {
          const { [currentSetting]: firstParam, ...rest } = reportSettings[selectedType] as ReportSettingsParameterType;
          const ownersParams: queryParams = {};

          if (initial) {
            if (searchParams.owner && typeof searchParams.owner === 'string') {
              ownersParams.selected = searchParams.owner.split(',');
            }
          }

          this.setState({
            reportSettings: {
              ...reportSettings,
              [selectedType]: {
                owner: {
                  name: 'owner',
                  label: 'OWNER',
                  type: FIELD_TYPE_SELECT_MULTIPLE,
                  options: [],
                  optionType: OPTIONS_OWNERS,
                },
                ...rest,
              },
            },
          });

          await this.loadOwners(ownersParams);

          const { owners } = this.state;

          this.setSelectOptions(selectedType, owners, ['owner']);
          break;
        }
        case DISPATCHER_CENTER: {
          const { [currentSetting]: firstParam, ...rest } = reportSettings[selectedType] as ReportSettingsParameterType;
          const dcParams: queryParams = {};

          if (initial) {
            if (searchParams.dispatcher_center && typeof searchParams.dispatcher_center === 'string') {
              dcParams.selected = searchParams.dispatcher_center.split(',');
            }
          }

          this.setState({
            reportSettings: {
              ...reportSettings,
              [selectedType]: {
                dispatcher_center: {
                  name: 'dispatcher_center',
                  label: 'DC',
                  type: FIELD_TYPE_SELECT_MULTIPLE,
                  options: [],
                  optionType: OPTIONS_DC,
                },
                ...rest,
              },
            },
          });

          await this.loadDispatcherCenters(dcParams);

          const { dispatcherCenters } = this.state;

          this.setSelectOptions(selectedType, dispatcherCenters, ['dispatcher_center']);
          break;
        }

        default: break;
      }
    }
  };

  onChangeSelect = (value: string, key: ReportSettingsParameters, optionType: string | undefined) => {
    if (!value || (Array.isArray(value) && !value.length)) this.resetSelectOptions(key, optionType);
  };

  resetSelectOptions = async (key: ReportSettingsParameters, optionType: string | undefined) => {
    switch (optionType) {
      case OPTIONS_DC: {
        await this.loadDispatcherCenters();

        const { selectedType, dispatcherCenters } = this.state;

        this.setSelectOptions(selectedType, dispatcherCenters, [key]);
        break;
      }
      case OPTIONS_FUNCTION_GROUPS: {
        await this.loadFunctionGroups();

        const { selectedType, functionGroups } = this.state;

        this.setSelectOptions(selectedType, functionGroups, [key]);
        break;
      }
      case OPTIONS_VOLTAGE_LEVELS: {
        await this.loadVoltageLevels();

        const { selectedType, voltageLevels } = this.state;

        this.setSelectOptions(selectedType, voltageLevels, [key]);
        break;
      }
      case OPTIONS_SUBSTATIONS: {
        await this.loadSubstations();

        const { selectedType, substations } = this.state;

        this.setSelectOptions(selectedType, substations, [key]);
        break;
      }
      case OPTIONS_OWNERS: {
        await this.loadOwners();

        const { selectedType, owners } = this.state;

        this.setSelectOptions(selectedType, owners, [key]);
        break;
      }
      case OPTIONS_TASK_DC_STATUSES: {
        await this.loadTaskDCStatuses();

        const { selectedType, taskDcStatuses } = this.state;

        this.setSelectOptions(selectedType, taskDcStatuses, [key]);
        break;
      }
      default:
        break;
    }
  };

  getInput = (item: ReportSettingsParameterValuesType, key: ReportSettingsParameters) => {
    const { t } = this.props;
    const { selectedSubType } = this.state;
    const rules: {[key: string]: boolean | string }[] = [];
    const fieldOptions: {rules?: typeof rules; valuePropName?: string } = {};

    if (key === OPTIONS_TASK_DC_STATUSES
      && selectedSubType
      && ![
        TEMPLATE_GROUP_SUBTYPE_SETPOINT_REGISTRY,
        TEMPLATE_GROUP_SUBTYPE_SETPOINT_SUMMARY,
      ].includes(selectedSubType)
    ) {
      return null;
    }

    if (item.required) {
      rules.push({ required: true, message: t('REQUIRED_FIELD') as string });
    }

    fieldOptions.rules = rules;
    if (item.valuePropName) fieldOptions.valuePropName = item.valuePropName;

    return (
      <React.Fragment key={item.name}>
        {t(item.label)}:
        <br />
        <Form.Item name={item.name} {...fieldOptions}>
          {(() => {
            switch (item.type) {
              case FIELD_TYPE_INPUT:
                return <Input />;
              case FIELD_TYPE_TEXTAREA:
                return <TextArea rows={4} />;
              case FIELD_TYPE_SELECT:
                return (
                  <CustomSelect
                    allowClear
                    showSearch
                    onSearch={(value: string) => this.onSelectSearch(value, key, item.optionType)}
                    onChange={(value: string) => this.onChangeSelect(value, key, item.optionType)}
                    filterOption={false}
                    getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
                  >
                    {/*
                      (item.options as []) is a hack.
                      check this https://github.com/microsoft/TypeScript/pull/31023
                    */}
                    {item.options && (item.options as []).map((opt: { id: string; name: string }) => (
                      <Option key={opt.id} value={opt.id}>
                        {opt.name}
                      </Option>
                    ))}
                  </CustomSelect>
                );
              case FIELD_TYPE_SELECT_MULTIPLE:
                return (
                  <CustomSelect
                    allowClear
                    showSearch
                    onSearch={(value: string) => this.onSelectSearch(value, key, item.optionType)}
                    onChange={(value: string) => this.onChangeSelect(value, key, item.optionType)}
                    filterOption={false}
                    getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
                    mode='multiple'
                  >
                    {item.options && (item.options as []).map((opt: { id: string; name: string }) => (
                      <Option key={opt.id} value={opt.id}>
                        {opt.name}
                      </Option>
                    ))}
                  </CustomSelect>
                );
              case FIELD_TYPE_SWITCH:
                return (
                  <Switch disabled={item.disabled} />
                );
              case FIELD_TYPE_PICKER:
                return (
                  <RangePicker
                    style={{ width: '100%' }}
                  />
                );
              default:
                return <Input />;
            }
          })()}
        </Form.Item>
      </React.Fragment>
    );
  };

  handleSubmit = () => {
    const { t, socket, isSocketConnected } = this.props;
    const { selectedType, selectedFormat, selectedSubType } = this.state;

    this.form.current
    && this.form.current.validateFields()
      .then(async (values) => {
        if (socket && isSocketConnected) {
          const reportType = selectedSubType || selectedType;
          const { date_range, ...valuesForRequest } = values;

          if (date_range) {
            valuesForRequest.date_from = date_range[0] && moment(date_range[0]).startOf('day').format(DATE_FORMAT);
            valuesForRequest.date_to = date_range[1] && moment(date_range[1]).startOf('day').format(DATE_FORMAT);
          }

          const params: { [key: string]: string } = {};

          Object.keys(valuesForRequest).forEach((key) => {
            if (valuesForRequest[key] !== undefined
              && valuesForRequest[key] !== ''
              && valuesForRequest[key].toString() !== ''
              && key !== 'with_revert_roles'
            ) {
              params[key] = valuesForRequest[key];
            }
          });

          if (reportType === TEMPLATE_GROUP_TYPE_FUNCTIONS) {
            params['reversed'] = valuesForRequest['with_revert_roles'];
          }

          if ([
            TEMPLATE_GROUP_TYPE_SETPOINT_PLANS,
            TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS,
            TEMPLATE_GROUP_SUBTYPE_SETPOINT_READY,
          ].includes(reportType)) {
            const responseStatuses = await api.getTaskDCStatuses();

            if (responseStatuses.status === 200) {
              params.status = responseStatuses.data.results
                && responseStatuses.data.results.map((status: {id: string; name: string }) => status.id);
            }
          }

          params.view_mode = selectedFormat;
          params.grouping = 'true';

          const request = `${mapTemplateGroupToRequest[reportType]}?${Qs.stringify(params, { arrayFormat: 'repeat' })}`;
          const type = reportTemplates.find((x) => x.id === selectedType);
          const additional = type && type.name
            ? ' ('.concat(t(type.name), ')')
            : '';
          const format = reportFormatType.find((x) => x.id === selectedFormat);
          const formatType = (format && format.name) || undefined;

          await socket.send(JSON.stringify({
            message_type: 'export',
            title: `${t('REPORT_EXPORT')}${additional}`,
            url: request,
            accept: formatType,
          }));
        }
      })
      .catch((err) => {
        return this.form.current && this.form.current.scrollToField(err.errorFields[0].name[0]);
      });
  };

  render() {
    const { t, user } = this.props;
    const {
      selectedType, selectedFormat, selectedSubType, reportSettings, loading, currentRequester,
    } = this.state;

    const initialValues = selectedType === TEMPLATE_GROUP_TYPE_FUNCTIONS
    && user && user.dispatcher_center ? {
        initial_dc: user.dispatcher_center.id,
      } : {};

    return (
      <Layout
        title={(
          <div>
            {t('REPORT')}
          </div>
        )}
      >
        <CustomCard className={styles.wrapper}>
          <Typography.Title level={4}>{t('REPORTS_SETTINGS')}</Typography.Title>
          <Spin spinning={loading}>
            <div className={styles.formWrapper}>
              <div className={styles.mainTemplate}>
                {t('SELECT_REPORT_TEMPLATE_GROUP')}:
                <br />
                <CustomSelect
                  defaultValue={reportTemplates[0].id}
                  value={selectedType}
                  style={{ width: '100%' }}
                  onSelect={this.handleSelectTemplate}
                  getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
                >
                  {reportTemplates.map((item) => (
                    <Option value={item.id} key={item.id}>{t(item.name)}</Option>
                  ))}
                </CustomSelect>
              </div>
              <div className={styles.mainTemplate}>
                {t('SELECT_REPORT_FORMAT')}:
                <br />
                <CustomSelect
                  defaultValue={reportFormat[0].id}
                  value={selectedFormat}
                  style={{ width: '100%' }}
                  onSelect={(value: ReportFormatType) => this.setState({ selectedFormat: value })}
                  getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
                >
                  {reportFormat.map((item) => (
                    <Option value={item.id} key={item.id}>{t(item.name)}</Option>
                  ))}
                </CustomSelect>
              </div>
              <div>
                <Form
                  ref={this.form}
                  layout='vertical'
                  initialValues={initialValues}
                  onValuesChange={(changedValues, allValues) => this.onFormValuesChange(changedValues, allValues)}
                >
                  {selectedType === TEMPLATE_GROUP_TYPE_SETPOINT_PLANS && (
                    <>
                      {t('REPORT_TYPE')}:
                      <br />
                      <Form.Item name='task_type'>
                        <CustomSelect
                          value={selectedSubType}
                          onSelect={this.handleSelectSubTemplate}
                          getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
                        >
                          <Option value={TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS} key={1}>{t('REPORT_TYPE_PLANNED')}</Option>
                          <Option value={TEMPLATE_GROUP_SUBTYPE_SETPOINT_READY} key={2}>{t('REPORT_TYPE_EXECUTED')}</Option>
                          <Option value={TEMPLATE_GROUP_SUBTYPE_SETPOINT_REGISTRY} key={3}>{t('REPORT_TYPE_REGISTRY')}</Option>
                          <Option value={TEMPLATE_GROUP_SUBTYPE_SETPOINT_SUMMARY} key={4}>{t('REPORT_TYPE_SUMMARY')}</Option>
                        </CustomSelect>
                      </Form.Item>
                    </>
                  )}
                  {selectedType === TEMPLATE_GROUP_TYPE_NONPERFORMANCE && (
                    <>
                      {t('SELECT_REPORT_TYPE')}:
                      <br />
                      <Form.Item name='nonperformance_term'>
                        <CustomSelect
                          value={selectedSubType}
                          onSelect={this.handleSelectSubTemplate}
                          getPopupContainer={(triggerNode: Node) => triggerNode.parentNode}
                        >
                          <Option value={TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY} key={1}>{t('REDUNDANCY')}</Option>
                          <Option value={TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_LIGHTLOAD} key={2}>{t('LIGHTLOAD')}</Option>
                          <Option value={TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_NONSELECTIVITY} key={3}>{t('NONSELECTIVITY')}</Option>
                        </CustomSelect>
                      </Form.Item>
                    </>
                  )}
                  {(nonperformanceReports.includes(selectedType)
                    || selectedType === TEMPLATE_GROUP_TYPE_SETPOINT_PLANS)
                  && (
                    <Radio.Group
                      value={currentRequester}
                      onChange={this.handleRequesterRadioChange}
                      className={styles.radioGroup}
                    >
                      <Radio value={OWNER}>{t('OWNER')}</Radio>
                      <Radio value={DISPATCHER_CENTER}>{t('DC')}</Radio>
                    </Radio.Group>
                  )}
                  <br />
                  {reportSettings[selectedType]
                  && (Object.keys(
                    reportSettings[selectedType] as ReportSettingsParameterType
                  ) as ReportSettingsParameters[]).map((key) => (
                    this.getInput(
                      (
                        reportSettings[selectedType] as ReportSettingsParameterType
                      )[key] as ReportSettingsParameterValuesType,
                      key
                    )
                  ))}
                </Form>
              </div>
              <Button
                htmlType='submit'
                type='primary'
                onClick={this.handleSubmit}
              >
                {t('APPLY')}
              </Button>
            </div>
          </Spin>
        </CustomCard>
      </Layout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  sidebar: state.sidebar,
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});

const mapDispatchToProps = {
  openSettings: openSidebar,
  closeSettings: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      Reports
    )
  )
);
