import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Button, Form, message, Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import api from '@services/api';
import { closeSidebar } from '@state/sidebar/actions';

import { SidebarLayout } from '@ui';

import { validateFile } from '@globalHelpers';

const allowedExtensions = ['JSON'];

class ImportTemplateSidebar extends Component {
  form = React.createRef();

  state = {
    isImporting: false,
    fileList: [],
  };

  closeModal = () => {
    const { closeImportSidebar } = this.props;

    closeImportSidebar();
  };

  validateFileExt = async (rule, value) => {
    const { t, settings } = this.props;

    validateFile(t, value, allowedExtensions, settings.MAX_UPLOAD_FILE_SIZE);
  };

  getLastFile = (e) => {
    return e && e.fileList[e.fileList.length - 1];
  };

  handleSubmit = () => {
    const { t, data } = this.props;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ isImporting: true });

      const formDataPrime = new FormData();

      formDataPrime.append('file', values.json.originFileObj || values.json);

      const { status, data: requestData } = await api.uploadTemplate(formDataPrime);

      if (status === 200) {
        message.success(t('PASSPORT_SETPOINTS_SUCCESS_CREATE'));

        if (data.callback) {
          (await data.callback(requestData));
        }
        this.closeModal();
      } else if (requestData.error && requestData.error.indexOf('already exist') !== -1) {
        message.error(
          `${t('PASSPORT_SETPOINTS_ERROR_CREATE')}: ${t('PASSPORT_SETPOINTS_ERROR_EXISTS')}`,
          5
        );
      } else if (requestData.error && requestData.error.length < 250) {
        message.error(`${t('PASSPORT_SETPOINTS_ERROR_CREATE')}: ${requestData.error}`, 5);
      } else {
        message.error(t('PASSPORT_SETPOINTS_ERROR_CREATE'), 5);
      }

      this.setState({ isImporting: false });
    });
  };

  render() {
    const { t } = this.props;
    const { isImporting, fileList } = this.state;
    const propsPrime = {
      onRemove: () => {
        this.setState({ fileList: [] });
      },
      beforeUpload: (file) => {
        this.setState({ fileList: [file] });
        return false;
      },
      fileList,
    };

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t('PASSPORT_SETPOINTS_CREATE_BLANK_IMPORT')}</h4>}
        footer={(
          <Button
            block
            type='primary'
            key='submit'
            htmlType='submit'
            onClick={this.handleSubmit}
            loading={isImporting}
          >
            {t('PUBLISH')}
          </Button>
        )}
      >
        <Form name='templateSidebarForm' ref={this.form}>
          <Form.Item name='json' getValueFromEvent={this.getLastFile} rules={[{ validator: this.validateFileExt }]} label={t('UPLOADED_FILE')}>
            <Upload {...propsPrime}>
              <Button size='small'>
                <UploadOutlined />
                {t('UPLOAD')}
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings.data,
});

const mapDispatchToProps = {
  closeImportSidebar: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    ImportTemplateSidebar
  )
);
