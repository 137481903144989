import React from 'react';
import { Row, Col } from 'antd';

import LoginForm from './form';

import styles from './styles.module.less';

const Login = () => {
  return (
    <Row type='flex' justify='space-around' align='middle' className={styles.content}>
      <Col span={6} className={styles.container}>
        <LoginForm />
      </Col>
    </Row>
  );
};

export default Login;
