import {
  SET_CHECKED_ITEMS,
  REMOVE_CHECKED_ITEMS,
  SETUP_GLOBAL_SEARCH_STRING,
  SETUP_GLOBAL_SEARCH_RELOAD,
  SETUP_GLOBAL_SEARCH_FILTERS,
  SETUP_GLOBAL_SEARCH_FILTERS_LOADING,
  APPLY_GLOBAL_SEARCH_PARAMS,
  CLEAR_CHECKED_ITEMS,
} from './types';

export const setupGlobalSearchString = (value) => {
  return {
    type: SETUP_GLOBAL_SEARCH_STRING,
    value,
  };
};

export const setupGlobalSearchReload = (value) => {
  return {
    type: SETUP_GLOBAL_SEARCH_RELOAD,
    value,
  };
};

export const setupGlobalSearchFilters = (value) => {
  return {
    type: SETUP_GLOBAL_SEARCH_FILTERS,
    value,
  };
};

export const setupGlobalSearchFiltersLoading = (value) => {
  return {
    type: SETUP_GLOBAL_SEARCH_FILTERS_LOADING,
    value,
  };
};

export const applyGlobalSearchParams = (value) => {
  return {
    type: APPLY_GLOBAL_SEARCH_PARAMS,
    value,
  };
};

export const setCheckedItem = (itemsSectionName, checkedItem = {}) => {
  return {
    type: SET_CHECKED_ITEMS,
    itemsSectionName,
    checkedItem,
  };
};

export const removeCheckedItem = (itemsSectionName, id) => {
  return {
    type: REMOVE_CHECKED_ITEMS,
    itemsSectionName,
    id,
  };
};

export const clearCheckedItems = () => {
  return {
    type: CLEAR_CHECKED_ITEMS,
  };
};
