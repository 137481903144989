import {
  websocketState,
  WS_CLEAR_MSG,
  WS_CONNECTION_CLOSED,
  WS_CONNECTION_ERROR,
  WS_CONNECTION_INIT,
  WS_CONNECTION_MESSAGE,
  WS_CONNECTION_SUCCESS,
  WS_RUN_ENDPOINT,
  wsConnectionAction,
  wsConnectionInitAction,
  wsMessageAction,
  wsRunAction,
} from './types';

const initialState: websocketState = {
  message: undefined,
  instance: undefined,
  connected: false,
  runActions: {},
};

const websocketReducer = (
  state: websocketState = initialState,
  action: wsConnectionInitAction | wsConnectionAction | wsMessageAction | wsRunAction
): websocketState => {
  const newRunActions = state.runActions;
  const { params, ...newAction } = action as wsRunAction;

  switch (action.type) {
    case WS_CONNECTION_INIT:
      return {
        ...state,
        instance: (action as wsConnectionInitAction).instance,
      };
    case WS_CONNECTION_SUCCESS:
      return {
        ...state,
        connected: true,
      };
    case WS_CONNECTION_ERROR:
      return {
        ...state,
        connected: false,
      };
    case WS_CONNECTION_CLOSED:
      return {
        ...state,
        connected: false,
      };
    case WS_CONNECTION_MESSAGE:
      return {
        ...state,
        message: (action as wsMessageAction).message,
      };
    case WS_CLEAR_MSG:
      return {
        ...state,
        message: undefined,
      };
    case `${WS_RUN_ENDPOINT}_START`:
      newRunActions[(action as wsRunAction).frontId] = (newAction as wsRunAction);
      return {
        ...state,
        runActions: newRunActions,
      };
    case `${WS_RUN_ENDPOINT}_ERROR`:
      delete newRunActions[(action as wsRunAction).frontId];
      return {
        ...state,
        runActions: newRunActions,
      };
    case `${WS_RUN_ENDPOINT}_SUCCESS`:
      delete newRunActions[(action as wsRunAction).frontId];
      return {
        ...state,
        runActions: newRunActions,
      };
    default:
      return state;
  }
};

export default websocketReducer;
