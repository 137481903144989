import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Checkbox, Input, Button, Card, Tooltip, message, Spin,
} from 'antd';
import Icon, { UploadOutlined } from '@ant-design/icons';

import store from '@state/store';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { getBucket, getMoreBucket, removeFromBucketLocally } from '@state/bucket/actions';
import api from '@services/api';
import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';

import { DOCUMENTS_UPLOAD } from '@common/sidebarRoot/types';
import { DOC_SIDEBAR_DOC } from '@common/documentsUploadSidebar/constants';
import { InfiniteScroll } from '@ui';
import { LIMIT, MODE_EDIT, RIGHT_PASSPORT_DOCS } from '@globalConstants';

import styles from './styles.module.less';

const { Search } = Input;

class Bucket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchPattern: '',
      loading: false,
      isBucketUp: false,
      page: 1,
    };
  }

  componentDidMount() {
    this.loadBucket();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { linesCnt } = this.props;
    if (prevProps.linesCnt !== linesCnt) {
      this.setState({ page: 1 }, () => this.loadBucket());
    }
  }

  clearSelected = async () => {
    const { t, selectedDevices, onClearSelectedDevices } = this.props;

    this.setState({
      loading: true,
    });

    const deviceIds = selectedDevices.map((x) => x.id);

    const { status } = await api.clearBucket(deviceIds);

    if (status === 200 || status === 204) {
      removeFromBucketLocally(deviceIds, true, true);
      message.success(`${selectedDevices.length} ${t('REMOVE_ALL_ON_PAGE_FROM_BUCKET_MESSAGE')}`);
      onClearSelectedDevices();
    } else {
      message.error(t('REMOVE_FROM_BUCKET_FAIL_MESSAGE'));
    }

    this.setState({
      loading: false,
    });
  };

  loadBucket = async () => {
    const { page } = this.state;

    this.setState({ loading: true });
    await store.dispatch(getBucket(page));
    this.setState({ loading: false });
  };

  loadMoreBucket = async () => {
    const { page } = this.state;

    this.setState({ isBucketUp: true });
    await store.dispatch(getMoreBucket(page));
    this.setState({ isBucketUp: false });
  };

  loadFullBucket = async () => {
    this.setState({ loading: true });
    await store.dispatch(getBucket(0));
    this.setState({ loading: false });
  }

  onSearchPatternChange = (event) => {
    this.setState({ searchPattern: event.target.value });
  };

  onSelectAllChange = async (event) => {
    await this.loadFullBucket();

    const { onSelectDevices, lines } = this.props;

    const checkState = event.target.checked;

    onSelectDevices(checkState ? lines : []);
  };

  onSelectLineChange = (line) => {
    return (event) => {
      const { onSelectDevices, selectedDevices } = this.props;
      const checkState = event.target.checked;
      onSelectDevices(checkState ? [...selectedDevices, line] : selectedDevices.filter((x) => x.id !== line.id));
    };
  };

  openDocumentsSidebar = () => {
    const { openDocumentsSidebar, selectedDevices, openUploadDocuments } = this.props;

    const selectedPassports = selectedDevices.flatMap((x) => x.passport.map((y) => y.id));

    openUploadDocuments(
      DOCUMENTS_UPLOAD,
      {
        type: DOC_SIDEBAR_DOC,
        passports: selectedPassports,
        showPassportsList: true,
        showSystemType: true,
      },
      700
    );

    openDocumentsSidebar(selectedPassports);
  };

  loadMoreHandle = () => {
    const { linesCnt } = this.props;
    const { page } = this.state;

    if (linesCnt > LIMIT * page) {
      this.setState((prevState) => ({ page: prevState.page + 1 }), this.loadMoreBucket);
    }
  };

  render() {
    const {
      selectedDevices, onClose, lines, t, rights, clientHeight, linesCnt,
    } = this.props;
    const {
      searchPattern, loading, isBucketUp, page,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_DOCS] === MODE_EDIT;
    const isAllDevicesChecked = linesCnt > 0 && selectedDevices.length === linesCnt;

    return (
      <>
        <div className={styles.bucketMessageBox}>
          <div className={styles.table}>
            <div className={`${styles.row} ${styles.header}`}>
              <div className={styles.cell1}>
                <Checkbox checked={isAllDevicesChecked} onChange={this.onSelectAllChange} />
              </div>
              <div className={styles.cell2}>
                <Search
                  placeholder={t('BUCKET_SEARCH_PLACEHOLDER')}
                  defaultValue={searchPattern}
                  value={searchPattern}
                  onChange={this.onSearchPatternChange}
                  size='small'
                />
              </div>
            </div>
          </div>
          <Card loading={loading} className={`${styles.table} ${styles.bodyTable}`}>
            <InfiniteScroll
              scrollbarProps={{
                style: { height: `${clientHeight - 36 - 34}px` },
              }}
              loadMoreEnabled={!isBucketUp && lines && (linesCnt > lines.length) && (linesCnt > LIMIT * page)}
              loadMore={this.loadMoreHandle}
              isUsingClientHeight={true}
            >
              <Spin spinning={isBucketUp}>
                {lines
                  .filter((line) => {
                    if (!searchPattern || searchPattern.length < 1) return true;
                    return line.name.toLowerCase().indexOf(searchPattern.toLowerCase()) !== -1;
                  })
                  .map((line) => (
                    <div className={`${styles.row} ${styles.item}`} key={line.id}>
                      <div className={styles.cell1}>
                        <Checkbox
                          checked={selectedDevices.some((x) => x.id === line.id)}
                          onChange={this.onSelectLineChange(line)}
                        />
                      </div>
                      <Tooltip title={line.name} placement='rightTop'>
                        <div className={styles.cell2}>
                          <Link to={`/passport/${line.id}`} onClick={onClose} target="_blank" rel="noopener noreferrer">
                            {line.name}
                          </Link>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
              </Spin>
            </InfiniteScroll>
          </Card>
          <div className={styles.actions}>
            <Button
              onClick={this.openDocumentsSidebar}
              disabled={!selectedDevices.length || !isEditRight}
              type='danger'
            >
              <UploadOutlined />
              {t('BUCKET_UPLOAD')}
            </Button>
            <Button onClick={this.clearSelected} type='danger' disabled={!selectedDevices.length}>
              <Icon component={BucketIcon} />
              {`${t('BUCKET_CLEAR_BUCKET')} (${selectedDevices.length})`}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lines: state.bucket.bucketData,
  linesCnt: state.bucket.count,
  rights: state.rights.rightsData,
});

const mapDispatchToProps = {
  openUploadDocuments: openSidebar,
  closeUploadDocuments: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Bucket
  )
);
