import { AppThunk, RootState } from '@state/store';
import api from '@services/api';
import { setAppStage } from '@state/appStage/actions';
import { APPSTAGE_AUTH, APPSTAGE_SIGNIN, AUTH_COOKIE_NAME } from '@globalConstants';
import {
  SET_TOKEN,
  SET_JWT_DATA,
  SET_USER_EXTRA_INFO,
  userActionTypes,
  TokenDto,
} from './types';
import storage from "@services/storage";

export function setToken(token: string): userActionTypes {
  return {
    type: SET_TOKEN,
    token,
  };
}

export const refreshSSO = async (): Promise<string | null> => {
    const { status, data } = await api.tokenRefreshEmpty();

    if (status === 200) {
      return data.token;
    } else return null;
}


export const refreshJWT = async (): Promise<string | null> => {
    const { status, data } = await api.tokenRefresh(storage.get(AUTH_COOKIE_NAME));

    if (status === 200) {
      return data.token;
    } else return null;
}

export const refreshToken = (): AppThunk => {
  return async (dispatch) => {

    const resSSO = await refreshSSO();

    if (resSSO) {
      api.setAUTHToken(resSSO);
      dispatch(setAppStage(APPSTAGE_AUTH));
    } else {
      const resJWT = await refreshJWT();

      if (resJWT) {
        api.setAUTHToken(resJWT);
        dispatch(setAppStage(APPSTAGE_AUTH));
      } else {
        dispatch(setAppStage(APPSTAGE_SIGNIN));
      }
    }
  }
}

// export const refreshToken = (token: string): AppThunk => {
//   return async (dispatch) => {
//     const { status, data } = await api.tokenRefresh(token);

//     if (status === 200) {
//       api.setAUTHToken(data.token);
//       dispatch(setAppStage(APPSTAGE_AUTH));
//     }

//     if (status === 400) {
//       dispatch(setAppStage(APPSTAGE_SIGNIN));
//     }
//   };
// };

export function jwtData(decoded: TokenDto): userActionTypes {
  return {
    type: SET_JWT_DATA,
    data: decoded,
  };
}

export const setUserExtraInfo = (): AppThunk => {
  return async (dispatch, getState) => {
    const { user }: RootState = getState();
    const { status, data } = await api.getUserById(user.user_id);

    if (status === 200) {
      dispatch({
        type: SET_USER_EXTRA_INFO,
        data,
      });
      dispatch(setAppStage(APPSTAGE_AUTH));
    }
  };
};

export const setClearUserExtraInfo = (): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_EXTRA_INFO,
      data: {
        id: '',
        username: '',
        last_name: '',
        first_name: '',
        email: '',
        dispatcher_center: {},
        roles: [],
        notify_settings: {},
        send_email: false,
        departament: '',
      },
    });
  };
};