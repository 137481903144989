import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import {
  DatePicker, Form, Input, message, Modal, Select, Empty,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { FILES, LandocsClient, REGISTEREDDOCUMENT_1003 } from '@rtsoft/issrza-integrations-client';
import debounce from 'lodash/debounce';

import api from '@services/api';
import CommonTable from '@common/CommonTable';
import { withPagination, withRowSelection } from '@hoc';
import { Letter, LetterFile } from '@services/api-dts';
import styles from './styles.module.less';

interface Types {
  [key: string]: string;
}

const journalTypes: Types = {
  incoming: 'in',
  outgoing: 'out',
  favorite: 'service',
};

const letterTypes: Types = {
  in: 'incoming',
  out: 'outgoing',
  service: 'favorite',
};

interface Props extends WithTranslation {
  addLetterVisibility: boolean;
  hideAddLetterModal: () => void;
  selectedItem: REGISTEREDDOCUMENT_1003;
  onSelectItem: (item: REGISTEREDDOCUMENT_1003 | null) => void;
  changeLetterListCallback: (response: Letter) => void;
  passportId: string;
  changePage: Function;
  onShowSizeChange: Function;
  loadByPage: Function;
  page: number;
  limit: number;
  count: number;
}
interface State {
  landocsUrl: string;
  isLettersLoading: boolean;
  modalVisibility: boolean;
  registeredDocuments: REGISTEREDDOCUMENT_1003[];
  files: FILES[];
  count: number;
}

class ModalSearchLetter extends Component<Props, State> {
  form = React.createRef<FormInstance>();

  searchLetterDebounce: any;

  constructor(props: Props) {
    super(props);
    this.searchLetterDebounce = debounce(this.searchLetter, 800);

    this.state = {
      landocsUrl: '',
      isLettersLoading: false,
      modalVisibility: false,
      registeredDocuments: [],
      files: [],
      count: 0,
    };
  }

  componentDidMount() {
    this.setUrl(this.searchLetter);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { addLetterVisibility, onSelectItem } = this.props;
    const { modalVisibility } = this.state;

    if (prevProps.addLetterVisibility !== addLetterVisibility) {
      this.setState({ modalVisibility: addLetterVisibility });
    }

    if (prevState.modalVisibility !== modalVisibility && !modalVisibility) {
      this.form.current && this.form.current.resetFields();
      this.setState({ registeredDocuments: [] });
      onSelectItem(null);
    }
  }

  setUrl = async (callback: Function) => {
    const { data } = await api.getIntegrations();

    this.setState({
      landocsUrl: data.LANDOCS && data.LANDOCS.landocs_url,
    }, () => callback && callback());
  };

  getColumnLetters = () => {
    const { t } = this.props;

    return [
      {
        title: `${t('REG')}. №`,
        dataIndex: 'registrationNumber',
        key: 'registrationNumber',
        width: '33.3%',
      },
      {
        title: `${t('REG')}. ${t('DATE')}`,
        dataIndex: 'registrationDate',
        key: 'registrationDate',
        width: '33.3%',
        render: (text: string) => moment(text).format('L'),
      },
      {
        title: `${t('SHORT_DESCRIPTION')}`,
        dataIndex: 'annotation',
        key: 'annotation',
        width: '33.3%',
      },
    ];
  };

  disabledDate = (current: Moment, name: string) => {
    if (name === 'endDate' && this.form.current?.getFieldValue('startDate')) {
      return current && current < moment(this.form.current?.getFieldValue('startDate')).endOf('day');
    }

    if (name === 'startDate' && this.form.current?.getFieldValue('endDate')) {
      return current && current > moment(this.form.current?.getFieldValue('endDate')).endOf('day');
    }

    return false;
  };

  clearDocuments = () => {
    this.setState({
      registeredDocuments: [],
      files: [],
      count: 0,
    });
  }

  searchLetter = () => {
    const { page, limit } = this.props;
    const { landocsUrl } = this.state;

    this.form.current && this.form.current.validateFields().then(async (values) => {
      const number = values.number ? values.number : undefined;
      const startDate = values.startDate ? values.startDate.toDate() : undefined;
      const endDate = values.endDate ? values.endDate.toDate() : undefined;
      const pageNumber = page;
      const rowsPerPage = limit;

      this.setState({ isLettersLoading: true });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const landocsClient: any = new LandocsClient(landocsUrl);

      const params = {
        number,
        startDate: startDate ? moment(startDate).subtract(1, "days").toDate() : undefined,
        endDate: endDate ? moment(endDate).add(1, "days").toDate() : undefined,
        pageNumber,
        rowsPerPage,
      };
      
      try {
        const resultCount = await landocsClient.count(params, values.journalType || 'in');

        if (resultCount > 0) {
          const result = await landocsClient.select(params, values.journalType || 'in');
          this.setState({
            registeredDocuments: result?.registeredDocuments || [],
            files: result?.files || [],
            count: resultCount,
          });
        } else {
          this.clearDocuments();
        }
      } catch (e) {
        // тут будет ошибка по которой надо вычислить и выдать ошибку авторизации пользователя
        console.log({ e });
        this.clearDocuments();
      }

      this.setState({ isLettersLoading: false });
    });
  };

  saveFiles = async (letter: Letter) => {
    const { t, selectedItem } = this.props;
    const { landocsUrl, files } = this.state;

    const selectedLetterFiles = files.filter((file) => file.documentID === selectedItem.id);

    const requests: Promise<{ status: number; data: LetterFile }>[] = [];

    selectedLetterFiles.forEach((file) => {
      requests.push(api.addLetterFiles({
        name: file.name,
        landocs_id: file.documentID,
        size: file.sizeKb,
        extension: file.extension,
        external_link:
          `${landocsUrl}/api/landocs/file?fileId=${file.objID}&name=${file.name}&type=${file.extension}`, // TODO replace host
        letter_id: letter.id,
      }));
    });

    const responses = await Promise.all(requests);

    let responsesErrorCount = 0;
    responses.forEach((response) => {
      const { status } = response;
      if (!(status === 200 || status === 201)) responsesErrorCount++;
    });

    if (responsesErrorCount === 0) message.success(t('FILES_ADD_SUCCESS'));
    else message.error(t('FILES_ADD_ERROR_PART_1')+` ${responsesErrorCount} `+t('FILES_ADD_ERROR_PART_2'), 5);
  };

  handleAttachLetter = async () => {
    const {
      t, passportId, hideAddLetterModal, selectedItem, changeLetterListCallback,
    } = this.props;
    const {
      whoItName, nOutDocDate, commonWho, name, nOutDoc,
      id, commonPodpis, registrationNumber, registrationDate, annotation,
    } = selectedItem;

    const { status, data } = await api.addLetter({
      passports: [passportId],
      name: name,
      sender: commonWho,
      recipient: whoItName,
      letter_type: letterTypes[this.form.current?.getFieldValue('journalType') || 'in'] || 'incoming',
      landocs_id: id,
      // correspondent: selectedItem., на уточнении
      signer: commonPodpis,
      document_number: nOutDoc,
      document_date: (
        nOutDocDate
          ? moment(nOutDocDate).format('YYYY-MM-DD')
          : null
      ),
      registration_number: registrationNumber,
      registration_date: (
        registrationDate
          ? moment(registrationDate).format('YYYY-MM-DD')
          : null
      ),
      commentary: annotation,
    });

    if ([200, 201].indexOf(status) !== -1 && data && data.id) {
      await this.saveFiles(data as Letter);

      hideAddLetterModal();
      changeLetterListCallback(data);

      this.setState({
        modalVisibility: false,
      });

      message.success(t('LETTER_ADD_SUCCESS'));
    } else {
      message.error(t('LETTER_ADD_ERROR'));
    }
  };

  handleCancelLetter = () => {
    const { hideAddLetterModal } = this.props;
    hideAddLetterModal();

    this.setState({
      modalVisibility: false,
    });
  };

  render() {
    const {
      t, selectedItem, onSelectItem, changePage, limit, page, onShowSizeChange,
    } = this.props;
    const {
      isLettersLoading, modalVisibility, registeredDocuments, count,
    } = this.state;

    const options: object[] = [];
    Object.keys(journalTypes).forEach((key: string) => {
      const val: string = journalTypes[key];
      options.push(
        <Select.Option value={val} key={key}>
          {t(`${key.toUpperCase()}_CORRESPONDENCE`)}
        </Select.Option>
      );
    });

    return (
      <Modal
        width='80%'
        title={t('ADDING_LETTER')}
        visible={modalVisibility}
        onOk={this.handleAttachLetter}
        okText={t('ATTACH')}
        okButtonProps={{
          disabled: !Object.keys(selectedItem).length,
        }}
        onCancel={this.handleCancelLetter}
      >
        <Form
          className={styles.form}
          layout='inline'
          ref={this.form}
          onValuesChange={this.searchLetterDebounce}
          initialValues={{
            journalType: journalTypes.incoming,
          }}
        >
          <Form.Item
            className={styles.formItem}
            name='number'
            label={`${t('REG')}. №`}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name='startDate'
            label={`${t('REG')}. ${t('DATE')} ${t('FROM_ALT')}`}
          >
            <DatePicker
              disabledDate={(current) => this.disabledDate(moment(current.toString()), 'startDate')}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name='endDate'
            label={`${t('REG')}. ${t('DATE')} ${t('TO_ALT')}`}
          >
            <DatePicker
              disabledDate={(current) => this.disabledDate(moment(current.toString()), 'endDate')}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name='journalType'
            label={t('JOURNAL')}
          >
            <Select>
              {options}
            </Select>
          </Form.Item>
        </Form>
        <CommonTable
          tableName='searchLetter'
          loading={isLettersLoading}
          columns={this.getColumnLetters()}
          dataSource={registeredDocuments}
          locale={{
            emptyText: (
              <Empty
                description={(
                  <span>
                    {t('NOT_FOUND_BY_PARAMETERS')}
                  </span>
                )}
              />
            ),
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedItem.id ? [selectedItem.id] : [''],
            onChange: (
              selectedRowKeys,
              selectedRows
            ) => {
              onSelectItem(selectedRows[0] as REGISTEREDDOCUMENT_1003);
            },
          }}
          pagination={{
            onChange: (newPage) => changePage(newPage, this.searchLetter),
            pageSize: limit,
            current: page,
            total: count,
          }}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, 'searchLetter', this.searchLetter)
          }
        />
      </Modal>
    );
  }
}

export default withTranslation()(
  withRowSelection(
    withPagination(
      ModalSearchLetter
    )
  )
);
