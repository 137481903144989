import moment from 'moment';

import api from '@services/api';
import storage from '@services/storage';

import { LIMIT } from '@app/constants';
import { TAB_ACTIONS, TAB_MESSAGES } from '@common/journal/constants';
import {
  CLEAR_JOURNAL_STATE,
  GET_ACTIONS_LOG, GET_MESSAGES_LOG,
  SET_FILTERS_ACTIONS, SET_FILTERS_MESSAGES,
  GET_LIMIT, SET_LIMIT,
  SET_DATE_RANGE,
  SET_PAGE,
} from './types';

export const getActionsLogs = (deviceId = null) => {
  return async (dispatch, getState) => {
    const state = getState().journal;

    if (state.isActionsLoading) return;

    dispatch({ type: GET_ACTIONS_LOG });

    const requestParams = {};
    requestParams.protection_device = deviceId || undefined;
    requestParams.timestamp_min = (
      state.start
      && moment(state.start).format('YYYY-MM-DD 00:00:00')
    ) || undefined;
    requestParams.timestamp_max = (
      state.end
      && moment(state.end).format('YYYY-MM-DD 23:59:59')
    ) || undefined;
    requestParams.username = (
      state.actionFilters.user_name
      && state.actionFilters.user_name[0]
    ) || undefined;
    requestParams.action = (
      state.actionFilters.action
      && state.actionFilters.action.length
      && state.actionFilters.action
    ) || undefined;
    requestParams.model_name = (
      state.actionFilters.model_name
      && state.actionFilters.model_name.length
      && state.actionFilters.model_name
    ) || undefined;

    const calculatedOffset = (state.limit[TAB_ACTIONS] * (state.page[TAB_ACTIONS] - 1)) || 0;

    requestParams.limit = state.limit[TAB_ACTIONS] || LIMIT;
    requestParams.offset = Math.floor(calculatedOffset);
    requestParams.sorting_by = 'timestamp';
    requestParams.sorting_order = 'DESC';

    const { status, data } = await api.getLog(requestParams);

    if (status === 200) {
      dispatch({
        type: `${GET_ACTIONS_LOG}_SUCCESS`,
        count: data.count,
        logs: data.results,
      });
    } else {
      dispatch({ type: `${GET_ACTIONS_LOG}_ERROR` });
    }
  };
};

export const getMessagesLogs = () => {
  return async (dispatch, getState) => {
    const state = getState().journal;

    dispatch({ type: GET_MESSAGES_LOG });

    const requestParams = {};
    requestParams.limit = state.limit[TAB_MESSAGES];
    requestParams.offset = state.limit[TAB_MESSAGES] * (state.page[TAB_MESSAGES] - 1);

    const { status, data } = await api.getJournalMessages(requestParams);

    if (status === 200) {
      dispatch({
        type: `${GET_MESSAGES_LOG}_SUCCESS`,
        count: data.count,
        logs: data.results,
      });
    } else {
      dispatch({ type: `${GET_MESSAGES_LOG}_ERROR` });
    }
  };
};

export const setDateRage = (start, end) => {
  return {
    type: SET_DATE_RANGE,
    start,
    end,
  };
};

export const setFiltersActions = (filters) => {
  return {
    type: SET_FILTERS_ACTIONS,
    filters: filters,
  };
};

export const setFiltersMessages = (filters) => {
  return {
    type: SET_FILTERS_MESSAGES,
    filters,
  };
};

const getStoredName = (name) => `table_rows_size__${name}`;

export const getLimit = (name, activeTab) => {
  return async (dispatch, getState) => {
    const limit = storage.get(getStoredName(name)) || getState().journal.limit[activeTab];

    dispatch({
      type: GET_LIMIT,
      activeTab,
      limit,
    });
  };
};

export const setLimit = (limit, tableName, activeTab) => {
  const numberSize = typeof limit === 'number'
    ? limit
    : parseInt(limit, 10);

  storage.set(getStoredName(tableName), numberSize);

  return {
    type: SET_LIMIT,
    activeTab,
    limit: numberSize,
  };
};

export const setPage = (page, activeTab) => {
  return {
    type: SET_PAGE,
    activeTab,
    page,
  };
};

export const clearJournalState = () => {
  return {
    type: CLEAR_JOURNAL_STATE,
  };
};
