import React from 'react';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import api from '@services/api';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { DOCUMENTS_UPLOAD, DOCUMENTS_BIND, CREATE_TASK } from '@common/sidebarRoot/types';
import { DOC_SIDEBAR_TASK } from '@common/documentsUploadSidebar/constants';
import DocsTable from '@routes/passport/docsTable';
import { MODE_EDIT, RIGHT_PASSPORT_DOCS, RIGHT_PASSPORT_TASKS } from '@globalConstants';
import { TASK_MODE_AGREEMENT } from '@routes/passport/tabs/tasks/constants';
import { PassportContext } from '@routes/passport/PassportContext';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import { CustomTag, PopoverTags } from '@ui';
import moment from 'moment';
import { DOC_BIND_SIDEBAR_TASK } from './documentsBindSidebar/constants';
import { withRouter } from 'react-router-dom';
import { setUnBlockSidebar } from '@app/helpers';

class TaskDocuments extends React.Component {
  tableName = 'passport_tab_tasks_documents';

  constructor(props) {
    super(props);

    this.state = {
      taskId: props.taskId,
      documents: [],
      count: 0,
      passportDocsCount: 0,
      taskDocsIds: [],
      passportDocsIds: [],
      isDocsLoading: false,
      isAvailableDocs: false,
    };
  }

  componentDidMount() {
    this.mounting();
  }

  mounting = async () => {
    const { setLoadTaskDocumentsCallback } = this.context;
    const {
      setTableName, passportId, taskId, history,
    } = this.props;

    setTableName(this.tableName, () => passportId && taskId && this.loadDocuments());

    setLoadTaskDocumentsCallback(this.loadDocuments);

    this.unblock = setUnBlockSidebar(history, 'CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL_PASSPORT_CHANGE_LOCATION');
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      taskId, closeBindDocuments, taskStatus, updateDocuments, isUpdateDocuments, passportId,
    } = this.props;
    const { passportDocsCount, count } = this.state;

    if ((passportId && passportId !== prevProps.passportId)
      || (taskId && !prevProps.taskId) || (prevState.count !== count)
      || (prevState.passportDocsCount !== passportDocsCount)) {
      this.resolveAddCapability();
    }

    if (!taskId && prevProps.taskId) {
      this.resetDocumentsList();
      this.setState({
        taskId,
      });
    }

    if (taskId && prevProps.taskId !== taskId) {
      const documents = null;

      closeBindDocuments && closeBindDocuments();
      this.updateData(taskId, documents);
      this.loadDocuments();
    }

    if (taskId && prevProps.taskStatus !== taskStatus && prevProps.taskId === taskId) {
      this.loadDocuments();
    }
    if (prevProps.isUpdateDocuments !== isUpdateDocuments && isUpdateDocuments === true) {
      updateDocuments && updateDocuments(false);
      this.loadDocuments();
    }
  }

  componentWillUnmount() {
    this.unblock && this.unblock();
  }

  updateData = (taskId, documents) => {
    this.setState({
      taskId,
      documents,
    });
  };

  resetDocumentsList = () => {
    this.setState({
      documents: [],
      count: 0,
    });
  }

  resolveAddCapability = async () => {
    const { passportId, taskId } = this.props;
    if (!passportId || !taskId) return;

    this.setState({ isAvailableDocs: false })

    const passportDocs = await this.loadFullDocsIds('passport', passportId);
    const passportDocsFiltered = passportDocs.filter(doc => !doc.document_type?.is_system);
    const taskDocs = await this.loadFullDocsIds('task_dc', taskId);

    const passportIds = passportDocsFiltered.map(doc => doc.id);
    const taskIds = taskDocs.map(doc => doc.id);

    const isAvailableDocs = this.checkAvailableDocs(taskIds, passportIds);

    this.setState({
      isAvailableDocs
    })
  }

  loadFullDocsIds = async (fieldname, targetId = '') => {
    const res = await api.getDocumentsByParams({ [fieldname]: targetId, limit: 999, offset: 0 });
    if (res?.status !== 200) return [];

    return res.data.results || [];
  }

  checkAvailableDocs = (taskDocsIds, passportDocsIds) => {
    const test = passportDocsIds.filter(n => !taskDocsIds.includes(n));
    if (test.length > 0) return true;
    return false
  }

  loadDocuments = async (addParams = {}, callback) => {
    const {
      taskId, loadByPage, filterValues,
    } = this.props;

    if (taskId) {
      Object.keys(filterValues).forEach((key) => {
        if (!filterValues[key]) delete filterValues[key];
      });

      const requestParams = { task_dc: taskId, ...filterValues, ...addParams };

      this.setState({ isDocsLoading: true });

      await loadByPage(
        this.tableName,
        'getDocumentsByParams',
        requestParams,
        200,
        async (status, data) => {
          this.setState({
            documents: data.results,
            count: data.count
          }, () => {
            callback && callback();
          });
        },
        () => this.resetDocumentsList()
      );
      this.setState({ isDocsLoading: false });
    } else {
      this.resetDocumentsList();
    }
  };

  loadWSParams = () => {
    const { t, taskId, filterValues } = this.props;

    if (taskId) {
      Object.keys(filterValues).forEach((key) => {
        if (!filterValues[key]) delete filterValues[key];
      });

      return {
        title: t('PASSPORT_TASKS_DOCS_TITLE_EXPORT'),
        url: '/v1/documents/',
        params: {
          task_dc: taskId,
          ...filterValues,
        },
        accept: 'application/xlsx',
      };
    }
  };

  loadDocumentIds = async (taskId) => {
    const { status, data } = await api.getDocumentsByParams({ task_dc: taskId });
    return (status === 200 && data && data.results && data.results.length)
      ? data.results.map((item) => item.id)
      : [];
  };

  uploadDocumentsCallback = async (document) => {
    const { changePage, onSelectItems } = this.props;

    changePage(1, async () => {
      await this.loadDocuments({ selected: document.id }, () => {
        const { documents: updatedDocuments } = this.state;
        onSelectItems([updatedDocuments[0]]);
      });
    });
  };

  openDocumentsSidebar = (isEdit = false) => {
    const {
      t, selectedItems, sidebarType, sidebarIsOpen, openUploadDocuments, closeUploadDocuments,
      openCreateTaskSidebar, closeCreateTaskSidebar, selectedTask, passportId,
    } = this.props;
    const { taskId } = this.state;

    if (
      isEdit
      && selectedTask
      && selectedItems
      && selectedItems.length
      && selectedItems[0]
      && selectedItems[0].document_type
      && selectedItems[0].document_type.name === t('TASK_DC')
    ) {
      if (sidebarIsOpen && sidebarType === CREATE_TASK) {
        closeCreateTaskSidebar();
      } else {
        openCreateTaskSidebar(
          CREATE_TASK,
          {
            passportId,
            selectedTask,
            mode: TASK_MODE_AGREEMENT,
            // TODO: заготовка на будущее, если потребуется загружать ограниченный тип документов
            // accept: accept,
            // TODO: заготовка на будущее, если потребуется отслеживать был ли загружен документ
            // callback: (task, taskDocument) => {
            callback: this.uploadDocumentsCallback,
          },
          700,
          true
        );
      }
    } else {
      if (sidebarIsOpen && sidebarType === DOCUMENTS_UPLOAD) {
        closeUploadDocuments();
      } else {
        openUploadDocuments(
          DOCUMENTS_UPLOAD,
          {
            type: DOC_SIDEBAR_TASK,
            header: isEdit ? t('PASSPORT_TASKS_DOCUMENTS_EDIT') : t('PASSPORT_TASKS_DOCUMENTS_ADD'),
            autoCreated: false,
            isEdit,
            taskId,
            document: isEdit && selectedItems.length && selectedItems[0],
            passports: [passportId],
            callback: this.uploadDocumentsCallback,
          },
          700,
          true
        );
      }
    }
  };

  bindDocumentsSidebar = async () => {
    const {
      openBindDocuments, closeBindDocuments, sidebarIsOpen, sidebarType, passportId,
    } = this.props;
    const { taskId } = this.state;

    if (sidebarIsOpen && sidebarType === DOCUMENTS_BIND) {
      closeBindDocuments();
    } else {
      openBindDocuments(
        DOCUMENTS_BIND,
        {
          type: DOC_BIND_SIDEBAR_TASK,
          passportId,
          taskId,
          documentsId: await this.loadDocumentIds(taskId),
          callback: () => {
            this.loadDocuments();
            closeBindDocuments();
          },
        }
      );
    }

    return undefined;
  };

  deleteFile = async (documents) => {
    const { t, onSelectItems } = this.props;
    const { taskId } = this.state;

    const documentsIds = documents.map((x) => x.id);
    const { status, data: responseData } = await api.unlinkDocumentFromTaskDC(taskId, documentsIds);

    if (status === 200) {
      message.success(responseData.detail);
      await this.loadDocuments();
    } else {
      if (responseData && responseData.error) {
        message.error(responseData.error);
      } else {
        message.error(t('PASSPORT_TASKS_DOCS_DELETING_ERROR'));
      }
    }

    onSelectItems([]);
  };

  handleResetCallback = () => {
    const { onSelectItems } = this.props;

    onSelectItems([]);
  };

  onDocumentPatching = async (attrName, valueForPatch, valueForState, record) => {
    const { t } = this.props;
    const { documents } = this.state;
    const { id, document_type, passports, document_task_dcs, name } = record;
    const preparedData = {
      name,
      passports,
      document_task_dcs,
      document_type: document_type.id,
    }

    preparedData[attrName] = valueForPatch;

    this.setState({ isDocsLoading: true });
    const { status } = await api.patchDocumentItem(id, preparedData, false);

    if (status === 200) {
      const recordIndex = documents.findIndex(doc => doc.id === id);
      if (recordIndex === -1) message.error(t('EDIT_ERROR'));
      const preparedDocsState = [...documents];
      preparedDocsState[recordIndex][attrName] = valueForState;
      this.setState({ documents: preparedDocsState });
      message.success(t('EDIT_SUCCESS'))
    } else {
      message.error(t('EDIT_ERROR'));
    }

    this.setState({ isDocsLoading: false })
  }

  onNameChanged = async (event, record, fieldname) => {
    const value = event?.target?.value || '';
    await this.onDocumentPatching(fieldname, value, value, record);
  }

  onStatusChanged = async (value, record, fieldname) => {
    await this.onDocumentPatching(fieldname, value?.id || null, value, record);
  }

  render() {
    const {
      passportId, t, rights,
      changePage, onShowSizeChange, page, limit,
      getColumnSearchFilter, getColumnSearchFilterList,
      addExtraFilters, filterValues,
      onSelectItems, selectedItems,
      isArchived, taskStatus,
      isDefaultOpenPanel, titleExtra,
    } = this.props;
    const {
      taskId, documents, isDocsLoading, count, isAvailableDocs,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_TASKS] === MODE_EDIT && !isArchived;
    const isEditRightEditButton =
      rights[RIGHT_PASSPORT_DOCS] === MODE_EDIT &&
      rights[RIGHT_PASSPORT_TASKS] === MODE_EDIT;

    const columnsDocuments = [
      {
        title: t('PASSPORT_TASKS_DOCS_TABLE_COL_DOC'),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        editable: {
          onConfirm: (event, record, fieldname) => this.onNameChanged(event, record, fieldname),
        },
        ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
      },
      {
        title: t('PASSPORT_TASKS_DOCS_TABLE_COL_TYPE'),
        dataIndex: 'document_type',
        key: 'document_type',
        width: '15%',
        render: (item) => item && item.name,
      },
      {
        title: t('PASSPORT_TASKS_DOCS_TABLE_COL_STATUS'),
        dataIndex: 'document_status',
        key: 'document_status',
        width: '15%',
        editable: {
          onConfirm: (value, record, fieldname) => this.onStatusChanged(value, record, fieldname),
        },
      },
      {
        title: t('CLASSIFICATORS_DOCUMENTS_TAGS'),
        dataIndex: 'tags',
        key: 'tags',
        width: '15%',
        render: (item) => item && (
          <PopoverTags size={item.length}>
            {item.map((tag) => (
              <CustomTag key={tag.id}>{tag.name}</CustomTag>
            ))}
          </PopoverTags>
        ),
      },
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_ADDED'),
        dataIndex: 'startup_date',
        key: 'startup_date',
        width: '15%',
        render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_OWNER_DC'),
        dataIndex: 'dispatcher_center',
        key: 'dispatcher_center',
        width: '15%',
        render: (data) => (data ? data.name : ''),
      },
    ];

    return (
      <DocsTable
        collapsable={true}
        titleExtra={titleExtra}
        loading={isDocsLoading}
        isDefaultOpenPanel={isDefaultOpenPanel}
        tableName={this.tableName}
        loadData={this.loadDocuments}
        loadWSParams={this.loadWSParams}
        count={count}
        changePage={changePage}
        onShowSizeChange={onShowSizeChange}
        page={page}
        limit={limit}
        columnsDocuments={columnsDocuments}
        dataSource={documents}
        addFilters={addExtraFilters}
        filterValues={filterValues}
        getColumnSearchFilterList={getColumnSearchFilterList}
        onSelectItems={onSelectItems}
        selectedItems={selectedItems}
        selectedDoc={(selectedItems.length === 1) && selectedItems[0]}
        openDocumentsSidebar={(isEdit) => this.openDocumentsSidebar(isEdit)}
        bindDocumentsSidebar={this.bindDocumentsSidebar}
        taskStatus={taskStatus}
        deleteFile={this.deleteFile}
        deletionType={'deattach'}
        disabledUpload={!taskId}
        isAvailableDocs={isAvailableDocs}
        isEditRight={isEditRight}
        passportId={passportId}
        taskId={taskId}
        isEditRightEditButton={isEditRightEditButton}
      />
    );
  }
}

TaskDocuments.contextType = PassportContext;

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  sidebarIsOpen: state.sidebar.isOpen,
  sidebarType: state.sidebar.componentType,
});

const mapDispatchToProps = {
  openUploadDocuments: openSidebar,
  openCreateTaskSidebar: openSidebar,
  openBindDocuments: openSidebar,
  closeUploadDocuments: closeSidebar,
  closeCreateTaskSidebar: closeSidebar,
  closeBindDocuments: closeSidebar,
};

const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      withRouter(
        TaskDocuments
      )
    )
  )
);
