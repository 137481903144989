import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Popover, Tag, Input, Spin,
} from 'antd';
import debounce from 'lodash/debounce';

import { InfiniteScroll, CustomEmpty } from '@ui';

import styles from './styles.module.less';

const LIMIT = 10;
class Content extends React.Component {
  refScroll = React.createRef();

  loadOnNameChange = debounce(async (name) => {
    const { loadTags } = this.props;
    const data = await loadTags(name, 0, LIMIT);
    this.setState({ isLoading: false, tags: data, page: 0 });
  }, 500);

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      name: '',
      page: 0,
      isLoading: false,
      count: 0,
    };
  }

  async componentDidMount() {
    const { loadTags, size } = this.props;

    this.setState({ isLoading: true });
    const data = await loadTags(undefined, 0, LIMIT);
    this.setState({
      isLoading: false,
      tags: data,
      count: (
        size === -1
          ? (data.length === LIMIT
            ? LIMIT + 1
            : data.length)
          : size
      ),
    });
  }

  changeName = (name) => {
    this.setState({ name, isLoading: true }, () => this.loadOnNameChange(name));
  }

  loadMore = async () => {
    const { loadTags, size } = this.props;
    const { page, name, count } = this.state;

    this.setState({ isLoading: true });

    const data = await loadTags(name.length > 0 ? name : undefined, page * LIMIT, LIMIT);

    this.setState((state) => ({
      isLoading: false,
      tags: [
        ...state.tags,
        ...data,
      ],
      count: (
        size === -1
          ? (data.length === LIMIT
            ? count + LIMIT + (data.length ? 0 : 1)
            : count - LIMIT
          )
          : count
      ),
    }));
  };

  loadMoreHandle = () => {
    const { tags, count, isLoading } = this.state;

    if (!isLoading && tags.length < count) {
      this.setState(
        (state) => ({
          page: state.page + 1,
          isLoading: true,
        }),
        async () => await this.loadMore()
      );
    }
  };

  render() {
    const {
      tags, name, isLoading, count, page,
    } = this.state;
    const {
      navigateTo, link, size, passportsEntity, renderItem,
    } = this.props;

    return (
      <div>
        {
          size && size >= 5
            ? (
              <div className={styles.nameFilter}>
                <Input value={name} onChange={(e) => this.changeName(e.target.value)} />
              </div>
            )
            : ''
        }
        <Spin spinning={isLoading}>
          {tags.length ? (
            <InfiniteScroll
              ref={this.refScroll}
              scrollbarProps={{
                style: { height: tags.length >= 8 ? '240px' : tags.length <= 2 ? '60px' : tags.length * 30 },
              }}
              loadMoreEnabled={!isLoading && tags && (count > tags.length) && (count > LIMIT * page)}
              loadMore={this.loadMoreHandle}
              isUsingClientHeight
            >
              {tags.map((tag) => {
                const keyId = tag.id;
                const tagId = passportsEntity ? tag.protection_device && tag.protection_device.id : tag.id;
                const tagName = (
                  renderItem && typeof renderItem === 'function'
                    ? renderItem(tag, passportsEntity)
                    : (
                      passportsEntity
                        ? tag.protection_device && tag.protection_device.name
                        : tag.name
                    )
                );

                return (
                  <div key={`div_${keyId}`}>
                    <Tag
                      className={`${(navigateTo || link) && 'link'} ${styles.tag}`}
                      key={`tag_${keyId}`}
                      onClick={navigateTo && (() => navigateTo(tagId, tagName))}
                    >
                      {
                        link
                          ? <Link key={`link_${keyId}`} to={link(tagId)}>{tagName}</Link>
                          : tagName
                      }
                    </Tag>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : <CustomEmpty />}
        </Spin>
      </div>
    );
  }
}

const PopoverLoadingTags = ({
  size, t, loadTags, navigateTo, link, title, passportsEntity, renderItem, ...restProps
}) => (
  size
    ? (
      <Popover
        getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
        overlayClassName={`${styles.popover} popover-loading-tags enable-scroll-popover`}
        content={(
          <Content
            loadTags={loadTags}
            navigateTo={navigateTo}
            link={link}
            size={size}
            passportsEntity={passportsEntity}
            renderItem={renderItem}
          />
        )}
        placement='topRight'
        trigger='click'
        destroyTooltipOnHide={true}
        {...restProps}
      >
        <Tag>
          {`${title ? t(title) : t('ALL')}${size === -1 ? '' : ': '.concat(size)}`}
        </Tag>
      </Popover>
    )
    : null
);

export default withTranslation()(
  memo(PopoverLoadingTags)
);
