import React from 'react';
import { Resizable } from 'react-resizable';

const ResizeableTitle = (props) => {
  const {
    onResize,
    onResizeStart,
    onResizeStop,
    resizable,
    loadWidth = false,
    width,
    minWidth = 40,
    maxWidth = Infinity,
    ...restProps
  } = props;

  if (restProps
    && restProps.className
    && restProps.className.indexOf('ant-table-selection-column') !== -1
  ) {
    return (
      <th
        width='40px'
        {...restProps}
      />
    );
  } else if (!loadWidth || resizable === false) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={Number(width) || 0}
      height={0}
      axis='x'
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      minConstraints={[minWidth, 0]}
      maxConstraints={[maxWidth, 0]}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableTitle;
