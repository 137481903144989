import { message } from 'antd';
import { getId } from '@globalHelpers';

export class ActionsForTree {
  constructor(t) {
    this.t = t;
  }

  newTreeItem = (parent) => {
    if (parent) {
      return {
        id: getId(5),
        name: null,
        parent,
        is_accounting_group: true,
        children: [],
        isNewItem: true,
      };
    } else {
      message.warn(this.t('TREE_NO_NEW_TREE_ITEM_VALUE_FOR_ROOT'));
      return null;
    }
  };
}
