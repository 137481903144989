import React from 'react';

import { Resizable } from 'react-resizable';
import styles from './styles.module.less'

const ResizableHeaderCell = (props: any) => {
  const {
    onResize, onResizeStop, width, style, resizable, ...restProps
  } = props;

  if (!width || !resizable) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onResizeStop={onResizeStop}
      draggableOpts={{ enableUserSelectHack: true }}
      className={!resizable ? styles['non-resizable'] : ''}
    >
      <th
        {...restProps}
        style={{
          ...style,
          overflowWrap: 'normal',
          whiteSpace: 'normal',
        }}
      />
    </Resizable>
  );
};

export default ResizableHeaderCell;
