import api from '@services/api';
import { message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getId, loopTreeParentObject } from '@globalHelpers';

export class ActionsForTree {
  constructor(props) {
    this.t = props.t;
    this.component = props.component;
  }

  newTreeItem = (parent) => {
    return {
      id: getId(5),
      name: null,
      parent,
      is_accounting_group: true,
      children: [],
      isNewItem: true,
    };
  };

  getNoFunctionCategory = () => {
    const { t } = this;

    return {
      id: '00000000-0000-0000-0000-000000000000',
      name: t('PASSPORT_SETPOINTS_WITHOUT_FUNCTIONS'),
      children: [],
    };
  };

  compareCategoriesForFunctionOptionSet = async (
    passportId,
    taskId,
    setPointsNameFilter,
    getDataFunction,
    getCategoryFunction,
    additionalMainFunction,
    additionalCompareFunction
  ) => {
    const { t, component } = this;
    const { compareMode, compareIds } = component.props;

    const resFunction = await api[getDataFunction](taskId, setPointsNameFilter);

    if (resFunction.status !== 200) {
      component.setState({ categoryBlockIsLoading: false });
      message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
      return null;
    }

    const categories = await getCategoryFunction(resFunction.data);

    const treeDataList = {};
    const loadedCompareCategory = {};
    loadedCompareCategory[taskId] = {};
    loopTreeParentObject(categories, '', (item, parent) => {
      // eslint-disable-next-line no-param-reassign
      item.parent = parent ? parent.id : null;
      // eslint-disable-next-line no-param-reassign
      item.parentNode = parent ? { id: parent.id, name: parent.name } : null;
      treeDataList[`${item.name}_${parent ? parent.name : ''}`] = item;
      loadedCompareCategory[taskId][item.id] = item.id;
    });
    const treeData = cloneDeep(categories);
    const oldTreeData = cloneDeep(categories);

    if (additionalMainFunction) {
      additionalMainFunction(resFunction.data);
    }

    if (compareMode && compareIds && compareIds.length) {
      const req = [];
      for (let i = 0; i < compareIds.length; i += 1) {
        req.push(api[getDataFunction](compareIds[i], setPointsNameFilter));
      }

      const results = await Promise.all(req);

      results.forEach((res, idx) => {
        if (res.status !== 200) {
          component.setState({ categoryBlockIsLoading: false });
          message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
        } else {
          const dataList = {};
          loadedCompareCategory[compareIds[idx]] = {};
          loopTreeParentObject(
            getCategoryFunction(res.data),
            null,
            (resItem, resParent) => {
              // eslint-disable-next-line no-param-reassign
              resItem.parent = resParent ? resParent.id : null;
              // eslint-disable-next-line no-param-reassign
              resItem.parentNode = resParent ? { id: resParent.id, name: resParent.name } : null;
              const compareIndex = `${resItem.name}_${resParent ? resParent.name : ''}`;
              dataList[compareIndex] = resItem;
              if (Object.keys(treeDataList).indexOf(compareIndex) === -1) {
                if (!resParent) {
                  const newItem = cloneDeep(resItem);
                  newItem.wasAdd = true;
                  treeData.push(newItem);
                  treeDataList[compareIndex] = newItem;
                  loadedCompareCategory[taskId][newItem.id] = newItem.id;
                  loadedCompareCategory[compareIds[idx]][newItem.id] = newItem.id;
                } else {
                  loopTreeParentObject(treeData, null, (treeItem) => {
                    const itemIndex = `${treeItem.name}_${treeItem.parentNode ? treeItem.parentNode.name : ''}`;
                    // eslint-disable-next-line max-len
                    const parentIndex = `${resParent ? resParent.name : ''}_${resParent.parentNode ? resParent.parentNode.name : ''}`;

                    if (itemIndex === parentIndex) {
                      const newItem = cloneDeep(resItem);
                      newItem.wasAdd = true;
                      treeItem.children.push(newItem);
                      treeDataList[compareIndex] = newItem;
                      loadedCompareCategory[taskId][newItem.id] = newItem.id;
                      loadedCompareCategory[compareIds[idx]][newItem.id] = newItem.id;
                    }
                  });
                }
              } else {
                loadedCompareCategory[compareIds[idx]][treeDataList[compareIndex].id] = resItem.id;
              }
            }
          );

          loopTreeParentObject(treeData, null, (treeItem, treeParent) => {
            const compareIndex = `${treeItem.name}_${treeParent ? treeParent.name : ''}`;
            if (oldTreeData.some((el) => el.id === treeItem.id)
              && Object.keys(dataList).indexOf(compareIndex) === -1
            ) {
              // eslint-disable-next-line no-param-reassign
              treeItem.wasDelete = true;
            }
          });

          if (additionalCompareFunction) {
            additionalCompareFunction(resFunction);
          }
        }
      });
      component.setState({
        loadedCompareCategory,
      });
    }

    return treeData;
  };

  onExpandTreeData = (expandedKeys) => {
    const { component } = this;
    component.setState({
      expandedTreeItems: expandedKeys,
    });
  };
}
