import {
  RIGHT_PASSPORT_INFO,
  RIGHT_PASSPORT_DOCS,
  RIGHT_PASSPORT_SETPOINTS,
  RIGHT_PASSPORT_SETPOINT_MAP,
  RIGHT_PASSPORT_FUNCTIONS,
  RIGHT_PASSPORT_TASKS,
  RIGHT_PASSPORT_LETTERS,
  RIGHT_PASSPORT_NONPERFORMANCE,
} from '@globalConstants';
import {
  GET_RIGHTS,
  GET_PASSPORT_RIGHTS,
  GET_RIGHT_PASSPORT_DOCS,
  rightsState,
  rightsActionTypes,
} from './types';

const commonRights = {
  NOTIFICATION_TASK_DC_DEADLINE: 'readonly',
  NOTIFICATION_TASK_DC_EXPIRATION: 'readonly',
  NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE: 'readonly',
  NOTIFICATION_TASK_DC_STATUS_CHANGE: 'readonly',
  RIGHT_DOCUMENT_GLOBAL_STORAGE: 'readonly',
  RIGHT_PASSPORT_DOCS: 'readonly',
  RIGHT_PASSPORT_EVENTS: 'readonly',
  RIGHT_PASSPORT_FUNCTIONS: 'readonly',
  RIGHT_PASSPORT_INFO: 'readonly',
  RIGHT_PASSPORT_SETPOINTS: 'readonly',
  RIGHT_PASSPORT_SETPOINT_MAP: 'readonly',
  RIGHT_PASSPORT_TASKS: 'readonly',
  RIGHT_ROUTE_CLASSIFIERS: 'readonly',
  RIGHT_ROUTE_EVENTS: 'readonly',
  RIGHT_ROUTE_EXTERNAL_SYSTEMS: 'readonly',
  RIGHT_ROUTE_FUNCTIONS: 'readonly',
  RIGHT_ROUTE_GLOBAL_SEARCH: 'readonly',
  RIGHT_ROUTE_JOURNAL: 'readonly',
  RIGHT_ROUTE_REPORTS: 'readonly',
  RIGHT_ROUTE_ROLES: 'readonly',
  RIGHT_ROUTE_STORAGES: 'readonly',
  RIGHT_ROUTE_SYSTEM_SETTINGS: 'readonly',
  RIGHT_ROUTE_TEMPLATES: 'readonly',
  RIGHT_PASSPORT_LETTERS: 'readonly',
  RIGHT_PASSPORT_NONPERFORMANCE: 'readonly',
  RIGHT_ROUTE_TREE: 'readonly',
  RIGHT_NEWS: 'readonly',
  RIGHT_MAINTENANCE: 'readonly',
};

const rightPassportDocsInitial = {
  RIGHT_PASSPORT_DOCS: 'readonly',
}

const initialState: rightsState = {
  rightsDataGeneral: { ...commonRights },
  rightsData: { ...commonRights },
  rightPassportDocs: { ...rightPassportDocsInitial },
};

export default function rightsReducer(state = initialState, action: rightsActionTypes): rightsState {
  switch (action.type) {
    case GET_RIGHTS:
      return {
        ...state,
        rightsData: action.rightsData,
        rightsDataGeneral: action.rightsDataGeneral,
      };
    case GET_PASSPORT_RIGHTS:
      return {
        ...state,
        rightsData: {
          ...state.rightsData,
          RIGHT_PASSPORT_INFO: action.rightsData[RIGHT_PASSPORT_INFO],
          RIGHT_PASSPORT_DOCS: action.rightsData[RIGHT_PASSPORT_DOCS],
          RIGHT_PASSPORT_SETPOINTS: action.rightsData[RIGHT_PASSPORT_SETPOINTS],
          RIGHT_PASSPORT_SETPOINT_MAP: action.rightsData[RIGHT_PASSPORT_SETPOINT_MAP],
          RIGHT_PASSPORT_FUNCTIONS: action.rightsData[RIGHT_PASSPORT_FUNCTIONS],
          RIGHT_PASSPORT_TASKS: action.rightsData[RIGHT_PASSPORT_TASKS],
          RIGHT_PASSPORT_LETTERS: action.rightsData[RIGHT_PASSPORT_LETTERS],
          RIGHT_PASSPORT_NONPERFORMANCE: action.rightsData[RIGHT_PASSPORT_NONPERFORMANCE],
        },
      };
    case GET_RIGHT_PASSPORT_DOCS:
      return {
        ...state,
        rightPassportDocs: action.rightPassportDocs,
      };
    default:
      return state;
  }
}
