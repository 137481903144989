import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Layout, message, Spin } from 'antd';

import { WarningOutlined } from '@ant-design/icons';
import { getObjectFromUrl, closeAllPopups } from '@app/helpers';

import {
  setupGlobalSearchFilters,
  setupGlobalSearchFiltersLoading,
  setupGlobalSearchString
} from '@state/search/actions';
import { initializeWebSocket } from '@state/websocket/actions';
import { getArchivedSettings } from '@state/archived/actions';

import config from '@globalConfig';
import { ConfigProvider } from 'antd';

import PrivateRoute from '@common/privateRoute';
import SidebarRoot from '@common/sidebarRoot';
import { AppMessage } from '@ui';
import GlobalSearch from '@routes/globalSearch';
import TemplatesRoute from '@routes/templates';
import Tree from '@routes/treeFilter';
import Passport from '@routes/passport';
import Journal from '@routes/journal';
import Functions from '@routes/functions';
import Roles from '@routes/roles';
import ChosenDevices from '@routes/chosenDevices';
import UndefinedPage from '@routes/undefinedPage';
import CreateTemplateRoute from '@routes/createTemplate';
import Classificators from '@routes/classificators';
import IntegrationsRoute from '@routes/integrations';
import SettingsRoute from '@routes/settings';
import TaskDcMonitoring from '@routes/TaskDcMonitoring';
import Reports from '@routes/Reports';
import Storages from '@routes/Storages';
import Events from '@routes/Events';

import {
  ROUTE_ROOT,
  ROUTE_TREE,
  ROUTE_SEARCH,
  ROUTE_SEARCH_WITH_QUERY,
  ROUTE_SEARCH_TABS,
  ROUTE_SEARCH_TABS_WITH_QUERY,
  ROUTE_PASSPORT,
  ROUTE_PASSPORT_TAB,
  ROUTE_JOURNAL,
  ROUTE_FUNCTIONS,
  ROUTE_ROLES,
  ROUTE_ROLES_TAB,
  ROUTE_CHOSEN_DEVICES,
  ROUTE_404,
  ROUTE_403,
  ROUTE_CREATE_TEMPLATE,
  ROUTE_EDIT_TEMPLATE,
  ROUTE_TEMPLATES,
  ROUTE_EXTERNAL_SYSTEM,
  ROUTE_EXTERNAL_SYSTEM_TAB,
  ROUTE_SETTINGS,
  ROUTE_CLASSIFICATORS,
  ROUTE_CLASSIFICATORS_TAB,
  APPSTAGE_AUTH,
  ROUTE_EXTERNAL_LINK,
  ROUTE_TASK_DC_MONITORING,
  ROUTE_REPORTS,
  ROUTE_STORAGES,
  ROUTE_EVENTS,
  ROLE_KEY_ADMIN_IA,
  ROLE_KEY_ADMIN_ODU,
  ROLE_KEY_ADMIN_RDU,
  ROUTE_FILE_ERROR,
} from '@globalConstants';

import TopBar from './topBar';
import LeftMenu from './leftMenu';

import styles from './styles.module.less';
import MaintenanceAlert from './topBar/maintenance/maintenanceAlert';

const { Content } = Layout;

class Main extends PureComponent {
  componentDidMount() {
    const {
      token, initializeWebSocketData, initArchivedSettings, setGlobalFiltersLoading,
    } = this.props;

    setGlobalFiltersLoading(true);

    this.setGlobalSearchParams();

    initializeWebSocketData(`${config.websocket_prefix}/general/?token=${token}`);

    initArchivedSettings();

    message.config({
      top: 50,
      maxCount: 3,
    });
  }

  componentDidUpdate(prevProps) {
    const { userInfo, searchFilter, setGlobalFiltersLoading, t } = this.props;

    if (!prevProps.userInfo.id && userInfo.id) {
      this.setGlobalSearchParams();
    }
    if (!prevProps.searchFilter && searchFilter) {
      setGlobalFiltersLoading(false);
    }

    document.title = t('SYSTEM_TITLE');
  }

  setGlobalSearchParams = () => {
    const {
      location,
      setGlobalFilters,
      setGlobalSearchString,
      userInfo,
    } = this.props;

    const dispatchCenter = userInfo && userInfo.dispatcher_center && userInfo.dispatcher_center.id;
    const query = getObjectFromUrl(location, ['query', 'filter']);
    const filter = query.filter || (
      !query.query && dispatchCenter
        ? `{"DEVICE":{"DEVICE.COL_DISPATCHER_CENTER":["${dispatchCenter}"]}, "TASKS":{"TASKS.COL_DISPATCHER_CENTER":["${dispatchCenter}"]}}`
        : undefined
    );
    setGlobalSearchString(query.query || undefined);
    setGlobalFilters(filter);
  };

  render() {
    const {
      rights, sidebar, appStage, t, isSidebarOpen, userInfo, location, searchFilterLoading,
    } = this.props;

    const rightBarStyles = {};
    const width = sidebar.width || 360;
    const workAreaStyles = {};

    if (sidebar.isOpen) {
      rightBarStyles.width = `${width}px`;
      workAreaStyles.marginRight = `${width}px`;
    } else {
      rightBarStyles.width = '0px';
      workAreaStyles.marginRight = '0px';
    }

    const isDisableBySidebar = (
      isSidebarOpen
      && !sidebar.isNotDisableBySidebar
      && !(
        location
        && location.pathname
        && location.pathname.includes('/search/tab/protection-devices')
      )
    );

    return (
      <Layout className={styles.outsideLayout}>
        <MaintenanceAlert />
        <LeftMenu />
        <Layout className={styles.contentLayout} onScroll={closeAllPopups}>
          <TopBar />
          <ConfigProvider size='small'>
          <Content className={styles.content}>
            <div style={workAreaStyles}>
              <div className={styles.rightBar} style={rightBarStyles}>
                <div className={styles.inner}>
                  {sidebar.isOpen && <SidebarRoot />}
                </div>
              </div>
              <div className={styles.workArea}>
                <Spin
                  spinning={searchFilterLoading || isDisableBySidebar}
                  tip={searchFilterLoading ? undefined : t('DISABLE_BY_SIDEBAR')}
                  indicator={searchFilterLoading ? undefined : <WarningOutlined />}
                >
                  <div className={styles.inner}>
                    {!isEmpty(rights)
                        && (() => {
                          switch (appStage) {
                            case APPSTAGE_AUTH:
                              return (
                                <Switch>
                                  <PrivateRoute path={ROUTE_TREE} component={Tree} />
                                  <Route exact path={ROUTE_ROOT}>
                                    {
                                      userInfo.roles.some(role =>
                                        [ROLE_KEY_ADMIN_IA, ROLE_KEY_ADMIN_ODU, ROLE_KEY_ADMIN_RDU].includes(role.key)
                                      )
                                        ? <Redirect to={ROUTE_EXTERNAL_SYSTEM} />
                                        : <Redirect to={ROUTE_SEARCH} />
                                    }
                                  </Route>
                                  <PrivateRoute exact path={ROUTE_SEARCH} component={GlobalSearch} />
                                  <PrivateRoute path={ROUTE_SEARCH_WITH_QUERY} component={GlobalSearch} />
                                  <PrivateRoute path={ROUTE_SEARCH_TABS_WITH_QUERY} component={GlobalSearch} />
                                  <PrivateRoute path={ROUTE_SEARCH_TABS} component={GlobalSearch} />
                                  <PrivateRoute exact path={ROUTE_PASSPORT} component={Passport} />
                                  <PrivateRoute path={ROUTE_PASSPORT_TAB} component={Passport} />
                                  <PrivateRoute path={ROUTE_JOURNAL} component={Journal} />
                                  <PrivateRoute path={ROUTE_FUNCTIONS} component={Functions} />
                                  <PrivateRoute exact path={ROUTE_CLASSIFICATORS} component={Classificators} />
                                  <PrivateRoute path={ROUTE_CLASSIFICATORS_TAB} component={Classificators} />
                                  <PrivateRoute exact path={ROUTE_ROLES} component={Roles} />
                                  <PrivateRoute path={ROUTE_ROLES_TAB} component={Roles} />
                                  <PrivateRoute exact path={ROUTE_TASK_DC_MONITORING} component={TaskDcMonitoring} />
                                  <Route path={ROUTE_CHOSEN_DEVICES} render={() => <ChosenDevices />} />
                                  <Route path={ROUTE_CREATE_TEMPLATE} component={CreateTemplateRoute} />
                                  <Route path={ROUTE_EDIT_TEMPLATE} component={CreateTemplateRoute} />
                                  <PrivateRoute exact path={ROUTE_TEMPLATES} component={TemplatesRoute} />
                                  <PrivateRoute exact path={ROUTE_EXTERNAL_SYSTEM} component={IntegrationsRoute} />
                                  <PrivateRoute path={ROUTE_EXTERNAL_SYSTEM_TAB} component={IntegrationsRoute} />
                                  <PrivateRoute exact path={ROUTE_SETTINGS} component={SettingsRoute} />
                                  <PrivateRoute exact path={ROUTE_REPORTS} component={Reports} />
                                  <PrivateRoute exact path={ROUTE_STORAGES} component={Storages} />
                                  <PrivateRoute exact path={ROUTE_EVENTS} component={Events} />
                                  <Route path={ROUTE_404} component={UndefinedPage} />
                                  <Route path={ROUTE_403} component={UndefinedPage} />
                                  <Route path={ROUTE_FILE_ERROR} component={UndefinedPage} />
                                  <Route path={ROUTE_EXTERNAL_LINK} component={UndefinedPage} />
                                  <Route path={'*'} to={ROUTE_404} />
                                </Switch>
                              );
                            default:
                              return <></>;
                          }
                        })()}
                  </div>
                </Spin>
              </div>
            </div>
            <AppMessage />
          </Content>
          </ConfigProvider>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  rights: state.rights.rightsData,
  sidebar: state.sidebar,
  appStage: state.appStage,
  deviceCount: state.bucket.count,
  notificationsCounter: state.notifications.count_new,
  notifications: state.notifications.notificationsData,
  isSidebarOpen: state.sidebar.isOpen,
  token: state.user.token,
  searchFilter: state.search.searchFilter,
  searchFilterLoading: state.search.searchFilterLoading,
});

const mapDispatchToProps = {
  setGlobalFilters: setupGlobalSearchFilters,
  setGlobalFiltersLoading: setupGlobalSearchFiltersLoading,
  setGlobalSearchString: setupGlobalSearchString,
  initializeWebSocketData: initializeWebSocket,
  initArchivedSettings: getArchivedSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      Main
    )
  )
);
