import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Divider,
  DatePicker,
  Checkbox,
  Empty, Button,
} from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';

import api from '@services/api';
import { DATE_FORMAT } from '@globalConstants';
import { CustomCard, Layout } from '@ui';
import { RootState } from '@state/store';
import { RadioChangeEvent } from 'antd/es/radio';
import { RangePickerProps } from 'antd/es/date-picker';

import SelectDC from './SelectDC';
import Statuses from './Statuses';
import Calendar from './calendar';

import styles from './styles.module.less';
import type { Data, Item } from './types';
import { CALENDAR_FULLNAMES_STORAGE_ATTR_NAME } from './calendar/constants';

interface Props extends WithTranslation {
  info: { dispatcher_center: Item };
  socket: WebSocket;
  isSocketConnected: boolean;
}

interface State {
  aCheckedStatuses: string[];
  dCData: [];
  aStatuses: Item[];
  dateFrom: Moment;
  dateTo: Moment;
  selectedDC: string;
  isExpires: boolean;
  isStatusesLoading?: boolean;
  isLoading?: boolean;
  isFullNames: boolean
}

interface BlurFunc { blur: Function }

interface PickerRef extends Component<RangePickerProps>, BlurFunc {}

const { RangePicker } = DatePicker;

class Monitoring extends Component<Props, State> {
  private pickerRef = React.createRef<PickerRef>();

  constructor(props: Props) {
    super(props);

    const {
      info: { dispatcher_center },
    } = props;

    this.state = {
      aCheckedStatuses: [],
      dCData: [],
      aStatuses: [],
      dateFrom: moment().startOf('day').subtract(1, 'months'),
      dateTo: moment().startOf('day').add(1, 'day'),
      selectedDC: dispatcher_center && dispatcher_center.id,
      isExpires: false,
      isFullNames: false
    };
  }

  componentDidMount = () => {
    this.mounting();
  };

  mounting = async () => {
    await this.loadStatuses();
    await this.loadData();
    const isFullNames = this.loadIsFullNames();
    this.setState({ isFullNames: isFullNames })
  }

  loadStatuses = async () => {
    this.setState({ isStatusesLoading: true });
    const { status, data } = await api.getTaskDCStatuses();
    this.setState({ isStatusesLoading: false });

    if (status === 200) {
      this.setState({
        aStatuses: data.results,
      });
    }
  };

  loadData = async () => {
    const {
      selectedDC, aCheckedStatuses, dateFrom, dateTo, isExpires,
    } = this.state;

    this.setState({ isLoading: true });
    const { status, data } = await api.getTaskDCSearch({
      dispatcher_center__id: selectedDC,
      status__in: aCheckedStatuses.join(','),
      date_from: dateFrom.format(DATE_FORMAT),
      date_to: dateTo.format(DATE_FORMAT),
      expired_force: isExpires,
    });
    this.setState({ isLoading: false });

    const today = moment().endOf('day');
    const dCData = isExpires ? data.results : data.results.filter((task: Data) => {
      const plannedDay = moment(task.date_planned_completion).endOf('day');
      if (!task.date_planned_completion
        || today < plannedDay
        || task.date_actual_completion
        || (task.date_actual_completion && (moment(task.date_actual_completion).endOf('day') < plannedDay))) {
        return true;
      }

      return false;
    });

    if (status === 200) {
      this.setState({
        dCData,
      });
    }
    return;
  };

  handleCheckExpires = (e: RadioChangeEvent) => {
    const { target } = e;

    this.setState({
      isExpires: !!target.checked,
    }, this.loadData);
  };

  handleCheckFullNames = (e: RadioChangeEvent) => {
    const { target } = e;

    this.setState({
      isFullNames: !!target.checked
    }, () => this.saveIsFullNames())
  }

  saveIsFullNames = () => {
    localStorage.setItem(CALENDAR_FULLNAMES_STORAGE_ATTR_NAME, String(this.state.isFullNames));
  }

  loadIsFullNames = () => {
    const value = localStorage.getItem(CALENDAR_FULLNAMES_STORAGE_ATTR_NAME);
    if (String(value).toLowerCase() === "true") return true;
    return false;
  }

  handleSelectDC = (selectedDC: string) => {
    this.setState({ selectedDC }, this.loadData);
  };

  handleCheckStatus = (aCheckedStatuses: string[]) => {
    this.setState({ aCheckedStatuses }, this.loadData);
  };

  // @TODO any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeRange = (dateRange: any) => {
    this.setState({
      dateFrom: moment(dateRange[0]).startOf('day'),
      dateTo: moment(dateRange[1]).startOf('day'),
    }, this.loadData);
  };

  handleCollapsePicker = (open: boolean) => {
    open && this.pickerRef.current && this.pickerRef.current.blur();
  };

  handleClickGenerateStatistics = async (preview?: boolean) => {
    const { socket, isSocketConnected } = this.props;
    const { selectedDC, aCheckedStatuses, dateFrom, dateTo, isExpires } =
      this.state;

    const dispatcherCenterIdQuery = !selectedDC
      ? ''
      : `dispatcher_center__id=${selectedDC}&`;

    const docUrl = `/v1/task-dc/search?${dispatcherCenterIdQuery}status__in=${aCheckedStatuses.join(
      ','
    )}&date_from=${dateFrom.format(DATE_FORMAT)}&date_to=${dateTo.format(
      DATE_FORMAT
    )}&expired_force=${isExpires}`;

    if (!docUrl) return;

    if (socket && isSocketConnected) {
      const wsMessage = {
        message_type: 'export',
        accept: preview ? 'text/html' : 'application/xlsx',
        url: docUrl,
        properties: preview ? { downloadImmediately: true } : undefined,
      };

      this.setState({ isLoading: true });
      socket.send(JSON.stringify(wsMessage));
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { t } = this.props;
    const {
      isLoading,
      dCData,
      dateFrom,
      dateTo,
      aStatuses,
      isStatusesLoading,
      isExpires,
      isFullNames
    } = this.state;

    const isDataEmpty = dCData.length === 0;

    return (
      <Layout className={styles.taskDCMonitoring} title={t('LEFT_MENU_ITEM_TASK_DC_MONITORING')}>
        <CustomCard
          extra={
            <>
              <Checkbox checked={isFullNames} onChange={this.handleCheckFullNames}>
                {t('TASKS_DC_CALENDAR_SHOW_FULL_NAMES')}
              </Checkbox>
              <Divider type='vertical' />
              <Checkbox checked={isExpires} onChange={this.handleCheckExpires}>
                {t('SHOW_EXPIRED_TASKS')}
              </Checkbox>
              <Divider type='vertical' />
              <SelectDC
                handleSelectDC={this.handleSelectDC}
                isDataLoading={isLoading}
                callback={this.handleCollapsePicker}
              />
              <Divider type='vertical' />
              <Statuses
                handleCheckStatus={this.handleCheckStatus}
                isLoading={isStatusesLoading}
                aStatuses={aStatuses}
                t={t}
                callback={this.handleCollapsePicker}
              />
              <Divider type='vertical' />
              <RangePicker
                ref={this.pickerRef}
                onChange={this.handleChangeRange}
                defaultValue={[dateFrom, dateTo]}
                size='small'
                disabled={isLoading}
                allowClear={false}
              />
            </>
          }
        >
          {!isLoading && isDataEmpty ? (
            <Empty />
          ) : (
            <>
              <Calendar
                data={dCData}
                aStatuses={aStatuses}
                isLoading={isLoading}
                dateFrom={dateFrom}
                dateTo={dateTo}
                showFullNames={isFullNames}
              />
              <div style={{ display: 'flex', gap: '5px', padding: '10px' }}>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => this.handleClickGenerateStatistics()}
                  disabled={isLoading}
                >
                  Сформировать статистику
                </Button>
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => this.handleClickGenerateStatistics(true)}
                  disabled={isLoading}
                >
                  Предпросмотр
                </Button>
              </div>
            </>
          )}
        </CustomCard>
      </Layout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  rights: state.rights.rightsData,
  info: state.user.info,
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected
});

export default connect(mapStateToProps, null)(
  withTranslation()(
    Monitoring
  )
);
