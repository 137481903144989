import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import isEqual from 'lodash/isEqual';

import api from '@services/api';
import { closeSidebar } from '@state/sidebar/actions';
import {
  getTemplates, getMoreTemplates, templatesWithChildrenSelector,
} from '@state/templates/actions';
import { withPagination } from '@hoc';

import { SidebarLayout } from '@ui';
import BlanksForm from './blanksForm';

class ImportBlankSidebar extends Component {
  blankForm = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isExporting: false,
      templates: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    this.loadTemplates();
  }

  componentDidUpdate(prevProps, prevState) {
    const { templatesData, changePage } = this.props;
    const { searchValue } = this.state;

    if (!isEqual(prevProps.templatesData, templatesData)
      || (prevState.templates.length === 0 && templatesData.length)) {
      this.setState({
        templates: templatesData,
      });
    }

    if (prevState.searchValue !== searchValue) {
      changePage(1, () => {
        this.loadTemplates({ name: searchValue });
      });
    }
  }

  setSearchValue = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  loadTemplates = async (addParams = {}) => {
    const {
      getTemplatesData, page, filterValues,
    } = this.props;
    const { searchValue } = this.state;
    const params = {
      name: searchValue,
      limit: 30,
      offset: 30 * (page - 1),
      group_by: 'descendant',
      view_mode: 'full',
      is_count: true,
    };
    const requestParams = { ...params, ...filterValues, ...addParams };

    await getTemplatesData(this, requestParams);
  };

  loadMoreTemplates = async () => {
    const {
      page, changePage, templatesCount, getMoreTemplatesData,
    } = this.props;
    const { templates, searchValue } = this.state;

    if (templates.length < templatesCount) {
      changePage(
        page + 1,
        () => getMoreTemplatesData({
          name: searchValue,
          limit: 30,
          offset: 30 * (page),
          group_by: 'descendant',
          view_mode: 'full',
          is_count: true,
        })
      );
    }
  };

  handleSubmit = () => {
    const currentForm = this.blankForm.form.current;

    currentForm && currentForm.validateFields().then(async (values) => {
      this.setState({ isExporting: true });

      const {
        data, t, closeSidebarData,
      } = this.props;

      const formDataPrime = new FormData();
      let result;

      if (data.type === 'blank') {
        Object.keys(values).forEach((item) => {
          if (!values[item]) {
            return;
          }

          if (item === 'file') {
            formDataPrime.append('file', values[item].originFileObj || values[item]);
          }
        });

        if (values['loadType'] === 3) {
          formDataPrime.append('template', values['template']);
        } else if (values['loadType'] === 2) {
          formDataPrime.append('template', 'auto');
        }

        result = await api.uploadBlank(data.passport__id, formDataPrime);

        if (result && result.status === 200) {
          message.success(t('PASSPORT_SETPOINTS_SUCCESS_CREATE'));

          if (data.callback) {
            (await data.callback(result.data));
          }
          closeSidebarData();
        } else {
          if (result && result.data && result.data.error) {
            console.error(result.data.error);
          }
          if (
            (result.data.error && result.data.error.indexOf('already exist') !== -1)
            || (result.data.error && Array.isArray(result.data.error) && result.data.error[0] && result.data.error[0].indexOf('already exist') !== -1)
            || (result.data.non_field_errors && result.data.non_field_errors.indexOf('already exist') !== -1)
          ) {
            message.error(
              `${t('PASSPORT_SETPOINTS_ERROR_CREATE')}: ${t('PASSPORT_SETPOINTS_ERROR_EXISTS')}`,
              5
            );
          } else if (
            (result.data.error && result.data.error.indexOf('JSON validation error') !== -1)
            || (result.data.error && Array.isArray(result.data.error) && result.data.error[0] && result.data.error[0].indexOf('JSON validation error') !== -1)
          ) {
            message.error(
              `${t('PASSPORT_SETPOINTS_ERROR_FORMAT')}`,
              5
            );
          } else if (result.data.error && Array.isArray(result.data.error) && result.data.error[0]) {
            message.error(
              result.data.error[0],
              5
            );
          } else {
            result.data.non_field_errors
              ? message.error(`${t('PASSPORT_SETPOINTS_ERROR_CREATE')}: ${result.data.non_field_errors}`, 5)
              : message.error(`${t('PASSPORT_SETPOINTS_ERROR_CREATE')}`, 5);
          }
        }
      } else if (data.type === 'task') {
        if (values.file) {
          formDataPrime.append('blank', values.file.originFileObj || values.file);
        }

        result = await api.uploadTask(data.task__id, data.blank__id, formDataPrime);

        if (result && result.status === 200) {
          message.success(t('PASSPORT_TASKS_UPDATE_BLANK_SUCCESS'));

          if (data.callback) {
            (await data.callback(result.data));
          }
          closeSidebarData();
        } else {
          if (result && result.data && result.data.error) {
            console.error(result.data.error);
          }
          if (
            result && result.data && result.data.error && Array.isArray(result.data.error) && result.data.error[0]
          ) {
            message.error(
              `${t('PASSPORT_TASKS_UPDATE_BLANK_ERROR')} : ${result.data.error[0]}`,
              5
            );
          } else if (result && result.data && result.data.error) {
            message.error(
              `${t('PASSPORT_TASKS_UPDATE_BLANK_ERROR')} : ${result.data.error}`,
              5
            );
          } else {
            message.error(`${t('PASSPORT_TASKS_UPDATE_BLANK_ERROR')}`, 5);
          }
        }
      } else {
        message.error(`${t('PASSPORT_UPDATE_BLANK_UNKNOWN_TYPE')}`, 5);
      }

      this.setState({ isExporting: false });
    });
  };

  render() {
    const { t, isTemplatesLoading, data } = this.props;
    const { isExporting, templates } = this.state;

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t('PFPROTECTION_IMPORT')}</h4>}
        footer={(
          <Button
            block
            id='submit_upload_pf_protection_input'
            type='primary'
            form='createProjectForm'
            key='submit'
            htmlType='submit'
            onClick={this.handleSubmit}
            loading={isExporting}
            disabled={isExporting}
          >
            {t('IMPORT_BLANK')}
          </Button>
        )}
      >
        <BlanksForm
          ref={(ref) => this.blankForm = ref}
          type={data.type}
          handleSubmit={this.handleSubmit}
          templates={templates}
          isUploading={isExporting}
          loadMoreTemplates={this.loadMoreTemplates}
          isTemplatesLoading={isTemplatesLoading}
          setSearchValue={this.setSearchValue}
        />
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  templatesData: templatesWithChildrenSelector(state),
  templatesCount: state.templates.count,
  isTemplatesLoading: state.templates.isTemplatesLoading,
});

const mapDispatchToProps = {
  closeSidebarData: closeSidebar,
  getTemplatesData: getTemplates,
  getMoreTemplatesData: getMoreTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withPagination(
      ImportBlankSidebar
    )
  )
);
