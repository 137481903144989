import React, { Component } from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import {
  Input, DatePicker, Select, Form,
} from 'antd';
import Paragraph from "antd/lib/typography/Paragraph";

import { CustomTag, PopoverTags, SelectInfiniteScroll } from '@ui';

import { componentConstants } from '../constants';
import styles from './styles.module.less';

const {
  TEXTAREA, INPUT, DATEPICKER, TAGS_ROLES, SELECT_SEARCH,
} = componentConstants;
const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

class PassportParameter extends Component {
  constructor(props) {
    super(props);
    this.onSearch = debounce(this.onSearch, 800);

    this.state = {
      changedValue: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { content, isEdit } = this.props;

    if ((!prevProps.content && content) || (prevProps.isEdit !== isEdit)) {
      this.setState({
        changedValue: (content && typeof content === 'object' && content.id)
          ? content.id
          : content && content.map
            ? content.map(({ id }) => id)
            : content,
      });
    }
  }

  onSearch = (value) => {
    const { onSearch } = this.props;

    onSearch(value);
  };

  loadMore = () => {
    const { onLoadMore } = this.props;

    onLoadMore && onLoadMore();
  };

  onChangeHandle = (e) => {
    const { id, formChangeHandle } = this.props;
    this.setState({ changedValue: e && e.target ? e.target.value : e });
    formChangeHandle && formChangeHandle({ [id]: e });
  };

  getInput = () => {
    const {
      content, loading, selectOptions, disabledDate, disabled, type, multiple, allowClear,
    } = this.props;
    const { changedValue } = this.state;
    const initialValue = (
      content && typeof content === 'object' && content.id
        ? content.id
        : (
          content && content.map
            ? content.map(({ id }) => id)
            : content
        )
    );
    const itemChanged = !isEqual(initialValue, changedValue);

    switch (type) {
      case TEXTAREA:
        return (
          <TextArea
            rows={3}
            onChange={this.onChangeHandle}
            className={itemChanged ? styles.changed : null}
          />
        );
      case INPUT:
        return (
          <Input
            onChange={this.onChangeHandle}
            className={itemChanged ? styles.changed : null}
          />
        );
      case DATEPICKER:
        return (
          <DatePicker
            onChange={this.onChangeHandle}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            disabledDate={disabledDate}
            className={itemChanged ? styles.changed : null}
            style={{ width: '100%' }}
          />
        );
      case SELECT_SEARCH:
        return (
          <SelectInfiniteScroll
            style={{ width: '100%' }}
            showSearch
            mode={multiple && 'multiple'}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={this.onChangeHandle}
            onSearch={this.onSearch}
            loadMoreHandle={this.loadMore}
            filterOption={false}
            loading={loading}
            disabled={disabled}
            className={itemChanged ? styles.changed : null}
            allowClear={allowClear || false}
          >
            {selectOptions && selectOptions.map((option) => (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            ))}
          </SelectInfiniteScroll>
        );
      default:
        return null;
    }
  }

  render() {
    const { label, content, isEdit, type, id, multiple, isCopyable, t } = this.props;
    const isContent = Array.isArray(content) ? !!content.length : !!content;

    return (
      <div>
        {label && (
          <div className={styles.label}>
            {label}
            :
          </div>
        )}
        <div className={isCopyable? styles.contentCopyable : styles.content}>

          {isEdit
            ? (
              <Item name={id}>
                {this.getInput()}
              </Item>
            )
            : isContent
              ? (() => {
                if (type === DATEPICKER) {
                  return moment(content).format('YYYY-MM-DD');
                }

                if (typeof content === 'string' && !isCopyable) return content;

                if (typeof content === 'object' && content.name) return content.name;

                if (multiple) {
                  return (
                    <PopoverTags
                      size={content.length}
                      popoverPlacement='left'
                      getPopupContainer={(triggerNode) => (
                        triggerNode.closest('.ant-tag')
                          ? triggerNode.closest('.ant-tag').parentNode
                          : triggerNode
                      )}
                    >
                      {content.map((contentItem) => (
                        <CustomTag
                          maxChars={25}
                          key={contentItem.id}
                          disablePopover={content.length > 1}
                          getPopupContainer={(triggerNode) => (
                            triggerNode.closest('.ant-tag')
                              ? triggerNode.closest('.ant-tag').parentNode
                              : triggerNode
                          )}
                        >
                          {contentItem.name}
                        </CustomTag>
                      ))}
                    </PopoverTags>
                  );
                }

                if (type === TAGS_ROLES) {
                  const rolesHash = {};

                  if (content) {
                    content.forEach((role) => {
                      const key = role.name;
                      if (!rolesHash[key]) {
                        rolesHash[key] = [];
                      }
                      rolesHash[key].push(role);
                    });
                  }

                  return Object.entries(rolesHash).map(([name, roles]) => (
                    <div key={name} className={styles.label}>
                      <div>
                        {name}
                        :&emsp;
                      </div>
                      <PopoverTags
                        size={roles.length}
                        popoverPlacement='left'
                        getPopupContainer={(triggerNode) => (
                          triggerNode.closest('.ant-tag')
                            ? triggerNode.closest('.ant-tag').parentNode
                            : triggerNode
                        )}
                      >
                        {roles.map((role) => (
                          role.dc && (
                            <CustomTag
                              maxChars={25}
                              key={role.dc.id}
                              disablePopover={roles.length > 1}
                              getPopupContainer={(triggerNode) => (
                                triggerNode.closest('.ant-tag')
                                  ? triggerNode.closest('.ant-tag').parentNode
                                  : triggerNode
                              )}
                            >
                              {role.dc.name}
                            </CustomTag>
                          )
                        ))}
                      </PopoverTags>
                    </div>
                  ));
                }

                return false;
              })()
              : '–'}

          {isCopyable && (
            <Paragraph
              className={styles.copyable}
              copyable={{ tooltips: [t('COPY'), t('COPIED')] }}
            >
              {content}
            </Paragraph>
          )}
        </div>
      </div>
    );
  }
}

export default PassportParameter;
