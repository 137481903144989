import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Button, Popconfirm, Spin, message, Checkbox, Divider, Popover,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import {
  UnorderedListOutlined, FileAddOutlined, SaveOutlined, EditOutlined, DeleteOutlined,
  SnippetsOutlined, CopyOutlined, MenuOutlined, SettingOutlined,
} from '@ant-design/icons';
import CommonTable from '@common/CommonTable';
import { MAP_RIGHTS_TO_PATHS, MODE_EDIT, ROUTE_TEMPLATES, TABLE_SIZE } from '@globalConstants';
import storage from '@services/storage';
import { CustomEmpty } from '@ui';

import './style.less';
import { EditFormulaModal } from '@routes/createTemplate/modal';

const CheckboxGroup = Checkbox.Group;
const additionalColumnList = [
  'VIEW_COMPLEX',
  'VIEW_TASK',
];

class TemplateTreeValues extends Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.div = React.createRef();
    this.storageDisabledColumnViewName = `columns_disabled_view_${props.tableName}`;

    this.state = {
      data: [],
      selectedTreeItem: {},
      editId: null,
      editItem: {},
      selectedId: null,
      dragItem: null,
      canDropItem: null,
      pasteItem: null,
      pasteCount: 0,
      viewColumnList: [],
      isEditFormulaModalVisible: false,
      formulaModalInitialValue: null,
      formulaModalCallback: () => { },
      formulaIsEditing: false
    };
  }

  componentDidMount = async () => {
    const { values, selectedTreeItem, additionalComponentDidMount } = this.props;
    this.setState({
      data: values,
      selectedTreeItem: selectedTreeItem,
      viewColumnList: this.getViewColumnList(),
    });
    if (additionalComponentDidMount) {
      await additionalComponentDidMount(this);
    }

    document.addEventListener('click', this.handleClickOutside, false);
  };

  componentDidUpdate(prevProps) {
    const {
      values, selectedTreeItem, dragTreeValueItem, blank,
    } = this.props;
    const { data, selectedId, editId } = this.state;

    if (prevProps.blank && blank && prevProps.blank.id !== blank.id) {
      this.updateState({
        viewColumnList: this.getViewColumnList(),
      });
    }

    if ((JSON.stringify(prevProps.values) !== JSON.stringify(values))
      || (!editId && JSON.stringify(data) !== JSON.stringify(values))
    ) {
      const newSelectedId = values.some((x) => x.id === selectedId) ? selectedId : null;
      this.updateState({
        data: values,
        selectedId: newSelectedId,
      });
    }

    if (JSON.stringify(prevProps.selectedTreeItem) !== JSON.stringify(selectedTreeItem)) {
      this.updateState({
        selectedTreeItem: selectedTreeItem,
        selectedId: null,
      });
    }
    if (JSON.stringify(prevProps.dragTreeValueItem) !== JSON.stringify(dragTreeValueItem)) {
      this.updateState({
        dragItem: dragTreeValueItem,
        canDropItem: null,
      });
      this.deleteAllDraggedFloatingElement();
    }
  }

  componentWillUnmount() {
    const { additionalComponentWillUnmount } = this.props;

    document.removeEventListener('click', this.handleClickOutside, false);

    if (additionalComponentWillUnmount) {
      additionalComponentWillUnmount(this);
    }
  }

  onListItems() {
    const { isViewTree, onViewTree } = this.props;
    if (isViewTree) {
      onViewTree(false);
    } else {
      onViewTree(true);
    }
  }

  getColumnTitle = (column) => (
    column.origin_column
      && column.origin_column.showTitleAsIs
      && column.origin_column.originTitle
      ? column.origin_column.originTitle
      : column.origin_column.title
  );

  getColumnTitleText = (column) => (
    column.origin_column
      && column.origin_column.showTitleAsIs
      && column.origin_column.originTitleText
      ? column.origin_column.originTitleText
      : this.props.t(`TABLE_COL_${column.origin_column.title}`)
  );

  loadDisabledSettingsFromStorage = () => {
    const { saveSettings = false } = this.props;
    const emptyArray = [];

    if (!saveSettings) {
      return emptyArray;
    }

    const storageDisabledColumnView = storage.get(this.storageDisabledColumnViewName);

    // сброс значений ширины колонок
    if (!storageDisabledColumnView) {
      storage.set(this.storageDisabledColumnViewName, JSON.stringify(emptyArray));
      return emptyArray;
    }
    return JSON.parse(storageDisabledColumnView);
  }

  saveSettingsFromStorage = (viewColumList, allColumnList) => {
    const { saveSettings = false } = this.props;

    if (saveSettings) {
      const disabledList = (
        allColumnList
          .map((x) => this.getColumnTitle(x))
          .filter(
            (x) => (
              viewColumList.length !== 0
              && !viewColumList.includes(x)
              && !additionalColumnList.includes(x)
            )
          )
      );

      storage.set(this.storageDisabledColumnViewName, JSON.stringify(disabledList));
    }
    this.setState({ viewColumnList: viewColumList });
  }

  getViewColumnList = () => {
    const { originComponent, visibleStandardButtons } = this.props;

    if (visibleStandardButtons && visibleStandardButtons.indexOf('settings') === -1) {
      return [];
    }

    const { columns, columnsList } = this.getColumnsForRender();
    const disablesColumnsViewList = this.loadDisabledSettingsFromStorage();

    const viewColumnList = (
      columns
        .filter((x) => (
          !disablesColumnsViewList.includes(this.getColumnTitle(x))
          && (
            !x.origin_column.filterColumn
            || x.origin_column.filterColumn(this, originComponent)
          )
        ))
        .map((x) => this.getColumnTitle(x))
    );

    return (
      viewColumnList.length === columnsList.length
        ? []
        : viewColumnList
    );
  }

  handleClickOutside = async (event) => {
    const { editId, isEditFormulaModalVisible } = this.state;;

    // Если какой-то элемент редактируется
    if (editId && !isEditFormulaModalVisible) {
      // Получаем основной div компонента
      const thisDiv = this.div.current;

      //guard 
      const pathRoot = event.path || (event.composedPath && event.composedPath());
      if (!pathRoot) return;

      // получаем дерево элементов, куда мы сейчас ткнули ...
      const path = pathRoot.map((x) => x.className).join(' ');
      // был ли там выпадающий список ...
      const isDropDown = (
        path.indexOf('ant-select-tree-title') !== -1
        || path.indexOf('ant-select-dropdown') !== -1
      );

      // Если клик был сделан вне текущего компонента и вне выпадающего списка, то сохраняем изменения.
      if ((!thisDiv || !thisDiv.contains(event.target)) && !isDropDown) {
        await this.onSaveEditedData();
      }
    }
  }

  onAddItem = async (event) => {
    const { selectedTreeItem, editId, editItem } = this.state;
    if (selectedTreeItem && selectedTreeItem.id !== '0') {
      if (editId && editItem && 'id' in editItem) {
        await this.onSaveEditedData(event, this.addItem);
      } else {
        this.addItem();
      }
    }
  };

  onEditItem = () => {
    const { t, onEditItem, checkRecordForEdit } = this.props;
    const { data, selectedId } = this.state;

    let editedItem = data.find((item) => item.id === selectedId);
    const enableEditItem = checkRecordForEdit
      ? checkRecordForEdit(editedItem)
      : true;

    if (enableEditItem) {
      if (onEditItem) {
        editedItem = onEditItem(editedItem);
      }
      this.setState({
        editId: selectedId,
        editItem: editedItem,
      });
    } else if (enableEditItem === false) {
      message.error(t('TREE_VALUES_DONT_ENABLE_EDIT_THIS_RECORD'));
    }
  };

  onSaveEditedData = async (event, callback = null) => {
    const {
      onSaveEditedData, onChangeTreeValueData, onShowButtonsSaveCancel, t,
    } = this.props;
    const { editItem, data } = this.state;

    if (editItem.name) {
      const newData = await onSaveEditedData(this);

      if (newData === false) {
        return false;
      }
      const newEditItem = cloneDeep(newData.find((x) => x.id === editItem.id));
      this.setState(
        {
          data: newData,
          editItem: {},
          editId: null,
        },
        () => {
          if (typeof callback === 'function') {
            callback();
          }
          if (newEditItem && 'isNewItem' in newEditItem) {
            onChangeTreeValueData(newData, 'insert', newEditItem);
          } else {
            onChangeTreeValueData(newData, 'edit', newEditItem);
          }
          onShowButtonsSaveCancel(true);
        }
      );
    } else {
      const newData = data.filter((item) => item.id !== editItem.id);
      this.setState({
        data: newData,
        editItem: {},
        editId: null,
      });
      onChangeTreeValueData(newData, 'revertInsert', {});
      message.warn(t('WARN_EMPTY_TREE_VALUE_ITEM'), 6);
    }
    return true;
  };

  onDeleteItem = () => {
    const { onChangeTreeValueData, onShowButtonsSaveCancel } = this.props;
    const { data, selectedId } = this.state;

    let newData = [...data];
    const itemForDelete = newData.find((item) => item.id === selectedId);
    newData = newData.filter((item) => item.id !== selectedId);
    this.setState(
      {
        data: newData,
      },
      () => {
        onChangeTreeValueData(
          newData,
          'delete',
          Array.isArray(itemForDelete) ? itemForDelete[0] : itemForDelete
        );
        onShowButtonsSaveCancel(true);
      }
    );
  };

  onCopyItem = () => {
    const { selectedId, data } = this.state;
    const { onCopyTreeValueData } = this.props;

    const copyItem = data.find((item) => item.id === selectedId);

    let pasteItem = cloneDeep(copyItem);
    if (onCopyTreeValueData) {
      pasteItem = onCopyTreeValueData(pasteItem);
    }
    delete pasteItem.id;
    delete pasteItem.category;
    delete pasteItem.parent;

    this.setState({
      pasteItem,
      pasteCount: 0,
    });
  }

  onPasteItem = () => {
    this.addItem(true);
  }

  addItem = (addPasteItem = false) => {
    const {
      newItem, onAddItem, onShowButtonsSaveCancel, onChangeTreeValueData,
    } = this.props;
    const {
      data, selectedTreeItem, selectedId, pasteItem, pasteCount,
    } = this.state;

    let newPasteCount = pasteCount;

    let item = newItem(this);
    if (addPasteItem) {
      item = { ...item, ...pasteItem };
      newPasteCount += 1;
      item.name = item.name.concat(` (${newPasteCount})`);
      item.pf_classname = item.pf_classname && item.pf_classname.length
        ? item.pf_classname.concat(`_${newPasteCount}`)
        : item.pf_classname;
    }
    if (item) {
      let selectedIndex = null;
      let groupIndex = data.length;
      data.forEach((x, index) => {
        if (x.id === selectedId) {
          selectedIndex = index;
        }
        if (x.category && x.category.id === selectedTreeItem.id) {
          groupIndex = index;
        }
      });
      const newData = [...data];
      newData.splice((selectedIndex !== null ? selectedIndex : groupIndex) + 1, 0, item);
      if (onAddItem && typeof onAddItem === 'function') {
        item = onAddItem(item);
      }
      if (item) {
        onShowButtonsSaveCancel(true);
        this.setState({
          data: newData,
          editId: addPasteItem ? null : item.id,
          editItem: addPasteItem ? null : item,
          selectedId: addPasteItem ? selectedId : item.id,
          pasteCount: newPasteCount,
        });
        if (addPasteItem && typeof onChangeTreeValueData === 'function') {
          onChangeTreeValueData(newData, 'insert', item);
        }
      }
    }
  };

  onDragStart = (event, item) => {
    const { onDragStartTreeValueItem } = this.props;

    const row = document.getElementById(`row_${item.id}`).cloneNode(true);
    const tableBody = document.createElement('div');
    tableBody.className = 'ant-table-body';
    tableBody.appendChild(row);
    const table = document.createElement('div');
    table.className = 'ant-table';
    table.appendChild(tableBody);
    const div = document.createElement('div');
    div.className = 'template-tree-value__body for-delete-div';
    div.appendChild(table);
    div.style.color = 'rgb(0,0,0,1)';
    div.style.backgroundColor = '#e6f7ff';
    div.style.height = '40px';
    document.body.appendChild(div);
    event.dataTransfer.setDragImage(div, 0, 0);
    onDragStartTreeValueItem(item);
    this.setState({
      dragItem: item,
      canDropItem: null,
      editId: null,
      editItem: {},
      selectedId: null,
    });
  };

  onDragEnter = (event, item) => {
    this.setState({
      canDropItem: item,
    });
  };

  onDragOver = (event, item) => {
    const { dragItem, canDropItem } = this.state;
    if (dragItem && dragItem.id && canDropItem && canDropItem.id === item.id && dragItem.id !== item.id) {
      event.preventDefault();
    }
  };

  onDrop = (event, item) => {
    const { onDropTreeValueItemInside } = this.props;
    const { dragItem } = this.state;

    onDropTreeValueItemInside(dragItem, item);
    this.setState({
      dragItem: null,
      canDropItem: null,
    });
    this.deleteAllDraggedFloatingElement();
  };

  deleteAllDraggedFloatingElement = () => {
    const forDelete = document.getElementsByClassName('for-delete-div');
    while (forDelete[0]) {
      forDelete[0].parentNode.removeChild(forDelete[0]);
    }
  };

  getColumnsForRender = () => {
    const { renderColumns } = this.props;
    const { data, selectedTreeItem } = this.state;

    const dataFiltered = selectedTreeItem && 'id' in selectedTreeItem && data && data.length > 0
      ? data.filter((item) => item.category && item.category.id && item.category.id === selectedTreeItem.id)
      : [];
    const columns = renderColumns(this, dataFiltered);
    const columnsList = columns.map((x) => this.getColumnTitle(x));
    const columnsListOptions = columns.map(
      (x) => ({
        value: this.getColumnTitle(x),
        label: this.getColumnTitleText(x),
      })
    );

    return {
      columns,
      columnsList,
      columnsListOptions,
    };
  }

  updateState(x) {
    this.setState(x);
  }

  renderColumnSettingButton = (isEnableButton) => {
    const { t } = this.props;
    const { viewColumnList } = this.state;

    const title = t('CREATE_TEMPLATE_BTN_SETTINGS');
    const { columns, columnsList, columnsListOptions } = this.getColumnsForRender();

    const newViewColumnList = (
      viewColumnList && viewColumnList.length
        ? viewColumnList
        : columnsList
    );
    const someColumnsEnabled = !!newViewColumnList.length;
    const allColumnsEnabled = newViewColumnList.length === columnsList.length;

    const content = (
      <div className='settings'>
        <Checkbox
          indeterminate={!allColumnsEnabled && someColumnsEnabled}
          checked={allColumnsEnabled}
          onChange={(e) => (
            e.target.checked
              ? this.saveSettingsFromStorage([], columns)
              : this.saveSettingsFromStorage([columnsList[0]], columns)
          )}
        >
          {t('CREATE_TEMPLATE_BTN_SETTINGS_ALL_COLUMNS')}
        </Checkbox>
        <Divider />
        <CheckboxGroup
          options={columnsListOptions}
          value={newViewColumnList}
          onChange={(list) => this.saveSettingsFromStorage(list, columns)}
        />
      </div>
    );

    return (
      <div className='template-tree-value__button-column-setting'>
        {
          isEnableButton
            ? (
              <Popover
                placement="bottom"
                title={title}
                content={content}
                trigger="click"
                getPopupContainer={(node) => node.closest('.template-tree-value__top')}
              >
                <Button
                  icon={<SettingOutlined />}
                  size='small'
                  disabled={!isEnableButton}
                />
              </Popover>
            )
            : (
              <Button
                icon={<SettingOutlined />}
                size='small'
                disabled={!isEnableButton}
              />
            )
        }
      </div>
    );
  };

  openEditFormulaModal = (value, callback, isEdit) => {
    this.setState({
      isEditFormulaModalVisible: true,
      formulaModalInitialValue: value,
      formulaModalCallback: callback,
      formulaIsEditing: isEdit || false
    })
  }

  closeEditFormulaModal = () => {
    setTimeout(() => {
      this.setState({
        isEditFormulaModalVisible: false,
        formulaModalInitialValue: null,
        formulaModalCallback: () => { },
        formulaIsEditing: false
      })
    }, 100)
  }

  onFormulaConfirm = (value) => {
    const { formulaModalCallback } = this.state;

    formulaModalCallback && formulaModalCallback(value);
    this.closeEditFormulaModal();
  }

  render() {
    const {
      isLoading,
      isCanEdit,
      isCanDragAndDrop,
      isShowButtonsPanel,
      enableStandardButtons,
      visibleStandardButtons,
      extraButtons,
      renderColumns,
      isEditTreeItem,
      modifiedTreeItemsList,
      checkErrorValueAndGetClassName,
      tableName,
      t,
      isViewTree,
      checkRecordForEdit,
      rowSelection,
      setpointsId,
      selectedGroup,
      rights,
    } = this.props;
    const {
      data,
      selectedTreeItem,
      selectedId,
      editId,
      editItem,
      dragItem,
      canDropItem,
      pasteItem,
      viewColumnList,
      isEditFormulaModalVisible,
      formulaModalInitialValue,
      formulaIsEditing
    } = this.state;

    const groupName = selectedTreeItem && 'name' in selectedTreeItem ? selectedTreeItem.name : '';

    const dataFiltered = selectedTreeItem && 'id' in selectedTreeItem && data && data.length > 0
      ? data.filter((item) => item.category && item.category.id && item.category.id === selectedTreeItem.id)
      : [];
    const count = dataFiltered && dataFiltered.length ? dataFiltered.length : 0;
    const selectedItem = selectedId && data && data.length > 0
      ? data.find((item) => item.id === selectedId)
      : null;
    const isCompareMode = checkRecordForEdit && checkRecordForEdit([]) === null;

    const isEdit = editId && editItem && 'id' in editItem;
    const enableEdit = (
      selectedId && selectedItem
        ? !selectedItem.wasAdd && isCanEdit && !isEditTreeItem && !isCompareMode
        : isCanEdit && !isEditTreeItem && !isCompareMode
    );
    const enableDragAndDrop = enableEdit && isCanDragAndDrop && !isEdit;
    const disableButtons = !enableEdit || (!isEdit && !selectedId);

    const enableTreeButton = !enableStandardButtons || enableStandardButtons.indexOf('tree') !== -1;
    const enableAddButton = !enableStandardButtons || enableStandardButtons.indexOf('add') !== -1;
    const enableEditButton = !enableStandardButtons || enableStandardButtons.indexOf('edit') !== -1;
    const enableDeleteButton = !enableStandardButtons || enableStandardButtons.indexOf('delete') !== -1;
    const enableCopyPasteButton = !enableStandardButtons || enableStandardButtons.indexOf('copy/paste') !== -1;
    const enableSettingPasteButton = !enableStandardButtons || enableStandardButtons.indexOf('settings') !== -1;

    const visibleTreeButton = !visibleStandardButtons || visibleStandardButtons.indexOf('tree') !== -1;
    const visibleAddButton = !visibleStandardButtons || visibleStandardButtons.indexOf('add') !== -1;
    const visibleEditButton = !visibleStandardButtons || visibleStandardButtons.indexOf('edit') !== -1;
    const visibleDeleteButton = !visibleStandardButtons || visibleStandardButtons.indexOf('delete') !== -1;
    const visibleCopyPasteButton = !visibleStandardButtons || visibleStandardButtons.indexOf('copy/paste') !== -1;
    const visibleSettingButton = !visibleStandardButtons || visibleStandardButtons.indexOf('settings') !== -1;

    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    let columns = enableDragAndDrop && !isReadOnly
      ? [
        {
          key: 'menu',
          width: '5px',
          resizable: true,
          className: 'template-tree-value__column-userInfo',
          onDragStart: (event, record) => this.onDragStart(event, record),
          render: (text, record) => {
            return <MenuOutlined onDragStart={(event) => this.onDragStart(event, record)} draggable />;
          },
        },
      ]
      : [];

    columns = [...columns, ...renderColumns(this, dataFiltered)]
      .filter((x) => (
        !x.origin_column
        || !x.origin_column.title
        || !viewColumnList.length
        || viewColumnList.includes(this.getColumnTitle(x))
      ));

    const onRow = (record) => {
      return {
        id: `row_${record.id}`,
        onDragOver: (event) => enableDragAndDrop && this.onDragOver(event, record),
        onDragEnter: (event) => enableDragAndDrop && this.onDragEnter(event, record),
        onDrop: (event) => enableDragAndDrop && this.onDrop(event, record),
        onClick: () => {
          const { onSelectItem } = this.props;

          if (editId && editId !== record.id && this.onSaveEditedData()) {
            // Nothing
          }

          onSelectItem && onSelectItem(record);
          if (editId !== record.id) {
            this.setState({
              selectedId: record.id,
            });
          }
        },
        onDoubleClick: () => enableEdit && isCanEdit && (!editId || editId !== record.id) && this.onEditItem(),
      };
    };

    const listProps = {
      locale: {
        emptyText: <CustomEmpty />,
      },
    };

    if (rowSelection) {
      listProps.rowSelection = rowSelection;
    }

    return (
      <div ref={this.div} className='template-tree-value'>
        <div className={`template-tree-value__top ${isShowButtonsPanel ? '' : 'hidden'}`}>
          <div className='template-tree-value__button-tree'>
            <Button
              type={isViewTree ? 'primary' : 'default'}
              size='small'
              icon={<UnorderedListOutlined />}
              className={visibleTreeButton ? '' : 'hidden'}
              onClick={(event) => this.onListItems(this, event)}
              disabled={!enableTreeButton || isEditTreeItem}
            />
          </div>
          <div className='template-tree-value__button-add'>
            <Button
              size='small'
              icon={<FileAddOutlined />}
              className={visibleAddButton ? '' : 'hidden'}
              onClick={this.onAddItem}
              disabled={!enableEdit || !selectedTreeItem || !selectedTreeItem.id || !enableAddButton || isEdit || isReadOnly}
            >
              {t('TREE_VALUES_ADD_TREE_VALUE_ITEM')}
            </Button>
          </div>
          <div className='template-tree-value__button-edit'>
            <Button
              size='small'
              icon={isEdit ? <SaveOutlined /> : <EditOutlined />}
              className={visibleEditButton ? '' : 'hidden'}
              onClick={isEdit ? this.onSaveEditedData : this.onEditItem}
              disabled={disableButtons || !enableEditButton || isReadOnly}
            >
              {isEdit
                ? t('TREE_VALUES_SAVE_TREE_VALUE_ITEM')
                : t('TREE_VALUES_EDIT_TREE_VALUE_ITEM')}
            </Button>
          </div>
          <div className='template-tree-value__button-delete'>
            {
              disableButtons || !enableDeleteButton || !visibleDeleteButton || isEdit || isReadOnly
                ? (
                  <Button
                    size='small'
                    icon={<DeleteOutlined />}
                    className={'disabled-button'.concat(visibleDeleteButton ? '' : ' hidden')}
                    disabled={true}
                  >
                    {t('TREE_VALUES_DELETE_TREE_VALUE_ITEM')}
                  </Button>
                )
                : (
                  <Popconfirm
                    title={t('TREE_VALUES_DELETE_CONFIRM')}
                    onConfirm={this.onDeleteItem}
                    okText={t('TREE_VALUES_YES')}
                    cancelText={t('TREE_VALUES_NO')}
                  >
                    <Button size='small' icon={<DeleteOutlined />}>
                      {t('TREE_VALUES_DELETE_TREE_VALUE_ITEM')}
                    </Button>
                  </Popconfirm>
                )
            }
          </div>
          <div className='template-tree-value__button-copy'>
            <Button
              size='small'
              icon={<CopyOutlined />}
              className={visibleCopyPasteButton ? '' : 'hidden'}
              onClick={(event) => this.onCopyItem(this, event)}
              disabled={disableButtons || !enableCopyPasteButton || isEdit || isReadOnly}
            >
              {t('TREE_VALUES_COPY_TREE_VALUE_ITEM')}
            </Button>
          </div>
          <div className='template-tree-value__button-paste'>
            <Button
              size='small'
              icon={<SnippetsOutlined />}
              className={visibleCopyPasteButton ? '' : 'hidden'}
              onClick={(event) => this.onPasteItem(this, event)}
              disabled={!enableCopyPasteButton || !selectedTreeItem || !selectedTreeItem.id || !pasteItem || isEdit || isReadOnly}
            >
              {t('TREE_VALUES_PASTE_TREE_VALUE_ITEM')}
            </Button>
          </div>
          {extraButtons ? extraButtons() : ''}
          {visibleSettingButton && this.renderColumnSettingButton(enableSettingPasteButton)}
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className='template-tree-value__body'
          onClick={(event) => {
            if ([
              'template-tree-value__body',
              'ant-spin-nested-loading',
            ].includes(event.target.className)) {
              if (editId && this.onSaveEditedData(event)) {
                // Nothing
              }
              this.setState({
                selectedId: null,
              });
            }
          }}
          onDragOver={(event) => {
            if (event.target.className === 'template-tree-value__body') {
              this.setState({
                canDropItem: null,
              });
            }
          }}
        >
          <Spin spinning={!!isLoading}>
            {
              groupName && groupName.length > 0
                ? (
                  <div className='template-tree-value__body_wrapper'>
                    <div className='template-tree-value__group-name'>
                      {groupName}
                      {
                        count
                          ? <span className='template-tree-value__count'>{count}</span>
                          : ''
                      }
                    </div>
                    <CommonTable
                      tableName={tableName || 'treeValue'}
                      {...listProps}
                      size={TABLE_SIZE}
                      key='tableTreeValues'
                      className={`treeValue ${groupName.length > 0 ? 'tableTreeValues' : 'empty'}`}
                      columns={columns}
                      dataSource={dataFiltered}
                      showHeader={groupName.length > 0}
                      bordered={false}
                      pagination={false}
                      onRow={onRow}
                      rowClassName={(record) => [
                        record.id === selectedId
                          ? 'rowSelected'
                          : '',
                        setpointsId
                          && record.task_dc_setpoint_value_id
                          && setpointsId.includes(record.task_dc_setpoint_value_id[selectedGroup]) ? 'selectedStatus' : '',
                        dragItem
                          && dragItem.id
                          && canDropItem
                          && canDropItem.id === record.id
                          && dragItem.id !== record.id
                          ? 'canDropItem'
                          : '',
                        modifiedTreeItemsList
                          && Array.isArray(modifiedTreeItemsList)
                          && modifiedTreeItemsList.length
                          && modifiedTreeItemsList.indexOf(record.id) !== -1
                          ? 'modified'
                          : '',
                        checkErrorValueAndGetClassName
                          && typeof checkErrorValueAndGetClassName === 'function'
                          ? checkErrorValueAndGetClassName(record)
                          : '',
                      ].join(' ')}
                    />
                  </div>
                )
                : (
                  <CustomEmpty />
                )
            }
          </Spin>
          <EditFormulaModal
            visible={isEditFormulaModalVisible}
            onClose={this.closeEditFormulaModal}
            onConfirm={this.onFormulaConfirm}
            initialFormula={formulaModalInitialValue}
            isEdit={formulaIsEditing} 
          />
        </div>
      </div>
    );
  }
}

const onDragStartTreeValueItem = (item, component) => {
  component.setState({
    dragTreeValueItem: item,
  });
};

const onDropTreeValueItemInside = (dragObject, dropAfterObject, component) => {
  if (dragObject && dragObject.id && dropAfterObject && dropAfterObject.id) {
    let dragIndex = null;
    let dropIndex = null;
    const data = component.state.treeValueData.map((item, index) => {
      if (item.id === dragObject.id) {
        dragIndex = index;
      }
      return cloneDeep(item);
    });

    if (dragIndex !== null) {
      data.splice(dragIndex, 1);
    }
    data.forEach((item, index) => {
      if (item.id === dropAfterObject.id) {
        dropIndex = index;
      }
    });
    if (dropIndex !== null) {
      data.splice(dropIndex + 1, 0, dragObject);
    }
    component.setState({
      treeValueData: data,
      showButtons: true,
    });
  }
};

const onClearDragTreeValue = (component) => {
  component.setState({
    dragTreeValueItem: {},
  });
};

const onChangeTreeValueData = (newTreeValueData, operation, itemForOperation, component) => {
  if (JSON.stringify(newTreeValueData) !== JSON.stringify(component.state.treeValueData)) {
    if ('isNewTemplate' in component.state) {
      const isNewTemplate = newTreeValueData.some((item) => item.isNewItem) || component.state.isNewTemplate;
      component.setState({
        treeValueData: newTreeValueData,
        isNewTemplate,
      });
    } else {
      component.setState({
        treeValueData: newTreeValueData,
      });
    }
  }
  component.setState({
    isEditTreeValueItem: false,
  });
};

const renderTitleForCheckBox = (dataFiltered, column, component) => {
  const treeItemId = (
    component
    && component.state
    && component.state.selectedTreeItem
    && component.state.selectedTreeItem.id
  );

  if (treeItemId) {
    if (column.showAlwaysAsEdit) {
      const fields = column.field && column.field.split('.');
      const checkChecked = (x) => (
        fields.length === 1
          ? !!(x[fields[0]])
          : !!(x[fields[0]][fields[1]])
      );
      const someChecked = (
        dataFiltered
        && fields
        && dataFiltered.length
        && dataFiltered.some((x) => checkChecked(x))
      );
      const allChecked = (
        someChecked
        && dataFiltered
        && fields
        && dataFiltered.length
        && dataFiltered.every((x) => checkChecked(x))
      );

      return (
        <div className='checkBox_all_fields'>
          {component.props.t(`TABLE_COL_${column.title}`)}
          <Checkbox
            key={`checkBox_all_fields_${column.field}_for_${treeItemId}`}
            indeterminate={!allChecked && someChecked}
            checked={allChecked}
            disabled={!dataFiltered || !dataFiltered.length}
            onChange={(e) => {
              e.target.checked
                ? dataFiltered.forEach(
                  (x) => {
                    fields.length === 1
                      ? x[fields[0]] = true
                      : x[fields[0]][fields[1]] = true;
                    component
                      && component.props.onChangeTreeValueData
                      && component.props.onChangeTreeValueData(component.state.data, 'batch-update', x);
                  }
                )
                : dataFiltered.forEach(
                  (x) => {
                    fields.length === 1
                      ? x[fields[0]] = false
                      : x[fields[0]][fields[1]] = false;
                    component
                      && component.props.onChangeTreeValueData
                      && component.props.onChangeTreeValueData(component.state.data, 'batch-update', x);
                  }
                );
              component
                && component.props.onShowButtonsSaveCancel
                && component.props.onShowButtonsSaveCancel(true);
            }}
          />
        </div>
      );
    } else {
      return (
        <div className='checkBox_all_fields'>
          {component.props.t(`TABLE_COL_${column.title}`)}
        </div>
      );
    }
  } else {
    return component.props.t(`TABLE_COL_${column.title}`);
  }
};

const mapStateToProps = state => ({
  rights: state.rights.rightsData
});

export {
  onDragStartTreeValueItem,
  onDropTreeValueItemInside,
  onClearDragTreeValue,
  onChangeTreeValueData,
  renderTitleForCheckBox,
};

export default connect(mapStateToProps)(TemplateTreeValues);