import React from 'react';
import { connect } from 'react-redux';
import { Button, Breadcrumb, message } from 'antd';
import { withTranslation } from 'react-i18next';

import store from '@state/store';
import api from '@services/api';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { setupGlobalSearchFiltersLoading } from '@state/search/actions';
import { PASSPORT_DETAILS } from '@common/sidebarRoot/types';
import { sleep } from '@globalHelpers';
import { Layout as CustomLayout } from '@ui';
import { cloneDeep } from 'lodash';

import Column from './column';
import {
  COLUMN_COUNT, COLUMN_WIDTH, COLUMNS, LEVEL_DEVICE,
} from './constants';

import styles from './styles.module.less';

class TreeFilter extends React.Component {
  xscroll = {};

  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
    this.state = {
      innerColumns: [...COLUMNS],
      loadingLevels: {},
      isEdit: false,
      edited: '',
      editedKey: '',
    };
  }

  componentDidUpdate = async (prevProps) => {
    const { archived } = this.props;
    const { innerColumns } = this.state;

    if (prevProps.archived
      && prevProps.archived.isArchivedData !== undefined
      && prevProps.archived.isArchivedData !== archived.isArchivedData
    ) {
      const search = this.onSearchPatternChange(1);
      search(innerColumns[0].searchPattern);
    }
  }

  onScroll = () => {
    if (this.xscrollTarget && this.xscroll) {
      this.xscrollTarget.scrollLeft = this.xscroll.scrollLeft;
    }
  };

  updateSideBarState = async () => {
    const state = store.getState();

    // before open
    if (this.sideBarState.isOpen === false && state.sidebar.isOpen === true) {
      await sleep(250); // wait till DOM change
      this.xscroll && this.xscroll.scroll((COLUMN_COUNT + 2) * COLUMN_WIDTH, 0);
    }

    this.sideBarState = state.sidebar;
  };

  columnsLoad = async (index, pattern) => {
    const { isEdit, innerColumns, loadingLevels } = this.state;

    this.setState({ loadingLevels: { ...loadingLevels, [index]: true } });

    const data = isEdit && innerColumns[index].loadForEdit
      ? await innerColumns[index].loadForEdit(pattern, innerColumns)
      : await innerColumns[index].load(pattern, innerColumns);

    this.setState({ loadingLevels: { ...loadingLevels, [index]: false } });

    await this.updateColumn(
      index,
      {
        searchPattern: pattern,
        list: data.results || [],
        next: data.next,
        previous: data.previous,
        total: data.count,
        loaded: (data && data.results) ? data.results.length : 0,
      },
      true
    );

    return data;
  }

  onSearchPatternChange = (index) => {
    return (pattern, element, callback = null) => {
      (async () => {
        const { innerColumns } = this.state;

        if (pattern === '' && index > 0 && !innerColumns[index - 1].activeElement) {
          await this.updateColumn(index, { list: [] }, true);
          return;
        }

        const setActiveElement = this.onColumnElementActivate(index);
        const oldActiveElement = innerColumns[index].activeElement;

        const data = await this.columnsLoad(index, pattern);

        const activeElement = (
          data && data.results && data.results.length === 1
            ? data.results[0]
            : (
              data
              && data.results
              && data.results.length
              && oldActiveElement
              && data.results.find((x) => x.id === oldActiveElement.id)
            ) || null
        );

        activeElement && setActiveElement(activeElement, true);

        if (callback) {
          callback();
        }
      })();
    };
  };

  changeIsCanDeleteValue = async (index) => {
    const { innerColumns } = this.state;

    if (
      innerColumns
      && innerColumns[index]
      && innerColumns[index].activeElement
      && innerColumns[index].activeElement.name
      && innerColumns[index].load
    ) {
      const { count, results: data } = await innerColumns[index].load(
        innerColumns[index].activeElement.name,
        innerColumns,
        {
          limit: 1,
        }
      );

      if (count > 0 && data && data.length && data[0] && 'is_can_delete' in data[0]) {
        setTimeout(
          () => this.setState((state) => {
            const newInnerColumns = state.innerColumns;
            const column = newInnerColumns[index];
            const el = column.activeElement;
            el.is_can_delete = data[0].is_can_delete;
            const list = column.list;
            const listEl = list.find((x) => x.id === el.id);
            if (listEl) {
              listEl.is_can_delete = data[0].is_can_delete;
            }

            return {
              innerColumns: newInnerColumns,
            };
          }),
          200
        );
      }
    }
  };

  onAddItem = (index) => {
    return (parent, element) => {
      (async () => {
        const { innerColumns } = this.state;

        const newList = cloneDeep(innerColumns[index].list) || [];
        const idx = newList.findIndex((x) => x.id === parent);
        newList.splice(idx + 1, 0, element);
        this.setState({
          edited: element.name,
          editedKey: element.id,
        }, async () => {
          await this.updateColumn(index, {
            list: newList,
            activeElement: element,
            total: (innerColumns[index].total || 0) + 1,
            loaded: (innerColumns[index].loaded || 0) + 1,
          });
        });
      })();
    };
  };

  onSaveItem = (index) => {
    return async () => {
      const { t } = this.props;
      const { edited, innerColumns } = this.state;

      if (innerColumns[index] && innerColumns[index].add && typeof innerColumns[index].add === 'function') {
        const data = await innerColumns[index].add(edited, innerColumns);
        if (data) {
          await this.columnsLoad(index, innerColumns[index].searchPattern);

          const { innerColumns: newInnerColumns } = this.state;
          const selected = (
            newInnerColumns
            && newInnerColumns[index]
            && newInnerColumns[index].list
            && newInnerColumns[index].list.length
            && newInnerColumns[index].list.find((x) => x.name === edited)
          );

          const elementActivate = this.onColumnElementActivate(index);
          selected && elementActivate && elementActivate(selected, true);

          this.clearEditItem();

          if (index === 3) {
            await this.changeIsCanDeleteValue(1);
          } else if (index > 0) {
            await this.changeIsCanDeleteValue(index - 1);
          }

          message.info(t('CREATE_SUCCESS'));
        } else {
          message.error(t('CREATE_ERROR'));
        }
      }
    };
  };

  onUpdateItem = (index) => {
    return async () => {
      const { t } = this.props;
      const { editedKey, edited, innerColumns } = this.state;

      if (innerColumns[index] && innerColumns[index].update && typeof innerColumns[index].update === 'function') {
        const data = await innerColumns[index].update(editedKey, edited);
        if (data) {
          await this.columnsLoad(index, innerColumns[index].searchPattern);
          this.clearEditItem();
          message.info(t('EDIT_SUCCESS'));
        } else {
          message.error(t('EDIT_ERROR'));
        }
      }
    };
  };

  onDeleteItem = (index) => {
    return (id) => {
      (async () => {
        const { t } = this.props;
        const { innerColumns } = this.state;

        const del = innerColumns[index].delete;

        if (!del) {
          message.warn(t('TREE_DELETE_TREE_ITEM_UNDEFINED'));
          return false;
        }

        const result = await del(id);

        if (result && result.status === 204) {
          const elementActivate = this.onColumnElementActivate(index);
          elementActivate && elementActivate({});
          message.info(t('TREE_DELETE_TREE_ITEM_SUCCESS'));
          if (index > 0 && innerColumns[index].list && innerColumns[index].list.length === 1) {
            await this.columnsLoad(index - 1, innerColumns[index - 1].searchPattern);
          }
          if (index === 3) {
            await this.changeIsCanDeleteValue(1);
          } else if (index > 0 && innerColumns[index].list && innerColumns[index].list.length > 1) {
            await this.changeIsCanDeleteValue(index - 1);
          }
        } else if (result && result.data && result.data.error) {
          message.error(`${t('TREE_DELETE_TREE_ITEM_ERROR')}: ${result.data.error}`);
        } else if (result && result.data && result.data.detail) {
          message.error(`${t('TREE_DELETE_TREE_ITEM_ERROR')}: ${result.data.detail}`);
        } else {
          message.error(t('TREE_DELETE_TREE_ITEM_ERROR'));
        }

        await this.columnsLoad(index, innerColumns[index].searchPattern);
      })();
    };
  };

  deleteItem = async (index, key) => {
    const { innerColumns } = this.state;

    const newList = cloneDeep(innerColumns[index].list);
    const idx = newList.findIndex((x) => x.id === key);
    newList.splice(idx, 1);
    await this.updateColumn(
      index,
      {
        list: newList,
        activeElement: undefined,
      },
      true
    );
  }

  clearEditItem = (callback) => {
    this.setState({
      editedKey: '',
      edited: '',
    }, async () => callback && callback());
  }

  onCancelEdit = (index) => {
    return async (isNewItem) => {
      const { editedKey } = this.state;

      this.clearEditItem(async () => {
        isNewItem && await this.deleteItem(index, editedKey);
      });
    };
  }

  onViewPassport = async (element) => {
    const { openDetails, setGlobalLoading } = this.props;

    setGlobalLoading(true);

    const { status, data } = await api.getProtectionDevice(element.id);

    if (
      status === 200
      && data
      && data.passports
      && data.passports.length
      && data.passports[0]
      && data.passports[0].id
    ) {
      const { status: statusPassport } = await api.getPassport(data.passports[0].id);

      if (statusPassport === 200) {
        openDetails(
          PASSPORT_DETAILS,
          {
            ...data,
            treeFooter: true,
          },
          360,
          true
        );
      }
    }

    setGlobalLoading(false);
  }

  onColumnElementActivate = (index) => {
    const { editedKey, isEdit } = this.state;

    if (COLUMNS[index] && COLUMNS[index].levelName === LEVEL_DEVICE) {
      return (element) => {
        (async () => {
          if (!element) {
            return;
          }

          await this.updateColumn(index, { activeElement: element });

          editedKey === '' && this.xscroll && this.xscroll.scroll((COLUMN_COUNT + 2) * COLUMN_WIDTH, 0);

          !isEdit && await this.onViewPassport(element);
        })();
      };
    }

    return (element, force = false) => {
      (async () => {
        const { innerColumns: oldInnerColumns } = this.state;

        if (element
          && !force
          && oldInnerColumns[index].activeElement
          && oldInnerColumns[index].activeElement.id === element.id
        ) {
          return;
        }

        if (
          this.xscroll
          && editedKey === ''
          && ((index - 1) * COLUMN_WIDTH) > (this.xscroll && this.xscroll.scrollLeft)
        ) {
          this.xscroll.scrollLeft = (index - 1) * COLUMN_WIDTH;
        }

        await this.updateColumn(index, { activeElement: element }, true);

        element && element.id && await this.columnsLoad(index + 1, '');
      })();
    };
  };

  goToLevelByItem = async (item) => {
    const { editedKey } = this.state;

    const index = item.level;
    const scrollPosition = index * COLUMN_WIDTH;

    if (!COLUMNS[index]) return;

    if (editedKey === '' && this.xscroll) {
      this.xscroll.scrollLeft = scrollPosition;
    }

    await this.updateColumn(index + 1, { activeElement: null }, true);
  };

  loadMore = async (index) => {
    const { innerColumns, loadingLevels } = this.state;

    const url = innerColumns[index].next ? new URL(innerColumns[index].next) : {};

    if (url.pathname && url.search) {
      const request = url.pathname + url.search;

      this.setState({ loadingLevels: { ...loadingLevels, [index]: true } });

      const { status, data } = await api.urlGetRequest(request);

      this.setState({ loadingLevels: { ...loadingLevels, [index]: false } });

      if (status === 200) {
        const list = innerColumns[index].list.concat(data.results);
        await this.updateColumn(index, {
          list,
          next: data.next,
          previous: data.previous,
          total: data.count || 0,
          loaded: list ? list.length : 0,
        });
      }
    }
  };

  async updateColumn(index, props, isDeleteNextAll = false) {
    const { innerColumns } = this.state;

    const newInnerColumns = innerColumns;
    newInnerColumns[index] = { ...newInnerColumns[index], ...props };
    if (isDeleteNextAll) {
      for (let i = index + 1; i < COLUMN_COUNT; i += 1) {
        newInnerColumns[i].activeElement = null;
        newInnerColumns[i].list = [];
        newInnerColumns[i].searchPattern = '';
      }
    }

    this.setState({ innerColumns: newInnerColumns });

    return newInnerColumns;
  }

  render() {
    const { t } = this.props;
    const {
      innerColumns, loadingLevels, isEdit, edited, editedKey,
    } = this.state;

    const isEnableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP === undefined;
    const breadcrumbs = innerColumns.filter(({ activeElement }) => !!activeElement);

    return (
      <CustomLayout
        title={t('TREE_TREE_TITLE')}
        titleExtraButton={
          isEnableEditAIP
            ? (
              <Button
                size='small'
                disabled={editedKey !== ''}
                type={isEdit ? 'primary' : 'default'}
                onClick={() => this.setState((state) => ({ isEdit: !state.isEdit }))}
              >
                {isEdit ? t('DISABLE_EDIT') : t('ENABLE_EDIT')}
              </Button>
            )
            : ''
        }
        renderBreadcrumbs={(
          <Breadcrumb>
            <Breadcrumb.Item
              key={0}
              onClick={() => (
                editedKey !== ''
                  ? false
                  : this.goToLevelByItem({
                    level: 0,
                    activeElement: breadcrumbs[0] && breadcrumbs[0].activeElement,
                  })
              )}
            >
              Объекты системы
            </Breadcrumb.Item>
            {breadcrumbs.map((item) => {
              return (
                <Breadcrumb.Item
                  key={`Breadcrumb_Item_${item.levelName}`}
                  onClick={() => (
                    editedKey !== ''
                      ? false
                      : this.goToLevelByItem(item)
                  )}
                >
                  {!COLUMNS[item.level] ? (
                    <span className='ant-breadcrumb_inactive'>{item.activeElement.name}</span>
                  ) : (
                    item.activeElement.name
                  )}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        )}
      >
        <div className={styles.elementsTree} ref={this.contentRef}>
          <div
            style={{
              width: '100%',
              overflowX: 'scroll',
            }}
            ref={(input) => {
              this.xscroll = input;
            }}
          >
            <div className={styles.columnsWrapper} style={{ width: COLUMN_WIDTH * COLUMN_COUNT, height: `calc(100vh - ${150}px)` }}>
              {COLUMNS.map((item) => {
                const index = item.level;
                return (
                  <Column
                    isEdit={isEdit}
                    isLoading={loadingLevels[index]}
                    extendLoader={innerColumns[index].extendLoader}
                    total={innerColumns[index].total}
                    loaded={innerColumns[index].loaded}
                    key={index}
                    indexColumn={index}
                    levelName={innerColumns[index].levelName}
                    list={innerColumns[index].list}
                    searchPattern={innerColumns[index].searchPattern}
                    title={t(`TREE_TREE_LEVEL_${index + 1}_TYPE_NAME`)}
                    activeElement={innerColumns[index] && innerColumns[index].activeElement}
                    prevActiveElement={innerColumns[index - 1] && innerColumns[index - 1].activeElement}
                    onElementActivate={this.onColumnElementActivate(index)}
                    onSearchPatternChange={this.onSearchPatternChange(index)}
                    onAddItem={this.onAddItem(index)}
                    onSaveItem={this.onSaveItem(index)}
                    onUpdateItem={this.onUpdateItem(index)}
                    onDeleteItem={this.onDeleteItem(index)}
                    onLoadMore={(level) => this.loadMore(level)}
                    isEditElement={editedKey !== ''}
                    edited={edited}
                    editedKey={editedKey}
                    onStartEdit={(key, value) => this.setState({ editedKey: key, edited: value })}
                    onChangeEdit={(value) => this.setState({ edited: value })}
                    onCancelEdit={this.onCancelEdit(index)}
                    onViewPassport={this.onViewPassport}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </CustomLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  archived: state.archived
});

const mapDispatchToProps = {
  openDetails: openSidebar,
  closeDetails: closeSidebar,
  setGlobalLoading: setupGlobalSearchFiltersLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    TreeFilter
  )
);
