import React from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import DateSpot from './Spot';
import styles from '../styles.module.less';

import {
  DATE_ITEM_WIDTH,
  SPOT_SIZE,
  PLANNED_STATUS,
  DATE_DAY_FORMAT,
  START_OFFSET,
} from './constants';

type Props = {
  dateTo: Moment;
  dateFrom: Moment;
  dateStart: string;
  dateEnd: string;
  isEndNeeded?: boolean;
  existsSpotPlaces: Record<string, number>;
  type: string;
  name: string;
  offsetX?: number;
  isEqStartDate?: boolean;
  isEqEndDate?: boolean;
};

const Line = ({
  dateFrom,
  dateTo,
  dateStart,
  dateEnd,
  existsSpotPlaces,
  type = 'actual',
  name = '',
  isEndNeeded = true,
  offsetX = 0,
  isEqStartDate = false,
  isEqEndDate = false,
}: Props) => {
  const { t } = useTranslation();

  const momentDate = moment(dateStart || dateFrom);
  const momentEnd = moment(dateEnd || dateTo);

  const isAfterOfStart = momentDate.isAfter(dateTo, 'day');
  const isAfterOfEnd = momentEnd.isAfter(dateTo, 'day');
  const isBeforeOfStart = momentDate.isBefore(dateFrom, 'day');
  const isBeforeOfEnd = momentEnd.isBefore(dateFrom, 'day');

  const currentStart = isAfterOfStart ? dateTo : isBeforeOfStart ? dateFrom : momentDate;
  const currentEnd = isAfterOfEnd ? dateTo : isBeforeOfEnd ? dateFrom : momentEnd;

  const cntDaysFromStart = currentStart.diff(dateFrom, 'days') + 1;
  const cntDaysToEnd = currentEnd.diff(dateFrom, 'days') + (isAfterOfEnd ? 2 : 1);

  const offsetLeft = (!isBeforeOfStart ? cntDaysFromStart : 0) + offsetX;
  const width = !isBeforeOfEnd ? cntDaysToEnd : 0;

  const isEqStartEnd = momentEnd.diff(momentDate, 'days') === 0;
  const isLineNeeded = dateEnd && dateStart && !isEqStartEnd && !isAfterOfStart && !isBeforeOfEnd;
  const nextExistsSpotPlaces = { ...existsSpotPlaces };

  nextExistsSpotPlaces[dateStart] = existsSpotPlaces[dateStart] || 0;
  nextExistsSpotPlaces[dateEnd] = existsSpotPlaces[dateEnd] || 0;

  if (dateStart) {
    nextExistsSpotPlaces[dateStart] += isBeforeOfEnd ? 2 : 1;
  }
  const startPoint = nextExistsSpotPlaces[dateStart];
  if (dateEnd) {
    nextExistsSpotPlaces[dateEnd] += isAfterOfStart ? 2 : 1;
  }
  const endPoint = nextExistsSpotPlaces[dateEnd];

  const isStartInPeriod = !isAfterOfStart && !isBeforeOfStart;
  const isEndInPeriod = !isAfterOfEnd && !isBeforeOfEnd;

  const textStartDate = type === PLANNED_STATUS
    ? t('START_DATE_PLANNED')
    : t('START_DATE_ACTUAL');

  const textEndDate = type === PLANNED_STATUS
    ? t('END_DATE_PLANNED')
    : t('END_DATE_ACTUAL');

  return (
    <>
      {dateStart && isStartInPeriod && (
        <DateSpot
          key={`${name}_${type}_start`}
          isStart
          isEqStartEnd={isEqStartEnd || isEqStartDate}
          spotNumber={startPoint}
          type={type}
          left={offsetLeft - (isBeforeOfStart ? START_OFFSET : 0)}
          date={startPoint === 1 ? moment(dateStart).format(DATE_DAY_FORMAT) : ''}
          tooltipDate={
            (
              <span>
                {textStartDate}:<br />
                {dateStart}
              </span>
            )
          }
        />
      )}
      {dateEnd && isEndInPeriod && isEndNeeded && !isAfterOfStart && (
        <DateSpot
          key={`${name}_${type}_end`}
          isEqStartEnd={isEqStartEnd || isEqEndDate}
          spotNumber={endPoint}
          type={type}
          left={width + (isAfterOfEnd ? START_OFFSET : 0)}
          date={endPoint === 1 ? moment(dateEnd).format(DATE_DAY_FORMAT) : ''}
          tooltipDate={
            (
              <span>
                {textEndDate}:<br />
                {dateEnd}
              </span>
            )
          }
        />
      )}
      {isLineNeeded && (
        <div
          key={`${name}_${type}_line`}
          className={type === PLANNED_STATUS ? styles.dashed : styles.line}
          style={{
            left: offsetLeft * DATE_ITEM_WIDTH + (DATE_ITEM_WIDTH / 2) + (
              isBeforeOfStart
                ? 0
                : START_OFFSET
            ),
            width: (width - offsetLeft) * DATE_ITEM_WIDTH - SPOT_SIZE + (
              isAfterOfEnd
                ? (
                  isBeforeOfStart
                    ? 2 * START_OFFSET
                    : START_OFFSET
                )
                : (
                  isBeforeOfStart
                    ? START_OFFSET
                    : 0
                )
            ),
          }}
        />
      )}
    </>
  );
};

export default Line;
