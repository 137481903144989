import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withPagination } from '@hoc';
import api from '@services/api';
import copy from 'copy-to-clipboard';

import {
  Button, Empty, message, Spin,
} from 'antd';

import CommonTable from '@common/CommonTable';
import { PopoverEllipsis } from '@ui';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

class SavedQueries extends Component {
  tableName = 'globalSearch_search_query';

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      count: 0,
    };
  }

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName);
    this.changePage(1);
  }

  componentDidUpdate(prevProps) {
    const {
      isReloadQueryList, unsetReloadQueryList, limit, filterValue,
    } = this.props;

    if (prevProps.isReloadQueryList !== isReloadQueryList) {
      this.loadData();
      unsetReloadQueryList();
    }
    if (prevProps.limit !== limit) {
      this.changePage(1);
    }
    if (prevProps.filterValue !== filterValue) {
      this.changePage(1);
    }
  }

  loadData = async () => {
    const { userId, loadByPage, filterValue } = this.props;
    const requestParams = { user: userId };

    if (filterValue) {
      requestParams.name__icontains = filterValue.toLowerCase();
    }

    this.setState({ isLoading: true });
    await loadByPage(this.tableName, 'getSearchQueryList', requestParams, [200, 201], (status, data) => {
      this.setState({
        data: data.results,
        count: data.count,
      });
    });
    this.setState({ isLoading: false });
  }

  changePage = (page) => {
    const { changePage: mainChangePage } = this.props;

    mainChangePage(page, () => {
      this.loadData();
    });
  }

  getListProps = () => {
    const { t, page, limit } = this.props;
    const { count } = this.state;
    return {
      locale: {
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>{t('NOT_FOUND_BY_PARAMETERS')}</span>}
          />
        ),
      },
      pagination: {
        onChange: this.changePage,
        total: count,
        pageSize: limit,
        current: page,
      },
      size: 'small',
      tableLayout: 'auto',
    };
  };

  onDeleteQuery = async (e, id) => {
    const { t } = this.props;

    this.setState({ isLoading: true });
    const { status } = await api.deleteSearchQuery(id);

    if (status === 204) {
      await this.loadData();
      message.success(t('SEARCH_QUERY_DELETE_OK'));
    } else {
      message.error(t('SEARCH_QUERY_DELETE_ERROR'));
    }
    this.setState({ isLoading: false });
  }

  onCopyQuery = (e, url) => {
    const { t, unsetVisible } = this.props;

    if (copy(url)) {
      message.success(t('SEARCH_QUERY_COPY_TO_CLIPBOARD_OK'));
    } else {
      message.error(t('SEARCH_QUERY_COPY_TO_CLIPBOARD_ERROR'));
    }

    unsetVisible();
  }

  render() {
    const { t, unsetVisible, onShowSizeChange } = this.props;
    const { isLoading, data } = this.state;

    const listProps = this.getListProps();
    const columns = [
      {
        title: t('COL_SEARCH_QUERY_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '85%',
        minWidth: 100,
        render: (text, record) => (
          <Button
            tabIndex={0}
            type='link'
            size='small'
            className={styles.link}
            title={t('SEARCH_QUERY_APPLY')}
            onClick={async () => {
              window.location = decodeURI(record.url);
              unsetVisible();
            }}
          >
            <PopoverEllipsis content={text}>
              {text}
            </PopoverEllipsis>
          </Button>
        ),
      },
      {
        title: '',
        key: 'action',
        width: '15%',
        render: (text, record) => {
          /* Оставлю временно, если нужно будет отобразить кнопки со значками, а не просто значки
          return (
            <>
              <Button
                icon={<DeleteOutlined />}
                size='small'
                onClick={(e) => this.onDeleteQuery(e, record.id)}
              />
              <Button
                icon={<CopyOutlined />}
                size='small'
                onClick={(e) => this.onCopyQuery(e, record.url)}
              />
            </>
          );
          */

          return (
            <div className={styles.action}>
              <span className={styles.delete}>
                <DeleteOutlined
                  title={t('SEARCH_QUERY_DELETE')}
                  size='medium'
                  onClick={(e) => this.onDeleteQuery(e, record.id)}
                />
              </span>
              <span className={styles.copy}>
                <CopyOutlined
                  title={t('SEARCH_QUERY_COPY_TO_CLIPBOARD')}
                  size='small'
                  onClick={(e) => this.onCopyQuery(e, decodeURI(record.url))}
                />
              </span>
            </div>
          );
        },
      },
    ];

    return (
      <div className={styles.table}>
        <Spin spinning={isLoading}>
          <CommonTable
            {...listProps}
            tableName={this.tableName}
            columns={columns}
            dataSource={data}
            loading={isLoading}
            bordered={false}
            size='small'
            onShowSizeChange={(page, pageSize) => onShowSizeChange(page, pageSize, this.tableName)}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.user_id,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withPagination(
      SavedQueries
    )
  )
);
