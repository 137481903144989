import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Popover, Progress, Tooltip } from 'antd';
import cn from 'classnames';
import { clamp, toNumber } from 'lodash';
import { InfoCircleOutlined, ClockCircleFilled, SafetyCertificateFilled } from '@ant-design/icons';
import crypto from 'crypto';
import {
  CHUNK_DELAY_IN_SECONDS,
  CHUNK_MAX_RESENT_COUNT,
  CHUNK_SIZE_KB,
  KB,
  STORAGE_USE_CHUNK,
  STORAGE_WARNINGS,
  STORAGE_WARNINGS_CAUTION_OFFSET,
} from '@globalConstants';
import api from '@services/api';
import storage from '@services/storage';
import { PopoverEllipsis } from '@ui/index';
import { closeSidebar, showPrompt } from '@state/sidebar/actions';
import store from '@state/store';
import moment from 'moment';

const warningColors = ['black','orange','red'];

const decodePath = (fileName) => {
  let newFileName = fileName;
  let i = 0;
  while (newFileName.indexOf('%') !== -1 && i < 10) {
    newFileName = decodeURI(newFileName);
    i += 1;
  }
  return decodeURIComponent(newFileName);
};

const viewFileSize = (size_in_byte, elementNumber) => {
  let size = (
    typeof size_in_byte === 'number'
      ? size_in_byte
      : parseFloat(size_in_byte)
  );
  const name = ['b', 'Kb', 'Mb', 'Gb', 'Tb'];
  let level = 0;
  while (size > 1024 && level < name.length) {
    size /= 1024;
    level += 1;
  }
  return (
    size === 0
      ? (
        elementNumber === undefined || elementNumber === 0
          ? '-'
          : ''
      )
      : (
        elementNumber === undefined
          ? `${size.toFixed(2)} ${name[level]}`
          : (
            elementNumber === 0
              ? size.toFixed(2).toString()
              : name[level]
          )
      )
  );
};

const WarningIcon = ({ icon, tier = 0, tip = ''}) => {
  if (!tier || !icon) return null;

  return (
    <div style={{ color: warningColors.at(tier) || 'black' }}>
      <Tooltip title={tip}>
      {icon}
      </Tooltip>
    </div>
  )
}

const avoidNull = (_value, _default) => {
  if (_value !== null && _value !== undefined) return _value;
  return _default;
}

const Warnings = ({ record, settings }) => {
  const [workingDaysTier, setWorkingDaysTier] = useState(0);
  const [certificateDaysTier, setCertificateDaysTier] = useState(0);
  const { t } = useTranslation();
  const data = record && record.host_data;
  const dataToday = data && data.length && data.find((x) => x.tags && x.tags.length && x.tags[0] && x.tags[0].name.includes('today'));
  const dataYesterday = data && data.length && data.find((x) => x.tags && x.tags.length && x.tags[0] && x.tags[0].name.includes('yesterday'));
  const dataCurrent = dataToday || dataYesterday;
  const workingDays = avoidNull(dataCurrent?.working_days,0);
  const certificateExpireDays = avoidNull(dataCurrent?.days_to_expiration,9999);
  const uptimeWarningPoint = avoidNull(settings?.STORAGE_UPTIME_WARNING_POINT, STORAGE_WARNINGS.uptime);
  const certificateWarningPoint = avoidNull(settings?.STORAGE_CERTIFICATE_WARNING_POINT, STORAGE_WARNINGS.certificate);

  useEffect(() => {
    updateTiers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTiers = () => {
    let newWorkingDaysTier = 0;
    let newCertificateDaysTier = 0;

    const uptimeSpotCaution = clamp(uptimeWarningPoint - STORAGE_WARNINGS_CAUTION_OFFSET, 0, 1000);
    const certificateSpotCaution = clamp(certificateWarningPoint + STORAGE_WARNINGS_CAUTION_OFFSET, 0, 1000);

    if (workingDays >= uptimeSpotCaution) newWorkingDaysTier = 1;
    if (workingDays >= uptimeWarningPoint) newWorkingDaysTier = 2;
    if (certificateExpireDays <= certificateSpotCaution) newCertificateDaysTier = 1;
    if (certificateExpireDays <= certificateWarningPoint) newCertificateDaysTier = 2;

    setWorkingDaysTier(newWorkingDaysTier);
    setCertificateDaysTier(newCertificateDaysTier)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
      <WarningIcon
        icon={<ClockCircleFilled />}
        tier={workingDaysTier}
        tip={`${t('STORAGE_WARNING_UPTIME_PART1')}${workingDays}${t('STORAGE_WARNING_UPTIME_PART2')} ${workingDaysTier === 2 ? t('STORAGE_WARNING_UPTIME_PART3') : ''}`} />
      <WarningIcon
        icon={<SafetyCertificateFilled />}
        tier={certificateDaysTier}
        tip={
          certificateExpireDays > 0
            ? `${t('STORAGE_WARNING_CERTIFICATE_PART1')}${certificateExpireDays}${t(
              'STORAGE_WARNING_CERTIFICATE_PART2'
            )}`
            : certificateExpireDays === 0
              ? `${t('STORAGE_WARNING_CERTIFICATE_PART3')} ${t('STORAGE_INFO_TODAY')}`
              : `${t('STORAGE_WARNING_CERTIFICATE_PART3')} ${Math.abs(certificateExpireDays)} ${t(
                'STORAGE_WARNING_CERTIFICATE_PART4'
              )}`
        }
      />
    </div>
  )
}

const StorageState = ({ record, withInfo = true, noProgress = false, type }) => {
  const { t } = useTranslation();
  const data = record && record.host_data;
  const data_today = data && data.length && data.find((x) => x.tags && x.tags.length && x.tags[0] && x.tags[0].name.includes('today'));
  const data_yesterday = data && data.length && data.find((x) => x.tags && x.tags.length && x.tags[0] && x.tags[0].name.includes('yesterday'));
  const data_current = data_today || data_yesterday || undefined;

  const getWorkingDaysInfo = () => {
    if (data_today?.working_days === null || data_today?.working_days === undefined) return '-';
    if (data_today?.working_days <= 1) return t('STORAGE_INFO_LESS_THAN_ONE_DAY');
    return data_today?.working_days
  }

  const getCertificateExpirationInfo = () => {
    if (data_today?.days_to_expiration === null || data_today?.days_to_expiration === undefined) return '-';
    return data_today?.days_to_expiration
  }

  const renderDetailInfo = (today, yesterday, current) => {
    const all = toNumber(current.all_disk_space);
    const free = toNumber(current.free_disk_space);
    const percentFree = Math.ceil(((all - free) / all) * 100);
    const colorForPercent = (
      percentFree < 70
        ? ''
        : (
          percentFree < 90
            ? 'storage-state-warning'
            : 'storage-state-error'
        )
    );
    const colorForPercentProgress = (
      percentFree < 70
        ? 'green'
        : (
          percentFree < 90
            ? 'orange'
            : 'red'
        )
    );
    const percentFreeProgress = (
      <span className={cn('storage-state-percent', colorForPercent)}>
        <Progress
          className='storage-state-percent-progress'
          percent={percentFree}
          steps={10}
          type='line'
          size='small'
          strokeWidth={12}
          width={10}
          strokeColor={colorForPercentProgress}
          trailColor='lightgray'
          status='normal'
        />
      </span>
    );
    const percentFreeText = (
      <span className={cn('storage-state-percent', colorForPercent)}>
        {percentFree}&nbsp;%
      </span>
    );

    const workingDays = getWorkingDaysInfo();
    const certificateExpiration = getCertificateExpirationInfo();

    const text = (
      <div className='storage-popover-text'>
        {t('STORAGE_DATE_UPDATE')}: {moment(current.date).format('DD.MM.YYYY')}<br />
        <br />
        <table>
          <thead>
            <tr>
              <th>{t('STORAGE_NAME_PARAMETERS')}</th>
              {today && (<th>{t('STORAGE_TODAY')}</th>)}
              {yesterday && (<th>{t('STORAGE_YESTERDAY')}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('STORAGE_ALL_SPACE')}</td>
              {today && (<td>{viewFileSize(today.all_disk_space)}</td>)}
              {yesterday && (<td>{viewFileSize(yesterday.all_disk_space)}</td>)}
            </tr>
            <tr>
              <td>{t('STORAGE_FREE_SPACE')}</td>
              {today && (<td>{viewFileSize(today.free_disk_space)}</td>)}
              {yesterday && (<td>{viewFileSize(yesterday.free_disk_space)}</td>)}
            </tr>
            <tr>
              <td>{t('STORAGE_NUMBER_DOWNLOADED_FILES')}</td>
              {today && (<td>{today.number_downloaded_files}</td>)}
              {yesterday && (<td>{yesterday.number_downloaded_files}</td>)}
            </tr>
            <tr>
              <td>{t('STORAGE_NUMBER_FILES_TO_BE_DELETED')}</td>
              {today && (<td>{today.number_files_to_be_deleted}</td>)}
              {yesterday && (<td>{yesterday.number_files_to_be_deleted}</td>)}
            </tr>
            <tr>
              <td>{t('STORAGE_NUMBER_UPLOADED_FILES')}</td>
              {today && (<td>{today.number_uploaded_files}</td>)}
              {yesterday && (<td>{yesterday.number_uploaded_files}</td>)}
            </tr>
            <tr>
              <td>{t('STORAGE_SIZE_DOWNLOADED_FILES')}</td>
              {today && (<td>{viewFileSize(today.size_downloaded_files)}</td>)}
              {yesterday && (<td>{viewFileSize(yesterday.size_downloaded_files)}</td>)}
            </tr>
            <tr>
              <td>{t('STORAGE_SIZE_UPLOADED_FILES')}</td>
              {today && (<td>{viewFileSize(today.size_uploaded_files)}</td>)}
              {yesterday && (<td>{viewFileSize(yesterday.size_uploaded_files)}</td>)}
            </tr>
            {today &&
              yesterday &&
              today.version_storage !== '1970.1.1.0.0.0' &&
              yesterday.version_storage !== '1970.1.1.0.0.0' && (
                <tr>
                  <td>{t('STORAGE_VERSION')}</td>
                  <td>{today.version_storage}</td>
                  <td>{yesterday.version_storage}</td>
                </tr>
              )}
            <tr>
              <td>{t('STORAGE_UPTIME')}</td>
              <td>{workingDays}</td>
            </tr>
            <tr>
              {certificateExpiration > 0 ? (
                <>
                  <td>{t('STORAGE_CERTIFICATE_EXPIRATION')}</td>
                  <td>{certificateExpiration}</td>
                </>
              ) : certificateExpiration === 0 ? (
                <>
                  <td>{t('STORAGE_WARNING_CERTIFICATE_PART3')}</td>
                  <td>{t('STORAGE_INFO_TODAY')}</td>
                </>
              ) : (
                <>
                  <td>
                    {t('STORAGE_WARNING_CERTIFICATE_PART3')} {t('STORAGE_WARNING_CERTIFICATE_PART4')}
                  </td>
                  <td>{Math.abs(certificateExpiration)}</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    );
    return (
      <div className='storage-state-block-wrapper'>
        { !noProgress && (
          type === 'progress'
            ? percentFreeProgress
            : percentFreeText
          )
        }

        {
          withInfo && (
            <>
              <Popover
                overlayClassName='storage-state-popover'
                content={text}
                trigger="hover"
                placement='top'
                key={`storage-${record.id}-state-popover_${current.id}`}
              >
                <InfoCircleOutlined className='storage-popover-info' />
              </Popover>
            </>
          )
        }
      </div>
    );
  };

  return (
    <div className='storage-state-block'>
      {
        data_current && data_current.id
          ? renderDetailInfo(data_today, data_yesterday, data_current)
          : ''
      }
    </div>
  );
};

const getObjectFromUrl = (location, params = []) => {
  const urlParams = new URLSearchParams(location.search);
  const object = {};

  params.forEach((item) => {
    object[item] = urlParams.get(item);
  });

  return object;
};

const sleep = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

const verifyExtensionBeforeSave = (name, externalLink) => {
  let externalExtension = null;
  let nameExtension = null;
  let newName = name;

  if (externalLink && externalLink.indexOf('.') !== -1) {
    const extensionRegex = /(.*?)\.([\w\d]+?)$/;
    const matchesExternal = extensionRegex.exec(externalLink);
    const matchesName = extensionRegex.exec(name);

    if (matchesExternal != null && matchesExternal[2]) {
      externalExtension = matchesExternal[2].replace(/['"]/g, '');
    }

    if (matchesName != null && matchesName[2]) {
      nameExtension = matchesName[2].replace(/['"]/g, '');
    }

    if (externalExtension !== nameExtension) {
      newName = newName.concat('.', externalExtension);
    }
  }

  return newName;
};

const clearHashFromFile = (fileName) => (fileName.replace(/^__dbrza_hash__[a-f0-9]{32}__/, ''));

const getFileNameFromLink = (text, isStorage) => {
  let viewText = text;
  if (isStorage) {
    const arr = text.split('/');
    viewText = arr[arr.length - 1];
  }
  return clearHashFromFile(viewText);
};

const getPopoverFileNameFromLink = (text, isStorage, content) => {
  const viewText = getFileNameFromLink(text, isStorage);
  const newContent = content || viewText;
  return (
    <PopoverEllipsis content={newContent} popoverPlacement='left'>
      {viewText}
    </PopoverEllipsis>
  );
};

const getHashForFile = () => {
  const time = new Date().getTime().toString();
  const hash = crypto.createHash('md5').update(time).digest('hex');
  return '__dbrza_hash__'.concat(hash, '__');
};

const openSaveFileDialog = (data, fileName, externalLink, mimeType) => {
  if (!data) return;

  const blob = data.constructor !== Blob
    ? new Blob([data], { type: mimeType || 'application/octet-stream' })
    : data;

  let newFileName = decodePath(fileName);
  newFileName = verifyExtensionBeforeSave(getFileNameFromLink(newFileName, true), externalLink);

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, newFileName);
    return;
  }

  const lnk = document.createElement('a');
  const url = window.URL;

  if (mimeType) {
    lnk.type = mimeType;
  }

  lnk.download = newFileName || 'untitled';
  const objectURL = url.createObjectURL(blob);
  lnk.href = objectURL;
  lnk.dispatchEvent(new MouseEvent('click'));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const findIndexOfId = (id, array) => {
  const index = array.findIndex((item) => item.id === id);

  return index !== -1;
};

const getId = (length) => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, length);
};

const readUrlFromClipboard = async () => {
  if (navigator.clipboard && navigator.clipboard.readText) {
    const text = await navigator.clipboard.readText();
    // eslint-disable-next-line max-len
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);

    if (text.match(regex)) {
      return text;
    }
  }

  return undefined;
};

const validateFile = (t, value, allowedExtensions, maxFileSize) => {
  if (!value) {
    throw new Error(t('PASSPORT_SETPOINTS_FILE_MESSAGE'));
  }

  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp(`(.*?)\.(${allowedExtensions.join('|').toLowerCase()})$`);

  if (!regex.test(value.name)) {
    throw new Error(t('PASSPORT_SETPOINTS_FILE_EXT_MESSAGE'));
  }

  if (value.size > maxFileSize) {
    throw new Error(`${t('FILE_MAX_UPLOAD_FILE_SIZE_MESSAGE')} ${maxFileSize / KB / KB}Mb`);
  }
  if (value.size === 0) {
    throw new Error(t('FILE_MIN_UPLOAD_FILE_SIZE_MESSAGE'));
  }
};

const withPrefix = (prefix, t) => (str) => {
  const translation = t([`${prefix}${str}`, `${str}`]);
  if (translation !== `${prefix}${str}` && translation !== `${str}`) {
    return translation;
  } else {
    return `${prefix}${str}`;
  }
};

const getFileNameFromDisposition = (disposition, postfix = null) => {
  let filename = null;

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      const filenameRegexPostfix = /(.*?)(_[a-zA-Z\d]{7})\.(\w+)$/g;
      matches = filenameRegexPostfix.exec(filename);

      while (matches && matches[1] && matches[2] && matches[3]) {
        filename = matches[1].concat('.', matches[3]);
        matches = filenameRegexPostfix.exec(filename);
      }
    }

    if (postfix && postfix.length && filename && filename.length) {
      const filenameRegexExtension = /(.*?)\.(\w+)$/g;
      matches = filenameRegexExtension.exec(filename);

      if (matches != null && matches[1] && matches[2]) {
        filename = matches[1].concat(' ', postfix, '.', matches[2]);
      }
    }
  }

  return filename;
};

const textHighlighter = (searchTerm, data, disablePopover = false) => {
  if (!data) {
    return '';
  }
  if (!searchTerm || data.indexOf(searchTerm) === -1) {
    return data;
  }

  const searchRegExp = new RegExp(`(${escapeRegExp(searchTerm)})`, 'i');
  const parts = data.split(searchRegExp);

  const result = parts.reduce((acc, item, index) => {
    if (!item) return acc;

    return acc.concat((
      // eslint-disable-next-line react/no-array-index-key
      <span key={`textHighlighter_${index}`} style={index % 2 ? { backgroundColor: '#ffe58f' } : {}}>
        {item}
      </span>
    ));
  }, []);

  return (
    !disablePopover
      ? (
        <PopoverEllipsis content={result}>
          {result}
        </PopoverEllipsis>
      )
      : <div>{result}</div>
  );
};

const outerWidth = (el) => {
  let width = (el && el.offsetWidth) || 0;
  const style = window.getComputedStyle(el);

  width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  width += parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
  return width;
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const preventFocus = (e) => {
  e.currentTarget.blur();
}

const downloadDocExternal = async (wsState, t, url = null) => {
  if (url) {
    if (
      ['html', 'htm'].includes(url.substring(url.lastIndexOf('.') + 1, url.length))
      || url.substring(url.length - 1) === '/'
    ) {
      openInNewTab(decodePath(url));
    } else {
      let result;
      if (url.indexOf('/upload/') !== -1) {
        result = await api.request(
          url,
          'get',
          {},
          { responseType: 'blob' },
          { 'Cache-Control': 'no-cache' },
          true
        );
      } else {
        result = await api.downloadDocFromStorage(decodePath(url));
      }

      if (result.status === 200) {
        openSaveFileDialog(result.data, decodePath(url));
        message.success(t('DOWNLOAD_STARTED'));
      } else {
        message.info(t('DOWNLOAD_TRY_CENTER_NODE'));

        const socket = wsState && wsState.instance;
        const isSocketConnected = wsState && wsState.connected;

        if (socket && isSocketConnected) {
          await socket.send(JSON.stringify({
            message_type: 'downloads',
            url: decodePath(url),
            title: `${t('DOWNLOAD_FILE_WEB_SOCKET_HELP')} "${clearHashFromFile(decodePath(url).split('/').pop())}"`,
          }));
        }
      }
    }
  }
};

const closeAllPopups = () => {
  const myEvent = document.createEvent('CustomEvent');
  myEvent.initEvent('closePopover', true, true);
  document.dispatchEvent(myEvent);
};

const compareObjects = () => {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    compare: function (obj1, obj2, cnt = 0) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        return undefined;
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        const result = this.compareValues(obj1, obj2);
        return result !== this.VALUE_UNCHANGED
          ? {
            type: result,
            obj1,
            obj2,
          }
          : undefined;
      }

      if (cnt > 10) {
        return undefined;
      }

      const diff = {};
      const skip = {};
      for (const key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        const value2 = obj2[key] !== undefined
          ? obj2[key]
          : undefined;

        const result_1 = this.compare(obj1[key], value2, cnt + 1);
        if (result_1) {
          diff[key] = result_1;
        } else {
          skip[key] = 1;
        }
      }
      for (const key in obj2) {
        if (
          this.isFunction(obj2[key])
          || skip[key] !== undefined
          || diff[key] !== undefined
        ) {
          continue;
        }

        const result_2 = this.compare(undefined, obj2[key], cnt + 1);
        if (result_2) {
          diff[key] = result_2;
        }
      }

      return Object.keys(diff).length > 0
        ? diff
        : undefined;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
};

const saveFileToStorage = async (t, selectedStorage, file) => {
  const date = new Date();
  let storageUrl = selectedStorage?.url;

  if (storageUrl && storageUrl.substr(-1) !== '/') {
    storageUrl += '/';
  }

  const res = await api.request(
    `${storageUrl}ping`,
    'head',
    null,
    { timeout: 3000 },
    { 'Cache-Control': 'no-cache' },
    true
  );

  if (res.status < 0) {
    message.error(t('UPLOAD_NETWORK_ERROR'));
    return null;
  }

  const saveUrl = `${storageUrl}${date.getFullYear()}/${date.getMonth()}/`;
  const chunkDeleteUrl = `${storageUrl}chunks/${date.getFullYear()}/${date.getMonth()}/`;
  const newFileName = getHashForFile().concat(file.name);

  if (!STORAGE_USE_CHUNK || !file.originFileObj) {
    const fileFormData = new FormData();
    fileFormData.append('file', file.originFileObj || file, newFileName);

    const { status } = await api.request(
      saveUrl,
      'put',
      fileFormData,
      {},
      {},
      true
    );

    if (status === 201) {
      return `${saveUrl}${newFileName}`;
    } else {
      message.error(t('CREATE_ERROR'));
      return null;
    }
  } else {
    const chunkSize = CHUNK_SIZE_KB * KB;
    const fileSize = file.size;
    const totalCount = (
      fileSize % chunkSize === 0
        ? fileSize / chunkSize
        : Math.floor(fileSize / chunkSize) + 1
    );
    let currentChunk;
    let allSentChunk = false;
    let allErrorChunk = false;
    const startTime = new Date();
    const chunkInfo = [...Array(totalCount).keys()].map((x, i) => ({
      number: i,
      begin: i * chunkSize,
      end: ((i + 1) * chunkSize < fileSize) ? (i + 1) * chunkSize : fileSize,
      sent: 0,
      resent: 0,
      notBefore: startTime.getTime() - CHUNK_DELAY_IN_SECONDS * 1000,
    }));

    currentChunk = chunkInfo.find((x) => x.sent === 0 && x.notBefore <= startTime.getTime());

    while (currentChunk || !(allSentChunk || allErrorChunk)) {
      if (currentChunk) {
        const chunk = file.originFileObj.slice(currentChunk.begin, currentChunk.end);
        const fileFormData = new FormData();
        fileFormData.append('file', chunk, newFileName);
        fileFormData.append('chunk_index', currentChunk.number.toString());
        fileFormData.append('total_chunk', totalCount.toString());

        const { status } = await api.request(
          saveUrl,
          'post',
          fileFormData,
          {},
          { 'Content-Type': 'application/json' },
          true
        );

        const currentTime = new Date();

        if ([200, 201, 202, 203, 204].includes(status)) {
          currentChunk.sent = 1;
        } else {
          currentChunk.sent = 0;
          currentChunk.resent += 1;
          currentChunk.notBefore = currentTime.getTime() + CHUNK_DELAY_IN_SECONDS * 1000;
        }
      }

      currentChunk = chunkInfo.find((x) => (
        x.sent === 0
        && x.resent <= CHUNK_MAX_RESENT_COUNT
        && x.notBefore <= (new Date()).getTime()
      ));
      allSentChunk = chunkInfo.every((x) => x.sent === 1);
      const errorChunk = chunkInfo
        .filter((x) => x.sent === 0 && x.resent > 0);
      allErrorChunk = errorChunk.length && errorChunk.every((x) => x.resent > CHUNK_MAX_RESENT_COUNT);

      if (!currentChunk && !allSentChunk && !allErrorChunk) {
        await sleep(2000);
      }
    }

    if (allErrorChunk) {
      await api.request(
        `${chunkDeleteUrl}${newFileName}`,
        'delete',
        {},
        {},
        {},
        true
      );
      return null;
    }

    return `${saveUrl}${newFileName}`;
  }
};

const isArchived = () => {
  return storage.get('isArchivedData') === true;
};

const setUnBlockSidebar = (history, prompt) => history.block((targetLocation) => {
  const state = store.getState();
  const sidebar = state && state.sidebar;

  if (sidebar && sidebar.isOpen && sidebar.isChanged) {
    store.dispatch(showPrompt(() => history.push(targetLocation.pathname)));
    return false;
  } else {
    if (sidebar && sidebar.isOpen) {
      store.dispatch(closeSidebar());
    }
    return true;
  }
});

export {
  viewFileSize,
  StorageState,
  Warnings,
  getObjectFromUrl,
  sleep,
  verifyExtensionBeforeSave,
  openSaveFileDialog,
  escapeRegExp,
  findIndexOfId,
  getId,
  readUrlFromClipboard,
  validateFile,
  withPrefix,
  getFileNameFromDisposition,
  textHighlighter,
  outerWidth,
  openInNewTab,
  downloadDocExternal,
  getFileNameFromLink,
  getPopoverFileNameFromLink,
  decodePath,
  closeAllPopups,
  compareObjects,
  saveFileToStorage,
  clearHashFromFile,
  isArchived,
  setUnBlockSidebar,
  preventFocus,
};
