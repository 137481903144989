import React from 'react';

import store from '@state/store';
import { closeSidebar } from '@state/sidebar/actions';

import Passport from './passport';

class PassportRoute extends React.Component {
  componentDidMount() {
    store.dispatch(closeSidebar());
  }

  render() {
    const {
      match: {
        params: { id, tab },
      },
    } = this.props;

    return <Passport id={id} activeTab={tab} />;
  }
}

export default PassportRoute;
