import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { message, Button } from 'antd';

import { WS_RESULT_COUNT, WS_TASKDC_START, WS_RUN_ENDPOINT_MSG } from '@app/constants';
import { downloadDocExternal } from '@globalHelpers';
import { GET_NEW_NOTIFICATIONS_COUNTER } from '@state/notifications/types';
import store, { RootState } from '@state/store';
import { clearMessage, wsRunActionError, wsRunActionSuccess } from '@state/websocket/actions';
import {
  WS_RUN_ENDPOINT,
  websocketState,
  wsMessageCountSubType,
  wsMessageMainSubType,
  wsMessageRunEndpointSubType,
  wsMessageType,
  wsRunAction,
} from '@state/websocket/types';
import { DURATION } from '@ui/AppMessage/constants';

interface Props extends WithTranslation {
  wsMessage?: wsMessageType;
  wsState?: websocketState;
  clearMsg: () => void;
  runActions: { [key: string]: wsRunAction };
}

class AppMessage extends Component<Props> {
  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      wsMessage, clearMsg, t, wsState, runActions,
    } = this.props;

    const wsMainMessage: wsMessageMainSubType|undefined = (
      wsMessage
      && wsMessage?.type !== WS_RESULT_COUNT
      && wsMessage?.type !== WS_TASKDC_START
      && !wsMessage?.type?.includes(WS_RUN_ENDPOINT_MSG)
        ? wsMessage as wsMessageMainSubType
        : undefined
    );

    const wsCountMessage: wsMessageCountSubType|undefined = (
      wsMessage
      && wsMessage?.type === WS_RESULT_COUNT
        ? wsMessage as wsMessageCountSubType
        : undefined
    );

    const wsRunEndpointMessage: wsMessageRunEndpointSubType|undefined = (
      wsMessage
      && wsMessage?.type?.includes(WS_RUN_ENDPOINT_MSG)
        ? wsMessage as wsMessageRunEndpointSubType
        : undefined
    );

    if (
      (wsMainMessage && !prevProps.wsMessage?.message && wsMainMessage?.message)
      || (
        wsRunEndpointMessage
        && (
          (!prevProps.wsMessage?.message && wsRunEndpointMessage?.message)
          || (prevProps.wsMessage?.type !== wsRunEndpointMessage?.type)
        )
      )
    ) {
      setTimeout(clearMsg, DURATION * 1000);
    }

    wsCountMessage && wsCountMessage.message && store.dispatch({
      type: GET_NEW_NOTIFICATIONS_COUNTER,
      count_new: wsCountMessage.message?.count,
    });

    if (
      wsRunEndpointMessage
      && wsRunEndpointMessage.message
      && wsRunEndpointMessage.front_id
      && (
        (!prevProps.wsMessage?.message && wsRunEndpointMessage?.message)
        || (prevProps.wsMessage?.type !== wsRunEndpointMessage?.type)
      )
    ) {
      const [prefix, newFrontId] = (
        wsRunEndpointMessage
        && wsRunEndpointMessage.front_id.length
        && wsRunEndpointMessage.front_id.split('___')
      );
      const currentAction: wsRunAction = (newFrontId && runActions && runActions[newFrontId]) || {} as wsRunAction;
      const textKey = wsRunEndpointMessage.message.toUpperCase().replaceAll(' ', '_');
      const text = t(`RUN_ENDPOINT_MESSAGE_${textKey}`, wsRunEndpointMessage.message);

      let newAction: wsRunAction;

      switch (wsRunEndpointMessage.type) {
        case `${WS_RUN_ENDPOINT_MSG}_message`:
          message.info(
            `${currentAction.title ? `${currentAction.title}: ` : ''}${text}`,
            DURATION + 1
          );
          break;
        case `${WS_RUN_ENDPOINT_MSG}_error`:
          newAction = {
            type: `${WS_RUN_ENDPOINT}_ERROR`,
            subType: currentAction.subType,
            title: currentAction.title,
            frontId: newFrontId,
            message: wsRunEndpointMessage,
          };
          prefix && wsRunActionError(newAction, store.dispatch);
          prefix && store.dispatch(newAction);
          message.error(
            (
              <>
                {
                  currentAction.title
                    ? (
                      <>
                        {currentAction.title}
                        &nbsp;
                        {t('RUN_ENDPOINT_ERROR')}
                        <br />
                      </>
                    )
                    : ''
                }
                {wsRunEndpointMessage.message}
              </>
            ),
            DURATION + 3
          );
          break;
        case `${WS_RUN_ENDPOINT_MSG}_result`:
          newAction = {
            type: `${WS_RUN_ENDPOINT}_SUCCESS`,
            subType: currentAction.subType,
            title: currentAction.title,
            frontId: newFrontId,
            message: wsRunEndpointMessage,
          };
          prefix && wsRunActionSuccess(newAction, store.dispatch);
          prefix && store.dispatch(newAction);

          currentAction.title && message.info(
            `${currentAction.title} ${t('RUN_ENDPOINT_SUCCESS')}`,
            DURATION + 1
          );
          break;
        default:
          message.error(
            <>
              {t('RUN_ENDPOINT_TYPE_NOT_FOUND_1')}
              <br />
              {t('RUN_ENDPOINT_TYPE_NOT_FOUND_2')}: {wsRunEndpointMessage.type}
              <br />
              {t('RUN_ENDPOINT_TYPE_NOT_FOUND_3')}
            </>,
            DURATION + 3
          );
      }
    }

    wsMainMessage && !wsMainMessage.url && wsMainMessage.message && message.info(
      wsMainMessage.message,
      DURATION
    );

    wsMainMessage && wsMainMessage?.url && message.info(
      <Button
        type='link'
        onClick={() => wsMainMessage.url && downloadDocExternal(wsState, t, wsMainMessage.url)}
      >
        {wsMainMessage.message}
      </Button>,
      DURATION
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  wsState: state.websocket,
  wsMessage: state.websocket.message,
  runActions: state.websocket.runActions,
});

const mapDispatchToProps = {
  clearMsg: clearMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    AppMessage
  )
);
