import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withPagination } from '@hoc';
import { Link } from 'react-router-dom';
import {
  Input, Button, message,
} from 'antd';
import Icon, { SearchOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import Qs from 'qs';

import api from '@services/api';
import { DOCUMENTS_UPLOAD } from '@common/sidebarRoot/types';
import { DOC_SIDEBAR_DOC } from '@common/documentsUploadSidebar/constants';
import CommonTable from '@common/CommonTable';
import { openSidebar, closeSidebar, updateSidebar } from '@state/sidebar/actions';
import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';
import { getShortBucket } from '@state/bucket/actions';
import store from '@state/store';

import { CustomCard, PopoverEllipsis } from '@ui';
import { MODE_EDIT, TABLE_SIZE, RIGHT_PASSPORT_DOCS } from '@globalConstants';

import styles from './styles.module.less';

class ChosenDevices extends React.Component {
  tableName = 'chosenDevices';

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      loading: false,
      devices: [],
      count: 0,
    };
  }

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName, () => this.loadBucket());
  }

  getColumnSearchProps(dataIndex) {
    const { t } = this.props;
    return {
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={t('CHOSEN_DEVICES_DEVICE_NAME')}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            {t('CHOSEN_DEVICES_SEARCH')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            {t('CHOSEN_DEVICES_RESET')}
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, right: 'calc(100% - 220px)' }} />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
    };
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  loadBucket = async () => {
    const { loadByPage } = this.props;

    this.setState({ loading: true });
    await loadByPage(this.tableName, 'getBucket', {}, 200, (status, data) => {
      this.setState({
        devices: data.results,
        count: data.count,
      });
    });
    this.setState({ loading: false });
  };

  onSelectChange = (selected) => {
    const { updateUploadDocuments } = this.props;
    const { devices } = this.state;

    this.setState({ selected });

    const selectedDevices = devices.filter((x) => selected.find((y) => x.id === y));
    const selectedPassports = selectedDevices.flatMap((x) => x.passport.map((y) => y.id));

    updateUploadDocuments({ passports: selectedPassports });
  };

  clearSelected = async () => {
    const { t } = this.props;
    const { selected, devices } = this.state;

    this.setState({ loading: true });

    const { status } = await api.clearBucket(selected);

    if (status === 200 || status === 204) {
      const newDevicesData = devices.filter((device) => !selected.includes(device.id));
      message.success(`${selected.length} ${t('REMOVE_ALL_ON_PAGE_FROM_BUCKET_MESSAGE')}`);
      store.dispatch(getShortBucket(false));
      this.setState({ loading: false, selected: [], devices: newDevicesData });
    } else {
      this.setState({ loading: false });
      message.error(t('REMOVE_FROM_BUCKET_FAIL_MESSAGE'));
    }
  };

  downloadSetpointsMap = async () => {
    const { t, socket, isSocketConnected } = this.props;
    if (socket && isSocketConnected) {
      const { selected, devices } = this.state;

      this.setState({ selected: [] });

      const selectedDevices = devices.filter((x) => selected.find((y) => x.id === y));
      const selectedPassports = selectedDevices.flatMap((x) => x.passport.map((y) => y.id));
      const params = {
        passports: selectedPassports,
      };

      const request = `/v1/setpoint-map?${Qs.stringify(params, { arrayFormat: 'repeat' })}`;

      await socket.send(JSON.stringify({
        message_type: 'export',
        title: t('PASSPORT_SETPOINT_MAP_COMPLEX_EXPORT_MULTI_PASSPORT'),
        url: request,
        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }));
    }
  };

  toggleDocumentsSidebar = () => {
    const {
      sidebar, openUploadDocuments, closeUploadDocuments,
    } = this.props;
    const { devices, selected } = this.state;

    const selectedDevices = devices.filter((x) => selected.find((y) => x.id === y));
    const selectedPassports = selectedDevices.flatMap((x) => x.passport.map((y) => y.id));

    if (sidebar.isOpen && sidebar.componentType === DOCUMENTS_UPLOAD) {
      closeUploadDocuments();
    } else {
      openUploadDocuments(
        DOCUMENTS_UPLOAD,
        {
          type: DOC_SIDEBAR_DOC,
          passports: selectedPassports,
          showPassportsList: true,
          showSystemType: true,
        },
        700
      );
    }
  };

  columns() {
    const { t } = this.props;
    return [
      {
        title: t('CHOSEN_DEVICES_DEVICE_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (text, record) => {
          const content = <Link to={`/passport/${record.id}`}>{text}</Link>;

          return (
            <PopoverEllipsis
              content={content}
            >
              {content}
            </PopoverEllipsis>
          );
        },
        ...this.getColumnSearchProps('name'),
      },
      {
        title: t('DC'),
        dataIndex: ['dispatcher_center', 'name'],
        key: 'dispatcher_center_name',
        width: '50%',
      },
    ];
  }

  render() {
    const {
      t, rights, changePage, onShowSizeChange, page, limit,
    } = this.props;
    const {
      loading, devices, selected, count,
    } = this.state;
    const isEditRightDoc = rights[RIGHT_PASSPORT_DOCS] === MODE_EDIT;

    return (
      <CustomCard
        className={styles.chosenDevices}
        style={{ minHeight: `calc(100vh - 800px)` }}
        noBottomMargin
        extra={(
          <div className={styles.actions}>
            <Button
              className={styles.documentsButton}
              onClick={this.toggleDocumentsSidebar}
              disabled={!selected.length || !isEditRightDoc}
              type='primary'
              size='small'
            >
              <UploadOutlined />
              {t('CHOSEN_DEVICES_UPLOAD')}
            </Button>
            <Button
              className={styles.mapButton}
              onClick={this.downloadSetpointsMap}
              disabled={!selected.length}
              type='secondary'
              size='small'
              icon={<DownloadOutlined />}
              loading={loading}
            >
              {t('CHOSEN_DEVICES_DOWNLOAD_SETPOINTS_MAP')}
            </Button>
            <div className={styles.deleteButton}>
              <Button onClick={this.clearSelected} disabled={!selected.length} type='default' size='small'>
                <Icon component={BucketIcon} />
                {`${t('CHOSEN_DEVICES_CLEAR_BUCKET')} (${selected.length})`}
              </Button>
            </div>
          </div>
        )}
      >
        <CommonTable
          tableName={this.tableName}
          size={TABLE_SIZE}
          columns={this.columns()}
          dataSource={devices}
          loading={loading}
          rowSelection={{
            selectedRowKeys: selected,
            onChange: this.onSelectChange,
          }}
          pagination={{
            onChange: (newPage) => changePage(newPage, this.loadBucket),
            total: count,
            pageSize: limit,
            current: page,
          }}
          onShowSizeChange={
            (current, size) => onShowSizeChange(current, size, this.tableName, this.loadBucket)
          }
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  sidebar: state.sidebar,
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});

const mapDispatchToProps = {
  openUploadDocuments: openSidebar,
  closeUploadDocuments: closeSidebar,
  updateUploadDocuments: updateSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withPagination(
      ChosenDevices
    )
  )
);
