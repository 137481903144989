import React from 'react';
import { DatePicker } from 'antd';
import styles from './styles.module.less';

const { RangePicker } = DatePicker;
const dateFormat = 'DD.MM.YYYY';

const FilterDateRange = (props) => {
  const { keyName, onChange, values } = props;

  return (
    <RangePicker
      className={styles.date}
      key={keyName}
      onChange={onChange}
      value={values}
      format={dateFormat}
    />
  );
};

export default FilterDateRange;
