
import { Modal } from 'antd';
import React, { FC, memo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import NewsList from '@common/news/newsList';

export interface INewsModal extends WithTranslation {
  visible: boolean;
  closeModal: () => void;
}

const modalBodyStyle = {
  height: '600px',
  overflow: 'auto',
  paddingTop: 5,
};

const NewsModal: FC<INewsModal> = memo(({ visible = false, closeModal, t }) => (
  <Modal
    visible={visible}
    onCancel={closeModal}
    width={1000}
    bodyStyle={modalBodyStyle}
    title={t('NEWS')}
    footer={null}
  >
    {/*@ts-ignore - фикс, все работает верно, но показывает ошибку из-за оберток*/}
    {visible && <NewsList />} 
  </Modal>
));

export default withTranslation()(NewsModal);
