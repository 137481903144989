import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  UPDATE_SIDEBAR,
  IS_CHANGED_SIDEBAR,
  SHOW_PROMPT_SIDEBAR,
  CLOSE_PROMPT_SIDEBAR,
} from './types';

export const showPrompt = (
  actionOnCancel = null,
  promptCancelText = null,
  promptWidth = null,
  promptTitleText = null,
  promptOkText = null
) => {
  return {
    type: SHOW_PROMPT_SIDEBAR,
    data: {
      promptWidth,
      promptTitleText,
      promptOkText,
      promptCancelText,
      function: actionOnCancel,
    },
  };
};

export const closePrompt = () => {
  return {
    type: CLOSE_PROMPT_SIDEBAR,
  };
};

export const openSidebar = (componentType, data, width = 360, isNotDisableBySidebar = false) => {
  return async (dispatch, getState) => {
    const sidebar = getState().sidebar;

    if (sidebar && sidebar.isOpen && sidebar.isChanged) {
      dispatch(showPrompt(
        () => dispatch({
          type: OPEN_SIDEBAR,
          componentType,
          data,
          width,
          isNotDisableBySidebar,
        }),
        'CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL_PASSPORT_CHANGE_SIDEBAR',
        800
      ));
    } else {
      dispatch({
        type: OPEN_SIDEBAR,
        componentType,
        data,
        width,
        isNotDisableBySidebar,
      });
    }
  };
};

export const closeSidebar = () => {
  return async (dispatch, getState) => {
    const sidebar = getState().sidebar;
    if (sidebar && sidebar.isChanged) {
      dispatch({
        type: SHOW_PROMPT_SIDEBAR,
        data: closeSidebar,
      });
    } else {
      dispatch({ type: CLOSE_SIDEBAR });
    }
  };
};

export const updateSidebar = (data) => {
  return {
    type: UPDATE_SIDEBAR,
    data,
  };
};

export const setIsChangedSidebar = (isChangedData) => {
  return {
    type: IS_CHANGED_SIDEBAR,
    data: isChangedData,
  };
};
