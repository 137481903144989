import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, message, Popconfirm, Tooltip,
} from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import api from '@services/api';
import { getActionsLogs } from '@state/journal/actions';
import {
  MODE_EDIT, RIGHT_ROUTE_JOURNAL, ROLE_KEY_GUEST, ROLE_KEY_TECH,
} from '@globalConstants';

import {
  MODEL_BLANK,
  MODEL_TASK,
  MODEL_DOCUMENT,
  MODEL_OWNER,
  MODEL_VERSION,
  MODEL_TEMPLATE,
  MODEL_IMPLEMENTATION_TYPE,
  MODEL_LETTER,
  MODEL_PRODUCER,
  MODEL_TRADE_DEVICE,
  MODEL_TAG,
  MODEL_DOCUMENT_STATUS,
  MODEL_DOCUMENT_TYPE,
  MODEL_EVENT,
  MODEL_PROTECTION_DEVICE_COMPLEX,
} from './constants';

class CancelChanges extends Component {
  confirm = async () => {
    const {
      t, record, deviceId, getActions,
    } = this.props;
    let request;

    switch (record.model_name) {
      case MODEL_OWNER: {
        request = api.restoreOwner(record.instance_id);

        break;
      }
      case MODEL_BLANK: {
        request = api.restoreBlank(record.instance_id);

        break;
      }
      case MODEL_DOCUMENT: {
        request = api.restoreDocument(record.instance_id);

        break;
      }
      case MODEL_TASK: {
        request = api.restoreTask(record.instance_id);

        break;
      }
      case MODEL_VERSION: {
        request = api.restoreVersion(record.instance_id);

        break;
      }
      case MODEL_TEMPLATE: {
        request = api.restoreTemplate(record.instance_id);

        break;
      }
      case MODEL_IMPLEMENTATION_TYPE: {
        request = api.restoreImplementationTypes(record.instance_id);

        break;
      }
      case MODEL_LETTER: {
        request = api.restoreLetter(record.instance_id);

        break;
      }
      case MODEL_PRODUCER: {
        request = api.restoreProducers(record.instance_id);

        break;
      }
      case MODEL_TRADE_DEVICE: {
        request = api.restoreTradeDevices(record.instance_id);

        break;
      }
      case MODEL_TAG: {
        request = api.restoreTag(record.instance_id);

        break;
      }
      case MODEL_DOCUMENT_STATUS: {
        request = api.restoreDocumentStatus(record.instance_id);

        break;
      }
      case MODEL_DOCUMENT_TYPE: {
        request = api.restoreDocumentType(record.instance_id);

        break;
      }
      case MODEL_EVENT: {
        request = api.restoreEvents(record.instance_id);

        break;
      }
      case MODEL_PROTECTION_DEVICE_COMPLEX: {
        request = api.restoreComplex(record.instance_id);

        break;
      }
      default:
        break;
    }

    const { status } = await request;

    if ([200, 201].includes(status)) {
      const { status: logStatus } = await api.loggerRequest(`/records/restored/${record.id}`, 'put');
      if ([200, 201].includes(logStatus)) {
        message.success(t('CANCEL_ACTION_SUCCESS'));
      } else {
        message.error(t('CANCEL_ACTION_ERROR'));
      }
    } else {
      message.error(t('CANCEL_ACTION_ERROR'));
    }

    getActions(deviceId);
  };

  render() {
    const {
      t, record, rights, deviceId, userInfo,
    } = this.props;

    let readOnly = rights[RIGHT_ROUTE_JOURNAL] !== MODE_EDIT;

    if (deviceId) {
      readOnly = (
        userInfo
        && userInfo.roles
        && userInfo.roles.some(role => [ROLE_KEY_GUEST, ROLE_KEY_TECH].includes(role.key))
          ? record.user_id !== userInfo.id
          : false
      );
    }

    return record && !record.is_restored && !record.is_have_task_dispatcher_center && (
      <Popconfirm
        title={t(`JOURNAL_CANCEL_DELETE_${record.model_name && record.model_name.toUpperCase()}`)}
        okText={t('YES')}
        cancelText={t('NO')}
        onConfirm={this.confirm}
        disabled={readOnly}
      >
        <Tooltip
          title={t('TO_CANCEL')}
        >
          <Button
            size='small'
            disabled={readOnly}
            icon={<UndoOutlined />}
          />
        </Tooltip>
      </Popconfirm>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  getActions: getActionsLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    CancelChanges
  )
);
