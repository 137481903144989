import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Menu, Layout, Row, Col,
} from 'antd';
import storage from '@services/storage';

import { ReactComponent as ApsIcon } from '@ui/icons/APS-white.svg';
import {
  UserOutlined,
  SwapOutlined,
  SettingOutlined,
  ApiOutlined,
  ContainerOutlined,
  FileTextOutlined,
  DatabaseOutlined,
  FileSearchOutlined,
  ProjectOutlined,
  ReadOutlined,
  FolderOpenOutlined,
  FlagOutlined,
} from '@ant-design/icons';
import {
  APPSTAGE_AUTH,
  ROUTE_ROOT,
  ROUTE_ROLES,
  ROUTE_EXTERNAL_SYSTEM,
  ROUTE_SETTINGS,
  ROUTE_STORAGES,
  ROUTE_FUNCTIONS,
  ROUTE_CLASSIFICATORS,
  ROUTE_TEMPLATES,
  ROUTE_SEARCH,
  ROUTE_TREE,
  ROUTE_JOURNAL,
  MAP_RIGHTS_TO_PATHS,
  SOFTWARE_VERSION,
  ROUTE_REPORTS,
  ROUTE_EVENTS, REDIRECT_URL_NEW_FRONT,
} from '@globalConstants';

import MenuItem from './menuItem';
import MenuGroup from './menuGroup';

import styles from './styles.module.less';

const { Sider } = Layout;

class LeftMenu extends Component {
  constructor(props) {
    super(props);

    const { location: { pathname } } = props;

    this.menuItems = {
      roles: {
        key: '1',
        caption: 'LEFT_MENU_USER_ROLES',
        displayed: false,
        route: ROUTE_ROLES,
        onClick: () => this.selectRoute(ROUTE_ROLES, 1),
        iconType: UserOutlined,
      },
      aip: {
        key: '2',
        caption: 'LEFT_MENU_ITEM_AIP',
        displayed: false,
        route: ROUTE_EXTERNAL_SYSTEM,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_EXTERNAL_SYSTEM, 2),
        iconType: SwapOutlined,
      },
      settings: {
        key: '3',
        caption: 'LEFT_MENU_ITEM_SETTINGS',
        displayed: false,
        route: ROUTE_SETTINGS,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_SETTINGS, 3),
        iconType: SettingOutlined,
      },
      functions: {
        key: '4',
        caption: 'LEFT_MENU_ITEM_FUNCTIONS',
        displayed: false,
        route: ROUTE_FUNCTIONS,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_FUNCTIONS, 4),
        iconType: ApiOutlined,
      },
      templates: {
        key: '5',
        caption: 'LEFT_MENU_ITEM_TEMPLATES',
        displayed: false,
        route: ROUTE_TEMPLATES,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_TEMPLATES, 5),
        iconType: FileTextOutlined,
      },
      classificators: {
        key: '6',
        caption: 'LEFT_MENU_ITEM_CLASSIFICATORS',
        displayed: false,
        route: ROUTE_CLASSIFICATORS,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_CLASSIFICATORS, 6),
        iconType: DatabaseOutlined,
      },
      search: {
        key: '7',
        caption: 'LEFT_MENU_ITEM_GLOBAL_SEARCH',
        displayed: false,
        count: 0,
        route: `(${ROUTE_ROOT}|${ROUTE_SEARCH})`,
        onClick: () => this.selectRoute(ROUTE_SEARCH, 7),
        iconType: FileSearchOutlined,
      },
      tree: {
        key: '8',
        caption: 'LEFT_MENU_ITEM_TREE',
        displayed: false,
        route: ROUTE_TREE,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_TREE, 8),
        iconType: ProjectOutlined,
      },
      journal: {
        key: '9',
        caption: 'LEFT_MENU_ITEM_JOURNAL',
        displayed: false,
        route: ROUTE_JOURNAL,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_JOURNAL, 9),
        iconType: ReadOutlined,
      },
      reports: {
        key: '10',
        caption: 'LEFT_MENU_ITEM_REPORTS',
        displayed: false,
        route: ROUTE_REPORTS,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_REPORTS, 10),
        iconType: FolderOpenOutlined,
      },
      events: {
        key: '11',
        caption: 'LEFT_MENU_ITEM_EVENTS',
        displayed: false,
        route: ROUTE_EVENTS,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_EVENTS, 11),
        iconType: FlagOutlined,
      },
      storages: {
        key: '12',
        caption: 'LEFT_MENU_ITEM_STORAGES',
        displayed: false,
        route: ROUTE_STORAGES,
        count: 0,
        onClick: () => this.selectRoute(ROUTE_STORAGES, 12),
        iconType: ContainerOutlined,
      },
    };

    const menuItemKey = Object.values(this.menuItems).findIndex((item) => (
      pathname.indexOf(item.route) === 0
    ));

    this.state = {
      isMenuCollapsed: false,
      selectedMenuItem: menuItemKey !== -1 ? menuItemKey + 1 : pathname === '/' ? 7 : 0,
    };
    this.sider = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    const isMenuCollapsed = storage.get('isMenuCollapsed');
    this.setState({ isMenuCollapsed });

    this.historyListener = history.listen((location) => {
      const { route } = this.state;
      const { pathname } = location;

      window.scrollTo(0, 0);

      if (pathname.indexOf(route) !== 0 || route === ROUTE_ROOT) {
        this.setState({ selectedMenuItem: 0 });
      }
    });
  }

  componentWillUnmount() {
    this.historyListener();
  }

  handleCollapse = (isMenuCollapsed, type) => {
    /** Отменяем раскрытие меню, если :
     *  1. Меню было до этого свёрнуто
     *  2. Раскрытие происходит автоматически при расширении экрана
     * */
    if (type === 'responsive' && !isMenuCollapsed) {
      const currentSider = this.sider.current;
      currentSider.setState({ collapsed: true });
    } else {
      storage.set('isMenuCollapsed', isMenuCollapsed);
      this.setState({ isMenuCollapsed });
    }
  };

  selectRoute = (route = ROUTE_SEARCH, menuItem = 7) => {
    const { selectedMenuItem } = this.state;
    const { history } = this.props;

    if (selectedMenuItem !== menuItem) {
      this.setState({ selectedMenuItem: menuItem, route }, () => history.push(route));
    }
  };

  render() {
    const {
      t,
      appStage,
      rights,
      userInfo,
    } = this.props;

    const { isMenuCollapsed, selectedMenuItem } = this.state;

    if (appStage !== APPSTAGE_AUTH) return <></>;

    this.menuItems.roles.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_ROLES]];
    this.menuItems.aip.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_EXTERNAL_SYSTEM]];
    this.menuItems.settings.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_SETTINGS]];
    this.menuItems.functions.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_FUNCTIONS]];
    this.menuItems.templates.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]];
    this.menuItems.classificators.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_CLASSIFICATORS]];
    this.menuItems.search.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_SEARCH]];
    this.menuItems.tree.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TREE]];
    this.menuItems.journal.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_JOURNAL]];
    this.menuItems.events.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_EVENTS]];
    this.menuItems.reports.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_REPORTS]];
    this.menuItems.storages.displayed = rights[MAP_RIGHTS_TO_PATHS[ROUTE_STORAGES]];

    const showAdminBlock = [ROUTE_ROLES, ROUTE_EXTERNAL_SYSTEM, ROUTE_SETTINGS, ROUTE_STORAGES]
      .some((item) => rights[MAP_RIGHTS_TO_PATHS[item]]);
    const showAdminTechBlock = [ROUTE_FUNCTIONS, ROUTE_TEMPLATES, ROUTE_CLASSIFICATORS]
      .some((item) => rights[MAP_RIGHTS_TO_PATHS[item]]);
    const showTechBlock = [ROUTE_SEARCH, ROUTE_TREE, ROUTE_JOURNAL, ROUTE_EVENTS, ROUTE_REPORTS]
      .some((item) => rights[MAP_RIGHTS_TO_PATHS[item]]);

    const dispatcherCenterName = userInfo && userInfo.dispatcher_center
      ? userInfo.dispatcher_center.name
      : ' ';

    return (
      <Sider
        width={300}
        collapsedWidth={80}
        breakpoint="xl"
        collapsible
        collapsed={isMenuCollapsed}
        onCollapse={this.handleCollapse}
        ref={this.sider}
        className={styles.menu}
      >
        <Row className={styles.menuHeader}>
          <Col span={!isMenuCollapsed ? 7 : 24}>
            <a
              href={REDIRECT_URL_NEW_FRONT}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ApsIcon className={styles.logoIcon} />
            </a>
          </Col>
          <Col span={!isMenuCollapsed ? 17 : 0} className={styles.logoText}>
            <p className={styles.subtitle}>{t('AUTH_FORM_SUBTITLE')}</p>
            <p className={styles.dcName}>{dispatcherCenterName}</p>
          </Col>
        </Row>
        <Scrollbars
          autoHide
          className={styles.scrollbar}
          style={{
            height: 'calc(100vh - 190px)',
            position: 'fixed',
          }}
        >
          <Menu theme="dark" selectedKeys={[`${selectedMenuItem}`]} mode="inline">

            <MenuGroup isMenuCollapsed={isMenuCollapsed} displayed={showAdminBlock} caption="ADMIN" />
            <MenuItem {...this.menuItems.roles} />
            <MenuItem {...this.menuItems.aip} />
            <MenuItem {...this.menuItems.settings} />
            <MenuItem {...this.menuItems.storages} />

            <MenuGroup isMenuCollapsed={isMenuCollapsed} displayed={showAdminTechBlock} caption="ADMIN_TECH" />
            <MenuItem {...this.menuItems.functions} />
            <MenuItem {...this.menuItems.templates} />
            <MenuItem {...this.menuItems.classificators} />

            <MenuGroup isMenuCollapsed={isMenuCollapsed} displayed={showTechBlock} caption="TECH" />
            <MenuItem {...this.menuItems.search} />
            <MenuItem {...this.menuItems.tree} />
            <MenuItem {...this.menuItems.journal} />
            <MenuItem {...this.menuItems.reports} />
            <MenuItem {...this.menuItems.events} />

          </Menu>

        </Scrollbars>
        <div
          className={`${styles.version} ${isMenuCollapsed ? styles.versionCollapsed : styles.versionExpanded}`}
        >
          {`v. ${SOFTWARE_VERSION}`}
        </div>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => ({
  appStage: state.appStage,
  rights: state.rights.rightsData,
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      LeftMenu
    )
  )
);
