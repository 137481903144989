import React from 'react';
import moment from 'moment';

import { renderFields } from '@common/treeValues/renderFields';
import { getId } from '@globalHelpers';
import api from '@services/api';
import { cloneDeep } from 'lodash';
import browserHistory from '@app/browserHistory';

export class ActionsForTreeValues {
  constructor(t) {
    this.t = t;
  }

  columnsForTreeValue = (editableFunctions) => [
    {
      title: 'NAME',
      field: 'name',
      type: 'text',
      empty: 'WITHOUT_NAME',
      placeholder: this.t('TREE_VALUE_NEW_TREE_ITEM_VALUE'),
    },
    {
      title: 'SHORT_NAME',
      field: 'short_name',
      type: 'text',
      empty: 'WITHOUT_VALUE',
    },
    {
      title: 'ANSI',
      field: 'ansi',
      type: 'text',
      empty: 'WITHOUT_VALUE',
    },
    {
      title: 'DEVICES',
      field: 'protection_devices',
      type: 'loading-tag',
      loadTags: async (id, { name, offset, limit }) => {
        const result = await api.getFunctionProtectionDevices(id, {
          name,
          offset,
          limit,
          noChangePage: true,
        });
        if (result.status === 200 && result.data.results[0]) {
          return result.data.results;
        } else {
          return [];
        }
      },
      navigateTo: (id) => browserHistory.push(`/passport/${id}`),
    },
    ...(editableFunctions ? ([
      {
        title: 'STARTUP_DATE',
        field: 'startup_date',
        type: 'only_view',
        empty: 'WITHOUT_VALUE',
        render: (x, y, text) => moment(text).format('HH:mm:ss DD.MM.YYYY'),
      },
      {
        title: 'CREATED_BY',
        field: 'created_by',
        type: 'only_view',
        empty: 'WITHOUT_VALUE',
      },
      {
        title: 'LAST_CHANGED_BY',
        field: 'last_changed_by',
        type: 'only_view',
        empty: 'WITHOUT_VALUE',
      },
    ]) : []),
  ];

  newTreeValueItem = (component) => {
    const { selectedTreeItem } = component.state;
    return {
      id: getId(8),
      name: null,
      short_name: null,
      ansi: null,
      category: {
        id: selectedTreeItem.id,
        name: selectedTreeItem.name,
      },
      is_accounting_group: false,
      isNewItem: true,
    };
  };

  renderTreeValueColumns = (component, editableFunctions, dataFiltered) => {
    const { editId, editItem } = component.state;

    const columnsList = this.columnsForTreeValue(editableFunctions);
    const columns = [];

    if (columnsList && Array.isArray(columnsList) && columnsList.length) {
      columnsList.forEach((column, columnIndex) => {
        const obj = {};
        obj.origin_column = column;
        obj.title = column.title
          ? (
            column.showTitleAsIs
              ? (
                column.renderTitle && typeof column.renderTitle === 'function'
                  ? <div>{column.renderTitle(dataFiltered, column, component)}</div>
                  : <div>{column.title}</div>
              )
              : <div>{this.t(`TABLE_COL_${column.title}`)}</div>
          )
          : <></>;
        const newObj = renderFields(
          obj,
          column,
          'template-tree-value',
          editId,
          editItem,
          columnIndex,
          component,
          this.t
        );
        columns.push(newObj);
      });
    }

    return columns;
  };

  // Дополнительные действия над элементом при его создании, создание доп полей и т.п.
  onSaveEditedTreeValueData = async (component) => {
    const { data, editId, editItem } = component.state;
    const newEditItem = editItem;
    return data.map((el) => {
      if (el.id === editId) {
        return { ...el, ...newEditItem };
      } else {
        return el;
      }
    });
  };

  // Дополнительные действия над элементом при его создании, создание доп полей и т.п.
  onAddTreeValueItem = (addItem) => {
    return addItem;
  };

  // Дополнительные действия над элементом перед редактированием, создание доп полей и т.п.
  onEditTreeValueData = (editedItem) => {
    return editedItem;
  };

  // Дополнительные действия над элементом перед копированием, удаление полей для копии
  onCopyTreeValueData = (item) => {
    const newItem = cloneDeep(item);

    delete newItem.created_by;
    delete newItem.last_changed_by;

    return newItem;
  };
}
