import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button, message, Divider, Popconfirm, Popover,
} from 'antd';
import Icon, {
  CopyOutlined, FolderOpenOutlined, ImportOutlined, PlusCircleOutlined, UpCircleOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import api from '@services/api';
import {
  clearTemplatesError,
  clearTemplatesSuccess,
  getTemplates,
  templatesWithChildrenSelector,
} from '@state/templates/actions';
import { openSidebar } from '@state/sidebar/actions';
import { IMPORT_TEMPLATE } from '@common/sidebarRoot/types';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';
import { getObjectFromUrl, textHighlighter } from '@globalHelpers';
import { CustomCard } from '@ui';
import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';
import { ExportBottom } from '@common/exportPopconfirm';
import {
  MAP_RIGHTS_TO_PATHS, ROUTE_TEMPLATES, MODE_EDIT, TABLE_SIZE,
} from '@globalConstants';
import TemplatesBlanks from './TemplatesBlanks';

import styles from './styles.module.less';

class Templates extends Component {
  tableName = 'templates';

  state = {
    isTemplateCoping: false,
    isProcessing: false,
    isVersionUp: false,
  };

  componentDidMount = async () => {
    const { location, setTableName } = this.props;
    const templateFromUrl = getObjectFromUrl(location, ['id', 'name']);

    setTableName(this.tableName, async () => {
      if (templateFromUrl.id) {
        await this.filterTemplatesByIdName(templateFromUrl.id);
      } else {
        await this.loadTemplates();
      }
    });
  };

  componentDidUpdate(prevProps) {
    const {
      savedTemplatesError, savedTemplatesSuccess, savedTemplates, wsData,
      history, setClearTemplateError, setClearTemplateSuccess,
    } = this.props;

    if (!isEqual(prevProps.savedTemplatesError, savedTemplatesError)) {
      const diff = savedTemplatesError.filter((x) => !prevProps.savedTemplatesError.includes(x));
      const lastDiff = diff && Array.isArray(diff) && diff.length && diff.pop();

      if (
        lastDiff
        && savedTemplates
        && savedTemplates[lastDiff]
        && !savedTemplates[lastDiff].templateId
        && wsData
        && wsData[lastDiff]
        && wsData[lastDiff].message
      ) {
        history.push(`/templates/create?return_id=${lastDiff}`);
      } else {
        setClearTemplateError(lastDiff);
      }
    }

    if (!isEqual(prevProps.savedTemplatesSuccess, savedTemplatesSuccess)) {
      const diff = savedTemplatesSuccess.filter((x) => !prevProps.savedTemplatesSuccess.includes(x));
      const lastDiff = diff && Array.isArray(diff) && diff.length && diff.pop();

      if (
        lastDiff
        && savedTemplates
        && savedTemplates[lastDiff]
        && !savedTemplates[lastDiff].templateId
        && wsData
        && wsData[lastDiff]
        && wsData[lastDiff].message
        && wsData[lastDiff].message.response
        && wsData[lastDiff].message.response.id
      ) {
        this.filterTemplatesByIdName(wsData[lastDiff].message.response.id);
      }
      setClearTemplateSuccess(lastDiff);
    }
  }

  loadTemplates = async (addParams = {}, callback) => {
    const {
      getTemplatesData, page, limit, filterValues,
    } = this.props;
    const params = {
      limit,
      offset: limit * (page - 1),
      group_by: 'descendant',
      view_mode: 'full',
      is_count: true,
    };
    const requestParams = { ...params, ...filterValues, ...addParams };
    await getTemplatesData(this, requestParams);
    callback && callback();
  };

  filterTemplatesByIdName = async (id) => {
    const { changePage, onSelectItem, resetFilters } = this.props;

    resetFilters(
      changePage(1, async () => {
        await this.loadTemplates({ selected: id }, () => {
          const { templates } = this.props;
          onSelectItem(templates[0]);
        });
      })
    );
  };

  onCreateTemplate = () => {
    const { history } = this.props;

    history.push('/templates/create');
  };

  onEditTemplate = () => {
    const { history, selectedItem } = this.props;

    if (selectedItem.id !== '') {
      history.push(`/templates/edit/${selectedItem.id}`);
    }
  };

  onCopyTemplate = async () => {
    const { selectedItem } = this.props;

    this.setState({ isTemplateCoping: true, isProcessing: true });

    const { status, data } = await api.copyTemplate(selectedItem.id);

    if (status === 200) {
      await this.filterTemplatesByIdName(data.id);
    }
    this.setState({ isTemplateCoping: false, isProcessing: false });
  };

  onDeleteTemplate = async () => {
    const { selectedItem, onSelectItem, t } = this.props;

    this.setState({ isProcessing: true });

    const deleteTemplatesAnswer = await api.deleteTemplates(selectedItem.id);

    this.setState({ isProcessing: false });
    onSelectItem({});

    switch (deleteTemplatesAnswer.status) {
      case 204: {
        message.success(t('DELETE_SUCCESS'));
        await this.loadTemplates();
        break;
      }
      case 400: message.error(deleteTemplatesAnswer.data.error); break;
      case 403: message.error(deleteTemplatesAnswer.data.detail); break;
      default: message.error(t('DELETE_ERROR')); break;
    }
  };

  versionUp = async () => {
    const { t, selectedItem, onSelectItem } = this.props;

    if (!selectedItem || selectedItem.family === undefined) {
      return;
    }

    const {
      id, name, template_type, documents,
    } = selectedItem;

    this.setState({ isProcessing: true, isVersionUp: true });

    const { status, data } = await api.cloneTemplate(id, {
      id,
      name,
      template_type,
      documents,
    });

    if (status === 200) {
      message.success(t('SUCCESS_CLONE'));

      await this.loadTemplates();

      const { templates } = this.props;

      onSelectItem(templates.find((item) => item.id === data.id));
    } else {
      message.error(t('ERROR_CLONE'));
    }

    this.setState({ isProcessing: false, isVersionUp: false });
  };

  templateSidebarHandler = () => {
    const { openImportSidebar, changePage, onSelectItem } = this.props;

    openImportSidebar(
      IMPORT_TEMPLATE,
      {
        callback: async (data) => {
          changePage(1, async () => {
            await this.loadTemplates({ selected: data.id }, () => {
              const { templates } = this.props;
              onSelectItem(templates[0]);
            });
          });
        },
      }
    );
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      rights, t, selectedItem,
      templates, loading, count, limit, page, changePage, onShowSizeChange,
      getColumnSearchFilter, filterValues, onSelectItem,
    } = this.props;
    const {
      isProcessing,
      isTemplateCoping,
      isVersionUp,
    } = this.state;

    const isButtonsDisabled = isProcessing || !selectedItem.id;
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    const taggedTemplates = templates.map((template) => {
      const newTemplate = {
        ...template,
        blanks:
        {
          id: template.id,
          size: template.blanks,
        },
      };

      if (template.children) {
        newTemplate.children = template.children.map((childTemplate) => ({
          ...childTemplate,
          blanks:
          {
            id: childTemplate.id,
            size: childTemplate.blanks,
          },
        }));
      }

      return newTemplate;
    });

    return (
      <CustomCard
        className={styles.templateList}
        extra={(
          <div>
            <Button
              className='control-button'
              size='small'
              onClick={this.onEditTemplate}
              disabled={isButtonsDisabled}
            >
              <FolderOpenOutlined />
              {t('OPEN')}
            </Button>
            <Divider type='vertical' />
            <Button
              className='control-button'
              size='small'
              onClick={this.onCopyTemplate}
              disabled={isButtonsDisabled || isReadOnly}
              loading={isTemplateCoping}
            >
              <CopyOutlined />
              {t('MAKE_COPY')}
            </Button>
            <Button
              className='control-button'
              size='small'
              onClick={this.versionUp}
              disabled={
                isButtonsDisabled
                || isReadOnly
                || (selectedItem && (selectedItem.family === undefined))
              }
              loading={isVersionUp}
            >
              <UpCircleOutlined />
              {t('TABLE_COL_VERSION_UP')}
            </Button>
            <Divider type='vertical' />
            <Button
              className='control-button'
              size='small'
              onClick={this.onCreateTemplate}
              disabled={isReadOnly}
            >
              <PlusCircleOutlined />
              {t('CREATE_TEMPLATE')}
            </Button>
            <Popconfirm
              title={t('TEMPLATE_DELETE_CONFIRM')}
              okText={t('YES')}
              cancelText={t('NO')}
              onConfirm={this.onDeleteTemplate}
              disabled={isButtonsDisabled || isReadOnly}
            >
              <Button
                className='control-button'
                size='small'
                disabled={isButtonsDisabled || isReadOnly}
              >
                <Icon component={BucketIcon} />
                {t('DELETE')}
              </Button>
            </Popconfirm>
            <Divider type='vertical' />
            <Button
              className='control-button'
              disabled={isReadOnly}
              type='primary'
              onClick={this.templateSidebarHandler}
              size='small'
              icon={<ImportOutlined />}
            >
              {t('PFPROTECTION_IMPORT')}
            </Button>
          </div>
        )}
      >
        <FixedYCommonTable
          tableName={this.tableName}
          size={TABLE_SIZE}
          className={styles.table}
          columns={[
            {
              title: t('TABLE_COL_NAME'),
              dataIndex: 'name',
              key: 'name',
              width: '40%',
              ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
              render: (text) => (text ? textHighlighter(filterValues['name'], text) : t('WITHOUT_NAME')),
            },
            {
              title: t('TABLE_COL_VERSION'),
              dataIndex: 'dbrza_version',
              key: 'dbrza_version',
              width: '10%',
            },
            {
              title: t('TABLE_COL_BLANKS'),
              dataIndex: 'blanks',
              key: 'blanks',
              width: '10%',
              render: ({ id, size }) => {
                return size
                  ? (
                    <Popover
                      //Фикс для DBRZA-3324 - привязка popover`а к ноде за пределами таблицы
                      getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode}
                      overlayClassName={`${styles.popover} popover-loading-tags enable-scroll-popover`}
                      content={<TemplatesBlanks templateId={id} />}
                      placement='top'
                      trigger='click'
                      destroyTooltipOnHide={true}
                    >
                      <span className={styles.link}>
                        {`${t('ALL')}: ${size}`}
                      </span>
                    </Popover>
                  )
                  : '';
              },
            },
            {
              title: 'Модель PF.Protection',
              dataIndex: 'pf_model',
              key: 'pf_model',
              width: '20%',
            },
            {
              title: 'Версия модели PF.Protection',
              dataIndex: 'pf_version',
              key: 'pf_blank_version',
              width: '20%',
            },
          ]}
          dataSource={taggedTemplates}
          loading={loading}
          pagination={{
            onChange: (newPage) => changePage(newPage, this.loadTemplates),
            total: count,
            pageSize: limit,
            current: page,
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedItem.id],
            onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0]),
          }}
          onRow={(record) => ({
            onClick: () => {
              onSelectItem(record);
            },
          })}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadTemplates)
          }
        />
        <ExportBottom
          icon='file-excel'
          count={count}
          WSData={{
            url: '/v1/legacy-templates',
            params: {
              group_by: 'descendant',
              view_mode: 'full',
              is_count: true,
              ...filterValues,
            },
            accept: 'application/xlsx',
          }}
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  templates: templatesWithChildrenSelector(state),
  loading: state.templates.isTemplatesLoading,
  savedTemplates: state.templates.savedTemplates,
  wsData: state.templates.wsData,
  savedTemplatesError: state.templates.savedTemplatesError,
  savedTemplatesSuccess: state.templates.savedTemplatesSuccess,
  count: state.templates.count,
});

const mapDispatchToProps = {
  getTemplatesData: getTemplates,
  openImportSidebar: openSidebar,
  setClearTemplateError: clearTemplatesError,
  setClearTemplateSuccess: clearTemplatesSuccess,
};

const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      withRouter(
        Templates
      )
    )
  )
);
