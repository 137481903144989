import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  Spin, message,
  Table,
  Radio,
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';

import api from '@services/api';
import store from '@state/store';
import { updateSettings } from '@state/settings/actions';
import { getRights } from '@state/rights/actions';

import { INPUT_TYPE_NUMBER } from '@ui/formItem/constants';
import { isInteger, lessOrEqual, moreOrEqual } from '@globalHelpers';
import {
  MAP_RIGHTS_TO_PATHS, MODE_EDIT, MODE_READONLY, ROUTE_SETTINGS, KB, ROLE_KEY_ADMIN_TECH, TABLE_SIZE, ROLE_KEY_ADMIN_IA, STORAGE_WARNINGS_CAUTION_OFFSET,
} from '@globalConstants';
import { CustomCard, FormItem } from '@ui';
import {
  ADMIN_IA,
  ADMIN_ODU,
  ADMIN_RDU,
  ADMIN_TECH,
  TECH, GUEST,
  NOTIFICATION_TASK_DC_STATUS_CHANGE,
  NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE,
  NOTIFICATION_TASK_DC_DEADLINE,
  NOTIFICATION_TASK_DC_EXPIRATION,
} from '../roles/constants';

import styles from './styles.module.less';

const notificationsRights = [
  NOTIFICATION_TASK_DC_STATUS_CHANGE,
  NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE,
  NOTIFICATION_TASK_DC_DEADLINE,
  NOTIFICATION_TASK_DC_EXPIRATION,
];

class Settings extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isFormChanged: false,
      loading: false,
      formHasError: false,
      componentsToUp: {},
      defaultRights: {},
    };
  }

  componentDidMount() {
    this.getInterfaceRights();
  }

  getInterfaceRights = async () => {
    const { t } = this.props;
    this.setState({ loading: true });
    const { status, data } = await api.getInterfaceRights();
    store.dispatch(getRights());

    if (status === 200) {
      const findRight = (right) => data.results.find((item) => item.component_id === right) || {};
      const rightsData = {
        [NOTIFICATION_TASK_DC_STATUS_CHANGE]: findRight(NOTIFICATION_TASK_DC_STATUS_CHANGE),
        [NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE]: findRight(NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE),
        [NOTIFICATION_TASK_DC_DEADLINE]: findRight(NOTIFICATION_TASK_DC_DEADLINE),
        [NOTIFICATION_TASK_DC_EXPIRATION]: findRight(NOTIFICATION_TASK_DC_EXPIRATION),
      };

      const rolesRows = [
        { key: ADMIN_IA, title: t('ADMIN_IA') },
        { key: ADMIN_ODU, title: t('ADMIN_ODU') },
        { key: ADMIN_RDU, title: t('ADMIN_RDU') },
        { key: ADMIN_TECH, title: t('ADMIN_TECH') },
        { key: TECH, title: t('TECH') },
        { key: GUEST, title: t('GUEST') },
      ];

      const dataSource = rolesRows.map((role) => ({
        ...role,
        [NOTIFICATION_TASK_DC_STATUS_CHANGE]: rightsData[NOTIFICATION_TASK_DC_STATUS_CHANGE][role.key],
        [NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE]:
          rightsData[NOTIFICATION_TASK_DC_SETPOINT_STATUS_CHANGE][role.key],
        [NOTIFICATION_TASK_DC_DEADLINE]: rightsData[NOTIFICATION_TASK_DC_DEADLINE][role.key],
        [NOTIFICATION_TASK_DC_EXPIRATION]: rightsData[NOTIFICATION_TASK_DC_EXPIRATION][role.key],
      }));

      this.setState({
        dataSource,
        defaultRights: rightsData,
      });
    }

    this.setState({ loading: false });
  };

  handleCancel = () => {
    this.form.current.resetFields();
    this.setState({ isFormChanged: false });
  };

  updateRights = async () => {
    const { componentsToUp, defaultRights } = this.state;
    const req = [];

    Object.keys(componentsToUp).forEach((compId) => {
      const data = {
        component_id: compId,
      };
      Object.keys(componentsToUp[compId]).forEach((comp) => {
        data[comp] = componentsToUp[compId][comp];
      });

      req.push(api.patchInterfaceRights(defaultRights[compId].id, data));
    });

    await Promise.all(req);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { t } = this.props;
    const { componentsToUp } = this.state;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ loading: true });

      const json = JSON.stringify(values);
      const blob = new Blob([json], {
        type: 'application/json',
      });
      const formData = new FormData();
      formData.append('json', blob);
      const { status } = await api.changeSettings(formData);
      await this.updateRights();

      if (status === 200) {
        message.success(t('SUCCESS_SAVE'));

        if (Object.keys(componentsToUp).length) {
          this.getInterfaceRights();
        }

        this.setState({
          isFormChanged: false,
          isEdit: false,
          componentsToUp: {},
        });
        store.dispatch(updateSettings(values));
      } else {
        message.error(t('ERROR_SAVE'));
      }

      this.setState({ loading: false });
    }).catch(({ errorFields }) => this.form.current.scrollToField(errorFields && errorFields[0].name));
  };

  changeFormValues = () => {
    const { settings } = this.props;
    let isFormChanged = false;

    const updatedValues = this.form.current.getFieldsValue();
    const updatedSettings = { ...settings };

    updatedSettings.MAX_UPLOAD_FILE_SIZE = updatedSettings.MAX_UPLOAD_FILE_SIZE / KB / KB;

    isFormChanged = !isEqual(updatedValues, updatedSettings);

    this.form.current.validateFields().then(() => { // to update form values
      this.setState({
        isFormChanged,
        formHasError: false,
      });
    }).catch((errorInfo) => {
      if (errorInfo && errorInfo.errorFields.length) {
        this.setState({
          isFormChanged: true,
          formHasError: true,
        });
      }
    });
  };

  toggleSettingsEdit = (e) => {
    e.preventDefault();

    const { isEdit } = this.state;

    if (isEdit) {
      this.form.current.resetFields();
    }

    this.setState({
      isEdit: !isEdit,
    });
  };

  cancelChanges = () => {
    this.setState({
      componentsToUp: {},
    });
    this.toggleSettingsEdit({
      preventDefault: () => { },
    });
  };

  onChangeRight = (right, key, event) => {
    const { componentsToUp, defaultRights, isFormChanged } = this.state;
    const path = `${right}.${key}`;
    const newComponentsToUp = cloneDeep(componentsToUp);
    const defaultValue = get(defaultRights, path);
    let newIsFormChanged = false;

    if (event.target.value && defaultValue !== MODE_EDIT) {
      set(newComponentsToUp, path, MODE_EDIT);
    } else if (!event.target.value && defaultValue === MODE_EDIT) {
      set(newComponentsToUp, path, MODE_READONLY);
    } else {
      unset(newComponentsToUp, path);

      if (newComponentsToUp[right] && !Object.keys(newComponentsToUp[right]).length) {
        unset(newComponentsToUp, path);
      }
    }

    if (Object.keys(newComponentsToUp).length) {
      newIsFormChanged = true;
    }

    this.setState({
      componentsToUp: newComponentsToUp,
      isFormChanged: newIsFormChanged || isFormChanged,
    });
  };

  renderCheckbox = (role, right, key) => {
    const { t } = this.props;
    const { loading, isEdit } = this.state;

    if (isEdit) {
      return (
        <Radio.Group
          disabled={loading}
          onChange={(event) => this.onChangeRight(right, key, event)}
          defaultValue={role === MODE_EDIT}
        >
          <Radio value={true}>{t('YES')}</Radio>
          <Radio value={false}>{t('NO')}</Radio>
        </Radio.Group>
      );
    } else {
      return role === MODE_EDIT ? (
        <>
          <CheckCircleOutlined className={styles.yes} />
          &nbsp;{t('YES')}
        </>
      ) : (
        <>
          <CloseCircleOutlined className={styles.no} />
          &nbsp;{t('NO')}
        </>
      );
    }
  };

  render() {
    const {
      t,
      rights,
      settings,
      user,
    } = this.props;
    const {
      isFormChanged, loading, formHasError, dataSource, isEdit,
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_SETTINGS]] !== MODE_EDIT
      || loading;
    const columns = [
      {
        title: t('ROLE'),
        dataIndex: 'title',
        key: 'title',
      },
      ...notificationsRights.map((right) => ({
        title: t(right),
        dataIndex: right,
        key: right,
        render: (role, record) => this.renderCheckbox(role, right, record.key),
      })),
    ];

    return (
      <CustomCard
        className={styles.content}
        title={null}
        extra={(
          <>
            {rights[MAP_RIGHTS_TO_PATHS[ROUTE_SETTINGS]] === MODE_EDIT ? (
              <>
                {!isEdit && (
                  <Button
                    tabIndex={0}
                    size='small'
                    onClick={this.toggleSettingsEdit}
                    icon={<EditOutlined />}
                    disabled={isReadOnly || formHasError}
                  >
                    {t('EDIT')}
                  </Button>
                )}
                {isEdit && (
                  <>
                    <Button
                      disabled={loading}
                      tabIndex={0}
                      size='small'
                      onClick={this.cancelChanges}
                    >
                      {t('CANCEL')}
                    </Button>
                    &nbsp;
                    <Button
                      disabled={!isFormChanged || isReadOnly || formHasError}
                      loading={loading}
                      tabIndex={0}
                      size='small'
                      type='primary'
                      onClick={this.handleSubmit}
                    >
                      {t('SAVE')}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <span>{t('EDIT')}</span>
            )}
          </>
        )}
      >
        {user?.roles.length > 0 && <Form
          ref={this.form}
          name='settings'
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          labelAlign='left'
          initialValues={{
            TTL_PASSPORT: settings.TTL_PASSPORT,
            TASK_DC_DAYS_LEFT_NOTIFY: settings.TASK_DC_DAYS_LEFT_NOTIFY,
            MAX_UPLOAD_FILE_SIZE: settings.MAX_UPLOAD_FILE_SIZE / KB / KB,
            STORAGE_CERTIFICATE_WARNING_POINT: settings.STORAGE_CERTIFICATE_WARNING_POINT,
            STORAGE_UPTIME_WARNING_POINT: settings.STORAGE_UPTIME_WARNING_POINT,
            NETWORK_MODELS_EDITABLE_PERIOD: settings.NETWORK_MODELS_EDITABLE_PERIOD
          }}
        >
          {user.roles.some(role => [ROLE_KEY_ADMIN_TECH, ROLE_KEY_ADMIN_IA].includes(role.key)) && (
            <FormItem
              id='TTL_PASSPORT'
              label={t('TTL_PASSPORT')}
              secondLabel={` ${t('MONTH_SHORT')}`}
              form={this.form.current}
              initialValue={settings.TTL_PASSPORT}
              isReadOnly={isReadOnly || !isEdit}
              inputStyle={{ width: 80 }}
              FormItemType={INPUT_TYPE_NUMBER}
              min={0}
              formChangeHandle={this.changeFormValues}
              rules={[
                isInteger,
                () => moreOrEqual(0),
              ]}
            />
          )}
          <FormItem
            id='TASK_DC_DAYS_LEFT_NOTIFY'
            label={t('TASK_DC_DAYS_LEFT_NOTIFY')}
            secondLabel={` ${t('FOR_DAYS')}`}
            form={this.form.current}
            initialValue={settings.TASK_DC_DAYS_LEFT_NOTIFY}
            isReadOnly={isReadOnly || !isEdit}
            inputStyle={{ width: 80 }}
            FormItemType={INPUT_TYPE_NUMBER}
            min={0}
            max={14}
            formChangeHandle={this.changeFormValues}
            rules={[
              { required: true, message: t('REQUIRED_FIELD') },
              isInteger,
              () => moreOrEqual(0, true),
              () => lessOrEqual(14, true),
            ]}
          />
          <FormItem
            id='MAX_UPLOAD_FILE_SIZE'
            label={t('MAX_UPLOAD_FILE_SIZE')}
            secondLabel={` ${t('MEGABYTES')}`}
            form={this.form.current}
            initialValue={settings.MAX_UPLOAD_FILE_SIZE / KB / KB}
            isReadOnly={isReadOnly || !isEdit}
            inputStyle={{ width: 80 }}
            FormItemType={INPUT_TYPE_NUMBER}
            min={0}
            max={1000}
            formChangeHandle={this.changeFormValues}
            rules={[
              { required: true, message: t('REQUIRED_FIELD') },
              isInteger,
              () => moreOrEqual(0, true),
              () => lessOrEqual(1000, true),
            ]}
          />
          <FormItem
            id='STORAGE_UPTIME_WARNING_POINT'
            label={t('STORAGE_UPTIME_WARNING_POINT')}
            secondLabel={` ${t('FOR_DAYS')}`}
            form={this.form.current}
            initialValue={settings.STORAGE_UPTIME_WARNING_POINT}
            isReadOnly={isReadOnly || !isEdit}
            inputStyle={{ width: 80 }}
            FormItemType={INPUT_TYPE_NUMBER}
            min={STORAGE_WARNINGS_CAUTION_OFFSET}
            max={1000}
            formChangeHandle={this.changeFormValues}
            rules={[
              { required: true, message: t('REQUIRED_FIELD') },
              isInteger,
              () => moreOrEqual(0, true),
              () => lessOrEqual(1000, true),
            ]}
          />
          <FormItem
            id='STORAGE_CERTIFICATE_WARNING_POINT'
            label={t('STORAGE_CERTIFICATE_WARNING_POINT')}
            secondLabel={` ${t('FOR_DAYS')}`}
            form={this.form.current}
            initialValue={settings.STORAGE_CERTIFICATE_WARNING_POINT}
            isReadOnly={isReadOnly || !isEdit}
            inputStyle={{ width: 80 }}
            FormItemType={INPUT_TYPE_NUMBER}
            min={STORAGE_WARNINGS_CAUTION_OFFSET}
            max={1000}
            formChangeHandle={this.changeFormValues}
            rules={[
              { required: true, message: t('REQUIRED_FIELD') },
              isInteger,
              () => moreOrEqual(0, true),
              () => lessOrEqual(1000, true),
            ]}
          />
          <FormItem
            id='NETWORK_MODELS_EDITABLE_PERIOD'
            label={t('RMMS_EDITING_TIME')}
            secondLabel={` ${t('FOR_DAYS')}`}
            form={this.form.current}
            initialValue={settings.NETWORK_MODELS_EDITABLE_PERIOD}
            isReadOnly={isReadOnly || !isEdit}
            inputStyle={{ width: 80 }}
            FormItemType={INPUT_TYPE_NUMBER}
            min={1}
            max={1000}
            formChangeHandle={this.changeFormValues}
            rules={[
              { required: true, message: t('REQUIRED_FIELD') },
              isInteger,
              () => moreOrEqual(0, true),
              () => lessOrEqual(1000, true),
            ]}
          />
        </Form>}
        {rights ? (
          <Table
            loading={loading}
            className={styles.table}
            size={TABLE_SIZE}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={
              { x: true }
            }
          />
        ) : (
          <Spin />
        )}
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
  rights: state.rights.rightsData,
  settings: state.settings.data,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Settings
  )
);
