import { TAB_ACTIONS, TAB_MESSAGES } from '@common/journal/constants';
import { LIMIT } from '@globalConstants';
import {
  CLEAR_JOURNAL_STATE,
  GET_ACTIONS_LOG, GET_MESSAGES_LOG,
  SET_FILTERS_ACTIONS, SET_FILTERS_MESSAGES,
  GET_LIMIT, SET_LIMIT,
  SET_DATE_RANGE,
  SET_PAGE,
} from './types';

const initialState = {
  actionsLogs: [],
  messagesLogs: [],
  isActionsLoading: false,
  isMessagesLoading: false,
  start: '',
  end: '',
  actionFilters: {},
  messageFilters: {},
  limit: {
    [TAB_ACTIONS]: LIMIT,
    [TAB_MESSAGES]: LIMIT,
  },
  page: {
    [TAB_ACTIONS]: 1,
    [TAB_MESSAGES]: 1,
  },
  count: {
    [TAB_ACTIONS]: 0,
    [TAB_MESSAGES]: 0,
  },
};

function journalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIONS_LOG:
      return {
        ...state,
        isActionsLoading: true,
      };
    case `${GET_ACTIONS_LOG}_SUCCESS`:
      return {
        ...state,
        actionsLogs: [
          ...action.logs,
        ],
        count: {
          ...state.count,
          [TAB_ACTIONS]: action.count,
        },
        isActionsLoading: false,
      };
    case `${GET_ACTIONS_LOG}_ERROR`:
      return {
        ...state,
        isActionsLoading: false,
      };
    case GET_MESSAGES_LOG:
      return {
        ...state,
        isMessagesLoading: true,
      };
    case `${GET_MESSAGES_LOG}_SUCCESS`:
      return {
        ...state,
        messagesLogs: [
          ...action.logs,
        ],
        count: {
          ...state.count,
          [TAB_MESSAGES]: action.count,
        },
        isMessagesLoading: false,
      };
    case `${GET_MESSAGES_LOG}_ERROR`:
      return {
        ...state,
        isMessagesLoading: false,
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        start: action.start,
        end: action.end,
      };
    case SET_FILTERS_ACTIONS:
      return {
        ...state,
        actionFilters: {
          ...state.actionFilters,
          ...action.filters,
        },
      };
    case SET_FILTERS_MESSAGES:
      return {
        ...state,
        messageFilters: {
          ...state.messageFilters,
          ...action.filters,
        },
      };
    case GET_LIMIT:
      return {
        ...state,
        limit: {
          ...state.limit,
          [action.activeTab]: action.limit,
        },
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: {
          ...state.limit,
          [action.activeTab]: action.limit,
        },
      };
    case SET_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          [action.activeTab]: action.page,
        },
      };
    case CLEAR_JOURNAL_STATE:
      return initialState;
    default:
      return state;
  }
}

export default journalReducer;
