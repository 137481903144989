import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Input, Button, Table, message, Form, Spin,
} from 'antd';

import api from '@services/api';
import { getShortBucket } from '@state/bucket/actions';

import { SidebarLayout, SidebarCard } from '@ui';
import { TABLE_SIZE } from '@globalConstants';

import styles from './styles.module.less';

class ComplexesFormSidebar extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      selectedDevices: [],
      selectedDevicesIds: [],
      initSelectedDevicesIds: [],
    };
  }

  componentDidMount() {
    const { data, getBucketShortData } = this.props;
    const { complex } = data;

    if (complex) {
      this.form.current.setFieldsValue({ name: complex.name });
      this.loadComplex();
    }

    getBucketShortData(false);
  }

  loadComplex = async () => {
    const { data } = this.props;
    const { complex } = data;

    this.setState({ isLoading: true });
    const complexData = await api.getDevicesComplexItem(complex.id);
    this.setState({ isLoading: false });

    if (complexData.status === 200) {
      const initSelectedDevicesIds = complexData.data.protection_devices
        ? complexData.data.protection_devices.map(({ id }) => id)
        : [];

      this.setState({
        selectedDevices: complexData.data ? complexData.data.protection_devices : [],
        selectedDevicesIds: initSelectedDevicesIds,
        initSelectedDevicesIds,
      });
    }
  };

  onDeviceChange = (selectedDevicesIds) => {
    this.setState({
      selectedDevicesIds,
    });
  };

  onCreateComplex = async () => {
    const { data, t } = this.props;
    const { callback } = data;

    this.form.current.validateFields().then(async (values) => {
      const { selectedDevicesIds } = this.state;

      this.setState({ isSending: true });
      const response = await api.createDevicesComplex({
        name: values.name,
        protection_devices: selectedDevicesIds,
      });
      this.setState({ isSending: false });

      if (response.status === 201) {
        callback && callback(response.data);
        message.success(t('CLASSIFICATORS_COMPLEXES_CREATE_SUCCESS'));
      } else {
        this.form.current.setFields(Object.entries(response.data).map(([field, err]) => ({
          name: field,
          errors: err,
        })));
      }
    });
  };

  onUpdateComplex = async () => {
    const { data, t } = this.props;
    const { complex, callback } = data;

    this.form.current.validateFields().then(async (values) => {
      const { selectedDevicesIds } = this.state;

      this.setState({ isSending: true });
      const response = await api.updateDevicesComplex(complex.id, {
        name: values.name,
        protection_devices: selectedDevicesIds,
      });
      this.setState({ isSending: false });

      if (response.status === 200) {
        callback && callback(response.data);
        message.success(t('CLASSIFICATORS_COMPLEXES_EDIT_SUCCESS'));
      } else {
        message.error(t('CLASSIFICATORS_COMPLEXES_EDIT_ERROR'));
      }
    });
  };

  render() {
    const {
      data, t, bucketShortData,
    } = this.props;
    const { complex } = data;
    const {
      isLoading, isSending, selectedDevices, selectedDevicesIds, initSelectedDevicesIds,
    } = this.state;
    let sortedSelectedDevices = [];
    const sortedBucketDevices = bucketShortData
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(({ id }) => !initSelectedDevicesIds.includes(id));

    if (selectedDevices.length) {
      sortedSelectedDevices = selectedDevices.sort((a, b) => a.name.localeCompare(b.name));
    }

    const devices = [...sortedSelectedDevices, ...sortedBucketDevices];

    return (
      <SidebarLayout
        withoutCard
        noBounds
        className={styles.complexesForm}
        header={(
          <h4>
            {complex
              ? `${t('CLASSIFICATORS_COMPLEXES_EDIT')} ${t('CLASSIFICATORS_COMPLEXES_COMPLEX')}`
              : `${t('CLASSIFICATORS_COMPLEXES_CREATE')} ${t('CLASSIFICATORS_COMPLEXES_COMPLEX')}`}
          </h4>
        )}
        footer={(
          <Button
            block
            type='primary'
            loading={isSending}
            disabled={!!isLoading}
            onClick={complex ? this.onUpdateComplex : this.onCreateComplex}
          >
            {complex && t('CLASSIFICATORS_COMPLEXES_EDIT')}
            {!complex && t('CLASSIFICATORS_COMPLEXES_CREATE')}
          </Button>
        )}
      >
        <Spin spinning={!!isLoading}>
          <SidebarCard>
            <SidebarCard.content>
              <SidebarCard.label>{t('CLASSIFICATORS_COMPLEXES_NAME')}</SidebarCard.label>
              <Form name='complexesForm' ref={this.form}>
                <Form.Item name='name' rules={[{ required: true, message: t('CLASSIFICATORS_COMPLEXES_ENTER_NAME') }]}>
                  <Input placeholder={t('CLASSIFICATORS_COMPLEXES_ENTER_NAME')} />
                </Form.Item>
              </Form>
            </SidebarCard.content>
          </SidebarCard>
          <SidebarCard>
            <Table
              size={TABLE_SIZE}
              rowKey='id'
              columns={[
                {
                  title: t('DEVICE'),
                  dataIndex: 'name',
                  key: 'name',
                  render: (text) => text || '',
                },
              ]}
              dataSource={devices}
              loading={isLoading}
              pagination={false}
              rowClassName={styles.devices}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedDevicesIds,
                onChange: this.onDeviceChange,
              }}
            />
          </SidebarCard>
        </Spin>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  bucketShortData: state.bucket.bucketShortData,
});

const mapDispatchToProps = {
  getBucketShortData: getShortBucket,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    ComplexesFormSidebar
  )
);
