import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '@state/store';
import { Button } from 'antd';

import CommonTable from '@common/CommonTable';
import { Column } from '@common/CommonTable/types';
import { CustomCard } from '@ui';
import { withPagination, withRowSelection } from '@hoc';
import { TABLE_SIZE } from '@globalConstants';

import { Letter, LetterFile, User } from '@services/api-dts';
import { TableRowSelection } from 'antd/lib/table/interface';

interface Props extends WithTranslation {
  userInfo: User;
  letter: Letter;
  passportId: string;
  setTableName: (tableName: string, callback?: Function) => void;
  onShowSizeChange: (current: number, size: number, name?: string, callback?: Function) => void;
  loadByPage: (
    tableName: string|null,
    apiFunctionName: string,
    mainParams: {},
    okStatuses: number[] | number,
    callbackOk?: ((status: number, data: { results: LetterFile[]; count: number }) => void) | null,
    callbackError?: (() => void) | null
  ) => void;
  changePage: (page: number, callback?: () => void) => void;
  page: number;
  limit: number;
  selectedItem: LetterFile;
  onSelectItem: (item: LetterFile | null) => void;
  isArchived: boolean;
}

interface State {
  files: LetterFile[];
  count: number;
  isLoading: boolean;
}

class LetterFiles extends Component<Props, State> {
  tableName = 'passport_tab_letter_files';

  state = {
    files: [],
    count: 0,
    isLoading: false,
  }

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName, () => this.loadFiles());
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { letter, onSelectItem } = this.props;

    if (prevProps.letter.id !== letter.id) {
      this.loadFiles();
      onSelectItem({});
    }
  }

  getColumnFiles = (): Column[] => {
    const { t } = this.props;

    return [
      {
        title: t('JOURNAL_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '33.3%',
      },
      {
        title: `${t('FILE_SIZE')}`,
        dataIndex: 'size',
        key: 'size',
        width: '33.3%',
      },
      {
        title: `${t('EXTENSION')}`,
        dataIndex: 'extension',
        key: 'extension',
        width: '33.3%',
        render: (text: string) => `*.${text}`,
      },
    ];
  };

  loadFiles = async (addParams = {}) => {
    const { letter, loadByPage } = this.props;
    const params = {
      landocs_id: letter.landocs_id,
      letter_id: letter.id,
    };

    this.setState({ isLoading: true });

    await loadByPage(
      this.tableName,
      'getLetterFiles',
      { ...params, ...addParams },
      [200, 201],
      (status, data) => {
        this.setState({
          files: data.results,
          count: data.count,
        });
      }
    );

    this.setState({ isLoading: false });
  };

  render() {
    const {
      t, changePage, limit, page, selectedItem, onSelectItem, letter, userInfo, isArchived, onShowSizeChange,
    } = this.props;
    const { files, count, isLoading } = this.state;
    const rowSelection: TableRowSelection<object> = {
      type: 'radio',
      selectedRowKeys: selectedItem.id ? [selectedItem.id] : [''],
      onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0] as LetterFile),
    };

    const enableDownload = (
      (
        userInfo && userInfo.dispatcher_center && userInfo.dispatcher_center.id
        && letter && letter.dispatcher_center && letter.dispatcher_center.id
        && userInfo.dispatcher_center.id === letter.dispatcher_center.id
      )
      || (letter && (!letter.dispatcher_center || !letter.dispatcher_center.id))
      || !isArchived
    );

    return (
      <CustomCard
        style={{ margin: '16px' }}
        title={t('FILES')}
        extra={(
          <Button
            size='small'
            className='control-button'
            disabled={!Object.keys(selectedItem).length || !enableDownload}
            title={!enableDownload ? t('DOWNLOAD_DISABLE_BECAUSE_NOT_USER_DISPATCHER_CENTER') : undefined}
          >
            <a href={selectedItem.external_link}>{t('DOWNLOAD')}</a>
          </Button>
        )}
      >
        <CommonTable
          tableName={this.tableName}
          size={TABLE_SIZE}
          loading={isLoading}
          columns={this.getColumnFiles()}
          dataSource={files}
          pagination={{
            onChange: (newPage: number) => changePage(newPage, this.loadFiles),
            total: count,
            pageSize: limit,
            current: page,
          }}
          rowSelection={rowSelection}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadFiles)
          }
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state: Readonly<RootState>) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withPagination(
      withRowSelection(
        LetterFiles
      )
    )
  )
);
