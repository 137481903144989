import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { MAP_RIGHTS_TO_PATHS, ROUTE_403 } from '@globalConstants';

const PrivateRoute = (p) => {
  const { rights, path, ...props } = p;
  const currentRightsComponent = MAP_RIGHTS_TO_PATHS[path];

  return rights[currentRightsComponent] === null ? <Redirect to={ROUTE_403} /> : <Route path={path} {...props} />;
};

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  PrivateRoute
);
