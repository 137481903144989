import api from '@services/api';
import { MAX_FILE_SIZE, KB } from '@globalConstants';
import { GET_SETTINGS, UPDATE_SETTINGS } from './types';

const getBytes = (mb) => {
  return mb * KB * KB;
};

export const getSettings = () => {
  return async (dispatch) => {
    const { status, data } = await api.getSettings();

    const settings = { ...data };

    settings.MAX_UPLOAD_FILE_SIZE = getBytes(data.MAX_UPLOAD_FILE_SIZE || MAX_FILE_SIZE);

    if (status === 200) {
      dispatch({
        type: GET_SETTINGS,
        settings,
      });
    }
  };
};

export const updateSettings = (settings) => {
  return async (dispatch) => {
    const nextSettings = { ...settings };

    nextSettings.MAX_UPLOAD_FILE_SIZE = getBytes(settings.MAX_UPLOAD_FILE_SIZE);

    dispatch({
      type: UPDATE_SETTINGS,
      settings: nextSettings,
    });
  };
};
