import React, { PureComponent } from 'react';
import { MessageOutlined } from '@ant-design/icons';

import styles from '../styles.module.less';

class NotificationCard extends PureComponent {
  render() {
    const {
      title, notification, changed_date, notes_number, onClick,
    } = this.props;

    return (
      <div tabIndex={0} role='button' className={styles.setpointDcCard} onClick={onClick}>
        {title && <div className={styles.title}>{title}</div>}
        <p>{notification}</p>
        <div className={styles.footer}>
          <div>
            <MessageOutlined />
            &nbsp;
            {notes_number}
          </div>
          <div>{changed_date}</div>
        </div>
      </div>
    );
  }
}

export default NotificationCard;
