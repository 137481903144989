import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Badge, Button, Card } from 'antd';

import api from '@services/api';
import { ExportBottom } from '@common/exportPopconfirm';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { PASSPORT_DETAILS } from '@common/sidebarRoot/types';
import { CustomTag, PopoverEllipsis, PopoverTags } from '@ui';

import AbstractTab from '../../abstractTab';
import ResetTableBtn from '../../ResetTableBtn';
import { globalSearchTableNamePrefix } from '../../constants';

import styles from './styles.module.less';

const openPassportInfoSidebar = async (id, openSidebar, component) => {
  component.setState({
    isLoading: true,
  });

  const { status, data } = await api.getProtectionDevice(id);

  if (status === 200 && data?.passports?.length > 0) {
    const { status: statusPassport } = await api.getPassport(data.passports[0].id);

    if (statusPassport === 200) {
      openSidebar(PASSPORT_DETAILS, {
        ...data,
        treeFooter: true,
      });
    }
  }

  component.setState({
    isLoading: false,
  });
};

const prefix = 'DEVICE';
const filters = [
  {
    name: 'DEVICE.COL_NAME',
    field: 'name',
    queryFiled: 'name',
    type: 'STRING',
    width: '13%',
    fixed: false,
    initialWidth: 280,
    visible: true,
    customRender: (record, content, keyId, openSidebar, AbstractTabComponent) => {
      const link = (
        <Button
          type='link'
          size='small'
          className='small-font'
          key={`link_${record.id}_${keyId}`}
          onClick={() => openPassportInfoSidebar(record.id, openSidebar, AbstractTabComponent)}
        >
          {content}
        </Button>
      );
      return (
        <PopoverEllipsis content={content} containerStyles={{ color: '#1890ff', wordBreak: 'normal' }}>
          {link}
        </PopoverEllipsis>
      );
    },
  },
  {
    name: 'DEVICE.COL_TERRITORY',
    field: 'region',
    queryFiled: 'region',
    type: 'CHECK_LIST',
    width: '6%',
    initialWidth: 75,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        // TODO: потом надо включить обратно, когда появится связка ДЦ <=> Территория
        // dispatcher_center: filterValuesList['DEVICE.COL_DISPATCHER_CENTER'],
      };
      return api.getDataForFilter('/v1/regions/', params);
    },
  },
  {
    name: 'DEVICE.COL_DISPATCHER_CENTER',
    // field: '',
    field: 'dispatcher_center',
    queryFiled: 'dispatcher_center',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 160,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        owner: filterValuesList['DEVICE.COL_OWNER'],
      };

      return api.getDataForFilter('/v1/dispatcher-centers/', params);
    },
  },
  {
    name: 'DEVICE.COL_ROLES',
    field: 'roles',
    // queryFiled: 'dispatch_control',
    type: 'ONLY_VIEW_COLUMN',
    width: '5%',
    initialWidth: 90,
    fixedWidth: '90px',
    visible: true,
    render: (content) => {
      const rolesHash = {};

      if (content && Array.isArray(content) && content.length) {
        content.forEach((role) => {
          const key = role.name;
          if (!rolesHash[key]) {
            rolesHash[key] = [];
          }
          rolesHash[key].push(role);
        });

        return Object.entries(rolesHash).map(([name, roles]) => (
          <div key={name} className={styles.rolesLabel}>
            {name}:
            <PopoverTags size={roles.length} getPopupContainer={(triggerNode) => triggerNode.closest('tr')}>
              {roles.map(
                (role) =>
                  role.dc && (
                    <CustomTag
                      maxChars={25}
                      className={styles.rolesTag}
                      key={role.dc.id}
                      disablePopover={roles.length > 1}
                      getPopupContainer={(triggerNode) => triggerNode.closest('tr')}
                    >
                      {role.dc.name}
                    </CustomTag>
                  )
              )}
            </PopoverTags>
          </div>
        ));
      }
      return '';
    },
  },
  {
    name: 'DEVICE.COL_DISPATCHER_CONTROL',
    field: '',
    // field: 'dispatch_control',
    queryFiled: 'dispatch_control',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 160,
    visible: false,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        owner: filterValuesList['DEVICE.COL_OWNER'],
      };

      return api.getDataForFilter('/v1/dispatcher-centers/', params);
    },
  },
  {
    name: 'DEVICE.COL_DISPATCHER_MANAGEMENT',
    field: '',
    // field: 'dispatching',
    queryFiled: 'dispatching',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 160,
    visible: false,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        owner: filterValuesList['DEVICE.COL_OWNER'],
      };

      return api.getDataForFilter('/v1/dispatcher-centers/', params);
    },
  },
  {
    name: 'DEVICE.COL_TECHNICAL_ACCOUNTING',
    field: '',
    // field: 'technical_accounting',
    queryFiled: 'technical_accounting',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 160,
    visible: false,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        owner: filterValuesList['DEVICE.COL_OWNER'],
      };

      return api.getDataForFilter('/v1/dispatcher-centers/', params);
    },
  },
  {
    name: 'DEVICE.COL_SHORT_NAME',
    field: 'short_name',
    queryFiled: 'short_name',
    type: 'STRING',
    width: '6%',
    initialWidth: 120,
    visible: false,
  },
  {
    name: 'DEVICE.COL_FUNCTION',
    field: 'device_tracking_group',
    queryFiled: 'func',
    type: 'CHECK_LIST',
    width: '6%',
    initialWidth: 120,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/functions/', { name__icontains: name, selected });
    },
  },
  {
    name: 'DEVICE.COL_USAGE_START_DATE',
    field: 'usage_start_date',
    queryFiled: 'usage_start_date_before',
    type: 'DATE_RANGE',
    width: '6%',
    initialWidth: 75,
    visible: true,
  },
  {
    name: 'DEVICE.COL_USAGE_END_DATE',
    field: 'dismantlement_date',
    queryFiled: 'dismantlement_date_before',
    type: 'DATE_RANGE',
    width: '6%',
    initialWidth: 75,
    visible: true,
  },
  {
    name: 'DEVICE.COL_EQUIPMENT_BASE',
    field: 'equipment_base',
    queryFiled: 'equipment',
    type: 'CHECK_LIST',
    width: '8%',
    initialWidth: 190,
    visible: true,
    className: styles.equipment_base,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/equipment/', { name, selected });
    },
    render: (content) => {
      let tags = [];

      if (content?.length) {
        tags = content.map((tag) => (
          <CustomTag
            key={tag.id}
            disablePopover={true}
            getPopupContainer={(triggerNode) => triggerNode.closest('tr')}
          >
            {tag.name}
          </CustomTag>
        ));
      }
      return (
        <PopoverTags
          size={content?.length}
          getPopupContainer={(triggerNode) => triggerNode.closest('tr')}
          disableChildrenHiding
        >
          {tags}
        </PopoverTags>
      );
    },
  },
  {
    name: 'DEVICE.COL_SUBSTATION',
    field: 'substation',
    queryFiled: 'substation',
    type: 'CHECK_LIST',
    width: '7.2%',
    initialWidth: 170,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        dispatcher_center: filterValuesList['DEVICE.COL_DISPATCHER_CENTER'],
      };

      return api.getDataForFilter('/v1/substations/tree', params);
    },
  },
  {
    name: 'DEVICE.COL_PRODUCER',
    field: 'producer',
    queryFiled: 'producer',
    type: 'CHECK_LIST',
    width: '6.5%',
    initialWidth: 150,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/producers/', { name, selected });
    },
  },
  {
    name: 'DEVICE.COL_IMPLEMENTATION_TYPE',
    field: 'implementation_type',
    queryFiled: 'implementation_type',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 140,
    visible: false,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/implementation-types/', { name, selected });
    },
  },
  {
    name: 'DEVICE.COL_TRADE_DEVICE',
    field: 'trade_device',
    queryFiled: 'trade_device',
    type: 'CHECK_LIST',
    width: '8%',
    initialWidth: 180,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        implementation_type: filterValuesList['DEVICE.COL_IMPLEMENTATION_TYPE'],
        producer: filterValuesList['DEVICE.COL_PRODUCER'],
      };
      return api.getDataForFilter('/v1/trade-devices/', params);
    },
  },
  {
    name: 'DEVICE.COL_OWNER',
    field: 'owner',
    queryFiled: 'owner',
    type: 'CHECK_LIST',
    width: '6.5%',
    initialWidth: 150,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/owners/', { name, selected });
    },
  },
  {
    name: 'DEVICE.COL_VOLTAGE_LEVEL',
    field: 'voltage_level',
    queryFiled: 'voltage_level',
    type: 'CHECK_LIST',
    width: '5%',
    initialWidth: 100,
    visible: false,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/voltage-levels/', { name, selected });
    },
  },
  {
    name: 'DEVICE.COL_SOFTWARE_VERSION',
    field: 'device_version',
    queryFiled: 'device_version',
    type: 'CHECK_LIST',
    width: '4%',
    initialWidth: 85,
    visible: true,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        trade_device: filterValuesList['DEVICE.COL_TRADE_DEVICE'],
        custom_name: true
      };
      return api.getDataForFilter('/v1/versions/', params);
    },
  },
  {
    name: 'DEVICE.COL_TASK_DC_STATUSES',
    field: 'task_dc_statuses',
    queryFiled: 'task_dc_statuses',
    type: 'CHECK_LIST',
    width: '7%',
    initialWidth: 140,
    visible: false,
    getPopupContainer: (triggerNode) => triggerNode.closest('tr'),
    getList: async (name, selected) => {
      return api.getDataForFilter('/v1/task-dc/statuses', { name, selected });
    },
  },
];

class Devices extends Component {
  tableName = `${globalSearchTableNamePrefix}${prefix.toLowerCase()}`;

  componentDidMount() {
    const { setTableName, setInitialFilters } = this.props;

    setTableName(this.tableName, () => {
      setInitialFilters();
    });
  }

  render() {
    const {
      t,
      data,
      count,
      isLoading,
      countFiltersWithValues,
      getListProps,
      searchValue,
      getColumns,
      onShowSizeChange,
      toggleFilter,
      filterValuesForExport,
      loadData,
      id,
      isActiveResetTableBtn,
      handleActivateResetTableBtn,
      handleResetTable,
    } = this.props;

    const listProps = getListProps();
    const columns = getColumns(prefix);

    let tableWidth = 0;

    columns.forEach((item) => {
      if (item.initialWidth) tableWidth += item.initialWidth;
    });

    const columnsWithWidth = columns.filter((item) => !!item.width);

    if (columnsWithWidth.length) {
      const hiddenColumns = filters.filter((filter) => !columnsWithWidth.find((column) => column.key === filter.field));

      if (hiddenColumns.length) {
        let hiddenWidth = 0;

        hiddenColumns.forEach((col) => {
          hiddenWidth += parseFloat(col.width);
        });

        columnsWithWidth.forEach((column) => {
          column.width = parseFloat(column.width) + hiddenWidth / columnsWithWidth.length;
        });
      }
    }

    return (
      <Card
        className={styles.card}
        extra={
          <>
            <Badge offset={[-6, 3]} className={styles.badge} count={countFiltersWithValues} size='small'>
              <Button onClick={toggleFilter} size='small'>{t('FILTERS_AND_CONFIGURATIONS')}</Button>
            </Badge>
            <ResetTableBtn disabled={!isActiveResetTableBtn} onClick={handleResetTable} />
          </>
        }
        bordered={false}
      >
        <FixedYCommonTable
          {...listProps}
          tableName={this.tableName}
          className={styles.table}
          columns={columns}
          dataSource={data}
          width={tableWidth}
          loading={isLoading}
          handleActivateResetTableBtn={handleActivateResetTableBtn}
          onShowSizeChange={(current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, loadData)}
        />
        <ExportBottom
          count={count}
          WSData={{
            url: `/v1/${id}/`,
            params: { ...filterValuesForExport, search: searchValue },
            accept: 'application/xlsx',
          }}
        />
      </Card>
    );
  }
}

const Tab = AbstractTab(withTranslation()(Devices));

export { Tab, filters, prefix };
