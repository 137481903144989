export const MAINTENANCE_ALERT_SET_DATA = 'MAINTENANCE_ALERT_SET_DATA'

export interface MaintenanceAlertState {
    content: string,
    visible: boolean
}


interface setMaintenanceAlertData {
    type: typeof MAINTENANCE_ALERT_SET_DATA;
    content: string;
    visible: boolean;
}

export type maintenanceAlertActionTypes = setMaintenanceAlertData;
