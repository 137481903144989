import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Layout } from '@ui';

import Roles from './roles';
import { TAB_FUNCTIONS, TAB_ROLES } from './constants';

class RolesRoute extends PureComponent {
  render() {
    const { t, match } = this.props;

    return (
      <Layout
        title={t('ROLES_TITLE')}
        tabs={[
          { id: TAB_FUNCTIONS, url: 'functions', name: t('FUNCTIONS_TITLE') },
          { id: TAB_ROLES, url: 'roles', name: t('ROLES_TITLE') },
        ]}
        activeTabUrl={(match.params && match.params.tab) || undefined}
        defaultTabUrl='functions'
      >
        <Roles />
      </Layout>
    );
  }
}

export default withTranslation()(
  withRouter(
    RolesRoute
  )
);
