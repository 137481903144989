import {
  SETUP_GLOBAL_SEARCH_STRING,
  SETUP_GLOBAL_SEARCH_RELOAD,
  SETUP_GLOBAL_SEARCH_FILTERS,
  SETUP_GLOBAL_SEARCH_FILTERS_LOADING,
  APPLY_GLOBAL_SEARCH_PARAMS,
  SET_CHECKED_ITEMS,
  REMOVE_CHECKED_ITEMS,
  CLEAR_CHECKED_ITEMS,
} from './types';

const initialState = {
  searchValue: '',
  searchReload: false,
  searchFilter: '',
  searchFilterLoading: true,
  applySearch: false,
  checkedItems: {},
};

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SETUP_GLOBAL_SEARCH_STRING:
      return {
        ...state,
        searchValue: action.value,
      };
    case SETUP_GLOBAL_SEARCH_RELOAD:
      return {
        ...state,
        searchReload: action.value,
      };
    case SETUP_GLOBAL_SEARCH_FILTERS:
      return {
        ...state,
        searchFilter: action.value,
      };
    case SETUP_GLOBAL_SEARCH_FILTERS_LOADING:
      return {
        ...state,
        searchFilterLoading: action.value,
      };
    case APPLY_GLOBAL_SEARCH_PARAMS:
      return {
        ...state,
        applySearch: action.value,
      };
    case SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: {
          ...state.checkedItems,
          [action.itemsSectionName]: {
            ...state.checkedItems[action.itemsSectionName],
            [action.checkedItem.id]: action.checkedItem,
          },
        },
      };
    case REMOVE_CHECKED_ITEMS: {
      const newCheckedItems = { ...state.checkedItems[action.itemsSectionName] };

      delete newCheckedItems[action.id];

      return {
        ...state,
        checkedItems: {
          ...state.checkedItems,
          [action.itemsSectionName]: newCheckedItems,
        },
      };
    }
    case CLEAR_CHECKED_ITEMS: {
      return {
        ...state,
        checkedItems: {},
      };
    }
    default:
      return state;
  }
}

export default searchReducer;
