import React from 'react';
import moment from 'moment';
import { message, Badge } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import Qs from 'qs';

import api from '@services/api';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';
import { DOCUMENTS_UPLOAD } from '@common/sidebarRoot/types';
import { DOC_SIDEBAR_DOC } from '@common/documentsUploadSidebar/constants';
import DocsTable from '@routes/passport/docsTable';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';
import { PopoverEllipsis, PopoverTags, CustomTag } from '@ui';
import { MODE_EDIT, RIGHT_PASSPORT_DOCS } from '@globalConstants';
import { withRouter } from 'react-router-dom';
import { setUnBlockSidebar } from '@app/helpers';

class Documents extends React.Component {
  tableName = 'passport_tab_documents';

  state = {
    documents: [],
    isDocsLoading: false,
    isWaitingPassportData: false,
    count: 0,
  };

  componentDidMount() {
    const { setTableName, passportId, history } = this.props;
    setTableName(this.tableName, () => (passportId && this.checkPassportData()));

    this.unblock = setUnBlockSidebar(history, 'CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL_PASSPORT_CHANGE_LOCATION');
  }

  componentDidUpdate(prevProps) {
    const { passportId } = this.props;
    const { isWaitingPassportData } = this.state;

    if (passportId && passportId !== prevProps.passportId) {
      this.checkPassportData();
    }

    if (isWaitingPassportData) {
      this.checkPassportData();
    }
  }

  componentWillUnmount() {
    this.unblock && this.unblock();
  }

  checkPassportData = () => {
    const { passportId, protectionDeviceData } = this.props;
    const { isWaitingPassportData } = this.state;

    if (passportId && Object.keys(protectionDeviceData).length > 0) {
      this.setState({
        isWaitingPassportData: false,
      }, () => this.loadData());
    } else if (!isWaitingPassportData) {
      this.setState({
        isWaitingPassportData: true,
      });
    }
  }

  getMetatags = () => {
    const { protectionDeviceData } = this.props;

    const NULL_UUID = '00000000-0000-0000-0000-000000000000';
    const metatags = {
      producer: get(protectionDeviceData, 'producer.id', NULL_UUID),
      func: (
        (
          protectionDeviceData
          && protectionDeviceData.functions
          && protectionDeviceData.functions.length
          && protectionDeviceData.functions.map((func) => func.id)
        )
        || NULL_UUID
      ),
      device_version: get(protectionDeviceData, 'device_version.id', NULL_UUID),
      trade_device: get(protectionDeviceData, 'trade_device.id', NULL_UUID),
      implementation_type: get(protectionDeviceData, 'implementation_type.id', NULL_UUID),
    };

    return { ...metatags };
  };

  loadData = async (addParams = {}, callback) => {
    const {
      passportId,
      filterValues,
      loadByPage,
    } = this.props;

    Object.keys(filterValues).forEach((key) => {
      if (!filterValues[key]) delete filterValues[key];
    });

    const requestParams = {
      passport: passportId,
      ...filterValues,
      ...addParams,
      ...this.getMetatags(),
    };

    this.setState({ isDocsLoading: true });
    await loadByPage(this.tableName, 'getPassportDocs', requestParams, 200, (status, data) => {
      this.setState({
        documents: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isDocsLoading: false });
  };

  loadWSParams = () => {
    const { t, passportId, filterValues } = this.props;

    Object.keys(filterValues).forEach((key) => {
      if (!filterValues[key]) delete filterValues[key];
    });

    return {
      title: t('PASSPORT_DOCS_TITLE_EXPORT'),
      url: '/v1/passport-documents/',
      params: {
        passport: passportId,
        ...filterValues,
        ...this.getMetatags(),
      },
      accept: 'application/xlsx',
    };
  };

  deleteFile = async (documents) => {
    const { passportId, t, onSelectItems } = this.props;

    if (documents && documents.length) {
      const params = {
        passports: [passportId],
        is_archived: true
      };
      const queryParams = Qs.stringify(params, { arrayFormat: 'repeat' });

      const result = {
        success: 0,
        error: 0,
        errorText: [],
      };
      for (const document of documents) {
        const { status, data } = await api.deletePassportDocument(document.id, queryParams);
        if (status === 204) {
          result.success += 1;
        } else {
          result.error += 1;
          data && data.detail
            ? result.errorText.push(data.detail)
            : data && data.error && result.errorText.push(data.error);
        }
      }

      result.error
        ? (
          result.error === documents.length
            ? message.error(
              result.errorText && result.errorText.length === 1
                ? <span>{result.errorText[0]}<br /></span>
                : (
                  result.errorText && result.errorText.length
                    ? (
                      <div style={{ textAlign: 'left' }}>
                        {t('PASSPORT_DOCUMENTS_DELETING_RESULT')}:<br />
                        {result.errorText.map((x) => <span>&nbsp;&nbsp;&mdash;&nbsp;{x}<br /></span>)}
                      </div>
                    )
                    : t('PASSPORT_DOCUMENTS_DELETING_ERROR')
                )
            )
            : message.warn(
              <div style={{ textAlign: 'left' }}>
                {t('PASSPORT_DOCUMENTS_DELETING_RESULT')}:<br />
                {
                  result.success
                    ? (<span>&nbsp;&nbsp;&mdash;&nbsp;{t('RESULT_DELETE_DOCS_SUCCESS')}: {result.success}<br /></span>)
                    : ''
                }
                {
                  result.error
                    ? (<span>&nbsp;&nbsp;&mdash;&nbsp;{t('RESULT_DELETE_DOCS_ERROR')}: {result.error}<br /></span>)
                    : ''
                }
                {
                  result.errorText.length
                    ? result.errorText.map((x) => <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&mdash;&nbsp;{x}<br /></span>)
                    : ''
                }
              </div>
            )
        )
        : (
          message.success(t('PASSPORT_DOCUMENTS_DELETING_SUCCESS'))
        );
      await this.loadData();
    } else {
      message.error(t('PASSPORT_DOCUMENTS_DELETING_ERROR'));
    }

    onSelectItems([]);
  };

  openDocumentsSidebar = async (isEdit = false) => {
    const {
      t, passportId, sidebarType, sidebarIsOpen, openUploadDocuments, closeUploadDocuments,
      selectedItems, protectionDeviceData, changePage, onSelectItems,
    } = this.props;

    if (sidebarIsOpen && sidebarType === DOCUMENTS_UPLOAD) {
      closeUploadDocuments();
    } else {
      openUploadDocuments(
        DOCUMENTS_UPLOAD,
        {
          type: DOC_SIDEBAR_DOC,
          header: isEdit ? t('PASSPORT_DOCUMENTS_EDIT') : t('PASSPORT_DOCUMENTS_ADD'),
          isEdit,
          showSystemType: true,
          document: isEdit && selectedItems.length && selectedItems[0],
          protectionDeviceData,
          // TODO: Заменить на список паспортов из сущности документ
          passports: [passportId],
          showPassportsList: true,
          callback: async (document) => {
            changePage(1, async () => {
              await this.loadData({ selected: document.id }, () => {
                const { documents: updatedDocuments } = this.state;
                onSelectItems([updatedDocuments[0]]);
              });
            });
          },
        },
        700,
        true
      );
    }
  };

  handleResetCallback = () => {
    const { onSelectItems } = this.props;

    onSelectItems([]);
  };

  checkDocument = (user, document) => {
    return (
      document.length > 0 &&
      Object.keys(document[0]?.meta_tags).length !== 0 &&
      user.dispatcher_center.id === document[0]?.dispatcher_center.id
    );
  };

  updateRightPassportDoc = (generalRight, passportRight) => {
    if (!generalRight || !passportRight) return;

    if (generalRight === passportRight || generalRight === 'edit') {
      return passportRight;
    } else {
      return generalRight;
    }
  };

  render() {
    const {
      t, passportId, rights,
      changePage, onShowSizeChange, page, limit,
      getColumnSearchFilter, getColumnSearchFilterList,
      filterValues, addExtraFilters,
      onSelectItems, selectedItems,
      isArchived, userInfo, rightsGeneral, rightDocs
    } = this.props;
    const {
      documents, isDocsLoading, count,
    } = this.state;

    // Определяем наихудший вариант из двух, в дальнейшем для проверки прав редактирования документа без метатегов
    const rightPassportDoc = this.updateRightPassportDoc(
      rightsGeneral[RIGHT_PASSPORT_DOCS],
      rightDocs[RIGHT_PASSPORT_DOCS]
    );

    // Оставил, чтобы не поломалась логика, так как используется в других местах
    const isEditRight = rights[RIGHT_PASSPORT_DOCS] === MODE_EDIT && !isArchived;
    // Проверяем, есть ли права для редактирования документа без метатегов
    const isEditRightNoMetaTags = rightPassportDoc === MODE_EDIT && !isArchived;
    // Проверяем, есть ли права для редактирования документа с метатегами
    const isEditRightMetaTags =
      rightsGeneral[RIGHT_PASSPORT_DOCS] === MODE_EDIT && !isArchived;
    // Проверяем наличие метатегов у документа
    const isDocumentMetaTags =
      selectedItems.length > 0 &&
      selectedItems[0]?.meta_tags &&
      Object.keys(selectedItems[0]?.meta_tags).length !== 0;

    // Общая логика disabled кнопки редактировать
    const isEditRightEditButton = isDocumentMetaTags
      ? isEditRightMetaTags && this.checkDocument(userInfo, selectedItems)
      : isEditRightNoMetaTags;

    const columnsDocuments = [
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_DOC'),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
      },
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_TYPE'),
        dataIndex: 'document_type',
        key: 'document_type',
        width: '15%',
        render: (item) => item && item.name,
      },
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_STATUS'),
        dataIndex: 'document_status',
        key: 'document_status',
        width: '15%',
        render: (item) => item && (
          <PopoverEllipsis content={item && item.name}>
            <Badge status='success' text={item && item.name} />
          </PopoverEllipsis>
        ),
      },
      {
        title: t('CLASSIFICATORS_DOCUMENTS_TAGS'),
        dataIndex: 'tags',
        key: 'tags',
        width: '15%',
        render: (item) => item && (
          <PopoverTags size={item.length}>
            {item.map((tag) => (
              <CustomTag key={tag.id} disablePopover={item.length > 1}>{tag.name}</CustomTag>
            ))}
          </PopoverTags>
        ),
      },
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_ADDED'),
        dataIndex: 'startup_date',
        key: 'startup_date',
        width: '15%',
        render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: t('PASSPORT_DOCUMENTS_TABLE_COL_OWNER_DC'),
        dataIndex: 'dispatcher_center',
        key: 'dispatcher_center',
        width: '15%',
        render: (data) => (data ? data.name : ''),
      },
    ];

    return (
      <DocsTable
        isDefaultOpenPanel={true}
        tableName={this.tableName}
        loadData={this.loadData}
        loadWSParams={this.loadWSParams}
        count={count}
        changePage={changePage}
        onShowSizeChange={onShowSizeChange}
        page={page}
        limit={limit}
        columnsDocuments={columnsDocuments}
        dataSource={documents}
        addFilters={addExtraFilters}
        filterValues={filterValues}
        getColumnSearchFilterList={getColumnSearchFilterList}
        onSelectItems={onSelectItems}
        selectedItems={selectedItems}
        selectedDoc={(selectedItems.length === 1) && selectedItems[0]}
        openDocumentsSidebar={(isEdit) => this.openDocumentsSidebar(isEdit)}
        deleteFile={this.deleteFile}
        loading={isDocsLoading}
        isEditRight={isEditRight}
        isEditRightEditButton={isEditRightEditButton}
        passportId={passportId}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rightsGeneral: state.rights.rightsDataGeneral,
  rights: state.rights.rightsData,
  rightDocs: state.rights.rightPassportDocs,
  sidebarType: state.sidebar.componentType,
  sidebarIsOpen: state.sidebar.isOpen,
  userInfo: state.user.info
});

const mapDispatchToProps = {
  openUploadDocuments: openSidebar,
  closeUploadDocuments: closeSidebar,
};

const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      withRouter(
        Documents
      )
    )
  )
);
