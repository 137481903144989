export const READY_TO_FORMED = 'ready_to_formed';
export const IS_FORMED = 'is_formed';
export const IS_APPROVED = 'is_approved';
export const IS_ISSUED = 'is_issued';
export const IS_ISSUED_ARCHIVE = 'is_issued_archive';

export const STATUS_NEW = 'new';
export const STATUS_CALCULATING = 'calculating';
export const STATUS_COMPLETED = 'completed';
export const STATUS_PLANNED = 'planned';

export const TASK_MODE_CREATE = 'create';
export const TASK_MODE_EDIT = 'edit';
export const TASK_MODE_AGREEMENT = 'agreement';
export const LOADING_MESSAGE_KEY = 'task_status_updating';
