import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { Spin, Divider, Button, Table } from 'antd';
import { PopoverEllipsis } from '@ui';
import groupBy from 'lodash/groupBy';

import { openSidebar } from '@state/sidebar/actions';
import { TASK_DC_INFO } from '@common/sidebarRoot/types';
import { DATE_FORMAT } from '@globalConstants';
import { RootState } from '@state/store';

import DateLine from './Line';
import SpotsOutsidePeriod from './SpotsOutside';
import type { Data, Item } from '../types';

import styles from '../styles.module.less';

import {
  DATE_ITEM_WIDTH,
  DATE_MONTH_FORMAT,
  DATE_DAY_FORMAT,
  SPOT_SIZE,
  PLANNED_STATUS,
  ACTUAL_STATUS,
  MIXED_STATUS,
  START_OFFSET,
  BEGIN_PERIOD_WIDTH,
  END_PERIOD_WIDTH,
  CALENDAR_NAME_COLUMN_LOCAL_STORAGE_ATTR_NAME,
  CALENDAR_NAME_COLUMN_WIDTH_DEFAULT,
} from './constants';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { ResizeCallbackData } from 'react-resizable';
import { ResizableTitle } from './resizableTitle';


interface Props {
  dateTo: Moment;
  dateFrom: Moment;
  isLoading?: boolean;
  data: Data[];
  aStatuses: Item[];
  openTaskInfo: Function;
  showFullNames?: boolean;
}

class Calendar extends PureComponent<Props> {
  state = {
    nameColumnWidth: 0,
  };

  componentDidMount = () => {
    const width = this.loadNameWidthFromLocalStorage();
    this.setState({ nameColumnWidth: width });
  }

  openTaskDCInfo = (selectedTask: Data) => {
    const { openTaskInfo } = this.props;

    openTaskInfo(TASK_DC_INFO, {
      selectedTask,
    });
  };

  handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      this.setState({ nameColumnWidth: size.width });
  };

  handleStopResize = () => {
    this.saveNameWidthToLocalStorage();
  }

  mergeColumns = (columns: ColumnsType<Data>) => columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: (column as ColumnType<Data>).width,
      onResize: this.handleResize(index),
      onResizeStop: this.handleStopResize()
    }),
  }));

  saveNameWidthToLocalStorage = () => {
    if (this.state.nameColumnWidth !== 0) localStorage.setItem(CALENDAR_NAME_COLUMN_LOCAL_STORAGE_ATTR_NAME, this.state.nameColumnWidth.toString());
  }

  loadNameWidthFromLocalStorage = () => {
    const value = localStorage.getItem(CALENDAR_NAME_COLUMN_LOCAL_STORAGE_ATTR_NAME) || CALENDAR_NAME_COLUMN_WIDTH_DEFAULT;
    return Number(value);
  }


  render() {
    const { dateTo, dateFrom, isLoading, data, aStatuses } = this.props;
    const showFullNames = this.props.showFullNames || false;

    const { nameColumnWidth } = this.state;

    if (data && data.length) {
      data.forEach((item: Data) => (item.key = item.status));
    }
    const groupedData = groupBy(data, 'status');
    const groupedStatuses = groupBy(aStatuses, 'id');
    const orderedDataByStatus: Record<string, Data[]> = {};

    aStatuses.forEach((status: Item) => {
      if (groupedData[status.id]) {
        orderedDataByStatus[status.id] = groupedData[status.id];
      }
    });

    const existsStatuses = Object.keys(orderedDataByStatus);
    const statusSourceForTable = existsStatuses.map((status) => ({
      id: status,
      name: status,
      key: status,
      children: orderedDataByStatus[status],
    }));
    const today = moment().startOf('day');
    const todayLine =
      today.isSameOrBefore(dateTo, 'day') && today.isSameOrAfter(dateFrom, 'day') ? (
        <div
          className={styles.todayLine}
          style={{
            left: (today.diff(dateFrom, 'days') + 1) * DATE_ITEM_WIDTH + SPOT_SIZE + START_OFFSET,
          }}
        />
      ) : null;

    const changeYearInPeriod = dateFrom.year() !== dateTo.year();
    const xLength =
      dateTo.diff(dateFrom, 'days') * DATE_ITEM_WIDTH +
      nameColumnWidth +
      16 +
      BEGIN_PERIOD_WIDTH +
      END_PERIOD_WIDTH +
      30 +
      17;

    const columnNameButtonMargin = -37;
    const columnNameButtonWidth = `calc(${nameColumnWidth}px - 16px - 15px - 17px - 15px + ${-columnNameButtonMargin}px)`;
    const columns = [
      {
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: nameColumnWidth,
        className: styles.namesColumn,
        render: (name: string, record: Data) => {
          if (record.children) {
            const text = groupedStatuses[name] ? groupedStatuses[name][0].name : name;

            return (
              <PopoverEllipsis key={record.key} content={text}>
                {text}
              </PopoverEllipsis>
            );
          }

          if (showFullNames) {
            return (
              <Button
                type='link'
                size='small'
                key={`button_${record.key}`}
                tabIndex={0}
                style={{
                  width: columnNameButtonWidth,
                  textAlign: 'left',
                  marginLeft: columnNameButtonMargin,
                  whiteSpace: 'normal',
                  height: 'auto',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  this.openTaskDCInfo(record);
                }}
              >
                {name}
              </Button>
            );
          }

          return (
            <Button
              type='link'
              size='small'
              key={`button_${record.key}`}
              tabIndex={0}
              style={{
                width: columnNameButtonWidth,
                textAlign: 'left',
                marginLeft: columnNameButtonMargin
              }}
              onClick={() => {
                this.openTaskDCInfo(record);
              }}
            >
              <PopoverEllipsis content={name}>{name}</PopoverEllipsis>
            </Button>
          );
        },
      },
      {
        title: () => {
          const currDate = dateFrom.clone();
          const dates = [];
          const dateToStr = dateTo.clone().toString();
          const dateFromStr = dateFrom.clone().toString();
          const dateFromDay = dateFrom.clone().date();
          const dateFromMonth = dateFrom.clone().month();
          const dateToDay = dateTo.clone().date();
          const dateToMonth = dateTo.clone().month();
          const dateFromMonthEndDay = dateFrom.clone().daysInMonth();
          const enableBeginDivider = dateFromDay < dateFromMonthEndDay;

          while (currDate.diff(dateTo) <= 0) {
            const currDateStr = currDate.toString();
            const day = currDate.format(DATE_DAY_FORMAT);
            const month = currDate.month();
            const endOfMonth = day === currDate.daysInMonth().toString();
            const endOfPeriod = currDateStr === dateToStr;
            const beginOfPeriod = currDateStr === dateFromStr;

            dates.push(
              <span
                style={{
                  width: DATE_ITEM_WIDTH,
                  maxWidth: DATE_ITEM_WIDTH,
                  minWidth: DATE_ITEM_WIDTH,
                }}
                key={`dateline_${currDate.format(DATE_FORMAT)}`}
              >
                <span>
                  {changeYearInPeriod ? (
                    (day === '01' && month === 0) ||
                    (beginOfPeriod && dateFromDay <= dateFromMonthEndDay - 3) ||
                    (endOfPeriod && day === '01' && month !== 0) ||
                    (month !== 0 && dateToMonth === month && dateToDay <= 3 && day === '01') ? (
                      <div className={styles.left}>{currDate.year()}</div>
                    ) : changeYearInPeriod && day === '31' && month === 11 ? (
                      <div className={styles.right}>
                        {currDate.year()}
                        <Divider type='vertical' />
                      </div>
                    ) : (endOfMonth && month === dateFromMonth) || (endOfPeriod && dateToDay > 3) ? (
                      <div className={styles.right}>
                        {currDate.year()}
                        <Divider
                          type='vertical'
                          className={endOfMonth ? styles.right_divider_year : styles.end_period_divider}
                        />
                      </div>
                    ) : endOfMonth || endOfPeriod ? (
                      <div className={styles.right}>
                        &emsp;
                        <Divider
                          type='vertical'
                          className={endOfMonth ? styles.right_divider_year : styles.end_period_divider}
                        />
                      </div>
                    ) : (
                      <div>&nbsp;</div>
                    )
                  ) : (
                    ''
                  )}
                  {day === '01' || (beginOfPeriod && dateFromDay <= dateFromMonthEndDay - 3) ? (
                    <div className={styles.day}>{currDate.format(DATE_MONTH_FORMAT)}</div>
                  ) : endOfMonth ? (
                    <div className={styles.right}>
                      {currDate.format(DATE_MONTH_FORMAT)}
                      <Divider type='vertical' />
                    </div>
                  ) : endOfPeriod && dateToDay > 3 ? (
                    <div className={styles.right}>
                      {currDate.format(DATE_MONTH_FORMAT)}
                      <Divider type='vertical' className={endOfPeriod ? styles.end_period_divider : ''} />
                    </div>
                  ) : endOfPeriod ? (
                    <div className={styles.right}>
                      &emsp;
                      <Divider type='vertical' className={styles.end_period_divider} />
                    </div>
                  ) : (
                    <div className={styles.day}>&nbsp;</div>
                  )}
                  <div className={styles.day}>
                    {day}
                    <Divider type='vertical' className={endOfMonth ? styles.right_divider : undefined} />
                  </div>
                </span>
              </span>
            );

            currDate.add(1, 'days');
          }
          return (
            <span className={styles.dates}>
              <span
                style={{
                  width: `${BEGIN_PERIOD_WIDTH}px`,
                  minWidth: `${BEGIN_PERIOD_WIDTH}px`,
                  maxWidth: `${BEGIN_PERIOD_WIDTH}px`,
                }}
              >
                <span className={styles.right}>
                  {changeYearInPeriod ? (
                    <div>
                      &emsp;
                      {enableBeginDivider ? (
                        <Divider type='vertical' className={styles.begin_period_divider} />
                      ) : (
                        <>&nbsp;</>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    &emsp;
                    {enableBeginDivider ? (
                      <Divider type='vertical' className={styles.begin_period_divider} />
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>
                  <span>
                    <span className={styles.begin_period_dotted}>&hellip;</span>
                    <Divider type='vertical' />
                  </span>
                </span>
              </span>
              {dates}
              <span
                style={{
                  width: `${END_PERIOD_WIDTH}px`,
                  minWidth: `${END_PERIOD_WIDTH}px`,
                  maxWidth: `${END_PERIOD_WIDTH}px`,
                }}
              >
                <span>
                  {changeYearInPeriod ? <div>&nbsp;</div> : ''}
                  <div>&nbsp;</div>
                  <span className={styles.end_period_dotted}>&hellip;</span>
                </span>
              </span>
            </span>
          );
        },
        dataIndex: 'date_planned_start',
        key: 'date_planned_start',
        render: (date: string, record: Data) => {
          if (record.children) {
            return null;
          }

          const existsSpotPlaces = {};

          return (
            <div className={styles.lineContainer}>
              {todayLine}
              {date && (
                <DateLine
                  name={record.name}
                  type={PLANNED_STATUS}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  dateStart={date}
                  dateEnd={record.date_planned_completion || ''}
                  existsSpotPlaces={existsSpotPlaces}
                  isEqStartDate={date === record.date_actual_start}
                  isEqEndDate={(record.date_planned_completion || '') === record.date_actual_completion}
                />
              )}
              {record.date_actual_start && record.date_actual_completion && (
                <DateLine
                  name={record.name}
                  type={ACTUAL_STATUS}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  dateStart={record.date_actual_start}
                  dateEnd={record.date_actual_completion}
                  existsSpotPlaces={existsSpotPlaces}
                />
              )}
              {record.date_actual_start && record.date_planned_completion && !record.date_actual_completion && (
                <DateLine
                  name={record.name}
                  type={MIXED_STATUS}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  dateStart={record.date_actual_start}
                  dateEnd={record.date_planned_completion}
                  existsSpotPlaces={existsSpotPlaces}
                  isEndNeeded={!date}
                />
              )}
              <SpotsOutsidePeriod dateFrom={dateFrom} dateTo={dateTo} record={record} offsetX={START_OFFSET} />
            </div>
          );
        },
      },
    ];

    //@ts-ignore
    const mergedColumns = this.mergeColumns(columns);

    return (
      <Spin spinning={isLoading}>
        <Table
          bordered
          className={styles.table}
          tableLayout='auto'
          rowKey='id'
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          columns={mergedColumns as ColumnsType<Data>}
          dataSource={statusSourceForTable}
          rowClassName={(record: Data) => {
            if (!record.date_planned_completion) {
              return undefined;
            }

            const tenDaysAfter = moment().add(10, 'day');

            const momentPlanned = moment(record.date_planned_completion);
            if (
              tenDaysAfter.isAfter(momentPlanned) &&
              (!record.date_actual_completion || moment(record.date_planned_completion).isAfter(momentPlanned))
            ) {
              return styles.danger;
            }

            return undefined;
          }}
          pagination={false}
          scroll={{
            x: xLength,
            y: `calc(100vh - 320px - 24px - 24px ${changeYearInPeriod ? '- 24px' : ''} )`,
          }}
        />
      </Spin>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isSidebarOpen: state.sidebar.isOpen,
});

const mapDispatchToProps = {
  openTaskInfo: openSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  Calendar
);
