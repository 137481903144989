import {
  CLEAR_NOTIFICATIONS, DELETE_NOTIFICATION,
  GET_NEW_NOTIFICATIONS_COUNTER,
  GET_NOTIFICATIONS,
  LOAD_MORE_NOTIFICATIONS,
} from './types';

const initialState = {
  count_new: 0,
  notificationsData: [],
  count: 0,
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEW_NOTIFICATIONS_COUNTER:
      return {
        ...state,
        count_new: action.count_new,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationsData: action.notificationsData,
        count: action.countAll,
      };
    case LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        notificationsData: [...state.notificationsData, ...action.notificationsData],
        count: action.countAll,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationsData: [],
        count: 0,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationsData: state.notificationsData.filter((item) => (item.id !== action.id)),
        count: state.count - 1,
      };
    default:
      return state;
  }
}

export default notificationsReducer;
