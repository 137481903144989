import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import {
  Button, Popconfirm, message, Breadcrumb, Tooltip,
} from 'antd';
import {
  PlusCircleOutlined, EditOutlined, DeleteOutlined, CloseOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import api from '@services/api';
import { COMMON_CLASSIFICATORS_FORM } from '@common/sidebarRoot/types';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { CustomCard } from '@ui';
import {
  TABLE_SIZE, RIGHT_ROUTE_CLASSIFIERS, MODE_EDIT,
} from '@globalConstants';
import { textHighlighter } from '@globalHelpers';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import {
  OWNERS,
  PRODUCERS,
  IMPLEMENTATION_TYPES,
  TRADE_DEVICES,
  TERMINAL_TYPES,
  SOFTWARE_VERSION,
} from '../constants';

import styles from './styles.module.less';

const mapClassificatorToRequestURL = {
  [OWNERS]: '/v1/owners/',
  [PRODUCERS]: '/v1/producers/',
  [IMPLEMENTATION_TYPES]: '/v1/implementation-types/',
  [TRADE_DEVICES]: '/v1/trade-devices/',
  [TERMINAL_TYPES]: '/v1/terminal-types/',
  [SOFTWARE_VERSION]: '/v1/versions/',
};
class DeviceClassificators extends Component {
  tableNameTemplate = 'classificators_tab__devices_';

  state = {
    currentClassificator: OWNERS,
    tableName: `${this.tableNameTemplate}_${OWNERS}`,
    classificatorsData: [],
    isLoading: false,
    isDeleting: false,
    selectedProducers: null,
    selectedImplementationTypes: null,
    selectedTradeDevice: null,
  };

  componentDidMount() {
    const { setTableName } = this.props;
    const { tableName } = this.state;
    setTableName(tableName, () => this.loadData());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedItem, isSidebarOpen, closeFormSidebar, archived, changePage,
    } = this.props;
    const { currentClassificator, sidebarTypeMode } = this.state;

    if (
      prevProps.selectedItem !== selectedItem
      && isSidebarOpen
      && prevState.currentClassificator === currentClassificator
    ) {
      this.openForm(sidebarTypeMode);
    }

    if (prevState.currentClassificator !== currentClassificator && isSidebarOpen) {
      closeFormSidebar();
    }

    if (prevProps.archived !== archived) {
      changePage(1, this.loadData);
    }
  }

  componentWillUnmount() {
    const { isSidebarOpen, closeFormSidebar } = this.props;

    if (isSidebarOpen) closeFormSidebar();
  }

  loadData = async (addParams = {}, callback) => {
    const { filterValues, loadByPage } = this.props;
    const {
      currentClassificator, selectedProducers, selectedImplementationTypes, selectedTradeDevice, tableName,
    } = this.state;
    const selectedParams = {};

    if (currentClassificator === SOFTWARE_VERSION) {
      if (!selectedTradeDevice) {
        this.setState({
          classificatorsData: [],
          count: 0,
        });

        return;
      }

      selectedParams.trade_device = selectedTradeDevice.id;
    }

    if (currentClassificator === TRADE_DEVICES) {
      selectedParams.implementation_type = selectedImplementationTypes && selectedImplementationTypes.id;
      selectedParams.producer = selectedProducers && selectedProducers.id;
    }

    const requestParams = { ...filterValues, ...addParams, ...selectedParams };

    this.setState({ isLoading: true });

    await loadByPage(tableName, `get${currentClassificator}`, requestParams, 200, (status, data) => {
      this.setState({
        classificatorsData: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isLoading: false });
  };

  handleSelect = (selectedRow) => {
    const { onSelectItem } = this.props;
    const { currentClassificator } = this.state;

    switch (currentClassificator) {
      case PRODUCERS: {
        this.setState({ selectedProducers: selectedRow, selectedTradeDevice: null });
        break;
      }
      case IMPLEMENTATION_TYPES: {
        this.setState({ selectedImplementationTypes: selectedRow, selectedTradeDevice: null });
        break;
      }
      case TRADE_DEVICES: {
        this.setState({ selectedTradeDevice: selectedRow });
        break;
      }
      default:
        break;
    }

    onSelectItem(selectedRow);
  };

  clearSelectedItems = () => {
    const { onSelectItem } = this.props;

    this.setState({
      selectedProducers: null,
      selectedImplementationTypes: null,
      selectedTradeDevice: null,
    }, () => this.loadData());

    onSelectItem({});
  };

  openForm = async (isEditMode = false) => {
    const {
      isSidebarOpen, t, openFormSidebar, closeFormSidebar, selectedItem, changePage, onSelectItem,
    } = this.props;
    const {
      classificatorsData, currentClassificator, selectedTradeDevice, selectedProducers, selectedImplementationTypes,
    } = this.state;
    let item;

    if (isSidebarOpen) {
      await closeFormSidebar();
      this.setState({ sidebarTypeMode: '' });
    }

    let method = `add${currentClassificator}`;

    if (isEditMode) {
      item = classificatorsData.find((x) => x.id === selectedItem.id);
      method = `update${currentClassificator}`;
    }

    openFormSidebar(
      COMMON_CLASSIFICATORS_FORM,
      {
        item,
        currentClassificator,
        isWithShortName: currentClassificator === IMPLEMENTATION_TYPES,
        method,
        selectedTradeDevice,
        selectedProducers,
        selectedImplementationTypes,
        label: t(currentClassificator.toUpperCase()),
        callback: async (data) => {
          this.setState({
            selectedProducers: null,
            selectedImplementationTypes: null,
          }, async () => {
            changePage(1, async () => {
              await this.loadData({ selected: data.id }, async () => {
                const { classificatorsData: updatedClassificatorsData } = this.state;
                await onSelectItem(updatedClassificatorsData[0]);
              });
            });
          });
          closeFormSidebar();
        },
      }
    );

    this.setState({ sidebarTypeMode: isEditMode });
  };

  deleteItem = async () => {
    const { t, selectedItem } = this.props;
    const { currentClassificator } = this.state;

    this.setState({ isDeleting: true });
    const { status } = await api[`delete${currentClassificator}`](selectedItem.id);
    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));

      if (currentClassificator === SOFTWARE_VERSION) {
        await this.loadData();
      } else {
        this.clearSelectedItems();
      }
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;
    const {
      currentClassificator,
      selectedProducers,
      selectedImplementationTypes,
      selectedTradeDevice,
    } = this.state;

    switch (currentClassificator) {
      case PRODUCERS: {
        onSelectItem(selectedProducers);
        break;
      }
      case IMPLEMENTATION_TYPES: {
        onSelectItem(selectedImplementationTypes);
        break;
      }
      case TRADE_DEVICES: {
        onSelectItem(selectedTradeDevice);
        break;
      }
      default:
        onSelectItem({});
        break;
    }
  };

  prepareColumns = () => {
    const { t, getColumnSearchFilter, filterValues } = this.props;
    const { currentClassificator } = this.state;

    const columns = [
      {
        title: t('NAME'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
        render: (text) => (text ? textHighlighter(filterValues.name, text) : ''),
      },
    ];

    switch (currentClassificator) {
      case IMPLEMENTATION_TYPES:
        columns.push(
          {
            title: t('SHORT_NAME'),
            dataIndex: 'short_name',
            key: 'short_name',
          }
        );
        break;
      case SOFTWARE_VERSION:
        columns.push(
          {
            title: t('SOFTWARE_VERSION_DATE'),
            dataIndex: 'date',
            key: 'date',
            render: (text) => ((text && moment(text).format('DD.MM.YYYY')) || ''),
          }
        );
        break;
      case TERMINAL_TYPES:
        columns.push(
          {
            title: t('CREATED'),
            dataIndex: 'created_by',
            key: 'created_by',
          },
          {
            title: t('CHANGED'),
            dataIndex: 'last_changed_by',
            key: 'last_changed_by',
          }
        );
        break;
      default:
        break;
    }

    return columns.map((column) => ({
      ...column,
      width: `${100 / columns.length}%`,
    }));
  };

  onTabChange = (currentClassificator) => {
    const {
      onSelectItem, changePage, setTableName, resetFilters,
    } = this.props;
    const { selectedProducers, selectedImplementationTypes, selectedTradeDevice } = this.state;

    switch (currentClassificator) {
      case PRODUCERS: {
        onSelectItem(selectedProducers);
        break;
      }
      case IMPLEMENTATION_TYPES: {
        onSelectItem(selectedImplementationTypes);
        break;
      }
      case TRADE_DEVICES: {
        onSelectItem(selectedTradeDevice);
        break;
      }
      default:
        onSelectItem({});
        break;
    }

    resetFilters(
      changePage(1, () => {
        this.setState(
          {
            currentClassificator,
            tableName: `${this.tableNameTemplate}_${currentClassificator}`,
          },
          () => setTableName(`${this.tableNameTemplate}_${currentClassificator}`, () => this.loadData())
        );
      })
    );
  };

  render() {
    const {
      t, changePage, onShowSizeChange, limit, page, selectedItem, rights, filterValues,
    } = this.props;
    const {
      isLoading, isDeleting, classificatorsData, count, currentClassificator,
      selectedProducers, selectedImplementationTypes, selectedTradeDevice, tableName,
    } = this.state;

    const isEditRight = rights[RIGHT_ROUTE_CLASSIFIERS] === MODE_EDIT;
    const isDisableAddDelAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP !== undefined
      && ([OWNERS, PRODUCERS, TRADE_DEVICES].indexOf(currentClassificator) !== -1);
    const isDisableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP !== undefined
      && ([OWNERS, PRODUCERS].indexOf(currentClassificator) !== -1);

    return (
      <>
        <Breadcrumb>
          {selectedProducers && <Breadcrumb.Item>{selectedProducers.name}</Breadcrumb.Item>}
          {selectedImplementationTypes && <Breadcrumb.Item>{selectedImplementationTypes.name}</Breadcrumb.Item>}
          {selectedTradeDevice && <Breadcrumb.Item>{selectedTradeDevice.name}</Breadcrumb.Item>}
          {(selectedProducers || selectedImplementationTypes || selectedTradeDevice)
          && (
            <Tooltip title={t('REMOVE_SELECTION')}>
              <CloseOutlined
                className={styles.clearSelectedItems}
                onClick={this.clearSelectedItems}
              />
            </Tooltip>
          )}
        </Breadcrumb>
        <CustomCard
          className='documents-list'
          tabList={[
            {
              key: OWNERS,
              tab: t(OWNERS.toUpperCase()),
            },
            {
              key: PRODUCERS,
              tab: t(PRODUCERS.toUpperCase()),
            },
            {
              key: IMPLEMENTATION_TYPES,
              tab: t(IMPLEMENTATION_TYPES.toUpperCase()),
            },
            {
              key: TRADE_DEVICES,
              tab: t(TRADE_DEVICES.toUpperCase()),
            },
            // temporary disabled
            // {
            //   key: TERMINAL_TYPES,
            //   tab: t(TERMINAL_TYPES.toUpperCase()),
            // },
            {
              key: SOFTWARE_VERSION,
              tab: t(SOFTWARE_VERSION.toUpperCase()),
            },
          ]}
          activeTabKey={currentClassificator}
          onTabChange={this.onTabChange}
          tabBarExtraContent={(
            <>
              <Button
                onClick={() => this.openForm(false)}
                className='control-button'
                size='small'
                icon={<PlusCircleOutlined />}
                disabled={
                  (currentClassificator === SOFTWARE_VERSION && !selectedTradeDevice)
                  || !isEditRight
                  || isDisableAddDelAIP
                }
              >
                {t('ADD')}
              </Button>
              <Button
                onClick={() => this.openForm(true)}
                disabled={!selectedItem.id || isDeleting || !isEditRight || isDisableEditAIP}
                className='control-button'
                size='small'
                icon={<EditOutlined />}
              >
                {t('EDIT')}
              </Button>
              <Popconfirm
                title={t('CONFIRM_DELETE')}
                onConfirm={this.deleteItem}
                okText={t('DELETE')}
                cancelText={t('CANCEL')}
                disabled={!isEditRight || isDisableAddDelAIP}
              >
                <Button
                  disabled={!selectedItem.id || isDeleting || !isEditRight || isDisableAddDelAIP}
                  loading={isDeleting}
                  className='control-button'
                  size='small'
                  icon={<DeleteOutlined />}
                >
                  {t('DELETE')}
                </Button>
              </Popconfirm>
            </>
          )}
        >
          <FixedYCommonTable
            tableName={tableName}
            size={TABLE_SIZE}
            columns={this.prepareColumns()}
            dataSource={classificatorsData}
            loading={isLoading}
            pagination={{
              onChange: async (newPage) => changePage(newPage, await this.loadData),
              total: count,
              pageSize: limit,
              current: page,
            }}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [selectedItem.id],
              onChange: (selectedRowKeys, selectedRows) => this.handleSelect(selectedRows[0]),
            }}
            onShowSizeChange={(current, pageSize) => onShowSizeChange(current, pageSize, tableName, this.loadData)}
          />
          <ExportBottom
            count={count}
            className='control-button'
            size='small'
            WSData={{
              url: mapClassificatorToRequestURL[currentClassificator],
              params: {
                ...filterValues,
                producer: selectedProducers && selectedProducers.id,
                implementation_type: selectedImplementationTypes && selectedImplementationTypes.id,
                trade_device: selectedTradeDevice && selectedTradeDevice.id,
              },
              accept: 'application/xlsx',
            }}
          />
        </CustomCard>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isOpen,
  sidebarType: state.sidebar.element_type,
  rights: state.rights.rightsData,
  archived: state.archived,
});
const mapDispatchToProps = {
  openFormSidebar: openSidebar,
  closeFormSidebar: closeSidebar,
};
const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      DeviceClassificators
    )
  )
);
