import axios from 'axios';
import decode from 'jwt-decode';
import qs from 'qs';
import { isArchived } from '@app/helpers';
import store from '@state/store';
import {
  jwtData, refreshToken, setClearUserExtraInfo, setToken, setUserExtraInfo,
} from '@state/user/actions';
import { setAppStage } from '@state/appStage/actions';

import {
  APPSTAGE_SIGNIN, LIMIT,
} from '@globalConstants';

import config from '@globalConfig';
import { cloneDeep } from 'lodash';

axios.defaults.timeout = config.ajax_request_timeout;
const api_url = config.main_api_prefix;
const logger_url = config.logger_prefix;

let token;
let timerId;

class API {
  async requestAsFormData(url, method = 'get', dataForSend = null, extraOpts = {}, extraHeaders = {}, external = false) {
    const formData = new FormData();

    if (dataForSend?.additional_attributes) {
      formData.append(
        'additional_attributes',
        JSON.stringify(dataForSend.additional_attributes)
      );
    }

    if (dataForSend?.additional_external_link) {
      formData.append(
        'additional_external_link',
        JSON.stringify(dataForSend?.additional_external_link)
      );
    }

    Object.keys(dataForSend).forEach((key) => {
      if (dataForSend[key] !== undefined && key !== 'additional_attributes' && key !== 'additional_external_link') {
        if (Array.isArray(dataForSend[key])) {
          dataForSend[key].forEach((x) => formData.append(key, x));
        } else {
          formData.append(key, dataForSend[key]);
        }
      }
    });
    return this.request(
      url,
      method,
      formData,
      extraOpts,
      extraHeaders,
      external
    );
  }

  async loggerRequest(url, method = 'get', dataToSend = null, extraOpts = {}, extraHeaders = {}) {
    const newUrl = logger_url + url;
    return this.request(newUrl, method, dataToSend, extraOpts, extraHeaders, true);
  }

  async request(url, method = 'get', dataToSend = null, extraOpts = {}, extraHeaders = {}, external = false, noArchived = false) {
    let answer;
    const requestUrl = external ? url : api_url + url;

    const noChangePage = dataToSend && dataToSend.noChangePage && dataToSend.noChangePage === true;
    if (noChangePage) {
      delete dataToSend.noChangePage;
    }
    let data;
    if (method === 'get' && !external && !noArchived) {
      const isArchivedData = isArchived();
      if (dataToSend) {
        data = { ...dataToSend, is_archived: isArchivedData };
      } else {
        data = { is_archived: isArchivedData };
      }
    } else {
      data = dataToSend;
    }

    const params = method === 'get' ? data : null;

    try {
      const opts = {
        method,
        url: requestUrl,
        headers: {
          ...extraHeaders,
        },
        data,
        params,
        ...extraOpts,
      };
      if (token) opts.headers.Authorization = `bearer ${token}`;
      const response = await axios(opts);

      answer = { response };
    } catch (error) {
      answer = { response: error.response };
    }

    if (answer.response) {
      answer.headers = answer.response.headers;
      answer.status = answer.response.status;
      const answer_data = answer.response.data;
      answer.data = answer_data;
      answer.page = (
        data && data.page
          ? data.page
          : (
            data && data.offset !== undefined && data.limit !== undefined && data.limit
              ? Math.floor(data.offset / data.limit) + 1
              : 1
          )
      );

      if (answer.status === 401 && !external) {
        store.dispatch(setAppStage(APPSTAGE_SIGNIN));
      } else if (
        answer_data
        && answer_data.results
        && answer_data.results.length === 0
        && answer_data.count
        && params
        && params.limit
        && noChangePage !== true
      ) {
        const newData = cloneDeep(data);
        let newPage;
        if (data.offset && answer_data.count <= data.offset) {
          newPage = Math.floor(answer_data.count / data.limit) + 1;
        } else if (data.page && answer_data.count <= (data.page - 1) * data.limit) {
          newPage = data.page - 1;
        }
        newData.offset = (newPage - 1) * data.limit;
        newData.page = newPage;

        answer = await this.request(url, method, newData, extraOpts, extraHeaders, external);
      }
    } else {
      answer.status = -1;
      answer.data = null;
    }

    return answer;
  }

  tokenAuth(username, password) {
    return this.request('/v1/api-token-auth/', 'post', {
      username,
      password,
    });
  }

  tokenRefresh(newToken) {
    return this.request('/v1/api-token-refresh/', 'get', { token: newToken });
  }

  tokenRefreshEmpty() {
    return this.request('/v1/api-token-refresh/', 'get');
  }

  getOptsForArray() {
    return {
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    };
  }

  getNullUuid = () => '00000000-0000-0000-0000-000000000000';

  setAUTHToken(token_ = '') {
    if (token_ && token_.length) {
      try {
        const decoded = decode(token_);

        token = token_;
        store.dispatch(jwtData(decoded));

        const current_date = +(new Date());
        const exp_date = decoded.exp * 1000;

        if (timerId) {
          clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            token = undefined;
            store.dispatch(refreshToken(token_))
            }, exp_date - current_date - 90 * 1000
        );
      } catch (e) {
        token = undefined;
        if (timerId) {
          clearTimeout(timerId);
        }
      }
    } else {
      token = undefined;
      if (timerId) {
        clearTimeout(timerId);
      }
    }

    store.dispatch(setToken(token));
    token
      ? store.dispatch(setUserExtraInfo())
      : store.dispatch(setClearUserExtraInfo());
  }

  getRights() {
    return this.request('/v1/interface-rights/get-rights/');
  }

  getPassportRights(id) {
    return this.request(`/v1/interface-rights/get-rights/?protection_device_id=${id}`);
  }

  // ---------------------------------------------

  urlGetRequest(url) {
    return this.request(url);
  }

  getDispatcherCenters(params = {}, opts = {}) {
    return this.request('/v1/dispatcher-centers/', 'get', params, opts);
  }

  getDispatcherCentersByName(params) {
    return this.request('/v1/dispatcher-centers/tree', 'get', params);
  }

  addDispatcherCenters(name) {
    return this.request('/v1/dispatcher-centers/', 'post', { name });
  }

  updateDispatcherCenters(id, name) {
    return this.request(`/v1/dispatcher-centers/${id}/`, 'patch', { name });
  }

  deleteDispatcherCenters(id) {
    return this.request(`/v1/dispatcher-centers/${id}/`, 'delete');
  }

  async getSubstationsTree(dispatcherCentersId, progressUpdater) {
    const answer = await this.request('/v1/substations/tree', 'get', { dispatcher_center: dispatcherCentersId });
    if (answer.status === 200 && progressUpdater) {
      let count = answer.data.count;
      if (count > answer.data.results.length) {
        const chunk = answer.data.results.length;
        if (progressUpdater) progressUpdater(count, chunk);
        const total = count;
        count -= chunk;
        let offset = chunk;
        const chunkp = [];
        while (count > 0) {
          chunkp.push(
            this.request('/v1/substations/tree', 'get', { dispatcher_center: dispatcherCentersId, offset })
          );
          offset += chunk;
          count -= chunk;
        }
        offset = chunk;
        for (let i = 0, l = chunkp.length; i < l; i += 1) {
          const res = await chunkp[i];
          if (res.status === 200) {
            answer.data.results.push(...res.data.results);
          }
          offset += chunk;
          if (progressUpdater) progressUpdater(total, offset);
        }
      }
    }
    return answer;
  }

  getRegions(params, opts = {}) {
    return this.request('/v1/regions/', 'get', params, opts);
  }

  getSubstations(params, opts = {}) {
    return this.request('/v1/substations/tree', 'get', params, opts);
  }

  getSubstationsByName(params) {
    return this.request('/v1/substations/tree', 'get', params);
  }

  addSubstation(name, dispatcherCentersId = '') {
    return this.request('/v1/substations/', 'post', {
      name,
      dispatcher_centers: dispatcherCentersId ? [dispatcherCentersId] : undefined,
    });
  }

  updateSubstation(id, name) {
    return this.request(`/v1/substations/${id}/`, 'patch', { name });
  }

  deleteSubstation(id) {
    return this.request(`/v1/substations/${id}/`, 'delete');
  }

  getEquipmentTypes(params) {
    return this.request('/v1/equipment-types/', 'get', params);
  }

  addEquipmentType(params) {
    return this.request('/v1/equipment-types/', 'post', params);
  }

  updateEquipmentType(id, params) {
    return this.request(`/v1/equipment-types/${id}/`, 'patch', params);
  }

  deleteEquipmentType(id) {
    return this.request(`/v1/equipment-types/${id}/`, 'delete');
  }

  getEquipmentBase(params, opts = {}) {
    return this.request('/v1/equipment-base/tree', 'get', params, opts);
  }

  addEquipmentBase(params) {
    return this.request('/v1/equipment/', 'post', params);
  }

  updateEquipmentBase(id, name) {
    return this.request(`/v1/equipment/${id}/`, 'patch', { name });
  }

  deleteEquipmentBase(id) {
    return this.request(`/v1/equipment/${id}/`, 'delete');
  }

  getProtectionDeviceList(params) {
    return this.request('/v1/protection-devices/tree', 'get', params);
  }

  getProtectionDevicePassportId(deviceId) {
    return this.request(`/v1/device/${deviceId}/passport-ids/`);
  }

  getProtectionDevice(deviceId) {
    return this.request(`/v1/protection-devices/${deviceId}/`);
  }

  addProtectionDevice(data) {
    return this.request('/v1/protection-devices/', 'post', data);
  }

  patchProtectionDevice(id, values) {
    return this.request(`/v1/protection-devices/${id}/`, 'patch', values);
  }

  deleteProtectionDevice(id) {
    return this.request(`/v1/protection-devices/${id}/`, 'delete');
  }

  async getDataForFilter(url = '', data = {}, limit = LIMIT, offset = 0) {
    const requestParams = { ...data };
    requestParams.limit = limit;
    requestParams.offset = offset;

    return this.request(url, 'get', requestParams, this.getOptsForArray());
  }

  async getPostDataForFilter(url = '', data = {}, limit = LIMIT, offset = 0) {
    const requestParams = { ...data };
    requestParams.limit = limit;
    requestParams.offset = offset;

    return this.request(url, 'post', requestParams);
  }

  searchHints(pattern) {
    return this.request('/v1/search-hints/', 'get', { name: pattern });
  }

  searchHistory(search) {
    return this.request('/v1/search-history/', 'get', { search });
  }

  getSearchQueryList(data) {
    return this.request('/v1/search-requests/', 'get', data);
  }

  saveSearchQuery(data = {}) {
    return this.request('/v1/search-requests/', 'post', data);
  }

  deleteSearchQuery(id) {
    return this.request(`/v1/search-requests/${id}/`, 'delete');
  }

  //---------------------------------------------

  getProducers(data, opts = {}) {
    return this.request('/v1/producers/', 'get', data, opts);
  }

  addProducers(producer) {
    return this.request('/v1/producers/', 'post', producer);
  }

  updateProducers(id, data) {
    return this.request(`/v1/producers/${id}/`, 'patch', data);
  }

  deleteProducers(id) {
    return this.request(`/v1/producers/${id}/`, 'delete');
  }

  restoreProducers(id) {
    return this.request(`/v1/producers/${id}/restore/`, 'post');
  }

  getOwners(data, opts = {}) {
    return this.request('/v1/owners/', 'get', data, opts);
  }

  addOwners(owner) {
    return this.request('/v1/owners/', 'post', owner);
  }

  updateOwners(id, data) {
    return this.request(`/v1/owners/${id}/`, 'patch', data);
  }

  deleteOwners(id) {
    return this.request(`/v1/owners/${id}/`, 'delete');
  }

  restoreOwner(id) {
    return this.request(`/v1/owners/${id}/restore/`, 'post');
  }

  exportExcelOwners() {
    return this.request(
      '/v1/owners/',
      'get',
      null,
      { responseType: 'blob' },
      { Accept: 'application/xlsx' }
    );
  }

  getVoltageLevels(data = {}, opts = {}) {
    return this.request('/v1/voltage-levels/', 'get', data, opts);
  }

  getTradeDevices(data, opts = {}) {
    return this.request('/v1/trade-devices/', 'get', data, opts);
  }

  addTradeDevices(data) {
    return this.request('/v1/trade-devices/', 'post', data);
  }

  updateTradeDevices(id, data) {
    return this.request(`/v1/trade-devices/${id}/`, 'patch', data);
  }

  deleteTradeDevices(id) {
    return this.request(`/v1/trade-devices/${id}/`, 'delete');
  }

  restoreTradeDevices(id) {
    return this.request(`/v1/trade-devices/${id}/restore/`, 'post');
  }

  deleteTags(id) {
    return this.request(`/v1/tags/${id}/`, 'delete');
  }

  restoreTag(id) {
    return this.request(`/v1/tags/${id}/restore/`, 'post');
  }

  deleteDocumentStatuses(id) {
    return this.request(`/v1/documents-statuses/${id}/`, 'delete');
  }

  restoreDocumentStatus(id) {
    return this.request(`/v1/documents-statuses/${id}/restore/`, 'post');
  }

  deleteDocumentTypes(id) {
    return this.request(`/v1/documents-types/${id}/`, 'delete');
  }

  restoreDocumentType(id) {
    return this.request(`/v1/documents-types/${id}/restore/`, 'post');
  }

  updateTags(id, data) {
    return this.request(`/v1/tags/${id}/`, 'patch', data);
  }

  updateDocumentStatuses(id, data) {
    return this.request(`/v1/documents-statuses/${id}/`, 'patch', data);
  }

  updateDocumentTypes(id, data) {
    return this.request(`/v1/documents-types/${id}/`, 'patch', data);
  }

  createTags(data) {
    return this.request('/v1/tags/', 'post', data);
  }

  createDocumentStatuses(data) {
    return this.request('/v1/documents-statuses/', 'post', data);
  }

  createDocumentTypes(data) {
    return this.request('/v1/documents-types/', 'post', data);
  }

  getPassport(id) {
    return this.request(`/v1/passports/${id}/`);
  }

  getPassports(params) {
    return this.request('/v1/passports/', 'get', params);
  }

  getFunctions(data = {}) {
    return this.getFunctionsTree(true, data, false);
  }

  addFunctionToTreeAsChildren = (data, callback) => {
    data.forEach((item) => {
      if ('children' in item && item.children) {
        this.addFunctionToTreeAsChildren(item.children, callback);
      }
      if (item.functions) {
        item.children = [...item.children, ...item.functions];
      }
    });
  };

  getFunctionsTree = async (isHideEmpty, args = {}, onlyGroups = false) => {
    const dataForRequest = {
      ordering: 'name',
      has_functions: isHideEmpty || undefined,
      ...args,
    };

    const res = await this.request('/v1/function-groups/tree/', 'get', dataForRequest);

    if (!onlyGroups && res && res.data && res.data.tree) {
      const fixed = [...res.data.tree];
      
      this.addFunctionToTreeAsChildren(fixed);
    }

    if (res?.data?.tree) { 
        res.data.results = [...res.data.tree];
        delete res.data.tree;
    }

    return res;
  }

  v1_getFunctionsForBlank(blankId, searchName) {
    const data = {
      blank: blankId,
    };
    if (searchName && searchName !== '') {
      data.setpoint_name = searchName;
    }

    return this.getFunctionsTree(true, data, false);
  }

  v1_getFunctionsForTaskDC(taskId, blankId, searchName) {
    const data = {
      task_dc: taskId,
      blank: blankId,
    };
    if (searchName && searchName !== '') {
      data.setpoint_name = searchName;
    }

    return this.getFunctionsTree(true, data, false);
  }

  getFunctionsListByTreeElement(args = {}) {
    const data = {
      ordering: 'name',
      ...args,
    };
    return this.request('/v1/functions/', 'get', data);
  }

  getProtectionDeviceFunctions(data = {}) {
    return this.request('/v1/protection-device-functions/', 'get', data);
  }

  deletePassportFunctionsV3(passportId, id) {
    return this.request(`/v1/passport/${passportId}/protection-device-functions/${id}`, 'delete');
  }

  restorePassportFunctionsV3(id) {
    return this.request(`/v1/protection-device-functions/${id}/restore/`, 'post');
  }

  async getFunctionsForFilter(name, isAccounting = false, offset = 0, limit = 20) {
    return this.request(
      '/v1/functions/',
      'get',
      {
        ordering: 'name',
        name,
        limit,
        offset,
        is_accounting_group: isAccounting,
      }
    );
  }

  addPassportFunction(func) {
    return this.request('/v1/protection-device-functions/', 'post', func);
  }

  addFunction(func) {
    return this.request('/v1/functions/', 'post', func);
  }

  editFunction(id, func) {
    return this.request(`/v1/functions/${id}/`, 'patch', func);
  }

  deleteFunction(id) {
    return this.request(`/v1/functions/${id}/`, 'delete');
  }

  addFunctionsGroup(func) {
    return this.request('/v1/function-groups/', 'post', func);
  }

  editFunctionsGroup(id, func) {
    return this.request(`/v1/function-groups/${id}/`, 'patch', func);
  }

  deleteFunctionsGroup(id) {
    return this.request(`/v1/function-groups/${id}/`, 'delete');
  }

  updatePassportFunction(id, func) {
    return this.request(`/v1/protection-device-functions/${id}/`, 'patch', func);
  }

  patchAllChangedTaskSetpoints(values, calcViewMode, staticRec) {
    return this.request(`/v1/setpoint-values/many/?calc_view_mode=${calcViewMode}&static_rec=${staticRec}`, 'patch', values);
  }

  getSetpointTypes() {
    return this.request('/v1/setpoint-types/');
  }

  getMeasurementUits() {
    return this.request('/v1/measurement-units/');
  }

  getDocumentsByParams(params = {}) {
    return this.request(
      '/v1/documents/',
      'get',
      params,
      this.getOptsForArray()
    );
  }

  getPassportDocs(params) {
    return this.request(
      '/v1/passport-documents/',
      'get',
      params,
      this.getOptsForArray()
    );
  }

  getBucket(params = {}) {
    return this.request('/v1/bucket', 'get', params);
  }

  addProtectionDevice2Bucket(id) {
    return this.request('/v1/bucket', 'post', { protection_device_id: [id] });
  }

  addProtectionDevices2Bucket(protectionDeviceIds) {
    return this.request('/v1/bucket', 'post', { protection_device_id: protectionDeviceIds });
  }

  clearBucket(protectionDeviceIds = []) {
    const protectionDeviceIdsList = !Array.isArray(protectionDeviceIds)
      ? [protectionDeviceIds]
      : [...protectionDeviceIds];
    return this.request('/v1/bucket', 'delete', { protection_device_id: protectionDeviceIdsList });
  }

  restoreDocument = (documentId) => {
    return this.request(`/v1/documents/${documentId}/restore/`, 'post');
  };

  deletePassportDocument(documentId, params = '') {
    return this.request(`/v1/documents/${documentId}/?${params}`, 'delete');
  }

  getUsers() {
    return this.request('/v1/users/');
  }

  getUserById(id) {
    return this.request(`/v1/users/${id}/`);
  }

  updateUser(id, data) {
    return this.request(`/v1/users/${id}/`, 'patch', data);
  }

  /* кажется не используется */
  getTasks(filters = {}) {
    return this.request('/v1/tasks/', 'get', filters);
  }

  createProject(data) {
    return this.request('/v1/projects/', 'post', data);
  }

  getNotifications(data = {}) {
    return this.request('/v1/task-dc-notifications/?ordering=-changed_date', 'get', data);
  }

  markNotifications(mark) {
    return this.request(`/v1/task-dc-notifications/?read=${mark}`, 'post');
  }

  cleanNotifications() {
    return this.request('/v1/task-dc-notifications/clean/', 'post');
  }

  deleteNotification(id) {
    return this.request(`/v1/task-dc-notifications/${id}/`, 'delete');
  }

  getDocumentTypes(data, opts) {
    return this.request('/v1/documents-types/', 'get', data, opts);
  }

  getDocumentStatuses(data, opts) {
    return this.request('/v1/documents-statuses/', 'get', data, opts);
  }

  uploadDocument(data, params = {}) {
    return this.request(`/v1/documents/?${qs.stringify(params)}`, 'post', data);
  }

  unlinkDocumentFromTaskDC(task_id, document = []) {
    return this.requestAsFormData(`/v1/task-dcs/${task_id}/unlink-documents/`, 'post', { document });
  }

  getLog(data = {}) {
    return this.loggerRequest('/records/', 'get', data, this.getOptsForArray());
  }

  getJournalMessages(params = {}) {
    const { limit, offset } = params;

    return this.request('/api/system/system-log/', 'get', {
      limit,
      offset,
    });
  }

  getTags(data, opts) {
    return this.request('/v1/tags/', 'get', data, opts);
  }

  getBlankComparableList(blankId) {
    return this.request(`/v1/blanks/${blankId}/comparable/`, 'get');
  }

  getBlanks(params) {
    return this.request(
      '/v1/blanks/?view_mode=full',
      'get',
      params,
      {
        paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
      }
    );
  }

  getBlankById(blankId) {
    return this.request(`/v1/blanks/${blankId}/`, 'get');
  }

  getPassportBlankById(deviceId, blankId) {
    return this.request(`/v1/passport/${deviceId}/blanks/${blankId}?view_mode=full`, 'get');
  }

  uploadBlank(passportId, data) {
    return this.request(`/v1/passports/${passportId}/upload-blank/`, 'post', data);
  }

  uploadTask(taskId, blankId, data) {
    return this.request(`/v1/task-dcs/${taskId}/update-blank/${blankId}/`, 'post', data);
  }

  uploadTemplate(data) {
    return this.request('/v1/templates/upload/', 'post', data);
  }

  createBlank(data) {
    return this.request('/v1/blanks/', 'post', data);
  }

  exportBlankToWord(blankId, data = {}) {
    return this.request(
      `/v1/blanks/${blankId}/word/`,
      'get',
      data,
      { responseType: 'blob' },
      { accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
    );
  }

  updateBlankV1(blankId, data) {
    return this.request(`/v1/blanks/${blankId}/`, 'patch', data);
  }

  restoreBlank(id) {
    return this.request(`/v1/blanks/${id}/restore/`, 'post');
  }

  verifyBlank(id, is_verify) {
    const data = { is_verify };
    return this.request(`/v1/blanks/${id}/change_verification/`, 'patch', data);
  }

  updateBlankTemplateVersion(blankId) {
    return this.request(`/v1/blanks/${blankId}/upgrade/`, 'post');
  }

  deleteBlank(passportId, blankId) {
    return this.request(`/v1/passport/${passportId}/blanks/${blankId}`, 'delete');
  }

  addGroupToBlank(passportId, blankId) {
    return this.request(`/v1/passport/${passportId}/blanks/${blankId}/add-group`);
  }

  deleteGroupFromBlank(passportId, blankId, groupId) {
    return this.request(`/v1/passport/${passportId}/blanks/${blankId}/del-group/${groupId}`);
  }

  getTemplatesV3(data = {}) {
    return this.request('/v1/legacy-templates', 'get', data);
  }

  getTemplateBlanks(id, data = {}) {
    return this.request(`/v1/templates/${id}/blanks`, 'get', data);
  }

  getFunctionProtectionDevices(id, data = {}) {
    return this.request(`/v1/functions/${id}/protection-devices`, 'get', data);
  }

  getProtectionDeviceComplexProtectionDevices(id, data = {}) {
    return this.request(`/v1/protection-device-complexes/${id}/protection-device`, 'get', data);
  }

  getTemplate(templateId) {
    return this.request(`/v1/templates/${templateId}/`);
  }

  getTemplateTree(templateId, isDeleted = false) {
    return this.request(`/v1/templates-tree/${templateId}/?is_deleted=${isDeleted}`);
  }

  addTemplateTree(template, isDeleted = false) {
    return this.request(`/v1/templates-tree/?is_deleted=${isDeleted}`, 'post', template);
  }

  updateTemplateTree(templateId, template, isDeleted = false) {
    return this.request(`/v1/templates-tree/${templateId}/?is_deleted=${isDeleted}`, 'patch', template);
  }

  saveTemplates(data) {
    return this.request('/v1/templates/', 'post', data);
  }

  updateTemplates(id, data) {
    return this.request(`/v1/templates/${id}/`, 'patch', data);
  }

  deleteTemplates(id) {
    return this.request(`/v1/templates/${id}/`, 'delete');
  }

  restoreTemplate(id) {
    return this.request(`/v1/templates/${id}/restore/`, 'post');
  }

  copyTemplate(id) {
    return this.request(`/v1/templates/${id}/copy/`, 'post');
  }

  getDocumentByIdV2(id) {
    return this.request(`/v1/documents/${id}/`);
  }

  downloadDocument(link) {
    return this.request(
      link,
      'get',
      {},
      { responseType: 'blob' },
      {},
      true
    );
  }

  patchDocumentItem(documentId, data = {}, autoCreated) {
    const addParams = {};
    if (autoCreated !== undefined) {
      addParams.autocreated = autoCreated;
    }
    const isArchivedData = isArchived();
    if (isArchivedData) {
      addParams.is_archived = isArchivedData;
    }

    const qString = qs.stringify(addParams);

    return this.request(
      `/v1/documents/${documentId}/${
        qString && qString.length
          ? `?${qString}`
          : ''
      }`,
      'patch',
      data
    );
  }

  getInterfaceRights() {
    return this.request('/v1/interface-rights/');
  }

  setInterfaceRights(data) {
    return this.request('/v1/interface-rights/', 'post', data);
  }

  patchInterfaceRights(id, data) {
    return this.request(`/v1/interface-rights/${id}/`, 'patch', data);
  }

  getGroupsWithRoles() {
    return this.request('/v1/groups/?with_roles=true');
  }

  getRoles() {
    return this.request('/v1/roles');
  }

  changeGroupRoles(id, data) {
    return this.request(`/v1/groups/${id}/`, 'patch', { role_id: data });
  }

  addGroup(data) {
    return this.request('/v1/groups/', 'post', data);
  }

  deleteGroup(id) {
    return this.request(`/v1/groups/${id}/`, 'delete');
  }

  getIntegrations() {
    return this.request('/api/integrations/settings');
  }

  saveIntegrations(data) {
    return this.request('/api/integrations/settings', 'post', data);
  }

  restoreTask(id) {
    return this.request(`/v1/task-dcs/${id}/restore/`, 'post');
  }

  getTaskDcSetpointValuesTree(taskId) {
    return this.request(`/v1/task-dc/${taskId}/setpoint-values-tree/?ordering=startup_date`, 'get');
  }

  getTaskDcSetpointValueStatus(id) {
    return this.request(`/v1/task-dc-setpoint-values/${id}/`, 'get');
  }

  getTaskDcSetpointValueNotes(data) {
    return this.request('/v1/task-dc-setpoint-value-note/', 'get', data);
  }

  setTaskDcSetpointValueStatusMass(ids, status) {
    const getChangeStatusSetpoint = {
      selected_for_calc: 'set-selected',
      is_calculated: 'set-calculated',
      is_approved: 'set-approved',
    };
    const currentSetpoint = getChangeStatusSetpoint[status];
    if (currentSetpoint) {
      return this.request(
        `/v1/task-dc-setpoint-values/${currentSetpoint}/`,
        'post',
        { tdc_setpoint_values: ids }
      );
    } else {
      return this.errorAnswer();
    }
  }

  sendSetpointNotes(data) {
    return this.request('/v1/task-dc-setpoint-value-note/', 'post', data);
  }

  bindSetpointNoteFile(data) {
    return this.request('/v1/task-dc-setpoint-value-note-document/', 'post', data);
  }

  cloneTemplate(id, data) {
    return this.request(`/v1/templates/${id}/upgrade/`, 'post', data);
  }

  errorAnswer() {
    return { status: 404, data: null };
  }

  emptyAnswer() {
    return { status: 200, data: null };
  }

  emptyAnswerWithNext() {
    return {
      status: 200,
      data: {
        results: [],
        count: 0,
        next: null,
        previous: null,
      },
    };
  }

  v1_getCategoriesForBlank(blankId, searchName) {
    const data = {
      blank: blankId
    };
    if (searchName && searchName !== '') {
      data.setpoint_name = searchName;
    }

    return this.request('/v1/categories/tree/', 'get', data);
  }

  async v1_getSetPointsWithValues(data) {
    const res = await this.request('/v1/setpoint-values/embedded-values/', 'get', data);

    // Специальная обработка именно этой ошибки, поскольку мы на БЭКе перенесли группы функций в др.таблицу
    // и теперь эндпоинт не находит в списке эту функцию (потому что мы указали группу) и возвращает ошибку
    if (res
      && res.status === 400
      && res.data
      && res.data.function
      && (
        (Array.isArray(res.data.function)
         && res.data.function.join(' ').indexOf('Выберите корректный вариант') !== false
         && res.data.function.join(' ').indexOf('нет среди допустимых значений') !== false
        )
        || (!Array.isArray(res.data.function)
          && res.data.function.indexOf('Выберите корректный вариант') !== false
          && res.data.function.indexOf('нет среди допустимых значений') !== false
        )
      )
    ) {
      return {
        status: 200,
        data: {
          results: [],
        },
      };
    }

    return res;
  }

  patchAllChangedSetpoints(values, calcViewMode , staticRec) {
    return this.request(`/v1/setpoint-values/many/?calc_view_mode=${calcViewMode}&static_rec=${staticRec}`, 'patch', values);
  }

  createTaskDCV2(data) {
    return this.requestAsFormData(
      '/v1/task-dcs/',
      'post',
      data,
      {
        paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
      }
    );
  }

  deleteTaskDCV2(taskId) {
    return this.request(`/v1/task-dcs/${taskId}/`, 'delete');
  }

  getTasksDCV2(params) {
    return this.request('/v1/task-dcs/?view_mode=full', 'get', params);
  }

  getTaskDCV2(taskId, data = {}) {
    return this.request(`/v1/task-dcs/${taskId}/`, 'get', data);
  }

  patchTaskDCV2(taskId, data) {
    return this.requestAsFormData(
      `/v1/task-dcs/${taskId}/`,
      'patch',
      data,
      {
        paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
      }
    );
  }

  patchWindingLabels(labelId, data) {
    return this.requestAsFormData(`/v1/winding-labels/${labelId}/`, 'patch', data, {
      paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
    });
  }

  splitTaskBlanks(taskId, data) {
    return this.request(
      `/v1/task-dcs/${taskId}/separate/`,
      'post',
      data
    );
  }

  deleteTaskBlanks(taskId, data) {
    return this.request(
      `/v1/task-dcs/${taskId}/exclude/`,
      'post',
      data
    );
  }

  updateTaskStatus(newStatus, taskId, data = {}) {
    return this.request(`/v1/task-dcs/${taskId}/set-${newStatus}/`, 'post', data);
  }

  getTaskStatusV2(taskId) {
    return this.request(`/v1/task-dcs/${taskId}/status/`);
  }

  generateTask(passportId, taskId) {
    return this.request(
      `/v1/task-dcs/${taskId}/word/`,
      'get',
      {},
      { responseType: 'blob' },
      { accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
    );
  }

  downloadTaskDCDocument(id) {
    return this.request(`/v1/task-dcs/${id}/document`);
  }

  startAipIntegration() {
    return this.request('/api/integrations/aip_sync', 'get');
  }

  startAipTestIntegration(params = {}) {
    return this.request(
      '/api/integrations/aip_sync',
      'get',
      params,
      {
        paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
      }, {}, false, 
      true //отключает query параметр is_archived
    );
  }

  getDevicesOscillogramList(data = {}) {
    return this.request('/v1/oscillogram/', 'get', data);
  }

  getDevicesComplexes(data = {}) {
    return this.request('/v1/protection-device-complexes/', 'get', data);
  }

  getDevicesComplexItem(id) {
    return this.request(`/v1/protection-device-complexes/${id}/`);
  }

  createDevicesComplex(data = {}) {
    return this.request('/v1/protection-device-complexes/', 'post', data);
  }

  updateDevicesComplex(complexId, data = {}) {
    return this.request(`/v1/protection-device-complexes/${complexId}/`, 'patch', data);
  }

  getDocumentItem(documentId, data = {}) {
    return this.request(`/v1/documents/${documentId}/`, 'get', data);
  }

  downloadPassportDocs(docId, passportId) {
    return this.request(
      `/v1/passport/${passportId}/documents/${docId}/download`,
      'get',
      {},
      { responseType: 'blob' }
    );
  }

  async getSettings() {
    return this.request('/api/settings');
  }

  async changeSettings(data) {
    return this.request('/api/settings', 'post', data);
  }

  async getTerminalTypes(data) {
    return this.request('/v1/terminal-types/', 'get', data);
  }

  async addTerminalTypes(data) {
    return this.request('/v1/terminal-types/', 'post', data);
  }

  async updateTerminalTypes(id, data) {
    return this.request(`/v1/terminal-types/${id}/`, 'patch', data);
  }

  async deleteTerminalTypes(id) {
    return this.request(`/v1/terminal-types/${id}/`, 'delete');
  }

  getVersions(data, opts = {}) {
    return this.request('/v1/versions/', 'get', data, opts);
  }

  addVersions(data) {
    return this.request('/v1/versions/', 'post', data);
  }

  updateVersions(id, data) {
    return this.request(`/v1/versions/${id}/`, 'patch', data);
  }

  updateVersionsMerge(id, data) {
    return this.request(`/v1/versions/${id}/merge/`, 'patch', data);
  }


  deleteVersions(id) {
    return this.request(`/v1/versions/${id}/`, 'delete');
  }

  restoreVersion(id) {
    return this.request(`/v1/versions/${id}/restore/`, 'post');
  }

  deleteComplex(id) {
    return this.request(`/v1/protection-device-complexes/${id}/`, 'delete');
  }

  restoreComplex(id) {
    return this.request(`/v1/protection-device-complexes/${id}/restore/`, 'post');
  }

  async getImplementationTypes(data, opts = {}) {
    return this.request('/v1/implementation-types/', 'get', data, opts);
  }

  addImplementationTypes(data) {
    return this.request('/v1/implementation-types/', 'post', data);
  }

  updateImplementationTypes(id, data) {
    return this.request(`/v1/implementation-types/${id}/`, 'patch', data);
  }

  deleteImplementationTypes(id) {
    return this.request(`/v1/implementation-types/${id}/`, 'delete');
  }

  restoreImplementationTypes(id) {
    return this.request(`/v1/implementation-types/${id}/restore/`, 'post');
  }

  async getTaskStatuses() {
    return this.request('/v1/task-statuses/');
  }

  async getTaskDCStatuses(data = {}) {
    return this.request('/v1/task-dc/statuses', 'get', data);
  }

  async getTaskDCSearch(data = {}) {
    return this.request('/v1/task-dc/search', 'get', data);
  }

  getEvents(data) {
    return this.request('/v1/events/', 'get', data, this.getOptsForArray());
  }

  getEventItem(id) {
    return this.request(`/v1/events/${id}/`);
  }

  addEvents(data) {
    return this.request('/v1/events/', 'post', data);
  }

  updateEvents(id, data) {
    return this.request(`/v1/events/${id}/`, 'patch', data);
  }

  deleteEvents(id) {
    return this.request(`/v1/events/${id}/`, 'delete');
  }

  restoreEvents(id) {
    return this.request(`/v1/events/${id}/restore/`, 'post');
  }

  bindDeviceToEvent(id, data) {
    return this.request(`/v1/events/${id}/protection-devices-add`, 'post', data);
  }

  unbindDeviceFromEvent(id, data) {
    return this.request(`/v1/events/${id}/protection-devices-remove`, 'post', data);
  }

  getSubstationsData(data) {
    return this.request('/v1/substations/tree', 'get', data);
  }

  addDocumentaryToEvents(id, data = {}) {
    return this.request(`/v1/events/${id}/documentary-documents-add`, 'post', data);
  }

  removeDocumentaryFromEvents(id, data = {}) {
    return this.request(`/v1/events/${id}/documentary-documents-remove`, 'post', data);
  }

  addExecutiveDocToEvents(id, data = {}) {
    return this.request(`/v1/events/${id}/executive-program-documents-add`, 'post', data);
  }

  removeExecutiveDocFromEvents(id, data = {}) {
    return this.request(`/v1/events/${id}/executive-program-documents-remove`, 'post', data);
  }

  getStorages(params = {}) {
    return this.request('/v1/storages/', 'get', params);
  }

  createStorage(data) {
    return this.request('/v1/storages/', 'post', data);
  }

  updateStorage(id, data) {
    return this.request(`/v1/storages/${id}/`, 'patch', data);
  }

  deleteStorage(id) {
    return this.request(`/v1/storages/${id}/`, 'delete');
  }

  async getNews(params = {}) {
    return this.request('/v1/news/', 'get', params);
  }

  async createNews(params = {}) {
    return this.request('/v1/news/', 'post', params);
  }

  async editNews(id, params = {}) {
    return this.request(`/v1/news/${id}/`, 'patch', params);
  }

  async deleteNews(id) {
    return this.request(`/v1/news/${id}/`, 'delete');
  }

  async getNewsUnread() {
    return this.request('/v1/news/count/', 'get');
  }

  async getMaintenance() {
    return this.request('/v1/maintenance/', 'get');
  }

  async readMaintenance() {
    return this.request(`/v1/maintenance/set-read/`, 'get');
  }

  async editMaintenance(params = {}) {
    return this.request('/v1/maintenance/', 'post', params);
  }

  async downloadDocFromStorage(link) {
    const url = new URL(link);
    // Костыль, когда доступ к хранилищу проксируется через nginx
    const storage_url_ping = link.indexOf('/st/') !== -1
      ? `${url.origin}/st/ping`
      : `${url.origin}/ping`;
    const res = await this.request(
      storage_url_ping,
      'head',
      null,
      { timeout: 3000 },
      { 'Cache-Control': 'no-cache' },
      true
    );

    if (res.status < 0) return res;

    return this.request(
      link,
      'get',
      {},
      { responseType: 'blob' },
      { 'Cache-Control': 'no-cache' },
      true
    );
  }

  getNonperformanceParams(params = {}) {
    return this.request('/v1/parameters/', 'get', params);
  }

  addNonperformanceTerm(params = {}) {
    return this.request('/v1/terms/', 'post', params);
  }

  editNonperformanceTerm(id, params = {}) {
    return this.request(`/v1/terms/${id}/`, 'patch', params);
  }

  deleteNonperformanceTerm(id) {
    return this.request(`/v1/terms/${id}/`, 'delete');
  }

  getTermDescriptions(id) {
    return this.request('/v1/descriptions/', 'get', { term: id });
  }

  addTermDescriptions(params = {}) {
    return this.request('/v1/descriptions/', 'post', params, this.getOptsForArray());
  }

  editTermDescriptions(id, params = {}) {
    return this.request(`/v1/descriptions/${id}/`, 'patch', params, this.getOptsForArray());
  }

  deleteTermDescriptions(id) {
    return this.request(`/v1/descriptions/${id}/`, 'delete');
  }

  getLetters(params = {}) {
    return this.request('/v1/letters/', 'get', params);
  }

  addLetter(params = {}) {
    return this.request('/v1/letters/', 'post', params);
  }

  editLetter(id, params = {}) {
    return this.request(`/v1/letters/${id}/`, 'patch', params);
  }

  deleteLetter(id, passport) {
    return this.request(`/v1/letters/${id}/?passports=${passport}`, 'delete');
  }

  restoreLetter(id) {
    return this.request(`/v1/letters/${id}/restore/`, 'post');
  }

  addLetterFiles(params) {
    return this.request('/v1/letter-files/', 'post', params);
  }

  getLetterFiles(params = {}) {
    return this.request('/v1/letter-files/', 'get', params);
  }

  uploadDCMap(params) {
    return this.request('/v1/document-template/visio', 'post', params);
  }

  getDocumentTemplates(params = {}) {
    return this.request('/v1/document-templates/', 'get', params);
  }

  deleteDCMap(id) {
    return this.request(`/v1/document-templates/${id}/`, 'delete');
  }

  activateDCMap(data = {}) {
    return this.request('/v1/document-template/active', 'patch', data);
  }

  updateDCMap(id, data = {}) {
    return this.requestAsFormData(`/v1/document-templates/${id}/`, 'patch', data);
  }

  bindTerm(data = {}) {
    return this.request('/v1/term-setpoint-value/', 'post', data);
  }

  unbindTerm(id, data = {}) {
    return this.request(`/v1/setpoint-values/${id}/terms-remove`, 'post', data);
  }

  getSetpointEmbeddedVal(data = {}) {
    return this.request('/v1/setpoints/embedded-values/', 'get', data);
  }

  getTransformers(data) {
    return this.request('/v1/measurement-transformer/', 'get', data);
  }
}

const api = new API();

export default api;
