import { GET_ARCHIVED_SETTINGS, TOGGLE_ARCHIVED_SETTINGS } from './types';

const initialState = {
  isArchivedData: undefined,
};

export default function archivedReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARCHIVED_SETTINGS:
      return {
        ...state,
        isArchivedData: action.value,
      };
    case TOGGLE_ARCHIVED_SETTINGS:
      return {
        ...state,
        isArchivedData: action.value,
      };
    default:
      return state;
  }
}
