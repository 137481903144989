import storage from '@services/storage';
import { GET_ARCHIVED_SETTINGS, TOGGLE_ARCHIVED_SETTINGS } from './types';

export const getArchivedSettings = () => {
  return async (dispatch) => {
    const value = storage.get('isArchivedData') === true;
    dispatch({
      type: GET_ARCHIVED_SETTINGS,
      value,
    });
  };
};

export const toggleArchivedSettings = () => {
  return async (dispatch) => {
    const value = storage.get('isArchivedData') === true;
    const newValue = !value;
    storage.set('isArchivedData', newValue);
    await dispatch({
      type: TOGGLE_ARCHIVED_SETTINGS,
      value: newValue,
    });
    window.location.reload();
  };
};
