export const HEADER_DATE_FORMAT = 'DD MMM YYYY';
export const NEWS_INFINITE_SCROLL_PAGE_SIZE = 10;
export const EDITOR_BUTTONS = [
    'undo',
    'redo',
    'font',
    'fontSize',
    'formatBlock',
    'paragraphStyle',
    'blockquote',
    'bold',
    'underline',
    'italic',
    'strike',
    'subscript',
    'superscript',
    'fontColor',
    'hiliteColor',
    'textStyle',
    'removeFormat',
    'outdent',
    'indent',
    'align',
    'horizontalRule',
    'list',
    'lineHeight',
    'table',
    'link',
    'image',
    'fullScreen',
    'showBlocks',
];