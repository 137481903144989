import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  List, message, Button, Popconfirm, Tooltip,
} from 'antd';
import { DownCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Qs from 'qs';

import { LIMIT } from '@globalConstants';
import api from '@services/api';
import { SidebarLayout, PopoverEllipsis } from '@ui';

const ACTIVE_STATUS = 'active';

class SPMapListSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maps: [],
      isLoading: false,
      isDownloading: false,
      isAction: false,
      page: 1,
      count: 0,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { data: { complexId } } = this.props;
    const { page } = this.state;

    this.setState({ isLoading: true });
    const offset = LIMIT * (page - 1);
    const { status, data } = await api.getDocumentTemplates({
      object_id: complexId,
      view: 'COMPLEX_SETPOINT_MAP',
      type: 'protectiondevicecomplex',
      offset,
      limit: LIMIT,
    });
    this.setState({ isLoading: false });

    if (status === 200) {
      this.setState({
        maps: data.results,
        count: data.count,
      });
    }
  };

  onChangePage = (page) => {
    this.setState({ page }, this.loadData);
  };

  onDownloadMap = async (map) => {
    const {
      t, data, socket, isSocketConnected,
    } = this.props;
    if (socket && isSocketConnected) {
      const { complexId, complexName } = data;

      const params = {
        complex_id: complexId,
        id: map.id,
        additional_name: t('DCMAP_EXPORT_ADDITIONAL_NAME'),
      };

      const request = `/v1/document-template/visio/download?${Qs.stringify(params, { arrayFormat: 'repeat' })}`;

      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('DCMAP_EXPORT_1')} "${complexName}" ${t('DCMAP_EXPORT_2')}`,
        url: request,
        accept: 'application/zip',
      }));
    }
  };

  onDownloadTemplate = async (map) => {
    const {
      t, socket, isSocketConnected, data,
    } = this.props;

    if (socket && isSocketConnected) {
      const { complexName } = data;
      const params = {
        additional_name: t('DCMAP_TEMPLATE_EXPORT_ADDITIONAL_NAME'),
      };

      const request = `/v1/document-template/${map}/download/?${Qs.stringify(params, { arrayFormat: 'repeat' })}`;

      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('DCMAP_TEMPLATE_EXPORT_1')} "${complexName}" ${t('DCMAP_TEMPLATE_EXPORT_2')}`,
        url: request,
        accept: 'application/zip',
      }));
    }
  };

  onDeleteMap = async (mapId) => {
    const { t } = this.props;
    const { maps } = this.state;

    this.setState({ isAction: true });
    const result = await api.deleteDCMap(mapId);
    this.setState({ isAction: false });

    if (result.status === 204) {
      this.setState({
        maps: maps.filter((map) => map.id !== mapId),
      });
      message.success(t('DCMAP_DELETE_SUCCESS'));
    } else {
      message.error(t('DCMAP_DELETE_ERROR'));
    }
  };

  onSelectMap = async (mapId) => {
    const { data, t } = this.props;
    const { complexId } = data;
    const { maps } = this.state;

    this.setState({ isAction: true });
    const result = await api.activateDCMap({
      document_template_id: mapId,
      object_id: complexId,
      content_type_name: 'protectiondevicecomplex',
    });
    this.setState({ isAction: false });

    if (result.status === 200) {
      this.setState({
        maps: maps.map((map) => (map.id === mapId ? { ...map, active: true } : { ...map, active: false })),
      });
      message.success(t('DCMAP_ACTIVATE_SUCCESS'));
    } else {
      message.error(t('DCMAP_ACTIVATE_ERROR'));
    }
  };

  onUnSelectMap = async (mapId) => {
    const { t } = this.props;
    const { maps } = this.state;

    this.setState({ isAction: true });

    const result = await api.updateDCMap(mapId, { active: false });
    this.setState({ isAction: false });

    if (result.status === 200) {
      this.setState({
        maps: maps.map((map) => (map.id === mapId ? { ...map, active: false } : map)),
      });
      message.success(t('DCMAP_DEACTIVATE_SUCCESS'));
    } else {
      message.error(t('DCMAP_DEACTIVATE_ERROR'));
    }
  };

  render() {
    const {
      t,
    } = this.props;
    const {
      maps, isLoading, isDownloading, isAction, page, count,
    } = this.state;

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t('DCMAP_SELECT')}</h4>}
      >
        <List
          dataSource={maps}
          loading={isLoading}
          pagination={{
            onChange: this.onChangePage,
            total: count,
            pageSize: LIMIT,
            current: page,
            size: 'small',
            hideOnSinglePage: true,
          }}
          renderItem={(item) => (
            <List.Item
              actions={[
                (
                  <Tooltip title={t('DOWNLOAD_SETPOINT_MAP_TEMPLATE')}>
                    <Button
                      type='link'
                      disabled={isAction}
                      onClick={() => this.onDownloadTemplate(item.id)}
                    >
                      <DownCircleOutlined />
                    </Button>
                  </Tooltip>
                ),
                (
                  <Tooltip title={item[ACTIVE_STATUS] ? t('DEACTIVATE') : t('ACTIVATE')}>
                    <Button
                      type='link'
                      disabled={isAction}
                      onClick={
                        () => (
                          item[ACTIVE_STATUS]
                            ? this.onUnSelectMap(item.id)
                            : this.onSelectMap(item.id)
                        )
                      }
                    >
                      <CheckCircleOutlined style={item[ACTIVE_STATUS] ? { color: 'green' } : { color: 'lightgray' }} />
                    </Button>
                  </Tooltip>
                ),
                (
                  <Tooltip title={t('DELETE')}>
                    <Popconfirm
                      title={t('DELETE_CONFIRM')}
                      disabled={item[ACTIVE_STATUS] || isAction}
                      placement='topRight'
                      onConfirm={() => this.onDeleteMap(item.id)}
                      okText={t('YES')}
                      cancelText={t('NO')}
                    >
                      <CloseCircleOutlined style={item[ACTIVE_STATUS] ? { color: 'lightgray' } : { color: 'red' }} />
                    </Popconfirm>
                  </Tooltip>
                ),
              ]}
            >
              <PopoverEllipsis
                content={item.name}
                popoverPlacement='left'
              >
                <Button
                  key={`map_${item.id}`}
                  type='link'
                  onClick={() => this.onDownloadMap(item)}
                  disabled={isDownloading}
                >
                  {item.name}
                </Button>
              </PopoverEllipsis>
            </List.Item>
          )}
        />
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});

export default connect(mapStateToProps)(
  withTranslation()(
    SPMapListSidebar
  )
);
