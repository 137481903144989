import { GET_TEMPLATES, GET_MORE_TEMPLATES, SAVE_TEMPLATES } from './types';

const initialState = {
  templates: [],
  count: 0,
  isTemplatesLoading: false,
  getTemplatesError: false,
  savedTemplates: {},
  wsData: {},
  savedTemplatesError: [],
  savedTemplatesSuccess: [],
};

function templatesReducer(state = initialState, action) {
  const newSavedTemplates = state.savedTemplates;
  const newSavedTemplatesError = state.savedTemplatesError;
  const newSavedTemplatesSuccess = state.savedTemplatesSuccess;
  const newWsData = state.wsData;

  switch (action.type) {
    case `${GET_TEMPLATES}_START`:
      return {
        ...state,
        isTemplatesLoading: true,
      };
    case `${GET_TEMPLATES}_SUCCESS`:
      return {
        ...state,
        templates: action.data.results,
        count: action.data.count,
        getTemplatesError: false,
        isTemplatesLoading: false,
      };
    case `${GET_TEMPLATES}_ERROR`:
      return {
        ...state,
        getTemplatesError: true,
        isTemplatesLoading: false,
      };
    case `${GET_MORE_TEMPLATES}_START`:
      return {
        ...state,
        isTemplatesLoading: true,
      };
    case `${GET_MORE_TEMPLATES}_SUCCESS`:
      return {
        ...state,
        templates: [...state.templates, ...action.data.results],
        count: action.data.count,
        getTemplatesError: false,
        isTemplatesLoading: false,
      };
    case `${GET_MORE_TEMPLATES}_ERROR`:
      return {
        ...state,
        getTemplatesError: true,
        isTemplatesLoading: false,
      };
    case `${SAVE_TEMPLATES}_START`:
      newSavedTemplates[action.frontId] = action.params;
      return {
        ...state,
        savedTemplates: newSavedTemplates,
      };
    case `${SAVE_TEMPLATES}_ERROR_START`:
      newWsData[action.frontId] = action;
      return {
        ...state,
        wsData: newWsData,
        savedTemplatesError: [
          ...state.savedTemplatesError,
          action.frontId,
        ],
      };
    case `${SAVE_TEMPLATES}_ERROR_END`:
      newSavedTemplatesError.splice(newSavedTemplatesError.indexOf(action.frontId), 1);
      delete newWsData[action.frontId];
      delete newSavedTemplates[action.frontId];
      return {
        ...state,
        savedTemplates: newSavedTemplates,
        wsData: newWsData,
        savedTemplatesError: newSavedTemplatesError,
      };
    case `${SAVE_TEMPLATES}_SUCCESS_START`:
      newWsData[action.frontId] = action;
      return {
        ...state,
        wsData: newWsData,
        savedTemplatesSuccess: [
          ...state.savedTemplatesSuccess,
          action.frontId,
        ],
      };
    case `${SAVE_TEMPLATES}_SUCCESS_END`:
      newSavedTemplatesSuccess.splice(newSavedTemplatesSuccess.indexOf(action.frontId), 1);
      delete newWsData[action.frontId];
      delete newSavedTemplates[action.frontId];
      return {
        ...state,
        savedTemplates: newSavedTemplates,
        wsData: newWsData,
        savedTemplatesSuccess: newSavedTemplatesSuccess,
      };
    default:
      return state;
  }
}

export default templatesReducer;
