import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, Popover } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import store from '@state/store';
import { setAppStage } from '@state/appStage/actions';
import api from '@services/api';
import storage from '@services/storage';
import { AUTH_COOKIE_NAME, APPSTAGE_AUTH, APPSTAGE_SIGNIN } from '@globalConstants';
import { PopoverEllipsis } from '@ui';

import styles from './styles.module.less';
import { setupGlobalSearchFiltersLoading } from '@state/search/actions';
import { preventFocus } from '@globalHelpers';

class UserInfo extends Component {
  state = {
    userInfoVisible: false,
  };

  logout = () => {
    const { setAppStageData, setGlobalFiltersLoading, socket } = this.props;
    const token = undefined;
    storage.set(AUTH_COOKIE_NAME, token);
    api.setAUTHToken(token);
    socket.close();

    store.dispatch(setAppStageData(APPSTAGE_SIGNIN));
    store.dispatch(setGlobalFiltersLoading(true));
  };

  userInfo() {
    const { t, user } = this.props;

    return user && (
      <div className={styles.user}>
        <div className={styles.label}>{t('AUTH_FORM_LABEL_LOGIN')}</div>
        <div className={styles.content}>{user.username || '---'}</div>
        <div className={styles.label}>{t('LAST_NAME')}</div>
        <div className={styles.content}>{user.last_name || '---'}</div>
        <div className={styles.label}>{t('FIRST_NAME')}</div>
        <div className={styles.content}>{user.first_name || '---'}</div>
        <div className={styles.label}>{t('EMAIL')}</div>
        <div className={styles.content}>{user.email || '---'}</div>
        <div className={styles.label}>{t('ROLE')}</div>
        <div className={styles.content}>
          {user.roles.length > 0
            ? user.roles.map((role) => role.name).join(', ')
            : '---'}
        </div>
        <div className={styles.label}>{t('DC')}</div>
        <div className={styles.content}>{user.dispatcher_center ? user.dispatcher_center.name : '---'}</div>
        <div className={styles.label}>{t('DEPARTAMENT')}</div>
        <div className={styles.content}>{user.departament || '---'}</div>
        <Tooltip title={t('EXIT')}>
          <Button onClick={(e) => {
            preventFocus(e);
            this.logout();
          }} icon={<LogoutOutlined />} style={{ width: '100%' }} className='bordered'>{t('EXIT')}</Button>
        </Tooltip>
      </div>
    );
  }

  render() {
    const { userInfoVisible } = this.state;
    const {
      t,
      user,
      name,
      appStage,
    } = this.props;
    const userName = user.first_name || user.last_name ? `${user.first_name || ''} ${user.last_name || ''}` : '–––';

    return (
      <>
        <div className={styles.content}>
          <Popover
            visible={userInfoVisible}
            title={t('USER_INFO')}
            height={350}
            onVisibleChange={(x) => this.setState({ userInfoVisible: x })}
            content={this.userInfo()}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            destroyTooltipOnHide
            overlayClassName={styles.popover}
            placement='bottomRight'
            trigger='click'
          >
            <div className={styles.name}>
              {(() => {
                switch (appStage) {
                  case APPSTAGE_AUTH:
                    return (
                      <>
                        <Button className={styles.cardButton} onClick={(e) => preventFocus(e)}>
                          <PopoverEllipsis content={userName} className={styles.nameContainer}>
                            &nbsp;
                            {userName}
                          </PopoverEllipsis>
                          <div className={styles.role}>
                            {name}
                          </div>
                        </Button>
                      </>
                    );
                  default:
                    return <></>;
                }
              })()}
            </div>
          </Popover>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.user.name,
  user: state.user.info,
  appStage: state.appStage,
  socket: state.websocket.instance,
});

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setGlobalFiltersLoading: setupGlobalSearchFiltersLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    UserInfo
  )
);
