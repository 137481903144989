/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Checkbox, Input, InputNumber, Select, Tooltip, TreeSelect,
} from 'antd';

import { CheckCircleOutlined, CodeOutlined } from '@ant-design/icons';
import { getId, onChangeSelectedFn, renderFunctionsSelect } from '@app/helpers';
import { getStepFromRange, renderRangeFieldTextValue } from '@common/treeValues/renderRangeFields';
import {
  CustomTag,
  PopoverTags,
  PopoverLoadingTags,
  PopoverEllipsis,
  CustomSelect,
} from '@ui';
import styles from './styles.module.less';

const { Option } = Select;

const makeLink = (item, link, text, key) => {
  const linkComponent = <Link to={link(item)}>text</Link>;
  return (
    <Button
      type='link'
      size='small'
      tabIndex={0}
      key={`link_${key}`}
    >
      <PopoverEllipsis
        content={linkComponent}
      >
        {linkComponent}
      </PopoverEllipsis>
    </Button>
  );
};

const getMyNumber = (str) => {
  if (!str) {
    return str;
  }
  const val = str.replace(/[^\d.,-]/g, '').replace(/,/g, '.');
  const val1 = val.search(/^[\d-].*$/) === -1
    ? ''
    : val.substr(0, 1);
  const val2 = val.substr(1).replace(/[-_+]/g, '');
  const idx = val2.indexOf('.');
  if (idx === -1) {
    return val1.concat(val2);
  }
  const val3 = val2.substr(0, idx + 1);
  const val4 = val2.substr(idx).replace(/[.]/g, '');
  return val1.concat(val3, val4);
};

const getEditItemValue = (component, fields, column) => {
  const { editItem } = component.state;

  if (!editItem || JSON.stringify(editItem) === JSON.stringify({})) {
    return null;
  }

  switch (column.type) {
    case 'text':
    case 'number':
    case 'default_value':
    case 'checkBox':
    case 'formula':
      return (fields.length === 1
        ? editItem[fields[0]]
        : (editItem[fields[0]] && editItem[fields[0]][fields[1]]
          ? editItem[fields[0]][fields[1]]
          : ''
        )
      );
    case 'treeSelect':
    case 'select':
      return (fields.length === 1
        ? (editItem[fields[0]]
          ? editItem[fields[0]]['id']
          : ''
        )
        : (editItem[fields[0]] && editItem[fields[0]][fields[1]]
          ? editItem[fields[0]][fields[1]]['id']
          : ''
        )
      );
    default:
      return null;
  }
};

const updateEditItemValue = (component, fields, value) => {
  const { editItem } = component.state;
  if (typeof value === 'undefined' || value === undefined) {
    value = null;
  }
  return {
    ...editItem,
    [fields[0]]: (fields.length === 1
      ? value
      : { ...editItem[fields[0]], [fields[1]]: value }
    ),
  };
};

const renderFields = (
  obj,
  column,
  classPrefix,
  editId,
  editItem,
  columnIndex,
  component,
  translation,
  checkRanges = () => { },
  checkSelector = () => { }
) => {
  let newObj;
  if (!('readOnly' in column)) {
    // eslint-disable-next-line no-param-reassign
    column.readOnly = false;
  }
  const fields = column.field && column.field.split('.');
  let nameOfClass = `${classPrefix}__column-${column.field}`;
  let nameOfKey = `${column.field}`;
  if (column.subField) {
    nameOfClass = nameOfClass.concat(`_${column.subField}`);
    nameOfKey = nameOfKey.concat(`_${column.subField}`);
  }
  if (column.additionalKey) {
    nameOfClass = nameOfClass.concat(`_${column.additionalKey}`);
    nameOfKey = nameOfKey.concat(`_${column.additionalKey}`);
  }
  switch (column.type) {
    case 'text':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (text, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, text, record, index);
          } else if (!column.readOnly && editId && editId === record.id && !record.wasAdd) {
            return (
              <Input
                size='small'
                defaultValue={getEditItemValue(component, fields, column)}
                autoFocus={columnIndex === 0}
                placeholder={column.placeholder}
                onChange={(e) => component.setState({
                  editItem: updateEditItemValue(component, fields, e.target.value),
                })}
              />
            );
          } else {
            if ('link' in column && text) {
              return makeLink(record, column.link, text, `${column.field}_${record.id}`);
            }
            return (
              text && text.length
                ? text
                : translation(column.empty)
            );
          }
        },
      };
      break;
    case 'number':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (text, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, text, record, index);
          } else if (!column.readOnly && editId && editId === record.id && !record.wasAdd && text !== null) {
            return (
              <InputNumber
                className={styles.input}
                size='small'
                min={0}
                defaultValue={getEditItemValue(component, fields, column) || 0}
                autoFocus={columnIndex === 0}
                placeholder={column.placeholder}
                onChange={(e) => component.setState({
                  editItem: updateEditItemValue(component, fields, e),
                })}
              />
            );
          } else return text
        },
      };
      break;
    case 'treeSelect':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (el, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, el, record, index);
          } else if (!column.readOnly && editId && editId === record.id && !record.wasAdd) {
            const itemList = component.state[column.items] || [];

            let optArr = renderFunctionsSelect(
              itemList,
              [],
              getEditItemValue(component, fields, column),
              translation,
              column,
              false,
              column?.allEnabled || false,
              column?.disableParent || false
            );

            if (column.emptyItem) {
              optArr = (
                <TreeSelect.TreeNode key={`${column.field}_option_null`} value={null} title={translation(column.empty)}>
                  {optArr}
                </TreeSelect.TreeNode>
              );
            }

            return (
              <TreeSelect
                size='small'
                treeDefaultExpandAll
                defaultValue={getEditItemValue(component, fields, column)}
                dropdownMatchSelectWidth={false}
                onChange={(selectedFunc) => onChangeSelectedFn(
                  selectedFunc,
                  itemList,
                  (found) => component.setState({
                    editItem: updateEditItemValue(component, fields, found),
                  })
                )}
              >
                {optArr}
              </TreeSelect>
            );
          } else {
            if (el && (el.function || el.name)) {
              const elName = (el.function && el.function.name) || el.name;
              const typeText = elName.replace('-', '_').toUpperCase();
              const result = column.translate
                ? translation(`${column.field.toUpperCase()}_${typeText}`) || null
                : elName;
              if ('link' in column && result) {
                return makeLink(record, column.link, result, `${column.field}_${record.id}`);
              }
              if (result) {
                return result;
              }
            }
            return translation(column.empty);
          }
        },
      };
      break;
    case 'select':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (el, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, el, record, index);
          } else if (!column.readOnly && editId && editId === record.id && !record.wasAdd) {
            const itemList = component.state[column.items] || [];
            const optArr = [];
            if (column.emptyItem) {
              optArr.push(
                <Option key={`${column.field}_option_null`} value={null}>
                  {translation(column.empty)}
                </Option>
              );
            }
            itemList.forEach(
              (x) => {
                const typeText = x.name.replace('-', '_').toUpperCase();
                optArr.push(
                  <Option key={`${column.field}_option_${x.id}`} value={x.id}>
                    {column.translate ? translation(`${column.field.toUpperCase()}_${typeText}`) : x.name}
                  </Option>
                );
              }
            );
            return (
              <CustomSelect
                size='small'
                dropdownMatchSelectWidth={false}
                getPopupContainerDefault
                defaultValue={getEditItemValue(component, fields, column)}
                onChange={(item) => {
                  const selectedItem = itemList.find((x) => x.id === item);
                  component.setState({
                    editItem: updateEditItemValue(component, fields, selectedItem),
                  });
                }}
              >
                {optArr}
              </CustomSelect>
            );
          } else {
            if (el && el.name) {
              const typeText = el.name.replace('-', '_').toUpperCase();
              const result = column.translate
                ? translation(`${column.field.toUpperCase()}_${typeText}`) || null
                : el.name;
              if ('link' in column && result) {
                return makeLink(record, column.link, result, `${column.field}_${record.id}`);
              }
              if (result) {
                return result;
              }
            }
            return translation(column.empty);
          }
        },
      };
      break;
    case 'range':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (arrItem, record, index) => {
          if (!column.readOnly && editId && editId === record.id) {
            return column.colSpan > 0 && 'render' in column
              ? {
                children: column.render(component, column, arrItem, record, index),
                props: {
                  colSpan: column.colSpan,
                },
              }
              : {
                props: {
                  colSpan: 0,
                  className: 'column-hidden',
                },
              };
          } else if (!arrItem) {
            return translation(column.empty);
          } else if (Array.isArray(arrItem)) {
            return arrItem.map((el, elIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${column.field}_${column.subField}_${elIndex}`}>
                {
                  el[column.subField] !== null && el[column.subField] !== ''
                    ? renderRangeFieldTextValue(el[column.subField])
                    : translation(column.empty)
                }
              </div>
            ));
          } else {
            return renderRangeFieldTextValue(arrItem[column.subField]) || translation(column.empty);
          }
        },
      };
      break;
    case 'rangeButtons':
      newObj = {
        ...obj,
        className: editId ? nameOfClass : 'column-hidden',
        key: nameOfKey,
        render: (text, record) => {
          if (column.readOnly || !editId || (editId && editId === record.id)) {
            return {
              props: {
                colSpan: 0,
                className: 'column-hidden',
              },
            };
          } else {
            return {
              props: {
                colSpan: 1,
                className: nameOfClass,
              },
            };
          }
        },
      };
      break;
    case 'selector':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (arrItem, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, arrItem, record, index);
          } else if (!arrItem) {
            return translation(column.empty);
          } else if (Array.isArray(arrItem)) {
            return arrItem.map((el, elIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${column.field}__${elIndex}`}>
                {
                  el !== null
                    ? (
                      <PopoverEllipsis
                        content={el}
                      >
                        {el}
                      </PopoverEllipsis>
                    )
                    : translation(column.empty)
                }
              </div>
            ));
          } else {
            if ('link' in column && arrItem) {
              return makeLink(record, column.link, arrItem, `${column.field}_${record.id}`);
            }
            return (
              arrItem && arrItem.length
                ? arrItem
                : translation(column.empty)
            );
          }
        },
      };
      break;
    case 'default_value':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (text, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, text, record, index);
          }

          const isMultiple = (
            component.state.editItem
            && column
            && column.switchField
            && component.state.editItem[column.switchField]
            && component.state.editItem[column.switchField].name === 'multiple_selector'
          );

          if (
            !column.readOnly
            && editId
            && editId === record.id
            && component.state.editItem[column.switchField]
            && component.state.editItem[column.switchField].name
            && !record.wasAdd
          ) {
            switch (component.state.editItem[column.switchField].name) {
              case 'text':
                return (
                  <input
                    key='default_value--text'
                    type='small'
                    defaultValue={text}
                    placeholder={translation(column.empty)}
                    onChange={(e) => component.setState({
                      editItem: updateEditItemValue(component, fields, e.target.value),
                    })}
                  />
                );
              case 'numeric':
                return (
                  <InputNumber
                    key='default_value--range'
                    step={getStepFromRange(editItem, fields)}
                    size='small'
                    defaultValue={text}
                    placeholder={translation(column.empty)}
                    className={
                      checkRanges(
                        getEditItemValue(component, fields, column),
                        (
                          editItem.range
                          || (editItem.value && editItem.value.range_values)
                          || editItem.range_values
                        )
                      )
                        ? ''
                        : `${column.field}__error`
                    }
                    parser={(e) => getMyNumber(e || e === '0' ? e : null)}
                    onChange={(e) => component.setState({
                      editItem: updateEditItemValue(
                        component,
                        fields,
                        getMyNumber(e || e === 0 ? e.toString() : null)
                      ),
                    })}
                  />
                );
              case 'selector':
              case 'multiple_selector': {
                const optArr = [];
                if (!isMultiple) {
                  optArr.push(
                    <Option key={`${column.field}__option_null`} value={null}>
                      {translation(column.empty)}
                    </Option>
                  );
                }
                editItem.selector.forEach(
                  (x) => {
                    optArr.push(
                      <Option key={`${column.field}__option_${x.value}`} value={x.value}>
                        {x.value}
                      </Option>
                    );
                  }
                );

                return (
                  <CustomSelect
                    key='default_value--selector'
                    size='small'
                    dropdownMatchSelectWidth={false}
                    getPopupContainerDefault
                    defaultValue={isMultiple && !text ? undefined : (text || null)}
                    placeholder={isMultiple ? '' : translation(column.empty)}
                    mode={isMultiple ? 'multiple' : 'default'}
                    className={
                      checkSelector(getEditItemValue(component, fields, column), editItem.selector)
                        ? ''
                        : `${column.field}__error`
                    }
                    onChange={(item) => {
                      component.setState({
                        editItem: updateEditItemValue(component, fields, item),
                      });
                    }}
                  >
                    {optArr}
                  </CustomSelect>
                );
              }
              default:
                return translation(column.empty);
            }
          } else if (text === null || text === undefined) {
            return translation(column.empty);
          } else if (Array.isArray(text)) {
            return text.map((el) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`selector_values__${el}`}>
                {
                  el !== null
                    ? (
                      <PopoverEllipsis
                        content={el}
                      >
                        {el}
                      </PopoverEllipsis>
                    )
                    : translation(column.empty)
                }
              </div>
            ));
          } else {
            if ('link' in column && text) {
              return makeLink(record, column.link, renderRangeFieldTextValue(text), `${column.field}_${record.id}`);
            }
            return renderRangeFieldTextValue(text) || translation(column.empty);
          }
        },
      };
      break;
    case 'tag':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (el, record) => {
          if (!el || !Array.isArray(el) || !('subField' in column)) {
            return translation(column.empty);
          }
          return (
            <PopoverTags size={el.length}>
              {el.map((item) => {
                if (item[column.subField]) {
                  let result = <CustomTag key={item.id}>{item[column.subField]}</CustomTag>;
                  if ('link' in column && result) {
                    result = makeLink(item, column.link, result, `${column.field}_${record.id}_${item.id}`);
                  }
                  return result;
                }
                return undefined;
              })}
            </PopoverTags>
          );
        },
      };
      break;
    case 'loading-tag':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (text) => {
          if (!text) {
            return '';
          }

          const { id, size } = text;

          return (
            <PopoverLoadingTags
              size={size}
              className={column.navigateTo && 'link'}
              loadTags={(name, offset, limit) => column.loadTags(id, { name, offset, limit })}
              navigateTo={column.navigateTo}
            />
          );
        },
      };
      break;
    case 'only_view':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (text, record, index) => {
          let result = text;
          if ('render' in column) {
            result = column.render(component, column, result, record, index);
          } else if (text === null || text === undefined) {
            result = translation(column.empty);
          } else if ('subField' in column) {
            if (Array.isArray(text)) {
              result = text.map((el, elIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${column.field}_${column.subField}_${elIndex}`}>
                  {el[column.subField] !== null && el[column.subField] !== ''
                    ? renderRangeFieldTextValue(el[column.subField])
                    : translation(column.empty)}
                </div>
              ));
            } else {
              result = renderRangeFieldTextValue(text[column.subField]) || translation(column.empty);
            }
          } else if (Array.isArray(text)) {
            result = text.map((el, elIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${column.field}__${elIndex}`}>
                {
                  el !== null
                    ? renderRangeFieldTextValue(el)
                    : translation(column.empty)
                }
              </div>
            ));
          } else {
            result = renderRangeFieldTextValue(result);
          }
          if ('link' in column && result) {
            result = makeLink(record, column.link, result, `${column.field}_${record.id}`);
          }
          return result || translation(column.empty);
        },
      };
      break;
    case 'checkBox':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (value, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, value, record, index);
          } else if (
            (!column.readOnly && editId && editId === record.id && !record.wasAdd)
            || column.showAlwaysAsEdit
          ) {
            return (
              <Checkbox
                key={`checkBox_field_${column.field}_${record.id}`}
                checked={!!value}
                disabled={editId && editId !== record.id}
                onChange={(e) => {
                  if (editId === record.id) {
                    component.setState({
                      editItem: updateEditItemValue(component, fields, e.target.checked),
                    });
                  }
                  if (column.showAlwaysAsEdit) {
                    if (fields && fields.length === 1) {
                      record[fields[0]] = e.target.checked;
                      component
                        && component.props.onChangeTreeValueData
                        && component.props.onChangeTreeValueData(component.state.data, 'batch-update', record);
                    } else {
                      record[fields[0]][fields[1]] = e.target.checked;
                      component
                        && component.props.onChangeTreeValueData
                        && component.props.onChangeTreeValueData(component.state.data, 'batch-update', record);
                    }
                  }
                  component
                    && component.props.onShowButtonsSaveCancel
                    && component.props.onShowButtonsSaveCancel(true);
                }}
              />
            );
          } else {
            return (
              value
                ? <CheckCircleOutlined />
                : ''
            );
          }
        },
      };
      break;
    case 'formula':
      newObj = {
        ...obj,
        dataIndex: fields,
        className: nameOfClass,
        key: nameOfKey,
        render: (value, record, index) => {
          if ('render' in column
            && (column.showByRenderOnView
              || (!column.readOnly && editId && editId === record.id)
            )
          ) {
            return column.render(component, column, value, record, index);
          } else {
            if ((!column.readOnly && editId && editId === record.id && !record.wasAdd)
            || column.showAlwaysAsEdit) {
              const defaultValue = getEditItemValue(component, fields, column);
              
              const onSubmit = (value) => {
                component.setState({
                  editItem: updateEditItemValue(component, fields, value),
                })
              }

              return (
                <Tooltip title='Редактировать формулу'>
                  <Button icon={<CodeOutlined />}
                    onClick={() => component.openEditFormulaModal(defaultValue, onSubmit, true)}
                  />
                </Tooltip>
              );
            } else {
              return <Tooltip title='Просмотреть формулу'>
                <Button icon={<CodeOutlined />} onClick={() => component.openEditFormulaModal(value, () => {}, false)} />
              </Tooltip>
            }
          }
        },
      };
      break;
    default: newObj = { ...obj }
  }
  if (column.width) {
    newObj.width = column.width;
  }
  if (column.minWidth) {
    newObj.minWidth = column.minWidth;
  }
  if (column.maxWidth) {
    newObj.maxWidth = column.maxWidth;
  }
  if (column.fixedWidth) {
    newObj.fixedWidth = column.fixedWidth;
  }
  return newObj;
};

const onAddInnerTableRow = (component, index, list, type, emptyRow) => {
  const newListValue = [
    ...list.slice(0, index + 1),
    {
      key: getId(10),
      ...emptyRow,
    },
    ...list.slice(index + 1),
  ];
  if (type === 'range') {
    component.setState({
      editItem: {
        ...component.state.editItem,
        range: newListValue,
      },
    });
  } else if (type === 'selector') {
    component.setState({
      editItem: {
        ...component.state.editItem,
        selector: newListValue,
      },
    });
  }
};

const onDeleteInnerTableRow = (component, index, list, type) => {
  const { editItem } = component.state;
  if (type === 'range') {
    const newListValue = editItem.range.filter((item, itemIndex) => itemIndex !== index);
    component.setState({
      editItem: {
        ...editItem,
        range: newListValue,
      },
    });
  } else if (type === 'selector') {
    const newListValue = editItem.selector.filter((item, itemIndex) => itemIndex !== index);
    component.setState({
      editItem: {
        ...editItem,
        selector: newListValue,
      },
    });
  }
};

export {
  getMyNumber,
  makeLink,
  renderFields,
  onAddInnerTableRow,
  onDeleteInnerTableRow,
};
