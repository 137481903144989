export const WS_CONNECTION_INIT = 'WS_CONNECTION_INIT';
export const WS_CONNECTION_SUCCESS = 'WS_CONNECTION_SUCCESS';
export const WS_CONNECTION_ERROR = 'WS_CONNECTION_ERROR';
export const WS_CONNECTION_CLOSED = 'WS_CONNECTION_CLOSED';
export const WS_CONNECTION_MESSAGE = 'WS_CONNECTION_MESSAGE';

export const WS_CLEAR_MSG = 'WS_CLEAR_MSG';
export const WS_RUN_ENDPOINT = 'WS_RUN_ENDPOINT';

export type wsCountSubType = {
  count?: number|string;
}

export type wsMessageCountSubType = {
  identity?: string;
  message?: wsCountSubType;
  type?: string;
};

export type wsRunEndpointSubType = {
  front_id?: string;
}

export type wsMessageRunEndpointSubType = {
  identity?: string;
  message?: string;
  type?: string;
  title?: string;
  front_id?: string;
  response?: string | object;
};

type TProperties = {
  downloadImmediately: boolean;
}

export type wsMessageMainSubType = {
  description?: string;
  identity?: string;
  message?: string;
  time?: string;
  type?: string;
  url?: string;
  title?: string;
  properties?: TProperties;
};

export type wsMessageType = wsMessageMainSubType|wsMessageCountSubType|wsMessageRunEndpointSubType;

export interface wsConnectionInitAction {
  type: typeof WS_CONNECTION_INIT;
  instance: WebSocket;
}

export interface wsConnectionAction {
  type: typeof WS_CONNECTION_SUCCESS
    | typeof WS_CONNECTION_ERROR
    | typeof WS_CONNECTION_CLOSED
    | typeof WS_CONNECTION_MESSAGE
    | typeof WS_CLEAR_MSG;
}

export interface wsMessageAction {
  type: typeof WS_CONNECTION_MESSAGE;
  message: wsMessageType;
}

export interface wsRunAction {
  type: string;
  subType: string;
  frontId: string;
  title?: string;
  params?: object;
  message?: wsRunEndpointSubType;
}

export interface websocketState {
  message?: wsMessageType;
  instance?: WebSocket | null;
  connected: boolean;
  runActions: { [key: string]: wsRunAction };
}
