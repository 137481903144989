import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Layout } from '@ui';

import Integrations from './integrations';
import {
  AIP_TAB, SNMP_TAB, LANDOCS, SMTP, AIP_TEST_TAB,
} from './constants';

class IntegrationsRoute extends PureComponent {
  render() {
    const { t, match } = this.props;

    return (
      <Layout
        title={t('SERVICES_TITLE')}
        tabs={[
          { id: AIP_TAB, url: 'aip', name: t('AIP_TITLE') },
          { id: AIP_TEST_TAB, url: 'aip_test', name: t('AIP_TEST_TITLE') },
          { id: SNMP_TAB, url: 'snmp', name: t('SNMP_TITLE') },
          { id: LANDOCS, url: 'landocs', name: t('LANDOCS_TITLE') },
          { id: SMTP, url: 'smtp', name: t('SMTP_TITLE') },
        ]}
        activeTabUrl={(match.params && match.params.tab) || undefined}
        defaultTabUrl='aip'
      >
        <Integrations />
      </Layout>
    );
  }
}

export default withTranslation()(
  withRouter(
    IntegrationsRoute
  )
);
