import React from 'react';
import { Translation } from 'react-i18next';
import { Typography } from 'antd';
import uniqueId from 'lodash/uniqueId';

import styles from './styles.module.less';

const { Text } = Typography;

const Bold = ({ children }) => (
  <span className={styles.bold}>
    {children}
  </span>
);

const getStatus = (status) => {
  if (status && status.status) {
    return `NOTIFICATIONS_${status.status.toUpperCase()}`;
  }
  return 'NOTIFICATIONS_STATUS_NULL';
};

const getDeadlineMessage = (daysLeft, t) => {
  // Если параметр не пришёл, то никакое сообщение выводить не нужно
  if (!daysLeft) return <></>;

  let daysLeftNumber = parseInt(daysLeft, 10);

  if (daysLeftNumber <= 0) {
    return (
      <Text type='danger' className={styles.newline}>
        {'\n'}
        {t('NOTIFICATIONS_DEADLINE_DANGER')}
        {' '}
        {daysLeftNumber}
        {' '}
        {t('NOTIFICATIONS_DAYS')}
      </Text>
    );
  }

  // Если нам приходит этот параметр, то значит в любом случае нужно выводить пользователю сообщение
  daysLeftNumber = Math.abs(daysLeftNumber);
  return (
    <Text type='warning' className={styles.newline}>
      {'\n'}
      {t('NOTIFICATIONS_DEADLINE_WARNING')}
      {' '}
      {daysLeftNumber}
      {' '}
      {t('NOTIFICATIONS_DAYS')}
    </Text>
  );
};

export default (note) => (
  <Translation>
    {
      (t) => {
        let notification = [
          `${t('NOTIFICATIONS_USER')} `,
          <Bold>
            &#39;
            {note.changed_user || 'System'}
            &#39;
          </Bold>,
          ` ${t('NOTIFICATIONS_CHANGE_SETPOINT_STATUS')} `,
          <Bold>
            &#39;
            {note.details.task_dc_setpoint_value && note.details.task_dc_setpoint_value.name}
            &#39;
          </Bold>,
          ` ${t('NOTIFICATIONS_TASK_DC')} `,
          <Bold>
            &#39;
            {note.details.task_dc && note.details.task_dc.name}
            &#39;
          </Bold>,
          ` ${t('NOTIFICATIONS_ON')} `,
          <Bold>
            &#39;
            {t(getStatus(note.details.task_dc_setpoint_value))}
            &#39;
          </Bold>,
          ` ${t('NOTIFICATIONS_WITH_VALUE')} `,
          <Bold>
            &#39;
            {note.details.task_dc_setpoint_value && note.details.task_dc_setpoint_value.value}
            &#39;
          </Bold>,
        ];

        if (note.details.task_dc_setpoint_value && note.details.task_dc_setpoint_value.group) {
          notification.push(` ${t('NOTIFICATIONS_IN_GROUP')} `);
          notification.push(
            <Bold>
              &#39;
              {note.details.task_dc_setpoint_value.group}
              &#39;
            </Bold>
          );
        }

        if (note.details.task_dc_setpoint_value && note.details.task_dc_setpoint_value.id === null) {
          if (note.details.task_dc && note.details.task_dc.is_deleted) {
            notification = [
              `${t('NOTIFICATIONS_USER')} `,
              <Bold>
                &#39;
                {note.changed_user || 'System'}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_DELETE_TASK')} `,
              <Bold>
                &#39;
                {note.details.task_dc.name}
                &#39;
              </Bold>,
            ];
          } else if (note.details.task_dc && note.details.task_dc.status === 'planned') {
            notification = [
              `${t('NOTIFICATIONS_USER')} `,
              <Bold>
                &#39;
                {note.changed_user || 'System'}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_PLAN_NEW')} `,
              <Bold>
                &#39;
                {note.details.task_dc.name}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_ON')} `,
              <Bold>
                {note.details.task_dc.date_planned_start}
              </Bold>,
            ];
          } else if (note.details.task_dc && note.details.task_dc.status === 'new') {
            notification = [
              `${t('NOTIFICATIONS_USER')} `,
              <Bold>
                &#39;
                {note.changed_user || 'System'}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_CREATE_NEW')} `,
              <Bold>
                &#39;
                {note.details.task_dc.name}
                &#39;
              </Bold>,
            ];
          } else if (note.details.task_dc && note.details.task_dc.status === 'is_issued') {
            notification = [
              `${t('NOTIFICATIONS_USER')} `,
              <Bold>
                &#39;
                {note.changed_user || 'SYSTEM'}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_SEND_NEW')} `,
              <Bold>
                &#39;
                {note.details.task_dc.name}
                &#39;
              </Bold>,
            ];
          } else {
            notification = [
              `${t('NOTIFICATIONS_USER')} `,
              <Bold>
                &#39;
                {note.changed_user || 'System'}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_CHANGE_STATUS')} `,
              <Bold>
                &#39;
                {note.details.task_dc.name}
                &#39;
              </Bold>,
              ` ${t('NOTIFICATIONS_ON')} `,
              <Bold>
                &#39;
                {t(getStatus(note.details.task_dc))}
                &#39;
              </Bold>,
            ];
          }
        }

        notification.push(getDeadlineMessage(note.days_left, t));

        return <>{notification.map((item) => <span key={uniqueId()}>{item}</span>)}</>;
      }
    }
  </Translation>
);
