/* eslint-disable no-param-reassign */
import * as React from 'react';
import {
  Button, InputNumber, Popconfirm, Table,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import { RANGE_MAX_VALUE, TABLE_SIZE } from '@app/constants';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { getId } from '@app/helpers';
import { PopoverEllipsis } from '@ui';
import { getMyNumber, onAddInnerTableRow, onDeleteInnerTableRow } from './renderFields';

const emptyRangeValues = {
  step: 1,
  min_value: 0,
  max_value: RANGE_MAX_VALUE,
};

const checkDefaultValueIntoRanges = (defaultValue, rangeArr) => {
  function isValueInRange(value, range) {
    return value >= range.min_value && value <= range.max_value;
  }

  function filterNullableValues(obj) {
    const newObj = {};

    for (const key in obj) {
      if (obj[key] !== null) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  function checkObjectRange(obj, ranges) {
    for (const key in obj) {
      const value = obj[key];

      if (Array.isArray(ranges) && !ranges.every((range) => isValueInRange(value, range))) {
        return false;
      }
    }
    return true;
  }

  function validateObjectRange(defaultValue, rangeArr) {
    if (typeof defaultValue === 'object') {
      const filteredObj = filterNullableValues(defaultValue);
      return checkObjectRange(filteredObj, rangeArr);
    }
    return false;
  }

  const isValidRange = validateObjectRange(defaultValue, rangeArr);
  const valueNumber = Number.parseFloat(defaultValue);

  if (!rangeArr) {
    // eslint-disable-next-line no-console
    console.error('Error: range - undefined (checkDefaultValueIntoRanges)');
  }

  if (JSON.stringify(rangeArr) === JSON.stringify({})) {
    return true;
  }

  if (!Array.isArray(rangeArr)) {
    const newRange = [];
    newRange.push(rangeArr);
    rangeArr = newRange;
  }

  return (
    isValidRange ||
    typeof defaultValue === 'undefined'
    || defaultValue === null
    || (
      (valueNumber === defaultValue
        || valueNumber.toString() === defaultValue
        || valueNumber.toString().concat('.0') === defaultValue
      )
      && rangeArr.reduce((result, item) => {
        const checkMinValue = item.min_value || item.min_value === 0
          ? item.min_value <= valueNumber
          : (-RANGE_MAX_VALUE) <= valueNumber;
        const checkMaxValue = item.max_value || item.max_value === 0
          ? valueNumber <= item.max_value
          : valueNumber <= RANGE_MAX_VALUE;
        const checkValue = checkMinValue && checkMaxValue;
        return result || checkValue;
      }, false)
    )
  );
};

const renderRangeFieldTextValue = (value, withPopoverEllipse = true) => {
  return (
    (
      (typeof value === 'number' && RANGE_MAX_VALUE === value)
      || (typeof value === 'string' && typeof parseFloat(value) === 'number' && RANGE_MAX_VALUE === parseFloat(value))
    )
      // ? 'ꝏ'
      ? (
        <svg
          viewBox='0 0 512 512'
          xmlns='http://www.w3.org/2000/svg'
          style={{ width: '16px', height: '16px', verticalAlign: 'middle' }}
        >
          <path
            fill="currentColor"
            d="M463.1953,176.8047a111.9968,111.9968,0,0,0-158.3906,0l-48.5693,48.5679L207.667,176.8037a112,112,0,1,0,0,158.3926l48.5684-48.5693,48.5693,48.5693A111.9994,111.9994,0,1,0,463.1953,176.8047ZM185.04,312.5693a80,80,0,1,1,0-113.1386l55.1954,55.1963v2.746Zm255.5284,0a80.0015,80.0015,0,0,1-113.1368,0l-55.1962-55.1972v-2.7442l55.1962-55.1963A80,80,0,0,1,440.5684,312.5693Z"
          />
        </svg>
      )
      : (
        withPopoverEllipse
          ? (
            <PopoverEllipsis
              content={value}
            >
              {value}
            </PopoverEllipsis>
          )
          : value
      )
  );
};

const getStep = (rangeArr) => (rangeArr && rangeArr.step) || 1;

const getStepFromRange = (record, fields, groupNumber = null) => {
  const rangeArr = (
    record
    && (
      record.range
      || (record && record.value && record.value.range_values)
      || record.range_values
    )
  );
  if (record && fields && rangeArr) {
    if (Array.isArray(rangeArr) && rangeArr.length > 1) {
      const rangeValues = rangeArr.find((el) => {
        const minValue = el.min_value !== null && el.min_value !== undefined ? el.min_value : -RANGE_MAX_VALUE;
        const maxValue = el.max_value !== null && el.max_value !== undefined ? el.max_value : RANGE_MAX_VALUE;
        let curValue = (
          fields.length === 1
            ? record[fields[0]]
            : record[fields[0]][fields[1]]
        );
        if (groupNumber && curValue) {
          curValue = curValue[groupNumber];
        }
        if (curValue === undefined) {
          curValue = null;
        }
        return curValue && minValue <= curValue && curValue <= maxValue;
      });
      return getStep(rangeValues);
    } else if (Array.isArray(rangeArr)) {
      return getStep(rangeArr[0]);
    } else {
      return getStep(rangeArr);
    }
  } else {
    return 1;
  }
};

const renderRange = (component, mode) => {
  const { t } = component.props;
  const { editItem } = component.state;
  const editRange = editItem.range;
  if (editItem && editItem.setpoint_type && editItem.setpoint_type.name === 'numeric') {
    const columns = [
      {
        title: <div>{t('TABLE_COL_RANGE_VALUES_STEP')}</div>,
        dataIndex: 'step',
        className: 'template-tree-value__range-step',
        key: 'range-step',
        render: (text, record, index) => (
          <InputNumber
            disabled={mode}
            key='range_input-step'
            min={0.0000000001}
            step={10 ** ((record.step && record.step.toExponential().match(/[+-]\d+/)[0]) || -2)}
            size='small'
            // defaultValue={text || localeMessage("WITHOUT_NAME"}
            defaultValue={text}
            placeholder={t('TABLE_COL_RANGE_VALUES_STEP')}
            parser={(e) => getMyNumber(e || e === '0' ? e : null)}
            onBlur={(e) => {
              const newValue = editRange.map((el, elIndex) => {
                if (elIndex === index) {
                  // eslint-disable-next-line no-param-reassign
                  el.step = parseFloat(e.target.value);
                }
                return el;
              });
              component.setState({
                editItem: {
                  ...editItem,
                  range: newValue,
                },
              });
            }}
          />
        ),
      },
      {
        title: <div>{t('TABLE_COL_RANGE_VALUES_MIN')}</div>,
        dataIndex: 'min_value',
        className: 'template-tree-value__range-min_value',
        key: 'range-min_value',
        render: (text, record, index) => (
          <InputNumber
            disabled={mode}
            key='range_input-min_value'
            step={record.step}
            size='small'
            // defaultValue={text || localeMessage("WITHOUT_NAME"}
            defaultValue={text}
            placeholder={t('TABLE_COL_RANGE_VALUES_MIN')}
            parser={(e) => getMyNumber(e || e === '0' ? e : null)}
            onBlur={(e) => {
              const newValue = editRange.map((el, elIndex) => {
                if (elIndex === index) {
                  // eslint-disable-next-line no-param-reassign
                  el.min_value = parseFloat(e.target.value);
                }
                return el;
              });
              component.setState({
                editItem: {
                  ...editItem,
                  range: newValue,
                },
              });
            }}
          />
        ),
      },
      {
        title: <div>{t('TABLE_COL_RANGE_VALUES_MAX')}</div>,
        dataIndex: 'max_value',
        className: 'template-tree-value__range-max_value',
        key: 'range-max_value',
        render: (text, record, index) => (
          <InputNumber
            disabled={mode}
            key='range_input-max_value'
            step={record.step}
            size='small'
            // defaultValue={text || localeMessage("WITHOUT_NAME"}
            defaultValue={text}
            placeholder={t('TABLE_COL_RANGE_VALUES_MAX')}
            parser={(e) => getMyNumber(e || e === '0' ? e : null)}
            onBlur={(e) => {
              const newValue = editRange && editRange.map((el, elIndex) => {
                if (elIndex === index) {
                  // eslint-disable-next-line no-param-reassign
                  el.max_value = parseFloat(e.target.value);
                }
                return el;
              });
              component.setState({
                editItem: {
                  ...editItem,
                  range: newValue,
                },
              });
            }}
          />
        ),
      },
      {
        className: 'template-tree-value__range-fix',
        key: 'range-fix',
        render: (text, record, index) => (
          <div>
            <Button
              size="small"
              icon={<PlusOutlined />}
              onClick={() =>
                onAddInnerTableRow(component, index, editRange || [], 'range', {
                  key: getId(10),
                  ...emptyRangeValues,
                })
              }
            />
            {editRange && editRange.length && editRange.length > 1 ? (
              <Popconfirm
                title={t('DELETE_ROW_CONFIRM')}
                onConfirm={() => onDeleteInnerTableRow(component, index, editRange, 'range')}
                okText={t('YES')}
                cancelText={t('NO')}
                disabled={
                  index === 0 &&
                  record.min_value === null &&
                  record.max_value === null &&
                  record.step === null
                }
              >
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  className="disabled-button"
                  disabled={
                    index === 0 &&
                    record.min_value === null &&
                    record.max_value === null &&
                    record.step === null
                  }
                />
              </Popconfirm>
            ) : (
              ''
            )}
          </div>
        ),
      },
    ];

    if (mode) columns.pop();

    return (
      <Table
        size={TABLE_SIZE}
        kay='table_edit-range_values'
        className='edit-range_values'
        columns={columns}
        dataSource={editRange}
        rowKey='key'
        showHeader={false}
        bordered={false}
        pagination={false}
      />
    );
  } else {
    return t('TREE_VALUES_WITHOUT_VALUE');
  }
};

const getRangeForEdit = (item) => {
  let rangeArr = [{ key: getId(8), ...emptyRangeValues }];
  if (item) {
    const newItem = cloneDeep(item);
    if (Array.isArray(newItem) && newItem.length) {
      rangeArr = newItem.map((el) => {
        return { key: getId(10), ...el };
      });
    } else {
      rangeArr = [{ key: getId(10), ...newItem }];
    }
  }
  return rangeArr;
};

export {
  emptyRangeValues,
  checkDefaultValueIntoRanges,
  renderRangeFieldTextValue,
  getStepFromRange,
  renderRange,
  getRangeForEdit,
};
