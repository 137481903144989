import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isEqual, isEmpty } from 'lodash';
import moment from 'moment';

import { Input, Button, message, Form, DatePicker, Spin, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { DOC_SIDEBAR_DOC } from '@common/documentsUploadSidebar/constants';
import { DOCUMENTS_UPLOAD, EVENTS_FORM } from '@common/sidebarRoot/types';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { RootState } from '@state/store';
import api from '@services/api';
import { DATE_FORMAT } from '@globalConstants';

import { SidebarLayout } from '@ui';

import NextStep from './NextStep';
import styles from '../styles.module.less';

import { Item, IncomingItem } from './types';

const { TextArea } = Input;

type Data = {
  eventItem: IncomingItem;
  selectedEvent: string | boolean;
  isEditMode: boolean;
  mainCallback: Function;
  isNextStep: boolean;
  callback: Function;
};

interface Props extends WithTranslation {
  data: Data;
  openSb: Function;
  closeSb: Function;
  isSidebarOpen: boolean;
}

interface State {
  isDocDeleted: boolean;
  isExecDeleted: boolean;
  isAddDoc: boolean;
  isSending: boolean;
  isDeleting: boolean;
  eventItem?: IncomingItem;
}

class EventsFormSidebar extends Component<Props, State> {
  private form = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isDocDeleted: false,
      isExecDeleted: false,
      isAddDoc: false,
      isSending: false,
      isDeleting: false,
      eventItem: undefined,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { eventItem } = data;

    if (eventItem && this.form.current) {
      this.form.current.setFieldsValue({
        ...eventItem,
        date_planned_completion: eventItem.date_planned_completion && moment(eventItem.date_planned_completion),
        implementation_year: eventItem.implementation_year && moment(eventItem.implementation_year, 'YYYY'),
        voltage_level: eventItem.voltage_level && eventItem.voltage_level.id,
        substation: eventItem.substation && eventItem.substation.id,
        subject: eventItem.subject && eventItem.subject.id,
      });
      this.setState({
        eventItem,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { data } = this.props;
    const { eventItem } = data;
    const { eventItem: prevEventItem } = prevProps.data;

    if (this && eventItem && prevEventItem && !isEqual(eventItem, prevEventItem)) {
      this.setState({
        eventItem,
      });
    }
  }

  prepareData = async (values: Store) => {
    const { date_planned_completion, implementation_year, ...restValues } = values;

    return {
      ...restValues,
      date_planned_completion: date_planned_completion && date_planned_completion.format(DATE_FORMAT),
      implementation_year: implementation_year && implementation_year.format('YYYY'),
    };
  };

  onCreateEvent = async () => {
    const { t, data } = this.props;
    const { isAddDoc } = this.state;
    const { mainCallback } = data;

    this.form.current &&
      this.form.current
        .validateFields()
        .then(async (values: Store) => {
          this.setState({ isSending: true });
          const dataToSend = await this.prepareData(values);
          const response = await api.addEvents(dataToSend);

          if (response.status === 201) {
            this.setState(
              {
                eventItem: response.data,
              },
              () => {
                if (isAddDoc) {
                  this.openDocForm();
                } else {
                  this.openAdditionalData();
                }

                mainCallback(response?.data?.id);
              }
            );

            message.success(t('EVENT_CREATE_SUCCESS'));
          } else {
            message.error(t('EVENT_CREATE_ERROR'));
          }

          this.setState({ isSending: false });
        })
        .catch(() => {
          message.error(t('EVENT_CREATE_VALIDATE_ERROR'), 4);
        });
  };

  onUpdateEvent = async () => {
    const { data, t, closeSb } = this.props;
    const { isAddDoc, eventItem } = this.state;
    const { isNextStep, callback, mainCallback } = data;

    this.form.current &&
      eventItem &&
      eventItem.id &&
      this.form.current.validateFields().then(async (values: Store) => {
        this.setState({ isSending: true });
        const dataToSend = await this.prepareData(values);
        const response = await api.updateEvents(eventItem.id, dataToSend);

        if (response.status === 200) {
          const newEventItem = await mainCallback(eventItem.id);
          this.setState(
            {
              isSending: false,
              eventItem: newEventItem,
            },
            () => {
              if (isNextStep) {
                isAddDoc && this.openExecForm();
                !isAddDoc && callback() && closeSb();
              } else {
                isAddDoc && this.openDocForm();
                !isAddDoc && this.openAdditionalData();
              }
              message.success(t('EVENT_UPDATE_SUCCESS'));
            }
          );
        } else {
          this.setState({ isSending: false });
          message.error(t('EVENT_UPDATE_ERROR'));
        }
      });
  };

  loadDocument = async (id?: string) => {
    if (!id) {
      return undefined;
    }

    const { data, status } = await api.getDocumentByIdV2(id);

    if (status === 200) {
      return data;
    }

    return undefined;
  };

  openDocForm = async () => {
    const { isSidebarOpen, openSb, closeSb, data, t } = this.props;
    const { eventItem } = this.state;
    const { mainCallback, isEditMode } = data;

    const header =
      eventItem && eventItem.documentary_documents && eventItem.documentary_documents.length
        ? t('UPDATE_DOCUMENTARY_DOC')
        : t('NEW_DOCUMENTARY_DOC');
    const document = await this.loadDocument(
      eventItem?.documentary_documents && eventItem?.documentary_documents[0]?.id
    );

    isSidebarOpen && closeSb();

    openSb(DOCUMENTS_UPLOAD, {
      type: DOC_SIDEBAR_DOC,
      header,
      isEdit: document && isEditMode,
      document: isEditMode && document,
      callback: async (doc: Item) => {
        if (eventItem) {
          if (eventItem?.documentary_documents?.length) {
            await api.removeDocumentaryFromEvents(eventItem.id, {
              items: eventItem.documentary_documents.map(({ id }) => id),
            });
          }
          await api.addDocumentaryToEvents(eventItem.id, {
            items: [doc.id],
          });

          eventItem.documentary_documents = [doc];
          mainCallback(eventItem?.id);
          this.openAdditionalData();
        }
      },
    });
  };

  openAdditionalData = () => {
    const { isSidebarOpen, openSb, closeSb, data } = this.props;
    const { eventItem } = this.state;

    isSidebarOpen && closeSb();

    openSb(EVENTS_FORM, {
      isNextStep: true,
      eventItem,
      mainCallback: data.mainCallback,
      selectedEvent: eventItem,
      isEditMode: data.isEditMode,
      callback: async () => {
        data.mainCallback(eventItem?.id);
      },
    });
  };

  openExecForm = async () => {
    const { isSidebarOpen, openSb, closeSb, data, t } = this.props;
    const { eventItem } = this.state;
    const { isEditMode, mainCallback } = data;

    const header = eventItem ? t('UPDATE_EXECUTIVE_DOC') : t('NEW_EXECUTIVE_DOC');
    const document = await this.loadDocument(
      eventItem?.executive_program_documents && eventItem?.executive_program_documents[0]?.id
    );

    isSidebarOpen && closeSb();

    openSb(
      DOCUMENTS_UPLOAD,
      {
        type: DOC_SIDEBAR_DOC,
        header,
        isEdit: document && isEditMode,
        document: isEditMode && document,
        callback: async (doc: Item) => {
          if (eventItem?.executive_program_documents?.length) {
            await api.removeExecutiveDocFromEvents(eventItem?.id, {
              items: eventItem.executive_program_documents.map((d: Item) => d.id),
            });
          }
          await api.addExecutiveDocToEvents(eventItem?.id, {
            items: [doc.id],
          });

          mainCallback(eventItem?.id);
          closeSb();
        },
      },
      700
    );
  };

  onAddDoc = (event: CheckboxChangeEvent) => {
    const { target } = event;

    this.setState({
      isAddDoc: target.checked,
    });
  };

  onDeleteDoc = async () => {
    const { data } = this.props;
    const { eventItem } = this.state;
    const { isNextStep, isEditMode, mainCallback } = data;

    if (!isEditMode || !eventItem) {
      return undefined;
    }

    this.setState({ isDeleting: true });

    if (!isNextStep && !isEmpty(eventItem.documentary_documents)) {
      await api.removeDocumentaryFromEvents(eventItem.id, {
        items: eventItem.documentary_documents.map(({ id }) => id),
      });

      this.setState({
        isDocDeleted: true,
        eventItem: {
          ...eventItem,
          documentary_documents: [],
        },
      });
      mainCallback(eventItem?.id);
    }

    if (isNextStep && !isEmpty(eventItem.executive_program_documents)) {
      await api.removeExecutiveDocFromEvents(eventItem.id, {
        items: eventItem.executive_program_documents.map(({ id }) => id),
      });

      this.setState({
        isExecDeleted: true,
        eventItem: {
          ...eventItem,
          executive_program_documents: [],
        },
      });
      mainCallback(eventItem?.id);
    }

    this.setState({ isDeleting: false });

    return undefined;
  };

  render() {
    const { data, t } = this.props;
    const { selectedEvent, isNextStep, eventItem, isEditMode } = data;
    const { isSending, isAddDoc, isDeleting, isDocDeleted, isExecDeleted } = this.state;

    return (
      <SidebarLayout
        withoutCard
        className={styles.eventsForm}
        header={<h4>{selectedEvent ? t('EVENTS_EDIT') : t('EVENTS_ADD')}</h4>}
        footer={
          <Button
            block
            type='primary'
            loading={isSending}
            disabled={isSending}
            onClick={selectedEvent ? this.onUpdateEvent : this.onCreateEvent}
          >
            {selectedEvent && t('EVENTS_EDIT_ACTION')}
            {!selectedEvent && t('EVENTS_ADD')}
          </Button>
        }
      >
        <Spin spinning={!!isSending}>
          <Form ref={this.form} name='eventsForm'>
            {!isNextStep && (
              <>
                <Form.Item
                  label={t('EVENTS_NAME')}
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('EVENTS_NAME_REQUIRED'),
                    },
                  ]}
                >
                  <Input placeholder={t('EVENTS_NAME')} />
                </Form.Item>
                <Form.Item label={t('EVENTS_NOTE')} name='documentary_note'>
                  <TextArea placeholder={t('EVENTS_NOTE')} />
                </Form.Item>
                <Form.Item label={t('EVENTS_RISKS')} name='risks_default'>
                  <TextArea placeholder={t('EVENTS_RISKS')} />
                </Form.Item>
                <Form.Item label={t('EVENTS_COMPLETION')} name='date_planned_completion'>
                  <DatePicker className={styles.field} placeholder={t('EVENTS_COMPLETION')} />
                </Form.Item>
              </>
            )}
            {isNextStep && (
              <>
                <NextStep eventItem={eventItem} />
                <Form.Item label={t('EVENTS_IMPLEMENTATION_YEAR')} name='implementation_year'>
                  <DatePicker className={styles.field} placeholder={t('EVENTS_IMPLEMENTATION_YEAR')} picker='year' />
                </Form.Item>
              </>
            )}
          </Form>
          <Checkbox checked={isAddDoc} onChange={this.onAddDoc}>
            {!isNextStep && t('UPDATE_DOCUMENTARY_DOC')}
            {isNextStep && t('UPDATE_EXECUTIVE_DOC_LINK')}
          </Checkbox>
          {isEditMode &&
            ((!isNextStep && !isEmpty(eventItem.documentary_documents) && !isDocDeleted) ||
              (isNextStep && !isEmpty(eventItem.executive_program_documents) && !isExecDeleted)) && (
              <Button
                className={styles.deleteButton}
                onClick={this.onDeleteDoc}
                type='primary'
                size='small'
                loading={isDeleting}
                disabled={isDeleting || isAddDoc}
              >
                {t('DELETE_DOCUMENT')}
              </Button>
            )}
        </Spin>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isSidebarOpen: state.sidebar.isOpen,
});

const mapDispatchToProps = {
  openSb: openSidebar,
  closeSb: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EventsFormSidebar));
