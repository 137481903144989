import React, { Component } from 'react';
import { Popover } from 'antd';

import styles from './styles.module.less';

class PopoverEllipsis extends Component {
  container = {};

  state = {
    visible: false,
  };

  componentDidMount() {
    document.addEventListener('closePopover', this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener('closePopover', this.closePopover);
  }

  closePopover = () => {
    const { visible } = this.state;

    if (visible) {
      this.setState({
        visible: false,
      });
    }
  }

  handleVisibleChange = (visible) => {
    if (this.container.clientWidth < this.container.scrollWidth) {
      this.setState({
        visible: visible,
      });
    }
  };

  render() {
    const {
      children, content, containerStyles, containerClass, popoverPlacement = 'topLeft', ...restProps
    } = this.props;
    const { visible } = this.state;

    const popoverProps = {
      placement: `${popoverPlacement}`,
      ...restProps,
    };

    const divContent = (
      <div
        role='none'
        onClick={() => this.handleVisibleChange(false)}
      >
        {content}
      </div>
    );
    return (
      <Popover
        {...popoverProps}
        content={divContent}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        overlayClassName={`${styles.popover} popover-ellipsis`}
        mouseEnterDelay={0.5}
      >
        <div
          style={containerStyles}
          ref={
            (node) => {
              if (node) this.container = node;
              return this.container;
            }
          }
          className={`${styles.container} ${containerClass || ''}`}
        >
          {children}
        </div>
      </Popover>
    );
  }
}

export default PopoverEllipsis;
