import React, { Component } from 'react';
import { Row, Col, Input } from 'antd';
import { withTranslation } from 'react-i18next';

import styles from './styles.module.less';

class FilterRange extends Component {
  onValueChange() {
    const { onChange } = this.props;

    onChange();
  }

  render() {
    const { t } = this.props;

    return (
      <Row gutter={40}>
        <Col span={9} offset={2} className={styles.range}>
          <div className={styles.box}>{t('FROM')}</div>
          <Input onChange={() => this.onValueChange()} />
        </Col>
        <Col span={9} className={styles.range}>
          <div className={styles.box}>{t('TO')}</div>
          <Input onChange={() => this.onValueChange()} />
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(FilterRange);
