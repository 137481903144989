import {
  createStore, applyMiddleware, combineReducers, Action,
} from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import storage from '@services/storage';

import appStageReducer from './appStage/reducer';
import userReducer from './user/reducer';
import sidebarReducer from './sidebar/reducer';
import templatesReducer from './templates/reducer';
import blanksReducer from './blanks/reducer';
import bucketReducer from './bucket/reducer';
import notificationsReducer from './notifications/reducer';
import rightsReducer from './rights/reducer';
import searchReducer from './search/reducer';
import settingsReducer from './settings/reducer';
import journalReducer from './journal/reducer';
import websocketReducer from './websocket/reducer';
import archivedReducer from './archived/reducer';
import maintenanceAlertReducer from './maintenanceAlert/reducers';

let enhancer = applyMiddleware(thunk);
const reducer = combineReducers({
  user: userReducer,
  rights: rightsReducer,
  appStage: appStageReducer,
  sidebar: sidebarReducer,
  bucket: bucketReducer,
  templates: templatesReducer,
  blanks: blanksReducer,
  notifications: notificationsReducer,
  search: searchReducer,
  settings: settingsReducer,
  journal: journalReducer,
  websocket: websocketReducer,
  archived: archivedReducer,
  maintentanceAlert: maintenanceAlertReducer
});

export type RootState = ReturnType<typeof reducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// https://github.com/zalmoxisus/redux-devtools-extension
if (process.env.NODE_ENV === 'development') {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  enhancer = composeEnhancers(enhancer);
}

const preload = {
  journal: storage.get('journal'),
};

const store = createStore(reducer, preload, enhancer);

store.subscribe(() => {
  storage.set('journal', store.getState().journal);
});

export default store;
