import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, message, Popconfirm } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import api from '@services/api';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { COMPLEXES_FORM } from '@common/sidebarRoot/types';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { TABLE_SIZE, RIGHT_ROUTE_CLASSIFIERS, MODE_EDIT } from '@globalConstants';
import { textHighlighter } from '@globalHelpers';
import { CustomCard, PopoverLoadingTags } from '@ui';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import styles from './styles.module.less';

class Complexes extends Component {
  tableName = 'classificators_tab__complexes';

  state = {
    count: 0,
    complexes: [],
  };

  componentDidMount() {
    const { setTableName } = this.props;
    setTableName(this.tableName, () => this.loadComplexes());
  }

  componentDidUpdate(prevProps) {
    const {
      selectedItem, isSidebarOpen, archived, changePage,
    } = this.props;
    const { sidebarType } = this.state;

    if (prevProps.selectedItem !== selectedItem && isSidebarOpen) {
      this.openComplexForm(sidebarType);
    }

    if (prevProps.archived !== archived) {
      changePage(1, () => this.loadComplexes());
    }
  }

  componentWillUnmount() {
    const { isSidebarOpen, closeComplexesForm } = this.props;

    if (isSidebarOpen) closeComplexesForm();
  }

  loadComplexes = async (addParams = {}, callback) => {
    const { loadByPage, filterValues } = this.props;
    const params = {
      is_count: true,
    };
    const requestParams = { ...params, ...filterValues, ...addParams };

    this.setState({ isLoading: true });
    await loadByPage(this.tableName, 'getDevicesComplexes', requestParams, 200, (status, data) => {
      this.setState({
        complexes: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });
    });
    this.setState({ isLoading: false });
  };

  openComplexForm = async (isEditMode = false) => {
    const {
      isSidebarOpen, openComplexesForm, closeComplexesForm, selectedItem, changePage, onSelectItem,
    } = this.props;
    const { complexes, sidebarType } = this.state;
    let complex;

    if (isSidebarOpen && sidebarType === isEditMode) {
      await closeComplexesForm();
      this.setState({ sidebarType: '' });
    }

    if (isEditMode) {
      complex = complexes.find((item) => item.id === selectedItem.id);
    }

    openComplexesForm(
      COMPLEXES_FORM,
      {
        complex,
        callback: async (data) => {
          closeComplexesForm();

          changePage(1, async () => {
            await this.loadComplexes({ selected: data.id }, () => {
              const { complexes: updatedComplexes } = this.state;
              onSelectItem(updatedComplexes[0]);
            });
          });
        },
      }
    );

    this.setState({ sidebarType: isEditMode });
  };

  deleteComplex = async () => {
    const { t, selectedItem, onSelectItem } = this.props;

    if (!selectedItem.id) {
      return;
    }

    this.setState({ isDeleting: true });
    const { status } = await api.deleteComplex(selectedItem.id);
    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));
      this.loadComplexes();
      onSelectItem({});
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      t, history, changePage, onShowSizeChange, limit, page, onSelectItem, selectedItem,
      getColumnSearchFilter, filterValues, rights,
    } = this.props;
    const {
      isLoading, complexes, count, isDeleting,
    } = this.state;
    const isEditRight = rights[RIGHT_ROUTE_CLASSIFIERS] === MODE_EDIT;
    const taggedComplexes = complexes.map((x) => ({
      ...x,
      protection_devices: { id: x.id, size: x.protection_devices },
    }));

    const columns = [
      {
        title: t('CLASSIFICATORS_COMPLEXES_NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
        render: (text) => (text ? textHighlighter(filterValues.name, text) : ''),
      },
      {
        title: t('TABLE_COL_LINK_DEVICES'),
        dataIndex: 'protection_devices',
        key: 'protection_devices',
        width: '50%',
        render: ({ id, size }) => {
          return (
            <PopoverLoadingTags
              size={size}
              className='link'
              loadTags={async (name, offset, limitQuery) => {
                const result = await api.getProtectionDeviceComplexProtectionDevices(id, {
                  name: name,
                  offset,
                  limit: limitQuery,
                  noChangePage: true,
                });
                if (result.status === 200 && result.data.results[0]) {
                  return result.data.results;
                } else {
                  return [];
                }
              }}
              navigateTo={(deviceId) => history.push(`/passport/${deviceId}`)}
            />
          );
        },
      },
    ];

    let sDeleteConfirm = `${t('COMPLEX_DELETE_CONFIRM')} ${selectedItem.name}?`;

    if (selectedItem.protection_devices && selectedItem.protection_devices.length) {
      sDeleteConfirm = [
        `${t('COMPLEX_DELETE_CONFIRM_1')} ${selectedItem.name}:`,
        <br />,
        ...selectedItem.protection_devices.map(({ name }) => (
          <>
            {name}
            <br />
          </>
        )),
        `${t('COMPLEX_DELETE_CONFIRM_2')}`,
      ];
    }

    return (
      <CustomCard
        className={styles.complexesList}
        extra={(
          <>
            <Button
              onClick={() => this.openComplexForm()}
              className='control-button'
              size='small'
              icon={<PlusCircleOutlined />}
              disabled={!isEditRight}
            >
              {t('CLASSIFICATORS_COMPLEXES_ADD_COMPLEX')}
            </Button>
            <Button
              onClick={() => this.openComplexForm(true)}
              disabled={!selectedItem.id || !isEditRight}
              className='control-button'
              size='small'
              icon={<EditOutlined />}
            >
              {t('CLASSIFICATORS_COMPLEXES_EDIT_COMPLEX')}
            </Button>
            <Popconfirm
              title={sDeleteConfirm}
              okText={t('DELETE')}
              cancelText={t('CANCEL')}
              onConfirm={this.deleteComplex}
              placement='topRight'
              disabled={!isEditRight}
            >
              <Button
                disabled={!selectedItem.id || isDeleting || !isEditRight}
                loading={isDeleting}
                className='control-button'
                size='small'
                icon={<DeleteOutlined />}
              >
                {t('DELETE')}
              </Button>
            </Popconfirm>
          </>
        )}
      >
        <FixedYCommonTable
          tableName={this.tableName}
          size={TABLE_SIZE}
          columns={columns}
          dataSource={taggedComplexes}
          loading={isLoading}
          pagination={{
            onChange: (newPage) => changePage(newPage, this.loadComplexes),
            total: count,
            pageSize: limit,
            current: page,
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedItem.id],
            onChange: (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0]),
          }}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadComplexes)
          }
        />
        <ExportBottom
          count={count}
          className='control-button'
          size='small'
          WSData={{
            url: '/v1/protection-device-complexes/',
            accept: 'application/xlsx',
          }}
        />
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isOpen,
  rights: state.rights.rightsData,
  archived: state.archived,
});
const mapDispatchToProps = {
  openComplexesForm: openSidebar,
  closeComplexesForm: closeSidebar,
};
const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      withRouter(
        Complexes
      )
    )
  )
);
