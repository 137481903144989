import { CloseCircleFilled, ToolOutlined } from '@ant-design/icons';
import api from '@services/api';
import { setMaintenanceAlertData } from '@state/maintenanceAlert/actions';
import { maintenanceAlertActionTypes } from '@state/maintenanceAlert/types';
import { RootState } from '@state/store';
import React, { Dispatch, FC, memo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import styles from './styles.module.less';

export interface IMaintenanceAlert extends WithTranslation {}

type MaintenanceButtonProps = ConnectedProps<typeof connector>;

const MaintenanceAlert: FC<MaintenanceButtonProps> = ({ content, visible, setAlertData, t }) => {
  const closeHandler = () => {
    api.readMaintenance();
    setAlertData(content, false);
  };

  return (
    <>
      {visible && (
        <div className={styles['maintenance-alert']}>
          <span className={styles['content']}>
            <ToolOutlined />
            <b>{` ${t('MAINTENANCE_ALERT_CAPTION')} `}</b>
            {content}
          </span>
          <button className={styles['close-button']} onClick={closeHandler}>
            <CloseCircleFilled />
          </button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, props: IMaintenanceAlert) => ({
  content: state.maintentanceAlert.content,
  visible: state.maintentanceAlert.visible,
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch<maintenanceAlertActionTypes>) => ({
  setAlertData: (content: string, visible: boolean) => dispatch(setMaintenanceAlertData(content, visible)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(memo(MaintenanceAlert)));
