import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class InfiniteScroll extends Component {
  state = {
    stateScrollTop: 0,
  };

  componentDidUpdate(prevProps) {
    const { innerRef, children } = this.props;
    const { stateScrollTop } = this.state;

    if (children && children.length && prevProps.children.length && children.length >= prevProps.children.length) {
      if (innerRef && innerRef.current && innerRef.current.view) {
        innerRef.current.view.scrollTop = stateScrollTop;
      }
    }
  }

  handleUpdate = async (values) => {
    const { clientHeight, scrollTop, scrollHeight, offsetHeight } = values;
    const { loadMore, loadMoreEnabled, innerRef, isUsingClientHeight } = this.props;
    const roundedScrollTop = Math.ceil(scrollTop) + 2;

    const mainHeight = isUsingClientHeight ? clientHeight : offsetHeight;

    if (loadMoreEnabled && ((mainHeight + roundedScrollTop) >= scrollHeight)) {
      this.setState({ stateScrollTop: roundedScrollTop });
      await loadMore && await loadMore();
      const { stateScrollTop } = this.state;
      if (
        innerRef
        && innerRef.current
        && innerRef.current.getScrollTop
        && innerRef.current.getScrollTop() < stateScrollTop
      ) {
        innerRef.current.scrollTop(stateScrollTop);
      }
    }
  };

  render() {
    const { innerRef, children, scrollbarProps } = this.props;
    return (
      <Scrollbars
        ref={innerRef}
        {...scrollbarProps}
        onUpdate={this.handleUpdate}
      >
        {children}
      </Scrollbars>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <InfiniteScroll
    innerRef={ref}
    {...props}
  />
));
