/* eslint-disable no-param-reassign */
import React from 'react';
import {
  makeLink,
  renderFields,
  getMyNumber,
} from '@common/treeValues/renderFields';
import {
  checkDefaultValueIntoRanges,
  renderRangeFieldTextValue,
  getStepFromRange,
  renderRange,
  getRangeForEdit,
  emptyRangeValues,
} from '@common/treeValues/renderRangeFields';
import {
  checkDefaultValueIntoSelector,
} from '@common/treeValues/renderSelectorFields';

import {
  Button, InputNumber, message, Popconfirm, Popover, Select, Space, Tooltip
} from 'antd';
import { CheckCircleOutlined, LeftOutlined, RightOutlined, WarningOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import api from '@services/api';
import { onSelectTreeData } from '@common/tree';
import { getId } from '@globalHelpers';
import { PopoverEllipsis, CustomSelect } from '@ui';

import { IS_ISSUED_ARCHIVE } from '@routes/passport/tabs/tasks/constants';
import { renderTitleForCheckBox } from '@common/treeValues';

const { Option } = Select;

export class ActionsForTreeValues {
  constructor(props) {
    this.t = props.t;
    this.component = props.component;
  }

  updateEditItemValue = (component, fields, value) => {
    const { editItem } = component.state;

    if (fields.length === 1) {
      editItem[fields[0]] = value;
    } else {
      editItem[fields[0]][fields[1]] = value;
    }

    return editItem;
  };

  updateEditItemGroupValue = (component, fields, group, value) => {
    const { editItem } = component.state;

    if (fields.length === 1) {
      editItem[fields[0]][group] = value;
    } else {
      editItem[fields[0]][fields[1]][group] = value;
    }

    return editItem;
  };

  changeValue = async (component, column, obj, value) => {
    const { treeValueData, groupsCount, activeGroup } = component.state;
    const newItem = treeValueData.find((item) => item.id === obj.id);
    if (newItem && newItem.isEmpty && newItem.isDeleted) {
      delete newItem.isEmpty;
      delete newItem.isDeleted;
      newItem.isAdded = true;
    }

    if (groupsCount === 1) {
      newItem.value.value = value;
    } else {
      newItem.value.groups_values[activeGroup] = value;
    }
    await component.setState({
      treeValueData,
    });
    if (component.onMyChangeTreeValueData) {
      await component.onMyChangeTreeValueData(treeValueData, 'change', newItem, component);
    }
  };

  renderStatusButton = (mainComponent, record, disabled, statusObj, direction, selectedGroup) => {
    const { t } = this;
    const confirm = disabled
      ? ''
      : (
        <span>
          {direction === 'left' ? t('CONFIRM_CHANGE_PREV_STATUS') : t('CONFIRM_CHANGE_NEXT_STATUS')}
          <br />
          {`${t('CONFIRM_CHANGE_NEXT_STATUS_NAME')} "${statusObj.name}"`}
        </span>
      );

    return statusObj
      ? (
        <Popconfirm
          key={`${record.id}_${record.task_dc_setpoint_value_id[selectedGroup]}_${statusObj.id}_${direction}`}
          placement='topLeft'
          title={
            disabled
              ? ''
              : confirm
          }
          onConfirm={() => mainComponent.changeStatus(
            record.id,
            record.task_dc_setpoint_value_id[selectedGroup],
            statusObj.id,
            selectedGroup
          )}
          okText={t('YES')}
          cancelText={t('NO')}
          disabled={disabled}
        >
          <Button
            size='small'
            icon={direction === 'left' ? <LeftOutlined /> : <RightOutlined />}
            className={`disabled-button ${direction}-button`}
            title={disabled ? t('DISABLE_CHANGE_STATUS') : statusObj.name}
            disabled={disabled}
          />
        </Popconfirm>
      )
      : '';
  };

  renderStatus = (mainComponent, component, column, objValue, record) => {
    const { t } = this;
    const { selectedGroup } = mainComponent.state;
    const { isEditRight, taskStatus } = mainComponent.props;
    if (!objValue) {
      return t('WITHOUT_VALUE');
    }

    const text = objValue[selectedGroup || 1];
    if (!text) {
      return t('WITHOUT_VALUE');
    }

    const disableChangeStatusButtonsByTaskStatus = (IS_ISSUED_ARCHIVE === taskStatus);

    const prevIsArray = (
      text
      && text.prev_status
      && Array.isArray(text.prev_status)
      && text.prev_status.length > 0
      && 'id' in text.prev_status[0]
    );

    const nextIsArray = (
      text
      && text.next_status
      && Array.isArray(text.next_status)
      && text.next_status.length > 0
      && 'id' in text.next_status[0]
    );

    const disabledPrev = (
      !isEditRight
      || !text
      || disableChangeStatusButtonsByTaskStatus
      || !text.prev_status
      || (!prevIsArray && !text.prev_status.id)
    );
    const disabledNext = (
      !isEditRight
      || !text
      || disableChangeStatusButtonsByTaskStatus
      || !text.next_status
      || (!nextIsArray && !text.next_status.id)
    );

    const prevStatus = text && cloneDeep(text.prev_status);
    if (prevIsArray) {
      prevStatus.reverse();
    }
    const nextStatus = text && cloneDeep(text.next_status);
    if (nextIsArray) {
      nextStatus.reverse();
    }

    return (
      <div className='status-value'>
        {
          prevStatus && prevIsArray
            ? (
              prevStatus.map((el) => (
                this.renderStatusButton(mainComponent, record, disabledPrev, el, 'left', selectedGroup)
              ))
            )
            : (
              this.renderStatusButton(mainComponent, record, disabledPrev, prevStatus, 'left', selectedGroup)
            )
        }
        {
          (
            text
            && text.current_status
            && text.current_status.id
            && text.current_status.name
          )
          || t('WITHOUT_VALUE')
        }
        {
          nextStatus && nextIsArray
            ? (
              nextStatus.map((el) => (
                this.renderStatusButton(mainComponent, record, disabledNext, el, 'right', selectedGroup)
              ))
            )
            : (
              this.renderStatusButton(mainComponent, record, disabledNext, nextStatus, 'right', selectedGroup)
            )
        }
      </div>
    );
  };

  getColumnsForTreeValue = (showFunctionColumn = true, mainComponent /* , dataFiltered */) => {
    const { t } = this;
    const {
      compareMode, compareIds, groupsCount, selectedGroup, activeGroup,
    } = mainComponent.state;
    const { compareBlanks, mode } = mainComponent.props;

    const isGroupingSetPoint = groupsCount && groupsCount > 1;

    const result = [];

    result.push({
      title: 'NAME',
      field: 'name',
      type: 'only_view',
      empty: 'WITHOUT_NAME',
      width: '8%',
      render: (component, column, text, record) => {
        let content = t(column.empty);
        if (mode === 'task') {
          const button = (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <Button
              type='link'
              size='small'
              onClick={() => mainComponent.openSetpointComments(record, selectedGroup)}
            >
              {text}
            </Button>
          );
          content =
            <PopoverEllipsis
              content={text}
            >
              {button}
            </PopoverEllipsis>
        } else if (text) {
          content = text;
        }

        const warning = (
          <Popover content={record?.value?.warning}>
            <WarningOutlined style={{ color: 'red', fontSize: '16pt' }} />
          </Popover>
        );

        return (
          <Space>
            {record?.value?.warning && warning}
            <Tooltip
              title={content}
              color='white'
              overlayInnerStyle={{ color: 'black' }}
            >
              <span>{content}</span>
            </Tooltip>
          </Space>
        );
      },
    });
    result.push(
      {
        title: 'SHORT_NAME',
        field: 'short_name',
        type: 'text',
        empty: 'WITHOUT_NAME',
        width: '8%',
      }
    );
    if (showFunctionColumn) {
      result.push(
        {
          title: 'FUNCTION',
          field: 'value.protection_device_function',
          type: 'treeSelect',
          empty: 'WITHOUT_VALUE',
          items: 'deviceFns',
          subField: 'name',
          emptyItem: true,
          width: '8%',
        }
      );
    }
    result.push(
      {
        title: 'CODE',
        field: 'code',
        type: 'only_view',
        empty: 'WITHOUT_VALUE',
        width: '8%',
      },
      {
        title: 'STEP',
        field: 'value.range_values',
        type: 'range',
        empty: 'WITHOUT_VALUE',
        subField: 'step',
        colSpan: 3,
        width: '8%',
        render: (component) => renderRange(component, mode),
      },
      {
        title: 'RANGE_VALUES_MIN',
        field: 'value.range_values',
        type: 'range',
        empty: 'WITHOUT_VALUE',
        subField: 'min_value',
        colSpan: 0,
        width: '8%',
      },
      {
        title: 'RANGE_VALUES_MAX',
        field: 'value.range_values',
        type: 'range',
        empty: 'WITHOUT_VALUE',
        subField: 'max_value',
        colSpan: 0,
        width: '8%',
      },
    );
    result.push(
      {
        title: 'COEFFICIENT_RECULATION',
        field: 's2p_factor',
        type: 'number',
        empty: 'WITHOUT_VALUE',
        width: '8%',
        filterColumn: (treeValueComponent, component) => {
          return !!(component.props.blank && component.props.blank.view_complex);
        },
      }
    );
    if (isGroupingSetPoint) {
      result.push(
        {
          title: 'LAST_VALUE',
          field: 'value.last_groups_values',
          type: 'only_view',
          empty: 'WITHOUT_VALUE',
          width: '8%',
          render: (component, column, objValue) => {
            if (isGroupingSetPoint && objValue) {
              const res = [];
              for (let groupNumber = 1; groupNumber <= groupsCount; groupNumber += 1) {
                res.push(renderRangeFieldTextValue(objValue[groupNumber], false) || t(column.empty));
              }
              return renderRangeFieldTextValue(res.join('; ')) || t(column.empty);
            } else {
              return renderRangeFieldTextValue(objValue) || t(column.empty);
            }
          },
        }
      );
    } else {
      result.push(
        {
          title: 'LAST_VALUE',
          field: 'value.last_value',
          type: 'only_view',
          empty: 'WITHOUT_VALUE',
          width: '8%',
        }
      );
    }
    if (groupsCount) {
      let startGroup = 1;
      let endGroup = groupsCount;
      if (compareMode && compareIds.length) {
        startGroup = activeGroup;
        endGroup = activeGroup;
      }
      for (let groupNumber = startGroup; groupNumber <= endGroup; groupNumber += 1) {
        result.push(
          {
            title: isGroupingSetPoint
              ? (
                <>
                  {
                    groupNumber === activeGroup
                      ? <CheckCircleOutlined className='check-circle check-circle--active' />
                      : ''
                  }
                  <span
                    className={
                      groupNumber === selectedGroup
                        ? 'selected-group'
                        : ''
                    }
                  >
                    {`${t('GROUP')} ${(groupNumber)}`}
                  </span>
                </>
              )
              : 'VALUE',
            originTitle: isGroupingSetPoint ? `GROUP_${groupNumber}` : 'VALUE',
            originTitleText: isGroupingSetPoint ? `${t('GROUP')} ${(groupNumber)}` : t('VALUE'),
            showTitleAsIs: isGroupingSetPoint,
            field: isGroupingSetPoint
              ? 'value.groups_values'
              : 'value.value',
            additionalKey: groupNumber,
            type: 'default_value',
            empty: 'WITHOUT_VALUE',
            switchField: 'setpoint_type',
            showByRenderOnView: true,
            width: '8%',
            render: (component, column, objValue, record) => {
              const { editItem, editId } = component.state;
              const { isCanEdit } = component.props;
              const text = isGroupingSetPoint
                ? objValue && objValue[groupNumber]
                : objValue;
              const isMultiple = (
                editItem
                && column
                && column.switchField
                && editItem[column.switchField]
                && editItem[column.switchField].name === 'multiple_selector'
                && !record.wasAdd
              );

              if (
                isCanEdit
                && editId
                && editId === record.id
                && record[column.switchField]
                && record[column.switchField].name
                && !record.wasAdd
              ) {
                const fields = column.field.split('.');
                switch (record[column.switchField].name) {
                  case 'text':
                    return (
                      <input
                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                        autoFocus={true}
                        key='default_value--text'
                        type='small'
                        defaultValue={text || record.default_value}
                        placeholder={t(column.empty)}
                        onChange={(e) => component.setState({
                          editItem: isGroupingSetPoint
                            ? this.updateEditItemGroupValue(component, fields, groupNumber, e.target.value)
                            : this.updateEditItemValue(component, fields, e.target.value),
                        })}
                      />
                    );
                  case 'numeric':
                    return (
                      <InputNumber
                        autoFocus={true}
                        key='default_value--range'
                        step={getStepFromRange(editItem, fields, groupNumber)}
                        size='small'
                        defaultValue={text}
                        placeholder={t(column.empty)}
                        className={
                          checkDefaultValueIntoRanges(
                            (
                              fields.length === 1
                                ? editItem[fields[0]]
                                : editItem[fields[0]][fields[1]]
                            ),
                            (
                              (editItem.value && editItem.value.range_values)
                              || editItem.range_values
                            )
                          )
                            ? ''
                            : `${column.field.replace('.', '_')}__error`
                        }
                        parser={(e) => getMyNumber(e || e === '0' ? e : null)}
                        onChange={(e) => component.setState({
                          editItem: isGroupingSetPoint
                            ? this.updateEditItemGroupValue(
                              component,
                              fields,
                              groupNumber,
                              Number(getMyNumber(e || e === 0 ? e.toString() : null))
                            )
                            : this.updateEditItemValue(
                              component,
                              fields,
                              getMyNumber(e || e === 0 ? e.toString() : null)
                            ),
                        })}
                      />
                    );
                  case 'selector':
                  case 'multiple_selector': {
                    const optArr = [];
                    if (!isMultiple) {
                      optArr.push(
                        <Option key={`${column.field}__option_null`} value={null}>
                          {t(column.empty)}
                        </Option>
                      );
                    }
                    editItem.selector_values.forEach(
                      (x) => {
                        optArr.push(
                          <Option
                            key={`${column.field}__option_${x}`}
                            value={x}
                            style={{ maxWidth: 270 }}
                          >
                            <PopoverEllipsis
                              content={x}
                            >
                              {x}
                            </PopoverEllipsis>
                          </Option>
                        );
                      }
                    );

                    return (
                      <CustomSelect
                        autoFocus={true}
                        key='default_value--selector'
                        size='small'
                        dropdownMatchSelectWidth={false}
                        getPopupContainerDefault
                        defaultValue={isMultiple && !text ? undefined : (text)}
                        placeholder={isMultiple ? '' : t(column.empty)}
                        mode={isMultiple ? 'multiple' : 'default'}
                        className={
                          checkDefaultValueIntoSelector(editItem[column.field], editItem.selector_values)
                            ? ''
                            : `${column.field}__error`
                        }
                        onChange={(item) => {
                          component.setState({
                            editItem: isGroupingSetPoint
                              ? this.updateEditItemGroupValue(component, fields, groupNumber, item || null)
                              : this.updateEditItemValue(component, fields, item),
                          });
                        }}
                      >
                        {optArr}
                      </CustomSelect>
                    );
                  }
                  default:
                    return t(column.empty);
                }
              } else {
                let resultColumn;
                if (text === null || text === undefined) {
                  resultColumn = t(column.empty);
                } else if (Array.isArray(text)) {
                  resultColumn = text.map((el) => (
                    <div key={`array_values__${el}`}>
                      {
                        el !== null
                          ? renderRangeFieldTextValue(el)
                          : t(column.empty)
                      }
                    </div>
                  ));
                } else if ('link' in column) {
                  resultColumn = makeLink(
                    record,
                    column.link,
                    renderRangeFieldTextValue(text),
                    `${column.field}_${record.id}`
                  );
                } else {
                  resultColumn = renderRangeFieldTextValue(text) || t(column.empty);
                }
                if (record.value && record.value.isDeleted) {
                  return (
                    <div className='isDeleted'>
                      {resultColumn}
                    </div>
                  );
                } else {
                  return resultColumn;
                }
              }
            },
          }
        );
      }
    }
    if (compareIds && compareIds.length) {
      compareIds.forEach((id, indexId) => {
        result.push(
          {
            title: (
              <>
                {(compareBlanks[id] && compareBlanks[id].name) || indexId}
                {
                  (compareBlanks[id] && compareBlanks[id].blank && compareBlanks[id].blank.active_group)
                    ? (
                      <>
                        <br />
                        {`${t('GROUP')} ${compareBlanks[id].blank.active_group}`}
                      </>
                    )
                    : ''
                }
              </>
            ),
            originTitle: 'VALUE',
            originTitleText: t('PASSPORT_SETPOINTS_TABLE_COL_VALUE'),
            showTitleAsIs: true,
            field: `value_${id}`,
            type: 'only_view',
            empty: 'WITHOUT_VALUE',
            width: '8%',
            render: (component, column, obj, record) => {
              const { isCanEdit } = component.props;
              const isDeleted = obj && obj.isDeleted;
              const isAddRow = record && ((record.category && record.category.wasAdd) || record.wasAdd);

              if (obj && (obj.value || obj.value === 0)) {
                const isEqualValue = groupsCount === 1
                  ? record.value && obj.value === record.value.value
                  : record.value && record.value.groups_values && obj.value === record.value.groups_values[activeGroup];
                return (
                  <div className={`compare-value ${!isEqualValue && 'not-equal'} ${isDeleted && 'isDeleted'}`}>
                    <Button
                      size='small'
                      icon={<LeftOutlined />}
                      className='left-button'
                      onClick={() => this.changeValue(mainComponent, column, record, obj.value)}
                      disabled={isDeleted || isEqualValue || isAddRow || !isCanEdit}
                    />
                    <div>
                      {renderRangeFieldTextValue(obj.value) || t(column.empty)}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={`compare-value ${isDeleted && 'isDeleted'}`}>
                    {t(column.empty)}
                  </div>
                );
              }
            },
          }
        );
      });
    }

    result.push({
      title: 'MEASUREMENT',
      field: 'measurement_unit',
      type: 'only_view',
      empty: 'WITHOUT_VALUE',
      subField: 'name',
      width: '8%',
    });

    if (mode === 'task') {
      result.push({
        title: <span className='selected-group'>{`${t('STATUS')} (${t('GROUP')} ${selectedGroup})`}</span>,
        originTitle: 'STATUS',
        originTitleText: t('STATUS'),
        showTitleAsIs: true,
        field: 'status',
        type: 'only_view',
        empty: 'WITHOUT_VALUE',
        fixedWidth: '205px',
        width: '8%',
        render: (component, column, text, record, index) => this.renderStatus(
          mainComponent,
          component,
          column,
          text,
          record,
          index
        ),
      });
    }

    if (!compareMode && mode !== 'task') {
      result.push(
        {
          title: 'VIEW_COMPLEX',
          originTitle: 'VIEW_COMPLEX',
          originTitleText: t('TABLE_COL_VIEW_COMPLEX'),
          renderTitle: renderTitleForCheckBox,
          showTitleAsIs: true,
          showAlwaysAsEdit: mainComponent.state.editMode,
          field: 'value.view_complex',
          type: 'checkBox',
          empty: 'WITHOUT_VALUE',
          fixedWidth: '95px',
          width: '8%',
          filterColumn: (treeValueComponent, component) => {
            return !!(component.props.blank && component.props.blank.view_complex);
          },
        },
        {
          title: 'VIEW_TASK',
          originTitle: 'VIEW_TASK',
          originTitleText: t('TABLE_COL_VIEW_TASK'),
          renderTitle: renderTitleForCheckBox,
          showTitleAsIs: true,
          showAlwaysAsEdit: mainComponent.state.editMode,
          field: 'value.view_task',
          type: 'checkBox',
          empty: 'WITHOUT_VALUE',
          fixedWidth: '95px',
          width: '8%',
          filterColumn: (treeValueComponent, component) => {
            return !!(component.props.blank && component.props.blank.view_task);
          },
        }
      );
    }

    return result;
  };

  newTreeValueItem = (component) => {
    const { t } = this;
    const { selectedTreeItem } = component.state;
    return {
      id: getId(8),
      name: t('NEW_TREE_ITEM_NAME_VALUE'),
      short_name: null,
      ansi: null,
      category: {
        id: selectedTreeItem.id,
        name: selectedTreeItem.name,
      },
      is_accounting_group: false,
      isNewItem: true,
    };
  };

  renderTreeValueColumns = (component, mainComponent, showFunctionColumn, dataFiltered) => {
    const { t } = this;
    const { editId, editItem } = component.state;

    const columns = [];
    const columnsForTreeValue = this.getColumnsForTreeValue(
      showFunctionColumn,
      mainComponent
      /* dataFiltered */
    );

    if (columnsForTreeValue && Array.isArray(columnsForTreeValue) && columnsForTreeValue.length) {
      columnsForTreeValue.forEach((column, columnIndex) => {
        const obj = {};
        obj.origin_column = column;
        obj.title = (
          column.showTitleAsIs
            ? (
              column.renderTitle && typeof column.renderTitle === 'function'
                ? <div>{column.renderTitle(dataFiltered, column, component, mainComponent)}</div>
                : (
                  column.title
                    ? <div>{column.title}</div>
                    : <></>
                )
            )
            : (
              column.title
                ? <div>{t(`TABLE_COL_${column.title}`)}</div>
                : <></>
            )
        );
        const newObj = renderFields(
          obj,
          column,
          'template-tree-value',
          editId,
          editItem,
          columnIndex,
          component,
          this.t
        );
        columns.push(newObj);
      });
    }

    return columns;
  };

  // Дополнительные действия над элементом при его создании, создание доп полей и т.п.
  onSaveEditedTreeValueData = async (component) => {
    const { component: mainComponent } = this;
    const { editId, editItem } = component.state;
    const { treeValueData } = mainComponent.state;
    const editRange = cloneDeep(editItem.range);
    const newEditItem = cloneDeep(editItem);
    delete newEditItem.range;
    const newEditRange = editRange.map((el) => {
      const newEl = cloneDeep(el);
      delete newEl.key;
      return newEl;
    });
    if (newEditItem.setpoint_type.name === 'numeric' && newEditItem.value) {
      if (JSON.stringify(editRange) !== JSON.stringify(emptyRangeValues)
      ) {
        newEditItem.value.range_values = newEditRange;
      }
      if (typeof newEditItem.value.value === 'string') {
        newEditItem.value.value = parseFloat(newEditItem.value.value);
      }
    }

    const newData = treeValueData.map((el) => {
      if (el.id === editId) {
        return newEditItem;
      } else {
        return el;
      }
    });

    mainComponent.setState({
      isEditItem: false,
    });

    return newData;
  };

  // Дополнительные действия над элементом при его создании, создание доп полей и т.п.
  onAddTreeValueItem = (addItem) => {
    const { component } = this;

    component.setState({
      isEditItem: true,
    });

    return addItem;
  };

  // Дополнительные действия над элементом перед редактированием, создание доп полей и т.п.
  onEditTreeValueData = (editedItem) => {
    const { component } = this;
    const rangeArr = getRangeForEdit(
      editedItem && editedItem.value && 'range_values' in editedItem.value
        ? editedItem.value.range_values
        : null
    );

    component.setState({
      isEditItem: true,
    });

    return {
      ...editedItem,
      range: rangeArr,
    };
  };

  checkRecordForEdit = (record) => {
    const { component } = this;
    const { mode } = component.props;
    const { selectedGroup, compareMode } = component.state;

    return mode === 'task'
      ? (
        record.status
        && record.status[selectedGroup]
        && record.status[selectedGroup].current_status
        && record.status[selectedGroup].current_status.id !== component.APPROVED_STATUS
      )
      : (
        compareMode || record?.value?.warning?.length
          ? null
          : true
      );
  };

  checkErrorValueAndGetClassName = (record) => {
    const { component } = this;
    const { groupsCount } = component.state;

    if (
      record.value.range_values.length > 0 &&
      Object.keys(record.value.range_values[0]).length === 0
    )
      record.value.range_values.pop();

    const value = groupsCount === 1
      ? record.value && record.value.value
      : record.value && record.value.groups_values;

    return (
      (
        (
          record
          && record.value
          && record.value.range_values
          && (record.value.range_values.length
            || record.value.range_values.min_value
            || record.value.range_values.max_value
          )
        )
        || (
          record
          && record.range_values
          && (record.range_values.length
            || record.range_values.min_value
            || record.range_values.max_value
          )
        )
      )
      &&
      !checkDefaultValueIntoRanges(
        value,
        (
          (record.value && record.value.range_values)
          || record.range_values
        )
      )
        ? 'row__warning'
        : ''
    );
  };

  getEmptySetPointValue = (startObjectValue = {}) => {
    return {
      ...startObjectValue,
      value: null,
      protection_device_function: null,
    };
  };

  getSetPointsValues = async (blankId, categoryId, taskId = undefined) => {
    const { component } = this;
    const { mode, staticRec } = component.props;
    const { currentView, valuesViewMode } = component.state;

    const data = {};
    if (component.VIEW_SETS === currentView) {
      data.blank = blankId;
      data.category = categoryId;
      data.calc_view_mode = valuesViewMode;
      data.static_rec = staticRec;
    } else if (component.VIEW_FUNCTIONS === currentView) {
      if (mode === 'task') {
        data.task_dc = taskId;
        data.blank = blankId;
        data.calc_view_mode = valuesViewMode;
        data.static_rec = staticRec;
      } else if (categoryId !== '-1') {
        data.blank = blankId;
        data.calc_view_mode = valuesViewMode;
        data.static_rec = staticRec;
      }
      data.func = categoryId;
    }
    return api.v1_getSetPointsWithValues(data);
  };

  rowSelection = () => {
    const { component } = this;
    const { selectedTreeValueData } = component.state;

    return {
      type: 'checkBox',
      selectedRowKeys: selectedTreeValueData,
      onChange: (selectedRowKeys) => {
        component.setState({
          selectedTreeValueData: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: !!record.value.warning,
      }),
    };
  };

  prepareCompareData = (loadedCompareData, categoryId) => {
    if (!loadedCompareData) {
      loadedCompareData = {};
    }
    if (!loadedCompareData[categoryId]) {
      loadedCompareData[categoryId] = {};
    }
  };

  loadCompareSetPoints = async (loadedCompareData, compareId, categoryId, realCategoryId) => {
    this.prepareCompareData(loadedCompareData, categoryId);
    if (!loadedCompareData[categoryId][compareId]) {
      const res = await this.getSetPointsValues(compareId, realCategoryId || categoryId);

      if (res && res.status === 200 && res.data && res.data.results) {
        loadedCompareData[categoryId][compareId] = {};
        res.data.results.forEach((el) => {
          loadedCompareData[categoryId][compareId][el.id] = el;
        });
      }
    }
    return loadedCompareData;
  };

  addCompareValueToItem = (compareId, item, compareValue) => {
    const { compareBlanks } = this.component.props;

    if (compareValue && compareValue.id) {
      const currentValue = compareValue.value;
      const groupsCount = (
        compareBlanks[compareId]
        && compareBlanks[compareId].blank
        && compareBlanks[compareId].blank.groups_count
      ) || 1;
      const activeGroup = (
        compareBlanks[compareId]
        && compareBlanks[compareId].blank
        && compareBlanks[compareId].blank.active_group
      ) || 1;
      if (!item.id) {
        [
          'id',
          'name',
          'short_name',
          'setpoint_type',
          'measurement_unit',
          'setpoint_code',
          'range_values',
          'selector_values',
          'default_value',
          'code',
          's2p_factor'
        ].forEach((field) => {
          item[field] = compareValue[field];
        });
        item.wasAdd = true;
      }
      if (
        groupsCount === 1
        && (
          typeof currentValue !== 'undefined'
          || typeof currentValue.value !== 'undefined'
        )
      ) {
        item[`value_${compareId}`] = { ...currentValue };
      } else if (
        groupsCount > 1
        && currentValue
        && currentValue.groups_values
        && typeof currentValue['groups_values'][activeGroup] !== 'undefined'
      ) {
        currentValue.value = currentValue['groups_values'][activeGroup];
        item[`value_${compareId}`] = { ...currentValue };
      } else {
        item[`value_${compareId}`] = this.getEmptySetPointValue({ isDeleted: true });
      }
    } else {
      item[`value_${compareId}`] = this.getEmptySetPointValue({ isDeleted: true });
    }
  };

  loadSetPoints = async (category) => {
    const { component, t } = this;
    const {
      mode, compareMode, compareIds, blank,
    } = component.props;
    const {
      taskId, currentView, oldTreeValueData, treeValueData, loadedCompareCategory,
    } = component.state;
    let { loadedCompareData } = component.state;

    if (
      !taskId
      || !currentView
      || (
        component.VIEW_FUNCTIONS === currentView
        && category
        && category.id === '-1'
      )
    ) {
      return treeValueData;
    }

    const setPointsRes = await this.getSetPointsValues(blank.id, category.id, taskId);
    const newData = [];

    if (setPointsRes) {
      if (setPointsRes.status !== 200) {
        message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
        return newData;
      }

      const setPoints = {};
      const keysList = [];
      const makeStructure = (setPointsList, item, keyList, group_id, status, task_value_id) => {
        if (keyList.indexOf(item.id) === -1 && !setPointsList[item.id]) {
          setPointsList[item.id] = item;
          setPointsList[item.id]['status'] = {};
          setPointsList[item.id]['status'][group_id] = status;
          setPointsList[item.id]['task_dc_setpoint_value_id'] = {};
          setPointsList[item.id]['task_dc_setpoint_value_id'][group_id] = task_value_id;
          keyList.push(item.id);
        } else {
          setPointsList[item.id]['status'][group_id] = status;
          setPointsList[item.id]['task_dc_setpoint_value_id'][group_id] = task_value_id;
        }
      };
      cloneDeep(setPointsRes.data.results).forEach((item) => {
        if (mode === 'task') {
          if (item && item.taskdc_setpoint_value && item.taskdc_setpoint_value.length) {
            item.taskdc_setpoint_value.forEach((item2) => {
              makeStructure(setPoints, item, keysList, item2.group_id, item2.status, item2.id);
            });
          } else if (item && item.taskdc_setpoint_value && item.taskdc_setpoint_value.id) {
            const item2 = item.taskdc_setpoint_value;
            makeStructure(setPoints, item, keysList, item2.group_id, item2.status, item2.id);
          }
        } else {
          setPoints[item.id] = item;
          keysList.push(item.id);
        }
      });

      const mainKeysList = cloneDeep(keysList);
      const setPointsCompare = {};

      if (compareMode && compareIds && compareIds.length) {
        let compareId;

        for (let i = 0; i < compareIds.length; i += 1) {
          compareId = compareIds[i];

          if (!setPointsCompare[compareId]) {
            setPointsCompare[compareId] = {};
          }

          loadedCompareData = await this.loadCompareSetPoints(
            loadedCompareData,
            compareId,
            category.id,
            loadedCompareCategory[compareId] && loadedCompareCategory[compareId][category.id]
          );
          const res = loadedCompareData[category.id][compareId];

          // eslint-disable-next-line no-loop-func
          res && Object.values(res).forEach((item) => {
            setPointsCompare[compareId][item.id] = item;
            if (keysList.indexOf(item.id) === -1) {
              keysList.push(item.id);
            }
          });
        }
      }

      const oldData = [];
      keysList.forEach((key) => {
        let newItem = { category };
        let oldItem = null;
        if (mainKeysList.indexOf(key) !== -1) {
          newItem = {
            ...newItem,
            ...setPoints[key],
          };
          oldItem = cloneDeep(newItem);
        }
        if (compareMode && compareIds && compareIds.length) {
          let compareId;
          if (mainKeysList.indexOf(key) === -1) {
            newItem.value = this.getEmptySetPointValue({
              isEmpty: true,
              isDeleted: true,
            });
          }
          for (let i = 0; i < compareIds.length; i += 1) {
            compareId = compareIds[i];
            this.addCompareValueToItem(
              compareId,
              newItem,
              setPointsCompare[compareId] && setPointsCompare[compareId][key]
            );
          }
        }
        if (newItem && !('value' in newItem)) {
          newItem.value = {};
          if (oldItem && !('value' in oldItem)) {
            oldItem.value = {};
          }
        }
        if (newItem && newItem.value && !('value' in newItem.value)) {
          newItem.value.value = null;
          if (oldItem && oldItem.value) {
            oldItem.value.value = null;
          }
        }
        if (newItem && newItem.value && !('protection_device_function' in newItem.value)) {
          newItem.value.protection_device_function = null;
          if (oldItem && oldItem.value) {
            oldItem.value.protection_device_function = null;
          }
        }
        newData.push(newItem);
        if (oldItem) {
          oldData.push(oldItem);
        }
      });

      component.setState({
        oldTreeValueData: [...oldTreeValueData, ...oldData],
        treeValueData: [...treeValueData, ...newData],
        loadedCompareData,
      });
    }

    return newData.length
      ? [...treeValueData, ...newData]
      : treeValueData;
  };

  getSetPointValuesByCategory = async (treeValues, categoryId) => {
    return treeValues.filter((item) => (item.category && item.category.id === categoryId));
  };

  onSelectTreeData = async (selectedItem) => {
    const { component } = this;
    const { compareMode, compareIds } = component.props;
    const { loadedCompareCategory } = component.state;
    let { treeValueData, loadedCompareData } = component.state;

    component.setState({
      setPointsBlockIsLoading: true,
    });

    onSelectTreeData(selectedItem, component);

    let selectedData;
    if (selectedItem) {
      selectedData = await this.getSetPointValuesByCategory(treeValueData, selectedItem.id);
      if (!selectedData || selectedData.length === 0) {
        treeValueData = await this.loadSetPoints(selectedItem);
        selectedData = await this.getSetPointValuesByCategory(treeValueData, selectedItem.id);
      }
      if (selectedData && selectedData.length && compareMode && compareIds && compareIds.length) {
        const compareIdList = [];
        let compareId;
        let isChangeTreeValueData = false;

        for (let i = 0; i < compareIds.length; i += 1) {
          compareId = compareIds[i];
          if (selectedData && selectedData.length && selectedData[0] && !(`value_${compareId}` in selectedData[0])) {
            isChangeTreeValueData = true;
            loadedCompareData = await this.loadCompareSetPoints(
              loadedCompareData,
              compareId,
              selectedItem.id,
              loadedCompareCategory[compareId] && loadedCompareCategory[compareId][selectedItem.id]
            );
            const compareSetPoints = loadedCompareData[selectedItem.id][compareId];
            const itemsList = [];
            // eslint-disable-next-line no-loop-func
            selectedData.forEach((item) => {
              itemsList.push(item.id);
              this.addCompareValueToItem(compareId, item, compareSetPoints[item.id]);
            });
            // eslint-disable-next-line no-loop-func
            Object.values(compareSetPoints).forEach((item) => {
              if (itemsList.indexOf(item.id) === -1) {
                const newItem = {
                  ...item,
                  category: {
                    id: selectedItem.id,
                    name: selectedItem.name,
                  },
                  value: this.getEmptySetPointValue({ isDeleted: true, onlyView: true }),
                };
                if (compareIdList && compareIdList.length > 0) {
                  compareIdList.forEach((listId) => {
                    newItem[`value_${listId}`] = this.getEmptySetPointValue({ isDeleted: true });
                  });
                }
                this.addCompareValueToItem(compareId, newItem, item);
                treeValueData.push(newItem);
              }
            });
          }
          compareIdList.push(compareId);
        }

        if (isChangeTreeValueData) {
          component.setState({
            treeValueData,
            loadedCompareData,
          });
        }
      }
    }

    component.setState({
      setPointsBlockIsLoading: false,
    });
  };
}
