import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Checkbox, Card, Input, List, Spin, message,
} from 'antd';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import api from '@services/api';
import {
  setCheckedItem, removeCheckedItem, applyGlobalSearchParams, clearCheckedItems,
} from '@state/search/actions';
import { InfiniteScroll } from '@ui';

import styles from './styles.module.less';

const { Search } = Input;

class FilterCheckList extends Component {
  infiniteScroll = React.createRef();

  state = {
    isLoading: false,
    filterValuesList: [],
    searchPattern: '',
  };

  componentDidMount() {
    this.getListValues();
  }

  componentDidUpdate(prevProps) {
    const {
      isReloadData, offReload, clearItems, checkedItems,
    } = this.props;
    const { filterValuesList } = this.state;

    if (isReloadData === true
      && ((filterValuesList && filterValuesList.length)
          || (checkedItems && checkedItems.length))
      && prevProps.isReloadData !== isReloadData
    ) {
      this.setState({
        filterValuesList: [],
        searchPattern: '',
      }, () => {
        clearItems();
        this.getListValues();
        if (offReload && typeof offReload === 'function') {
          offReload();
        }
      });
    }
  }

  onSearchPatternChange = (event) => {
    const {
      target: { value },
    } = event;

    this.setState({ searchPattern: value }, this.onSearchPatternChangeDebounced);
  };

  onSearchPatternChangeDebounced = debounce(() => {
    this.getListValues();
  }, 800);

  getListValues = async () => {
    const { filterItem, filters } = this.props;
    const { searchPattern } = this.state;
    if (
      typeof filterItem.getList === 'function'
    ) {
      this.setState({ isLoading: true });

      const apiResult = await filterItem.getList(searchPattern, filters[filterItem.name], filters);

      this.setState({ isLoading: false });

      if (apiResult.status === 200) {
        let apiResultData = apiResult.data.results;

        if (
          filterItem.field === 'task_dc_statuses' &&
          apiResultData.filter((item) => item.id === 'inversion')
        ) {
          apiResultData.unshift({ id: 'inversion', name: 'Инверсия (не содержит)' });
        }
        if (
          filterItem.field === 'task_dc_statuses' &&
          apiResultData.filter((item) => item.id === 'has_task_dc')
        ) {
          apiResultData.unshift({ id: 'has_task_dc', name: 'Содержит Задания ДЦ' });
        }

        const { extractIdAttr } = filterItem;

        if (extractIdAttr) 
        apiResultData = apiResultData.map(item => {
          return {
            ...item,
            id: item[extractIdAttr][0].id
          }
        });

        this.setState({
          filterValuesList: apiResultData,
          next: apiResult.data.next,
        });

        this.infiniteScroll.current && this.infiniteScroll.current.scrollToTop();
      }
    }
  };

  loadMoreHandle = async () => {
    const { next, filterValuesList } = this.state;
    const { t } = this.props;

    const url = next ? new URL(next) : {};

    if (url.pathname && url.search) {

      url.searchParams.delete('is_archived');
      const request = url.pathname + url.search;

      this.setState({ isLoading: true });

      const { status, data } = await api.urlGetRequest(request);

      if (status === 200) {
        this.setState({
          isLoading: false,
          filterValuesList: [
            ...filterValuesList,
            ...data.results,
          ],
          next: data.next,
        });
      } else {
        this.setState({
          isLoading: false,
          next: next || {}
        },() => {
          message.error(t('SEARCH_FILTER_CHECKLIST_LOADING_ERROR'), 5)
        })
      }
    }
  };

  onValueChange = (checked, element) => {
    const {
      onChange, keyName, setItem, removeItem, setApplyParams,
    } = this.props;

    if (checked) {
      setItem(keyName, element);
    } else {
      removeItem(keyName, element.id);
    }
    setApplyParams(false);
    onChange(checked, element.id);
  };

  prepareList = () => {
    const { checkedItems, keyName } = this.props;
    const { filterValuesList } = this.state;

    if (!filterValuesList || !filterValuesList.map) {
      return [];
    }

    const listIds = [];

    const newList = filterValuesList.map((item) => {
      listIds.push(item.id);

      return item;
    });

    checkedItems[keyName] && Object.keys(checkedItems[keyName]).forEach((item) => {
      if (!listIds.includes(item)) {
        newList.unshift(checkedItems[keyName][item]);
      }
    });

    return newList;
  };

  renderList = (itemList) => {
    const { t, values, filterItem } = this.props;
    const { isLoading } = this.state;

    return (
      <List
        loading={isLoading}
        locale={{
          emptyText: t('EMPTY_ITEM'),
        }}
        dataSource={itemList}
        renderItem={(element) => (
          <List.Item className={element && element.is_archived ? 'isArchived' : ''}>
            <Checkbox
              key={`checkBoxElement_${element.id}`}
              className={styles.checkbox}
              checked={!!(values && values.indexOf(element.id) !== -1)}
              onChange={(event) => this.onValueChange(event.target.checked, element)}
            >
              {filterItem.field === 'task_dc_statuses' &&
              element.id !== 'has_task_dc' &&
              element.id !== 'inversion'
                ? `содержит в статусе "${element.name}"`
                : element.name}
            </Checkbox>
          </List.Item>
        )}
      />
    );
  };

  render() {
    const {
      t, keyName, setRef, isShowSearchBlock,
    } = this.props;
    const {
      isLoading, searchPattern, filterValuesList, next,
    } = this.state;

    const isShowSearchString =
      filterValuesList &&
      filterValuesList.length >= 10 &&
      keyName !== 'checkList_DEVICE.COL_TASK_DC_STATUSES';

    const itemList = this.prepareList();

    return (
      <Card
        key={`checkBoxSearchString_${keyName}`}
        className={keyName}
        title={
          isShowSearchString
          || (searchPattern && searchPattern.length > 0)
          || isShowSearchBlock
            ? (
              <Search
                ref={setRef}
                placeholder={t('SEARCH_BY_VALUE')}
                onChange={(event) => this.onSearchPatternChange(event)}
              />
            )
            : ''
        }
        bordered={false}
      >
        <Spin spinning={isLoading}>
          {isShowSearchString
            ? (
              <InfiniteScroll
                ref={this.infiniteScroll}
                scrollbarProps={{ style: { height: 250 }, autoHide: true }}
                loadMoreEnabled={!isLoading && !!next}
                loadMore={this.loadMoreHandle}
                isUsingClientHeight={true}
              >
                {this.renderList(itemList)}
              </InfiniteScroll>
            )
            : this.renderList(itemList)}
        </Spin>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  checkedItems: state.search.checkedItems,
});

const mapDispatchToProps = {
  setItem: setCheckedItem,
  removeItem: removeCheckedItem,
  clearItems: clearCheckedItems,
  setApplyParams: applyGlobalSearchParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    FilterCheckList
  )
);
