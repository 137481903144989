export const FIELD_TYPE_INPUT = 'input';
export const FIELD_TYPE_SELECT = 'select';
export const FIELD_TYPE_SELECT_MULTIPLE = 'select-multiple';
export const FIELD_TYPE_TEXTAREA = 'description';
export const FIELD_TYPE_SWITCH = 'switch';
export const FIELD_TYPE_PICKER = 'picker';

export const TEMPLATE_GROUP_TYPE_FUNCTIONS = 'functions';
export const TEMPLATE_GROUP_TYPE_EVENTS = 'events';
export const TEMPLATE_GROUP_TYPE_PROTECTION_DEVICES_INEFFICIENCY = 'protection-devices-inefficiency';
export const TEMPLATE_GROUP_TYPE_NONPERFORMANCE = 'nonperformance';
export const TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_REDUNDANCY = 'setpoint-values-redundancy';
export const TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_LIGHTLOAD = 'setpoint-values-lightload';
export const TEMPLATE_GROUP_TYPE_SETPOINT_VALUES_NONSELECTIVITY = 'setpoint-values-nonselectivity';
export const TEMPLATE_GROUP_TYPE_SETPOINT_PLANS = 'setpoint-plans';
export const TEMPLATE_GROUP_SUBTYPE_SETPOINT_PLANS = 'setpoint-subplans';
export const TEMPLATE_GROUP_SUBTYPE_SETPOINT_READY = 'setpoint-ready';
export const TEMPLATE_GROUP_SUBTYPE_SETPOINT_REGISTRY = 'setpoint-registry';
export const TEMPLATE_GROUP_SUBTYPE_SETPOINT_SUMMARY = 'setpoint-summary';

export const OPTIONS_DC = 'dc';
export const OPTIONS_FUNCTION_GROUPS = 'function_groups';
export const OPTIONS_VOLTAGE_LEVELS = 'voltage_levels';
export const OPTIONS_SUBSTATIONS = 'substations';
export const OPTIONS_OWNERS = 'owners';
export const OPTIONS_TASK_DC_STATUSES = 'task_dc_statuses';
export const OPTIONS_DATE_RANGE = 'date_range';

export const OWNER = 'owner';
export const DISPATCHER_CENTER = 'dispatcher_center';

export const REPORT_TYPE_PLANNED = 'REPORT_TYPE_PLANNED';
export const REPORT_TYPE_EXECUTED = 'REPORT_TYPE_EXECUTED';
export const REPORT_TYPE_REGISTRY = 'REPORT_TYPE_REGISTRY';

export const TEMPLATE_FORMAT_HTML = 'html';
export const TEMPLATE_FORMAT_DOCX = 'docx';
export const TEMPLATE_FORMAT_PDF = 'pdf';
