import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Menu, Dropdown, Button,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import store from '@state/store';
import { closeSidebar } from '@state/sidebar/actions';
import { SidebarLayout } from '@ui';

import styles from './styles.module.less';

import Notifications from './notifications';
import Terms from './terms';
import ThreadCard from './notifications/threadCard';
import ThreadForm from './notifications/threadCard/form';

const NOTES_TAB = 'notes';
const TERMS_TAB = 'terms';

class SetpointsInfo extends React.Component {
  notificationsComp = React.createRef();

  threadCardRef = React.createRef();

  termsRef = React.createRef();

  constructor(props) {
    super(props);

    const { data } = props;

    this.state = {
      activeTab: NOTES_TAB,
      currentNotification: data.currentNotification || null,
      threadH: 195,
      selectedComment: {},
    };
  }

  componentDidMount() {
    const { data } = this.props;

    data.activeTab && this.setActiveTab(data.activeTab);
  }

  setActiveTab = (activeTab) => {
    this.setState({ activeTab, currentNotification: null });
  };

  toggleHeaderMode = (item) => {
    const { currentNotification } = this.state;

    if (currentNotification) {
      this.notificationsComp.current && this.notificationsComp.current.closeThread();
      this.setState({ currentNotification: null });
    } else {
      this.setState({ currentNotification: item });
    }
  };

  onCloseSidebar = () => {
    store.dispatch(closeSidebar());
  };

  updateCommentForm = (textareaH = 32) => {
    this.setState({ threadH: 163 + textareaH });
  };

  setSelectedComment = (selectedComment) => {
    this.setState({ selectedComment });
  };

  updateComments = () => {
    this.updateCommentForm();
    this.threadCardRef.current && this.threadCardRef.current.updateComments();
  };

  saveTerms = async () => {
    this.setState({ isExecuting: true });
    this.termsRef.current && await this.termsRef.current.saveTerms();
    this.setState({ isExecuting: false });
  };

  setDisabledState = async (isDisabled = true) => {
    this.setState({ isDisabled });
  };

  updateNotificationNoteCnt = (cnt = 0) => {
    const { currentNotification } = this.state;

    const newCurNotification = {
      ...currentNotification,
      notes_number: cnt,
    };

    this.setState({
      currentNotification: newCurNotification,
    });
  };

  render() {
    const { t, data } = this.props;
    const {
      activeTab, currentNotification, threadH, selectedComment, isExecuting, isDisabled,
    } = this.state;

    const tabs = {
      [NOTES_TAB]: t('EVENTS_HISTORY'),
      [TERMS_TAB]: t('NONPERFORMANCE_TERMS'),
    };

    const menu = (
      <Menu>
        <Menu.Item key='1'>
          <Button
            type='link'
            size='small'
            tabIndex={0}
            rel='noopener noreferrer'
            onClick={() => this.setActiveTab(NOTES_TAB)}
          >
            {t('EVENTS_HISTORY')}
          </Button>
        </Menu.Item>
        <Menu.Item key='2'>
          <Button
            type='link'
            size='small'
            tabIndex={0}
            rel='noopener noreferrer'
            onClick={() => this.setActiveTab(TERMS_TAB)}
          >
            {t('NONPERFORMANCE_TERMS')}
          </Button>
        </Menu.Item>
      </Menu>
    );

    let footer = null;

    switch (activeTab) {
      case TERMS_TAB:
        footer = (
          <Button
            block
            type='primary'
            onClick={this.saveTerms}
            loading={isExecuting}
            disabled={isExecuting || isDisabled}
          >
            {t('SAVE')}
          </Button>
        );
        break;
      default: footer = null;
    }

    return (
      <>
        {!currentNotification && (
          <SidebarLayout
            withoutCard
            header={(
              <div className={styles.header}>
                {!currentNotification && (
                  <Dropdown overlay={menu}>
                    <div>
                      <span>{tabs[activeTab]}</span>
                      <CaretDownOutlined />
                    </div>
                  </Dropdown>
                )}
                {currentNotification && (
                  <div role='button' tabIndex={0} onClick={this.toggleHeaderMode}>
                    ⟵&nbsp;
                    <span>{t('BACK')}</span>
                  </div>
                )}
              </div>
            )}
            footer={footer}
          >
            <div>
              {activeTab === NOTES_TAB && (
                <Notifications
                  t={t}
                  {...data}
                  ref={this.notificationsComp}
                  toggleHeaderMode={this.toggleHeaderMode}
                />
              )}
              {activeTab === TERMS_TAB && (
                <Terms
                  t={t}
                  ref={this.termsRef}
                  isExecuting={isExecuting}
                  setDisabledState={this.setDisabledState}
                  blank={data && data.blank}
                  groupsCount={data && data.groupsCount}
                  setpointValueId={data && data.setpointValueId}
                  setpointId={data && data.setpointId}
                  functionId={data && data.functionId}
                />
              )}
            </div>
          </SidebarLayout>
        )}
        {currentNotification && (
          <SidebarLayout
            contentStyle={{ height: `calc(100vh - ${threadH}px)` }}
            withoutCard
            header={(
              <div className={styles.header}>
                <div role='button' tabIndex={0} onClick={this.toggleHeaderMode}>
                  ⟵&nbsp;
                  <span>{t('BACK')}</span>
                </div>
              </div>
            )}
            footer={(
              <ThreadForm
                selectedNotification={currentNotification}
                updateFooterH={this.updateCommentForm}
                selectedComment={selectedComment}
                dcSetpointValue={data.id}
                setpointValueId={data && data.setpointValueId}
                updateComments={this.updateComments}
                handleAnswer={this.setSelectedComment}
              />
            )}
          >
            <ThreadCard
              ref={this.threadCardRef}
              selectedNotification={currentNotification}
              updateNotificationNoteCnt={this.updateNotificationNoteCnt}
              setSelectedComment={this.setSelectedComment}
              selectedComment={selectedComment}
            />
          </SidebarLayout>
        )}
      </>
    );
  }
}

export default withTranslation()(
  SetpointsInfo
);
