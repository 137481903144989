import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  debounce, cloneDeep, intersection, isEqual, unionBy,
} from 'lodash';
import {
  Button, Checkbox, Collapse, Empty, Form, Input, List, message,
  Popconfirm, Radio, Select, Spin, Tooltip, Upload, Typography
} from 'antd';
import {
  DeleteOutlined, QuestionCircleOutlined, UndoOutlined, UploadOutlined,
} from '@ant-design/icons';

import api from '@services/api';
import { getBucket } from '@state/bucket/actions';
import { closeSidebar, setIsChangedSidebar } from '@state/sidebar/actions';
import {
  getPopoverFileNameFromLink, readUrlFromClipboard, saveFileToStorage, StorageState,
} from '@globalHelpers';
import {
  LIBRARY_LOCAL,
  LIBRARY_GLOBAL,
  KB,
  RIGHT_DOCUMENT_GLOBAL_STORAGE,
  MODE_EDIT,
  LIMIT,
  RIGHT_METATAGS_DOCS_ADD,
  RIGHT_PASSPORT_DOCS
} from '@globalConstants';
import { SidebarLayout, CustomSelect, PopoverEllipsis } from '@ui';
import DocumentExtraPropsForm from './DocumentExtraPropsForm';

import {
  DOC_SIDEBAR_TASK,
  DOC_SIDEBAR_TASK_CREATE_TASK,
  EMPTY_DOCUMENT,
  NO_ADMINISTRATORIA,
  FUNCTIONS,
  IMPLEMENTATION_TYPES,
  PRODUCERS,
  SOFTWARE_VERSION,
  TRADE_DEVICES,
  UPLOAD_TYPE_FILE,
  UPLOAD_TYPE_LINK,
} from './constants';

import styles from './styles.module.less';
import {IS_ISSUED_ARCHIVE} from "@routes/passport/tabs/tasks/constants";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const mapMetaTagsToExtraProps = {
  [PRODUCERS]: 'producers',
  [IMPLEMENTATION_TYPES]: 'implementation_types',
  [TRADE_DEVICES]: 'trade_devices',
  [SOFTWARE_VERSION]: 'versions',
  [FUNCTIONS]: 'functions',
};

class DocumentsUpload extends Component {
  form = React.createRef();

  state = {
    isLoading: true,
    isDevicesLoading: false,
    uploadType: undefined,
    docType: null,
    fileList: [],
    docTypes: [],
    docStatuses: [],
    docTags: [],
    searchDocTags: undefined,
    docTagsCount: 0,
    storages: [],
    isExtraPropsEdit: false,
    formValues: {
      old_files: [],
      deleted_external_links: [],
      external_links: [],
      library_type: LIBRARY_LOCAL,
      files: [],
    },
    metaTags: null,
    passportsList: [],
    currentPassportsList: [],
    currentPassportsCount: 0,
    allPassportsList: [],
    allPassportsCount: 0,
    selectedPassportsList: [],
    selectedPassportsCount: 0,
    onlySelected: false,
    searchDeviceValue: '',
    addButtonNumber: '0',
    isAddPassport: false,
    autoCreated: undefined,
    errors: {
      old_files: '',
      files: '',
      new_links: [],
    },
    initialValuesForCompare: undefined,
    deletedPassports: [],
    prepareToSaveFromTaskCD: false,
  };

  componentDidMount = async () => {
    const {
      bucketCount, bucketList, getSelectedPassports, setBlocked,
    } = this.props;

    setBlocked && setBlocked(true);

    if (bucketCount || (bucketList && bucketList.length)) {
      await getSelectedPassports(-1, false, { limit: 0, offset: 0 });
      this.setState({
        selectedPassportsList: bucketList,
        selectedPassportsCount: bucketCount,
        onlySelected: true,
      }, async () => {
        await this.loadingForMount();
      });
    } else {
      await this.loadingForMount();
    }
  };

  componentWillUnmount() {
    this.form.current && this.form.current.resetFields();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      bucketCount, bucketList, data, hasChanged,
    } = this.props;
    const {
      fileList, uploadType, isExtraPropsEdit, formValues, passportsList, onlySelected,
      initialValuesForCompare, prepareToSaveFromTaskCD,
    } = this.state;

    if (prevState.fileList.length !== fileList.length && this.form.current) {
      this.form.current.validateFields(['new_links'], { force: true });
    }

    if (
      (!isEqual(prevProps.bucketCount, bucketCount))
      || (!isEqual(prevProps.bucketList, bucketList))
      || (!isEqual(prevState.onlySelected, onlySelected))
    ) {
      this.updateButtonNumber();
      // this.onSearchDevice('', false);
    }

    if (!isEqual(prevProps.data, data)) {
      this.updateButtonNumber();
      // await this.onSearchDevice('', false);
      this.setInitialValues(data && data.type === DOC_SIDEBAR_TASK_CREATE_TASK);
    }

    if (!isEqual(prevState.passportsList, passportsList)) {
      await this.onSearchDevice('', true);
      this.compareFormValuesWithInitialValues();
    }

    if (prevState.uploadType !== undefined && prevState.uploadType !== uploadType && this.form.current) {
      if (uploadType === UPLOAD_TYPE_LINK) {
        const externalLink = await readUrlFromClipboard() || undefined;

        if (externalLink) {
          this.form.current.setFieldsValue({ new_links: externalLink });
        }

        await this.form.current.resetFields(['files']);
        await this.form.current.setFieldsValue({ files: [] });

        const newFormValues = {
          ...formValues,
          files: [],
          new_links: externalLink,
        };

        this.setState({
          fileList: [],
          formValues: newFormValues,
        });
      } else {
        this.form.current.resetFields(['new_links']);
        this.form.current.setFieldsValue({ new_links: '' });

        const newFormValues = {
          ...formValues,
          new_links: '',
        };

        this.setState({
          formValues: newFormValues,
        });
      }
    }

    if (prevState.isExtraPropsEdit && !isExtraPropsEdit && this.form.current) {
      if (isExtraPropsEdit) {
        this.setMetaTagsToState();
      } else {
        this.form.current.setFieldsValue(formValues);
        this.setState({ metaTags: null });
      }
    }

    if (!isEqual(formValues, prevState.formValues)) {
      if (data.type === DOC_SIDEBAR_TASK_CREATE_TASK) {
        !prepareToSaveFromTaskCD
        && hasChanged
        && hasChanged(isEqual(formValues, initialValuesForCompare));
      } else {
        this.compareFormValuesWithInitialValues();
      }
    }
  };

  compareFormValuesWithInitialValues = (newValues) => {
    const { setIsChangedDataSidebar, data } = this.props;
    const { initialValuesForCompare, deletedPassports, prepareToSaveFromTaskCD } = this.state;

    const allCurrentValues = newValues || (this.form.current && this.form.current.getFieldsValue(true)) || {};
    let allCurrentValuesKeys = Object.keys(allCurrentValues);

    const currentValues = {};
    if (allCurrentValuesKeys && allCurrentValuesKeys.length > 0) {
      allCurrentValuesKeys = allCurrentValuesKeys.filter((x) => !x.includes('passport[add_'));
    }
    if (allCurrentValuesKeys && allCurrentValuesKeys.length > 0) {
      if (data && !data.showPassportsList) {
        allCurrentValuesKeys = allCurrentValuesKeys.filter((x) => !x.includes('passport['));
      } else if (deletedPassports && deletedPassports.length > 0) {
        allCurrentValuesKeys = allCurrentValuesKeys.filter((x) => !deletedPassports.map((y) => `passport[${y}]`).includes(x));
      }
    }
    if (allCurrentValuesKeys && allCurrentValuesKeys.length > 0) {
      allCurrentValuesKeys.forEach((x) => {
        currentValues[x] = allCurrentValues[x];
      });
    }

    !prepareToSaveFromTaskCD
    && setIsChangedDataSidebar
    && setIsChangedDataSidebar(!isEqual(currentValues, initialValuesForCompare));
  }

  loadingForMount = async () => {
    const { data, setCurrentDocumentRef, setBlocked } = this.props;

    this.setState({ isLoading: true });
    setBlocked && setBlocked(true);

    const newState = { isLoading: false };

    if (data && data.type === DOC_SIDEBAR_TASK_CREATE_TASK) {
      newState.storages = [data.localStorage];
      newState.docTypes = [data.docType];
      newState.uploadType = UPLOAD_TYPE_FILE;
      await this.handleChangeLibraryType(LIBRARY_GLOBAL);
    } else {
      await this.loadStorages();
      await this.loadDocumentsTypes(data && data.showSystemType);
      await this.loadDocumentsStatuses();
      await this.loadTags();
      await this.loadPassports();
      await this.handleChangeLibraryType(LIBRARY_LOCAL);
      if (data && data.document && data.document.document_type && data.document.document_type.id) {
        await this.handleChangeType(data.document.document_type.id);
      }
      if (data.isEdit) {
        const { is_storage } = data.document;
        newState.uploadType = is_storage ? UPLOAD_TYPE_FILE : UPLOAD_TYPE_LINK;
      } else {
        newState.uploadType = UPLOAD_TYPE_FILE;
      }
    }
    // await this.onSearchDevice('', false);

    if (data.autoCreated !== undefined) {
      newState.autoCreated = data.autoCreated;
    }

    this.setState({ ...newState });
    this.setInitialValues(data && data.type === DOC_SIDEBAR_TASK_CREATE_TASK);

    if (setCurrentDocumentRef) {
      setCurrentDocumentRef(this.form, this);
    }

    setBlocked && setBlocked(false);
  }

  loadStorages = async (addParams = {}) => {
    const { user } = this.props;

    const { status, data } = await api.getStorages({
      status: 'available',
      dispatcher_center: user && user.dispatcher_center
        ? user.dispatcher_center.id
        : null,
      ...addParams,
    });

    if (status === 200) {
      this.setState({ storages: data.results });
    }
  };

  loadDocumentsTypes = async (showSystemType) => {
    const { data, status } = await api.getDocumentTypes();

    if (status === 200) {
      this.setState({
        docTypes: (
          showSystemType === true
            ? data.results
            : data.results.filter((x) => x.is_system === false)
        ),
      });
    }
  };

  loadDocumentsStatuses = async () => {
    const { data, status } = await api.getDocumentStatuses();

    if (status === 200) {
      this.setState({
        docStatuses: data.results,
      });
    }
  };

  loadTags = async () => {
    const { searchDocTags } = this.state;
    const props = {};
    if (searchDocTags && searchDocTags.length) {
      props.name = searchDocTags;
    }

    const { data, status } = await api.getTags(props);

    if (status === 200) {
      this.setState({
        docTags: data.results,
        docTagsCount: data.count,
      });
    }
  };

  loadPassports = async () => {
    const { data } = this.props;

    this.setState({
      passportsList: data && data.document && data.document.passports && data.document.passports.length
        ? data.document.passports.map((x) => x.id)
        : (
          data && data.passports
            ? cloneDeep(data.passports)
            : []
        ),
    });
  };

  onSearchCurrentDevices = async (value, offset = 0) => (
    this.onSearchDevice(value, true, offset)
  );

  onSearchAllDevices = async (value, offset = 0) => (
    this.onSearchDevice(value, false, offset)
  );

  onSearchSelectedDevices = async (value, offset = 0) => (
    this.onSearchDevice(value, false, offset)
  );

  onSearchDevice = async (value, isCurrentPassport, offset = 0) => {
    const { bucketList } = this.props;
    const {
      currentPassportsList, allPassportsList, searchDeviceValue, passportsList, onlySelected,
    } = this.state;

    const newValue = (
      value === ''
        ? undefined
        : value
    );

    const isSearchValueChanged = newValue !== searchDeviceValue || (offset === 0 && value === undefined);

    this.setState({ isDevicesLoading: true });

    if (isCurrentPassport || !onlySelected) {
      let limit = LIMIT;
      const data = {
        ordering: 'name',
      };
      if (newValue) {
        data.name = newValue;
      }
      if (isCurrentPassport && passportsList && passportsList.length) {
        data.passport = passportsList;
        limit = 0;
      }
      
      const endpoint = `/v1/protection-devices/short/`;

      const result = await api.getDataForFilter(
        endpoint,
        data,
        limit,
        offset
      );

      if (result.status === 200 && result.data.results) {
        const res = {};
        for (const x of result.data.results) {
          const key = x.passports && x.passports[0] && x.passports[0].id;
          res[key] = x;

          if (x?.substation?.name) res[key].name += ` (${x.substation.name})`;
        }
        const newState = {
          searchDeviceValue: newValue,
        };
        if (isCurrentPassport) {
          newState.currentPassportsCount = result.data.count;
          newState.currentPassportsList = isSearchValueChanged
            ? res
            : {
              ...currentPassportsList,
              ...res,
            };
        } else {
          newState.allPassportsCount = result.data.count;
          newState.allPassportsList = isSearchValueChanged
            ? res
            : {
              ...allPassportsList,
              ...res,
            };
        }

        this.setState(newState);
      }
    } else {
      const devices = newValue
        ? bucketList.filter((x) => x.name.includes(newValue))
        : bucketList;
      const devicesList = {};
      devices.forEach((x) => devicesList[x.id] = x);
      this.setState({
        searchDeviceValue: newValue,
        selectedPassportsList: devicesList,
        selectedPassportsCount: devices.length,
      });
    }
    this.setState({ isDevicesLoading: false });
  }

  onScrollDeviceSelector = (event, isCurrentPassport) => {
    const {
      allPassportsList,
      allPassportsCount,
      currentPassportsList,
      currentPassportsCount,
      searchDeviceValue,
      isDevicesLoading,
    } = this.state;

    const { target } = event; 
    const scrollTopMax = target.scrollHeight - 5;
    const scrollTop = target.scrollTop + target.offsetHeight;

    const list = isCurrentPassport ? currentPassportsList : allPassportsList;
    const countAll = isCurrentPassport ? currentPassportsCount : allPassportsCount;
    const countList = Object.keys(list).length;

    if (!isDevicesLoading && scrollTop >= scrollTopMax && countAll > countList) {
      this.onSearchDevice(searchDeviceValue, isCurrentPassport, countList);
    }
  }

  onChangeDevice = (value, option, oldValue) => {
    if (oldValue !== value) {
      this.onChangePassport(oldValue, value);
    }
    if (oldValue === undefined || oldValue === null) {
      this.setState({
        isAddPassport: false,
      });
    }
  }

  onChangePassport = (oldPassportValue, passportValue) => {
    const { passportsList, deletedPassports } = this.state;

    const oldPassportId = (oldPassportValue && oldPassportValue.value) || oldPassportValue;
    const passportId = (passportValue && passportValue.value) || passportValue;

    const newPassportsList = cloneDeep(passportsList);
    const newDeletedPassports = cloneDeep(deletedPassports);

    const index = newPassportsList.indexOf(oldPassportId);

    if (oldPassportId && index !== -1) {
      newPassportsList.splice(index, 1);
      if (newDeletedPassports && !newDeletedPassports.includes(oldPassportId)) {
        newDeletedPassports.push(oldPassportId);
      }
    }
    if (passportId && newPassportsList && !newPassportsList.includes(passportId)) {
      newPassportsList.push(passportId);
      if (newDeletedPassports && newDeletedPassports.includes(passportId)) {
        const delIndex = newDeletedPassports.indexOf(passportId);
        newDeletedPassports.splice(delIndex, 1);
      }
    }

    this.setState({
      passportsList: newPassportsList,
      deletedPassports: newDeletedPassports,
    }, () => {
      this.form.current && this.form.current.validateFields();
    });
  }

  addPassport = () => {
    this.setState({
      isAddPassport: true,
      addButtonNumber: moment.now().toFixed(0),
    });
  }

  updateButtonNumber = () => {
    this.setState({
      addButtonNumber: moment.now().toFixed(0),
    });
  }

  setInitialValues = (isTaskDCDocument) => {
    const { data, setBlocked, user } = this.props;
    const { passportsList } = this.state;

    this.setState({ isLoading: true });
    setBlocked && setBlocked(true);

    const {
      external_links,
      deleted_external_links,
      name,
      tags,
      is_storage,
      library = LIBRARY_LOCAL,
      document_type,
      document_status,
      document_task_dcs,
    } = (data && data.document) ||
    (user.roles.some(role => role.key === 'ADMINISTRATORIA') ? EMPTY_DOCUMENT : NO_ADMINISTRATORIA);

    const { storages } = this.state;
    let selectedStorage;
    let library_type;

    if (isTaskDCDocument) {
      selectedStorage = storages && storages[0];
      library_type = LIBRARY_GLOBAL;
    } else {
      if (data && data.isEdit) {
        const urlToStorage = is_storage && external_links && external_links.length
          ? new URL(external_links[0])
          : undefined;
        const storageUrl = urlToStorage && urlToStorage.origin;
        selectedStorage = storageUrl && storages.find((storage) => storage.url.includes(storageUrl));
      }

      if (!selectedStorage) {
        selectedStorage = storages.find(
          (storage) => storage.type === LIBRARY_LOCAL.toLowerCase()
        );
        if (!selectedStorage) {
          selectedStorage = storages.find(
            (storage) => storage.type === LIBRARY_GLOBAL.toLowerCase()
          );
        }
      }

      library_type = (
        selectedStorage
        && selectedStorage.type === LIBRARY_LOCAL.toLowerCase()
          ? LIBRARY_LOCAL
          : LIBRARY_GLOBAL
      );
    }

    const values = {
      library_type,
      library,
      old_files: external_links ? [...external_links] : [],
      files: [],
      new_links: '',
      deleted_external_links: deleted_external_links ? [...deleted_external_links] : [],
      storage: selectedStorage && selectedStorage.id,
      name: name || '',
      document_type: document_type && document_type.id,
      document_status: document_status && document_status.id,
      document_task_dcs: (
        data && [DOC_SIDEBAR_TASK, DOC_SIDEBAR_TASK_CREATE_TASK].includes(data.type)
          ? undefined
          : (
            document_task_dcs && Array.isArray(document_task_dcs) && document_task_dcs.length
              ? document_task_dcs
              : (
                data.taskId
                  ? [data.taskId]
                  : (data.selectedTask && data.selectedTask.id
                    ? [data.selectedTask.id]
                    : []
                  )
              )
          )
      ),
      task_dcs: (
        data && ![DOC_SIDEBAR_TASK, DOC_SIDEBAR_TASK_CREATE_TASK].includes(data.type)
          ? undefined
          : (
            data.taskId
              ? [data.taskId]
              : (data.selectedTask && data.selectedTask.id
                ? [data.selectedTask.id]
                : []
              )
          )
      ),
      tags: tags ? tags.map((tag) => tag.id) : [],
    };
    this.form.current && this.form.current.setFieldsValue(values);
    this.saveValuesToStore(values);

    this.setMetaTagsToState();

    const initialValuesForCompare = cloneDeep(values);

    if (passportsList && passportsList.length > 0 && !!data.showPassportsList) {
      passportsList.forEach((x) => initialValuesForCompare[`passport[${x}]`] = x);
    }

    this.setState({
      isLoading: false,
      initialValuesForCompare,
    }, () => this.compareFormValuesWithInitialValues());
    setBlocked && setBlocked(false);
  };

  setMetaTagsToState = () => {
    const { data, setBlocked } = this.props;

    this.setState({ isLoading: true });
    setBlocked && setBlocked(true);

    const metaTags = data.document?.meta_tags
      && Object.keys(data.document.meta_tags).reduce((memo, tag) => ({
        ...memo,
        [tag]: data.document.meta_tags[tag].map(({ object_id }) => object_id),
      }), {});

    if (metaTags && Object.keys(metaTags).length) {
      this.setState({ metaTags });
    }

    this.setState({ isLoading: false });
    setBlocked && setBlocked(false);
  };

  changeOnlySelected = (value) => {
    this.setState({
      onlySelected: value,
    });
  }

  handleUploadTypeChange = (e) => {
    this.setState({
      uploadType: e.target.value,
    });
  };

  handleChangeLibraryType = async (value) => {
    const { storages } = this.state;
    const storagesForCurrentType = storages && storages.filter(
      (storage) => storage.type === value.toLowerCase()
    );

    if (storagesForCurrentType && storagesForCurrentType.length) {
      this.form.current && this.form.current.setFieldsValue({ storage: storagesForCurrentType[0].id });
    } else {
      this.form.current && this.form.current.resetFields(['storage']);
    }
  };

  handleChangeType = async (value) => {
    const { t } = this.props;
    const { docTypes, docType, storages: oldStorages } = this.state;

    const currentDocType = docTypes.filter((type) => type.id === value)[0];

    if ([t('NON_DIGITAL_SETPOINT_BLANK'), t('TASK_DC')].indexOf(currentDocType.name) !== -1) {
      this.form.current.setFieldsValue({ library: LIBRARY_GLOBAL });
    } else if (docType && (docType.name === t('NON_DIGITAL_SETPOINT_BLANK') || docType.name === t('TASK_DC'))) {
      if (!oldStorages || oldStorages.length === 0) {
        await this.loadStorages();
      }
      const { storages } = this.state;
      const localStorages = storages.filter((storage) => storage.type === LIBRARY_LOCAL.toLowerCase());

      this.form.current.setFieldsValue({
        storage: localStorages[0] && localStorages[0].id,
        library_type: LIBRARY_LOCAL,
        library: LIBRARY_LOCAL,
      });
    }

    this.setState({
      docType: currentDocType,
    });
  };

  validateFileOrLink = async (rule, value) => {
    const { t, data } = this.props;
    const { formValues } = this.state;
    const {
      new_links: newLinks,
      files: newFiles,
      old_files: oldFiles,
    } = formValues;

    if (data.isEdit && ['files', 'new_links'].includes(rule.field)) {
      setTimeout(
        () => this.form.current.validateFields(['old_files']),
        300
      );
      return true;
    }

    const oldFilesCount = (
      oldFiles
        ? oldFiles.length
        : 0
    );

    const filesCount = (
      (
        newFiles && newFiles.fileList
          ? newFiles.fileList.length
          : 0
      )
      + (
        rule.field === 'files'
          ? (value && value.fileList
            ? value.fileList.length
            : 0
          )
          : 0
      )
    );

    const linksCount = (
      (
        newLinks
          ? newLinks.length
          : 0
      )
      + (
        rule.field === 'new_links'
          ? (value
            ? value.length
            : 0
          )
          : 0
      )
    );

    const { errors } = this.state;

    if (!filesCount && !linksCount && !oldFilesCount) {
      const text = t('SELECT_FILE_MESSAGE');
      if (data.isEdit) {
        const newErrors = cloneDeep(errors);
        newErrors.old_files = text;
        this.setState({
          errors: newErrors,
        });
      } else {
        throw new Error(text);
      }
    } else if (filesCount && linksCount) {
      const text = t('EXIST_FILE_AND_LINK_MESSAGE');
      if (data.isEdit) {
        const newErrors = cloneDeep(errors);
        newErrors.old_files = text;
        this.setState({
          errors: newErrors,
        });
      } else {
        throw new Error(text);
      }
    } else if (data.isEdit && errors.old_files.length) {
      errors.old_files = '';
      this.setState({
        errors,
      });
    }
  };

  validateFileSize = async (rule, value, text, cmpSizeFunction) => {
    const { data } = this.props;
    const { errors } = this.state;

    let errorFiles = false;
    const newErrors = cloneDeep(errors);
    if (!newErrors.files || newErrors.files === '') {
      newErrors.files = [];
    }

    if (value && value.fileList && value.fileList.length) {
      value.fileList.forEach((x) => {
        if (cmpSizeFunction(x.size)) {
          x.status = 'error';
          x.response = text;
          errorFiles = true;
        }
      });
    }
    if (errorFiles) {
      if (data.isEdit && !this.getCollapsedNewPanel()) {
        newErrors.files.push(text);
      } else {
        throw new Error(text);
      }
    } else if (data.isEdit && errors.files.length) {
      const idx = newErrors.files.indexOf(text);
      if (idx >= 0) {
        newErrors.files.splice(idx, 1);
      }
    }

    this.setState({
      errors: newErrors,
    });
  };

  validateMinFileSize = async (rule, value) => {
    const { t } = this.props;

    await this.validateFileSize(
      rule,
      value,
      t('FILE_MIN_UPLOAD_FILE_SIZE_MESSAGE'),
      (size) => size === 0
    );
  }

  validateMaxFileSize = async (rule, value) => {
    const { t, settings } = this.props;

    await this.validateFileSize(
      rule,
      value,
      `${t('FILE_MAX_UPLOAD_FILE_SIZE_MESSAGE')} ${settings.MAX_UPLOAD_FILE_SIZE / KB / KB}Mb`,
      (size) => size > settings.MAX_UPLOAD_FILE_SIZE
    );
  }

  validateLinks = async (rule, value) => {
    const { t, data } = this.props;

    const newValue = cloneDeep(value).trim('\n+$');

    const { errors } = this.state;
    const errorLinks = [];

    if (newValue && newValue.length) {
      const valueArr = newValue.split('\n');
      if (valueArr && valueArr.length) {
        valueArr.forEach((x) => {
          try {
            // eslint-disable-next-line
            const url = new URL(x);
          } catch (e) {
            const text = `${t('UPLOADED_LINKS_ERROR_NOT_LINKS')}: '${x.length ? x : t('EMPTY_STRING_INTO_LINKS_FIELD_ERROR')}'`;
            if (data.isEdit && !this.getCollapsedNewPanel()) {
              errorLinks.push(text);
            } else {
              throw new Error(`${t('UPLOADED_LINKS_ERROR_NOT_LINKS')}: '${x.length ? x : t('EMPTY_STRING_INTO_LINKS_FIELD_ERROR')}'`);
            }
          }
        });
      }
    }

    if (errorLinks && errorLinks.length) {
      const newErrors = cloneDeep(errors);
      newErrors.new_links = errorLinks;
      this.setState({
        errors: newErrors,
      });
    } else if (data.isEdit && errors.new_links.length) {
      errors.new_links = [];
      this.setState({
        errors,
      });
    }
  };

  editExtraProps = () => {
    this.form.current.validateFields()
      .then(() => {
        this.setState({ isExtraPropsEdit: true });
      })
      .catch(() => {});
  };

  editMainProps = () => {
    this.setState({ isExtraPropsEdit: false });
  };

  saveValuesToStore = (allFields) => {
    const { data, onFormValuesChange } = this.props;

    this.setState({ formValues: allFields });

    if (onFormValuesChange) onFormValuesChange(allFields);

    if (data.type !== DOC_SIDEBAR_TASK_CREATE_TASK) {
      this.compareFormValuesWithInitialValues(allFields);
    }
  };

  setExtraDocumentProps = (extraProps = {}) => {
    const metaTags = {};

    Object.keys(extraProps).forEach(
      (key) => {
        if (extraProps[key]) {
          metaTags[mapMetaTagsToExtraProps[key]] = extraProps[key];
        }
      }
    );

    this.setState({ metaTags });
  };

  saveDocument = async (callback) => {
    const { t, data, closeDocumentsUpload } = this.props;
    const {
      metaTags, isExtraPropsEdit, formValues, passportsList, autoCreated, storages,
    } = this.state;

    const values = {
      ...formValues,
      meta_tags: metaTags,
    };

    const formDataPrime = {};

    formDataPrime.passports = passportsList;

    const oldFiles = values['old_files'] || [];
    const newFiles = values['files'] || [];
    const newLinks = values['new_links'] || [];
    const newDelFiles = values['deleted_external_links'] || [];
    const oldDelFiles = data && data.document && data.document.deleted_external_links
      ? data.document.deleted_external_links
      : [];

    Object
      .keys(values)
      .filter((x) => (!['files', 'old_files', 'new_links'].includes(x)))
      .forEach((item) => {
        if (values[item]) {
          formDataPrime[item] = values[item];
        }
      });

    const device = data.protectionDeviceData;

    if (device && isExtraPropsEdit) {
      const isProducer = metaTags?.producers?.includes(device?.producer?.id);
      const isImpTypes = metaTags?.implementation_types?.includes(device?.implementation_type?.id);
      const isTradeDevice = metaTags?.trade_devices?.includes(device?.trade_device?.id);
      const isDeviceVersion = metaTags?.versions?.includes(device?.device_version?.id);
      const deviceFunctions = device?.functions.map((func) => func.id);
      const isFunction = intersection(metaTags?.functions, deviceFunctions);

      if (!(isProducer || isImpTypes || isTradeDevice || isDeviceVersion || isFunction.length)) {
        message.error(t('SAVE_DOCUMENT_FOR_PASSPORTS_ERROR'));

        return false;
      }
    }

    this.setState({ isUploading: true, isLoading: true });

    if (data.isEdit) {
      formDataPrime.deleted_external_links = newDelFiles;
      formDataPrime.external_links = oldFiles;
      formDataPrime.is_storage = data.document.is_storage;
    } else {
      formDataPrime.deleted_external_links = [];
      formDataPrime.external_links = [];
    }
    if (newLinks && newLinks.length) {
      formDataPrime.is_storage = 'false';

      const links = (
        newLinks
          .replace(/\n+/, '\n')
          .trim()
          .split('\n')
      );
      formDataPrime.external_links = [
        ...formDataPrime.external_links,
        ...links,
      ];
    }

    const newFilesForRevert = [];

    if (
      values['storage']
      && newFiles
      && newFiles.fileList
      && newFiles.fileList.length
    ) {
      formDataPrime.is_storage = 'true';
      const selectedStorage = storages.find((item) => (item.id === values['storage']));

      for (const file of newFiles.fileList) {
        const storageSavedLink = await saveFileToStorage(t, selectedStorage, file);

        if (storageSavedLink) {
          formDataPrime.external_links.push(storageSavedLink);
          newFilesForRevert.push(storageSavedLink);
        } else {
          // Удаляем только что загруженные на хранилище файлы, поскольку пришла ошибка сохранения очередного файла.
          for (const fileRevert of newFilesForRevert) {
            await api.request(fileRevert, 'delete', undefined, {}, {}, true);
          }

          this.setState({ isUploading: false, isLoading: false });

          return;
        }
      }
    }

    if (data.isEdit) {
      const deleted_files = newDelFiles.filter((x) => !oldDelFiles.includes(x));
      const restored_files = oldDelFiles.filter((x) => !newDelFiles.includes(x));

      if (
        formDataPrime.is_storage
        && (!isEqual(newDelFiles, oldDelFiles) || deleted_files.length || restored_files.length)
      ) {
        for (const file of deleted_files) {
          await api.request(file, 'delete', undefined, {}, {}, true);
        }
        for (const file of restored_files) {
          await api.request(file, 'patch', undefined, {}, {}, true);
        }
      }

      const {
        status: patchStatus,
        data: patchResponse,
      } = await api.patchDocumentItem(data.document.id, formDataPrime, autoCreated);

      this.setState({ isUploading: false, isLoading: false });

      if (patchStatus === 200) {
        closeDocumentsUpload();
        data.callback && await data.callback(patchResponse);
        callback && await callback(patchResponse);
        metaTags && Object.keys(metaTags).length
          ? message.success(t('SAVE_DOCUMENT_FOR_SAME_PASSPORTS_SUCCESS'))
          : message.success(t('UPDATE_SUCCESS'));
      } else {
        if (patchResponse && patchResponse.error) {
          message.error(patchResponse.error);
        } else {
          metaTags && Object.keys(metaTags).length
            ? message.error(t('SAVE_DOCUMENT_FOR_PASSPORTS_ERROR'))
            : message.error(t('UPDATE_ERROR'));
        }

        // Восстанавливаем обратно все. что сделали с файлами,
        // если вдруг пришла ошибка сохранения документа
        if (
          formDataPrime.is_storage
          && (!isEqual(newDelFiles, oldDelFiles) || deleted_files.length || restored_files.length)
        ) {
          for (const file of deleted_files) {
            await api.request(file, 'patch', undefined, {}, {}, true);
          }
          for (const file of restored_files) {
            await api.request(file, 'delete', undefined, {}, {}, true);
          }
        }
        if (newFilesForRevert && newFilesForRevert.length) {
          for (const file of newFilesForRevert) {
            await api.request(file, 'delete', undefined, {}, {}, true);
          }
        }
      }
    } else {
      const { status, data: responseData } = await api.uploadDocument(
        formDataPrime,
        autoCreated !== undefined
          ? { autocreated: autoCreated }
          : {}
      );

      this.setState({ isUploading: false, isLoading: false });

      if (status === 201) {
        closeDocumentsUpload();
        data.callback && await data.callback(responseData);
        callback && await callback(responseData);
        metaTags && Object.keys(metaTags).length
          ? message.success(t('SAVE_DOCUMENT_FOR_SAME_PASSPORTS_SUCCESS'))
          : message.success(t('CREATE_SUCCESS'));
      } else if (responseData && responseData.error) {
        message.error(responseData.error);
      } else {
        metaTags && Object.keys(metaTags).length
          ? message.error(t('SAVE_DOCUMENT_FOR_PASSPORTS_ERROR'))
          : message.error(`${t('CREATE_ERROR')}. ${Array.isArray(responseData) && responseData[0]}`);
      }
    }
  };

  getCollapsedNewPanel = () => {
    const el_2 = document.getElementById('collapsedNew');
    return el_2 && el_2.classList.contains('ant-collapse-item-active');
  }

  handleSubmit = async () => {
    const { setIsChangedDataSidebar } = this.props;
    const { isExtraPropsEdit } = this.state;

    if (isExtraPropsEdit) {
      setIsChangedDataSidebar && setIsChangedDataSidebar(false);
      await this.saveDocument();
    } else {
      this.form.current.validateFields()
        .then(() => {
          const { errors } = this.state;
          if ((errors.old_files.length + errors.files.length + errors.new_links.length) === 0) {
            setIsChangedDataSidebar && setIsChangedDataSidebar(false);
            this.form.current.submit();
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error({
            _f: 'DocumentsUpload-handleSubmit - error validate fields',
            e,
          });
        });
    }
  };

  removeOldFiles = async (item) => {
    const { formValues } = this.state;
    const old_files = formValues.old_files;
    const delete_files = formValues.deleted_external_links;
    const new_old_files = [...old_files];

    const index = new_old_files.indexOf(item);
    if (index !== -1) {
      new_old_files.splice(index, 1);
      const newDeletedFiles = [...delete_files, item];

      await this.form.current.setFieldsValue({
        old_files: new_old_files,
        deleted_external_links: newDeletedFiles,
      });

      setTimeout(
        () => this.form.current.validateFields(['old_files']),
        300
      );

      this.saveValuesToStore({
        ...formValues,
        old_files: new_old_files,
        deleted_external_links: newDeletedFiles,
      });
    }
  }

  recoverOldFiles = (item) => {
    const { formValues } = this.state;
    const old_files = formValues.old_files;
    const delete_files = formValues.deleted_external_links;

    const index = delete_files.indexOf(item);
    if (index !== -1) {
      delete_files.splice(index, 1);
      const newOldFiles = [...old_files, item];

      this.form.current.setFieldsValue({
        old_files: newOldFiles,
        deleted_external_links: delete_files,
      });
      this.saveValuesToStore({
        ...formValues,
        old_files: newOldFiles,
        deleted_external_links: delete_files,
      });
    }
  }

  setName = (e) => {
    const val = e.target.value;
    const oldName = this.form.current.getFieldValue('name');

    if ((val && val.length > 0) && (oldName === '' || !oldName)) {
      const arr = val.split('\n');
      const first = (arr && arr.shift()) || '';
      try {
        const url = new URL(first);
        this.form.current.setFieldsValue({ name: (url && url.hostname) || '' });
      } catch (error) {
        // нет действий
      }
    }
  }

  handleTagsScroll = (scroll) => {
    const {
      docTags, searchDocTags, isLoading, docTagsCount,
    } = this.state;

    const scrollTopMax = scroll?.nativeEvent?.target?.scrollHeight - scroll?.nativeEvent?.target?.offsetHeight;

    if (
      !isLoading
      && docTagsCount
      && docTagsCount > docTags.length
      && scroll?.nativeEvent?.target?.scrollTop === scrollTopMax
    ) {
      this.handleTagsSearch(searchDocTags, docTags.length);
    }
  };

  handleTagsSearch = async (value, offset = 0) => {
    const { docTags, searchDocTags } = this.state;
    const isSearchValueChanged = value !== searchDocTags;

    if (isSearchValueChanged || offset === docTags.count) {
      this.setState({ isLoading: true });
      const docTagsData = await api.getTags({ name: value, offset, limit: LIMIT });
      this.setState({ isLoading: false });

      if (docTagsData.status === 200 && docTagsData.data.results) {
        this.setState({
          docTags: isSearchValueChanged ? docTagsData.data.results : unionBy(docTags, docTagsData.data.results, 'id'),
          docTagsCount: docTagsData.data.count,
          searchDocTags: value,
        });
      }
    }
  };

  renderDeletedFiles(isEdit, deleteFiles, isStorage) {
    const { t } = this.props;

    return (
      <Form.Item
        name='deleted_external_links'
        label={
          isEdit
            ? undefined
            : t('DELETED_FILES_OLD')
        }
      >
        <List
          size='small'
          itemLayout="horizontal"
          dataSource={deleteFiles}
          renderItem={(item) => (
            <List.Item
              actions={[
                <UndoOutlined onClick={() => this.recoverOldFiles(item)} />,
              ]}
            >
              {getPopoverFileNameFromLink(item, isStorage)}
            </List.Item>
          )}
        />
      </Form.Item>
    );
  }

  renderNewLink(isEdit, externalLinks) {
    const { t } = this.props;

    return (
      <Form.Item
        name='new_links'
        rules={[
          { validator: this.validateFileOrLink },
          { validator: this.validateLinks },
        ]}
        label={
          isEdit
            ? undefined
            : (
              <>
                {t('FILE_LINK')}
                <Tooltip title={t('ADD_FILE_LINK')} className={styles.libraryMsg}>
                  <QuestionCircleOutlined className={styles.libraryMsg} />
                </Tooltip>
              </>
            )
        }
      >
        <TextArea
          size='small'
          placeholder={t('ADD_FILE_LINK')}
          value={externalLinks}
          rows={6}
          onBlur={this.setName}
        />
      </Form.Item>
    );
  }

  renderNewFiles(isEdit, fileList) {
    const { t } = this.props;
    const { formValues } = this.state;

    const propsPrime = {
      onRemove: async (file) => {
        const { fileList: oldFileList } = this.state;

        if (oldFileList && oldFileList.length === 1) {
          this.form.current.setFieldsValue({
            name: '',
            files: [],
          });
        }

        const idx = oldFileList.findIndex((x) => x.uid === file.uid);
        const newFileList = cloneDeep(oldFileList);
        if (idx !== -1) {
          newFileList.splice(idx, 1);
        }

        const newFormValues = {
          ...formValues,
          files: newFileList && newFileList.length
            ? {
              ...formValues.files,
              fileList: newFileList,
            }
            : [],
        };

        await this.form.current.setFieldsValue({
          files: newFileList && newFileList.length
            ? {
              fileList: newFileList,
            }
            : [],
        });
        this.form.current.validateFields(['files']);

        this.setState({
          fileList: newFileList,
        });

        this.saveValuesToStore(newFormValues);

        return false;
      },
      beforeUpload: (file, newFileList) => {
        const { fileList: stateFileList } = this.state;
        const { name } = file;
        const oldName = this.form.current.getFieldValue('name');

        if ((stateFileList && stateFileList.length === 0) && (oldName === '' || !oldName)) {
          this.form.current.setFieldsValue({ name });
        }

        const files = [
          ...stateFileList,
          ...newFileList,
        ];
        const newFormValues = {
          ...formValues,
          files,
        };

        this.setState({
          fileList: files,
          formValues: newFormValues,
        });

        return false;
      },
    };

    const warning = formValues?.files?.fileList?.filter(
      (item) => item.name.length > 133
    );

    return (
      <div className={warning ? styles.divider: ''}>
        <Form.Item
          name='files'
          rules={[
            { validator: this.validateFileOrLink },
            { validator: this.validateMinFileSize },
            { validator: this.validateMaxFileSize },
          ]}
          label={
            isEdit
              ? undefined
              : t('UPLOADED_FILES')
          }
          className={styles.form}
        >
          <Upload
            {...propsPrime}
            multiple={true}
            fileList={fileList}
            // TODO: заготовка на будущее, если потребуется загружать ограниченный тип документов
            // accept: accept,
          >
            <Button size='small'>
              <UploadOutlined />
              {
                isEdit
                  ? t('UPLOAD_MORE')
                  : t('UPLOAD')
              }
            </Button>
          </Upload>
        </Form.Item>
        {warning?.length > 0 ? (
          <Text type="warning" className={styles.warning}>
            {t('FILE_NAME_CUT')}
          </Text>
        ) : <div className={styles.divider}/>}
      </div>
    );
  }

  renderPassports = (currentPassportId, idx) => {
    const { t } = this.props;
    const {
      currentPassportsList, allPassportsList, passportsList,
      onlySelected, selectedPassportsList, selectedPassportsCount, isDevicesLoading
    } = this.state;

    const passportFieldName = `passport[${currentPassportId || idx}]`;

    const isCurrentPassport = !!currentPassportId;
    const passports = (
      currentPassportId
        ? currentPassportsList
        : (
          onlySelected
            ? selectedPassportsList
            : allPassportsList
        )
    );

    return (
      <div key={`div_${currentPassportId}_${idx}`} className={styles.itemPassport}>
        <Form.Item
          label={false}
          key={`passport_${currentPassportId}_${idx}`}
          name={passportFieldName}
          initialValue={currentPassportId}
        >
          <Select
            key={`passport_${currentPassportId}__${idx}_select`}
            size='small'
            loading={isDevicesLoading}
            disabled={currentPassportsList[currentPassportId] !== undefined}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            title={t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE_SEARCH')}
            placeholder={t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE_SELECTED')}
            defaultActiveFirstOption={false}
            filterOption={false}
            listItemHeight={10}
            listHeight={250}
            notFoundContent={<Empty description={isDevicesLoading ? t('DEVICES_LOADING') : t('DEVICES_NOT_FOUND')}/>}
            showSearch={true}
            onChange={
              (value, option) => this.onChangeDevice(
                value,
                option,
                currentPassportId
              )
            }
            onDropdownVisibleChange={(x) => x && this.onSearchDevice('', isCurrentPassport)}
            onPopupScroll={(event) => (
              onlySelected
                ? {}
                : this.onScrollDeviceSelector(event, isCurrentPassport)
            )}
            onSearch={debounce(
              isCurrentPassport
                ? this.onSearchCurrentDevices
                : (
                  onlySelected
                    ? this.onSearchSelectedDevices
                    : this.onSearchAllDevices
                ),
              800
            )}
          >
            {
              Object.keys(passports).map((key) => {
                const item = passports[key];
                const itemPassport = !isCurrentPassport && onlySelected
                  ? item.passport && item.passport[0] && item.passport[0].id
                  : item.passports && item.passports[0] && item.passports[0].id;
                return (
                  <Select.Option
                    key={`passport_${currentPassportId}__${idx}_select_${itemPassport}`}
                    value={itemPassport}
                    disabled={isDevicesLoading || (passportsList && passportsList.includes(itemPassport))}
                  >
                    <PopoverEllipsis
                      content={item.name}
                      placement='left'
                    >
                      {item.name}
                    </PopoverEllipsis>
                  </Select.Option>
                );
              })
            }
          </Select>
        </Form.Item>
        {
          currentPassportId
            ? (
              <>
                <Popconfirm
                  key={`passport_${currentPassportId}_confirm`}
                  title={t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE_CONFIRM')}
                  onConfirm={
                    () => this.onChangeDevice(
                      undefined,
                      idx,
                      currentPassportId
                    )
                  }
                  okText={t('YES')}
                  cancelText={t('NO')}
                >
                  <Button
                    key={`passport_${currentPassportId}_confirm_button`}
                    size='small'
                    icon={<DeleteOutlined />}
                    className={styles.deletePassport}
                    title={t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE_DEL')}
                  />
                </Popconfirm>
              </>
            )
            : (
              <Button
                key={`passport_${idx}_confirm_button`}
                size='small'
                icon={<DeleteOutlined />}
                className={styles.deletePassport}
                title={t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE_DEL')}
                onClick={() => this.setState({ isAddPassport: false })}
              />
            )
        }
        {
          !isCurrentPassport && selectedPassportsCount
            ? (
              <div>
                <Checkbox
                  checked={onlySelected === true}
                  onChange={() => this.changeOnlySelected(!onlySelected)}
                >
                  {t('PASSPORT_DOCS_TABLE_UPLOADS_ONLY_SELECTED')}
                </Checkbox>
              </div>
            )
            : ''
        }
      </div>
    );
  }

  renderButtonAddPassport = () => {
    const { t } = this.props;
    const { isAddPassport, addButtonNumber } = this.state;

    return isAddPassport
      ? this.renderPassports(
        null,
        'add_'.concat(addButtonNumber)
      )
      : (
        <>
          <hr />
          <Button
            type='button'
            className={styles.addPassport}
            key='addPassport'
            onClick={() => this.addPassport()}
          >
            {t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE_ADD')}
          </Button>
        </>
      );
  }

  renderFilePanels = (isTaskDCDocument = false) => {
    const { t, data } = this.props;
    const { uploadType, formValues, errors } = this.state;
    const {
      old_files: oldFiles,
      deleted_external_links: deleteFiles,
      external_links: externalLinks,
      files,
    } = formValues;

    const fileList = files && files.fileList;
    const isStorage = isTaskDCDocument || uploadType === UPLOAD_TYPE_FILE;

    return (
      data.isEdit
        ? (
          <>
            <Collapse
              id='collapsedAll'
              accordion={true}
              bordered={false}
              expandIconPosition='right'
              size='small'
            >
              <Collapse.Panel
                header={isStorage ? t('UPLOADED_FILES_OLD') : t('UPLOADED_LINKS_OLD')}
                key="UPLOADED_FILES_OLD"
                className={oldFiles && oldFiles.length ? '' : 'hidden'}
                size='small'
              >
                {this.renderOldFiles(data.isEdit, oldFiles, isStorage)}
              </Collapse.Panel>
              <Collapse.Panel
                header={isStorage ? t('DELETED_FILES_OLD') : t('DELETED_LINKS_OLD')}
                key="DELETED_FILES_OLD"
                className={deleteFiles && deleteFiles.length ? '' : 'hidden'}
                size='small'
              >
                {this.renderDeletedFiles(data.isEdit, deleteFiles, isStorage)}
              </Collapse.Panel>
              <Collapse.Panel
                id='collapsedNew'
                className={
                  (errors.old_files.length + errors.files.length + errors.new_links.length) === 0
                    ? ''
                    : styles.errorBordered
                }
                header={
                  isStorage
                    ? t('UPLOADED_MORE_FILES')
                    : (
                      <>
                        {t('UPLOADED_MORE_LINKS')}
                        <Tooltip title={t('ADD_FILE_LINK')} className={styles.libraryMsg}>
                          <QuestionCircleOutlined className={styles.libraryMsg} />
                        </Tooltip>
                      </>
                    )
                }
                key="UPLOADED_MORE_FILES"
              >
                {
                  isStorage
                    ? this.renderNewFiles(data.isEdit, fileList)
                    : this.renderNewLink(data.isEdit, externalLinks)
                }
              </Collapse.Panel>
            </Collapse>
            <div
              id='errorField'
              style={{ color: 'red' }}
            >
              {errors.old_files}
              {errors.files}
              {
                errors.new_links && errors.new_links.length
                  ? errors.new_links.join('. ').trim('. ')
                  : ''
              }
            </div>
            <br />
          </>
        )
        : (
          isStorage
            ? this.renderNewFiles(data.isEdit, fileList)
            : this.renderNewLink(data.isEdit, externalLinks)
        )
    );
  }

  renderOldFiles(isEdit, oldFiles, isStorage) {
    const { t } = this.props;

    return (
      <Form.Item
        name='old_files'
        rules={[{ validator: this.validateFileOrLink }]}
        label={
          isEdit
            ? undefined
            : t('UPLOADED_FILES_OLD')
        }
      >
        <List
          size='small'
          itemLayout="horizontal"
          dataSource={oldFiles}
          renderItem={(item) => (
            <List.Item
              actions={[
                <DeleteOutlined onClick={() => this.removeOldFiles(item)} />,
              ]}
            >
              {getPopoverFileNameFromLink(item, isStorage)}
            </List.Item>
          )}
        />
      </Form.Item>
    );
  }

  render() {
    const {
      t, data, rights, user, width, isArchived, taskStatus
    } = this.props;
    const {
      isLoading, uploadType, docTypes, docStatuses, docTags, storages, isUploading,
      isExtraPropsEdit, metaTags, docType, formValues, passportsList,
    } = this.state;
    const { library_type: storageType } = formValues;

    const storagesForCurrentType = storageType && storages && storages.filter(
      (storage) => storage.type === storageType.toLowerCase()
    );
    const storagesForLocal = storages && storages.filter(
      (storage) => storage.type.toLowerCase() === LIBRARY_LOCAL.toLowerCase()
    );
    const storagesInfoForLocal = (
      storagesForLocal && storagesForLocal.length === 1
        ? <StorageState record={storagesForLocal[0]} withInfo={false} type='progress' />
        : ''
    );
    const storagesForGlobal = storages && storages.filter(
      (storage) => storage.type.toLowerCase() === LIBRARY_GLOBAL.toLowerCase()
    );
    const storagesInfoForGlobal = (
      storagesForGlobal && storagesForGlobal.length === 1
        ? <StorageState record={storagesForGlobal[0]} withInfo={false} type='progress' />
        : ''
    );

    const userDispatcherCenterId = (
      user
      && user.dispatcher_center
      && user.dispatcher_center.id
    );
    const storagesForAlterType = storages.filter(
      (storage) => {
        return (
          storage.type === (
            storageType === LIBRARY_LOCAL
              ? LIBRARY_GLOBAL
              : LIBRARY_LOCAL
          ).toLowerCase()
        )
        && ((storage.dispatcher_center && storage.dispatcher_center.id) === userDispatcherCenterId);
      }
    );

    const disableLibraryType = !(
      rights[RIGHT_DOCUMENT_GLOBAL_STORAGE] === MODE_EDIT
      || storagesForAlterType.length
    );

    const disableLibrary = (docType && docType.name) === t('TASK_DC')
      || (docType && docType.name === t('NON_DIGITAL_SETPOINT_BLANK'));

    const disableNotCduUser = user && user.dispatcher_center && user.dispatcher_center.name !== t('CDA');

    const disabledMeta = (passportsList && passportsList.length > 1) || disableLibrary || disableNotCduUser;

    const disableSaveButton = data.showPassportsList && (!passportsList || passportsList.length === 0);

    const disabledMetatagsButtonByRights = rights[RIGHT_METATAGS_DOCS_ADD] !== MODE_EDIT;

    const isEditRight = rights[RIGHT_PASSPORT_DOCS] === MODE_EDIT && !isArchived;
    const disableButtonsByTaskStatus = (IS_ISSUED_ARCHIVE === taskStatus);

    return data.type !== DOC_SIDEBAR_TASK_CREATE_TASK
      ? (
        <SidebarLayout
          withoutCard
          sidebarStyle={{ width: `${width}px` }}
          header={<h4>{data.header || (data.isEdit ? t('EDIT_DOCUMENT') : t('CREATE_DOCUMENT'))}</h4>}
          footer={(
            <>
              {
                isExtraPropsEdit
                  ? (
                    <>
                      <Popconfirm
                        placement='topLeft'
                        title={t('SAVE_DOCUMENT_BACK_CONFIRM')}
                        okText={t('YES')}
                        cancelText={t('NO')}
                        onConfirm={this.editMainProps}
                      >
                        <Tooltip
                          title={`${t('GO_BACK_SAVE_DOCUMENT')} ${t('FOR_CURRENT_PASSPORT')}`}
                          className={styles.tooltipBtn}
                        >
                          <Button
                            block
                            className='bordered'
                          >
                            {t('BACK')}
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                      <Popconfirm
                        placement='topLeft'
                        title={t('SAVE_DOCUMENT_FOR_PASSPORTS')}
                        okText={t('YES')}
                        cancelText={t('NO')}
                        onConfirm={this.handleSubmit}
                      >
                        <Tooltip
                          title={`${t('SAVE_PUBLIC_DOCUMENT')} ${t('FOR_SAME_PASSPORTS')}`}
                          className={styles.tooltipBtn}
                        >
                          <Button
                            block
                            type='primary'
                            autoFocus
                            disabled={isUploading}
                            loading={isUploading}
                          >
                            {t('SAVE_PUBLIC_DOCUMENT')}
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )
                  : (
                    <>
                      <Tooltip
                        title={disableNotCduUser ? t('ONLY_CDA') : t('EXTRA_PROPERTIES_MSG')}
                        className={styles.tooltipBtn}
                      >
                        <Button
                          block
                          className='bordered'
                          disabled={disabledMeta || disableSaveButton || disabledMetatagsButtonByRights}
                          onClick={this.editExtraProps}
                        >
                          {t('METATAGS')}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={`${data.isEdit ? t('SAVE') : t('PUBLISH')} ${t('FOR_CURRENT_PASSPORT')}`}
                        className={styles.tooltipBtn}
                      >
                        {data.isEdit ? (
                          <Button
                            block
                            type="primary"
                            autoFocus
                            onClick={this.handleSubmit}
                            disabled={disableSaveButton || isUploading}
                            loading={isUploading}
                          >
                            {t('SAVE')}
                          </Button>
                        ) : (
                          <Button
                            block
                            type="primary"
                            autoFocus
                            onClick={this.handleSubmit}
                            disabled={
                              !isEditRight ||
                              disableButtonsByTaskStatus ||
                              disableSaveButton ||
                              isUploading
                            }
                            loading={isUploading}
                          >
                            {t('PUBLISH')}
                          </Button>
                        )}
                      </Tooltip>
                    </>
                  )
              }
            </>
          )}
        >
          <Spin spinning={isLoading}>
            {isExtraPropsEdit
              ? (
                <DocumentExtraPropsForm
                  protectionDeviceData={data.protectionDeviceData}
                  setExtraDocumentProps={this.setExtraDocumentProps}
                  metaTags={metaTags}
                />
              )
              : (
                <Form
                  ref={this.form}
                  name='FormDocumentsUpload'
                  // preserve={true}
                  onFinish={async () => this.saveDocument()}
                  onValuesChange={
                    (changedFields, allFields) => this.saveValuesToStore(allFields)
                  }
                >
                  <Radio.Group
                    onChange={this.handleUploadTypeChange}
                    value={uploadType}
                    className={styles.uploadTypeSelect}
                    disabled={data.isEdit}
                  >
                    <Radio value={UPLOAD_TYPE_FILE}>{t('FILE')}</Radio>
                    <Radio value={UPLOAD_TYPE_LINK}>{t('FILE_LINK')}</Radio>
                  </Radio.Group>
                  <Form.Item
                    name='library'
                    rules={[{ required: true, message: t('LIBRARY_MESSAGE') }]}
                    label={(
                      <>
                        {t('LIBRARY')}
                        <Tooltip title={t('LIBRARY_MSG')} className={styles.libraryMsg}>
                          <QuestionCircleOutlined className={styles.libraryMsg} />
                        </Tooltip>
                      </>
                    )}
                  >
                    <CustomSelect
                      size='small'
                      disabled={disableLibrary || !user.roles.some(role => role.key === 'ADMINISTRATORIA')}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      popoverPlacement='left'
                    >
                      <Option value={LIBRARY_LOCAL} key={LIBRARY_LOCAL}>{t('LIBRARY_LOCAL')}</Option>
                      <Option value={LIBRARY_GLOBAL} key={LIBRARY_GLOBAL}>{t('LIBRARY_GLOBAL')}</Option>
                    </CustomSelect>
                  </Form.Item>
                  {
                    uploadType === UPLOAD_TYPE_FILE
                      ? (
                        <>
                          <Form.Item
                            name='library_type'
                            rules={[{ required: true, message: t('LIBRARY_TYPE_MESSAGE') }]}
                            label={(
                              <>
                                {t('LIBRARY_TYPE')}
                                <Tooltip title={t('LIBRARY_TYPE_MSG')} className={styles.libraryMsg}>
                                  <QuestionCircleOutlined className={styles.libraryMsg} />
                                </Tooltip>
                              </>
                            )}
                            validateStatus={!storagesForCurrentType.length ? 'error' : undefined}
                            help={!storagesForCurrentType.length ? t('THERE_IS_NO_STORAGE') : undefined}
                          >
                            <CustomSelect
                              size='small'
                              disabled={disableLibraryType || data.isEdit}
                              getPopupContainer={(triggerNode) => triggerNode.parentNode}
                              onChange={(value) => this.handleChangeLibraryType(value)}
                              popoverPlacement='left'
                            >
                              <Option value={LIBRARY_LOCAL} key={LIBRARY_LOCAL}>
                                {t('LIBRARY_TYPE_LOCAL')}
                                <span style={{ marginLeft: '20px' }}>
                                  {storagesInfoForLocal}
                                </span>
                              </Option>
                              <Option value={LIBRARY_GLOBAL} key={LIBRARY_GLOBAL}>
                                {t('LIBRARY_TYPE_GLOBAL')}
                                <span style={{ marginLeft: '20px' }}>
                                  {storagesInfoForGlobal}
                                </span>
                              </Option>
                            </CustomSelect>
                          </Form.Item>
                          <Form.Item
                            name='storage'
                            rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                            label={t('STORAGE')}
                            className={
                              storagesForCurrentType.length <= 1
                                ? styles.hidden
                                : undefined
                            }
                          >
                            <CustomSelect
                              size='small'
                              placeholder={t('SELECT_STORAGE')}
                              disabled={data.isEdit}
                              popoverPlacement='left'
                            >
                              {
                                storagesForCurrentType.map((item) => (
                                  <Select.Option value={item.id} key={item.id}>
                                    {item.url}
                                    <span style={{ paddingLeft: '20px' }}>
                                      <StorageState record={item} type='progress' withInfo={false} />
                                    </span>
                                  </Select.Option>
                                ))
                              }
                            </CustomSelect>
                          </Form.Item>
                          {
                            data && data.isEdit
                              ? (
                                <div className={styles.owner_dc}>
                                  {t('PASSPORT_DOCUMENTS_TABLE_COL_OWNER_DC')}:
                                  <span className={styles.owner_dc_text}>
                                    {
                                      data
                                      && data.document
                                      && data.document.dispatcher_center
                                      && data.document.dispatcher_center.name
                                    }
                                  </span>
                                </div>
                              )
                              : ''
                          }
                        </>
                      )
                      : ''
                  }
                  {
                    this.renderFilePanels()
                  }
                  <Form.Item
                    name='name'
                    rules={[{ required: true, message: t('DOC_NAME_MESSAGE') }]}
                    label={t('DOC_NAME')}
                  >
                    <Input size='small' placeholder={t('ADD_DOC_NAME')} />
                  </Form.Item>
                  <Form.Item
                    name='document_type'
                    rules={[
                      { required: true, message: t('FILE_TYPE_MESSAGE') },
                      {
                        validator(rules, value) {
                          const passportsCount = passportsList && passportsList.length;
                          const docTypeValue = docTypes && docTypes.length && docTypes.find((x) => x.id === value);

                          if (value && docTypeValue && docTypeValue.name === t('NON_DIGITAL_SETPOINT_BLANK') && passportsCount > 1) {
                            return Promise.reject(new Error(t('PASSPORT_DOCS_TABLE_DOCUMENT_TYPE_ERROR_PASSPORTS_COUNT')));
                          } else if (value && docTypeValue && docTypeValue.name === t('NON_DIGITAL_SETPOINT_BLANK') && passportsCount <= 1) {
                            return Promise.resolve();
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    label={t('DOCUMENT.COL_DOCUMENT_TYPE')}
                  >
                    <CustomSelect
                      size='small'
                      placeholder={t('FILE_TYPE_MESSAGE')}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      onChange={(value) => this.handleChangeType(value)}
                      popoverPlacement='left'
                    >
                      {
                        docTypes.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))
                      }
                    </CustomSelect>
                  </Form.Item>
                  <Form.Item
                    name='document_status'
                    label={t('DOCUMENT_STATUS')}
                  >
                    <CustomSelect
                      size='small'
                      placeholder={t('SELECT_DOCUMENT_STATUS')}
                      popoverPlacement='left'
                    >
                      {docStatuses.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </CustomSelect>
                  </Form.Item>
                  <Form.Item
                    name='tags'
                    label={t('TAGS')}
                  >
                    <CustomSelect
                      size='small'
                      mode='multiple'
                      placeholder={t('SELECT_TAGS')}
                      popoverPlacement='left'
                      allowClear
                      showSearch
                      onSearch={debounce(this.handleTagsSearch, 800)}
                      onPopupScroll={this.handleTagsScroll}
                      filterOption={false}
                      onChange={() => this.handleTagsSearch(undefined)}
                    >
                      {
                        docTags.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))
                      }
                    </CustomSelect>
                  </Form.Item>
                  {
                    (
                      data.showPassportsList
                      && (
                        metaTags === null
                        || !data.document?.meta_tags
                        || Object.keys(data.document?.meta_tags).length === 0
                      )
                    )
                      ? (
                        <>
                          <hr />
                          {t('PASSPORT_DOCS_TABLE_UPLOADS_FIELD_DEVICE')}:
                          {
                            passportsList
                            && passportsList.length
                              ? (
                                passportsList.map(
                                  (passportId, idx) => this.renderPassports(
                                    passportId,
                                    idx
                                  )
                                )
                              )
                              : ''
                          }
                          {
                            this.renderButtonAddPassport()
                          }
                        </>
                      )
                      : ''
                  }
                </Form>
              )}
          </Spin>
        </SidebarLayout>
      )
      : (
        <Spin spinning={isLoading}>
          <Form
            ref={this.form}
            name='FormDocumentsUpload'
            onValuesChange={
              (changedFields, allFields) => this.saveValuesToStore(allFields)
            }
          >
            {this.renderFilePanels(true)}
          </Form>
        </Spin>
      );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings.data,
  rights: state.rights.rightsData,
  user: state.user.info,
  bucketList: state.bucket.bucketData,
  bucketCount: state.bucket.count,
});

const mapDispatchToProps = {
  closeDocumentsUpload: closeSidebar,
  getSelectedPassports: getBucket,
  setIsChangedDataSidebar: setIsChangedSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    DocumentsUpload
  )
);
