export const assembleTargetLink = ({ link, entity, entityNameSingle, record }) => {
  return link
    .replace(`#${entityNameSingle}_device_id`, record?.passport?.protection_device?.id || '')
    .replace(`#${entityNameSingle}_id`, entity?.id)
    .replace(`#${entityNameSingle}_name`, entity?.name)
    .replace('#record_id', record.id)
    .replace('#record_name', record.name);
};

export const assembleTitle = ({ protection_device, currentDeviceId, t }) => {
  if (protection_device && protection_device.id && protection_device.name) {
    const { id, name } = protection_device;

    if (id === currentDeviceId) return t('PASSPORT_TASKS_BLANK_LINK_TO_CURRENT_PASSPORT');
    else return name;

  } else return undefined;
};


export const getRealPassportsCount = (data, currentDeviceId) => {
  if (!data?.length) return 0;
  if (data.length === 1 && data.find(item => item.passport?.protection_device?.id === currentDeviceId))
    return 0;
  return data.length;
}