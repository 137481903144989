import api from '@services/api';

export const COLUMN_WIDTH = 340;
export const LIMIT = 100;
export const LEVEL_DC = 'DC';
export const LEVEL_SUBSTATION = 'SUBSTATION';
export const LEVEL_EQUIPMENT_TYPE = 'EQUIPMENT_TYPE';
export const LEVEL_EQUIPMENT = 'EQUIPMENT';
export const LEVEL_DEVICE = 'DEVICE';

const columns = [
  {
    // index = 0 - диспетчерские центры
    levelName: LEVEL_DC,
    load: async (pattern, innerColumns, extraParams = {}) => {
      const params = {
        limit: LIMIT,
        name: pattern === '' ? undefined : pattern,
        ...extraParams,
      };
      const { status, data } = await api.getDispatcherCentersByName(params);
      if (status === 200) {
        return data;
      }
      return [];
    },
    add: async (name) => {
      const { status, data } = await api.addDispatcherCenters(name);
      if (status === 201) {
        return data;
      }
    },
    update: async (id, name) => {
      const { status, data } = await api.updateDispatcherCenters(id, name);
      if (status === 200) {
        return data;
      }
    },
    delete: (id) => api.deleteDispatcherCenters(id),
  },

  {
    // 2 - энергообъекты
    levelName: LEVEL_SUBSTATION,
    load: async (pattern, innerColumns, extraParams = {}) => {
      if (
        innerColumns
        && innerColumns[0]
        && innerColumns[0].activeElement
      ) {
        const params = {
          limit: LIMIT,
          name: pattern === '' ? undefined : pattern,
          dispatcher_center: innerColumns[0].activeElement.id,
          ...extraParams,
        };
        const { status, data } = await api.getSubstationsByName(params);
        if (status === 200) {
          return data;
        }
      }
      return [];
    },
    add: async (name, innerColumns) => {
      if (
        innerColumns
        && innerColumns[0]
        && innerColumns[0].activeElement
      ) {
        const dc = innerColumns[0].activeElement.id;
        const { status, data } = await api.addSubstation(name, dc);
        if (status === 201) {
          return data;
        }
      }
    },
    update: async (id, name) => {
      const { status, data } = await api.updateSubstation(id, name);
      if (status === 200) {
        return data;
      }
    },
    delete: (id) => api.deleteSubstation(id),
  },

  {
    // 3 типы первичного оборудования
    levelName: LEVEL_EQUIPMENT_TYPE,
    load: async (pattern, innerColumns, extraParams = {}) => {
      if (
        innerColumns
        && innerColumns[1]
        && innerColumns[1].activeElement
      ) {
        const params = {
          limit: LIMIT,
          name: pattern === '' ? undefined : pattern,
          substation: innerColumns[1].activeElement.id,
          ...extraParams,
        };
        const { status, data } = await api.getEquipmentTypes(params);
        if (status === 200) {
          return {
            results: data.results,
            next: data.next,
            previous: data.previous,
          };
        }
      }
      return api.emptyAnswerWithNext().data;
    },
    loadForEdit: async (pattern) => {
      const params = {
        limit: LIMIT,
        name: pattern === '' ? undefined : pattern,
      };
      const { status, data } = await api.getEquipmentTypes(params);
      if (status === 200) {
        return {
          results: data.results,
          next: data.next,
          previous: data.previous,
        };
      } else {
        return api.emptyAnswerWithNext().data;
      }
    },
  },

  {
    // 4 первичное оборудование
    levelName: LEVEL_EQUIPMENT,
    load: async (pattern, innerColumns, extraParams = {}) => {
      if (
        innerColumns
        && innerColumns[1]
        && innerColumns[1].activeElement
        && innerColumns[2]
        && innerColumns[2].activeElement
      ) {
        const params = {
          limit: LIMIT,
          name: pattern === '' ? undefined : pattern,
          substation: innerColumns[1].activeElement.id,
          type: innerColumns[2].activeElement.id,
          ...extraParams,
        };
        const { status, data } = await api.getEquipmentBase(params);
        if (status === 200) {
          return data;
        }
      }
      return [];
    },
    add: async (name, innerColumns) => {
      if (
        innerColumns
        && innerColumns[1]
        && innerColumns[1].activeElement
        && innerColumns[2]
        && innerColumns[2].activeElement
      ) {
        const params = {
          name,
          substation: innerColumns[1].activeElement.id,
          type: innerColumns[2].activeElement.id,
        };
        const { status, data } = await api.addEquipmentBase(params);
        if (status === 201) {
          return data;
        }
      }
    },
    update: async (id, name) => {
      const { status, data } = await api.updateEquipmentBase(id, name);
      if (status === 200) {
        return data;
      }
    },
    delete: (id) => api.deleteEquipmentBase(id),
  },

  {
    // 5 устройство РЗА
    levelName: LEVEL_DEVICE,
    load: async (pattern, innerColumns, extraParams = {}) => {
      if (
        innerColumns
        && innerColumns[1]
        && innerColumns[1].activeElement
        && innerColumns[3]
        && innerColumns[3].activeElement
      ) {
        const params = {
          limit: LIMIT,
          name: pattern === '' ? undefined : pattern,
          substation: innerColumns[1].activeElement.id,
          equipment: innerColumns[3].activeElement.id,
          ...extraParams,
        };
        const { status, data } = await api.getProtectionDeviceList(params);
        if (status === 200) {
          return data;
        }
      }
      return [];
    },
    add: async (name, innerColumns) => {
      if (
        innerColumns
        && innerColumns[0]
        && innerColumns[0].activeElement
        && innerColumns[1]
        && innerColumns[1].activeElement
        && innerColumns[3]
        && innerColumns[3].activeElement
      ) {
        const params = {
          name,
          dispatcher_center: innerColumns[0].activeElement.id,
          substation: innerColumns[1].activeElement.id,
        };
        if (innerColumns[3].activeElement.id !== api.getNullUuid()) {
          params.base_equipment = innerColumns[3].activeElement.id;
        }
        const { status, data } = await api.addProtectionDevice(params);
        if (status === 201) {
          return data;
        }
      }
    },
    update: async (id, name) => {
      const { status, data } = await api.patchProtectionDevice(id, { name });
      if (status === 200) {
        return data;
      }
    },
    delete: (id) => api.deleteProtectionDevice(id),
  },
];

export const COLUMN_COUNT = columns.length;

for (let i = 0; i < COLUMN_COUNT; i += 1) {
  const item = columns[i];
  item.level = i;
  // Включение прогресс-бара загрузки данных внизу при постраничной загрузке
  // item.extendLoader = true;
}

export const COLUMNS = [...columns];
