import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isArchived } from '@app/helpers';
import {
  LIMIT, /* ROLE_KEY_ADMIN_IA, ROLE_KEY_ADMIN_ODU, ROLE_KEY_ADMIN_RDU, Временное решение для задачи DBRZA-2667 */
} from '@app/constants';
import api from '@services/api';
import storage from '@services/storage';
import { setupGlobalSearchFilters } from '@state/search/actions';
import store from '@state/store';
import { Layout } from '@ui/index';

import { globalSearchTableNamePrefix } from './constants';
import { Tab as TabDevices, filters as tabDevicesFilter, prefix as tabDevicesPrefix } from './tabs/devices';
import { Tab as TabDocuments, filters as tabDocumentsFilter, prefix as tabDocumentsPrefix } from './tabs/documents';
import { Tab as TabTasks, filters as tabTasksFilter, prefix as tabTasksPrefix } from './tabs/tasks';

const ALL_FILTERS = [...tabDevicesFilter, ...tabDocumentsFilter, ...tabTasksFilter];

const TABS = [
  {
    url: 'protection-devices',
    name: 'GLOBAL_SEARCH_DEVICES',
    prefix: tabDevicesPrefix,
    tabComponent: TabDevices,
    filters: tabDevicesFilter,
    allFilters: ALL_FILTERS,
    search:
      (url, name, limit = LIMIT, offset = 0, filters = null) => {
        return api.getPostDataForFilter(
          `/v1/${url}/post_list/`,
          {
            search: name,
            ...filters,
            ordering: 'name',
            is_archived: isArchived(),
          },
          limit,
          offset
        );
      },
  },
  {
    url: 'regions',
    name: 'GLOBAL_SEARCH_TERRITORY',
    results: 5,
    search: null,
  },
  { url: 'owners', name: 'GLOBAL_SEARCH_OWNERS', search: null },
  { url: 'dispatcher_centers', name: 'GLOBAL_SEARCH_DC', search: null },
  {
    url: 'documents',
    name: 'GLOBAL_SEARCH_DOCUMENTS',
    prefix: tabDocumentsPrefix,
    tabComponent: TabDocuments,
    filters: tabDocumentsFilter,
    allFilters: ALL_FILTERS,
    search:
      (url, name, limit = LIMIT, offset = 0, filters = null) => {
        return api.getPostDataForFilter(
          `/v1/${url}/post_list/`,
          {
            search: name,
            ...filters,
            ordering: 'name',
            is_archived: isArchived(),
          },
          limit,
          offset
        );
      },
  },
  {
    url: 'task-dcs',
    name: 'GLOBAL_SEARCH_TASKS',
    prefix: tabTasksPrefix,
    tabComponent: TabTasks,
    filters: tabTasksFilter,
    allFilters: ALL_FILTERS,
    search:
      (url, name, limit = LIMIT, offset = 0, filters = null) => {
        return api.getDataForFilter(
          `/v1/${url}/`,
          {
            name: name,
            ...filters,
            // ordering: 'name',
            is_archived: isArchived(),
          },
          limit,
          offset
        );
      },
  },
];

class GlobalSearch extends Component {
  constructor(props) {
    super(props);

    const tabList = [];

    for (let i = 0; i < TABS.length; i += 1) {
      const tab = TABS[i];
      if (Object.prototype.hasOwnProperty.call(tab, 'tabComponent')) {
        tabList.push(tab);
      }
    }

    this.state = {
      tabList: tabList,
      counts: {},
      filters: {},
    };
  }

  componentDidMount() {
    this.updateFilters();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { searchValue, searchFilter, searchFilterLoading, searchReload } = this.props;
    const { tabList, filters } = this.state;

    if (
      JSON.stringify(prevProps.searchFilter) !== JSON.stringify(searchFilter)
      || prevProps.searchFilterLoading !== searchFilterLoading
    ) {
      this.updateFilters();
      return;
    }

    if ((prevProps.searchValue !== searchValue || searchReload)
      || (JSON.stringify(prevState.tabList) !== JSON.stringify(tabList) && tabList && tabList.length)
      || (JSON.stringify(prevState.filters) !== JSON.stringify(filters) && Object.keys(filters).length)
    ) {
      await this.updateTabListCount();
    }
  }

  updateFilters = () => {
    const { searchFilter, searchFilterLoading } = this.props;
    const { tabList } = this.state;

    const filters = {};

    if (!searchFilterLoading && searchFilter) {
      const globalSearchFilter = JSON.parse(searchFilter);
      tabList.forEach((tab) => {
        const tableName = `${globalSearchTableNamePrefix}${tab.prefix.toLowerCase()}`;
        const storageValue = storage.get(`columnsValues_${tableName}`);
        filters[tab.prefix] = storageValue || globalSearchFilter[tab.prefix] || {};
      })
    }

    this.setState({
      filters,
    });
  }

  updateTabListCount = async () => {
    const {
      searchValue, searchFilterLoading, history, match,
    } = this.props;
    const { tabList, filters } = this.state;

    /** Пока идет процесс установки глобальных фильтров - не делать запросы */
    if (searchFilterLoading) {
      return;
    }

    const tab = (match && match.params && match.params.tab) || (history.location.state && history.location.state.tab);

    const filteredTab = tabList.filter(
      (item) => ((`${item.url}` !== tab) && item.search && typeof item.search === 'function')
    );

    for (let i = 0; i < filteredTab.length; i += 1) {
      let currentFilters = {};
      const item = filteredTab[i];

      if (filters[item.prefix]) {
        currentFilters = this.filterValuesForRequest(filters[item.prefix], true);
      }

      const result = await item.search(item.url, searchValue, 1, 0, currentFilters);

      if (result.status === 200) {
        this.changeItemsCount(item.url, result.data.count);
      }
    }
  }

  changeItemsCount = (url, count) => {
    const { counts } = this.state;
    this.setState({ counts: { ...counts, [url]: count } });
  }

  filterValuesForRequest = (filterValues, global = false) => {
    const values = {};

    Object.keys(filterValues).forEach((index) => {
      const currentFilter = ALL_FILTERS && ALL_FILTERS.find((x) => x.name === index);

      const val = global
        ? filterValues[index]
        : filterValues[index].value;

      if (
        val
        && currentFilter
        && currentFilter.queryFiled !== ''
      ) {
        if (
          Array.isArray(val)
          && currentFilter.type === 'DATE_RANGE'
          && currentFilter.queryFiled.indexOf('date') >= 0
        ) {
          if (
            currentFilter.suffix
            && Array.isArray(currentFilter.suffix)
            && currentFilter.suffix.length === 2
          ) {
            values[`${currentFilter.queryFiled}_${currentFilter.suffix[0]}`] = val[0];
            values[`${currentFilter.queryFiled}_${currentFilter.suffix[1]}`] = val[1];
          } else {
            values[`${currentFilter.queryFiled}_after`] = val[0];
            values[`${currentFilter.queryFiled}_before`] = val[1];
          }
        } else {
          values[currentFilter.queryFiled] = val;
        }
      }
    });

    return values;
  }

  changeFilters = (newFilters, prefix) => {
    const { filters } = this.state;

    filters[prefix] = newFilters;

    store.dispatch(setupGlobalSearchFilters(JSON.stringify(filters)));
  }

  render() {
    const { counts, tabList, filters } = this.state;
    // const { t, match, userInfo } = this.props; Временное решение для задачи DBRZA-2667
    const { t, match } = this.props; // Временное решение для задачи DBRZA-2667
    const tab = match && match.params && match.params.tab;
    const tabsForRender = [...tabList]; // Временное решение для задачи DBRZA-2667

    /* Временное решение для задачи DBRZA-2667
    let tabsForRender = [...tabList];

    if ([
      ROLE_KEY_ADMIN_IA,
      ROLE_KEY_ADMIN_ODU,
      ROLE_KEY_ADMIN_RDU,
    ].includes(userInfo.role.key)) {
      tabsForRender = tabList.filter((item) => item.url === 'documents');
    }
    */

    const tabs = tabsForRender.map((item) => {
      const CurrentComponent = item.tabComponent;
      return {
        ...item,
        id: item.url,
        name: `${t(item.name)} (${counts && (counts[item.url] || 0)})`,
        component: (
          <CurrentComponent
            id={item.url}
            isActive={tab === `${item.url}`}
            prefix={item.prefix}
            originFilters={item.filters}
            tabFilters={filters[item.prefix]}
            changeFilters={(newFilters) => this.changeFilters(newFilters, item.prefix)}
            changeItemsCount={(url, count) => this.changeItemsCount(url, count)}
            searchDataLoadFunction={item.search}
            getValuesForRequest={(values) => this.filterValuesForRequest(values)}
          />
        ),
      };
    });

    return (
      <Layout
        title={t('GLOBAL_SEARCH')}
        tabs={tabs}
        activeTabUrl={(match.params && match.params.tab) || undefined}
        defaultTabUrl={tabs && tabs[0] && tabs[0].url}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  searchValue: state.search.searchValue,
  searchFilter: state.search.searchFilter,
  searchFilterLoading: state.search.searchFilterLoading,
  userInfo: state.user.info,
  searchReload: state.search.searchReload
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      GlobalSearch
    )
  )
);
