import React from 'react';
import { TreeSelect } from 'antd';
import api from '@services/api';

const loopTree = (data, parentId, callback) => {
  data.forEach((item, index, arr) => {
    callback(item, parentId, index, arr);
    if ('children' in item && item.children) {
      loopTree(item.children, item.id, callback);
    }
  });
};

const loopTreeParentObject = (data, parent, callback) => {
  data.forEach((item, index, arr) => {
    callback(item, parent, index, arr);
    if (item.children) {
      loopTree(item.children, item, callback);
    }
  });
};

const renderFunctionsSelect = (items, selectedItems, currentItemId, t, columnInfo, isFunction = true, allEnabled = false, disableParent = false) => {
  const compareItems = (a, b) => {
    const itemNameA = isFunction && a.function ? a.function.name : a.name;
    const itemNameB = isFunction && b.function ? b.function.name : b.name;
    if (itemNameA > itemNameB) return 1;
    else if (itemNameA < itemNameB) return -1;
    else return 0;
  }

  if (!items || !items.length) {
    return null;
  }

  const selectedItemsIds = selectedItems.filter((x) => x.id !== currentItemId).map((x) => x.id);

  return items.sort(compareItems).map((item) => {
    let itemName = isFunction && item.function ? item.function.name : item.name;

    if (columnInfo && columnInfo.translate) {
      itemName = t(`${columnInfo.field.toUpperCase()}_${itemName.replace('-', '_').toUpperCase()}`);
    }

    let enableSelect = false;
    if (allEnabled || (!!item.issue_task && !selectedItemsIds.includes(item.id))) enableSelect = true;

    return (
      <TreeSelect.TreeNode
        key={item.id}
        value={item.id}
        title={itemName}
        selectable={enableSelect}
        disabled={!enableSelect || disableParent}
      >
        {renderFunctionsSelect(item.children, selectedItems, currentItemId, t, columnInfo, isFunction, enableSelect)}
      </TreeSelect.TreeNode>
    );
  });
};

const onChangeSelectedFn = (selectedFunctionId, deviceFns, onSelectFunction) => {
  let found;

  const findFunc = (aFunc) => {
    aFunc.forEach((item) => {
      if (item.function && item.function.id === selectedFunctionId) {
        found = { ...item, name: item.function.name };

        return;
      }
      if (item.id === selectedFunctionId) {
        found = item;

        return;
      }

      if (item.children && item.children.length) {
        findFunc(item.children);
      }
    });
  };

  findFunc(deviceFns);

  onSelectFunction(found);
};

const getDeviceFunctions = async (passportId = undefined, callback) => {
  const args = { passport: passportId };

  let functionsTree = {};
  let functionsList = { status: 200, data: { results: [] } };

  if (passportId) {
    functionsTree = await api.getFunctionsTree(true, args, false);
    functionsList = await api.getProtectionDeviceFunctions(args);
  } else {
    functionsTree = await api.getFunctionsTree(true, args, false);
  }

  if (functionsTree.status !== 200 || functionsList.status !== 200) {
    return;
  }

  const tree = (functionsTree.data && functionsTree.data.results) || [];

  loopTree(tree, null, (item, parentId, index, arr) => {
    const findFunction = functionsList.data
      && functionsList.data.results
      && functionsList.data.results.find((x) => x.function && x.function.id === item.id);
    if (findFunction) {
      // eslint-disable-next-line no-param-reassign
      arr[index] = { ...item, ...findFunction };
    }
  });

  callback && callback(
    tree,
    functionsTree && functionsTree.data && functionsTree.data.results ? functionsTree.data.results : [],
    functionsList && functionsList.data && functionsList.data.results ? functionsList.data.results : []
  );

  return tree;
};

export {
  loopTree,
  loopTreeParentObject,
  renderFunctionsSelect,
  onChangeSelectedFn,
  getDeviceFunctions,
};
