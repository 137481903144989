import React, { memo, useEffect } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import styles from './modal.module.less'

const { TextArea } = Input;

export const EditFormulaModal = memo(({ initialFormula = '', visible = false, onClose, onConfirm, isEdit = false }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormula])
    

    const onCancel = () => {
        onClose && onClose();
    }

    const onOk = () => {
        form
            .validateFields()
            .then(values => {
                onConfirm && onConfirm(values?.formula || null);
            })
    }

    const footer = [
        <Button key="back" onClick={onCancel}>
            Назад
        </Button>,
        isEdit && <Button key="submit" type="primary" onClick={onOk}>
            Принять
        </Button>
    ]

    return <Modal className={styles.root} forceRender visible={visible} title={`${isEdit ? 'Редактирование' : 'Просмотр'} формулы`} onCancel={onCancel} onOk={onOk} footer={footer}>
        <Form form={form} initialValues={{ formula: initialFormula }}>
            <Form.Item name='formula'>
                <TextArea autoFocus autoSize={{ minRows: 6, maxRows: 20 }} readOnly={!isEdit} />
            </Form.Item>
        </Form>
    </Modal>
})