import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Form, Input, Button, Spin,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { setAppStage } from '@state/appStage/actions';
import api from '@services/api';
import storage from '@services/storage';
import { APPSTAGE_AUTH, AUTH_COOKIE_NAME } from '@globalConstants';

import styles from './styles.module.less';
import { setupGlobalSearchFiltersLoading } from '@state/search/actions';

class LoginForm extends Component {
  form = React.createRef();

  state = {
    loading: false,
    loginError: false,
  };

  handleSubmit = () => {
    const { setAppStageData, setGlobalFiltersLoading } = this.props;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ loading: true });

      const { status, data } = await api.tokenAuth(values.username, values.password);

      this.setState({ loading: false });

      if ([200, 201].includes(status)) {
        const token = data.token;
        storage.set(AUTH_COOKIE_NAME, token);
        api.setAUTHToken(token);
        await setAppStageData(APPSTAGE_AUTH);
        setGlobalFiltersLoading(true);
      } else {
        this.setState({ loginError: true });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { loading, loginError } = this.state;

    const userNameError = this.form.current && this.form.current.isFieldTouched('username') && this.form.current.getFieldError('username');
    const passwordError = this.form.current && this.form.current.isFieldTouched('password') && this.form.current.getFieldError('password');

    return (
      <Form name='login' ref={this.form} onFinish={this.handleSubmit} className={styles.content}>
        <Spin spinning={loading}>
          <div>
            <p className={styles.subTitle}>{t('AUTH_FORM_SUBTITLE')}</p>

            <Form.Item name='username' rules={[{ required: true, message: t('AUTH_FORM_ERROR_LOGIN') }]} validateStatus={userNameError ? 'error' : ''} className={styles.item}>
              <Input
                prefix={<UserOutlined />}
                placeholder={t('AUTH_FORM_LABEL_LOGIN')}
                className={styles.input}
              />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: t('AUTH_FORM_ERROR_PASSWORD') }]} validateStatus={passwordError ? 'error' : ''} className={styles.item}>
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t('AUTH_FORM_LABEL_PASSWORD')}
                className={styles.input}
              />
            </Form.Item>

            <Form.Item className={styles.btnGroup}>
              <Button type='primary' htmlType='submit' className={styles.btn}>
                {t('AUTH_FORM_BUTTON_LOGIN')}
              </Button>
            </Form.Item>
          </div>
        </Spin>
        {loginError && <div className={styles.error}>{t('AUTH_FORM_ERROR_ENTER')}</div>}
      </Form>
    );
  }
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  setGlobalFiltersLoading: setupGlobalSearchFiltersLoading,
};

export default connect(null, mapDispatchToProps)(
  withTranslation()(
    LoginForm
  )
);
