import React, { ReactChild } from 'react';

import styles from './styles.module.less';

interface Props {
  label?: string;
  children?: ReactChild;
}

const Parameter = ({ label, children }: Props) => {
  return (
    <div>
      {label && (
        <div className={styles.label}>
          {label}
          :&emsp;
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default Parameter;
