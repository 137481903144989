import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Table, Button, Select, Input, Tag,
} from 'antd';
import Icon, { EditOutlined } from '@ant-design/icons';
import api from '@services/api';
import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';

import { CustomSelect, CustomCard } from '@ui';
import {
  MAP_RIGHTS_TO_PATHS, MODE_EDIT, ROUTE_ROLES, TABLE_SIZE,
} from '@globalConstants';

import styles from '../../styles.module.less';

const { Option } = Select;

class RolesTab extends Component {
  state = {
    groups: [],
    inputGroupValue: '',
    inputGroupRole: null,
    groupsToUp: {},
    groupsToAdd: [],
    groupsToDel: [],
  };

  componentDidMount() {
    this.getGroups();
  }

  getGroups = async () => {
    this.setState({ isLoading: true });
    const { status, data } = await api.getGroupsWithRoles();
    this.setState({ isLoading: false });

    if (status === 200) {
      this.setState({
        groups: data.results.map((item) => ({ ...item, key: item.id })),
      });
    }
  };

  handleChangeInputGroup = (e) => {
    this.setState({
      inputGroupValue: e.target.value,
    });
  };

  handleChangeInputRole = async (value) => {
    const { roles } = this.props;

    const selectedRole = roles && value && roles.find((x) => x.id === value);

    this.setState({
      inputGroupRole: selectedRole,
    });
  };

  addGroup = async () => {
    const {
      inputGroupValue, inputGroupRole, groupsToAdd, groupsToUp, groups,
    } = this.state;

    const groupIndex = groups.length && (groups[groups.length - 1].id + 1);
    const newItem = {
      id: groupIndex,
      key: groupIndex,
      name: inputGroupValue,
      roles: [inputGroupRole],
    };
    const newGroupsToUp = {
      ...groupsToUp,
      [groupIndex]: [inputGroupRole && inputGroupRole.id],
    };

    this.setState({
      inputGroupValue: '',
      inputGroupRole: null,
      groupsToAdd: [...groupsToAdd, newItem],
      groups: [...groups, newItem],
      groupsToUp: newGroupsToUp,
    });
  };

  handleChangeRoles = async (value, option, id) => {
    const { groupsToUp } = this.state;

    this.setState({
      groupsToUp: {
        ...groupsToUp,
        [id]: [value],
      },
    });
  };

  deleteGroup = async (e, id) => {
    const {
      groupsToDel, groupsToAdd, groupsToUp, groups,
    } = this.state;
    e.preventDefault();

    const notIncludes = (group) => group.id !== id;

    const foundAddItem = groupsToAdd.find((group) => group.id === id);
    const foundUpItem = Object.keys(groupsToUp).includes(id.toString());
    const newGroups = groups.filter(notIncludes);
    const newGroupsToAdd = foundAddItem ? groupsToAdd.filter(notIncludes) : groupsToAdd;

    const newGroupsToUp = foundUpItem
      ? (
        Object.keys(groupsToUp).reduce(
          (arr, key) => {
            if (key !== id.toString()) {
              arr[key] = groupsToUp[key];
            }
            return arr;
          },
          {}
        )
      )
      : groupsToUp;
    const newGroupsToDel = foundAddItem ? groupsToDel : [...groupsToDel, id];

    this.setState({
      groupsToAdd: newGroupsToAdd,
      groupsToUp: newGroupsToUp,
      groupsToDel: newGroupsToDel,
      groups: newGroups,
    });
  };

  cancelChanges = () => {
    const { toggleRolesEdit } = this.props;

    this.setState({
      groupsToUp: {},
      groupsToAdd: [],
      groupsToDel: [],
    });

    this.getGroups();

    toggleRolesEdit({
      preventDefault: () => { },
    });
  };

  saveChanges = async () => {
    const { groupsToUp, groupsToAdd, groupsToDel } = this.state;

    this.setState({ isUpdating: true });
    const delPromises = groupsToDel.map(async (id) => api.deleteGroup(id));

    await Promise.all(delPromises);

    const nextGroupsToUp = {
      ...groupsToUp,
    };

    for (const item of groupsToAdd) {
      const { data } = await api.addGroup({
        name: item.name,
      });

      nextGroupsToUp[data.id] = groupsToUp[item.id];

      if (data.id !== item.id) {
        delete nextGroupsToUp[item.id];
      }
    }

    const upPromises = Object.keys(nextGroupsToUp)
      .map(async (id) => !groupsToDel.includes(id) && api.changeGroupRoles(id, nextGroupsToUp[id]));

    await Promise.all(upPromises);

    this.cancelChanges();
    await this.getGroups();

    this.setState({ isUpdating: false });
  };

  render() {
    const {
      storeRights, roles, toggleRolesEdit, isEdit, t,
    } = this.props;
    const {
      inputGroupValue, inputGroupRole, isUpdating, isLoading, groups,
    } = this.state;
    const columns = [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => index+1,
      },
      {
        title: t('GROUP_AD'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('ROLES'),
        dataIndex: 'roles',
        key: 'roles',
        render: (currentRoles, record) => {
          if (isEdit) {
            return (
              <CustomSelect
                style={{ width: '250px' }}
                mode='multiple'
                defaultValue={currentRoles[0] && currentRoles[0].id}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(value, option) => this.handleChangeRoles(value, option, record.id)}
              >
                {roles.map((item) => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </CustomSelect>
            );
          }
          return currentRoles.map((item) => (
            <Tag key={item.id} closable={isEdit}>
              {item.name}
            </Tag>
          ));
        },
      },
    ];

    if (isEdit) {
      columns.push({
        title: '',
        key: 'delete',
        width: 100,
        render: (text, record) => (
          <Button onClick={(e) => this.deleteGroup(e, record.id)}>
            <Icon component={BucketIcon} />
            {t('DELETE_GROUP')}
          </Button>
        ),
      });
    }

    const disableEdit = storeRights[MAP_RIGHTS_TO_PATHS[ROUTE_ROLES]] !== MODE_EDIT;

    return (
      <CustomCard
        className={styles.header}
        loading={isLoading}
        extra={(
          <>
            {!isEdit && (
              <Button
                tabIndex={0}
                size='small'
                onClick={(e) => toggleRolesEdit(e)}
                icon={<EditOutlined />}
                disabled={disableEdit || isLoading}
              >
                {t('EDIT')}
              </Button>
            )}
            {isEdit && (
              <>
                <Button
                  disabled={isUpdating}
                  tabIndex={0}
                  size='small'
                  onClick={this.cancelChanges}
                >
                  {t('CANCEL')}
                </Button>
                &nbsp;
                <Button
                  disabled={isUpdating || isLoading}
                  loading={isUpdating}
                  tabIndex={0}
                  size='small'
                  type='primary'
                  onClick={this.saveChanges}
                >
                  {t('SAVE')}
                </Button>
              </>
            )}
          </>
        )}
      >
        {
          isEdit
            ? (
              <div>
                <label htmlFor='input-group'>
                  {t('ADD_GROUP')}:
                </label>
                <Input
                  id='input-group'
                  style={{
                    width: 350,
                    margin: '0 16px 16px 10px',
                  }}
                  onChange={this.handleChangeInputGroup}
                  value={inputGroupValue}
                  placeholder={t('GROUP_AD_PLACEHOLDER')}
                />
                <label htmlFor='input-select-role'>
                  {t('WITH_ROLES')}:
                </label>
                <CustomSelect
                  id='input-select-role'
                  mode='multiple'
                  style={{
                    width: '350px',
                    margin: '0 16px 16px 10px',
                  }}
                  value={inputGroupRole ? inputGroupRole.name : null}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  disabled={!inputGroupValue || inputGroupValue.length < 5}
                  onChange={this.handleChangeInputRole}
                  placeholder={t('ADD_ROLE_PLACEHOLDER')}
                >
                  {roles.map((item) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </CustomSelect>
                <Button
                  onClick={this.addGroup}
                  disabled={!inputGroupValue || inputGroupValue.length < 5 || !inputGroupRole}
                >
                  {t('ADD')}
                </Button>
              </div>
            )
            : ''
        }
        <Table
          size={TABLE_SIZE}
          dataSource={groups.sort((a, b) => a.id - b.id)}
          columns={columns}
          pagination={false}
        />
      </CustomCard>
    );
  }
}

export default withTranslation()(
  RolesTab
);
