import React from 'react';
import { connect } from "react-redux";
import cloneDeep from 'lodash/cloneDeep';

import { renderFields } from '@common/treeValues/renderFields';
import {
  checkDefaultValueIntoSelector,
  renderSelector,
  getSelectorForEdit,
} from '@common/treeValues/renderSelectorFields';
import {
  checkDefaultValueIntoRanges,
  renderRange,
  getRangeForEdit,
  emptyRangeValues,
} from '@common/treeValues/renderRangeFields';
import { getId, loopTree } from '@globalHelpers';
import { renderTitleForCheckBox } from '@common/treeValues';

export class ActionsForTreeValues {
  constructor(t, actionsForTree) {
    this.t = t;
    this.actionsForTree = actionsForTree;
  }

  columnsForTreeValue = () => [
    {
      title: 'FORMULA',
      field: 's2p_func',
      type: 'formula',
      width: '6%',
      resizable: false,
    },
    {
      title: 'NAME',
      field: 'name',
      type: 'text',
      empty: 'WITHOUT_NAME',
      width: '10%',
      placeholder: this.t('TREE_VALUE_NEW_TREE_ITEM_VALUE'),
    },
    {
      title: 'SHORT_NAME',
      field: 'short_name',
      type: 'text',
      width: '9%',
      empty: 'WITHOUT_NAME',
    },
    {
      title: 'PF_NAME',
      field: 'pf_classname',
      type: 'text',
      width: '7%',
      empty: 'WITHOUT_VALUE',
    },
    {
      title: 'FUNCTION',
      field: 'function',
      type: 'treeSelect',
      empty: 'WITHOUT_VALUE',
      items: 'deviceFns',
      subField: 'name',
      emptyItem: true,
      allEnabled: true,
      disableParent: true,
      width: '15%'
    },
    {
      title: 'CODE',
      field: 'code',
      type: 'text',
      width: '7%',
      empty: 'WITHOUT_VALUE',
    },
    {
      title: 'SETPOINT_TYPE',
      field: 'setpoint_type',
      type: 'select',
      width: '8%',
      empty: 'WITHOUT_VALUE',
      items: 'setPointTypeList',
      translate: true,
      emptyItem: false,
    },
    {
      title: 'MEASUREMENT_UNIT_PRIMARY',
      field: 'measurement_unit_primary',
      type: 'select',
      width: '8%',
      empty: 'WITHOUT_VALUE',
      items: 'setMeasurementUnitList',
      translate: false,
      emptyItem: true,
    },
    {
      title: 'MEASUREMENT_UNIT_SECONDARY',
      field: 'measurement_unit_secondary',
      type: 'select',
      width: '8%',
      empty: 'WITHOUT_VALUE',
      items: 'setMeasurementUnitList',
      translate: false,
      emptyItem: true,
    },
    {
      title: 'DIGITS',
      field: 'digits',
      type: 'number',
      width: '6%',
      empty: 'WITHOUT_VALUE',
      minWidth: 70,
      colSpan: 0,
    },
    {
      title: 'RANGE_VALUES_STEP',
      field: 'range_values',
      type: 'range',
      width: '6%',
      empty: 'WITHOUT_VALUE',
      subField: 'step',
      colSpan: 3,
      render: (component) => renderRange(component),
    },
    {
      title: 'RANGE_VALUES_MIN',
      field: 'range_values',
      type: 'range',
      width: '6%',
      empty: 'WITHOUT_VALUE',
      subField: 'min_value',
      minWidth: 70,
      colSpan: 0,
    },
    {
      title: 'RANGE_VALUES_MAX',
      field: 'range_values',
      type: 'range',
      width: '6%',
      empty: 'WITHOUT_VALUE',
      subField: 'max_value',
      minWidth: 70,
      colSpan: 0,
    },
    {
      title: 'SELECTOR_VALUES',
      field: 'selector_values',
      type: 'selector',
      width: '10%',
      empty: 'WITHOUT_VALUE',
      minWidth: 100,
      render: (component) => renderSelector(component),
    },
    {
      title: 'DEFAULT_VALUE',
      field: 'default_value',
      type: 'default_value',
      width: '7%',
      empty: 'WITHOUT_VALUE',
      switchField: 'setpoint_type',
    },
    {
      title: 'VIEW_COMPLEX',
      originTitle: 'VIEW_COMPLEX',
      originTitleText: this.t('TABLE_COL_VIEW_COMPLEX'),
      renderTitle: renderTitleForCheckBox,
      showTitleAsIs: true,
      showAlwaysAsEdit: true,
      field: 'view_complex',
      type: 'checkBox',
      empty: 'WITHOUT_VALUE',
      fixedWidth: '95px',
      filterColumn: (treeValueComponent, component) => {
        return !!component.state.isViewComplexColumn;
      },
    },
    {
      title: 'VIEW_TASK',
      originTitle: 'VIEW_TASK',
      originTitleText: this.t('TABLE_COL_VIEW_TASK'),
      renderTitle: renderTitleForCheckBox,
      showTitleAsIs: true,
      showAlwaysAsEdit: true,
      field: 'view_task',
      type: 'checkBox',
      empty: 'WITHOUT_VALUE',
      fixedWidth: '95px',
      filterColumn: (treeValueComponent, component) => {
        return !!component.state.isViewTaskColumn;
      },
    },
  ];

  newTreeValueItem = (component) => {
    const { selectedTreeItem, setPointTypeList } = component.state;
    const setPointTypeText = setPointTypeList
      && Array.isArray(setPointTypeList)
      && setPointTypeList.find((item) => item.name === 'text');
    return {
      id: getId(8),
      name: null,
      category: {
        id: selectedTreeItem.id,
        name: selectedTreeItem.name,
      },
      setpoint_type:
        setPointTypeText
        || (setPointTypeList && Array.isArray(setPointTypeList) && setPointTypeList.length && setPointTypeList[0]
          ? setPointTypeList[0]
          : []),
      isNewItem: true,
    };
  };

  renderTreeValueColumns = (component, dataFiltered) => {
    const { editId, editItem } = component.state;

    const columnsList = this.columnsForTreeValue();
    const columns = [];

    if (columnsList && Array.isArray(columnsList) && columnsList.length) {
      columnsList.forEach((column, columnIndex) => {
        const obj = {};
        obj.origin_column = column;
        obj.title = column.title
          ? (
            column.showTitleAsIs
              ? (
                column.renderTitle && typeof column.renderTitle === 'function'
                  ? <div>{column.renderTitle(dataFiltered, column, component)}</div>
                  : <div>{column.title}</div>
              )
              : <div>{this.t(`TABLE_COL_${column.title}`)}</div>
          )
          : <></>;
        obj.width = column.width;
        obj.resizable = column?.resizable || true;
        const newObj = renderFields(
          obj,
          column,
          'template-tree-value',
          editId,
          editItem,
          columnIndex,
          component,
          this.t,
          checkDefaultValueIntoRanges,
          checkDefaultValueIntoSelector
        );
        columns.push(newObj);
      });
    }

    return columns;
  };

  // Сборка массива объектов перед сохранением
  onSaveEditedTreeValueData = async (component) => {
    const { data, editId, editItem } = component.state;
    const editRange = cloneDeep(editItem.range);
    const editSelector = cloneDeep(editItem.selector);
    let newEditItem = cloneDeep(editItem);
    delete newEditItem.range;
    delete newEditItem.selector;
    const newEditRange = editRange.map((el) => {
      const newEl = cloneDeep(el);
      delete newEl.key;
      return newEl;
    });
    const newEditSelector = editSelector.map((el) => el.value);
    if (
      newEditItem.setpoint_type.name === 'numeric'
      && JSON.stringify(editRange) !== JSON.stringify(emptyRangeValues)
    ) {
      newEditItem = { ...newEditItem, range_values: newEditRange };
    } else {
      newEditItem = { ...newEditItem, range_values: null };
    }
    if (
      ['selector', 'multiple_selector'].indexOf(editItem.setpoint_type.name) !== -1
      && JSON.stringify(newEditSelector) !== JSON.stringify([''])
    ) {
      newEditItem = { ...newEditItem, selector_values: newEditSelector };
    } else {
      newEditItem = { ...newEditItem, selector_values: null };
    }
    return data.map((el) => {
      if (el.id === editId) {
        return newEditItem;
      } else {
        return el;
      }
    });
  };

  // Дополнительные действия над элементом при его создании, создание доп полей и т.п.
  onAddTreeValueItem = (addItem) => {
    const rangeArr = getRangeForEdit(null);
    const selector = getSelectorForEdit(null);
    return {
      ...addItem,
      range: rangeArr,
      selector,
    };
  };

  // Дополнительные действия над элементом перед редактированием, создание доп полей и т.п.
  onEditTreeValueData = (editedItem) => {
    const rangeArr = getRangeForEdit(editedItem && 'range_values' in editedItem ? editedItem.range_values : null);
    const selector = getSelectorForEdit(
      editedItem && 'selector_values' in editedItem ? editedItem.selector_values : null
    );
    return {
      ...editedItem,
      range: rangeArr,
      selector,
    };
  };

  checkRecordForEdit = () => {
    return false;
  };

  prepareForSaveTemplateTreeValue = async (
    templateName,
    newTreeData,
    oldTreeValueData,
    treeValueData,
    isNewTemplate
  ) => {
    const newTreeValueData = treeValueData.map((item) => {
      const newItem = cloneDeep(item);
      if (newItem && (newItem.isNewItem || isNewTemplate)) {
        delete newItem.id;
        delete newItem.isNewItem;
      }
      if (newItem && (!('range_values' in newItem) || newItem.range_values === null)) {
        newItem.range_values = [];
      }
      if (newItem && (!('selector_values' in newItem) || newItem.selector_values === null)) {
        newItem.selector_values = [];
      }
      if (newItem && newItem.category && newItem.category.id) {
        newItem.category = newItem.category.id;
      }
      if (newItem && newItem.setpoint_type && newItem.setpoint_type.id) {
        newItem.setpoint_type = newItem.setpoint_type.id;
      }
      if (newItem && newItem.measurement_unit_primary && newItem.measurement_unit_primary.id) {
        newItem.measurement_unit_primary = newItem.measurement_unit_primary.id;
      } else {
        newItem.measurement_unit_primary = null;
      }
      if (newItem && newItem.measurement_unit_secondary && newItem.measurement_unit_secondary.id) {
        newItem.measurement_unit_secondary = newItem.measurement_unit_secondary.id;
      } else {
        newItem.measurement_unit_secondary = null;
      }
      return newItem;
    });

    const oldTreeDataArray = {};
    const template = newTreeData.map((item) => cloneDeep(item));

    loopTree(
      oldTreeValueData.map((item) => cloneDeep(item)),
      null,
      (item, parentId) => {
        // eslint-disable-next-line no-param-reassign
        item.parent = parentId;
        oldTreeDataArray[item.id] = cloneDeep(item);
      }
    );

    loopTree(template, null, (category) => {
      // eslint-disable-next-line no-param-reassign
      category.setpoints = category && 'id' in category && newTreeValueData && newTreeValueData.length > 0
        ? newTreeValueData.filter((item) => item.category === category.id)
        : [];
      category.setpoints.forEach((x) => {
        delete x.category;
        if (x.function?.id) {
          const funcId = x.function?.id;
          delete x.function;
          x.function = funcId;
        } else {
          delete x.function;
        }
      });
      if (!oldTreeDataArray || !oldTreeDataArray[category.id] || isNewTemplate) {
        // eslint-disable-next-line no-param-reassign
        delete category.id;
        // eslint-disable-next-line no-param-reassign
        category.setpoints.forEach((item) => delete item.category);
        if ('parent' in category) {
          // eslint-disable-next-line no-param-reassign
          delete category.parent;
        }
        if ('isNewItem' in category) {
          // eslint-disable-next-line no-param-reassign
          delete category.isNewItem;
        }
      }
    });

    const newTemplateName = isNewTemplate
      ? templateName.concat(' ', this.t('TEMPLATE_NAME_WITH_COPY'))
      : templateName;

    return {
      name: newTemplateName,
      categories: template,
    };
  }

  rowSelection = () => {
    const { component } = this;
    const { selectedTreeValueData } = component.state;

    return {
      type: 'checkBox',
      selectedRowKeys: selectedTreeValueData,
      onChange: (selectedRowKeys) => {
        component.setState({
          selectedTreeValueData: selectedRowKeys,
        });
      },
    };
  };
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});

connect(mapStateToProps)(ActionsForTreeValues);