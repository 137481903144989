import React, { Component } from 'react';

const withRowSelection = (WrappedComponent) => {
  return class withRowSelectionHOC extends Component {
    state = {
      selectedItem: {},
      selectedItems: [],
    };

    onSelectItem = (item, callback) => {
      this.setState({ selectedItem: item || {} }, callback);
    };

    onSelectItems = (items, callback) => {
      this.setState({ selectedItems: items || [] }, callback);
    };

    render() {
      return (
        <WrappedComponent
          onSelectItem={this.onSelectItem}
          onSelectItems={this.onSelectItems}
          {...this.props}
          {...this.state}
        />
      );
    }
  };
};

export default withRowSelection;
