import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Layout } from '@ui';

import Complexes from './complexes';
import DocClassificators from './documents';
import DeviceClassificators from './devices';
import EquipmentTypes from './equipment_types';

import {
  COMPLEXES, DEVICE, DOCUMENTS, EQUIPMENT_TYPES,
} from './constants';

const ClassificatorsRoute = (props) => {
  const { t, match } = props;

  const tabs = [
    {
      id: COMPLEXES,
      name: t(`CLASSIFICATORS_${COMPLEXES}`),
      url: 'complexes',
      component: <Complexes />,
    },
    {
      id: DEVICE,
      name: t(`CLASSIFICATORS_${DEVICE}`),
      url: 'device',
      component: <DeviceClassificators />,
    },
    {
      id: DOCUMENTS,
      name: t(`CLASSIFICATORS_${DOCUMENTS}`),
      url: 'documents',
      component: <DocClassificators />,
    },
  ];

  const isEnableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP === undefined;

  if (isEnableEditAIP) {
    tabs.push({
      id: EQUIPMENT_TYPES,
      name: t(`CLASSIFICATORS_${EQUIPMENT_TYPES}`),
      url: 'equipment_types',
      component: <EquipmentTypes />,
    });
  }

  return (
    <Layout
      title={t('CLASSIFICATORS_TITLE')}
      tabs={tabs}
      activeTabUrl={(match.params && match.params.tab) || undefined}
      defaultTabUrl='complexes'
      baseUrl='classificators'
    />
  );
};

export default withTranslation()(
  withRouter(
    ClassificatorsRoute
  )
);
