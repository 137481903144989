import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';

import cn from 'classnames';
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import {
  Spin, message, Button, Typography, Popover, Popconfirm, DatePicker, Input
} from 'antd';
import {
  PlusCircleOutlined, EditOutlined, DeleteOutlined, FileTextOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { PopoverEllipsis } from '@ui/index';
import store from '@state/store';
import api from '@services/api';
import { setActiveBlank } from '@state/blanks/actions';
import { openSidebar, closeSidebar, showPrompt } from '@state/sidebar/actions';
import { clearMessage } from '@state/websocket/actions';
import { CREATE_TASK } from '@common/sidebarRoot/types';
import { ExportBottom } from '@common/exportPopconfirm';
import CommonTable from '@common/CommonTable';
import EntitiesAsTags from '../../../../common/entitiesAsTags';
import { downloadDocExternal, getObjectFromUrl, setUnBlockSidebar, textHighlighter } from '@globalHelpers';
import {
  RIGHT_PASSPORT_TASKS, MODE_EDIT, TABLE_SIZE, WS_TASKDC_START, RIGHT_PASSPORT_SETPOINTS, REDIRECT_URL_NEW_FRONT,
} from '@globalConstants';
import {
  CustomCard, CustomTag,
} from '@ui';
import GetStarted from './getStarted';

import {
  STATUS_PLANNED, STATUS_NEW, TASK_MODE_CREATE, TASK_MODE_EDIT, LOADING_MESSAGE_KEY, IS_ISSUED, STATUS_COMPLETED, IS_ISSUED_ARCHIVE
} from './constants';
import { Statuses, changeStatusForTaskDC } from './statuses';

import styles from './styles.module.less';
import { DateText } from './dateText/index';

const { Text } = Typography;

class DCTasks extends React.Component {
  tableName = 'passport_tab_tasks';

  constructor(props) {
    super(props);

    this.state = {
      tasksData: [],
      isLoading: false,
      isTaskDownloading: false,
      count: 0,
      createTaskMsgVisibility: false,
      isStartTaskDCBegin: [],
      isStartTaskDCFinishId: [],
      timerTask: [],
      editDateField: false,
      editDateFieldData: {},
      editNameField: false,
      editNameFieldData: {},
      editReasonField: false,
      editReasonFieldData: {}
    };
  }

  componentDidMount = async () => {
    const { setTableName, passportId, history } = this.props;

    setTableName(this.tableName, async () => {
      if (passportId) {
        const res = await this.loadQueryParams();
        if (!res) {
          await this.loadTasks();
        }
      }
    });

    this.unblock = setUnBlockSidebar(history, 'CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL_PASSPORT_CHANGE_LOCATION');
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      passportId, sidebarIsOpen, sidebarType, isUpdateTasks, updateTasks,
      wsMessage, clearMsg, location, deviceId,
    } = this.props;
    let { messages } = this.props;
    const { isStartTaskDCBegin, isStartTaskDCFinishId, timerTask } = this.state;

    if (prevProps.messages !== messages) {
      // idk what happen in this place
      messages = JSON.parse(JSON.stringify(messages));
      for (let i = 0, l = messages.length; i < l; i += 1) {
        let msg = messages[i];
        // idk what happen in this place
        msg = JSON.parse(JSON.stringify(msg));
        const { messageName } = msg;
        const method = `message_${messageName}`;
        if (method in this) {
          await this[method](msg);
        }
      }
    }

    if (
      passportId
      && (
        prevProps.passportId !== passportId
        || (
          !isEqual(location, prevProps.location)
          && location.search
          && location.search.length
          && prevProps.deviceId === deviceId
        )
      )
    ) {
      const res = await this.loadQueryParams();
      if (!res) {
        await this.loadTasks();
      }
    }

    if (sidebarIsOpen && sidebarType === CREATE_TASK && prevState.createTaskMsgVisibility) {
      this.setState({ createTaskMsgVisibility: false });
    }

    if (prevProps.isUpdateTasks !== isUpdateTasks && isUpdateTasks === true) {
      updateTasks && updateTasks(false);
      await this.loadTasks();
    }

    if (wsMessage && wsMessage.type === WS_TASKDC_START) {
      this.setState((state) => ({
        isStartTaskDCFinishId: [
          ...state.isStartTaskDCFinishId,
          wsMessage.message.tdc_id,
        ],
      }));
      clearMsg && clearMsg();
    }

    const filtredTaskDC = isStartTaskDCFinishId.filter((x) => isStartTaskDCBegin.includes(x));
    if (filtredTaskDC.length) {
      filtredTaskDC.forEach(async (finishedTaskId) => {
        const currentTimer = timerTask.find((x) => (x.id === finishedTaskId));
        this.setState((state) => ({
          isLoading: false,
          isStartTaskDCBegin: state.isStartTaskDCBegin?.filter((x) => (x !== finishedTaskId)) || [],
          isStartTaskDCFinishId: state.isStartTaskDCFinishId?.filter((x) => (x !== finishedTaskId)) || [],
          timerTask: state.timerTask?.filter((x) => (x.id !== finishedTaskId)) || [],
        }));
        await this.loadDataAfterStartTaskDC(finishedTaskId);
        currentTimer && currentTimer.timer && clearTimeout(currentTimer.timer);
      });
    }
  }

  componentWillUnmount() {
    this.unblock && this.unblock();
  }

  startTaskDC = (taskId) => {
    const { t } = this.props;

    const newTimerTask = setTimeout(() => {
      this.setState((state) => ({
        isLoading: false,
        isStartTaskDCBegin: state.isStartTaskDCBegin?.filter((x) => x !== taskId) || [],
        timerTask: state.timerTask?.filter((x) => x.id !== taskId) || [],
      }));
      message.warn({
        content: `${t('PASSPORT_TASKS_STATUS_UPDATED_ERROR_TIMEOUT_1')}
          <br/>
          ${t('PASSPORT_TASKS_STATUS_UPDATED_ERROR_TIMEOUT_2')}
        `,
        key: LOADING_MESSAGE_KEY,
      });
    }, 2 * 60 * 1000);

    this.setState((state) => ({
      isStartTaskDCBegin: [
        ...state.isStartTaskDCBegin,
        taskId,
      ],
      timerTask: [
        ...state.timerTask,
        { id: taskId, timer: newTimerTask },
      ],
    }));
  }

  loadDataAfterStartTaskDC = async (taskId) => {
    const { t, onSelectItem } = this.props;

    const statusData = await api.getTaskStatusV2(taskId);

    if (statusData.status === 200) {
      await this.loadTasks({}, () => {
        const { tasksData } = this.state;
        const currentTask = tasksData.find((task) => (task.id === taskId));

        onSelectItem(currentTask, () => this.onSelectItemChange());
      });

      message.success({content: t('PASSPORT_TASKS_STATUS_UPDATED'), key: LOADING_MESSAGE_KEY});
    } else {
      message.error({content: `${t('PASSPORT_TASKS_STATUS_UPDATED_ERROR')}`, key: LOADING_MESSAGE_KEY});
    }
  }

  getSelectedBlank = async (task, blankId) => {
    const { selectedBlank, onChangeTask } = this.props;

    const blank = (blankId && (!selectedBlank || selectedBlank.id !== blankId))
      ? await this.loadSelectedBlank(blankId)
      : (
        selectedBlank && selectedBlank.id
          ? selectedBlank.id
          : null
      );

    await onChangeTask(task && task.id ? task.id : null, task && task.id ? task : null, blank);
  }

  loadSelectedBlank = async (blankId) => {
    const { deviceId, selectBlankData } = this.props;

    let blank = null;

    const res = await api.getPassportBlankById(deviceId, blankId);
    const { passportId } = this.props;

    if (res.status === 200 && res.data && res.data.passport === passportId) {
      blank = res.data;
      await selectBlankData(blank);
    }
    return blank;
  }

  scrollToTask = (taskFromUrl) => {
    if (!taskFromUrl.blank_id && taskFromUrl.scroll_to_task) {
      const el = document.getElementById('passport-tasks');
      setTimeout(
        () => {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500
      );
    }
  }

  loadQueryParams = async () => {
    const {
      location, setOpenBlankPanel, setOpenSetpointsPanel, selectedItem, passportId,
    } = this.props;

    const taskFromUrl = getObjectFromUrl(location, [
      'id',
      'name',
      'blank_id',
      'blank_name',
      'openBlankPanel',
      'openSetpointsPanel',
      'scroll_to_task',
    ]);

    if (passportId && taskFromUrl.id && location.pathname.includes('/tab/tasks')) {
      if (!selectedItem || (selectedItem.id !== taskFromUrl.id)) {
        await this.filterTasksByIdName(taskFromUrl.id, async (selectedTask) => {
          await this.scrollToTask(taskFromUrl);
          if (taskFromUrl.blank_id) {
            await this.getSelectedBlank(selectedTask, taskFromUrl.blank_id);
          } else {
            await this.onSelectItemChange();
          }
        });
      } else {
        await this.scrollToTask(taskFromUrl);
        if (taskFromUrl.blank_id) {
          await this.getSelectedBlank(selectedItem, taskFromUrl.blank_id);
        } else {
          await this.onSelectItemChange();
        }
      }

      if (taskFromUrl.openBlankPanel) {
        setOpenBlankPanel && setOpenBlankPanel(!!taskFromUrl.openBlankPanel);
      }
      if (taskFromUrl.openSetpointsPanel) {
        setOpenSetpointsPanel && setOpenSetpointsPanel(!!taskFromUrl.openSetpointsPanel);
      }

      return true;
    }
    return false;
  };

  loadTasks = async (addParams = {}, callback) => {
    const {
      passportId, filterValues, selectedItem, onSelectItem, loadByPage,
    } = this.props;

    const requestParams = {
      passports: passportId,
      ...filterValues,
      ...addParams,
    };

    this.setState({ isLoading: true });

    await loadByPage(this.tableName, 'getTasksDCV2', requestParams, 200, (status, data) => {
      this.setState({
        tasksData: data.results,
        count: data.count,
      }, () => {
        callback && callback();
      });

      if ((!addParams || !addParams.selected) && selectedItem.id) {
        onSelectItem(data.results.find((item) => item.id === selectedItem.id), () => this.onSelectItemChange());
      }
    });

    this.setState({ isLoading: false });
  };

  getTaskById = async (id, callback) => {
    this.setState({ isLoading: true });

    const { status, data } = await api.getTaskDCV2(id);

    if (status === 200 && data) {
      callback && callback(data);
    }

    this.setState({ isLoading: false });
  };

  filterTasksByIdName = async (id, callback) => {
    const { onSelectItem, changePage, resetFilters } = this.props;

    resetFilters(
      changePage(1, async () => {
        await this.loadTasks({ selected: id }, () => {
          const { tasksData } = this.state;
          onSelectItem(tasksData[0], () => (callback ? callback(tasksData[0]) : this.onSelectItemChange()));
        });
      })
    );
  };

  message_updateCurrentTaskStatus = async () => {
    const { selectedItem, onSelectItem } = this.props;
    if (selectedItem.id) {
      const taskDCAnswer = await api.getTaskDCV2(selectedItem.id);
      if (taskDCAnswer.status === 200) {
        let { tasksData } = this.state;
        const task = tasksData.find((i) => i.id === selectedItem.id);
        if (task) {
          task.status = taskDCAnswer.data.status;
        }
        tasksData = [...tasksData];
        await this.setState({ tasksData });
        onSelectItem && onSelectItem(task);
      }
    }
  };

  onCreateTaskClick = async () => {
    const {
      sidebarType, sidebarIsOpen, openCreateTaskSidebar, closeCreateTaskSidebar, deviceId, passportId,
    } = this.props;

    if (sidebarIsOpen && sidebarType === CREATE_TASK) {
      closeCreateTaskSidebar();
    } else {
      openCreateTaskSidebar(
        CREATE_TASK,
        {
          deviceId,
          passportId,
          mode: TASK_MODE_CREATE,
          callback: this.onCreateTask,
        },
        700,
        true
      );
    }
  };

  onEditTaskClick = async () => {
    const {
        sidebarType, sidebarIsOpen, openCreateTaskSidebar, closeCreateTaskSidebar, deviceId, passportId, selectedItem,
    } = this.props;

    if (sidebarIsOpen && sidebarType === CREATE_TASK) {
      closeCreateTaskSidebar();
    } else {
      openCreateTaskSidebar(
        CREATE_TASK,
        {
          deviceId,
          passportId,
          selectedTask: selectedItem,
          mode: TASK_MODE_EDIT,
          callback: this.onCreateTask,
        },
        700,
        true
      );
    }
  };

  onCreateTask = async (task) => {
    const { changePage, onSelectItem } = this.props;

    if (task && (task.date_actual_start || task.status === STATUS_NEW)) {
      this.startTaskDC(task.id);
    }

    changePage(1, async () => {
      await this.loadTasks({ selected: task.id }, () => {
        const { tasksData: updatedTasksData } = this.state;
        onSelectItem(updatedTasksData[0], () => this.onSelectItemChange());
      });
    });
    store.dispatch(closeSidebar());
  };

  deleteTask = async () => {
    const {
      t, selectedItem, onSelectItem, handleSearch, updateDocuments,
    } = this.props;

    this.setState({ isLoading: true });

    const { status } = await api.deleteTaskDCV2(selectedItem.id);

    this.setState({ isLoading: false });

    if (status === 204) {
      await onSelectItem({}, async () => {
        await this.onSelectItemChange();
        updateDocuments && updateDocuments(true);
        handleSearch('', null, 'name__icontains');
        this.loadTasks();
      });
      message.success(t('DELETE_SUCCESS'));
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  updateStatus = async (taskId, status) => {
    const { t } = this.props;

    message.loading({ content: t('DC_TASK_SUCCESS_STATUS_UPDATE_MESSAGE'), key: LOADING_MESSAGE_KEY, duration: 5});

    this.setState({ isLoading: true });
    const result = await changeStatusForTaskDC(t, taskId, status);

    if (result.status === 200) {
      if (status === STATUS_NEW) {
        this.startTaskDC(taskId);
        await this.loadTasks();
        message.success({ content: t('PASSPORT_TASKS_STATUS_UPDATED'), key: LOADING_MESSAGE_KEY });
      } else {
        await this.loadDataAfterStartTaskDC(taskId);
      }
    } else {
      message.error({
        content: `${t('PASSPORT_TASKS_STATUS_UPDATED_ERROR')}: ${result.data.error || result.data.detail}`,
        key: LOADING_MESSAGE_KEY,
      });
    }
    this.setState({ isLoading: false });
  };

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({}, () => this.onSelectItemChange());
  };

  onChangeEditDate = async (newDate, newDateAsText) => {
    const { t } = this.props;
    const { editDateFieldData, tasksData } = this.state;
    const { id, fieldName } = editDateFieldData;

    this.setState({ isLoading: true });

    const newParams = {};
    newParams.id = id;
    newParams[fieldName] = newDateAsText;

    const res = await api.patchTaskDCV2(id, newParams);

    if (res.status === 200 && res.data) {
      const newData = cloneDeep(tasksData);
      newData.forEach((x) => {
        if (x.id === id && res.data[fieldName]) {
          x[fieldName] = res.data[fieldName];
        }
      })
      this.setState({
        tasksData: newData,
      });
      message.success(t('PASSPORT_TASKS_EDIT_DATE_END_SUCCESS'));
    } else {
      message.error(`
        ${t('PASSPORT_TASKS_EDIT_DATE_END_ERROR')}
        ${res.data && res.data.error ? res.data.error : ''}
        ${res.data && res.data.detail ? res.data.detail : ''}
      `);
      console.log({
        _f: 'Error --- api.patchTaskDCV2(id, newParams)',
        newParams,
        result: res,
      });

    }

    document.removeEventListener('click', this.documentClick, false);

    this.setState({
      isLoading: false,
      editDateField: false,
      editDateFieldData: {},
    });
  }

  onChangeEditTextField = async (attributeName) => {
    const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "";

    const capitalizedFieldName = `edit${capitalize(attributeName)}Field`; 
    const capitalizedFieldDataName = `${capitalizedFieldName}Data`; 
    const fieldData = this.state[capitalizedFieldDataName] || null;
    
    if (!fieldData) return;
    const { id, fieldName, value } = fieldData;
    const { t } = this.props;
    const { tasksData } = this.state;

    this.setState({ isLoading: true });

    const newParams = {};
    newParams.id = id;
    newParams[fieldName] = value;

    const res = await api.patchTaskDCV2(id, newParams);

    if (res.status === 200 && res.data) {
      const newData = cloneDeep(tasksData);
      newData.forEach((x) => {
        if (x.id === id) {
          x[fieldName] = res.data[fieldName] || '';
        }
      })
      this.setState({
        tasksData: newData,
      });
      message.success(t('PASSPORT_TASKS_CREATE_SUCCESS_PATCH'));
    } else {
      message.error(`
        ${t('PASSPORT_TASKS_CREATE_ERROR_PATCH')}
        ${res.data && res.data.error ? res.data.error : ''}
        ${res.data && res.data.detail ? res.data.detail : ''}
      `);
      console.log({
        _f: 'Error --- api.patchTaskDCV2(id, newParams)',
        newParams,
        result: res,
      });

    }

    const defaultValues = {
      [capitalizedFieldName]: false,
      [capitalizedFieldDataName]: {},
    }

    this.setState({
      isLoading: false,
      ...defaultValues
    });
  }

  documentClick = (event) => {
    // получаем дерево элементов, куда мы сейчас ткнули ...
    const path = event.path || (event.composedPath && event.composedPath());
    const pathText = path.map((x) => x.className).join(' ');

    // если клик был мимо выпадающего списка и мимо кнопки редаетирования - убираем редактирование
    if (
      !pathText.includes('editDatePickerElementDropDown')
      && !pathText.includes(styles.buttonEdit)
    ) {
      document.removeEventListener('click', this.documentClick, false);
      this.setState({
        editDateField: false,
        editDateFieldData: {},
      });
    }
  }

  columnsTasks = () => {
    const {
      t, settings, getColumnSearchFilter, filterValues, deviceId, history, rights, wsState
    } = this.props;
    const {
      editDateField, editDateFieldData, editNameField, editNameFieldData, editReasonField, editReasonFieldData
    } = this.state;

    const deadlineThreshold = (settings && settings.TASK_DC_DAYS_LEFT_NOTIFY)
      ? settings.TASK_DC_DAYS_LEFT_NOTIFY
      : 10;

    const isBlanksAllowed = rights[RIGHT_PASSPORT_SETPOINTS];

    return [
      {
        title: t('PASSPORT_TASKS_TABLE_COL_TASK'),
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        ...getColumnSearchFilter('name', 'name__icontains', null, this.handleResetCallback),
        render: (text, record) => {
          const editHandler = () => {
            this.setState({
              editNameField: true,
              editNameFieldData: {
                id: record.id,
                value: text,
                fieldName: 'name',
                record,
              },
            });
          };

          const onEditConfirm = (event) => {
            this.setState(
              {
                editNameField: true,
                editNameFieldData: {
                  id: record.id,
                  value: event.target.value || '',
                  fieldName: 'name',
                  record,
                },
              },
              () => this.onChangeEditTextField('name')
            );
          };

          const handleBlur = () => {
            this.setState({
              editNameField: true,
              editNameFieldData: {},
            });
          };

          return editNameField && editNameFieldData && editNameFieldData.id === record.id ? (
            <Input autoFocus size='small' defaultValue={text} onPressEnter={onEditConfirm} onBlur={handleBlur} />
          ) : text ? (
            <PopoverEllipsis content={textHighlighter(filterValues['name__icontains'], text)} onClick={editHandler}>
              <FileTextOutlined />
              &nbsp;
              {textHighlighter(filterValues['name__icontains'], text)}
            </PopoverEllipsis>
          ) : (
            <Text type='secondary' onClick={editHandler}>
              <EditOutlined /> {t('EDIT')}
            </Text>
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_STATUS'),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (status) => {
          const text = status && status.current_status && status.current_status.name;

          return (
            <PopoverEllipsis content={text}>
              <FileTextOutlined />
              &nbsp;
              {text}
            </PopoverEllipsis>
          );
        },
      },
      {
        title: t('DATE_OF_ISSUE'),
        dataIndex: 'date_start',
        key: 'date_start',
        width: '7%',
        render: (text, record) => {
          if (record.status && record.status.current_status.id === STATUS_PLANNED) {
            const plannedTime = moment(record.date_planned_start, 'YYYY-MM-DD');
            const today = moment(new Date(), 'YYYY-MM-DD');
            const diff = plannedTime.diff(today, 'days');

            let type = undefined;

            if (diff < 0 ) type = 'danger';
            else if (diff === 0) type='warning';

            return <DateText type={type}>{record.date_planned_start}</DateText>;
          }

          return <DateText type='danger'>{record.date_actual_start}</DateText>;
        },
      },
      {
        title: t('IMPLEMENTATION_PERIOD'),
        dataIndex: 'date_actual_completion',
        key: 'date_actual_completion',
        width: '9%',
        render: (date, record) => {
          if (date) {
            return (
              <DateText tip={date}>{date}</DateText>
            );
          }

          const { id, date_actual_start: startDate, date_planned_completion: endDate, timeframe } = record;

          const plannedTime = moment(endDate, 'YYYY-MM-DD');
          const today = moment(new Date(), 'YYYY-MM-DD');
          const diff = plannedTime.diff(today, 'days');

          const handleEndDateEditButtonClick = () => {
            this.setState({
              editDateField: true,
                editDateFieldData: {
                  id: id,
                  value: plannedTime,
                  fieldName: 'date_planned_completion',
                  record,
                }
            })
          }

          const buttonEdit = (
            <Button
              className={styles.buttonEdit}
              size='small'
              title={t('PASSPORT_TASKS_EDIT_DATE_END')}
              icon={<EditOutlined />}
              onClick={handleEndDateEditButtonClick}
            />
          );
          
          const prepareTextType = ({ diff, deadlineThreshold, timeframe }) => {
            if (timeframe) return 'secondary';
            if (diff <= 0) return 'danger';
            if (diff <= deadlineThreshold) return 'warning';
            return undefined;
          }

          const textType = prepareTextType({ diff, deadlineThreshold, timeframe });

          const disabledDate = (current) => {
            if (!startDate && !endDate) {
              return false;
            }
            return startDate
              ? moment(startDate, 'YYYY-MM-DD') > current
              : moment(record.date_planned_start, 'YYYY-MM-DD') > current;
          };

          if (timeframe) return <DateText type={textType} tip={timeframe}>{timeframe}</DateText>

          return (
            <div className={cn(styles.editDateBlock, `rec_${record.id}`)}>
              {editDateField && editDateFieldData && editDateFieldData.id === record.id ? (
                <>
                  <DatePicker
                    className={cn('editDatePickerElement', textType)}
                    dropdownClassName='editDatePickerElementDropDown'
                    clearIcon={false}
                    bordered={false}
                    open={true}
                    status={textType === 'danger' ? 'error' : textType}
                    placement='bottomRight'
                    onChange={this.onChangeEditDate}
                    defaultValue={moment(editDateFieldData.value)}
                    disabledDate={disabledDate}
                    getPopupContainer={(triggerNode) =>
                      triggerNode
                        ? triggerNode.closest('.ant-collapse')
                        : document
                            .querySelector(`div.${styles.editDateBlock}.rec_${record.id}`)
                            .closest('.ant-collapse')
                    }
                  />
                  {document.addEventListener('click', this.documentClick, false)}
                </>
              ) : (
                <>
                  <DateText type={textType} tip={timeframe || endDate}>{endDate}</DateText>
                  {buttonEdit}
                </>
              )}
            </div>
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_INITIAL_BLANK'),
        dataIndex: 'source_blanks',
        key: 'source_blanks',
        width: '15%',
        render: (blanks, record) => {
          const link = ''.concat('/passport/#blank_device_id/tab/setpoints/', '?id=#blank_id', '&name=#blank_name', '');
          return (
            <EntitiesAsTags
              entityName={'blanks'}
              entityNameSingle={'blank'}
              localizedEntityName={'Бланки'}
              entities={blanks}
              record={record}
              currentDeviceId={deviceId}
              link={link}
              isViewPassportsCount
              isAllowed={isBlanksAllowed}
              sourceTab={'tasks'}
            />
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_TASKS_BLANK'),
        dataIndex: 'blanks',
        key: 'blanks',
        width: '10%',
        render: (blanks, record) => {
          if (!blanks) return '';

          const linkSingle = ''.concat(
            '/passport/#blank_device_id/tab/tasks/',
            '?id=#record_id',
            '&name=#record_name',
            '&blank_id=#blank_id',
            '&blank_name=#blank_name',
            '&openBlankPanel=true',
            ''
          );
          const linkMultiple = ''.concat(
            '/passport/#blank_device_id/tab/setpoints/',
            '?id=#blank_id',
            '&name=#blank_name',
            ''
          );

          const targetLink = blanks?.length > 1 ? linkMultiple : linkSingle;

          return (
            <EntitiesAsTags
              entityName={'blanks'}
              entityNameSingle={'blank'}
              localizedEntityName={'Бланки'}
              entities={blanks}
              record={record}
              currentDeviceId={deviceId}
              link={targetLink}
              isViewPassportsCount
              isAllowed={isBlanksAllowed}
              sourceTab={'tasks'}
            />
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_REASON'),
        dataIndex: 'reason',
        key: 'reason',
        width: '15%',

        render: (text, record) => {
          const { t } = this.props;

          const editHandler = () => {
            this.setState({
              editReasonField: true,
              editReasonFieldData: {
                id: record.id,
                value: text,
                fieldName: 'reason',
                record,
              },
            });
          };

          const onEditConfirm = (event) => {
            this.setState(
              {
                editReasonField: true,
                editReasonFieldData: {
                  id: record.id,
                  value: event.target.value || '',
                  fieldName: 'reason',
                  record,
                },
              },
              () => this.onChangeEditTextField('reason')
            );
          };

          const handleBlur = () => {
            this.setState({
              editReasonField: true,
              editReasonFieldData: {},
            });
          };

          return editReasonField && editReasonFieldData && editReasonFieldData.id === record.id ? (
            <Input autoFocus size='small' defaultValue={text} onPressEnter={onEditConfirm} onBlur={handleBlur} />
          ) : text ? (
            <PopoverEllipsis content={text} onClick={editHandler}>
              {text}
            </PopoverEllipsis>
          ) : (
            <Text type='secondary' onClick={editHandler}>
              <EditOutlined /> {t('EDIT')}
            </Text>
          );
        },
      },
      {
        title: t('LETTERS'),
        dataIndex: 'letters',
        key: 'letters',
        width: '15%',
        render: (value) => {
          return (
            <div className={styles['letters-col']}>
              {value &&
                value.map((item) => (
                  <CustomTag
                    key={item.id}
                    disableEllipsis
                    className='link'
                    placement='topLeft'
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
                        .parentNode.parentNode.parentNode.parentNode
                    }
                    onClick={() => history.push(`/passport/${deviceId}/tab/letters?id=${item.id}&name=${item.name}`)}
                  >
                    {item.name}
                  </CustomTag>
                ))}
            </div>
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_REQUISITES'),
        dataIndex: 'documentation_requisites',
        key: 'documentation_requisites',
        width: '15%',
        render: (value) => {
          return (
            <Text>{value}</Text>
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_ADDITIONAL_CHARACTERISTICS'),
        dataIndex: 'additional_external_link',
        key: 'additional_external_link',
        width: '15%',
        render: (record) => {
          return (
            <>
              {record?.length > 0 && (
                <PopoverEllipsis
                  content={record[0]?.name}
                  className={styles.file}
                  onClick={() =>
                    downloadDocExternal(wsState, t, record[0]?.file)
                  }
                >
                  {record[0]?.name}
                </PopoverEllipsis>
              )}
            </>
          );
        },
      },
      {
        title: t('PASSPORT_TASKS_TABLE_COL_USED_MMS'),
        dataIndex: 'meta_mmn',
        key: 'meta_mmn',
        width: '15%',
        render: (record) => {
          const url = `${REDIRECT_URL_NEW_FRONT}/network-models`;
          return (
            <>
              {record?.map((item) => (
                <div key={item.meta_mmn_id}>
                  {item.type === 'CALCULATED' ? (
                    <PopoverEllipsis content={item.name}>
                      <a href={`${url}/rmms`} target="_blank">
                        {item.name}
                      </a>
                    </PopoverEllipsis>
                  ) : (
                    <PopoverEllipsis content={item.name}>
                      <a
                        href={`${url}/bmms/${item?.category_mmn?.id}/registration-updates-bmms`}
                        target="_blank"
                      >
                        {item.name}
                      </a>
                    </PopoverEllipsis>
                  )}
                </div>
              ))}
            </>
          );
        },
      },
    ];
  };

  onSelectItemChange = async () => {
    const { onChangeTask, deviceId, selectedItem } = this.props;
    let blank = null;

    if (selectedItem.blanks && selectedItem.blanks.length === 1 && selectedItem.blanks[0].id) {
      const res = await api.getPassportBlankById(deviceId, selectedItem.blanks[0].id);

      if (res.status === 200 && res.data) {
        blank = res.data;
      }
    }

    onChangeTask(selectedItem.id, selectedItem, blank);
  };

  changeCreateTaskMsgVisibility = (visible) => {
    const { sidebarIsOpen, sidebarType } = this.props;

    if (sidebarIsOpen && sidebarType === CREATE_TASK) {
      this.setState({ createTaskMsgVisibility: false });
    } else {
      this.setState({ createTaskMsgVisibility: visible });
    }
  };

  render() {
    const {
      rights, t, isBlocked, passportId, isArchived, updateDocuments,
      changePage, page, onShowSizeChange, limit, filterValues,
      onSelectItem, selectedItem, sidebarIsOpen, sidebarType,
      isDefaultOpenPanel, titleExtra, blank, staticRec
    } = this.props;
    const {
      tasksData, isLoading, isTaskDownloading, count, createTaskMsgVisibility,
    } = this.state;

    const isEdit = rights[RIGHT_PASSPORT_TASKS] === MODE_EDIT && !isArchived;

    const selectedTaskEditDisabled =
      selectedItem?.status?.current_status?.id === IS_ISSUED ||
      selectedItem?.status?.current_status?.id === STATUS_COMPLETED ||
      selectedItem?.status?.current_status?.id === IS_ISSUED_ARCHIVE;

    const isSidebarTaskOpen = sidebarIsOpen && sidebarType === CREATE_TASK;

    const rowSelection = {
      type: 'radio',
      onChange:
        (selectedRowKeys, selectedRows) => onSelectItem(selectedRows[0], () => this.onSelectItemChange()),
      hideDefaultSelections: true,
      selectedRowKeys: selectedItem.id ? [selectedItem.id] : [''],
    };

    return (
      <div id='passport-tasks'>
        <Spin spinning={isBlocked || isTaskDownloading}>
          <CustomCard
            collapsable
            noPadding
            noBottomMargin
            loading={isLoading}
            enableCollapsableHasNoData
            count={tasksData && tasksData.length}
            isDefaultOpenPanel={isDefaultOpenPanel}
            title={t('PASSPORT_TASKS_DCTASTS_TITLE')}
            hasData={!!(tasksData && tasksData.length)}
            extra={(
              <>
                <Popover
                  content={(
                    <>
                      <ExclamationCircleOutlined className={styles.warning} />
                      {' '}
                      {t('PASSPORT_TASKS_BEFORE_CREATE')}
                    </>
                  )}
                  visible={!isEdit ? false : createTaskMsgVisibility}
                  onVisibleChange={(visible) => this.changeCreateTaskMsgVisibility(visible)}
                >
                  <Button
                    size='small'
                    onClick={this.onCreateTaskClick}
                    disabled={!isEdit || isSidebarTaskOpen}
                    icon={<PlusCircleOutlined />}
                  >
                    {t('PASSPORT_TASKS_BUTTON_CREATE_TASK')}
                  </Button>
                </Popover>
                <Button
                  size='small'
                  icon={<EditOutlined />}
                  onClick={this.onEditTaskClick}
                  disabled={
                    !isEdit
                    || !selectedItem.id
                    || selectedTaskEditDisabled
                    || isSidebarTaskOpen
                  }
                >
                  {t('EDIT')}
                </Button>
                <GetStarted
                  isEdit={isEdit}
                  sidebarIsOpen={isSidebarTaskOpen}
                  selectedTask={selectedItem}
                  changeStatus={() => this.updateStatus(selectedItem.id, STATUS_NEW)}
                  loading={isLoading}
                />
                <Popconfirm
                  placement='topRight'
                  title={t('PASSPORT_TASKS_DELETE_CONFIRM')}
                  okText={t('YES')}
                  cancelText={t('NO')}
                  onConfirm={this.deleteTask}
                  disabled={!isEdit || !selectedItem.id || isSidebarTaskOpen}
                >
                  <Button
                    size='small'
                    icon={<DeleteOutlined />}
                    disabled={!isEdit || !selectedItem.id || isSidebarTaskOpen}
                  >
                    {t('DELETE')}
                  </Button>
                </Popconfirm>
              </>
            )}
          >
            <CommonTable
              tableName={this.tableName}
              size={TABLE_SIZE}
              columns={this.columnsTasks()}
              dataSource={tasksData}
              loading={isLoading}
              pagination={{
                onChange: (newPage) => changePage(newPage, this.loadTasks),
                pageSize: limit,
                current: page,
                total: count,
              }}
              rowSelection={rowSelection}
              onShowSizeChange={
                (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, this.loadTasks)
              }
            />
            <ExportBottom
              size='small'
              count={tasksData && tasksData.length}
              WSData={{
                url: '/v1/task-dcs/',
                params: {
                  ordering: 'startup_date',
                  passports: passportId,
                  ...filterValues,
                },
                accept: 'application/xlsx',
              }}
            />
          </CustomCard>
        </Spin>
        <Statuses
          titleExtra={titleExtra}
          isDefaultOpenPanel={!!selectedItem}
          isArchived={isArchived}
          passportId={passportId}
          selectedTask={{ ...selectedItem }}
          loadTasks={this.loadTasks}
          updateDocuments={updateDocuments}
          blank={blank}
          staticRec={staticRec}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarType: state.sidebar.componentType,
  sidebarIsOpen: state.sidebar.isOpen,
  rights: state.rights.rightsData,
  settings: state.settings.data,
  wsMessage: state.websocket.message,
  selectedBlank: state.blanks.activeBlank,
  wsState: state.websocket
});

const mapDispatchToProps = {
  openCreateTaskSidebar: openSidebar,
  closeCreateTaskSidebar: closeSidebar,
  selectBlankData: setActiveBlank,
  clearMsg: clearMessage,
  showPromptSidebar: showPrompt,
};
const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    enhance(
      withRouter(
        DCTasks
      )
    )
  )
);
