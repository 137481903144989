import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Spin, Steps, Button, message, Alert, Form, Row, DatePicker, Popover
} from 'antd';
import Icon, {
  DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined, UploadOutlined,
} from '@ant-design/icons';

import { ReactComponent as TransformerIcon } from "@ui/icons/transformer.svg";

import { CREATE_TASK } from '@common/sidebarRoot/types';
import { RIGHT_PASSPORT_TASKS, MODE_EDIT, KB } from '@globalConstants';
import { openAllDocumentLinks } from '@routes/passport/helpers';
import api from '@services/api';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { CustomCard, CustomEmpty } from '@ui';

import GetStarted from './getStarted';

import {
  READY_TO_FORMED, IS_FORMED, IS_APPROVED, IS_ISSUED, IS_ISSUED_ARCHIVE,
  STATUS_PLANNED, STATUS_NEW, STATUS_CALCULATING,
  STATUS_COMPLETED, TASK_MODE_AGREEMENT, LOADING_MESSAGE_KEY
} from './constants';

import styles from './styles.module.less';
import { withRouter } from 'react-router-dom';
import { setUnBlockSidebar } from '@app/helpers';

const { Step } = Steps;

const changeStatusForTaskDC = async (t, taskId, status) => {
  switch (status) {
    case IS_APPROVED:
      return api.updateTaskStatus('approved', taskId);
    case STATUS_CALCULATING:
      return api.updateTaskStatus('calculating', taskId);
    case STATUS_COMPLETED:
      return api.updateTaskStatus('completed', taskId);
    case IS_FORMED:
      return api.updateTaskStatus('formed', taskId);
    case IS_ISSUED:
      return api.updateTaskStatus('issued', taskId);
    case STATUS_NEW:
      return api.updateTaskStatus('new', taskId);
    case READY_TO_FORMED:
      return api.updateTaskStatus('ready', taskId);
    case IS_ISSUED_ARCHIVE:
      return api.updateTaskStatus('archived', taskId);
    default:
      message.error(t('ERROR_CHANGE_STATUS_TASK_DC_TO_UNKNOWN'));
  }
};

class StatusComponent extends Component {
  fileUpload = React.createRef();

  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      existsStatuses: [],
      docTypes: [],
      clicked: false,
    };
  }

  componentDidMount() {
    const { selectedTask, history } = this.props;
    if (selectedTask && selectedTask.id) {
      this.loadData();
    }

    this.unblock = setUnBlockSidebar(history, 'CHANGED_SIDEBAR_ON_CLOSE_CONFIRM_CANCEL_PASSPORT_CHANGE_LOCATION');
  }

  componentDidUpdate(prevProps) {
    const { selectedTask } = this.props;

    if ((!prevProps.selectedTask || !prevProps.selectedTask.id) && selectedTask && selectedTask.id) {
      this.loadData();
    }

    if (
      (
        (!prevProps.selectedTask && selectedTask && selectedTask.id)
        || (prevProps.selectedTask && selectedTask && prevProps.selectedTask.id !== selectedTask.id)
      )
      && this.form.current
    ) {
      this.form.current.setFieldsValue({
        sending_letter_date:
          selectedTask.sending_letter_date ? moment(selectedTask.sending_letter_date) : null,
        receiving_letter_date:
          selectedTask.receiving_letter_date ? moment(selectedTask.receiving_letter_date) : null,
        execution_date:
          selectedTask.execution_date ? moment(selectedTask.execution_date) : null,
      });
    }
  }

  componentWillUnmount() {
    this.unblock && this.unblock();
  }

  loadData = async () => {
    this.setState({ isLoading: true });
    const { status, data } = await api.getTaskDCStatuses();
    this.setState({ isLoading: false });

    if (status === 200) {
      this.setState({ existsStatuses: data.results });
    }
  };

  updateStatus = async (status) => {
    const { selectedTask, t, loadTasks } = this.props;

    const loadingMessageKey = LOADING_MESSAGE_KEY;

    message.loading({ content: t('DC_TASK_SUCCESS_STATUS_UPDATE_MESSAGE'), key: loadingMessageKey });

    this.setState({ isLoading: true });
    const result = await changeStatusForTaskDC(t, selectedTask.id, status);
    const statusData = await api.getTaskStatusV2(selectedTask.id);

    if (result.status === 200 && statusData.status === 200) {
      await loadTasks();

      message.success({ content: t('PASSPORT_TASKS_STATUS_UPDATED'), key: loadingMessageKey });
    } else {
      message.error({
        content: `${t('PASSPORT_TASKS_STATUS_UPDATED_ERROR')}: ${result.data.error || result.data.detail}`,
        key: loadingMessageKey,
      });
    }

    this.setState({ isLoading: false });
  };

  validateFile = (file) => {
    const { t, settings } = this.props;
    const maxSize = file && file.size < settings.MAX_UPLOAD_FILE_SIZE;
    const minSize = file && file.size > 0;
    const allowedSize = minSize && maxSize;

    if (!maxSize) {
      message.error(`${t('FILE_MAX_UPLOAD_FILE_SIZE_MESSAGE')} ${settings.MAX_UPLOAD_FILE_SIZE / KB / KB}Mb`);
    } else if (!minSize) {
      message.error(t('FILE_MIN_UPLOAD_FILE_SIZE_MESSAGE'));
    }

    return allowedSize;
  };

  loadStorages = async () => {
    const { user } = this.props;

    const { data } = await api.getStorages({
      status: 'available',
      type: 'local',
      dispatcher_center: user && user.dispatcher_center
        ? user.dispatcher_center.id
        : null,
    });

    return data.results;
  };

  loadDocumentsTypes = async () => {
    const { data, status } = await api.getDocumentTypes();

    if (status === 200) {
      this.setState({
        docTypes: data.results,
      });
    }
  };

  uploadTask = async () => {
    const {
        sidebarType, sidebarIsOpen, openCreateTaskSidebar, closeCreateTaskSidebar, passportId, selectedTask, updateDocuments,
    } = this.props;

    // TODO: заготовка на будущее, если потребуется загружать ограниченный тип документов
    // const accept = [
    //   '.odt',
    //   '.doc',
    //   '.docx',
    //   'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    // ].join(',');

    if (sidebarIsOpen && sidebarType === CREATE_TASK) {
      closeCreateTaskSidebar();
    } else {
      openCreateTaskSidebar(
        CREATE_TASK,
        {
          passportId,
          selectedTask: selectedTask,
          mode: TASK_MODE_AGREEMENT,
          // TODO: заготовка на будущее, если потребуется загружать ограниченный тип документов
          // accept: accept,
          // TODO: заготовка на будущее, если потребуется отслеживать был ли загружен документ
          // callback: (task, taskDocument) => {
          callback: () => {
            updateDocuments && updateDocuments(true);
          },
        },
        700,
        true
      );
    }
  };

  onDownloadTask = async (isDownloadMode = true, calcViewMode) => {
    const {
      rights, isArchived,
    } = this.props;

    const isEditRight = rights[RIGHT_PASSPORT_TASKS] === MODE_EDIT && !isArchived;

    if (!isEditRight) {
      return false;
    }

    this.setState({ isLoading: true });
    if (isDownloadMode) {
      await this.downloadTask();
    } else {
      await this.generateTask(calcViewMode);
    }
    this.setState({ isLoading: false, clicked: false });

    return undefined;
  };

  downloadTask = async () => {
    const {
      selectedTask, t,
    } = this.props;

    const { status, data } = await api.getDocumentsByParams({ task_dc: selectedTask.id });

    if (
      status === 200
      && data.results
      && data.results.length
      && data.results[0]
      && data.results[0].external_links
    ) {
      openAllDocumentLinks(data.results[0], this);
    } else {
      message.error(t('PASSPORT_TASKS_DOWNLOADING_ERROR'));
    }
  };

  generateTask = async (calcViewMode) => {
    const { t, wsState, selectedTask, staticRec, blank } = this.props;

    const socket = wsState && wsState.instance;
    const isSocketConnected = wsState && wsState.connected;

    if (socket && isSocketConnected) {
      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('PASSPORT_TASKS_DOWNLOAD_TASK_EXPORT')} "${selectedTask.name}"`,
        url: `/v1/task-dcs/${selectedTask.id}/word/?calc_view_mode=${
          this.getOldType(selectedTask, blank) ? 'primary' : calcViewMode
        }${
          !this.getOldType(selectedTask, blank) && calcViewMode === 'primary'
            ? `&static_rec=${staticRec}`
            : ''
        }`,
        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }));
    }
  };

  updateTaskDates = async (date) => {
    const { selectedTask, loadTasks, passportId } = this.props;
    const dateKey = Object.keys(date)[0];
    const dateFormatted = date[dateKey] && date[dateKey].format('YYYY-MM-DD');

    this.setState({ isLoading: true });
    const { status } = await api.patchTaskDCV2(selectedTask.id, {
      [dateKey]: dateFormatted,
      passport: passportId,
    });
    this.setState({ isLoading: false });

    if (status === 200) {
      loadTasks();
    }
  };

  handleClickChange = visible => {
    this.setState({ clicked: visible });
  };

  getOldType = (selectedTask, blank) => {
    if (blank && selectedTask) {
      if (selectedTask?.blanks?.length === 1) {
        return selectedTask?.blanks[0]?.old_type;
      } else {
        return blank?.old_type;
      }
    }
  };

  render() {
    const {
      rights, t, selectedTask, isArchived, isDefaultOpenPanel, titleExtra, blank
    } = this.props;
    const {
      isLoading, existsStatuses,
    } = this.state;

    const resultStatus = selectedTask && selectedTask.status;
    const currentStatus = resultStatus && resultStatus.current_status && resultStatus.current_status.id;
    const currentStep = existsStatuses.findIndex((item) => item.id === currentStatus);

    const isEditRight = rights[RIGHT_PASSPORT_TASKS] === MODE_EDIT && !isArchived;

    let menuButton = null;
    let messageText = false;

    const downloadTaskButton = this.getOldType(selectedTask, blank) ? (
      <Button
        icon={<DownloadOutlined />}
        onClick={() => this.onDownloadTask(false)}
        disabled={isLoading}
        className="bordered"
      >
        {t('PASSPORT_TASKS_DOWNLOAD_TASK')}
      </Button>
    ) : (
      <Popover
        content={
          <div>
            <Button onClick={() => this.onDownloadTask(false, 'primary')}>Первичные</Button>
            <Icon
              component={TransformerIcon}
              style={{ padding: '0 5px' }}
            />
            <Button onClick={() => this.onDownloadTask(false, 'secondary')}>Вторичные</Button>
          </div>
        }
        trigger="click"
        visible={this.state.clicked}
        onVisibleChange={this.handleClickChange}
      >
        <Button
          icon={<DownloadOutlined />}
          disabled={isLoading}
          className="bordered"
        >
          {t('PASSPORT_TASKS_DOWNLOAD_TASK')}
        </Button>
      </Popover>
    );

    switch (currentStatus) {
      case STATUS_PLANNED:
        messageText = selectedTask && selectedTask.source_blanks && selectedTask.source_blanks.length
          ? false
          : t('TASK_DC_MESSAGE_PLANNED');
        break;
      case STATUS_NEW:
        messageText = t('TASK_DC_MESSAGE_NEW');
        break;
      case STATUS_CALCULATING:
        messageText = [
          t('TASK_DC_MESSAGE_CALCULATING_1'),
          (<b key='highlighted_status'>{t('TASK_DC_MESSAGE_CALCULATING_STATUS')}</b>),
          t('TASK_DC_MESSAGE_CALCULATING_2'),
        ];
        break;
      case READY_TO_FORMED:
        if (isEditRight) {
          menuButton = (
            <>
              {downloadTaskButton}
              <Button
                icon={<UploadOutlined />}
                onClick={this.uploadTask}
                disabled={isLoading}
                className='bordered'
              >
                {t('PASSPORT_TASKS_UPLOAD_ADJUST_TASK')}
              </Button>
            </>
          );
          messageText = [
            t('TASK_DC_MESSAGE_READY_TO_FORMED_1'),
            (<b key='highlighted_status'>{t('TASK_DC_MESSAGE_READY_TO_FORMED_STATUS')}</b>),
            t('TASK_DC_MESSAGE_READY_TO_FORMED_2'),
          ];
        }
        break;
      case IS_FORMED:
        menuButton = (
          <Button
            icon={<UploadOutlined />}
            onClick={this.uploadTask}
            disabled={isLoading}
            className='bordered'
          >
            {t('PASSPORT_TASKS_UPLOAD_ADJUST_TASK')}
          </Button>
        );
        messageText = [
          t('TASK_DC_MESSAGE_FORMED_1'),
          (<b key='highlighted_status'>{t('TASK_DC_MESSAGE_FORMED_STATUS')}</b>),
          t('TASK_DC_MESSAGE_FORMED_2'),
        ];
        break;
      case IS_APPROVED:
        if (isEditRight) {
          menuButton = downloadTaskButton;
        }
        break;
      case IS_ISSUED:
        if (isEditRight) {
          menuButton = (
            <>
              {
                (!resultStatus.next_status || !resultStatus.next_status.action_name)
                  ? (
                    <Button
                      icon={<ArrowRightOutlined />}
                      onClick={() => this.updateStatus(STATUS_COMPLETED)}
                      disabled={isLoading}
                    >
                      {t('PASSPORT_TASKS_COMPLETE_TASK')}
                    </Button>
                  )
                  : ''
              }
              {downloadTaskButton}
            </>
          );
        }
        break;
      case STATUS_COMPLETED:
        if (isEditRight) {
          menuButton = downloadTaskButton;
        }
        break;
      case IS_ISSUED_ARCHIVE:
        if (isEditRight) {
          menuButton = downloadTaskButton;
        }
        break;
      default: break;
    }

    return (
      <CustomCard
        collapsable
        noBottomMargin
        titleExtra={titleExtra}
        loading={isLoading}
        isDefaultOpenPanel={isDefaultOpenPanel}
        className={styles.statuses}
        hasData={!!resultStatus && isEditRight}
        title={t('STATUS')}
        extra={resultStatus ? (
          <>
            <Row>
              {currentStep === 0 && (
                <GetStarted
                  isEdit={!isLoading && selectedTask && selectedTask.source_blanks && selectedTask.source_blanks.length}
                  selectedTask={selectedTask}
                  changeStatus={() => this.updateStatus(STATUS_NEW)}
                  loading={isLoading}
                />
              )}
              {currentStep > 0 && resultStatus.prev_status && resultStatus.prev_status.action_name && (
                <Button
                  style={{ marginLeft: 8 }}
                  icon={<ArrowLeftOutlined />}
                  className='bordered'
                  onClick={() => this.updateStatus(resultStatus.prev_status.id)}
                  disabled={isLoading}
                >
                  {resultStatus.prev_status.action_name}
                </Button>
              )}
              {
                currentStep > 0
                && currentStep < existsStatuses.length
                && resultStatus.next_status
                  ? (
                    Array.isArray(resultStatus.next_status) && resultStatus.next_status.length
                      ? (
                        resultStatus.next_status.filter((x) => x.action_name).map((x) => (
                          <Button
                            type='primary'
                            key={x.action_name}
                            icon={<ArrowRightOutlined />}
                            onClick={() => this.updateStatus(x.id)}
                            disabled={isLoading}
                            className='bordered'
                          >
                            {x.action_name}
                          </Button>
                        ))
                      )
                      : (
                        resultStatus.next_status && resultStatus.next_status.action_name
                          ? (
                            <Button
                              type='primary'
                              icon={<ArrowRightOutlined />}
                              onClick={() => this.updateStatus(resultStatus.next_status.id)}
                              disabled={isLoading}
                              className='bordered'
                            >
                              {resultStatus.next_status.action_name}
                            </Button>
                          )
                          : ''
                      )
                  )
                  : ''
              }
              {menuButton}
            </Row>
            {[IS_ISSUED_ARCHIVE, STATUS_COMPLETED, IS_ISSUED].includes(currentStatus) && (
              <Row className='status__row2'>
                <Form
                  ref={this.form}
                  onValuesChange={(val) => this.updateTaskDates(val)}
                  className={styles.dateForm}
                  layout='inline'
                  initialValues={{
                    sending_letter_date:
                      selectedTask.sending_letter_date ? moment(selectedTask.sending_letter_date) : null,
                    receiving_letter_date:
                      selectedTask.receiving_letter_date ? moment(selectedTask.receiving_letter_date) : null,
                    execution_date:
                      selectedTask.execution_date ? moment(selectedTask.execution_date) : null,
                  }}
                >
                  <Form.Item
                    label={t('SENDING_TASK_DC_LETTER_DATE')}
                    name='sending_letter_date'
                  >
                    <DatePicker
                      allowClear={false}
                      placeholder={t('DATE')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('RECEIVE_TASK_DC_LETTER_DATE')}
                    name='receiving_letter_date'
                  >
                    <DatePicker
                      allowClear={false}
                      placeholder={t('DATE')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('EXECUTION_TASK_DC_DATE')}
                    name='execution_date'
                  >
                    <DatePicker
                      allowClear={false}
                      placeholder={t('DATE')}
                    />
                  </Form.Item>
                </Form>
              </Row>
            )}
          </>
        ) : null}
      >
        {!resultStatus && (<CustomEmpty />)}
        {resultStatus && (
          <Spin spinning={isLoading}>
            <Steps current={currentStep} size='small'>
              {existsStatuses.map((item) => (
                <Step key={`step_${item.id}`} id={`step_${item.id}`} title={item.name} />
              ))}
            </Steps>
            {currentStep !== undefined && messageText && (
              <div className={styles.alert}>
                <Alert
                  showIcon
                  message={messageText}
                  type='info'
                />
              </div>
            )}
          </Spin>
        )}
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  settings: state.settings.data,
  user: state.user.info,
  wsState: state.websocket,
  sidebarType: state.sidebar.componentType,
  sidebarIsOpen: state.sidebar.isOpen,
});

const mapDispatchToProps = {
  openCreateTaskSidebar: openSidebar,
  closeCreateTaskSidebar: closeSidebar,
};

const Statuses = connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      StatusComponent
    )
  )
);

export {
  Statuses,
  changeStatusForTaskDC,
};
