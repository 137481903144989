import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import c from 'classnames';

import { Divider, Button } from 'antd';
import { getLinkForDocument } from '@routes/passport/helpers';

import styles from '../../styles.module.less';

class CommentCard extends React.Component {
  render() {
    const {
      id,
      created_by,
      startup_date,
      commentary,
      answers,
      handleAnswer,
      isSelected,
      documents,
      selectedComment,
      parent,
      rights,
      t,
    } = this.props;
    const commentClasses = c(styles.comment, { [styles.selected]: isSelected });

    return (
      <>
        <div className={commentClasses}>
          <div className={styles.commentTitle}>{created_by}</div>
          <div className={styles.content}>
            {moment(startup_date)
              .locale('ru')
              .format(`LL ${t('IN')} HH:mm`)}
          </div>
          <p>{commentary}</p>
          <div className={styles.document}>
            {
              documents
              && !!documents.length
              && getLinkForDocument(this, documents[0], true)
            }
          </div>
          <Button
            size='small'
            tabIndex={0}
            onClick={() => handleAnswer({ id, created_by })}
            className={styles.content}
          >
            {t('MAKE_ANSWER')}
          </Button>
        </div>
        {answers && !!answers.length && (
          <div className={styles.answers}>
            {answers.map((item) => (
              <CommentCard
                {...item}
                t={t}
                rights={rights}
                key={item.id}
                selectedComment={selectedComment}
                isSelected={item.id === selectedComment.id}
                handleAnswer={handleAnswer}
              />
            ))}
          </div>
        )}
        {parent === null && <Divider />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  wsState: state.websocket,
  rights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    CommentCard
  )
);
