import { Dispatch } from 'redux';
import { AppThunk } from '@state/store';
import { SAVE_TEMPLATES } from '@state/templates/types';
import {
  WS_CONNECTION_INIT,
  WS_CONNECTION_SUCCESS,
  WS_CONNECTION_ERROR,
  WS_CONNECTION_CLOSED,
  WS_CONNECTION_MESSAGE,
  WS_CLEAR_MSG,
  wsConnectionAction,
  wsConnectionInitAction,
  wsMessageType,
  wsRunAction,
} from './types';

export const wsConnectionInit = (instance: WebSocket): wsConnectionInitAction => ({
  type: WS_CONNECTION_INIT,
  instance,
});
export const wsConnectionSuccess = (): wsConnectionAction => ({ type: WS_CONNECTION_SUCCESS });
export const wsConnectionError = (): wsConnectionAction => ({ type: WS_CONNECTION_ERROR });
export const wsConnectionClosed = (): wsConnectionAction => ({ type: WS_CONNECTION_CLOSED });

export const wsMessage = (message: wsMessageType) => ({
  type: WS_CONNECTION_MESSAGE,
  message,
});

export const clearMessage = () => ({ type: WS_CLEAR_MSG });

export const initializeWebSocket = (host: string): AppThunk => {
  return (dispatch) => {
    const socket = new WebSocket(host);

    dispatch(wsConnectionInit(socket));

    socket.onopen = () => {
      dispatch(wsConnectionSuccess());
    };

    socket.onerror = (e) => {
      console.error('Websocket Error. ', e);
      dispatch(wsConnectionError());
    };

    socket.onclose = () => {
      console.warn('Websocket Connection Closed');
      dispatch(wsConnectionClosed());
    };

    socket.onmessage = (event) => {
      dispatch(wsMessage(JSON.parse(event.data)));
    };
  };
};

export const wsRunActionSuccess = (action: wsRunAction, dispatch: Dispatch) => {
  const {
    subType, title, frontId, message,
  } = action;

  switch (subType) {
    case SAVE_TEMPLATES:
      dispatch({
        type: `${subType}_SUCCESS_START`,
        title,
        frontId,
        message,
      });
      break;
    default:
      break;
  }
};

export const wsRunActionError = (action: wsRunAction, dispatch: Dispatch) => {
  const {
    subType, title, frontId, message,
  } = action;

  switch (subType) {
    case SAVE_TEMPLATES:
      dispatch({
        type: `${subType}_ERROR_START`,
        title,
        frontId,
        message,
      });
      break;
    default:
      break;
  }
};
