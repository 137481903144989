import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { OneNews } from '@services/api-dts';
import { Button, Divider, message, Spin } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import api from '@services/api';
import i18n from '@app/locale';
import { useVisibility } from '@globalHelpers';
import { NEWS_INFINITE_SCROLL_PAGE_SIZE } from './constants';
import styles from './styles.module.less';
import NewsItem from './newsItem';
import { PlusOutlined } from '@ant-design/icons';
import NewsFormModal, { newsFormModalTypes, NewsSubmitType } from './newsFormModal';
import { connect } from 'react-redux';
import { RootState } from '@state/store';
import { ConnectedProps } from 'react-redux';

export interface INewsList extends WithTranslation {}

type NewsListProps = ConnectedProps<typeof connector>;

const NewsList: FC<NewsListProps> = memo(({ rights, t }) => {
  const [news, setNews] = useState<OneNews[]>([]);
  const [isEditAllowed, setIsEditAllowed] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<newsFormModalTypes>('create');
  const [modalInitial, setModalInitial] = useState<OneNews>();
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [modalEditingId, setModalEditingId] = useState<string>();

  const checkEditAbility = useCallback(() => {
    if (rights.RIGHT_NEWS === 'edit') {
      setIsEditAllowed(true);
    } else setIsEditAllowed(false);
  },[rights]);

  //#region СПИСОК НОВОСТЕЙ

  const loadNews = useCallback(async (offset: number) => {
    const res = await api.getNews({
      offset: offset,
      limit: NEWS_INFINITE_SCROLL_PAGE_SIZE,
    });
    return res;
  },[]);

  const dropValues = useCallback(() => {
    setNews([]);
    setCount(0);
    setOffset(0);
  },[]);

  const getNewsNormally = useCallback(async () => {
    dropValues();
    setLoading(true);
    const { status, data } = await loadNews(0);
    if (status === 200) {
      setNews(data.results);
      setOffset(data.results.length);
      setCount(data.count);
    }
    setLoading(false);
    return;
  },[loadNews, dropValues]);

  const getNewsForScrolling = useCallback(async () => {
    setLoading(true);
    let newItems = [];
    const { status, data } = await loadNews(offset);
    if (status === 200) {
      newItems = data.results;
    }
    setLoading(false);
    return newItems;
  },[offset, loadNews]);

  const lastNews = useVisibility(
    (isVisible) => {
      if (isVisible) {
        getNewsForScrolling().then((newItems) => {
          setOffset(offset + newItems.length);
          setNews([...news, ...newItems]);
        });
      }
    },
    [offset, news]
  );

  const deleteNewsFromState = useCallback((id: string) => {
    const newNews = [...news];
    const index = newNews.findIndex((item) => item.id === id);
    if (index !== -1) {
      newNews.splice(index, 1);
      setNews(newNews);
      setOffset(offset - 1);
      setCount(count - 1);
    }
  },[count, news, offset]);

  const deleteNews = useCallback(async (id: string) => {
    setLoading(true);
    const res = await api.deleteNews(id);
    if (res.status === 200 || res.status === 204) {
      message.success(t('NEWS_DELETION_SUCCESS'));
      deleteNewsFromState(id);
    } else {
      message.error(t('NEWS_DELETION_ERROR'));
    }
    setLoading(false);
  },[t, deleteNewsFromState]);

  const updateNewsInState = useCallback((id: string, data: NewsSubmitType) => {
    const newNews = [...news];
    const index = newNews.findIndex((item) => item.id === id);
    if (index !== -1) {
      newNews[index].release_version = data.release_version;
      newNews[index].content = data.content;
      setNews(newNews);
    }
  },[news]);
  //#endregion

  //#region ОБСЛУЖИВАНИЕ МОДАЛКИ
  const openModalForCreate = useCallback(() => {
    setModalType('create');
    setModalEditingId(undefined);
    setModalInitial(undefined);
    setModalVisible(true);
  }, []);

  const createNews = useCallback(() => {
    openModalForCreate();
  }, [openModalForCreate]);

  const openModalForEdit = useCallback((record: OneNews) => {
    setModalInitial(record);
    setModalType('edit');
    setModalVisible(true);
  }, []);

  const editNews = useCallback((id: string) => {
    const record = news.find((item) => item.id === id);
    if (record) {
      setModalEditingId(id);
      openModalForEdit(record);
    }
  },[news, openModalForEdit]);

  const submitModalForm = async (record: NewsSubmitType) => {
    setModalIsLoading(true);
    if (modalType === 'create') {
      const res = await api.createNews(record);
      if (res.status === 201) {
        message.success(t('NEWS_CREATION_SUCCESS'));
        getNewsNormally();
      } else message.error(t('NEWS_CREATION_ERROR'));
    } else {
      const res = await api.editNews(modalEditingId, record);
      if (res.status === 200) {
        message.success(t('NEWS_EDITING_SUCCESS'));
        updateNewsInState(modalEditingId || '', record);
      } else message.error(t('NEWS_EDITING_ERROR'));
    }
    setModalIsLoading(false);
    setModalInitial(undefined);
    closeModal();
  };

  const closeModal = useCallback(() => {
    setModalVisible(false);
  },[]);
//#endregion

  const topButtonBlock = useMemo(
    () =>
      isEditAllowed && (
        <>
          <Button
            type='primary'
            className={styles['news-list-create-button']}
            onClick={createNews}
            icon={<PlusOutlined />}
          >
            {t('CREATE')}
          </Button>
          <Divider />
        </>
      ),
    [isEditAllowed, t, createNews]
  );

  const listBlock = useMemo(
    () => (
      <div className={styles['news-list']}>
        {news.map((item) => (
          <NewsItem
            editCallback={isEditAllowed ? editNews : undefined}
            deleteCallback={isEditAllowed ? deleteNews : undefined}
            i18n={i18n}
            tReady={false}
            t={t}
            key={`news_item_${item.id}`}
            ref={
              news.length >= NEWS_INFINITE_SCROLL_PAGE_SIZE && news.length < count
                ? news[news.length - 1].id === item.id
                  ? lastNews
                  : null
                : null
            }
            {...item}
          />
        ))}
      </div>
    ),
    [news, isEditAllowed, t, editNews, deleteNews, count, lastNews]
  );

  useEffect(() => {
    getNewsNormally();
    checkEditAbility();
  }, [checkEditAbility, getNewsNormally]);


  return (
    <>
      <Spin spinning={loading}>
        {topButtonBlock}
        {listBlock}
      </Spin>
      <NewsFormModal
        visible={modalVisible}
        type={modalType}
        initial={modalInitial}
        cancelCallback={closeModal}
        submitCallback={submitModalForm}
        isLoading={modalIsLoading}
      />
    </>
  );
});

const mapStateToProps = (state: RootState, props: INewsList) => ({
  rights: state.rights.rightsData,
  ...props,
});

const mapDispatchToProps = () => {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connect(mapStateToProps)(withTranslation()(NewsList));
