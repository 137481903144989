import React from 'react';

import CreateTemplateComponent from './createTemplate';

export const CreateTemplate = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return <CreateTemplateComponent templateId={id} />;
};

export default CreateTemplate;
