import React from 'react';
import c from 'classnames';

import {
  Layout, Typography, Breadcrumb, Tabs, Divider,
} from 'antd';
import { withRouter, Switch } from 'react-router-dom';

import PrivateRoute from '@common/privateRoute';

import './styles.less';

const { TabPane } = Tabs;
const { Content } = Layout;
const { Title } = Typography;

class LayoutComp extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();

    const { tabs } = props;

    this.state = {
      offsetTop: 0,
      activeTab: (tabs && tabs[0] && tabs[0].id) || undefined,
    };
  }

  componentDidMount() {
    const cardRect = this.cardRef && this.cardRef.current.getBoundingClientRect();

    this.setState({ offsetTop: cardRect.top + 16 }); // customCard margin bottom
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTabUrl, defaultTabUrl } = this.props;
    const { offsetTop } = this.state;

    if (activeTabUrl === undefined && defaultTabUrl !== undefined) {
      this.replaceTabUrl(defaultTabUrl);
    }

    if (prevState.offsetTop !== offsetTop) {
      const cardRect = this.cardRef && this.cardRef.current.getBoundingClientRect();

      this.setState({ offsetTop: cardRect.top + 16 });
    }
  }

  changeTab = (activeTab) => {
    const { tabs, history, match } = this.props;

    const tab = tabs.find(({ id }) => activeTab === id);

    if (tab && tab.url) {
      history.push(`${match.path}`.replace(':tab', tab.url), { tab: tab.url });
    } else {
      this.setState({ activeTab });
    }
  };

  replaceTabUrl = (url) => {
    const { history, match } = this.props;
    history.replace(`${match.url}/tab/${url}`, { tab: url });
  };

  render() {
    const {
      className,
      childrenClassName,
      children,
      title,
      titleExtraButton,
      breadcrumbs,
      renderBreadcrumbs,
      tabs,
      activeTabUrl,
      baseUrl,
    } = this.props;
    const { activeTab, offsetTop } = this.state;
    const tabIndex = tabs
      ? (activeTabUrl !== undefined
        ? tabs.findIndex(({ url }) => activeTabUrl === url)
        : tabs.findIndex(({ id }) => activeTab === id))
      : -1;
    const classes = c(className, 'custom-layout');
    const titleClasses = c('custom-layout__title', { 'custom-layout__title_with-tabs': !!tabs });
    const hasHead = title || tabs;
    const layoutPadding = 5;

    return (
      <Layout className={classes}>
        {breadcrumbs && (
          <Breadcrumb>
            {breadcrumbs.map((crumb) => (
              <Breadcrumb.Item>{crumb}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        {renderBreadcrumbs}
        {hasHead && (
          <Content className={titleClasses}>
            {
              title && (
                <div className='custom-layout__title__wrapper'>
                  <div className='custom-layout__title__text'>
                    <Title level={5}>
                      {title}
                    </Title>
                  </div>
                  {
                    titleExtraButton && (
                      <div className='custom-layout__title__buttonBlock'>
                        {titleExtraButton}
                      </div>
                    )
                  }
                </div>
              )
            }
            {title && tabs && <Divider />}
            {tabs && (
              <Tabs
                defaultActiveKey={tabs[0] && tabs[0].id}
                onChange={this.changeTab}
                activeKey={tabs[tabIndex] && tabs[tabIndex].id}
              >
                {tabs.map((tab) => (
                  <TabPane tab={tab.name} key={tab.id} />
                ))}
              </Tabs>
            )}
          </Content>
        )}
        <div className={childrenClassName} ref={this.cardRef}>
          {!baseUrl && tabs && tabs[tabIndex] && tabs[tabIndex].component}
          {baseUrl && (
            <Switch>
              {tabs.map((tab) => (
                <PrivateRoute
                  key={tab.id}
                  path={`/${baseUrl}/tab/${tab.url}`}
                  render={() => {
                    return tab.component;
                  }}
                />
              ))}
            </Switch>
          )}
          {children && !tabs && React.cloneElement(children, {
            offsetTop: offsetTop + layoutPadding,
          })}
          {children && tabs && React.cloneElement(children, {
            activeTab: tabs[tabIndex] && tabs[tabIndex].id,
            offsetTop: offsetTop + layoutPadding,
          })}
        </div>
      </Layout>
    );
  }
}

export default withRouter(
  LayoutComp
);
