import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import moment from 'moment';
import {
  Button, message, Popconfirm, Form, Spin, Typography
} from 'antd';
import { cloneDeep, isEqual } from 'lodash';

import { LIBRARY_LOCAL } from '@app/constants';
import DocumentsUpload from '@common/documentsUploadSidebar';
import { DOC_SIDEBAR_TASK_CREATE_TASK, TASK_DOCUMENT } from '@common/documentsUploadSidebar/constants';
import api from '@services/api';
import store from '@state/store';
import { closeSidebar, setIsChangedSidebar } from '@state/sidebar/actions';
import { SidebarLayout } from '@ui';
import { TYPES_OF_CHECKBOX_GROUP } from "./constants";
import TaskForm from './taskForm';
import {
  STATUS_PLANNED, STATUS_NEW, TASK_MODE_EDIT, TASK_MODE_CREATE, TASK_MODE_AGREEMENT,
} from '../constants';

import styles from './styles.module.less';
import { clearHashFromFile, decodePath, saveFileToStorage } from "@app/helpers";

const { Text } = Typography;

const dateFormat = 'YYYY-MM-DD';

class CreateTaskSidebar extends Component {
  taskForm = React.createRef();

  refDocuments = React.createRef();

  docComponent = undefined;

  state = {
    submitting: false,
    confirmVisible: false,
    isBlocked: true,
    confirmText: 'PASSPORT_TASKS_CREATE_CONFIRM',
    activeBlanksList: [],
    blanksList: {},
    selectedBlanksList: {},
    passportList: [],
    taskDCDocType: undefined,
    localStorage: [],
    isLocalStorageError: false,
    isLocalStorageLoading: false,
    taskDocument: {
      ...TASK_DOCUMENT,
    },
    initialValues: undefined,
    initialValuesForCompare: undefined,
    hasEqDocument: false,
    selectedCheckboxValues: [],
    metaMmnData: [],
    instructionsNewFileList: [],
    instructionsCurrentFileList: [],
    instructionsDeletedFileList: [],
    formValues: {
      old_files: [],
      deleted_external_links: [],
      external_links: [],
      library_type: LIBRARY_LOCAL,
      files: [],
    },
    currentTaskDocumentNameValue: ''
  };

  componentDidMount = async () => {
    const { data: { selectedTask, passportId, mode } } = this.props;

    await this.setFormInitValues();

    const passportList = [];
    passportList.push(passportId);
    if (selectedTask && selectedTask.passports && selectedTask.passports.length) {
      for (const x of selectedTask.passports) {
        x.id !== passportId && passportList.push(x.id);
      }
    }
    await this.loadDocumentsTypesForTaskDC();
    await this.loadLocalStorages();
    if (mode !== TASK_MODE_CREATE) {
      await this.loadTaskDocument();
    }

    this.getMetaMmnSelectedTask();
    this.getInstructionsCurrentFileListSelectedTask();

    const { taskDCDocType, taskDocument } = this.state;

    this.setState({
      isBlocked: false,
      passportList,
      taskDocument: {
        ...taskDocument,
        document_type: taskDCDocType,
      },
    }, async () => {
      await this.loadBlanks(true, () => this.changeFormInitValues());
    });
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      this.updateCurrentTaskDocumentNameValue(prevProps, prevState);
    }, 0)
  }

  updateCurrentTaskDocumentNameValue = (prevProps, prevState) => {
    const { formValues, currentTaskDocumentNameValue, isBlocked } = this.state;

    const setDocNameToFirstOldFile = () => {
      if (!formValues?.old_files?.length) {
        return;
      }

      const firstOldFile =
        formValues?.old_files.length > 0 &&
        clearHashFromFile(
          decodePath(formValues?.old_files[0]).split('/').pop()
        );
      this.setState({ currentTaskDocumentNameValue: firstOldFile });
    }

    const setDocNameToFirstNewFile = () => {
      if (!formValues?.files?.fileList?.length) {
        return;
      }
      const firstNewFile = formValues?.files?.fileList[0].name;
      this.setState({ currentTaskDocumentNameValue: firstNewFile });
    }

    const getAllFileNames = () => {
      const dataOldFiles =
        formValues?.old_files?.map((item) =>
          clearHashFromFile(decodePath(item).split('/').pop())
        ) || [];
      const dataDeletedExternalLinks =
        formValues?.deleted_external_links?.map((item) =>
          clearHashFromFile(decodePath(item).split('/').pop())
        ) || [];
      const dataNewFiles = formValues?.files?.fileList?.map((item) => item.name) || [];

      const allData = [...dataOldFiles, ...dataDeletedExternalLinks, ...dataNewFiles];

      return allData;
    }

    const allFileNames = getAllFileNames();

    if (isBlocked) return;
    if (!prevState?.formValues?.old_files && !prevState?.formValues?.files && !prevState?.formValues?.deleted_external_links) return;

    const filesChanged = prevState?.formValues?.old_files !== formValues?.old_files ||
      prevState?.formValues?.files?.fileList !== formValues?.files?.fileList ||
      prevState?.formValues?.deleted_external_links !==
      formValues?.deleted_external_links

    if (filesChanged) {
      if (!currentTaskDocumentNameValue?.length) {
        setDocNameToFirstOldFile();
        setDocNameToFirstNewFile();
        return;
      }

      const isDocNameOverlapOneOfFiles = allFileNames.includes(currentTaskDocumentNameValue);
      if (!isDocNameOverlapOneOfFiles) return;

      if (prevState?.formValues?.files?.fileList !== formValues?.files?.fileList) {
        if (formValues?.files?.fileList?.length) {
          setDocNameToFirstNewFile();
        } else {
          setDocNameToFirstOldFile();
        }
        return;
      }
      setDocNameToFirstOldFile();
    }
  }

  handleFormValues = (newFormValues) => {
    this.setState({ formValues: newFormValues });
  };

  getTaskDocumentName = () => {
    return this.state.currentTaskDocumentNameValue;
    // const { formValues, taskDocument, currentTaskDocumentNameValue } =
    //   this.state;
    // let resultTaskDocument;
    //
    // const firstOldFile =
    //   formValues?.old_files.length > 0 &&
    //   clearHashFromFile(
    //     decodePath(formValues?.old_files[0]).split('/').pop()
    //   );
    //
    // const dataOldFiles =
    //   formValues?.old_files.length > 0 &&
    //   formValues?.old_files.map((item) =>
    //     clearHashFromFile(decodePath(item).split('/').pop())
    //   );
    //
    // if (
    //   formValues?.old_files?.length === 0 &&
    //   formValues?.files?.fileList?.length > 0
    // ) {
    //   resultTaskDocument = formValues?.files?.fileList[0]?.name;
    // } else if (
    //   formValues?.old_files?.length > 0 &&
    //   dataOldFiles.includes(currentTaskDocumentNameValue)
    // ) {
    //   resultTaskDocument = firstOldFile;
    // } else if (
    //   formValues?.files?.length === 0 &&
    //   formValues?.old_files?.length === 0 &&
    //   formValues?.deleted_external_links?.length > 0
    // ) {
    //   resultTaskDocument = '';
    // } else {
    //   resultTaskDocument = taskDocument?.name;
    // }
    // return resultTaskDocument;
  };

  onChangeTaskDocumentNameValue = (value) => {
    const { currentTaskDocumentNameValue } = this.state;

    if (currentTaskDocumentNameValue === value) return;
    this.setState({ currentTaskDocumentNameValue: value });
  };

  onChangeChekboxGroupValues = (checkedValues) => {
    this.setState({ selectedCheckboxValues: checkedValues });
  };

  getMetaMmnSelectedTask = () => {
    const {
      data: { selectedTask },
    } = this.props;

    const dataMetaMmn = selectedTask?.meta_mmn?.map((item) => ({
      modelId: item.meta_mmn_id,
      name:
        item.category_mmn !== null
          ? `${item.category_mmn.dispatcher_center.name} / ${item.category_mmn.program_complex.name} / ${item.name}`
          : item.name,
    }));
    this.setState({ metaMmnData: dataMetaMmn });
  };

  getInstructionsCurrentFileListSelectedTask = () => {
    const {
      data: { selectedTask },
    } = this.props;

    const dataInstructionsCurrentFileList =
      selectedTask?.additional_external_link || [];
    this.setState({
      instructionsCurrentFileList: dataInstructionsCurrentFileList,
    });
  };

  updateMetaMmnData = (data) => {
    this.setState({ metaMmnData: data });
  };

  updateInstructionsNewFileList = (data) => {
    this.setState({ instructionsNewFileList: data });
  };

  updateInstructionsDeletedFileList = (data) => {
    this.setState({ instructionsDeletedFileList: data });
  };

  updateInstructionsCurrentFileList = (data) => {
    this.setState({ instructionsCurrentFileList: data });
  };

  setFormInitValues = async () => {
    const { data } = this.props;

    const selectedTask = data.selectedTask || {};
    const taskType = (data && data.mode === TASK_MODE_CREATE) || selectedTask.status
      ? STATUS_PLANNED
      : STATUS_NEW;

    const initialSelectedCheckboxGroup = [];
    const attributes = selectedTask?.additional_attributes;

    TYPES_OF_CHECKBOX_GROUP.forEach((task) => {
      if (attributes?.[task]) initialSelectedCheckboxGroup.push(task);
    });

    const formInitialValues = {
      status: taskType,
      name: selectedTask.name,
      reason: selectedTask.reason,
      timeframe: selectedTask.timeframe || null,
      source_blank:
        selectedTask.source_blanks &&
        selectedTask.source_blanks.length &&
        selectedTask.source_blanks[0] &&
        selectedTask.source_blanks[0].id,
      date_planned_start:
        selectedTask.date_planned_start &&
        moment(selectedTask.date_planned_start),
      date_planned_completion:
        selectedTask.date_planned_completion &&
        moment(selectedTask.date_planned_completion),
      network_model: 'bmms',
      protection_device_status:
        selectedTask?.additional_attributes?.protection_device_status ||
        'NEW',
      documentation_requisites: selectedTask?.documentation_requisites || '',
      form: selectedTask?.additional_attributes?.form || 'FULL',
      checkbox_group: initialSelectedCheckboxGroup,
      task_document: selectedTask?.task_document
    };

    this.setState({
      initialValues: formInitialValues,
      currentTaskDocumentNameValue : selectedTask?.task_document
    });

    this.taskForm.current && this.taskForm.current.setFieldsValue(formInitialValues);
  }

  changeFormInitValues = () => {
    const { data } = this.props;
    const { selectedBlanksList, initialValues, hasEqDocument } = this.state;

    const selectedTask = data.selectedTask || {};
    const formInitialValuesForSave = cloneDeep(initialValues);

    if (selectedTask.passports && selectedTask.passports.length) {
      selectedTask.passports.forEach((x) => {
        formInitialValuesForSave[`passport[${x.id}]`] = x.id;
        formInitialValuesForSave[`blanks_for_passport[${x.id}]`] = (
          selectedBlanksList
          && selectedBlanksList[x.id]
          && selectedBlanksList[x.id].length
          && selectedBlanksList[x.id].map((y) => y.id)
        ) || [];
      })
    }

    this.setState({
      initialValuesForCompare: formInitialValuesForSave,
    }, () => this.onChangeDocument(hasEqDocument));
  }

  loadDocumentsTypesForTaskDC = async () => {
    const { t } = this.props;

    const { status, data } = await api.getDocumentTypes({ name: t('TASK_DC') });

    if (status === 200 && data.results && data.results.length) {
      const docType = data.results.filter((x) => x.name === t('TASK_DC'));
      this.setState({
        taskDCDocType: docType && docType.length && docType[0],
      });
    }
  };

  loadLocalStorages = async () => {
    const { user } = this.props;

    this.setState({
      isLocalStorageLoading: true
    })

    const { status, data } = await api.getStorages({
      status: 'available',
      dispatcher_center: user && user.dispatcher_center
        ? user.dispatcher_center.id
        : null,
      type: LIBRARY_LOCAL.toLowerCase(),
    });

    if (status === 200 && data && data.results && data.results.length) {
      this.setState({
        localStorage: data.results[0],
        isLocalStorageLoading: false
      });
    } else {
      this.setState({
        isLocalStorageError: true,
        isLocalStorageLoading: false
      })
    }
  };

  loadTaskDocument = async () => {
    const { data } = this.props;
    const { taskDCDocType } = this.state;
    const { passportId, selectedTask } = data;

    const { status: docStatus, data: docData } = await api.getDocumentsByParams({
      passport: passportId,
      task_dc: selectedTask && selectedTask.id,
      document_type: taskDCDocType && taskDCDocType.id,
    });

    if (docStatus === 200 && docData && docData.results && docData.results.length) {
      this.setState({
        taskDocument: docData.results[0],
      });
    }
  }

  loadBlanks = async (isLoadSelected = false, callback) => {
    const { data: { selectedTask } } = this.props;
    const { blanksList, selectedBlanksList } = this.state;

    const selectedTaskId = (selectedTask && selectedTask.id) || undefined;

    this.setState({ isBlocked: true });

    const blanks = await this.loadBlankByPassports(blanksList);
    let selectedBlanks = selectedBlanksList;
    if (isLoadSelected && selectedTaskId) {
      selectedBlanks = await this.loadBlankByPassports(selectedBlanksList, selectedTaskId);
    }

    this.setState({
      isBlocked: false,
      blanksList: blanks,
      selectedBlanksList: selectedBlanks,
    }, () => callback && callback());
  }

  loadBlankByPassports = async (blanksList, taskId) => {
    const { passportList, activeBlanksList } = this.state;

    const passportListForRequest = [];
    const newBlanksList = {};
    const newActiveBlanksList = [];

    this.setState({ isBlocked: true });

    passportList.forEach((x) => {
      if (blanksList[x] === undefined) {
        passportListForRequest.push(x);
        newBlanksList[x] = [];
      }
    });

    if (passportListForRequest.length) {
      const result = await api.getBlanks({
        passport: passportListForRequest,
        source_task_dc: taskId,
        status: 'in_work',
      });

      if (result.status === 200 && result.data.results) {
        result.data.results.forEach((x) => {
          newBlanksList[x.passport].push(x);
          if (
            x.id
            && x.is_active
            && !activeBlanksList.includes(x.id)
            && !newActiveBlanksList.includes(x.id)
          ) {
            newActiveBlanksList.push(x.id);
          }
        });

        this.setState({
          isBlocked: false,
          activeBlanksList: newActiveBlanksList,
        });

        return {
          ...blanksList,
          ...newBlanksList,
        };
      }
    }

    this.setState({ isBlocked: false });

    return blanksList;
  };

  onChangePassport = (oldPassportValue, passportValue) => {
    const { passportList } = this.state;

    const oldPassportId = (oldPassportValue && oldPassportValue.value) || oldPassportValue;
    const passportId = (passportValue && passportValue.value) || passportValue;

    let newPassportList = cloneDeep(passportList);

    let isAdd = false;
    const index = newPassportList.indexOf(oldPassportId);
    if (oldPassportId && index !== -1) {
      newPassportList.splice(index, 1);
    }
    if (passportId && newPassportList && !newPassportList.includes(passportId)) {
      isAdd = true;
      newPassportList = [...newPassportList, passportId];
    }
    this.setState({
      passportList: newPassportList,
    }, async () => {
      isAdd && await this.loadBlanks();
    });
  }

  onUpdateBlanksListByPassport = (currentPassportId, callback) => {
    const { blanksList } = this.state;

    const newBlanksList = cloneDeep(blanksList);
    delete newBlanksList[currentPassportId];
    this.setState({
      blanksList: newBlanksList,
    }, () => this.loadBlanks(true, callback));
  }

  handleSubmit = () => {
    const { data: { mode } } = this.props;
    const { activeBlanksList } = this.state;

    const currentForm = this.taskForm.current;

    currentForm.validateFields().then(async (values) => {
      const sourceBlanks = [];
      let sourceBlanksHasActive = false;
      let confirmVisible = false;

      Object.keys(values).forEach((item) => {
        if (item.indexOf('blanks_for_passport') !== -1) {
          if (Array.isArray(values[item])) {
            values[item].forEach((x) => {
              sourceBlanks.push(x.key);
              sourceBlanksHasActive = sourceBlanksHasActive || activeBlanksList.includes(x.key);
            });
          } else if (typeof values[item] === 'object' && values[item] && values[item].key) {
            sourceBlanks.push(values[item].key);
            sourceBlanksHasActive = sourceBlanksHasActive || activeBlanksList.includes(values[item].key);
          }
        } else if (item.indexOf('source_blank') !== -1) {
          sourceBlanks.push(values[item]);
          sourceBlanksHasActive = sourceBlanksHasActive || activeBlanksList.includes(values[item]);
        }
      });

      if (
        mode === TASK_MODE_CREATE
        && values.status === STATUS_PLANNED
      ) {
        if (sourceBlanks.length === 0) {
          confirmVisible = true;
          this.setState({
            confirmVisible: true,
            confirmText: 'PASSPORT_TASKS_CREATE_CONFIRM',
          });
        } else if (!sourceBlanksHasActive) {
          confirmVisible = true;
          this.setState({
            confirmVisible: true,
            confirmText: 'PASSPORT_TASKS_CREATE_CONFIRM_ACTIVE',
          });
        }
      }
      if (!confirmVisible) {
        await this.onConfirm();
      }
    });
  };

  resultCallback = async (currentTask, taskDocument) => {
    const { data } = this.props;

    if (data.callback) {
      await data.callback(currentTask, taskDocument);
    }

    store.dispatch(closeSidebar());
  }

  updateDocState = (params, newState = {}, count = 0, callback) => {
    const docForm = this.refDocuments.current;
    const doc = this.docComponent;

    if (doc && docForm && count > 0) {
      doc.setState(
        (docState) => ({
          formValues: {
            ...docState.formValues,
            ...newState
          },
          autoCreated: false,
          passportsList: params['passports'],
          prepareToSaveFromTaskCD: true,
        }),
        () => {
          callback && callback();
        }
      )
    } else callback && callback();
  }

  onConfirm = async () => {
    const {
      metaMmnData,
      instructionsNewFileList,
      localStorage,
      instructionsDeletedFileList,
      instructionsCurrentFileList,
    } = this.state;
    const { data, setIsChangedDataSidebar, t } = this.props;
    const currentForm = this.taskForm.current;
    const docForm = this.refDocuments.current;
    const doc = this.docComponent;

    let validateFiles = false;
    let linksCount;
    let filesCount;
    let deletedCount;
    let oldFilesCount;

    //валидация формы документов
    if (doc && docForm) {
      const errors = doc && doc.state && doc.state.errors;
      const files = docForm && docForm.getFieldValue('files');
      filesCount = (files && files.fileList && files.fileList.length) || 0;
      const deleted = docForm && docForm.getFieldValue('deleted_external_links');
      deletedCount = (deleted && deleted.length) || 0;
      const links = docForm && docForm.getFieldValue('new_links')
        .replace(/\n+/, '\n')
        .trim()
        .split('\n');
      linksCount = (links && links.length && links[0].length) || 0;

      const oldFiles = docForm && docForm.getFieldValue('old_files');
      oldFilesCount = (oldFiles && oldFiles.length) || 0;

      if ((filesCount + linksCount + deletedCount + oldFilesCount) > 0) {
        try {
          await docForm.validateFields();
          if (!errors || (errors.old_files.length + errors.files.length + errors.new_links.length) === 0) {
            validateFiles = true;
          }
        } catch (e) {
          // Nothing
        }
      } else {
        validateFiles = true;
      }
    } else {
      validateFiles = true;
    }

    this.cancelConfirm();

    validateFiles && currentForm.validateFields().then(async (values) => {
      const params = {
        source_blanks: [],
        passports: [],
        additional_attributes: {},
        documentation_requisites: '',
        meta_mmn: [],
        additional_external_link: [],
        task_document: ''
      };

      Object.keys(values).forEach((item) => {
        if (item.indexOf('blanks_for_passport') !== -1) {
          if (Array.isArray(values[item])) {
            values[item].forEach((x) => params['source_blanks'].push(x.key));
          } else if (typeof values[item] === 'object' && values[item] && values[item].key) {
            params['source_blanks'].push(values[item].key);
          }
        } else if (item.indexOf('source_blank') !== -1) {
          params['source_blanks'].push(values[item]);
          params['passports'].push(data.passportId);
        } else if (item.indexOf('passport') !== -1) {
          if (Array.isArray(values[item])) {
            values[item].forEach((x) => params['passports'].push(x.key));
          } else if (typeof values[item] === 'object' && values[item] && values[item].key) {
            params['passports'].push(values[item].key);
          } else if (typeof values[item] === 'string' && values[item] && values[item].length) {
            params['passports'].push(values[item]);
          }
        } else {
          params[item] = values[item];
        }
      });

      if (!params.timeframe) params.timeframe = '';
      if (params.timeframe === 'null') params.timeframe = '';

      params.date_planned_completion = values.date_planned_completion
        && moment(values.date_planned_completion).format(dateFormat);
      params.date_planned_start = values.date_planned_start
        && moment(values.date_planned_start).format(dateFormat);

      params.additional_attributes['form'] = values.form;
      params.additional_attributes['protection_device_status'] =
        values.protection_device_status;

      TYPES_OF_CHECKBOX_GROUP.forEach((attribute) => {
        if (values?.checkbox_group.includes(attribute)) {
          params.additional_attributes[attribute] = true;
        }
      });

      params.documentation_requisites =
        values.protection_device_status === 'IN_SERVICE'
          ? ''
          : values.documentation_requisites;

      params.meta_mmn = metaMmnData?.map((item) => item.modelId);
      params.task_document = values.task_document;

      let customUrl;

      if (instructionsNewFileList.length > 0) {
        const hashLink = await saveFileToStorage(
          t,
          localStorage,
          instructionsNewFileList[0]
        );
        const newFiles = [...instructionsNewFileList];
        const newFile = newFiles[0];
        const newFileLink = { ...newFile, name: hashLink };
        customUrl = newFileLink.name;
      }

      params.additional_external_link =
        instructionsNewFileList.length > 0
          ? [
            {
              name: instructionsNewFileList[0]?.name,
              file: customUrl,
            },
          ]
          : [];

      delete params.form;
      delete params.protection_device_status;
      delete params.checkbox_group;
      delete params.network_model;

      if (params['passports'].length === 0) {
        params['passports'] = null;
      }
      if (params['source_blanks'].length === 0) {
        params['source_blanks'] = null;
      }

      if (
        instructionsDeletedFileList.length > 0 &&
        instructionsCurrentFileList.length === 0
      ) {
        await Promise.all(
          instructionsDeletedFileList.map((item) =>
            api.request(item.file, 'delete', undefined, {}, {}, true)
          )
        );
      }

      this.cancelConfirm();

      const allCount = filesCount + linksCount + deletedCount + oldFilesCount || 0;
      let currentTask;

      switch (data.mode) {
        case TASK_MODE_CREATE:
          //сохраняем документ
          setIsChangedDataSidebar && setIsChangedDataSidebar(false);
          if (doc && docForm && (filesCount + linksCount + deletedCount + oldFilesCount) > 0) {
            this.updateDocState(params, { task_dcs: undefined, document_task_dcs:[], name: params.task_document }, allCount,
              () => {
                doc.saveDocument(async (taskDocument) => {
                  const document = taskDocument;

                  if (!document || !document.document_task_dcs[0]) return;

                  const newTaskId = document.document_task_dcs[0];
                  currentTask = await api.patchTaskDCV2(newTaskId, params);

                  if (currentTask?.status === 400) {
                    const errorSourceBlanks = currentTask?.data?.source_blanks?.length > 0;
                    if (errorSourceBlanks)
                      message.error(currentTask.data.source_blanks[0]);
                  }

                  this.cancelConfirm();
                  await this.resultCallback(currentTask, document);
                });
              }
            );
          } else {
            currentTask = await this.createTask(params);
            this.cancelConfirm();
            await this.resultCallback(currentTask);
          }
          break;
        case TASK_MODE_EDIT:
          currentTask = await this.saveTask(params);
          if (currentTask && currentTask.id) {
            setIsChangedDataSidebar && setIsChangedDataSidebar(false);
            if (doc && docForm && (filesCount + linksCount + deletedCount + oldFilesCount) > 0) {
              this.updateDocState(params, { document_task_dcs: [currentTask.id], task_dcs: undefined, name: params.task_document }, allCount,
                () => {
                  doc.saveDocument(async (taskDocument) => {
                    this.cancelConfirm();
                    await this.resultCallback(currentTask, taskDocument || null);
                  });
                });
            }
            else {
              this.cancelConfirm();
              await this.resultCallback(currentTask);
            }
          }
          break;
        case TASK_MODE_AGREEMENT:
          currentTask = data && data.selectedTask;
          if (currentTask && currentTask.id) {
            setIsChangedDataSidebar && setIsChangedDataSidebar(false);
            if (doc && docForm && (filesCount + linksCount + deletedCount + oldFilesCount) > 0) {
              this.updateDocState(params, { document_task_dcs: [currentTask.id], task_dcs: undefined, name: params.task_document }, allCount,
                () => {
                  doc.saveDocument(async (taskDocument) => {
                    this.cancelConfirm();
                    await this.resultCallback(currentTask, taskDocument);
                  });
                })
            } else {
              this.cancelConfirm();
              await this.resultCallback(currentTask);
            }
          }
          break;
        default:
          break;
      }
    });
  };

  createTask = async (params) => {
    const { t } = this.props;

    this.setState({ submitting: true });
    const createTaskDCAnswer = await api.createTaskDCV2(params);
    this.setState({ submitting: false });

    if (createTaskDCAnswer.status === 201) {
      message.success(t('PASSPORT_TASKS_CREATE_SUCCESS_CREATE'));
      return createTaskDCAnswer.data;
    } else if (createTaskDCAnswer.data
      && createTaskDCAnswer.data.error
      && createTaskDCAnswer.data.error.length < 250
    ) {
      message.error(createTaskDCAnswer.data.error);
    } else {
      try {
        let errors = Object.keys(createTaskDCAnswer.data).map((x) => createTaskDCAnswer.data[x].join('<br />')).join('<br />');
        message.error(errors);
      } catch (e) {
        console.error(e);
        message.error(t('PASSPORT_TASKS_CREATE_ERROR_CREATE'));
      }
    }
    return false;
  };

  saveTask = async (params) => {
    const { t, data } = this.props;
    this.setState({ submitting: true });
    const response = await api.patchTaskDCV2(data.selectedTask.id, params);
    this.setState({ submitting: false });

    if (response.status === 200) {
      message.success(t('PASSPORT_TASKS_CREATE_SUCCESS_PATCH'));
      return response.data;
    } else {
      message.error(t('PASSPORT_TASKS_CREATE_ERROR_PATCH'));
    }
    return false;
  };

  cancelConfirm = () => {
    this.setState({ confirmVisible: false });
  };

  setCurrentDocumentRef = (ref, component) => {
    this.refDocuments = ref;
    this.docComponent = component;
  }

  onChangeDocument = async (hasEqDocument) => {
    const form = await this.taskForm.current;
    if (form) {
      const currentValues = await form.getFieldsValue(true);

      this.onFormValuesChange(currentValues, hasEqDocument);
    }

    this.setState({
      hasEqDocument,
    });
  }

  onFormValuesChange = (allValues, hasEqDocument) => {
    const { setIsChangedDataSidebar } = this.props;
    const { initialValuesForCompare } = this.state;

    const currentValues = cloneDeep(allValues);

    const valuesKeys = Object.keys(currentValues);
    const valueKeysFilter = valuesKeys.filter((x) => x.includes('blanks_for_passport'));

    if (currentValues && valuesKeys && valueKeysFilter && valueKeysFilter.length) {
      valueKeysFilter.forEach((x) => {
        currentValues[x] && currentValues[x].length && currentValues[x].forEach((_, y) => {
          currentValues[x][y] = currentValues[x][y].key;
        });
      });
    }

    setIsChangedDataSidebar
      && setIsChangedDataSidebar(!(isEqual(currentValues, initialValuesForCompare) && hasEqDocument));
  }

  render() {
    const { data, t, width } = this.props;
    const {
      submitting,
      confirmVisible,
      confirmText,
      hasEqDocument,
      isBlocked,
      blanksList,
      selectedBlanksList,
      passportList,
      taskDocument,
      localStorage,
      taskDCDocType,
      initialValues,
      isLocalStorageError,
      isLocalStorageLoading,
      selectedCheckboxValues,
      metaMmnData,
      instructionsNewFileList,
      instructionsDeletedFileList,
      instructionsCurrentFileList,
      currentTaskDocumentNameValue
    } = this.state;
    const { passportId, deviceId } = data;
    const selectedTask = data.selectedTask || {};

    let headerText = '';
    let buttonText = '';

    switch (data.mode) {
      case TASK_MODE_CREATE:
        headerText = t('PASSPORT_TASKS_CREATE_CREATE_TASK');
        buttonText = t('PASSPORT_TASKS_CREATE_CREATE_TASK');
        break;
      case TASK_MODE_EDIT:
        headerText = t('PASSPORT_TASKS_EDIT_TASK');
        buttonText = t('SAVE');
        break;
      case TASK_MODE_AGREEMENT:
        headerText = t('PASSPORT_TASKS_UPLOAD_TASK');
        buttonText = t('PASSPORT_TASKS_UPLOAD_BUTTON');
        break;
      default:
        break;
    }

    const uploadControl = (
      <DocumentsUpload
        hasChanged={this.onChangeDocument}
        setCurrentDocumentRef={this.setCurrentDocumentRef}
        setBlocked={(newStateIsBlocked) =>
          this.setState((state) => ({ isBlocked: state.isBlocked || newStateIsBlocked }))
        }
        data={{
          type: DOC_SIDEBAR_TASK_CREATE_TASK,
          isEdit: taskDocument && taskDocument.external_links && taskDocument.external_links.length,
          document: taskDocument,
          passports: [passportId],
          localStorage: localStorage,
          docType: taskDCDocType,
        }}
        onFormValuesChange={this.handleFormValues}
      />
    );

    const uploadErrorComponent = (
      <Text type="danger" style={{ marginLeft: 0 }}>
        {t('PASSPORT_TASKS_CREATE_LOCAL_STORAGE_ERROR')}
      </Text>
    );
    const uploadLoadingComponent = (
      <Text style={{ marginLeft: 0 }}>
        {t('PASSPORT_TASKS_CREATE_LOCAL_STORAGE_LOADING')}
      </Text>
    );

    let uploadElement = '';

    const isLocalStorageEmpty = !(localStorage && localStorage.id && taskDCDocType && taskDCDocType.id);

    if (isLocalStorageLoading) uploadElement = uploadLoadingComponent;
    else if (!isLocalStorageError && !isLocalStorageEmpty)
      uploadElement = uploadControl;
    else uploadElement = uploadErrorComponent;

    const blankListLength =
      blanksList &&
      blanksList[passportId] &&
      blanksList[passportId].length >= 0;

    const isDisabledSubmit =
      (data.mode === TASK_MODE_EDIT || selectedTask.status) && !blankListLength;

    return (
      <SidebarLayout
        withoutCard
        sidebarStyle={{ width: `${width}px` }}
        header={<h4>{headerText}</h4>}
        footer={
          <Popconfirm
            visible={confirmVisible}
            onConfirm={this.onConfirm}
            onCancel={this.cancelConfirm}
            title={t(confirmText)}
            okText={t('YES')}
            cancelText={t('NO')}
          >
            <Button
              block
              loading={submitting}
              type='primary'
              form='createTaskForm'
              key='submit'
              htmlType='submit'
              onClick={this.handleSubmit}
              disabled={isDisabledSubmit}
            >
              {buttonText}
            </Button>
          </Popconfirm>
        }
      >
        <Spin spinning={isBlocked}>
          <Form
            name='TaskModalForm'
            ref={this.taskForm}
            className={styles.taskForm}
            initialValues={initialValues}
            onValuesChange={(changedValues, allValues) => this.onFormValuesChange(allValues, hasEqDocument)}
          >
            <TaskForm
              form={this.taskForm.current}
              passportId={passportId}
              selectedTask={selectedTask}
              mode={data.mode}
              blanksList={blanksList}
              selectedBlanksList={selectedBlanksList}
              deviceId={deviceId}
              passports={passportList}
              isBlocked={isBlocked}
              localStorage={localStorage}
              setBlocked={(newStateIsBlocked) => this.setState({ isBlocked: newStateIsBlocked })}
              onChangePassport={this.onChangePassport}
              onUpdateBlanksListByPassport={this.onUpdateBlanksListByPassport}
              uploadElement={uploadElement}
              selectedCheckboxValues={selectedCheckboxValues}
              onChangeChekboxGroupValues={this.onChangeChekboxGroupValues}
              updateMetaMmnData={this.updateMetaMmnData}
              metaMmnData={metaMmnData}
              updateInstructionsNewFileList={this.updateInstructionsNewFileList}
              instructionsNewFileList={instructionsNewFileList}
              updateInstructionsDeletedFileList={this.updateInstructionsDeletedFileList}
              instructionsDeletedFileList={instructionsDeletedFileList}
              updateInstructionsCurrentFileList={this.updateInstructionsCurrentFileList}
              instructionsCurrentFileList={instructionsCurrentFileList}
              isLocalStorageLoading={isLocalStorageLoading}
              isLocalStorageError={isLocalStorageError}
              isLocalStorageEmpty={isLocalStorageEmpty}
              uploadErrorComponent={uploadErrorComponent}
              uploadLoadingComponent={uploadLoadingComponent}
              taskDocument={taskDocument}
              getTaskDocumentName={this.getTaskDocumentName()}
              onChangeTaskDocumentNameValue={this.onChangeTaskDocumentNameValue}
              currentTaskDocumentNameValue={currentTaskDocumentNameValue}
            />
          </Form>
        </Spin>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

const mapDispatchToProps = {
  setIsChangedDataSidebar: setIsChangedSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    CreateTaskSidebar
  )
);
