import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import moment from 'moment';
import {
  Input, Button, Table, message, DatePicker,
} from 'antd';

import { getTemplates } from '@state/templates/actions';
import api from '@services/api';
import { SidebarLayout, SidebarCard } from '@ui';
import { LIMIT, TABLE_SIZE } from '@globalConstants';

import styles from './styles.module.less';

const { Search } = Input;

class CreateBlankSidebar extends Component {
  constructor(props) {
    super(props);
    this.loadTemplates = debounce(this.loadTemplates, 800);

    this.state = {
      inputNameValue: '',
      usageDateStart: null,
      usageDateEnd: null,
      inputTemplateValue: '',
      selectedRowKey: '',
      page: 1,
    };
  }

  componentDidMount() {
    this.loadTemplates();
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, getTemplatesError } = this.props;
    const { inputTemplateValue } = this.state;

    if (prevState.inputTemplateValue !== inputTemplateValue) {
      this.loadTemplates();
      this.setState({ selectedRowKey: '' });
    }

    if (getTemplatesError) {
      message.error(t('PASSPORT_SETPOINTS_API_ERROR'));
    }
  }

  loadTemplates = async () => {
    const { getTemplatesData } = this.props;
    const { page, inputTemplateValue } = this.state;
    const params = {
      limit: LIMIT,
      offset: LIMIT * (page - 1),
      group_by: 'descendant',
      view_mode: 'full',
    };

    if (inputTemplateValue) {
      params.name = inputTemplateValue;
    }

    getTemplatesData(this, params);
  };

  inputChangeHandler = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  dateChangeHandler = (date, dateString, name) => {
    this.setState({
      [name]: date ? moment(date, 'YYYY-MM-DD') : null,
    });
  };

  selectRow = (record) => {
    this.setState({ selectedRowKey: record.id });
  };

  onSelectedRowKeyChange = (selectedRowKey) => {
    this.setState({ selectedRowKey: selectedRowKey[0] });
  };

  onCreateBlank = async () => {
    const { t, data, templates } = this.props;
    const {
      selectedRowKey, inputNameValue, usageDateStart, usageDateEnd,
    } = this.state;
    const selectedTemplate = { ...templates.find((item) => item.id === selectedRowKey) };

    if (selectedTemplate && !selectedTemplate.name) selectedTemplate.name = t('WITHOUT_NAME');
    if (inputNameValue) selectedTemplate.name = inputNameValue;

    const sendingData = {
      name: selectedTemplate.name,
      template: selectedTemplate.id,
      passport: data.passport_id,
      usage_start_date: usageDateStart ? usageDateStart.format('YYYY-MM-DD').concat('T00:00:00') : null,
      usage_finish_date: usageDateEnd ? usageDateEnd.format('YYYY-MM-DD').concat('T00:00:00') : null,
    };
    this.setState({ isSending: true });
    const { status, data: responseData } = await api.createBlank(sendingData);
    this.setState({ isSending: false });

    if (status === 201) {
      data.callback(responseData);
    }
  };

  changePage = (page) => {
    this.setState({ page }, () => this.loadTemplates());
  };

  disabledDate = (current, name) => {
    const { usageDateStart, usageDateEnd } = this.state;

    if (name === 'usageDateEnd' && usageDateStart) {
      return current && current <= moment(moment(usageDateStart).format('YYYY-MM-DD').concat('T23:59:59'));
    }

    if (name === 'usageDateStart' && usageDateEnd) {
      return current && current >= moment(moment(usageDateEnd).format('YYYY-MM-DD').concat('T00:00:00'));
    }

    return null;
  };

  render() {
    const {
      t, templates, count, templatesLoading,
    } = this.props;
    const {
      inputNameValue,
      inputTemplateValue,
      usageDateStart,
      usageDateEnd,
      selectedRowKey,
      isSending,
    } = this.state;
    const columns = [
      {
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => `${text} (v. ${record.dbrza_version})` || t('WITHOUT_NAME'),
      },
    ];

    const disableCreateBlank = (
      selectedRowKey === ''
      || isSending
      || (usageDateStart && usageDateEnd && usageDateStart >= usageDateEnd)
    );

    return (
      <SidebarLayout
        withoutCard
        noBounds
        header={<h4>{t('CREATE_NEW_BLANK')}</h4>}
        className={styles.content}
        footer={(
          <Button
            block
            type='primary'
            onClick={this.onCreateBlank}
            disabled={disableCreateBlank}
          >
            {t('CREATE_BLANK')}
          </Button>
        )}
      >
        <SidebarCard>
          <SidebarCard.content>
            <SidebarCard.label>{t('NEW_BLANK_NAME')}</SidebarCard.label>
            <Input
              placeholder={t('ENTER_NAME')}
              value={inputNameValue}
              onChange={(e) => this.inputChangeHandler(e, 'inputNameValue')}
            />
          </SidebarCard.content>
        </SidebarCard>
        <SidebarCard>
          <SidebarCard.content>
            <SidebarCard.label>{t('PASSPORT_SETPOINTS_VALIDITY_START')}</SidebarCard.label>
            <DatePicker
              value={usageDateStart}
              onChange={(date, dateString) => this.dateChangeHandler(date, dateString, 'usageDateStart')}
              disabledDate={(current) => this.disabledDate(current, 'usageDateStart')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </SidebarCard.content>
        </SidebarCard>
        <SidebarCard>
          <SidebarCard.content>
            <SidebarCard.label>{t('PASSPORT_SETPOINTS_VALIDITY_END')}</SidebarCard.label>
            <DatePicker
              value={usageDateEnd}
              onChange={(date, dateString) => this.dateChangeHandler(date, dateString, 'usageDateEnd')}
              disabledDate={(current) => this.disabledDate(current, 'usageDateEnd')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            {
              usageDateStart && usageDateEnd && usageDateStart >= usageDateEnd
                ? (<SidebarCard.label className={styles.error}>{t('PASSPORT_SETPOINTS_VALIDITY_ERROR')}</SidebarCard.label>)
                : ''
            }
          </SidebarCard.content>
        </SidebarCard>
        <SidebarCard>
          <SidebarCard.content className={styles.withTable}>
            <Search
              placeholder={t('SEARCH_BY_NAME')}
              value={inputTemplateValue}
              onChange={(e) => this.inputChangeHandler(e, 'inputTemplateValue')}
            />
            <SidebarCard.label>{t('SELECT_FROM_TEMPLATES')}</SidebarCard.label>
          </SidebarCard.content>
          <Table
            size={TABLE_SIZE}
            columns={columns}
            dataSource={templates}
            loading={templatesLoading}
            showHeader={false}
            pagination={{
              onChange: this.changePage,
              total: count,
              pageSize: LIMIT,
              size: 'small',
              showSizeChanger: false,
            }}
            rowClassName={styles.row}
            onRow={(record) => ({
              onClick: () => {
                this.selectRow(record);
              },
            })}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: [selectedRowKey],
              onChange: this.onSelectedRowKeyChange,
            }}
            rowKey='id'
          />
        </SidebarCard>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  templates: state.templates.templates,
  count: state.templates.count,
  templatesLoading: state.templates.isTemplatesLoading,
  getTemplatesError: state.templates.getTemplatesError,
});

const mapDispatchToProps = {
  getTemplatesData: getTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    CreateBlankSidebar
  )
);
