import api from '@services/api';

import {
  GET_NOTIFICATIONS,
  LOAD_MORE_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
} from './types';

export function getNotifications() {
  return async (dispatch) => {
    const { status, data } = await api.getNotifications({ is_hidden: false });

    if (status === 200) {
      dispatch({
        type: GET_NOTIFICATIONS,
        notificationsData: data.results,
        countAll: data.count,
      });
    } else {
      dispatch({
        type: GET_NOTIFICATIONS,
        notificationsData: [],
        countAll: 0,
      });
    }
  };
}

export function loadMoreNotifications(limit, offset) {
  return async (dispatch) => {
    const { status, data } = await api.getNotifications({ limit, offset, is_hidden: false });

    if (status === 200) {
      dispatch({
        type: LOAD_MORE_NOTIFICATIONS,
        notificationsData: data.results,
        countAll: data.count,
      });
    } else {
      dispatch({
        type: LOAD_MORE_NOTIFICATIONS,
        notificationsData: [],
        countAll: 0,
      });
    }
  };
}

export function deleteNotification(id) {
  return async (dispatch) => {
    const { status } = await api.deleteNotification(id);

    if (status === 204) {
      dispatch({
        type: DELETE_NOTIFICATION,
        id: id,
      });
    }
  };
}

export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
}
