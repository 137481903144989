import React, { Component } from 'react';
import { compose } from 'redux';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { textHighlighter } from '@app/helpers';
import api from '@services/api';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';
import CommonTable from '@common/CommonTable';

import s from './styles.module.less';
import { connect } from 'react-redux';

class TemplatesBlanks extends Component {
  tableName = 'templatesBlanks';

  state = {
    isLoading: false,
    blanks: [],
    count: 0,
  };

  componentDidMount = async () => {
    const { setTableName } = this.props;

    setTableName(this.tableName, async () => {
      await this.loadTemplatesBlanks();
    });
  };

  loadTemplatesBlanks = async () => {
    const {
      templateId, page, limit, filterValues
    } = this.props;

    this.setState({ isLoading: true });
    
    const params = {
      name__contains: filterValues['name'],
      protection_device__contains: filterValues['protection_device'],
      limit,
      offset: limit * (page - 1),
    };
    const result = await api.getTemplateBlanks(templateId, params);

    let newBlanks = [];
    let count = 0;
    if (result && result.status === 200 && result.data && result.data.results && result.data.results[0]) {
      newBlanks = result.data.results;
      count = result.data.count;
    }
    this.setState({
      blanks: newBlanks,
      isLoading: false,
      count,
    });
  };

  navigateToPassport = async (id, name) => {
    const { history } = this.props;
    const blank = await api.getBlankById(id);

    if (blank.status === 200 && blank.data) {
      const { status, data } = await api.getPassport(blank.data.passport);

      if (status === 200) {
        history.push(
          `/passport/${data.protection_device.id}/tab/setpoints/?id=${id}&name=${name}`
        );
      }
    }

    return undefined;
  };

  isTheLinkAllowed = (rightName) => {
    const { rights } = this.props;
    if (!rightName) return true;
    if (!rights[rightName]) return false;
    return true;
  }

  handleResetCallback = () => {
    const { onSelectItem } = this.props;

    onSelectItem({});
  };

  render() {
    const {
      t, limit, page, changePage, onShowSizeChange, getColumnSearchFilter, filterValues,
    } = this.props;
    const { isLoading, blanks, count } = this.state;

    return (
      <CommonTable
        tableName={this.tableName}
        size='small'
        className={s.popoverTable}
        columns={[
          {
            title: t('TABLE_COL_BLANKS'),
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchFilter('name', 'name', null, this.handleResetCallback),
            render: (text, record) => {
              const textContent = text ? textHighlighter(filterValues['name'], text) : t('WITHOUT_VALUE');
              return this.isTheLinkAllowed('RIGHT_PASSPORT_SETPOINTS') ? (
                <Button
                  type='link'
                  key={`link_${record && record.id}`}
                  className={`link ${s.button}`}
                  onClick={() => this.navigateToPassport(record && record.id, text)}
                >
                  {textContent}
                </Button>
              ) : (
                textContent
              );
            },
          },
          {
            title: t('TABLE_COL_DEVICE'),
            dataIndex: 'protection_device',
            key: 'protection_device',
            width: '60%',
            ...getColumnSearchFilter('protection_device', 'protection_device', null, this.handleResetCallback),
            render: (text) => (text && text.name) || t('WITHOUT_NAME'),
            //render: text => text ? textHighlighter(filterValues['protection_device'], text) : t('WITHOUT_VALUE'),
          },
        ]}
        dataSource={blanks}
        loading={isLoading}
        pagination={{
          onChange: (newPage) => changePage(newPage, this.loadTemplatesBlanks),
          total: count,
          pageSize: limit,
          current: page,
        }}
        onShowSizeChange={(current, pageSize) =>
          onShowSizeChange(current, pageSize, this.tableName, this.loadTemplatesBlanks)
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});
const mapDispatchToProps = {};


const enhance = compose(withPagination, withColumnFilters, withRowSelection);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(
  enhance(
    withRouter(
      TemplatesBlanks
    )
  )
));
