import { maintenanceAlertActionTypes, MaintenanceAlertState, MAINTENANCE_ALERT_SET_DATA } from './types';

const initialState: MaintenanceAlertState = { content: '', visible: false };

function maintenanceAlertReducer(state = initialState, action: maintenanceAlertActionTypes): MaintenanceAlertState {
    switch (action.type) {
        case MAINTENANCE_ALERT_SET_DATA:
            return {
                ...state,
                content: action.content,
                visible: action.visible
            }
        default:
            return state;
    }
}

export default maintenanceAlertReducer;
