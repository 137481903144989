import React, { Component } from 'react';
import {
  Input, Button, message, Form, Select, DatePicker, Modal,
} from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import api from '@services/api';
import { SidebarLayout, SidebarCard, SelectInfiniteScroll } from '@ui';

import { DATE_FORMAT } from '@globalConstants';
import {
  IMPLEMENTATION_TYPES, PRODUCERS, TRADE_DEVICES, SOFTWARE_VERSION,
} from '../constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

class CommonClassificatorsFormSidebar extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);
    this.onSelectSearch = debounce(this.onSelectSearch, 800);

    this.state = {
      isSending: false,
      isProducersLoading: false,
      optionsProducers: [],
      isImplementationTypesLoading: false,
      optionsImplementationTypes: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { item } = data;

    if (item) {
      this.form.current.setFieldsValue({ name: item.name });

      if (item.short_name) {
        this.form.current.setFieldsValue({ short_name: item.short_name });
      }
      if (item.date) {
        this.form.current.setFieldsValue({ date: moment(item.date) });
      }
    }

    this.loadOptions();
  }

  loadOptions = async (addParams = {}) => {
    const { data } = this.props;
    const { currentClassificator } = data;
    const params = {
      limit: 10,
    };

    if (currentClassificator === TRADE_DEVICES) {
      const mapRequestParams = {
        [PRODUCERS]: 'producer',
        [IMPLEMENTATION_TYPES]: 'implementation_type',
      };

      [PRODUCERS, IMPLEMENTATION_TYPES].map(async (type) => {
        this.setState({ [`is${type}Loading`]: true });
        params.selected = (
          (
            data
            && data.item
            && mapRequestParams
            && mapRequestParams[type]
            && data.item[mapRequestParams[type]]
          )
          || (
            data
            && data[`selected${type}`]
            && data[`selected${type}`].id
          )
          || undefined
        );
        const { status, data: responseData } = await api[`get${type}`]({ ...params, ...addParams });
        this.setState({ [`is${type}Loading`]: false });

        if (status === 200) {
          this.setState({
            [`options${type}`]: responseData.results,
            [`next${type}Url`]: responseData.next,
          });
        }
      });
    }
  };

  loadMoreOptions = async (type) => {
    const { [`options${type}`]: options, [`next${type}Url`]: nextUrl } = this.state;
    const url = nextUrl ? new URL(nextUrl) : {};

    if (url.pathname && url.search) {
      const request = url.pathname + url.search;

      this.setState({ [`is${type}Loading`]: true });
      const { status, data } = await api.urlGetRequest(request);
      this.setState({ [`is${type}Loading`]: false });

      if (status === 200) {
        this.setState({
          [`options${type}`]: [
            ...options,
            ...data.results,
          ],
          [`next${type}Url`]: data.next,
        });
      }
    }
  };

  onSelectSearch = async (value) => {
    this.loadOptions({ name: value });
  };

  onSend = async (merge = false) => {
    const {
      data, t,
    } = this.props;
    const {
      item, method, callback, currentClassificator, selectedTradeDevice,
    } = data;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ isSending: true });
      let methodOverload = method;

      let result = {};
      const extraParams = {};

      if (merge && method === 'updateVersions') {
        methodOverload += 'Merge';
      }

      if (currentClassificator === SOFTWARE_VERSION) {
        extraParams.trade_device = selectedTradeDevice.id;
        values.date && (extraParams.date = values.date.format(DATE_FORMAT));
      }

      if (item) {
        const nulledValues = { ...values };
        Object.keys(nulledValues).forEach((key) => {
          if (nulledValues[key] === undefined) nulledValues[key] = null;
        });
        result = await api[methodOverload](item.id, { ...nulledValues, ...extraParams });
      } else {
        result = await api[methodOverload]({ ...values, ...extraParams });
      }

      this.setState({ isSending: false });

      if (result.status === 200 || result.status === 201) {
        callback && callback(result.data);
        message.success(t('CREATE_SUCCESS'));
      } else {
        if (result.status === 409) {
          if (method === 'updateVersions') {
            this.showMergeConfirm();
          } else message.error(t('VERSION_DUPLICATION_ERROR'))
        }
        else message.error(t('CREATE_ERROR'));
      }

      return
    });
  };

  showMergeConfirm = () => {
    const th = this;
    const { t } = this.props; 
    confirm({
      title: t('VERSION_DUPLICATION_ERROR'),
      icon: <ExclamationCircleOutlined />,
      content: t('VERSION_DUPLICATION_OFFER_CAPTION'),
      onOk: async () => {
        return await th.onSend(true)
      },
      onCancel() {}
    })
  }


  render() {
    const {
      data, t,
    } = this.props;
    const {
      item, isWithShortName, label, currentClassificator,
      selectedProducers, selectedImplementationTypes,
    } = data;

    const {
      isSending, isProducersLoading, optionsProducers, isImplementationTypesLoading, optionsImplementationTypes,
    } = this.state;

    const isDisableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP !== undefined;

    return (
      <SidebarLayout
        withoutCard
        noBounds
        header={(<h4>{item ? `${t('EDIT')} ${label}` : `${t('CREATE')} ${label}`}</h4>)}
        footer={(
          <Button block type='primary' loading={isSending} onClick={() => this.onSend()}>
            {item && t('EDIT')}
            {!item && t('CREATE')}
          </Button>
        )}
      >
        <SidebarCard>
          <SidebarCard.content>
            <SidebarCard.label>{t('NAME')}</SidebarCard.label>
            <Form name='docClassificatorsForm' ref={this.form}>
              <Form.Item name='name' rules={[{ required: true, message: t('ENTER_NAME') }]}>
                <Input
                  placeholder={t('ENTER_NAME')}
                  disabled={currentClassificator === TRADE_DEVICES && isDisableEditAIP}
                />
              </Form.Item>
              {isWithShortName && (
                <>
                  <SidebarCard.label>{t('SHORT_NAME')}</SidebarCard.label>
                  <Form.Item name='short_name'>
                    <Input placeholder={t('ENTER_SHORT_NAME')} />
                  </Form.Item>
                </>
              )}
              {currentClassificator === SOFTWARE_VERSION && (
                <>
                  <SidebarCard.label>{t('SOFTWARE_VERSION_DATE')}</SidebarCard.label>
                  <Form.Item name='date'>
                    <DatePicker placeholder={t('ENTER_SOFTWARE_VERSION_DATE')} style={{ width: '100%' }} format={'DD.MM.YYYY'}/>
                  </Form.Item>
                </>
              )}
              {currentClassificator === TRADE_DEVICES && (
                <>
                  <SidebarCard.label>{t('DEVICE.COL_PRODUCER')}</SidebarCard.label>
                  <Form.Item
                    name='producer'
                    initialValue={item ? item.producer : selectedProducers && selectedProducers.id}
                  >
                    <SelectInfiniteScroll
                      placeholder={t('SELECT_PRODUCER')}
                      loading={isProducersLoading}
                      allowClear
                      showSearch
                      filterOption={false}
                      loadMoreHandle={() => this.loadMoreOptions(PRODUCERS)}
                      onSearch={(value) => this.onSelectSearch(value)}
                      disabled={isDisableEditAIP}
                      popoverPlacement='left'
                    >
                      {optionsProducers.map((opt) => (
                        <Select.Option key={opt.id} value={opt.id}>
                          {opt.name}
                        </Select.Option>
                      ))}
                    </SelectInfiniteScroll>
                  </Form.Item>
                  <SidebarCard.label>{t('IMPLEMENTATION_TYPE')}</SidebarCard.label>
                  <Form.Item
                    name='implementation_type'
                    initialValue={
                      item ? item.implementation_type : selectedImplementationTypes && selectedImplementationTypes.id
                    }
                  >
                    <SelectInfiniteScroll
                      placeholder={t('SELECT_LIST_VALUE')}
                      loading={isImplementationTypesLoading}
                      allowClear
                      showSearch
                      filterOption={false}
                      loadMoreHandle={() => this.loadMoreOptions(IMPLEMENTATION_TYPES)}
                      onSearch={(value) => this.onSelectSearch(value)}
                      popoverPlacement='left'
                    >
                      {optionsImplementationTypes.map((opt) => (
                        <Select.Option key={opt.id} value={opt.id}>
                          {opt.name}
                        </Select.Option>
                      ))}
                    </SelectInfiniteScroll>
                  </Form.Item>
                </>
              )}
            </Form>
          </SidebarCard.content>
        </SidebarCard>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  CommonClassificatorsFormSidebar
);
