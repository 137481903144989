import React, { memo, useMemo } from 'react';
import { CustomTag, PopoverTags } from '@ui';
import styles from '../styles.module.less';

const RolesParameter = ({ roles = [] }) => {
    const content = useMemo(() => {
        if (!roles && !roles.length) return {};

        const result = {};
        roles.forEach((role) => {
            const key = role.name;
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(role);
        });
        return result;
    }, [roles]);


    if (!roles && !roles.length) return '';

    return <div className={styles.paramContainer}>
        {Object.entries(content).map(([name, roles]) => (
            <div key={name} className={styles.paramLabel}>
                <div className={styles.paramName}>
                    {`${name}:`}
                </div>
                <PopoverTags
                    size={roles.length}
                popoverPlacement='top'
                getPopupContainer={(triggerNode) => (
                    triggerNode.closest('.ant-tag')
                        ? triggerNode.closest('.ant-tag').parentNode
                        : triggerNode
                )}
                >
                    {roles.map((role) => (
                        role.dc && (
                            <CustomTag
                                maxChars={25}
                                key={role.dc.id}
                                disablePopover={roles.length > 1}
                                getPopupContainer={(triggerNode) => (
                                triggerNode.closest('.ant-tag')
                                    ? triggerNode.closest('.ant-tag').parentNode
                                    : triggerNode
                            )}
                            >
                                {role.dc.name}
                            </CustomTag>
                        )
                    ))}
                </PopoverTags>
            </div>
        ))}
    </div>


}

export default memo(RolesParameter)