import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Form, Button, Divider, message, Spin,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { MODE_EDIT, RIGHT_PASSPORT_INFO, LIMIT } from '@globalConstants';
import { FormOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import api from '@services/api';
import { addToBucketLocally, removeFromBucketLocally } from '@state/bucket/actions';
import { setIsChangedSidebar } from '@state/sidebar/actions';
import { SidebarLayout } from '@ui';

import { componentConstants, componentFilters, componentFiltersGet } from './constants';
import PassportParameter from './PassportParameter';

const {
  TEXTAREA, DATEPICKER, TAGS_ROLES, SELECT_SEARCH,
} = componentConstants;
const {
  SUBSTATIONS, REGIONS, PRODUCERS, TRADE_DEVICES, OWNERS, VOLTAGE_LEVELS,
  DEVICES, IMPLEMENTATION_TYPES, SOFTWARE_VERSIONS,
} = componentFilters;
const dateFormatServer = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

class PassportDetails extends Component {
  static valuesToCompare(values, keys) {
    const nextValues = {};
    keys.forEach((key) => {
      nextValues[key] = (
        (!values[key] || (Array.isArray(values[key]) && !values[key].length))
          ? undefined
          : cloneDeep(values[key])
      );
    });

    nextValues.usage_start_date = nextValues.usage_start_date
      && moment(nextValues.usage_start_date).utc().format(dateFormatServer);
    nextValues.dismantlement_date = nextValues.dismantlement_date
      && moment(nextValues.dismantlement_date).utc().format(dateFormatServer);
    nextValues.software_creation_date = nextValues.software_creation_date
      && moment(nextValues.software_creation_date).utc().format(dateFormatServer);
    nextValues.short_name = (!nextValues.short_name || nextValues.short_name === '')
      ? undefined
      : nextValues.short_name;

    nextValues.base_equipment = (
      values.base_equipment
        ? nextValues.base_equipment
        : (
          !values.equipment_base
          || (Array.isArray(values.equipment_base) && values.equipment_base.length === 0)
            ? undefined
            : (
              values.equipment_base
              && values.equipment_base[0]
              && values.equipment_base[0].id
                ? cloneDeep(values.equipment_base.map((x) => x.id))
                : cloneDeep(values.equipment_base)
            )
        )
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const name in nextValues) {
      // eslint-disable-next-line no-prototype-builtins
      if (nextValues.hasOwnProperty(name)) {
        if (nextValues[name] && nextValues[name].id) {
          nextValues[name] = nextValues[name].id;
        } else if ([
          'short_name',
          'usage_start_date',
          'dismantlement_date',
          'device_version',
          'implementation_type',
        ].indexOf(name) !== -1 && nextValues[name] === undefined) {
          nextValues[name] = null;
        }
      }
    }

    delete nextValues.roles;
    delete nextValues.device_tracking_group;

    return nextValues;
  }

  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      initialValues: {},
      list: {},
      next: {},
      isLoading: false,
      isLoadingList: {},
      isFormChanged: false,
      is_archived: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;

    this.setStateValues(data);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const { isEdit } = this.state;

    if (!isEqual(prevProps.data, data)) {
      this.setStateValues(data);
    }
    if (!isEqual(prevState.isEdit, isEdit) && isEdit) {
      this.loadData();
      this.setStateValues(data);
    }
  }

  setStateValues = (data) => {
    //показывает предупреждение deprecation warning, избежать не удалось
    const software_creation_date_moment = data.device_version?.date && moment(data.device_version.date).format('DD.MM.YYYY');
    
    this.setState({
      initialValues: {
        name: data.name,
        cim_id: data.cim_id,
        short_name: data.short_name || '',
        owner: data.owner || undefined,
        voltage_level: data.voltage_level || undefined,
        device_tracking_group: data.device_tracking_group || undefined,
        usage_start_date: data.usage_start_date || undefined,
        dismantlement_date: data.dismantlement_date || undefined,
        substation: data.substation || undefined,
        region: data.region || undefined,
        trade_device: data.trade_device || undefined,
        producer: data.producer || undefined,
        device_version: data.device_version || undefined,
        software_creation_date: (data.device_version && software_creation_date_moment) || undefined,
        roles: data.roles || undefined,
        equipment_base: data.equipment_base || undefined,
        implementation_type: data.implementation_type || undefined,
      },
      is_archived: data.is_archived,
    });
  };

  loadData = async () => {
    const { data: propsData } = this.props;
    const { isEdit } = this.state;

    this.setState({ isLoading: true });

    const apiRequest = [];
    const apiRequestName = [];
    apiRequestName.push(SUBSTATIONS);
    apiRequest.push(
      api.getSubstations({
        selected: propsData.substation && propsData.substation.id,
        limit: isEdit ? LIMIT : 1,
      })
    );
    apiRequestName.push(REGIONS);
    apiRequest.push(
      api.getRegions({
        selected: propsData.region && propsData.region.id,
        limit: isEdit ? LIMIT : 1,
      })
    );
    apiRequestName.push(PRODUCERS);
    apiRequest.push(
      api.getProducers({
        selected: propsData.producer && propsData.producer.id,
        limit: isEdit ? LIMIT : 1,
      })
    );
    apiRequestName.push(TRADE_DEVICES);
    apiRequest.push(
      api.getTradeDevices({
        selected: propsData.trade_device && propsData.trade_device.id,
        implementation_type: propsData.implementation_type && propsData.implementation_type.id,
        producer: propsData.producer && propsData.producer.id,
        limit: LIMIT,
      })
    );
    apiRequestName.push(OWNERS);
    apiRequest.push(
      api.getOwners({
        selected: propsData.owner && propsData.owner.id,
        limit: isEdit ? LIMIT : 1,
      })
    );
    apiRequestName.push(VOLTAGE_LEVELS);
    apiRequest.push(
      api.getVoltageLevels({
        selected: propsData.voltage_level && propsData.voltage_level.id,
        limit: isEdit ? LIMIT : 1,
      })
    );
    apiRequestName.push(DEVICES);
    apiRequest.push(
      api.getEquipmentBase(
        {
          selected: propsData.equipment_base && propsData.equipment_base.id
            ? propsData.equipment_base.id
            : (
              propsData.equipment_base
              && Array.isArray(propsData.equipment_base)
              && propsData.equipment_base.length
                ? propsData.equipment_base.map((x) => x.id)
                : propsData.equipment_base
            ),
          substation: propsData.substation && propsData.substation.id,
          limit: LIMIT,
        },
        api.getOptsForArray()
      )
    );
    apiRequestName.push(IMPLEMENTATION_TYPES);
    apiRequest.push(
      api.getImplementationTypes({
        selected: propsData.implementation_type && propsData.implementation_type.id,
        limit: isEdit ? LIMIT : 1,
      })
    );
    apiRequestName.push(SOFTWARE_VERSIONS);
    apiRequest.push(
      api.getVersions({
        selected: propsData.device_version && propsData.device_version.id,
        trade_device: propsData.trade_device && propsData.trade_device.id,
        limit: LIMIT,
        custom_name: true
      })
    );

    const result = await Promise.all(apiRequest);

    const list = {};
    const next = {};
    const isLoadingList = {};
    result.forEach((x, i) => {
      if (x.status === 200) {
        list[apiRequestName[i]] = x.data.results;
        next[apiRequestName[i]] = x.data.next;
        isLoadingList[apiRequestName[i]] = false;

      }
    });

    this.setState({
      list,
      next,
      isLoadingList,
      isLoading: false
    });
    return;
  };

  isEditEnable = () => {
    this.form.current.resetFields();
    this.setState({
      isEdit: true,
    });
  };

  disabledDate = (current, name) => {
    if (name === 'dismantlement_date' && this.form.current.getFieldValue('usage_start_date')) {
      return current && current < moment(this.form.current.getFieldValue('usage_start_date')).endOf('day');
    }

    if (name === 'usage_start_date' && this.form.current.getFieldValue('dismantlement_date')) {
      return current && current > moment(this.form.current.getFieldValue('dismantlement_date')).endOf('day');
    }

    return null;
  };

  changeListValue = async (type, params) => {
    const { data: propsData } = this.props;

    this.setState(
      (state) => {
        return {
          isLoadingList: {
            ...state.isLoadingList,
            [type]: true,
          },
        };
      }
    );

    const { status, data } = await componentFiltersGet[type](params, propsData, this);

    this.setState(
      (state) => {
        return {
          isLoadingList: {
            ...state.isLoadingList,
            [type]: false,
          },
        };
      }
    );

    if (status === 200) {
      this.setState(
        (state) => {
          return {
            list: {
              ...state.list,
              [type]: data.results,
            },
            next: {
              ...state.next,
              [type]: data.next,
            },
          };
        }
      );
    }
  };

  onSearch = async (type, value) => {
    await this.changeListValue(type, {
      limit: LIMIT,
      name: value || undefined,
    });
  };

  onLoadMore = async (type, sortByDate = false) => {
    const { next } = this.state;

    const url = next[type] ? new URL(next[type]) : {};

    if (url.pathname && url.search) {
      const request = url.pathname + url.search;

      this.setState(
        (state) => {
          return {
            isLoadingList: {
              ...state.isLoadingList,
              [type]: true,
            },
          };
        }
      );

      const { status, data } = await api.urlGetRequest(request);

      this.setState(
        (state) => {
          return {
            isLoadingList: {
              ...state.isLoadingList,
              [type]: false,
            },
          };
        }
      );

      if (status === 200) {
        if (sortByDate) data.results.sort((a,b) => moment(a.date)-moment(b.date));

        this.setState(
          (state) => {
            return {
              list: {
                ...state.list,
                [type]: [
                  ...state.list[type],
                  ...data.results,
                ],
              },
              next: {
                ...state.next,
                [type]: data.next,
              },
            };
          }
        );
      }
    }
  };

  changeFormValues = () => {
    const { initialValues } = this.state;
    const { setIsChangedPassportSidebar } = this.props;

    let isFormChanged = false;

    this.form.current.validateFields().then((values) => {
      const keys = Object.keys(values);
      const currentValues = PassportDetails.valuesToCompare(values, keys);
      const initialValuesToCompare = PassportDetails.valuesToCompare(initialValues, keys);
      isFormChanged = !isEqual(currentValues, initialValuesToCompare);
      this.setState({ isFormChanged });
      setIsChangedPassportSidebar && setIsChangedPassportSidebar(isFormChanged);
    });
  };

  handleCancel = () => {
    const { setIsChangedPassportSidebar } = this.props;

    this.form.current.resetFields();

    this.setState((prevState) => ({
      isEdit: !prevState.isEdit,
      isFormChanged: false,
      isLoading: false
    }));

    setIsChangedPassportSidebar && setIsChangedPassportSidebar(false);
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { data } = this.props;

    this.form.current.validateFields()
      .then(async (values) => {
        const passport = PassportDetails.valuesToCompare(values, Object.keys(values));

        this.setState({ isLoading: true });

        await api.patchProtectionDevice(data.id, passport);
        await data.callback();

        this.setState({ isEdit: false, isLoading: false });
      })
      .catch((error) => {
        error && error[0] && error[0].name
          ? this.form.current.scrollToField(error[0].name)
          : console.error({ _f: 'Error before save passport data', error });
      });
  };

  onAdd2Bucket = async (id, data) => {
    const { t, bucketIdsData } = this.props;

    if (id && data) {
      if (bucketIdsData.includes(id)) {
        const { status } = await api.clearBucket(id);

        if (status === 200 || status === 204) {
          removeFromBucketLocally(id);
          message.success(t('REMOVE_FROM_BUCKET_MESSAGE'));
        } else {
          message.error(t('REMOVE_FROM_BUCKET_FAIL_MESSAGE'));
        }

        return;
      }

      const { status } = await api.addProtectionDevice2Bucket(id);

      if (status === 200) {
        addToBucketLocally(id);
        message.success(t('ADD2BUCKET_MESSAGE'));
      } else {
        message.error(t('ADD2BUCKET_FAIL_MESSAGE'));
      }
    }
  };

  getInitialValue = (content) => {
    return (content && (typeof content === 'object' && content.id)) ? content.id : content;
  };

  resetDependableFields = () => {
    const { initialValues } = this.state;

    this.form.current.setFieldsValue({
      trade_device: null,
      device_version: null,
    });
    this.setState({
      initialValues: {
        ...initialValues,
        software_creation_date: null,
      },
    });
  };

  formChangeHandle = async (value) => {
    this.form.current.setFieldsValue(value);
    this.changeFormValues();

    switch (Object.keys(value)[0]) {
      case 'producer': {
        this.resetDependableFields();
        await this.changeListValue(
          TRADE_DEVICES,
          {
            implementation_type: this.form.current.getFieldValue('implementation_type'),
            producer: value.producer,
          }
        );
        break;
      }
      case 'implementation_type': {
        this.resetDependableFields();
        await this.changeListValue(
          TRADE_DEVICES,
          {
            implementation_type: value.implementation_type,
            producer: this.form.current.getFieldValue('producer'),
          }
        );
        break;
      }
      case 'trade_device': {
        await this.changeListValue(
          SOFTWARE_VERSIONS,
          { trade_device: value.trade_device }
        );
        break;
      }
      case 'device_version': {
        const { list, initialValues } = this.state;
        const deviceVersionObj = list[SOFTWARE_VERSIONS].find((item) => item.id === value.device_version);

        this.setState({
          initialValues: {
            ...initialValues,
            software_creation_date: deviceVersionObj && moment(deviceVersionObj.date).format('DD.MM.YYYY'),
          },
        });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const {
      t, rights, data, bucketIdsData,
    } = this.props;
    const {
      isEdit,
      list,
      isLoadingList,
      isLoading,
      initialValues,
      isFormChanged,
      is_archived,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_INFO] === MODE_EDIT && !is_archived;
    const isEnableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP === undefined;

    const recordInBucket = bucketIdsData.includes(data.id);
    const footer = data.treeFooter ? (
      <>
        <Link to={`/passport/${data.id}`} target={'_blank'} rel='noreferrer noopener'
          style={{ width: '100%', textAlign: 'center' }}>
          <Button
            disabled={!data.passports || data.passports.length === 0}
            className='bordered'
            style={{ width: '100%', textAlign: 'center' }}
          >
            {`${t('PASSPORT_SHORT')}`}
          </Button>
        </Link>

        <Button
          type={recordInBucket ? 'primary' : 'default'}
          onClick={() => this.onAdd2Bucket(data.id, data)}
          icon={recordInBucket ? <StarFilled /> : <StarOutlined />}
          style={{ width: '100%' }}
        >
          {recordInBucket ? t('REMOVE_DEVICE') : t('ADD_DEVICE')}
        </Button>
      </>
    ) : isEdit ? (
      <>
        <Button block onClick={this.handleCancel} className='bordered'>
          {t('CANCEL')}
        </Button>
        <Button disabled={!isFormChanged} block type='primary' onClick={this.onSubmit}>
          {t('SAVE')}
        </Button>
      </>
    ) : (
      <Button
        block
        onClick={this.isEditEnable}
        disabled={!isEditRight}
        title={is_archived ? t('ARCHIVED_PASSPORT_NOT_EDITABLE') : undefined}
      >
        <FormOutlined />
        {t('EDIT')}
      </Button>
    );

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t('INFO')}</h4>}
        footer={footer}
      >
        <Spin spinning={isLoading}>
          <Form
            ref={this.form}
            name='passportDetails'
            onChange={this.changeFormValues}
            onSubmit={this.onSubmit}
            initialValues={{
              ...Object.fromEntries(
                Object.keys(initialValues).map((key) => [key, this.getInitialValue(initialValues[key])])
              ),
              usage_start_date: this.getInitialValue(initialValues.usage_start_date
                ? moment(initialValues.usage_start_date)
                : null),
              dismantlement_date: this.getInitialValue(initialValues.dismantlement_date
                ? moment(initialValues.dismantlement_date)
                : null),
              software_creation_date: this.getInitialValue(initialValues.software_creation_date
                ? moment(initialValues.software_creation_date)
                : null),
              base_equipment: this.getInitialValue(
                initialValues.equipment_base && initialValues.equipment_base.map(({ id }) => id)
              ),
            }}
          >
            <PassportParameter
              id='name'
              label={t('DEVICE_NAME')}
              content={initialValues.name}
            />
            <PassportParameter
              id='cim_id'
              label={t('DEVICE_UID')}
              content={initialValues.cim_id?.toUpperCase()}
              t={t}
              isCopyable
            />
            <PassportParameter
              id='short_name'
              label={t('DEVICE_SHORT_NAME')}
              content={initialValues.short_name}
              isEdit={isEdit}
              type={TEXTAREA}
              form={this.form.current}
            />
            <PassportParameter
              id='device_tracking_group'
              label={t('ACCOUNTING_GROUP')}
              content={initialValues.device_tracking_group}
            />
            <Divider />
            <PassportParameter
              id='usage_start_date'
              label={t('DEVICE.COL_USAGE_START_DATE')}
              content={initialValues.usage_start_date ? moment(initialValues.usage_start_date) : null}
              isEdit={isEdit}
              form={this.form.current}
              type={DATEPICKER}
              disabledDate={(current) => this.disabledDate(current, 'usage_start_date')}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='dismantlement_date'
              label={t('DEVICE.COL_USAGE_END_DATE')}
              content={
                initialValues.dismantlement_date
                  ? moment(initialValues.dismantlement_date)
                  : null
              }
              isEdit={isEdit}
              form={this.form.current}
              type={DATEPICKER}
              disabledDate={(current) => this.disabledDate(current, 'dismantlement_date')}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='base_equipment'
              label={t('DEVICE_LABEL_EQUIPMENT_BASE')}
              content={initialValues.equipment_base}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[DEVICES]}
              onSearch={(value) => this.onSearch(DEVICES, value)}
              onLoadMore={() => this.onLoadMore(DEVICES)}
              loading={isLoadingList[DEVICES]}
              multiple
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='region'
              label={t('DEVICE_LABEL_TERRITORY')}
              content={initialValues.region}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[REGIONS]}
              onSearch={(value) => this.onSearch(REGIONS, value)}
              onLoadMore={() => this.onLoadMore(REGIONS)}
              loading={isLoadingList[REGIONS]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              disabled
              id='substation'
              label={t('DEVICE_LABEL_SUBSTATION')}
              content={initialValues.substation}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[SUBSTATIONS]}
              onSearch={(value) => this.onSearch(SUBSTATIONS, value)}
              onLoadMore={() => this.onLoadMore(SUBSTATIONS)}
              loading={isLoadingList[SUBSTATIONS]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <Divider />
            <PassportParameter
              id='producer'
              label={t('DEVICE.COL_PRODUCER')}
              content={initialValues.producer}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[PRODUCERS]}
              onSearch={(value) => this.onSearch(PRODUCERS, value)}
              onLoadMore={() => this.onLoadMore(PRODUCERS)}
              loading={isLoadingList[PRODUCERS]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='implementation_type'
              label={t('IMPLEMENTATION_TYPE')}
              content={initialValues.implementation_type}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[IMPLEMENTATION_TYPES]}
              onSearch={(value) => this.onSearch(IMPLEMENTATION_TYPES, value)}
              onLoadMore={() => this.onLoadMore(IMPLEMENTATION_TYPES)}
              loading={isLoadingList[IMPLEMENTATION_TYPES]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
              allowClear={true}
            />
            <PassportParameter
              id='trade_device'
              label={t('DEVICE.COL_TRADE_NAME')}
              content={initialValues.trade_device}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[TRADE_DEVICES]}
              onSearch={(value) => this.onSearch(TRADE_DEVICES, value)}
              onLoadMore={() => this.onLoadMore(TRADE_DEVICES)}
              loading={isLoadingList[TRADE_DEVICES]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='owner'
              label={t('DEVICE.COL_OWNER')}
              content={initialValues.owner}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[OWNERS]}
              onSearch={(value) => this.onSearch(OWNERS, value)}
              onLoadMore={() => this.onLoadMore(OWNERS)}
              loading={isLoadingList[OWNERS]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='voltage_level'
              label={t('DEVICE.COL_VOLTAGE_LEVEL')}
              content={initialValues.voltage_level}
              isEdit={isEdit && isEnableEditAIP}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[VOLTAGE_LEVELS]}
              onSearch={(value) => this.onSearch(VOLTAGE_LEVELS, value)}
              onLoadMore={() => this.onLoadMore(VOLTAGE_LEVELS)}
              loading={isLoadingList[VOLTAGE_LEVELS]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
            />
            <PassportParameter
              id='device_version'
              label={t('DEVICE.COL_SOFTWARE_VERSION')}
              disabled={this.form.current && !this.form.current.getFieldValue('trade_device')}
              content={initialValues.device_version}
              isEdit={isEdit}
              form={this.form.current}
              type={SELECT_SEARCH}
              selectOptions={list[SOFTWARE_VERSIONS]}
              onSearch={(value) => this.onSearch(SOFTWARE_VERSIONS, value)}
              onLoadMore={() => this.onLoadMore(SOFTWARE_VERSIONS, true)}
              loading={isLoadingList[SOFTWARE_VERSIONS]}
              formChangeHandle={(value) => this.formChangeHandle(value)}
              allowClear={true}
            />
            <PassportParameter
              id='software_creation_date'
              label={t('SOFTWARE_CREATION_DATE')}
              content={initialValues.software_creation_date}
            />
            <Divider />
            <PassportParameter
              id='roles'
              content={initialValues.roles}
              type={TAGS_ROLES}
            />
          </Form>
        </Spin>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  bucketIdsData: state.bucket.bucketIdsData,
});

const mapDispatchToProps = {
  setIsChangedPassportSidebar: setIsChangedSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    PassportDetails
  )
);
