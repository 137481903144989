import React, { Component } from 'react';
import api from '@services/api';
import {
  message, Button, Popconfirm, Divider, TreeSelect,
} from 'antd';
import Icon, { FileAddOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';

class ActionsComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      functions: [],
      selectedFunction: undefined,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ isLoading: true });
    const { status, data } = await api.getFunctionsTree(true, { ordering: 'name' });
    this.setState({ isLoading: false });

    if (status === 200 && data && data.results.length) {
      this.setState({ functions: data.results });
    }
  };

  selectFunction = (selectedFunction) => {
    this.setState({ selectedFunction });
  };

  addFunction = async () => {
    const {
      deviceId,
      refreshData,
      currentDC,
      t,
    } = this.props;
    const { selectedFunction, functions } = this.state;
    const defaultDcValue = currentDC.id;

    let found;

    const findFunc = (aFunc) => {
      aFunc.forEach((item) => {
        if (item.id === selectedFunction) {
          found = item;

          return;
        }

        if (item.children && item.children.length) {
          findFunc(item.children);
        }
      });
    };

    findFunc(functions);

    this.setState({ isSending: true });

    const dataToSend = found && found.is_accounting_group ? {
      function: selectedFunction,
      protection_device: deviceId,
    } : {
      function: selectedFunction,
      protection_device: deviceId,
      execute_calculations: [{ execute_calculations: defaultDcValue }],
      reconcile_calculations: [{ reconcile_calculations: defaultDcValue }],
      compose_task: [{ compose_task: defaultDcValue }],
      reconcile_task: [{ reconcile_task: defaultDcValue }],
      issue_task: [{ issue_task: defaultDcValue }],
    };
    const { status } = await api.addPassportFunction(dataToSend);
    this.setState({ isSending: false });

    if (status === 201) {
      refreshData();
      this.setState({ selectedFunction: undefined });
      message.success(t('CREATE_SUCCESS'));
    } else {
      message.error(t('CREATE_ERROR'));
    }
  };

  deleteFunction = async () => {
    const {
      t, refreshData, passportId, currentItem, resetSelection,
    } = this.props;

    if (!currentItem) {
      return;
    }

    this.setState({ isDeleting: true });
    const { status, data } = await api.deletePassportFunctionsV3(passportId, currentItem);
    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));
      resetSelection();
      refreshData();
    } else {
      message.error(data.error);
    }
  };

  renderFunctionsSelect = (items) => {
    if (!items || !items.length) {
      return null;
    }

    return items.map((item) => (
      <TreeSelect.TreeNode key={item.id} value={item.id} title={item.name}>
        {this.renderFunctionsSelect(item.children)}
      </TreeSelect.TreeNode>
    ));
  };

  render() {
    const {
      t, currentItem, openFunctionSidebar, isUpdating, existsFunctions, isEditRight, editableFunctions,
    } = this.props;
    const {
      isLoading, functions, isSending, selectedFunction, isDeleting,
    } = this.state;

    const filteredFunctions = functions.filter(({ id }) => !existsFunctions.includes(id));

    return (
      <>
        {editableFunctions && (
          <>
            <TreeSelect
              size='small'
              treeDefaultExpandAll
              value={selectedFunction}
              style={{ width: 300 }}
              suffixIcon={isLoading ? <LoadingOutlined /> : null}
              placeholder={filteredFunctions.length === 0 ? t('SELECT_NO_FUNCTIONS') : t('SELECT_FUNCTION')}
              disabled={!isEditRight || isLoading || isSending || (filteredFunctions.length === 0)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={this.selectFunction}
            >
              {this.renderFunctionsSelect(filteredFunctions)}
            </TreeSelect>
            &nbsp;
            <Button
              disabled={!isEditRight || isLoading || isSending || !selectedFunction || isUpdating}
              loading={isSending}
              onClick={this.addFunction}
              size='small'
              icon={<FileAddOutlined />}
            >
              {t('PASSPORT_FUNCTIONS_ADD_FUNCTION_BTN')}
            </Button>
            &nbsp;
            <Divider type='vertical' />
            &nbsp;
          </>
        )}
        <Button
          disabled={!isEditRight || isLoading || isUpdating || !currentItem}
          loading={isUpdating}
          onClick={openFunctionSidebar}
          size='small'
          icon={<EditOutlined />}
        >
          {t('EDIT')}
        </Button>
        {editableFunctions && (
          <>
            &nbsp;
            <Popconfirm
              title={t('DELETE_CONFIRM')}
              onConfirm={isEditRight && this.deleteFunction}
              okText={t('YES')}
              cancelText={t('NO')}
              disabled={!isEditRight || isLoading || isDeleting || !currentItem || isUpdating}
            >
              <Button
                disabled={!isEditRight || isLoading || isDeleting || !currentItem || isUpdating}
                loading={isDeleting}
                size='small'
              >
                <Icon component={BucketIcon} style={{ color: currentItem && 'red' }} />
                {t('DELETE')}
              </Button>
            </Popconfirm>
          </>
        )}
      </>
    );
  }
}

export default ActionsComp;
