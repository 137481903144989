import React, { Component } from 'react';
import {
  Input, Button, message, Form,
} from 'antd';
import { withTranslation } from 'react-i18next';

import api from '@services/api';
import { SidebarLayout, SidebarCard } from '@ui';

class EquipmentTypesFormSidebar extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { item } = data;

    if (item) {
      this.form.current.setFieldsValue({
        name: item.name,
        name_en: item.name_en,
      });
    }
  }

  onSend = async () => {
    const { data, t } = this.props;
    const { item, method, callback } = data;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ isSending: true });

      let result;

      const newValues = { ...values };
      Object.keys(newValues).forEach((key) => {
        if (newValues[key] === undefined) newValues[key] = null;
      });
      if (item) {
        result = await api[method](item.id, newValues);
      } else {
        result = await api[method](newValues);
      }

      this.setState({ isSending: false });

      if (result.status === 200 || result.status === 201) {
        callback && callback(result.data);
        message.success(t('CREATE_SUCCESS'));
      } else {
        message.error(t('CREATE_ERROR'));
      }
    });
  };

  render() {
    const { data, t } = this.props;
    const { item, label } = data;
    const { isSending } = this.state;

    const isDisableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP !== undefined;

    return (
      <SidebarLayout
        withoutCard
        noBounds
        header={(<h4>{item ? `${t('EDIT')} ${label}` : `${t('CREATE')} ${label}`}</h4>)}
        footer={(
          <Button block type='primary' loading={isSending} onClick={this.onSend}>
            {item && t('EDIT')}
            {!item && t('CREATE')}
          </Button>
        )}
      >
        <SidebarCard>
          <SidebarCard.content>
            <Form name='EquipmentTypesForm' ref={this.form}>
              <Form.Item
                name='name'
                label={t('CLASSIFICATORS_EQUIPMENT_TYPES_NAME')}
                rules={[{
                  required: true,
                  message: t('CLASSIFICATORS_EQUIPMENT_TYPES_ENTER_NAME'),
                }]}
              >
                <Input
                  placeholder={t('CLASSIFICATORS_EQUIPMENT_TYPES_NAME')}
                  disabled={isDisableEditAIP}
                />
              </Form.Item>
              <Form.Item
                name='name_en'
                label={t('CLASSIFICATORS_EQUIPMENT_TYPES_EQUIPMENT')}
                rules={[{
                  required: true,
                  message: t('CLASSIFICATORS_EQUIPMENT_TYPES_ENTER_EQUIPMENT'),
                }]}
              >
                <Input
                  placeholder={t('CLASSIFICATORS_EQUIPMENT_TYPES_EQUIPMENT')}
                  disabled={isDisableEditAIP}
                />
              </Form.Item>
            </Form>
          </SidebarCard.content>
        </SidebarCard>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  EquipmentTypesFormSidebar
);
