import * as React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import * as Sentry from '@sentry/react'; // Отключена до лучших времен
import '@ant-design/compatible/assets/index.css';

import 'moment/locale/ru';
import ruRU from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import './locale';

import browserHistory from '@app/browserHistory';
import { LANG_RU, LANG_EN } from '@globalConstants';
import { PassportContextProvider } from '@routes/passport/PassportContext';
import store from '@state/store';

import App from './App';

import './index.less';

if (ruRU && ruRU.DatePicker) {
  ruRU.DatePicker.lang.rangePlaceholder = ['Начало', 'Конец'];
}

const getLocale = (lang: string): (typeof ruRU | typeof enUS) => {
  switch (lang) {
    case LANG_RU:
      return ruRU;
    case LANG_EN:
      return enUS;
    default:
      return ruRU;
  }
};

const Index = () => {
  const { i18n } = useTranslation();
  const currentLocale = getLocale(i18n.language);
  moment.locale(i18n.language);

  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <ConfigProvider locale={currentLocale}>
          <PassportContextProvider>
            <App />
          </PassportContextProvider>
        </ConfigProvider>
      </Router>
    </Provider>
  );
};

if (process.env.NODE_ENV === 'production') {
  // TODO вынести dsn в конфиг
  // Отключена до лучших времен
  // Sentry.init({ dsn: 'https://996020f27d404d8f82292993118cac5b@sentry.rnd.rtsoft.ru/4' });
}

const root = document.getElementById('root');

ReactDOM.render(<Index />, root);
