import React, { PureComponent } from 'react';
import { TableProps } from 'antd/lib/table/Table';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';

import { Column } from '@common/CommonTable/types';
import CommonTable from '@common/CommonTable';

import styles from './styles.module.less';

interface Props extends TableProps<object> {
  columns: Column[];
  tableName: string|null;
  width?: number;
  handleActivateResetTableBtn?: (val: boolean) => void;
  onShowSizeChange?: (current: number, size: number) => void;
  onChange?: (pagination: TablePaginationConfig, filters: Record<string, Key[] | null>) => void;
}

class FixedYCommonTable extends PureComponent<Props> {
  render() {
    const { className, ...restProps } = this.props;
    return (
      <CommonTable
        className={`${className || ''} ${styles.tableFixedY}`}
        scrollX={true}
        scroll={{ x: 1300, y: 480 }}
        {...restProps}
      />
    );
  }
}

export default FixedYCommonTable;
