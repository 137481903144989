import { BaseType } from 'antd/lib/typography/Base';
import Text from 'antd/lib/typography/Text';
import React, { FC, memo } from 'react';
import './styles.css';

export interface DateTextProps {
  tip?: string;
  children?: string;
  type?: BaseType;
}

export const DateText: FC<DateTextProps> = memo(({ tip, children, type }) => {
  return (
    <Text type={type} className='date-text' title={tip}>
      {children}
    </Text>
  );
});
