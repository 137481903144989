import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import { Layout } from '@ui';

import Templates from './Templates';

class TemplatesRoute extends PureComponent {
  render() {
    const { t } = this.props;

    return (
      <Layout title={t('TEMPLATES_TITLE')}>
        <Templates />
      </Layout>
    );
  }
}

export default withTranslation()(
  TemplatesRoute
);
