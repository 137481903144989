import {
  UPDATE_BUCKET,
  UPDATE_BUCKET_SHORT_DATA,
  UPDATE_BUCKET_IDS_DATA,
  CLEAR_BUCKET,
  GET_MORE_BUCKET,
} from './types';

const initialState = {
  bucketData: [],
  bucketShortData: [],
  bucketIdsData: [],
  count: 0,
};

function bucketReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BUCKET: {
      const dataLen = (action && action.bucketData && action.bucketData.length) || 0;
      const data = (dataLen && action.bucketData) || [];
      const dataShort = dataLen && data
        ? data.map(
          (x) => {
            return {
              id: x.id,
              name: x.name,
            };
          }
        )
        : [];
      const dataIds = dataLen && data
        ? data.map((x) => x.id)
        : [];

      return {
        ...state,
        bucketData: data,
        bucketShortData: dataShort,
        bucketIdsData: dataIds,
        count: action.count,
      };
    }
    case UPDATE_BUCKET_SHORT_DATA: {
      const dataLen = (action && action.bucketShortData && action.bucketShortData.length) || 0;
      const dataShort = (dataLen && action.bucketShortData) || [];
      const dataIds = dataLen && dataShort
        ? dataShort.map((x) => x.id)
        : [];

      return {
        ...state,
        bucketShortData: dataShort,
        bucketIdsData: dataIds,
        count: action.count,
      };
    }
    case UPDATE_BUCKET_IDS_DATA: {
      const dataLen = (action && action.bucketIdsData && action.bucketIdsData.length) || 0;
      const dataIds = (dataLen && action.bucketIdsData) || [];

      return {
        ...state,
        bucketIdsData: dataIds,
        count: dataLen,
      };
    }
    case CLEAR_BUCKET:
      return {
        ...state,
        bucketData: [],
        bucketShortData: [],
        bucketIdsData: [],
        count: 0,
      };

    case GET_MORE_BUCKET:
      return {
        ...state,
        bucketData: [...state.bucketData, ...action.bucketData],
        count: action.count,
      };
    default:
      return state;
  }
}

export default bucketReducer;
