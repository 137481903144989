import React from 'react';
import { Typography, Tooltip } from 'antd';
import {
  ClockCircleTwoTone,
  RightCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';

import styles from '../styles.module.less';

import {
  DATE_ITEM_WIDTH,
  SPOT_SIZE,
  PLANNED_STATUS,
  ACTUAL_STATUS,
  MIXED_STATUS,
  START_OFFSET,
  IconType,
} from './constants';

type Props = {
  spotNumber: number;
  left: number;
  date: string;
  isStart?: boolean;
  type: string|IconType;
  tooltipDate: string|JSX.Element;
  offsetX?: number;
  className?: string;
  isEqStartEnd?: boolean;
}

const CHAR_WIDTH = 5;
const CHAR_HEIGHT = 10;

const Spot = ({
  spotNumber = 0,
  left = 0,
  date = '',
  isStart = false,
  type = '',
  tooltipDate = '-',
  offsetX = 0,
  className = '',
  isEqStartEnd = false,
}: Props) => {
  let SpotTypeComponent;

  if (typeof type === 'string') {
    switch (type) {
      case PLANNED_STATUS:
        SpotTypeComponent = ClockCircleTwoTone;
        break;
      case MIXED_STATUS:
        SpotTypeComponent = isStart ? RightCircleTwoTone : ClockCircleTwoTone;
        break;
      case ACTUAL_STATUS:
        SpotTypeComponent = isStart ? RightCircleTwoTone : CheckCircleTwoTone;
        break;
      default:
        SpotTypeComponent = ClockCircleTwoTone;
    }
  } else {
    SpotTypeComponent = type as IconType || CheckCircleTwoTone;
  }

  const actualSpotNumber = (spotNumber ? (spotNumber - 1) : 1);

  const spotStyle = {
    left: -SPOT_SIZE + (DATE_ITEM_WIDTH / 2) + offsetX + (
      isEqStartEnd
        ? (
          isStart
            ? -SPOT_SIZE / 2
            : SPOT_SIZE / 2
        )
        : 0
    ),
    top: (isEqStartEnd ? 0 : actualSpotNumber) * -SPOT_SIZE + 2 + (
      date
        ? (isEqStartEnd ? 0 : actualSpotNumber) * -CHAR_HEIGHT
        : 0
    ),
  };

  const textSize = +(date && date.length) * CHAR_WIDTH - SPOT_SIZE + 2 - (isEqStartEnd ? SPOT_SIZE : 0);
  return (
    <span
      className={`${styles.info} ${className}`}
      style={spotStyle}
    >
      <Tooltip
        title={tooltipDate}
      >
        <SpotTypeComponent
          className={styles.spot}
          style={{
            left: left * DATE_ITEM_WIDTH + START_OFFSET,
          }}
        />
      </Tooltip>
      <span
        className={styles.info}
        style={{
          top: 3,
          left: left * DATE_ITEM_WIDTH - textSize / 2 + START_OFFSET,
        }}
      >
        <Typography.Text
          type='secondary'
        >
          {date}
        </Typography.Text>
      </span>
    </span>
  );
};

export default Spot;
