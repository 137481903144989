import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tabs, Layout, List, Button, Popconfirm, message, Spin } from 'antd';

import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { getDeviceFunctions } from '@app/helpers';
import { NONPERFORMANCE_TERM } from '@common/sidebarRoot/types';
import { RIGHT_PASSPORT_NONPERFORMANCE, MODE_EDIT } from '@globalConstants';
import { IS_ISSUED_ARCHIVE } from '@routes/passport/tabs/tasks/constants';
import api from '@services/api';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { CustomCard, PopoverEllipsis } from '@ui';

import TermDescription from './TermDescription';

import styles from './styles.module.less';

const { TabPane } = Tabs;
const { Header, Sider, Content } = Layout;

class Nonperformance extends Component {
  state = {
    selectedParam: null,
    selectedTerm: null,
    isAddingTermDescription: false,
    isParamsLoading: false,
    isDeletingTerm: false,
    isLoadingDescription: false,
    parameters: [],
    descriptions: [],
    deviceFns: [],
    functionsCount: 0,
  };

  async componentDidUpdate(prevProps) {
    const { blank, taskId, passportId } = this.props;
    const { selectedParam } = this.state;

    if (blank && (!prevProps.blank || prevProps.blank.id !== blank.id || prevProps.taskId !== taskId)) {
      await this.loadData(async () => {
        const { parameters } = this.state;

        if (selectedParam) {
          this.onTabChange(selectedParam && selectedParam.id);
        } else if (parameters && parameters.length > 0) {
          this.onTabChange(parameters[0].id);
        }

        await getDeviceFunctions(passportId, (tree, functionsTree, functionsList) => {
          this.setState({
            deviceFns: tree,
            functionsCount: functionsList && functionsList.length,
          });
        });
      });
    }

    if (prevProps.blank && !blank) {
      this.setState({
        parameters: [],
      });
    }

    if (prevProps.passportId !== passportId && passportId && blank) {
      this.setState({
        deviceFns: await getDeviceFunctions(passportId),
      });
    }
  }

  loadData = async (callback) => {
    const { blank } = this.props;

    this.setState({ isParamsLoading: true });
    const { status, data } = await api.getNonperformanceParams({ blank: blank && blank.id });
    this.setState(
      {
        isParamsLoading: false,
        parameters: status === 200 ? data.results : [],
      },
      () => {
        callback && callback();
      }
    );
  };

  onTabChange = (tab) => {
    const { closeNonperformanceSidebar } = this.props;
    const { parameters } = this.state;
    const newSelectedParam = parameters.find((param) => tab === param.id);

    closeNonperformanceSidebar();

    this.setState({
      selectedParam: newSelectedParam,
      isAddingTermDescription: false,
    });

    newSelectedParam && newSelectedParam.terms[0]
      ? this.onSelectTerm(newSelectedParam.terms[0])
      : this.setState({ descriptions: [], selectedTerm: null });
  };

  onSelectTerm = async (term) => {
    this.setState({
      selectedTerm: term,
      isAddingTermDescription: false,
    });

    this.setState({ isLoadingDescription: true });

    const { status, data } = await api.getTermDescriptions(term.id);

    this.setState({
      isLoadingDescription: false,
      descriptions: status === 200 ? data.results : [],
    });
  };

  handleAddDescription = () => {
    this.setState({ isAddingTermDescription: true });
  };

  cancelAddingDescription = () => {
    this.setState({ isAddingTermDescription: false });
  };

  handleAddTermBtn = () => {
    const { t, openNonperformanceSidebar, closeNonperformanceSidebar, blank } = this.props;
    const { selectedParam } = this.state;

    openNonperformanceSidebar(
      NONPERFORMANCE_TERM,
      {
        terms: selectedParam.terms,
        title: selectedParam && t(selectedParam.name),
        selectedParamId: selectedParam && selectedParam.id,
        blank,
        callback: async () => {
          closeNonperformanceSidebar();
          await this.loadData();
          this.onTabChange(selectedParam && selectedParam.id);
        },
      },
      700
    );
  };

  handleEditTerm = () => {
    const { openNonperformanceSidebar, closeNonperformanceSidebar, blank } = this.props;
    const { selectedParam, selectedTerm } = this.state;

    openNonperformanceSidebar(
      NONPERFORMANCE_TERM,
      {
        terms: selectedParam.terms,
        isEdit: true,
        selectedParamId: selectedParam && selectedParam.id,
        selectedTerm,
        blank,
        callback: async (term) => {
          closeNonperformanceSidebar();
          await this.loadData();
          this.onTabChange(selectedParam && selectedParam.id);
          this.onSelectTerm(term);
        },
      },
      700
    );
  };

  confirmDeleteTerm = async () => {
    const { t } = this.props;
    const { selectedParam, selectedTerm } = this.state;

    this.setState({ isDeletingTerm: true });
    const { status } = await api.deleteNonperformanceTerm(selectedTerm.id);
    this.setState({ isDeletingTerm: false });

    if (status === 204) {
      await this.loadData();
      this.onTabChange(selectedParam && selectedParam.id);
      message.success(t('DELETE_SUCCESS'));
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  render() {
    const { t, blank, passportId, taskStatus, rights, isArchived, isDefaultOpenPanel, titleExtra } = this.props;
    const {
      parameters,
      selectedParam,
      selectedTerm,
      isAddingTermDescription,
      descriptions,
      isParamsLoading,
      isDeletingTerm,
      isLoadingDescription,
      deviceFns,
      functionsCount,
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_NONPERFORMANCE] === MODE_EDIT && !isArchived;

    const disableButtonsByTaskStatus = IS_ISSUED_ARCHIVE === taskStatus;

    const countTerms =
      parameters && parameters.length
        ? parameters.reduce((a, x) => a + (x && x.terms && x.terms.length ? x.terms.length : 0), 0)
        : 0;

    return (
      <CustomCard
        collapsable={true}
        titleExtra={titleExtra}
        loading={isParamsLoading}
        count={countTerms}
        isDefaultOpenPanel={isDefaultOpenPanel}
        className={styles.wrapper}
        hasData={!!blank}
        title={t('NONPERFORMANCE_TERMS')}
        tabList={parameters.map((param) => ({
          key: param.id,
          tab: t(param.name),
        }))}
      >
        <Tabs defaultActiveKey='1' onChange={this.onTabChange}>
          {parameters.map((param) => (
            <TabPane tab={t(param.name)} key={param.id} />
          ))}
        </Tabs>
        <Layout className={styles.editor}>
          <Sider width={260} className={styles.terms}>
            <List
              bordered={false}
              dataSource={(selectedParam && selectedParam.terms) || []}
              renderItem={(term) => (
                <List.Item
                  onClick={() => this.onSelectTerm(term)}
                  className={`${styles.term} ${selectedTerm && selectedTerm.id === term.id ? styles.activeTerm : ''}`}
                >
                  <PopoverEllipsis content={term.name}>{term.name}</PopoverEllipsis>
                </List.Item>
              )}
            />
            {selectedParam && (
              <Button
                className={styles.addTermBtn}
                block
                onClick={this.handleAddTermBtn}
                disabled={disableButtonsByTaskStatus || !isEditRight}
              >
                <PopoverEllipsis content={`${t('ADD')} ${selectedParam ? t(selectedParam.name).toLowerCase() : ''}`}>
                  <PlusOutlined /> {`${t('ADD')} ${selectedParam ? t(selectedParam.name).toLowerCase() : ''}`}
                </PopoverEllipsis>
              </Button>
            )}
          </Sider>
          <Layout>
            {selectedTerm && (
              <Header className={styles.header}>
                <div className={styles.header__text}>
                  <div className={styles.label}>{`${t('DESCRIPTION')} ${selectedParam && t(selectedParam.name)}`}</div>
                  <div className={styles.header__text__name}>{selectedTerm && selectedTerm.name}</div>
                </div>
                <div className={styles.actions}>
                  <Button
                    icon={<EditOutlined />}
                    onClick={this.handleEditTerm}
                    disabled={disableButtonsByTaskStatus || !isEditRight}
                  >
                    {t('EDIT')}
                  </Button>
                  <Popconfirm
                    placement='topLeft'
                    title={t('DELETE_TERM_CONFIRM')}
                    okText={t('YES')}
                    cancelText={t('NO')}
                    onConfirm={this.confirmDeleteTerm}
                    disabled={disableButtonsByTaskStatus || !isEditRight}
                  >
                    <Button
                      loading={isDeletingTerm}
                      icon={<DeleteOutlined />}
                      style={{ color: '#FF0000' }}
                      disabled={disableButtonsByTaskStatus || !isEditRight}
                    />
                  </Popconfirm>
                </div>
              </Header>
            )}
            <Spin spinning={isLoadingDescription}>
              <Content className={styles.content}>
                {descriptions.map((desc, index) => (
                  <TermDescription
                    description={desc}
                    key={desc.id}
                    descriptionType={selectedParam && selectedParam.description}
                    index={index}
                    passportId={passportId}
                    term={selectedTerm}
                    onSelectTerm={this.onSelectTerm}
                    deviceFns={deviceFns}
                    functionsCount={functionsCount}
                    isStepShowing={selectedParam.name === 'REDUNDANCY'}
                    descriptions={descriptions}
                  />
                ))}
                {isAddingTermDescription && (
                  <TermDescription
                    isAdding
                    descriptionType={selectedParam && selectedParam.description}
                    index={descriptions.length}
                    passportId={passportId}
                    term={selectedTerm}
                    cancelAddingDescription={this.cancelAddingDescription}
                    onSelectTerm={this.onSelectTerm}
                    deviceFns={deviceFns}
                    functionsCount={functionsCount}
                    isStepShowing={selectedParam.name === 'REDUNDANCY'}
                    descriptions={descriptions}
                  />
                )}
                {selectedParam && selectedTerm && (
                  <>
                    {selectedParam.description ? (
                      <Button
                        className={styles.addBtn}
                        icon={<PlusOutlined />}
                        onClick={this.handleAddDescription}
                        disabled={disableButtonsByTaskStatus || !isEditRight}
                      >
                        {t('ADD_RISK')}
                      </Button>
                    ) : (
                      <Button
                        className={styles.addBtn}
                        icon={<PlusOutlined />}
                        onClick={this.handleAddDescription}
                        disabled={disableButtonsByTaskStatus || !isEditRight}
                      >
                        {t('ADD_EQUIPMENT_BASE')}
                      </Button>
                    )}
                  </>
                )}
              </Content>
            </Spin>
          </Layout>
        </Layout>
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
});

const mapDispatchToProps = {
  openNonperformanceSidebar: openSidebar,
  closeNonperformanceSidebar: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Nonperformance));
