import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import {
  Typography, Button, Layout, Input, Popconfirm, Empty, TreeSelect
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import browserHistory from '@app/browserHistory';
import { MAP_RIGHTS_TO_PATHS, MODE_EDIT, RIGHT_ROUTE_TEMPLATES, ROUTE_TEMPLATES } from '@app/constants';
import {
  onDropTreeValueItemIntoNewTreeItem,
  onChangeTreeData,
  onSelectTreeData,
  onViewTree,
} from '@common/tree';
import TemplateTree from "@common/tree";
import TemplateTreeValues from "@common/treeValues";
import {
  onDragStartTreeValueItem,
  onDropTreeValueItemInside,
  onClearDragTreeValue,
  onChangeTreeValueData,
} from '@common/treeValues';
import { checkDefaultValueIntoRanges } from '@common/treeValues/renderRangeFields';
import { checkDefaultValueIntoSelector } from '@common/treeValues/renderSelectorFields';
import {
  getObjectFromUrl,
  loopTree,
  withPrefix,
  getDeviceFunctions,
  onChangeSelectedFn,
  renderFunctionsSelect
} from '@globalHelpers';
import api from '@services/api';
import { clearTemplatesError, saveTemplates } from '@state/templates/actions';
import { CustomCard } from '@ui';

import { ActionsForTree } from './actions/actionsForTree';
import { ActionsForTreeValues } from './actions/actionsForTreeValues';

import './styles.less';

class CreateTemplateComponent extends Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;
    const state = this.state || {};
    this.state = { ...state, ...this.getInitialState() };
    this.actionsForTree = new ActionsForTree();
    this.actionsForTreeValues = new ActionsForTreeValues(withPrefix('CREATE_TEMPLATE_', props.t), this.actionsForTree);
  }

  getInitialState() {
    const { templateId } = this.props;

    return {
      /** *************** state for Tree ************** */
      treeData: [],
      oldTreeData: [],
      selectedTreeItem: {},
      isViewTree: true,
      isEditTreeItem: false,

      /** *************** state for Tree Values ************** */
      treeValueData: [],
      dragTreeValueItem: {},
      isEditTreeValueItem: false,

      /** *************** state for this component ************** */
      template: {},
      currentTemplate: {},
      templateId: templateId || '',
      templateName: '',
      oldTemplateName: '',

      showButtons: false,
      isLoading: false,

      isNewTemplate: false,
      isSaveAsNewTemplate: false,

      isViewComplexColumn: false,
      isViewTaskColumn: false,
      deviceFns: [],
      selectedTreeValueData: [],
      treeTableComponent: null,
      multipleEditingMode: false,
    };
  }

  componentDidMount() {
    const {
      history, location, savedTemplates, setClearTemplateError,
    } = this.props;

    const templateFromUrl = getObjectFromUrl(location, ['return_id']);

    if (templateFromUrl.return_id) {
      const newState = (
        savedTemplates
        && savedTemplates[templateFromUrl.return_id]
        && savedTemplates[templateFromUrl.return_id].oldState
      );

      if (newState && newState.templateName) {
        this.setState({
          ...newState,
          isLoading: false,
        });
        setClearTemplateError(templateFromUrl.return_id);
        history.replace('/templates/create');
      } else {
        this.loadData();
      }
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { templateId } = this.props;

    if (templateId && prevState.templateId !== templateId) {
      this.updateState(templateId);
    }
  }

  onAddTreeItem = (addItem) => {
    this.setState({
      isEditTreeItem: true,
    });
    return addItem;
  };

  onEditTreeItem = (editedKey, editedItem) => {
    this.setState({
      isEditTreeItem: true,
    });
    return editedItem;
  };

  onAddTreeValueItem = (addItem) => {
    this.setState({
      isEditTreeValueItem: true,
    });
    return this.actionsForTreeValues.onAddTreeValueItem(addItem);
  };

  onEditTreeValueData = (editedItem) => {
    this.setState({
      isEditTreeValueItem: true,
    });
    return this.actionsForTreeValues.onEditTreeValueData(editedItem);
  };

  additionalComponentDidMount = async (component) => {
    let setPointTypeList = [];
    let setMeasurementUnitList = [];
    const { status: setPointTypeStatus, data: setPointTypeData } = await api.getSetpointTypes();
    const { status: setMeasurementUnitStatus, data: setMeasurementUnitData } = await api.getMeasurementUits();
    if (setPointTypeStatus === 200 && setPointTypeData) {
      setPointTypeList = cloneDeep(setPointTypeData.results);
    }
    if (setMeasurementUnitStatus === 200 && setMeasurementUnitData) {
      setMeasurementUnitList = cloneDeep(setMeasurementUnitData.results);
    }
    const tree = await getDeviceFunctions();
    this.setState({ deviceFns: tree });
    component.setState({
      setPointTypeList: setPointTypeList,
      setMeasurementUnitList: setMeasurementUnitList,
      deviceFns: tree,
      treeTableComponent: component
    });
  };

  additionalComponentWillUnmount = (component) => {
    const { deviceFns, setPointTypeList, setMeasurementUnitList, ...state } = component.state;
    component.setState(state);
  };

  loadData = async () => {
    const { templateId, templateName } = this.state;

    this.setState({ isLoading: true });

    let newTemplateName = templateName;

    if (templateId && templateId.length > 0) {
      const { status, data } = await api.getTemplateTree(templateId);
      if (status === 200 && data) {
        const template = cloneDeep(data);
        template.categories && delete template.categories;
        newTemplateName = template.name;

        const templateResult = {
          template,
          templateName: newTemplateName,
          oldTemplateName: newTemplateName,
        };

        if (data.categories && data.categories.length) {
          const oldTreeData = cloneDeep(data.categories);

          const setPoints = [];

          loopTree(data.categories, null, (item) => {
            if ('setpoints' in item && item.setpoints && item.setpoints.length) {
              item.setpoints.forEach((item_setpoints) => {
                const newItem = cloneDeep(item_setpoints);
                newItem.category = { id: item.id, name: item.name };
                setPoints.push(newItem);
              });
            }
          });

          templateResult.oldTreeData = oldTreeData;
          templateResult.treeData = data.categories;
          templateResult.treeValueData = setPoints;
          templateResult.isViewComplexColumn = setPoints.some((x) => !!x.view_complex);
          templateResult.isViewTaskColumn = setPoints.some((x) => !!x.view_task);
        }
        this.setState(templateResult);
      }
    }

    this.setState({
      isLoading: false,
    });
  };

  onClickButtonSave = async () => {
    const {
      isSaveAsNewTemplate,
      templateName,
      templateId,
      treeData,
      oldTreeData,
      treeValueData,
    } = this.state;
    const { onSaveTemplate } = this.props;

    this.setState({
      isLoading: true,
    });

    const newTemplate = await this.actionsForTreeValues.prepareForSaveTemplateTreeValue(
      templateName,
      treeData.map((item) => cloneDeep(item)),
      oldTreeData,
      treeValueData,
      isSaveAsNewTemplate
    );

    if (templateId && !isSaveAsNewTemplate) {
      await onSaveTemplate(this, templateId, templateName, newTemplate);
    } else {
      await onSaveTemplate(this, undefined, templateName, newTemplate);
    }
  };

  onClickButtonCancel = () => {
    browserHistory.goBack();
  };

  onClickButtonReload = () => {
    this.loadData();
    this.setState({
      showButtons: false,
    });
  };

  onAdminSaveCurrentTemplate = () => {
    this.setState(
      {
        isSaveAsNewTemplate: false,
      },
      () => {
        this.onClickButtonSave();
      }
    );
  };

  onAdminSaveNewTemplate = () => {
    this.setState(
      {
        isSaveAsNewTemplate: true,
      },
      () => {
        this.onClickButtonSave();
      }
    );
  };

  onShowButtonsSaveCancel = (visible) => {
    if (visible) {
      this.setState({
        showButtons: true,
      });
    } else {
      this.setState({
        showButtons: false,
      });
    }
  };

  renderButtonBack = () => {
    const { t } = this.props;
    const { showButtons } = this.state;

    return showButtons ? (
      <Popconfirm
        title={t('BACK_TEMPLATE_CONFIRM')}
        onConfirm={this.onClickButtonCancel}
        okText={t('YES')}
        cancelText={t('NO')}
      >
        <Button size='small'>{t('BTN_OPTIONS_BACK_TEMPLATE')}</Button>
      </Popconfirm>
    ) : (
      <Button size='small' onClick={this.onClickButtonCancel}>{t('BTN_OPTIONS_BACK_TEMPLATE')}</Button>
    );
  };

  renderButtonReload = () => {
    const { t, templateId, rights } = this.props;
    const { showButtons } = this.state;
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    return showButtons && templateId && !isReadOnly ? (
      <Popconfirm
        placement="topRight"
        title={t('RELOAD_TEMPLATE_CONFIRM')}
        onConfirm={this.onClickButtonReload}
        okText={t('YES')}
        cancelText={t('NO')}
      >
        <Button size='small'>{t('BTN_OPTIONS_RELOAD_TEMPLATE')}</Button>
      </Popconfirm>
    ) : (
      <Button onClick={this.onClickButtonReload} disabled={true} size='small'>
        {t('BTN_OPTIONS_RELOAD_TEMPLATE')}
      </Button>
    );
  };

  renderButtonSave = (showAdminQuestion, templateHasBlanks, templateHasChildren) => {
    const { t, rights } = this.props;
    const { template, showButtons } = this.state;
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    return showAdminQuestion ? (
      <Popconfirm
        placement='topLeft'
        title={(
          <div>
            <div>{t('ADMIN_SAVE_TEMPLATE_TEXT')}</div>
            <br />
            {templateHasBlanks ? (
              <div>
                <h4>{t('HAS_BLANKS')}</h4>
                <ul>
                  {template.blanks.map((item) => (
                    <li title={item.id}>{item.name}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
            {templateHasBlanks && templateHasChildren ? <h4>{t('AND')}</h4> : ''}
            {templateHasChildren ? (
              <h4>
                {t('HAS_CHILDREN_1')}
                <br />
                {t('HAS_CHILDREN_2')}
              </h4>
            ) : (
              ''
            )}
          </div>
        )}
        onConfirm={this.onAdminSaveNewTemplate}
        onCancel={this.onAdminSaveCurrentTemplate}
        okText={t('ADMIN_SAVE_NEW_TEMPLATE')}
        cancelText={t('ADMIN_SAVE_CURRENT_TEMPLATE')}
      >
        <Button type='primary' disabled={!showButtons || isReadOnly} size='small'>
          {t('BTN_OPTIONS_SAVE_TEMPLATE')}
        </Button>
      </Popconfirm>
    ) : (
      <Button onClick={this.onClickButtonSave} type='primary' disabled={!showButtons || isReadOnly} size='small'>
        {t('BTN_OPTIONS_SAVE_TEMPLATE')}
      </Button>
    );
  };

  setSelectedFunction = (func) => {
    const {
      treeValueData, selectedTreeValueData, selectedTreeItem,
    } = this.state;

    const newData = cloneDeep(treeValueData);

    newData.forEach(
      (x, i) => {
        if (x.category
          && x.category.id === selectedTreeItem.id
          && selectedTreeValueData
          && selectedTreeValueData.includes(x.id)
        ) {
          newData[i].function = func;
          onChangeTreeValueData(newData, 'batch-update', newData[i], this);
        }
      }
    );

    this.setState({
      selectedTreeValueData: [],
      selectedRowKeys: [],
      selectedRows: [],
      showButtons: true
    });
  }

  checkErrorValueAndGetClassName = (record) => {
    return (
      record.setpoint_type === 'numeric'
        ? (
          record.range_values
            && record.range_values.length
            && !checkDefaultValueIntoRanges(
              record.default_value,
              (
                (record.value && record.value.range_values)
                || record.range_values
              )
            )
            ? 'row__warning'
            : ''
        )
        : (
          ['selector', 'multiple_selector'].indexOf(record.setpoint_type.name) !== -1
            && (
              !record.selector_values
              || !record.selector_values.length
              || !checkDefaultValueIntoSelector(
                record.default_value,
                record.selector_values.map((x, i) => ({ id: i, value: x }))
              )
            )
            ? 'row__warning'
            : ''
        )
    );
  };

  updateState(templateId) {
    this.setState(
      {
        templateId: templateId || '',
      },
      () => {
        this.loadData();
      }
    );
  }

  enableMultipleSelectionMode = () => {
    this.setState({
      multipleEditingMode: true,
    })
  }

  disableMultipleSelectionMode = () => {
    this.setState({
      selectedTreeValueData: [],
      selectedRowKeys: [],
      selectedRows: [],
      multipleEditingMode: false,
    })
  }

  getExtraButtons = () => {
    const { multipleEditingMode, deviceFns, selectedTreeValueData } = this.state;
    const { t, rights } = this.props;

    const selectedTreeValueCount = selectedTreeValueData?.length;
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    if (multipleEditingMode) {
      return <div className='template-select-function-wrapper'>
        <div className='template-select-function-label'>
          {t('PASSPORT_SETPOINTS_SELECT_FUNCTION_LABEL')}
        </div>
        <TreeSelect
          size='small'
          disabled={!selectedTreeValueCount}
          className='template-select-function-select'
          treeDefaultExpandAll
          style={{ width: 400 }}
          value={null}
          onChange={(selectedFunctionId) => onChangeSelectedFn(
            selectedFunctionId,
            deviceFns,
            (found) => this.setSelectedFunction(found)
          )}
        >
          {renderFunctionsSelect(deviceFns, [], undefined, t, null, null, true, true)}
        </TreeSelect>
        <Button size='small' className='group-editing-button' onClick={this.disableMultipleSelectionMode}>Обычный режим</Button>
      </div>
    }

    return <Button size='small' className='group-editing-button' onClick={this.enableMultipleSelectionMode} disabled={isReadOnly}>Групповое изменение функций</Button>
  }

  render() {
    const { t, rights } = this.props;
    const {
      /** *************** state for Tree ************** */
      treeData,
      selectedTreeItem,
      isViewTree,
      isEditTreeItem,

      /** *************** state for Tree Values ************** */
      treeValueData,
      dragTreeValueItem,
      isEditTreeValueItem,

      /** *************** state for this component ************** */
      template,
      templateId,
      templateName,
      oldTemplateName,
      isLoading,
      isNewTemplate,
      selectedTreeValueData,
      multipleEditingMode,

      /** ****************** inner state ********************* */
    } = this.state;

    const isEditRight = rights[RIGHT_ROUTE_TEMPLATES] === MODE_EDIT;

    const { Title } = Typography;
    const { Content } = Layout;
    const templateHasBlanks = template
      && 'blanks' in template
      && template.blanks
      && Array.isArray(template.blanks)
      && template.blanks.length;
    const templateHasChildren = template && 'have_children' in template && template.have_children === true;

    const showAdminQuestion = templateId
      && templateId.length !== 0
      && templateName === oldTemplateName
      && isNewTemplate
      && (templateHasBlanks || templateHasChildren);

    const topButtons = multipleEditingMode && ['tree'];
    const isReadOnly = rights[MAP_RIGHTS_TO_PATHS[ROUTE_TEMPLATES]] !== MODE_EDIT;

    return (
      <Layout style={{ padding: '5px', paddingBottom: 0 }} className='template'>
        <Content>
          <CustomCard loading={isLoading} className='template__card template__card_head'>
            <div className='template__heading'>
              <div className='template__left'>
                <Title level={5}>
                  {templateId && !isReadOnly && t('TITLE')}
                  {templateId && isReadOnly && t('TEMPLATE_VIEW')}
                  {!templateId && t('CREATE_TEMPLATE_TITLE')}
                </Title>
                <span className='template__name'>
                  <Input
                    disabled={isReadOnly}
                    type='text'
                    value={templateName}
                    placeholder={t('CREATE_TEMPLATE_NEW_NAME')}
                    key='input_template_name'
                    size='small'
                    onChange={(event) =>
                      this.setState({
                        templateName: event.target.value,
                        showButtons: true,
                      })
                    }
                  />
                </span>
                <div className='template__additional-info'>
                  <span>Модель PF.Protection: {template?.pf_model || '-'}</span>
                  <span>Версия PF.Protection: {template?.pf_version || '-'}</span>
                </div>
              </div>
            </div>
            <div className='template__btn-group'>
              <div className='template__btn-group__back'>{this.renderButtonBack()}</div>
              <div className='template__btn-group__reload'>{this.renderButtonReload()}</div>
              <div className='template__btn-group__save'>
                {this.renderButtonSave(showAdminQuestion, templateHasBlanks, templateHasChildren)}
              </div>
            </div>
          </CustomCard>
          <CustomCard loading={isLoading} className='template__card template__card_body'>
            {treeData ? (
              <div className='template-wrapper'>
                <div className={`template-left ${!isViewTree && 'hidden'}`}>
                  <TemplateTree
                    /** **** Все поля являются обязательными для передачи ***** */
                    t={withPrefix('CREATE_TEMPLATE_', t)}
                    groups={treeData}
                    isRootTreeItem={true}
                    isEditTreeValueItem={isEditTreeValueItem}
                    newItem={this.actionsForTree.newTreeItem}
                    onAddItem={this.onAddTreeItem}
                    onEditItem={this.onEditTreeItem}
                    onSelectTreeData={(selected) => onSelectTreeData(selected, this)}
                    onChangeTreeData={(newTreeData, operation, item) =>
                      onChangeTreeData(newTreeData, operation, item, this)
                    }
                    dragTreeValueItem={dragTreeValueItem}
                    onDropTreeValueItemIntoNewTreeItem={(dragObject, dropObject) =>
                      onDropTreeValueItemIntoNewTreeItem(dragObject, dropObject, onChangeTreeValueData, this)
                    }
                    onClearDragTreeValue={() => onClearDragTreeValue(this)}
                    onShowButtonsSaveCancel={this.onShowButtonsSaveCancel}
                    isCanEdit={isEditRight}
                    isCanDragAndDrop={isEditRight}
                    isShowButtonsPanel={true}
                  /** **** (необязательно) ***** */
                  /** **** Список стандартных кнопок, которые разрешены/могут быть активны ***** */
                  /** **** ['add','delete']. Если все активны - можно не передавать ***** */
                  // enableStandardButtons={['add','delete']}
                  // visibleStandardButtons={['add','delete']}
                  /** **** Использование дополнительных кнопок ***** */
                  // onExpandTreeData={(expandedKeys) => this.setState({expandedTreeItem: expandedKeys})}
                  /** **** Использование дополнительных кнопок дерева ***** */
                  // extraButtons={() => this.renderExtraButtons(this)}
                  /** **** Переопределение отмеченных элементов дерева (при изменении дерева) ***** */
                  // selectedKeys={this.selectedTreeItem}
                  /** **** Переопределение открытых элементов дерева (при изменении дерева) ***** */
                  // expandedKeys={this.expandedTreeItem}
                  />
                </div>
                <div className={`template-right ${!isViewTree && 'full-size'}`}>
                  <TemplateTreeValues
                    /** **** Все поля являются обязательными для передачи ***** */
                    originComponent={this}
                    t={withPrefix('CREATE_TEMPLATE_', t)}
                    isViewTree={isViewTree}
                    isEditTreeItem={isEditTreeItem}
                    onViewTree={(isShow) => onViewTree(this, isShow)}
                    values={treeValueData}
                    renderColumns={(component, dataFiltered) =>
                      this.actionsForTreeValues.renderTreeValueColumns(
                        component,
                        this,
                        true,
                        dataFiltered
                      )
                    }
                    newItem={this.actionsForTreeValues.newTreeValueItem}
                    onAddItem={this.onAddTreeValueItem}
                    onEditItem={this.onEditTreeValueData}
                    onSaveEditedData={this.actionsForTreeValues.onSaveEditedTreeValueData}
                    selectedTreeItem={selectedTreeItem}
                    onChangeTreeValueData={(newTreeValueData, operation, item) =>
                      onChangeTreeValueData(newTreeValueData, operation, item, this)
                    }
                    dragTreeValueItem={dragTreeValueItem}
                    onDragStartTreeValueItem={(item) => onDragStartTreeValueItem(item, this)}
                    onDropTreeValueItemInside={(dragObject, dropAfterObject) =>
                      onDropTreeValueItemInside(dragObject, dropAfterObject, this)
                    }
                    onShowButtonsSaveCancel={this.onShowButtonsSaveCancel}
                    isCanEdit={isEditRight}
                    isCanDragAndDrop={isEditRight}
                    isShowButtonsPanel={true}
                    /** **** необязательные параметры ***** */
                    /** **** Название переменной для сохранения ширины колонок ***** */
                    tableName='createTemplate'
                    /** **** Список стандартных кнопок, которые разрешены/могут быть активны ***** */
                    /** **** ['tree','add','edit','delete','copy/paste','settings']. ***** */
                    /** **** Если все активны - можно не передавать ***** */
                    // enableStandardButtons={['tree', 'add', 'edit', 'delete', 'copy/paste', 'settings']}
                    visibleStandardButtons={topButtons}
                    // saveSettings={true}
                    /** **** Загрузка и использование доп.переменных в state-е компонента "Значений" ***** */
                    additionalComponentDidMount={this.additionalComponentDidMount}
                    additionalComponentWillUnmount={this.additionalComponentWillUnmount}
                    /** **** Использование дополнительных кнопок ***** */
                    extraButtons={this.getExtraButtons}
                    /** **** Выделение строки данных при определенных условиях (возвращается className) ***** */
                    checkErrorValueAndGetClassName={(record) => this.checkErrorValueAndGetClassName(record)}
                    /** **** Разрешение на редактирование конкретной записи ***** */
                    checkRecordForEdit={isReadOnly && this.actionsForTreeValues.checkRecordForEdit}
                    /** **** Дополнительная обработка элемента после копирования ***** */
                    // onCopyTreeValueData={this.actionsForTreeValues.onCopyTreeValueData}
                    /** **** Настройки выделения строк в таблице ***** */
                    rowSelection={
                      multipleEditingMode && {
                        type: 'checkBox',
                        selectedRowKeys: selectedTreeValueData,
                        onChange: (selectedRowKeys) => {
                          this.setState({
                            selectedTreeValueData: selectedRowKeys,
                          });
                        }
                      }}
                  />
                </div>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{t('NOT_FOUND_BY_PARAMETERS')}</span>} />
            )}
          </CustomCard>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
  savedTemplates: state.templates.savedTemplates,
});

const mapDispatchToProps = {
  onSaveTemplate: saveTemplates,
  setClearTemplateError: clearTemplatesError,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      CreateTemplateComponent
    )
  )
);
