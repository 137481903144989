import { GET_SETTINGS, UPDATE_SETTINGS } from './types';

const initialState = {
  data: {},
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        data: action.settings,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        data: action.settings,
      };
    default:
      return state;
  }
}
