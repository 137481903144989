import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import unionBy from 'lodash/unionBy';
import debounce from 'lodash/debounce';

import { Form, Select } from 'antd';

import api from '@services/api';
import { LIMIT } from '@globalConstants';

import { Item, IncomingItem } from './types';
import { SelectInfiniteScroll } from '@ui/index';

interface Props extends WithTranslation {
  eventItem: IncomingItem;
}

interface ScrollEvent extends UIEvent {
  target: HTMLInputElement & EventTarget;
}

interface State {
  owners: Item[];
  substations: Item[];
  voltageLevels: Item[];
  docTypes: Item[];
  isOwnersLoading: boolean;
  isSubstationsLoading: boolean;
  isVoltageLevelsLoading: boolean;
  ownersSearchValue?: string;
  subSearchValue?: string;
  volSearchValue?: string;
  ownersCount?: number;
  subsCount?: number;
  volCount?: number;
}

type Data = {
  data: {
    results: Item[];
    count: number;
  };
  status: number;
};

class EventsFormSidebar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { t, eventItem } = props;

    const state: State = {
      owners: [],
      substations: [{ name: t('EVENTS_SUBSTATION'), id: '' }],
      voltageLevels: [],
      docTypes: [],
      isOwnersLoading: false,
      isSubstationsLoading: false,
      isVoltageLevelsLoading: false,
    };

    eventItem.subject && (state.owners = unionBy(state.owners, [eventItem.subject], 'id'));
    eventItem.substation && (state.substations = unionBy(state.substations, [eventItem.substation], 'id'));
    eventItem.voltage_level && (state.voltageLevels = unionBy(state.voltageLevels, [eventItem.voltage_level], 'id'));

    this.state = state;
  }

  componentDidMount() {
    this.handleOwnerSearch();
    this.handleSubstationSearch();
    this.handleVoltageLevelsSearch();
  }

  handleOwnerSearch = async (value?: string, offset = 0) => {
    const { owners, ownersSearchValue } = this.state;
    const isSearchValueChanged = value !== ownersSearchValue;

    this.setState({ isOwnersLoading: true });
    const ownersData: Data = await api.getOwners({ name: value, offset, limit: LIMIT });
    this.setState({ isOwnersLoading: false });

    if (ownersData.status === 200 && ownersData.data.results) {
      this.setState({
        owners: isSearchValueChanged ? ownersData.data.results : unionBy(owners, ownersData.data.results, 'id'),
        ownersCount: ownersData.data.count,
        ownersSearchValue: value,
      });
    }
  };

  handleOwnersScroll = () => {
    const { owners, ownersSearchValue } = this.state;
    this.handleOwnerSearch(ownersSearchValue, owners.length);
  };

  handleSubstationsScroll = () => {
    const { substations, subSearchValue } = this.state;
    this.handleSubstationSearch(subSearchValue, substations.length);
  };

  handleVoltageLevelsScroll = () => {
    const { voltageLevels, volSearchValue } = this.state;
    this.handleVoltageLevelsSearch(volSearchValue, voltageLevels.length);
  };

  handleSubstationSearch = async (value?: string, offset = 0) => {
    const { substations, subSearchValue } = this.state;

    const isSearchValueChanged = value !== subSearchValue;

    this.setState({ isSubstationsLoading: true });
    const substationData: Data = await api.getSubstationsData({ name: value, offset, limit: LIMIT });
    this.setState({ isSubstationsLoading: false });

    if (substationData.status === 200 && substationData.data.results) {
      this.setState({
        substations: isSearchValueChanged
          ? substationData.data.results
          : unionBy(substations, substationData.data.results, 'id'),
        subsCount: substationData.data.count,
        subSearchValue: value,
      });
    }
  };

  handleVoltageLevelsSearch = async (value?: string, offset = 0) => {
    const { voltageLevels, volSearchValue } = this.state;
    const isSearchValueChanged = value !== volSearchValue;

    this.setState({ isVoltageLevelsLoading: true });
    const voltageLevelsData: Data = await api.getVoltageLevels({ name: value, offset, limit: LIMIT });
    this.setState({ isVoltageLevelsLoading: false });

    if (voltageLevelsData.status === 200 && voltageLevelsData.data.results) {
      this.setState({
        voltageLevels: isSearchValueChanged
          ? voltageLevelsData.data.results
          : unionBy(voltageLevels, voltageLevelsData.data.results, 'id'),
        volCount: voltageLevelsData.data.count,
        volSearchValue: value,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { voltageLevels, isVoltageLevelsLoading, isSubstationsLoading, substations, owners, isOwnersLoading } =
      this.state;

    return (
      <>
        <Form.Item label={t('EVENTS_OWNER')} name='subject'>
          <SelectInfiniteScroll
            placeholder={t('EVENTS_OWNER')}
            loading={isOwnersLoading}
            showSearch
            filterOption={false}
            loadMoreHandle={this.handleOwnersScroll}
            onSearch={debounce(this.handleOwnerSearch, 800)}
            onDropdownVisibleChange={(x: any) => x && this.handleOwnerSearch('')}
          >
           {owners.map((x: Item) => (
              <Select.Option key={x.id || 'level_key'} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </SelectInfiniteScroll>
        </Form.Item>
        <Form.Item label={t('EVENTS_SUBSTATION')} name='substation'>
          <SelectInfiniteScroll
            placeholder={t('EVENTS_SUBSTATION')}
            loading={isSubstationsLoading}
            showSearch
            filterOption={false}
            loadMoreHandle={this.handleSubstationsScroll}
            onSearch={debounce(this.handleSubstationSearch, 800)}
            onDropdownVisibleChange={(x: any) => x && this.handleSubstationSearch('')}
          >
            {substations.map((x) => (
              <Select.Option key={x.id || 'level_key'} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </SelectInfiniteScroll>
        </Form.Item>
        <Form.Item label={t('EVENTS_VOLTAGE_LEVEL')} name='voltage_level'>
          <SelectInfiniteScroll
            placeholder={t('EVENTS_VOLTAGE_LEVEL')}
            loading={isVoltageLevelsLoading}
            showSearch
            filterOption={false}
            loadMoreHandle={this.handleVoltageLevelsScroll}
            onSearch={debounce(this.handleVoltageLevelsSearch, 800)}
            onDropdownVisibleChange={(x: any) => x && this.handleVoltageLevelsSearch('')}
          >
            {voltageLevels.map((x) => (
              <Select.Option key={x.id || 'level_key'} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </SelectInfiniteScroll>
        </Form.Item>
      </>
    );
  }
}

export default withTranslation()(EventsFormSidebar);
