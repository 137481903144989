import { AppThunk } from '@state/store';

import api from '@services/api';
import {
  GET_RIGHTS,
  GET_PASSPORT_RIGHTS,
  GET_RIGHT_PASSPORT_DOCS,
} from './types';

export const getRights = (): AppThunk => {
  return async (dispatch) => {
    const { status, data } = await api.getRights();

    if (status === 200) {
      dispatch({
        type: GET_RIGHTS,
        rightsDataGeneral: data,
        rightsData: data,
      });
    }
  };
};

export const getPassportRights = (id: string): AppThunk => {
  return async (dispatch) => {
    const { status, data } = await api.getPassportRights(id);

    if (status === 200) {
      dispatch({
        type: GET_PASSPORT_RIGHTS,
        rightsData: data,
        rightPassportDocs: data
      });
      dispatch({
        type: GET_RIGHT_PASSPORT_DOCS,
        rightPassportDocs: { RIGHT_PASSPORT_DOCS: data.RIGHT_PASSPORT_DOCS }
      });
    }
  };
};
