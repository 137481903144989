import React from 'react';
import { Link } from 'react-router-dom';
import {
  clearHashFromFile, decodePath, downloadDocExternal, getPopoverFileNameFromLink, textHighlighter,
} from '@app/helpers';
import { ROUTE_EXTERNAL_LINK } from '@app/constants';
import {
  Button, List, Popover, Tooltip, Typography,
} from 'antd';
import {
  FileTextOutlined, LinkOutlined, PaperClipOutlined, SettingOutlined,
} from '@ant-design/icons';
import { PopoverEllipsis } from '@ui/index';

import styles from '@routes/passport/docsTable/styles.module.less';

const { Text } = Typography;

const openAllDocumentLinks = (document, component) => {
  const { t, wsState } = component && component.props;

  if (document && document.external_links && document.external_links.length) {
    if (document.is_storage) {
      component && component.setState({ isDocDownloading: true });
      document.external_links.forEach(
        async (x) => {
          await downloadDocExternal(wsState, t, x);
        }
      );
      component && component.setState({ isDocDownloading: false });
    } else if (
      component
      && component.props
      && component.props.history
    ) {
      component.props.history.push({
        pathname: ROUTE_EXTERNAL_LINK,
        state: {
          link: document.external_links,
        },
      });
    } else {
      window.history.pushState(
        {
          state: {
            link: document.external_links,
          },
        },
        undefined,
        ROUTE_EXTERNAL_LINK
      );
      window.history.go();
    }
  }
};

const onClickDocumentsFileDownload = async (e, linkToFile, component) => {
  e.stopPropagation();
  e.preventDefault();

  const { t, wsState } = component && component.props;

  component && component.setState({ isDocDownloading: true });
  await downloadDocExternal(wsState, t, linkToFile);
  component && component.setState({ isDocDownloading: false });
};

const getDocumentPopoverLink = (isStorage, link, wrappedText, component) => {
  const textLink = decodePath(link);
  const text = (
    isStorage
      ? (
        <Link
          to=''
          onClick={(e) => onClickDocumentsFileDownload(e, link, component)}
          className={styles.wrap_word_text}
        >
          {wrappedText || getPopoverFileNameFromLink(textLink, isStorage)}
        </Link>
      )
      : (
        <Link
          to={{
            pathname: ROUTE_EXTERNAL_LINK,
            state: {
              link: textLink,
            },
          }}
          className={styles.wrap_word_text}
        >
          {wrappedText || textLink}
        </Link>
      )
  );
  return (
    <PopoverEllipsis content={wrappedText || textLink}>
      {text}
    </PopoverEllipsis>
  );
};

const getLinkForDocument = (component, document, isEditRight, searchValue) => {
  const { t } = component && component.props;

  const visibleDocumentName = clearHashFromFile(document.name);
  const wrappedText = searchValue
    ? textHighlighter(searchValue, visibleDocumentName)
    : visibleDocumentName;

  let popoverVisibleContent;
  let visibleText;
  let popoverParentDivStyle;
  let popoverVisibleTitle;

  if (!document.external_links || document.external_links.length === 0) {
    popoverVisibleContent = t('NO_LINKS');
    popoverVisibleTitle = undefined;
    popoverParentDivStyle = undefined;
    visibleText = wrappedText;
  } else if (document.external_links.length === 1) {
    const external_link = document.external_links[0];
    const externalResource = getDocumentPopoverLink(document.is_storage, external_link, wrappedText, component);

    popoverVisibleContent = wrappedText;
    popoverVisibleTitle = undefined;
    popoverParentDivStyle = undefined;
    visibleText = (
      isEditRight
        ? externalResource
        : wrappedText
    );
  } else {
    popoverVisibleContent = (
      <div className={styles.multiple_document_list}>
        <Button
          className={styles.download_all}
          onClick={() => openAllDocumentLinks(document, component)}
          type='primary'
        >
          {
            document.is_storage
              ? t('OPEN_ALL_DOCUMENTS')
              : t('OPEN_ALL_LINKS')
          }
        </Button>
        <h4>
          {
            document.is_storage
              ? t('INCLUDE_FILES')
              : t('INCLUDE_LINKS')
          }
        </h4>
        <div className={styles.list_wrapper_scroll}>
          <div className={styles.list_wrapper_items}>
            <List
              size='small'
              itemLayout="horizontal"
              dataSource={document.external_links}
              className={styles.list}
              renderItem={(item) => (
                <List.Item>
                  {getDocumentPopoverLink(document.is_storage, item, null, component)}
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    );
    popoverVisibleTitle = (
      <div className={styles.popover_title}>
        <h3>
          {t('DOC_NAME')}
        </h3>
        <div className={styles.popover_title_name}>
          {wrappedText}
        </div>
      </div>
    );
    popoverParentDivStyle = styles.multiple_document_name;
    visibleText = wrappedText;
  }

  return (
    <Text className={styles.text_name}>
      {
        document.external_links && document.external_links.length > 1
          ? (
            <Tooltip placement='bottomLeft' title={t('IS_MULTIPLE')}>
              <PaperClipOutlined className={styles.icon_multiple} />
            </Tooltip>
          )
          : (
            ''
          )
      }
      {
        document.document_type && document.document_type.is_system
          ? (
            <Tooltip placement='bottomLeft' title={t('IS_SYSTEM')}>
              <SettingOutlined className={styles.icon_system_doc} />
            </Tooltip>
          )
          : (
            document.is_storage
              ? <FileTextOutlined className={styles.icon_system_doc} />
              : <LinkOutlined className={styles.icon_system_doc} />
          )
      }
      <div className={popoverParentDivStyle}>
        <Popover
          placement="topLeft"
          content={popoverVisibleContent}
          title={popoverVisibleTitle}
          overlayStyle={{ maxWidth: '500px' }}
        >
          { visibleText }
        </Popover>
      </div>
    </Text>
  );
};

export {
  getLinkForDocument,
  openAllDocumentLinks,
  getDocumentPopoverLink,
  onClickDocumentsFileDownload,
};
