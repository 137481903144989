const APPSTAGE_INITIAL = 'APPSTAGE_INITIAL';
const APPSTAGE_AUTH = 'APPSTAGE_AUTH';
const APPSTAGE_SIGNIN = 'APPSTAGE_SIGNIN';
const APPSTAGE_INITFAIL = 'APPSTAGE_INITFAIL';

const AUTH_COOKIE_NAME = 'auth_token';

// Locales
export const LANG_RU = 'ru';
export const LANG_EN = 'en';

// Routes
export const ROUTE_TREE = '/tree';
export const ROUTE_ROOT = '/';
export const ROUTE_SEARCH = '/search';
export const ROUTE_SEARCH_WITH_QUERY = '/search?:queryString';
export const ROUTE_SEARCH_TABS = '/search/tab/:tab/';
export const ROUTE_SEARCH_TABS_WITH_QUERY = '/search/tab/:tab/?:queryString';
export const ROUTE_PASSPORT = '/passport/:id';
export const ROUTE_PASSPORT_TAB = '/passport/:id/tab/:tab';
export const ROUTE_JOURNAL = '/journal';
export const ROUTE_FUNCTIONS = '/functions';
export const ROUTE_ROLES = '/roles';
export const ROUTE_ROLES_TAB = '/roles/tab/:tab';
export const ROUTE_CHOSEN_DEVICES = '/chosen-devices';
export const ROUTE_404 = '/404';
export const ROUTE_403 = '/403';
export const ROUTE_FILE_ERROR = '/file-error'
export const ROUTE_CREATE_TEMPLATE = '/templates/create';
export const ROUTE_EDIT_TEMPLATE = '/templates/edit/:id';
export const ROUTE_TEMPLATES = '/templates';
export const ROUTE_TASK_DC_MONITORING = '/monitoring';
export const ROUTE_EXTERNAL_SYSTEM = '/aip';
export const ROUTE_EXTERNAL_SYSTEM_TAB = '/aip/tab/:tab';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_CLASSIFICATORS = '/classificators';
export const ROUTE_CLASSIFICATORS_TAB = '/classificators/tab/:tab';
export const ROUTE_EXTERNAL_LINK = '/external_link';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_STORAGES = '/storages';
export const ROUTE_EVENTS = '/events';
export const ROUTE_NETWORK_MODELS = '/network-models';
export const ROUTE_LIBRARY_DOCS = '/library';
export const ROUTE_CHECK_SETPOINTS = '/protection-device-functions-checks';
export const ROUTE_RESERV = '/setpoint-values-redundancy';
export const ROUTE_DISTRIBUTION = '/reports/function-allocation-lists';

// Rights
export const RIGHT_ROUTE_ROLES = 'RIGHT_ROUTE_ROLES';
export const RIGHT_ROUTE_EXTERNAL_SYSTEMS = 'RIGHT_ROUTE_EXTERNAL_SYSTEMS';
export const RIGHT_ROUTE_SYSTEM_SETTINGS = 'RIGHT_ROUTE_SYSTEM_SETTINGS';
export const RIGHT_ROUTE_FUNCTIONS = 'RIGHT_ROUTE_FUNCTIONS';
export const RIGHT_ROUTE_TEMPLATES = 'RIGHT_ROUTE_TEMPLATES';
export const RIGHT_ROUTE_GLOBAL_SEARCH = 'RIGHT_ROUTE_GLOBAL_SEARCH';
export const RIGHT_ROUTE_TASK_DC_MONITORING = 'RIGHT_ROUTE_TASK_DC_MONITORING';
export const RIGHT_ROUTE_TREE = 'RIGHT_ROUTE_TREE';
export const RIGHT_ROUTE_JOURNAL = 'RIGHT_ROUTE_JOURNAL';
export const RIGHT_ROUTE_CLASSIFIERS = 'RIGHT_ROUTE_CLASSIFIERS';
export const RIGHT_ROUTE_STORAGES = 'RIGHT_ROUTE_STORAGES';
export const RIGHT_ROUTE_REPORTS = 'RIGHT_ROUTE_REPORTS';
export const RIGHT_PASSPORT_INFO = 'RIGHT_PASSPORT_INFO';
export const RIGHT_PASSPORT_DOCS = 'RIGHT_PASSPORT_DOCS';
export const RIGHT_PASSPORT_SETPOINTS = 'RIGHT_PASSPORT_SETPOINTS';
export const RIGHT_PASSPORT_SETPOINT_MAP = 'RIGHT_PASSPORT_SETPOINT_MAP';
export const RIGHT_PASSPORT_FUNCTIONS = 'RIGHT_PASSPORT_FUNCTIONS';
export const RIGHT_PASSPORT_TASKS = 'RIGHT_PASSPORT_TASKS';
export const RIGHT_DOCUMENT_GLOBAL_STORAGE = 'RIGHT_DOCUMENT_GLOBAL_STORAGE';
export const RIGHT_ROUTE_EVENTS = 'RIGHT_ROUTE_EVENTS';
export const RIGHT_PASSPORT_EVENTS = 'RIGHT_PASSPORT_EVENTS';
export const RIGHT_PASSPORT_LETTERS = 'RIGHT_PASSPORT_LETTERS';
export const RIGHT_PASSPORT_NONPERFORMANCE = 'RIGHT_PASSPORT_NONPERFORMANCE';
export const RIGHT_FUNCTIONS_READONLY_AIP = 'RIGHT_FUNCTIONS_READONLY_AIP';
export const RIGHT_PASSPORT_OSCILLOGRAM = 'RIGHT_PASSPORT_OSCILLOGRAM';
export const RIGHT_NEWS = 'RIGHT_NEWS';
export const RIGHT_MAINTENANCE = 'RIGHT_MAINTENANCE';
export const RIGHT_METATAGS_DOCS_ADD = 'RIGHT_METATAGS_DOCS_ADD';
export const RIGHT_NETWORK_MODELS = 'RIGHT_NETWORK_MODELS';
export const RIGHT_LIBRARY_DOCS = 'RIGHT_LIBRARY_DOCS';
export const RIGHT_ROUTE_CHECK_SETPOINTS = 'RIGHT_ROUTE_CHECK_SETPOINTS';
export const RIGHT_ROUTE_RESERV = 'RIGHT_ROUTE_RESERV';
export const RIGHT_ROUTE_DISTRIBUTION = 'RIGHT_ROUTE_DISTRIBUTION';

export const MAP_RIGHTS_TO_PATHS = {
  [ROUTE_ROLES]: RIGHT_ROUTE_ROLES,
  [ROUTE_ROLES_TAB]: RIGHT_ROUTE_ROLES,
  [ROUTE_EXTERNAL_SYSTEM]: RIGHT_ROUTE_EXTERNAL_SYSTEMS,
  [ROUTE_EXTERNAL_SYSTEM_TAB]: RIGHT_ROUTE_EXTERNAL_SYSTEMS,
  [ROUTE_SETTINGS]: RIGHT_ROUTE_SYSTEM_SETTINGS,
  [ROUTE_FUNCTIONS]: RIGHT_ROUTE_FUNCTIONS,
  [ROUTE_TEMPLATES]: RIGHT_ROUTE_TEMPLATES,
  [ROUTE_TASK_DC_MONITORING]: RIGHT_ROUTE_TASK_DC_MONITORING,
  [ROUTE_SEARCH]: RIGHT_ROUTE_GLOBAL_SEARCH,
  [ROUTE_SEARCH_WITH_QUERY]: RIGHT_ROUTE_GLOBAL_SEARCH,
  [ROUTE_SEARCH_TABS]: RIGHT_ROUTE_GLOBAL_SEARCH,
  [ROUTE_SEARCH_TABS_WITH_QUERY]: RIGHT_ROUTE_GLOBAL_SEARCH,
  [ROUTE_TREE]: RIGHT_ROUTE_TREE,
  [ROUTE_CLASSIFICATORS]: RIGHT_ROUTE_CLASSIFIERS,
  [ROUTE_CLASSIFICATORS_TAB]: RIGHT_ROUTE_CLASSIFIERS,
  [ROUTE_JOURNAL]: RIGHT_ROUTE_JOURNAL,
  [ROUTE_STORAGES]: RIGHT_ROUTE_STORAGES,
  [ROUTE_EVENTS]: RIGHT_ROUTE_EVENTS,
  [ROUTE_REPORTS]: RIGHT_ROUTE_REPORTS,
  [ROUTE_PASSPORT]: RIGHT_PASSPORT_INFO,
  [ROUTE_PASSPORT_TAB]: RIGHT_PASSPORT_INFO,
  [ROUTE_NETWORK_MODELS]: RIGHT_NETWORK_MODELS,
  [ROUTE_LIBRARY_DOCS]: RIGHT_LIBRARY_DOCS,
  [ROUTE_CHECK_SETPOINTS]: RIGHT_ROUTE_CHECK_SETPOINTS,
  [ROUTE_RESERV]: RIGHT_ROUTE_RESERV,
  [ROUTE_DISTRIBUTION]: RIGHT_ROUTE_DISTRIBUTION
};

// Roles
export const ROLE_KEY_ADMIN_IA = 'ADMINISTRATORIA';
export const ROLE_KEY_ADMIN_ODU = 'ADMINISTRATORFILIALAODU';
export const ROLE_KEY_ADMIN_RDU = 'ADMINISTRATORFILIALARDU';
export const ROLE_KEY_ADMIN_TECH = 'ADMINISTRATORTEHNOLOG';
export const ROLE_KEY_TECH = 'TEHNOLOG';
export const ROLE_KEY_GUEST = 'GOST';

export const MODE_READONLY = 'readonly';
export const MODE_EDIT = 'edit';
export const MODE_NO_ACCESS = null;
export const MODE_RIGHTS = 'rights';
export const MODE_EDIT_TIME = 'edit_time';

export const LIBRARY_LOCAL = 'Local';
export const LIBRARY_GLOBAL = 'Global';

// Equipment types
const EQUIPMENT_TYPE_NO_EQUIPMENT = 'no_equipment';
const EQUIPMENT_TYPE_LINES = 'lines';
const EQUIPMENT_TYPE_BUSES = 'buses';
const EQUIPMENT_TYPE_BREAKERS = 'breakers';
const EQUIPMENT_TYPE_GENERATORS = 'generators';
const EQUIPMENT_TYPE_TRANSFORMERS = 'transformers';
const EQUIPMENT_TYPE_TRANSFORMER_WINDINGS = 'transformer_windings';

export const LIMIT = 10;
export const PAGE_CHANGER_STEPS = ['10', '20', '30', '50', '100'];
export const MAX_FILE_SIZE = 5;
export const KB = 1024;
export const RANGE_MAX_VALUE = 9999999;
export const TABLE_SIZE = 'small';
export const MAX_TAGS_CNT = 3;
export const STORAGE_WARNINGS = {
  uptime: 30,
  certificate: 11
}

export const STORAGE_WARNINGS_CAUTION_OFFSET = 7;

export const STORAGE_USE_CHUNK = process.env.STORAGE_USE_CHUNK || false;
export const CHUNK_SIZE_KB = process.env.CHUNK_SIZE_KB || 20 * KB;
export const CHUNK_DELAY_IN_SECONDS = process.env.CHUNK_DELAY_IN_SECONDS || 15;
export const CHUNK_MAX_RESENT_COUNT = process.env.CHUNK_MAX_RESENT_COUNT || 4;
export const REDIRECT_URL_NEW_FRONT = process.env.REDIRECT_URL || 'https://ia-issrza-web-t.cdu.so:3000';

export const SOFTWARE_VERSION = '2.8.0';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const WS_RESULT_COUNT = 'count_new';
export const WS_TASKDC_START = 'tdc_ready';
export const WS_RUN_ENDPOINT_MSG = 'run_endpoint';

export {
  APPSTAGE_INITIAL,
  APPSTAGE_AUTH,
  APPSTAGE_SIGNIN,
  APPSTAGE_INITFAIL,
  AUTH_COOKIE_NAME,
  EQUIPMENT_TYPE_NO_EQUIPMENT,
  EQUIPMENT_TYPE_LINES,
  EQUIPMENT_TYPE_BUSES,
  EQUIPMENT_TYPE_BREAKERS,
  EQUIPMENT_TYPE_GENERATORS,
  EQUIPMENT_TYPE_TRANSFORMERS,
  EQUIPMENT_TYPE_TRANSFORMER_WINDINGS,
};
