import React, { Component } from 'react';

interface State {
  loadTaskDocuments?: () => void;
}

const PassportContext = React.createContext({});

class PassportContextProvider extends Component<unknown, State> {
  state: State = {
    loadTaskDocuments: undefined,
  };

  setLoadTaskDocumentsCallback = (callback: () => void) => {
    this.setState({ loadTaskDocuments: callback });
  };

  render() {
    const { loadTaskDocuments } = this.state;

    return (
      <PassportContext.Provider
        value={{
          loadTaskDocuments,
          setLoadTaskDocumentsCallback: this.setLoadTaskDocumentsCallback,
        }}
      >
        {this.props.children}
      </PassportContext.Provider>
    );
  }
}

const PassportContextConsumer = PassportContext.Consumer;

export { PassportContextProvider, PassportContextConsumer, PassportContext };
