import { APPSTAGE_INITIAL } from '@globalConstants';
import { APP_STAGE, appStageActionTypes } from './types';

const initialState = APPSTAGE_INITIAL;

function appStageReducer(state = initialState, action: appStageActionTypes) {
  switch (action.type) {
    case APP_STAGE:
      return action.stage;
    default:
      return state;
  }
}

export default appStageReducer;
