export const GET_ACTIONS_LOG = 'GET_ACTIONS_LOG';
export const GET_MESSAGES_LOG = 'GET_MESSAGES_LOG';

export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_FILTERS_ACTIONS = 'SET_FILTERS_ACTIONS';
export const SET_FILTERS_MESSAGES = 'SET_FILTERS_MESSAGES';

export const GET_LIMIT = 'GET_LIMIT';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_PAGE = 'SET_PAGE';

export const CLEAR_JOURNAL_STATE = 'CLEAR_JOURNAL_STATE';
