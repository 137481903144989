import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Typography, Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { PopoverEllipsis } from '@ui/index';
import { sleep } from '@app/helpers';
import {
  ROUTE_ROOT,
  ROUTE_404,
  ROUTE_403,
  ROUTE_EXTERNAL_LINK,
  ROUTE_FILE_ERROR,
} from '@globalConstants';
import styles from './styles.module.less';

const { Title } = Typography;

class UndefinedPage extends Component {
  goBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  openAllLinks = async (links) => {
    for (const x of links) {
      const newWindow = await window.open(x, '_blank', 'noopener, noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
      await sleep(500);
    }
  }

  render() {
    const { t, location } = this.props;

    return (
      <div className={styles.content}>
        <Title level={2} className={styles.level2}>
          {(() => {
            switch (location.pathname) {
              case ROUTE_404:
                return t('UNDEFINED_PAGE');
              case ROUTE_403:
                return t('NO_RIGHTS_PAGE');
              case ROUTE_FILE_ERROR:
                return t('FILE_ERROR_PAGE')
              case ROUTE_EXTERNAL_LINK:
                if (
                  location.state
                  && (
                    (
                      location.state.multiple
                      && location.state.link
                      && Array.isArray(location.state.link)
                    )
                    || (
                      location.state.link
                      && Array.isArray(location.state.link)
                    )
                  )
                ) {
                  return (
                    <div className={styles.msg_links}>
                      <h3>{t('EXTERNAL_LINKS_MSG')}</h3>
                      <div className={styles.msg_links_scroll}>
                        <div className={styles.msg_links_items}>
                          {
                            location.state.link.map((x) => (
                              <div key={x} className={styles.link_wrapper}>
                                <PopoverEllipsis
                                  content={x}
                                  placement='top'
                                >
                                  <LinkOutlined className={styles.icon_system_doc} />
                                  <a
                                    href={x}
                                    target="_blank"
                                    rel='noreferrer noopener'
                                  >
                                    {x}
                                  </a>
                                </PopoverEllipsis>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <Button
                        type='primary'
                        onClick={() => this.openAllLinks(location.state.link)}
                      >
                        {t('OPEN_ALL_LINKS')}
                      </Button>
                      <Button
                        onClick={this.goBack}
                      >
                        {t('GO_BACK')}
                      </Button>
                    </div>
                  );
                }
                return (
                  <div className={styles.msg}>
                    <p>{t('EXTERNAL_LINK_MSG')}</p>
                    <p>
                      <a
                        className={styles.url}
                        href={location.state && location.state.link}
                        target="_blank"
                        rel='noreferrer noopener'
                        onClick={this.goBack}
                      >
                        {location.state && location.state.link}
                      </a>
                    </p>
                    <Button
                      type='primary'
                    >
                      <a
                        href={location.state && location.state.link}
                        target="_blank"
                        rel='noreferrer noopener'
                        onClick={this.goBack}
                      >
                        {t('GO')}
                      </a>
                    </Button>
                    <Button
                      onClick={this.goBack}
                      className='bordered'
                    >
                      {t('CANCEL')}
                    </Button>
                  </div>
                );
              default:
                return t('UNDEFINED_PAGE');
            }
          })()}
        </Title>
        <Title level={3} className={styles.level3}>
          <Link to={ROUTE_ROOT}>{t('TO_HOME')}</Link>
        </Title>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(
    UndefinedPage
  )
);
