import React from 'react';
import { Tag, Popover } from 'antd';

const CustomTag = (props) => {
  const { children, disablePopover, disableEllipsis, getPopupContainer, placement, maxChars = 15, ...restProps  } = props;

  const isNeedPopover = typeof children === 'string' && children.length > maxChars;

  return isNeedPopover && !disablePopover ? (
    <Popover
      content={children}
      overlayClassName='custom-tag enable-scroll-popover'
      overlayStyle={{ zIndex: 1090 }}
      getPopupContainer={(triggerNode) => (getPopupContainer ? getPopupContainer(triggerNode) : triggerNode)}
      placement={placement}
    >
      <Tag {...restProps}>{disableEllipsis ? children : `${children.substr(0, maxChars)}...`}</Tag>
    </Popover>
  ) : (
    <Tag {...restProps}>{children}</Tag>
  );
};

export default CustomTag;
