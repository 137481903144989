import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  Input,
  AutoComplete,
  Button,
  Popover,
  message,
  Tooltip,
} from 'antd';
import { SearchOutlined, SaveOutlined, ProfileOutlined } from '@ant-design/icons/lib/icons';

import {
  ROUTE_SEARCH, /* ROLE_KEY_ADMIN_IA, ROLE_KEY_ADMIN_ODU, ROLE_KEY_ADMIN_RDU, Временное решение для задачи DBRZA-2667 */
} from '@globalConstants';
import api from '@services/api';
import {
  applyGlobalSearchParams,
  setupGlobalSearchReload,
  setupGlobalSearchString,
} from '@state/search/actions';
import { escapeRegExp } from '@globalHelpers';
import SavedQueries from './savedQueries';

import styles from './styles.module.less';

class Search extends Component {
  autoCompleteRef = React.createRef();

  loadData = debounce(async (value) => {
    const { status, data } = await api.searchHints(value);
    let dataSource = [];

    if (status === 200) {
      const searchRegExp = value ? new RegExp(`(${escapeRegExp(value.trim())})`, 'i') : '';
      dataSource = data.results.map((record, index) => {
        const item = record.name;
        const parts = item.split(searchRegExp);

        for (let i = 1; i < parts.length; i += 2) {
          parts[i] = (
            <span key={i} className={styles.autocompleteBold}>
              {parts[i]}
            </span>
          );
        }

        return {
          key: index,
          value: item,
          label: (
            <div key={`suggestion_${item}`}>
              <SearchOutlined className={styles.autocompleteSearch} />
              {parts}
            </div>
          ),
        };
      });
    }

    this.setState({
      dataSource: dataSource,
    });
  }, 800);

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      historyDataSource: [],
      value: props.searchValue,
      filterValue: '',
      isVisibleSaveDialog: false,
      isVisibleLoadDialog: false,
      isReloadQueryList: false,
      saveName: '',
      isSaving: false
    };
  }

  componentDidMount() {
    this.getHistoryData();
  }

  componentDidUpdate(prevProps) {
    const { searchValue, setGlobalSearchReload } = this.props;

    if (prevProps.searchValue !== searchValue) {
      this.setState({
        value: searchValue,
      });
    }

    setGlobalSearchReload(false);
  }

  getHistoryData = async (search) => {
    const historyDataSource = [];

    const { status, data } = await api.searchHistory(search);

    if ([200, 201].includes(status)) {
      data.results.forEach((value) => {
        historyDataSource.push({
          key: `history_${value}`,
          value: value,
          label: (
            <div key={`history_${value}`}>
              {value}
            </div>
          ),
        });
      });
    }

    this.setState({
      historyDataSource,
    });
  };

  handlePrepareDataSource = async (value) => {
    const { setApplyParams } = this.props;

    setApplyParams(false);

    this.setState({
      value,
    });

    await this.loadData(value);
  };

  onSearch = (value) => {
    const { history, setSearchString, setApplyParams, location, setGlobalSearchReload } =
      this.props;

    const path = (location && location.pathname && location.pathname.indexOf('/search') !== -1)
      ? location.pathname
      : '/search';

    setGlobalSearchReload(true);
    this.getHistoryData(value);
    setSearchString(value);
    setApplyParams(true);
    history.push(path);
  };

  onSearchIcon = (e) => {
    const { value } = this.state;

    e.stopPropagation();
    e.preventDefault();
    if (this.autoCompleteRef.current) {
      this.autoCompleteRef.current.blur();
    }
    this.onSearch(value);
  };

  handleKeyDown = (e) => {
    const { value } = this.state;
    if (e.key === 'Enter') {
      this.onSearch(value);
    }
  };

  onSaveQuery = async () => {
    const {
      userId, searchValue, searchFilter, t,
    } = this.props;
    const { saveName } = this.state;

    this.setState({
      isSaving: true
    })
    const params = [];

    if (searchValue) {
      params.push(`query=${encodeURI(searchValue)}`);
    }

    if (searchFilter) {
      params.push(`filter=${searchFilter}`);
    }
    let url = `${window.location.protocol}//${window.location.host}${ROUTE_SEARCH}`;
    if (params.length) {
      url = `${url}?${params.join('&')}`;
    }

    const sendData = {
      name: saveName,
      url: encodeURI(url),
      user: userId,
    };

    const { status } = await api.saveSearchQuery(sendData);

    if ([200, 201].includes(status)) {
      message.success(t('SEARCH_QUERY_SAVE_OK'));
    } else {
      message.error(t('SEARCH_QUERY_SAVE_ERROR'));
    }

    this.setState({
      saveName: '',
      isVisibleSaveDialog: false,
      isReloadQueryList: true,
      isSaving: false
    });
  }

  render() {
    // const { t, applySearch, userInfo } = this.props; Временное решение для задачи DBRZA-2667
    const { t, applySearch } = this.props;
    const {
      dataSource,
      historyDataSource,
      value,
      filterValue,
      isVisibleSaveDialog,
      isVisibleLoadDialog,
      isReloadQueryList,
      saveName,
      isSaving
    } = this.state;

    const searchKey = value
      ? [{
        key: `current_${value}`,
        value,
        label: (
          <div key={`current_${value}`}>
            {value}
          </div>
        ),
      }]
      : [];
    const hints = [...searchKey, ...dataSource];
    const historyDataSourceForSearch = historyDataSource.filter((item) => item.key !== `history_${value}`);
    /* Временное решение для задачи DBRZA-2667
    const isAdminNotAllowed = [
      ROLE_KEY_ADMIN_IA,
      ROLE_KEY_ADMIN_ODU,
      ROLE_KEY_ADMIN_RDU,
    ].includes(userInfo.role.key);
    */

    return (
      <>
        <div className={styles.topBarSearch}>
          <AutoComplete
            ref={this.autoCompleteRef}
            className={styles.autocomplete}
            placeholder={t('TOP_BAR_SEARCH_INPUT_PLACEHOLDER')}
            options={[
              {
                label: t('TOP_BAR_SEARCH_HINTS'),
                options: hints,
              },
              {
                label: t('TOP_BAR_SEARCH_HISTORY'),
                options: historyDataSourceForSearch,
              },
            ]}
            value={value}
            onSelect={this.onSearch}
            onChange={this.handlePrepareDataSource}
            defaultActiveFirstOption={false}
            dropdownClassName={styles.customDropDown}
            // disabled={isAdminNotAllowed} Временное решение для задачи DBRZA-2667
          >
            <Input
              onKeyDown={this.handleKeyDown}
              suffix={(
                <SearchOutlined
                  style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.45)' }}
                  onClick={
                    // isAdminNotAllowed ? null : (e) => this.onSearchIcon(e) Временное решение для задачи DBRZA-2667
                    (e) => this.onSearchIcon(e) // Временное решение для задачи DBRZA-2667
                  }
                />
              )}
            />
          </AutoComplete>
          <div className={styles.saveLoadPanel}>
            <span className={styles.save}>
              <Popover
                // visible={isAdminNotAllowed ? false : isVisibleSaveDialog} Временное решение для задачи DBRZA-2667
                visible={isVisibleSaveDialog} // Временное решение для задачи DBRZA-2667
                trigger='click'
                onVisibleChange={(visibility) => this.setState({ isVisibleSaveDialog: visibility })}
                content={(
                  <div className={styles.saveBlock}>
                    <div className={styles.searchQueryName}>
                      <label htmlFor='search_query_name'>
                        {t('SEARCH_QUERY_NAME')}
                      </label>
                      <Input
                        id='search_query_name'
                        maxLength={255}
                        autoFocus={true}
                        value={saveName}
                        onChange={(e) => this.setState({ saveName: e.target.value })}
                        onPressEnter={this.onSaveQuery}
                      />
                    </div>
                    <div className={styles.searchQuerySaveButton}>
                      <Button
                        loading={isSaving}
                        type='primary'
                        size='small'
                        disabled={!saveName || !saveName.trim().length || !applySearch}
                        onClick={this.onSaveQuery}
                      >
                        {t('SEARCH_QUERY_SAVE')}
                        {
                          !saveName || !saveName.trim().length || !applySearch
                            ? <sup>*</sup>
                            : ''
                        }
                      </Button>
                    </div>
                    <div className={
                      !saveName || !saveName.trim().length || !applySearch
                        ? styles.searchQuerySaveButtonError
                        : 'hidden'
                      }
                    >
                      <span className={styles.star}>*</span>
                      {
                        !applySearch
                          ? ((!value || !value.length) && (!filterValue || !filterValue.length)
                            ? t('SEARCH_QUERY_PARAMS_EMPTY')
                            : t('SEARCH_QUERY_APPLY_ERROR')
                          )
                          : (!saveName || !saveName.trim().length
                            ? t('SEARCH_QUERY_NAME_LENGTH_ERROR')
                            : ''
                          )
                      }
                    </div>
                  </div>
                )}
              >
                <Tooltip title={t('SEARCH_BAR_SAVE_QUERY')}>
                  <Button
                    icon={<SaveOutlined />}
                    // disabled={isAdminNotAllowed} Временное решение для задачи DBRZA-2667
                    onClick={(e) => {
                      e.currentTarget.blur();
                      this.setState((prevState) => ({ isVisibleSaveDialog: !prevState.isVisibleSaveDialog }))
                    }}
                  />
                </Tooltip>
              </Popover>
            </span>
            <span className={styles.load}>
              <Popover
                // visible={isAdminNotAllowed ? false : isVisibleLoadDialog} Временное решение для задачи DBRZA-2667
                visible={isVisibleLoadDialog} // Временное решение для задачи DBRZA-2667
                trigger='click'
                onVisibleChange={(visibility) => this.setState({ isVisibleLoadDialog: visibility })}
                content={(
                  <div className={styles.loadBlock}>
                    <SavedQueries
                      filterValue={filterValue}
                      isReloadQueryList={isReloadQueryList}
                      unsetVisible={() => this.setState({
                        isVisibleLoadDialog: false,
                      })}
                      unsetReloadQueryList={() => this.setState({
                        isReloadQueryList: false,
                      })}
                    />
                  </div>
                )}
                title={(
                  <div className={styles.filter}>
                    <label htmlFor='filter'>
                      {t('SEARCH_BAR_FILTER_QUERY')}
                    </label>
                    <Input
                      id='filter'
                      size='small'
                      value={filterValue}
                      onChange={(x) => this.setState({ filterValue: x.target.value })}
                    />
                  </div>
                )}
              >
                <Tooltip title={t('SEARCH_BAR_OPEN_QUERY')}>
                  <Button
                    icon={<ProfileOutlined />}
                    // disabled={isAdminNotAllowed} Временное решение для задачи DBRZA-2667
                    onClick={() => (
                      this.setState((prevState) => ({ isVisibleLoadDialog: !prevState.isVisibleLoadDialog }))
                    )}
                  />
                </Tooltip>
              </Popover>
            </span>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  searchValue: state.search.searchValue,
  searchFilter: state.search.searchFilter,
  applySearch: state.search.applySearch,
  userId: state.user.user_id,
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  setApplyParams: applyGlobalSearchParams,
  setSearchString: setupGlobalSearchString,
  setGlobalSearchReload: setupGlobalSearchReload,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(
      Search
    )
  )
);
