import React, { Component, ReactText } from 'react';
import api from '@services/api';
import {
  message, Table, Select,
} from 'antd';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';
import clone from 'lodash/cloneDeep';
import without from 'lodash/without';

import { CustomSelect, SidebarCard } from '@ui';

import styles from './styles.module.less';

const { Option } = Select;

type Term = { group: string; items: { id: string }[] };

type Param = {
  id: string;
  name: string;
  terms: Term[];
};

type TermsAssoc = {
  [name: string]: {
    [group: string]: string[];
  };
};

interface Props {
  setDisabledState: (arg?: boolean) => void;
  blank: string;
  t: (arg: string) => string;
  isExecuting: boolean;
  setpointValueId: string;
  functionId: string;
  setpointId: string;
  groupsCount: number;
}

interface State {
  isLoading: boolean;
  selectedTermsIds: TermsAssoc;
  initialTerms: TermsAssoc;
  params: Param[];
  selectedParam: string;
  selectedGroup: string;
}

class Terms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedTermsIds: {},
      initialTerms: {},
      params: [],
      selectedParam: '',
      selectedGroup: '1',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {
      setDisabledState, blank, functionId, setpointId,
    } = this.props;

    this.setState({ isLoading: true });
    setDisabledState();
    const { status, data } = await api.getNonperformanceParams({ blank });
    const embededVals = await api.v1_getSetPointsWithValues({ blank, func: functionId });
    this.setState({ isLoading: false });

    if (status === 200) {
      const selectedTermsIds: TermsAssoc = {};

      if (embededVals.status === 200 && embededVals.data && embededVals.data.results) {
        const embededVal: {
          id: string;
          value: { terms: Term[]; groups_count: number };
        } = embededVals.data.results?.find(
          (item: { id: string }) => item.id === setpointId
        );

        data.results.forEach((term: { id: string; terms: { id: string }[] }) => {
          selectedTermsIds[term.id] = {};
        });

        embededVal?.value?.terms.forEach((item: Term) => {
          const itemsIds = item.items?.map(({ id }) => id);

          data.results.forEach((term: { id: string; terms: { id: string }[] }) => {
            const termsIds = term.terms?.map((t: { id: string }) => t.id);

            if (itemsIds && itemsIds[0] && termsIds.includes(itemsIds[0])) {
              selectedTermsIds[term.id][item.group] = intersection(itemsIds, termsIds);
            }
          });
        });
      }

      this.setState({ params: data.results, selectedTermsIds, initialTerms: { ...selectedTermsIds } });
    }
  };

  onChangeSelect = (selectedParam: string) => {
    this.setState({ selectedParam: selectedParam as string });
  };

  onChangeGroup = (selectedGroup: string) => {
    this.setState({ selectedGroup: selectedGroup as string });
  };

  onTermChange = (selectedIds: ReactText[]) => {
    const { setDisabledState } = this.props;
    const {
      initialTerms, selectedTermsIds, selectedParam, selectedGroup,
    } = this.state;
    const newSelectedTerms = clone(selectedTermsIds);
    newSelectedTerms[selectedParam][selectedGroup] = [...selectedIds as string[]];

    if (!isEqual(newSelectedTerms, initialTerms)) {
      setDisabledState(false);
    } else {
      setDisabledState();
    }

    this.setState({ selectedTermsIds: newSelectedTerms });
  };

  saveTerms = async () => {
    const { setpointValueId, t } = this.props;
    const {
      selectedTermsIds, initialTerms,
    } = this.state;

    this.setState({ isLoading: true });
    const newInitialTerms = clone(selectedTermsIds);

    const requests: Promise<{ status: number }>[] = [];
    Object.keys(newInitialTerms).forEach((term: string) => {
      Object.keys(newInitialTerms[term]).forEach((group) => {
        newInitialTerms[term][group].forEach((item) => {
          if (
            !initialTerms
            || !initialTerms[term]
            || !initialTerms[term][group]
            || !initialTerms[term][group].includes(item)
          ) {
            requests.push(api.bindTerm({ term: item, group, setpoint_value: setpointValueId }));
          }
        });

        const toUnbind = without(initialTerms[term][group], ...newInitialTerms[term][group]);
        if (toUnbind && toUnbind.length) {
          requests.push(api.unbindTerm(setpointValueId, { group, items: toUnbind }));
        }
      });
    });
    const responses = await Promise.all(requests);
    this.setState({ isLoading: false });

    if (responses.every((response) => [201, 204].includes(response.status))) {
      message.success(t('TERMS_ADD_SUCCESS'));
      await this.loadData();
      this.setState({ initialTerms: newInitialTerms });
    } else {
      message.error(t('TERMS_ADD_ERROR'));
    }
  };

  render() {
    const { t, groupsCount, isExecuting } = this.props;
    const {
      params, isLoading, selectedTermsIds, selectedParam, selectedGroup,
    } = this.state;

    const currentParam: Param | undefined = params.find((param: Param) => param.id === selectedParam);

    return (
      <>
        <SidebarCard>
          <SidebarCard.content>
            <SidebarCard.parameter
              label={t('SHOW')}
            >
              <CustomSelect
                className={styles.termSelect}
                disabled={isExecuting}
                onChange={this.onChangeSelect}
                filterOption={false}
                getPopupContainer={(triggerNode: { parentNode: {} }) => triggerNode.parentNode}
                placeholder={t('SELECT_TERM')}
              >
                {params.map((opt) => (
                  <Option key={opt.id} value={opt.id}>
                    {t(opt.name)}
                  </Option>
                ))}
              </CustomSelect>
            </SidebarCard.parameter>
            <SidebarCard.parameter
              label={t('SELECT_GROUP')}
            >
              <CustomSelect
                className={styles.termSelect}
                disabled={isExecuting || !groupsCount || !selectedParam}
                onChange={this.onChangeGroup}
                defaultValue={selectedGroup}
                filterOption={false}
                getPopupContainer={(triggerNode: { parentNode: {} }) => triggerNode.parentNode}
                placeholder={t('SELECT_GROUP')}
              >
                {groupsCount && new Array(groupsCount).fill(0).map((key, group) => (
                  <Option key={`GROUP_${group} `} value={`${group + 1}`}>
                    {`${t('GROUP')} ${group + 1} `}
                  </Option>
                ))}
              </CustomSelect>
            </SidebarCard.parameter>
          </SidebarCard.content>
        </SidebarCard>
        <SidebarCard>
          <Table
            size='small'
            rowKey='id'
            className={styles.termsTable}
            columns={[
              {
                dataIndex: 'key',
                key: 'id',
                width: 20,
                render: (text, record, index) => (<span className={styles.index}>{index + 1}.</span>),
              },
              {
                dataIndex: 'name',
                key: 'name',
                render: (text) => text || '',
              },
            ]}
            dataSource={currentParam?.terms}
            loading={isLoading || isExecuting}
            pagination={false}
            showHeader={false}
            rowSelection={{
              type: 'checkbox',
              hideSelectAll: true,
              selectedRowKeys: (selectedTermsIds
                && selectedTermsIds[selectedParam]
                && selectedTermsIds[selectedParam][selectedGroup]) || [],
              onChange: this.onTermChange,
            }}
          />
        </SidebarCard>
      </>
    );
  }
}

export default Terms;
