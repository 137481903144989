import React from 'react';
import {
  Checkbox, message,
} from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { cloneDeep, isEqual } from 'lodash';
import api from '@services/api';

import store from '@state/store';
import { SET_USER_EXTRA_INFO } from '@state/user/types';

import styles from './styles.module.less';
import { RIGHT_ROUTE_STORAGES } from '@globalConstants';

class NotificationsSettings extends React.Component {
  state = {
    settings: {},
  };

  componentDidMount() {
    this.loadSettings();
  }

  componentDidUpdate(prevProps) {
    const { userId, userInfo } = this.props;

    if (!isEqual(prevProps.userId, userId) || !isEqual(prevProps.userInfo, userInfo)) {
      this.loadSettings();
    }
  }

  loadSettings = () => {
    const { userInfo } = this.props;

    if (userInfo) {
      this.setState({
        settings: {
          ...userInfo.notify_settings,
          send_email: (userInfo.send_email || false),
        },
      });
    }
  }

  saveSettings = async (id, value) => {
    const { userId, t } = this.props;
    const { settings } = this.state;

    const newSettings = cloneDeep(settings);
    newSettings[id] = value;

    const newData = {};
    if (id === 'send_email') {
      newData[id] = value;
    } else {
      newData.notify_settings = {};
      newData.notify_settings[id] = value;
    }

    const { status, data } = await api.updateUser(userId, newData);

    if (status === 200) {
      this.setState({
        settings: newSettings,
      });
      store.dispatch({
        type: SET_USER_EXTRA_INFO,
        data,
      });
    } else {
      message.error(t('NOTIFICATION_API_SAVE_ERROR'));
    }
  }

  renderSetting = (id) => {
    const { t } = this.props;
    const { settings } = this.state;

    const value = settings && settings[id];

    return (
      <div
        key={id}
        className={styles.item}
      >
        <Checkbox
          checked={value === true}
          onChange={() => this.saveSettings(id, !value)}
        >
          {t(`NOTIFICATION_SETTINGS_${id.toUpperCase()}`)}
        </Checkbox>
      </div>
    );
  }

  renderStorageInfoSetting = () => {
    const { rights } = this.props;

    if (!rights[RIGHT_ROUTE_STORAGES]) return null;
    return <>
      <hr />
      {this.renderSetting('storage_info')}
    </>
  }

  render() {
    const { visible, t } = this.props;
    const { settings } = this.state;

    const keys = (settings && Object.keys(settings).filter((x) => x !== 'send_email' && x !== 'storage_info')).sort() || [];

    return (
      visible && (
        <div className={styles.notificationsSettings}>
          <div className={styles.list}>
            <h3>{t('NOTIFICATION_SETTINGS_TITLE')}</h3>
            {keys.map((x) => this.renderSetting(x))}
            <hr />
            {this.renderSetting('send_email')}
            {this.renderStorageInfoSetting()}
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.user_id,
  userInfo: state.user.info,
  rights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    NotificationsSettings
  )
);
