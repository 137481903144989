import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withPagination } from '@hoc';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import storage from '@services/storage';
import {
  Empty, Button, Tooltip, message,
} from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';

import api from '@services/api';
import store from '@state/store';
import { removeFromBucketLocally, addToBucketLocally } from '@state/bucket/actions';
import { openSidebar, closeSidebar } from '@state/sidebar/actions';
import { SEARCH_FILTER } from '@common/sidebarRoot/types';
import { CustomTag, PopoverEllipsis, PopoverTags } from '@ui';

import { TABLE_SIZE } from '@globalConstants';
import { sleep, textHighlighter } from '@globalHelpers';

import { cloneDeep } from 'lodash';

import { prefix as tabDevicesPrefix } from '../tabs/devices';
// import { prefix as tabDocumentsPrefix } from '../tabs/documents';
// import { prefix as tabTasksPrefix } from '../tabs/tasks';

export default function AbstractTab(WrappedComponent) {
  class AbstractTabClass extends Component {
    state = {
      filters: [],
      count: 0,
      isLoading: false,
      data: [],
      filterValues: {},
      filterValuesForRequest: {},
      countFiltersWithValues: 0,
      isActiveResetTableBtn: false,
    };

    componentDidUpdate(prevProps, prevState) {
      const {
        isActive,
        searchValue,
        id,
        getValuesForRequest,
        tabFilters,
        archived,
        page,
        changePage,
        searchFilterLoading,
        prefix,
        searchReload,
      } = this.props;

      const { filters, isLoading } = this.state;

      let currentFilters = cloneDeep(filters);

      if (!isEqual(tabFilters, prevProps.tabFilters)) {
        storage.set(`columnsValues__Global_Prefix__${prefix}`, tabFilters);
        currentFilters = this.setFilters();
        this.isVisibilityChanged();
      }

      if (currentFilters && currentFilters.length) {
        let isReload = false;
        let newPage = 1;

        const newState = {
          isLoading: true,
        };

        if (searchValue !== prevProps.searchValue || prevProps.archived !== archived || searchReload) {
          newPage = 1;
          isReload = true;
        }

        if (prevProps.id !== id) {
          isReload = true;
        }

        if (
          !isLoading &&
          (!isEqual(prevState.filters, currentFilters) ||
          !isEqual(prevProps.tabFilters, tabFilters) ||
            isActive !== prevProps.isActive)
        ) {
          const newFilterValues = currentFilters.reduce((obj, item) => {
            // eslint-disable-next-line no-param-reassign
            obj[item.name] = {
              visible: item.visible ? 1 : 0,
              value: item.value || null,
            };
            return obj;
          }, {});

          newState.filterValues = newFilterValues;
          newState.countFiltersWithValues = this.getCountFiltersWithValues(newFilterValues);
          newState.filterValuesForRequest = getValuesForRequest(newFilterValues);
          isReload = true;
        }

        if (isReload || searchReload) {
          this.setState(
            newState,
            () => {
              if (!searchFilterLoading) {
                if (newPage !== page) {
                  changePage(newPage, () => this.loadSearchData(id));
                } else {
                  this.loadSearchData(id);
                }
              }
            }
          );
        }
      }
    }

    handleActivateResetTableBtn = (val) => {
      this.setState({
        isActiveResetTableBtn: val,
      });
    };

    handleResetTable = async () => {
      await this.resetColWidths();
      await this.resetColVisibility();
      await this.handleActivateResetTableBtn(false);
    };

    isVisibilityChanged = (filterValues, returnDefaultVisibility = false) => {
      const { originFilters, tableName, prefix } = this.props;
      const { filters } = this.state;
      const defaultVisible = {};
      const currentVisible = {};
      const defaultValue = {};
      const currentValue = {};

      const storedValueGlobalFilters = storage.get(`columnsValues__Global_Prefix__${prefix}`) || undefined;

      originFilters && originFilters.forEach((filter) => {
        defaultVisible[filter.name] = filter.visible ? 1 : 0;
        defaultValue[filter.name] = (
          storedValueGlobalFilters && storedValueGlobalFilters[filter.name]
            ? storedValueGlobalFilters[filter.name]
            : undefined
        );
      });

      if (returnDefaultVisibility) {
        return defaultVisible;
      }

      if (filterValues && Object.keys(filterValues).length) {
        Object.keys(filterValues).forEach((key) => {
          currentVisible[key] = filterValues[key].visible;
          currentValue[key] = filterValues[key].value;
        });
      } else {
        filters && filters.forEach((filter) => {
          currentVisible[filter.name] = filter.visible ? 1 : 0;
          currentValue[filter.name] = filter.value;
        });
      }

      if (
        (Object.keys(currentVisible).length && !isEqual(defaultVisible, currentVisible))
        || (Object.keys(currentValue).length && !isEqual(defaultValue, currentValue))
      ) {
        this.handleActivateResetTableBtn(true);
      } else if (!storage.get(`columns_width_${tableName}`)) {
        this.handleActivateResetTableBtn(false);
      }
    };

    onApplyFilter = (changedFilters, changedVisible) => {
      const { filterValues } = this.state;
      const { getValuesForRequest, changeFilters, tableName } = this.props;
      const newFilterValues = {};
      const newFilterValuesForSave = {};
      Object.keys(filterValues).forEach((index) => {
        newFilterValues[index] = { ...filterValues[index] };

        if (changedFilters && changedFilters[index] && changedFilters[index].length > 0) {
          newFilterValues[index].value = changedFilters[index];
          newFilterValuesForSave[index] = changedFilters[index];
        } else {
          newFilterValues[index].value = null;
        }
        if (changedVisible) {
          newFilterValues[index].visible = changedVisible[index] === undefined
            ? newFilterValues[index].visible
            : changedVisible[index];
        }
      });

      changeFilters(newFilterValuesForSave);

      if (changedFilters) {
        storage.set(`columnsValues_${tableName}`, changedFilters);
      }
      if (changedVisible) {
        storage.set(`columnsVisibility_${tableName}`, changedVisible);
      }

      const filterValuesForRequest = getValuesForRequest(newFilterValues);

      this.setState({
        filterValues: newFilterValues,
        filterValuesForRequest: filterValuesForRequest,
        countFiltersWithValues: this.getCountFiltersWithValues(newFilterValues),
      }, () => {
        changedVisible && this.isVisibilityChanged(newFilterValues);
      });
    };

    setInitialColumnsWidthAndVisible = async () => {
      const { tableName, prefix, tabFilters } = this.props;

      const storedValueFilters = storage.get(`columnsValues_${tableName}`);
      if (!storedValueFilters) {
        storage.set(`columnsValues__Global_Prefix__${prefix}`, tabFilters);
      }

      await this.setFilters();

      if (storage.get(`columns_width_${tableName}`)) {
        this.handleActivateResetTableBtn(true);
      }
      if (storedValueFilters || storage.get(`columnsVisibility_${tableName}`)) {
        this.isVisibilityChanged();
      }
    }

    setFilters = () => {
      const {
        tabFilters, originFilters, tableName, searchFilterLoading,
      } = this.props;
      const { filters } = this.state;

      if (searchFilterLoading) {
        return filters;
      };

      const presetSearchFilter = tabFilters || {};
      const storedVisibleFilters = storage.get(`columnsVisibility_${tableName}`);
      const storedValueFilters = storage.get(`columnsValues_${tableName}`);

      const newFilters = cloneDeep(originFilters);
      if (newFilters && Array.isArray(newFilters) && newFilters.length) {
        newFilters.forEach((x, index) => {
          newFilters[index].value = (
            storedValueFilters && storedValueFilters[x.name] !== undefined
              ? storedValueFilters[x.name]
              : (presetSearchFilter && presetSearchFilter[x.name] !== undefined
                ? presetSearchFilter[x.name]
                : undefined
              )
          );
          newFilters[index].visible = (
            storedVisibleFilters && storedVisibleFilters[x.name] !== undefined
              ? storedVisibleFilters[x.name] === 1
              : newFilters[index].visible
          );
        });
      }

      this.setState({
        filters: newFilters,
      });
      return newFilters;
    };

    getCountFiltersWithValues = (filterValues) => {
      let count = 0;
      Object.keys(filterValues).forEach((index) => {
        if (filterValues[index]
          && (
            filterValues[index].value !== null
            && filterValues[index].value !== undefined
            && (!Array.isArray(filterValues[index].value) || filterValues[index].value.length > 0)
            && (!(typeof filterValues[index].value === 'object') || Object.keys(filterValues[index].value).length > 0)
          )
        ) {
          count += 1;
        }
      });
      return count;
    }

    loadSearchData = async (path) => {
      const {
        isActive, id, changeItemsCount, searchValue = '', closeFilter,
        searchDataLoadFunction, page, limit, changePage,
      } = this.props;

      if (!isActive) {
        this.setState({
          isLoading: false,
          count: 0,
          data: [],
        });
        closeFilter();

        return false;
      }

      const { filterValuesForRequest = {} } = this.state;

      this.setState({ isLoading: true });

      const { status, data, page: newPage } = await searchDataLoadFunction(
        path,
        searchValue,
        limit,
        limit * (page - 1),
        filterValuesForRequest
      );

      if (status === 200) {
        this.setState(
          {
            isLoading: false,
            count: data.count,
            data: data.results,
          },
          () => (
            page === newPage
              ? changeItemsCount(id, data.count)
              : changePage(newPage, () => changeItemsCount(id, data.count))
          )
        );
      } else {
        this.setState(
          {
            isLoading: false,
            count: 0,
            data: [],
          },
          () => (
            page === 1
              ? changeItemsCount(id, 0)
              : changePage(1, changeItemsCount(id, 0))
          )
        );
      }

      return undefined;
    };

    toggleFilter = () => {
      const { sidebar, closeFilter, openFilter } = this.props;
      const { filters, filterValues } = this.state;

      if (sidebar.isOpen) {
        closeFilter();
      } else {
        openFilter(
          SEARCH_FILTER,
          {
            filters: filters,
            values: filterValues,
            onApply: this.onApplyFilter,
          }
        );
      }
    };

    updateSideBarState = async () => {
      const state = store.getState();

      // before open
      if (this.sideBarState.isOpen === false && state.sidebar.isOpen === true) {
        await sleep(250); // wait till DOM change
      }

      this.sideBarState = state.sidebar;
    };

    allRecordsAreInBucket = (bucketIdsData) => {
      const { data } = this.state;
      return (data && data.every((x) => bucketIdsData.includes(x.id)));
    };

    onAdd2Bucket = async (e, id) => {
      const { t, bucketIdsData } = this.props;

      e.preventDefault();

      // Если объект уже был выбран до этого, то удаляем его из списка объектов
      if (bucketIdsData.includes(id)) {
        const { status } = await api.clearBucket(id);

        if (status === 200 || status === 204) {
          removeFromBucketLocally(id);
          message.success(t('REMOVE_FROM_BUCKET_MESSAGE'));
        } else {
          message.error(t('REMOVE_FROM_BUCKET_FAIL_MESSAGE'));
        }

        return;
      }

      const { status } = await api.addProtectionDevice2Bucket(id);

      if (status === 200) {
        addToBucketLocally(id);
        message.success(t('ADD2BUCKET_MESSAGE'));
      } else {
        message.error(t('ADD2BUCKET_FAIL_MESSAGE'));
      }
    };

    onAddAllOnPage2Bucket = async (e) => {
      e.preventDefault();

      const { t, bucketIdsData } = this.props;
      const { data } = this.state;

      if (this.allRecordsAreInBucket(bucketIdsData)) {
        const newBucketDataOnPage = data.map((x) => x.id);
        const { status } = await api.clearBucket(newBucketDataOnPage);

        if (status === 200 || status === 204) {
          removeFromBucketLocally(newBucketDataOnPage);
          message.success(`${newBucketDataOnPage.length} ${t('REMOVE_ALL_ON_PAGE_FROM_BUCKET_MESSAGE')}`);
        } else {
          message.error(t('REMOVE_FROM_BUCKET_FAIL_MESSAGE'));
        }

        return;
      }

      const newBucketDataOnPage = data.filter((x) => !bucketIdsData.includes(x.id)).map((x) => x.id);
      const { status } = await api.addProtectionDevices2Bucket(newBucketDataOnPage);

      if (status === 200) {
        addToBucketLocally(newBucketDataOnPage);
        message.success(`${newBucketDataOnPage.length} ${t('ADD_ALL_ON_PAGE_TO_BUCKET_MESSAGE')}`);
      } else {
        message.error(t('ADD2BUCKET_FAIL_MESSAGE'));
      }
    };

    getListProps = () => {
      const { count } = this.state;
      const {
        t, page, limit, id, changePage,
      } = this.props;

      return {
        locale: {
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{t('NOT_FOUND_BY_PARAMETERS')}</span>}
            />
          ),
        },
        pagination: {
          onChange: (newPage) => changePage(newPage, () => this.loadSearchData(id)),
          total: count,
          pageSize: limit,
          current: page,
        },
        size: TABLE_SIZE,
      };
    };

    getColumns = (tabPrefix = '') => {
      const { filters, filterValues } = this.state;
      const {
        t, searchValue, bucketIdsData, openFilter,
      } = this.props;
      const columns = [];

      filters.forEach((item) => {
        if (filterValues[item.name] && filterValues[item.name].visible) {
          const name = item.field.indexOf('.') > 0 ? item.field.substr(0, item.field.indexOf('.')) : item.field;
          const fieldKeys = item.field.split('.');
          const columnParams = {
            title: t(item.name),
            dataIndex: fieldKeys,
            key: (fieldKeys.length > 1) ? fieldKeys[fieldKeys.length - 1] : name,
            width: item.width,
            fixed: item.fixed,
            initialWidth: item.initialWidth,
            field: item.field,
            className: item.className,
          };
          columnParams.render = (text, record) => {
            if (item.render) {
              return item.render(text, record);
            }

            let result = '';

            if (text === null) {
              return '';
            } else if (Array.isArray(text)) {
              const content = text.map((x) => {
                const highlightString = x['equipment']
                  ? textHighlighter(searchValue, `${x.name} (${x.equipment})`, true)
                  : textHighlighter(searchValue, x.name, true);

                const tag = item['link']
                  ? (
                    <CustomTag
                      maxChars={25}
                      className='link'
                      key={x.id}
                      disablePopover={text.length > 1}
                      getPopupContainer={
                        (triggerNode) => (
                          item.getPopupContainer && typeof item.getPopupContainer === 'function'
                            ? item.getPopupContainer(triggerNode)
                            : triggerNode
                        )
                      }
                    >
                      <Link to={item.link(x.id)}>{highlightString}</Link>
                    </CustomTag>
                  )
                  : (
                    <CustomTag
                      maxChars={25}
                      style={{ marginBottom: 5 }}
                      key={x.id}
                      disablePopover={text.length > 1}
                      getPopupContainer={
                        (triggerNode) => (
                          item.getPopupContainer && typeof item.getPopupContainer === 'function'
                            ? item.getPopupContainer(triggerNode)
                            : triggerNode
                        )
                      }
                    >
                      {highlightString}
                    </CustomTag>
                  );

                if (item['customRender']) {
                  return item.customRender(record, tag, x.id, openFilter, this);
                } else {
                  return tag;
                }
              });

              return (
                <PopoverTags
                  size={text.length}
                  getPopupContainer={
                    (triggerNode) => (
                      item.getPopupContainer && typeof item.getPopupContainer === 'function'
                        ? item.getPopupContainer(triggerNode)
                        : triggerNode.closest('td')
                    )
                  }
                >
                  {content}
                </PopoverTags>
              );
            } else if (typeof text === 'object' && text['name']) {
              result = text['equipment']
                ? textHighlighter(searchValue, `${text.name} (${text.equipment})`)
                : textHighlighter(searchValue, text.name);
            } else if (item.field.indexOf('date') >= 0) {
              result = textHighlighter(searchValue, moment(text).format('DD-MM-YYYY'));
            } else {
              result = textHighlighter(searchValue, text, true);
            }
            if (item['link']) {
              const link = (
                <Link to={item.link(record.id)}>{result}</Link>
              );
              return (
                <PopoverEllipsis
                  content={link}
                  containerStyles={{ color: '#1890ff' }}
                >
                  {link}
                </PopoverEllipsis>
              );
            } else if (item['customRender']) {
              return item.customRender(record, result, 0, openFilter, this);
            } else {
              return result;
            }
          };
          columns.push(columnParams);
        }
      });

      if (tabPrefix === tabDevicesPrefix) {
        const iconStyle = { fontSize: '16pt' };
        const buttonStyle = { width: '100%' };

        const checkedIcon = <StarFilled style={iconStyle} />;
        const uncheckedIcon = <StarOutlined style={iconStyle} />

        columns.unshift({
          title: () => {
            const allRecordsAreInBucket = this.allRecordsAreInBucket(bucketIdsData);
            const icon = allRecordsAreInBucket ? checkedIcon : uncheckedIcon;
            const title = allRecordsAreInBucket ? t('REMOVE_ALL') : t('ADD_ALL');

            return (
              <Tooltip
                key='addRemoveButtonTollTip'
                title={title}
                placement='left'
                //overlayClassName='tooltip'
                destroyTooltipOnHide={{ keepParent: true }}
              >
                <Button
                  type='link'
                  key='addRemoveButton'
                  onClick={(e) => this.onAddAllOnPage2Bucket(e)}
                  icon={icon}
                  style={buttonStyle}
                />
              </Tooltip>
            );
          },
          key: 'select_device',
          fixed: 'left',
          resizable: false,
          fixedWidth: '65px',
          minWidth: 65,
          maxWidth: 65,
          render: (text, record) => {
            const recordInBucket = bucketIdsData.includes(record.id);
            const title = recordInBucket ? t('REMOVE_DEVICE') : t('ADD_DEVICE');
            const icon = recordInBucket ? checkedIcon : uncheckedIcon;

            return (
              <Tooltip
                title={title}
              >
                <Button
                  type='link'
                  onClick={(e) => this.onAdd2Bucket(e, record.id)}
                  icon={icon}
                  style={buttonStyle}
                />
              </Tooltip>
            );
          },
        });
      }

      return columns;
    };

    resetColWidths = () => {
      const { tableName } = this.props;
      storage.remove(`columns_width_${tableName}`);
    };

    resetColVisibility = async () => {
      const { tableName, prefix } = this.props;

      storage.remove(`columnsVisibility_${tableName}`);
      storage.remove(`columnsValues_${tableName}`);
      storage.remove(`columnsValues__Global_Prefix__${prefix}`);

      window.location.reload();
    };

    filterValuesForRequest(filterValues) {
      const { filters } = this.state;
      const filterValuesForRequest = {};

      Object.keys(filterValues).forEach((index) => {
        const currentFilter = filters.find((x) => x.name === index);

        if (
          filterValues[index]
          && filterValues[index].value !== null
          && currentFilter
          && currentFilter.queryFiled !== ''
        ) {
          if (
            Array.isArray(filterValues[index].value)
            && currentFilter.type === 'DATE_RANGE'
            && currentFilter.queryFiled.indexOf('date') >= 0
          ) {
            filterValuesForRequest[`${currentFilter.queryFiled}_after`] = filterValues[index].value[0];
            filterValuesForRequest[`${currentFilter.queryFiled}_before`] = filterValues[index].value[1];
          } else if (Array.isArray(filterValues[index].value)) {
            filterValuesForRequest[currentFilter.queryFiled] = filterValues[index].value.join(',');
          } else {
            filterValuesForRequest[currentFilter.queryFiled] = filterValues[index].value;
          }
        }
      });

      return filterValuesForRequest;
    }

    render() {
      const {
        data, count, isLoading, countFiltersWithValues, filterValuesForRequest,
        isActiveResetTableBtn,
      } = this.state;
      const {
        id, rights, setTableName, onShowSizeChange, searchFilterLoading,
      } = this.props;

      return (
        <WrappedComponent
          data={data}
          count={count}
          isLoading={isLoading || searchFilterLoading}
          countFiltersWithValues={countFiltersWithValues}
          getListProps={this.getListProps}
          getColumns={(mode) => this.getColumns(mode)}
          onShowSizeChange={onShowSizeChange}
          toggleFilter={this.toggleFilter}
          filterValuesForExport={filterValuesForRequest}
          loadData={() => this.loadSearchData(id)}
          isActiveResetTableBtn={isActiveResetTableBtn}
          handleActivateResetTableBtn={this.handleActivateResetTableBtn}
          handleResetTable={this.handleResetTable}
          rights={rights}
          setTableName={setTableName}
          setInitialFilters={this.setInitialColumnsWidthAndVisible}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    searchValue: state.search.searchValue,
    bucketIdsData: state.bucket.bucketIdsData,
    rights: state.rights.rightsData,
    sidebar: state.sidebar,
    archived: state.archived,
    searchFilterLoading: state.search.searchFilterLoading,
    searchReload: state.search.searchReload
  });

  const mapDispatchToProps = {
    openFilter: openSidebar,
    closeFilter: closeSidebar,
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(
      withPagination(
        AbstractTabClass
      )
    )
  );
}
