import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@state/store';
import Initial from '@routes/initial';
import MainLayout from '@app/components/layouts/main';
import LoginLayout from '@app/components/layouts/login';

import { APPSTAGE_INITIAL, APPSTAGE_SIGNIN, APPSTAGE_AUTH } from './constants';

type Props = {
  appStage: string;
}

const App: React.FC<Props> = (props: Props) => {
  const { appStage } = props;

  return (
    <>
      {((): React.ReactNode => {
        switch (appStage) {
          case APPSTAGE_INITIAL:
            return <Initial />;
          case APPSTAGE_AUTH: {
            return <MainLayout />;
          }
          case APPSTAGE_SIGNIN:
            return <LoginLayout />;
          default:
            return <></>;
        }
      })()}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  appStage: state.appStage,
});

export default connect(mapStateToProps)(
  App
);
