import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Button, Input, Form, Upload, message, Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Qs from 'qs';

import api from '@services/api';
import { closeSidebar } from '@state/sidebar/actions';
import { SidebarLayout, SidebarCard } from '@ui';

class SPMapFormSidebar extends Component {
  form = React.createRef();

  state = {
    fileList: [],
  };

  sendData = () => {
    const { data, t, closeSB } = this.props;
    const { complexId } = data;

    this.form.current.validateFields().then(async (values) => {
      this.setState({ isLoading: true });

      const formData = new FormData();

      formData.append('complex_id', complexId);
      values.template && formData.append('template', values.template.file || values.template);
      values.name && formData.append('name', values.name);

      const result = await api.uploadDCMap(formData);

      this.setState({ isLoading: false });

      if (result.status === 201) {
        message.success(`${t('DCMAP_UPLOAD_MESSAGE')} ${t('SUCCESSFULL')}`);
        closeSB();
      } else if (result.data.error && result.data.error.length < 250) {
        message.error(`${t('DCMAP_UPLOAD_MESSAGE')} ${t('UNSUCCESSFULL')}. (${result.data.error})`, 5);
      } else {
        message.error(t('DCMAP_UPLOAD_MESSAGE_ERR'), 5);
      }
    }).catch(({ errorFields }) => this.form.current.scrollToField(errorFields[0].name));
  };

  downloadDCMap = async () => {
    const {
      t, data, socket, isSocketConnected,
    } = this.props;
    if (socket && isSocketConnected) {
      const { complexId, complexName, passportId } = data;

      const params = {
        complex_id: complexId,
        keys: true,
      };

      const request = `/v1/passport/${passportId}/setpoint-map?${Qs.stringify(params, { arrayFormat: 'repeat' })}`;

      await socket.send(JSON.stringify({
        message_type: 'export',
        title: `${t('DCMAP_UNLOAD_DESCRIPTION_EXPORT')} "${complexName}"`,
        url: request,
        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }));
    }
  };

  render() {
    const {
      t,
    } = this.props;

    const { isLoading, fileList } = this.state;

    const propsPrime = {
      onRemove: () => {
        this.setState({ fileList: [] });
      },
      beforeUpload: (file) => {
        this.setState({ fileList: [file] });
        return false;
      },
      fileList,
    };

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t('SETPOINT_MAP_ADD')}</h4>}
      >
        <>
          {t('DCMAP_UNLOAD_DESCRIPTION')}
          <SidebarCard.parameter>
            <Button
              block
              type='primary'
              onClick={this.downloadDCMap}
            >
              {t('UNLOAD')}
            </Button>
          </SidebarCard.parameter>
          <Divider />
          {t('DCMAP_UPLOAD_DESCRIPTION')}
          <Form name='setpointMapForm' ref={this.form}>
            <SidebarCard.parameter>
              <Form.Item
                name='template'
                rules={[{ required: true, message: t('TEMPLATE_REQUIRED_FIELD') }]}
                label={t('TEMPLATE')}
                valuePropName='file'
              >
                <Upload
                  accept='.vsdx'
                  disabled={isLoading}
                  {...propsPrime}
                >
                  <Button disabled={isLoading}>
                    <UploadOutlined />
                    &nbsp;
                    {t('TEMPLATE')}
                  </Button>
                </Upload>
              </Form.Item>
            </SidebarCard.parameter>
            <SidebarCard.parameter>
              <Form.Item
                name='name'
                label={t('NAME')}
              >
                <Input
                  rules={[{ required: true, message: t('DCMAP_REQUIRED_FIELD') }]}
                  label={t('PASSPORT_SETPOINT_MAP_COMPLEX')}
                  disabled={isLoading}
                />
              </Form.Item>
              <Button
                block
                type='primary'
                onClick={this.sendData}
                disabled={isLoading}
                loading={isLoading}
              >
                {t('UPLOAD')}
              </Button>
            </SidebarCard.parameter>
          </Form>
        </>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});
const mapDispatchToProps = {
  closeSB: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    SPMapFormSidebar
  )
);
