import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Popover } from 'antd';
import { TableOutlined, RollbackOutlined } from '@ant-design/icons';

import styles from '../styles.module.less';

interface Props extends WithTranslation {
  onClick: () => void;
  disabled: boolean;
}

class ResetTableBtn extends Component<Props> {
  render() {
    const { t, onClick, disabled } = this.props;

    return (
      <Popover
        placement='topRight'
        content={t('RESET_COL_WIDTHS')}
      >
        <Button
          disabled={disabled}
          className={styles.resetColWidthsBtn}
          onClick={onClick}
          size='small'
        ><TableOutlined key='1' /><RollbackOutlined key='2' /></Button>
      </Popover>
    );
  }
}

export default withTranslation()(
  ResetTableBtn
);
