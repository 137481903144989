import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { unionBy, debounce } from 'lodash';

import api from '@services/api';
import { PlusCircleOutlined, MinusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { LIMIT } from '@globalConstants';

import {
  Button, message, Select, Divider,
} from 'antd';
import { PopoverEllipsis } from '@ui/index';

class EventsRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      eventCount: 0,
      searchValue: undefined,
      eventToBind: null,
    };
  }

  componentDidMount() {
    this.onSearchEvent();
  }

  componentDidUpdate(prevProps) {
    const { isEditRight } = this.props;

    if (isEditRight !== prevProps.isEditRight) {
      this.onSearchEvent();
    }
  }

  onSelectEvent = (eventToBind) => {
    this.setState({
      eventToBind,
    });
  };

  onChangeSearchEvent = (searchValue) => {
    this.setState({
      searchValue,
    });
  };

  deviceBind = async () => {
    const {
      t, deviceId, updateEvents,
    } = this.props;
    const { eventToBind } = this.state;

    this.setState({ isSending: true });
    const { status } = await api.bindDeviceToEvent(eventToBind, { items: [deviceId] });
    this.setState({ isSending: false });

    if (status === 204) {
      this.setState({
        eventToBind: undefined,
        searchValue: undefined,
      });
      updateEvents(eventToBind);
      await this.onSearchEvent();
      message.success(t('DEVICE_BIND_SUCCESS'));
    } else {
      message.error(t('DEVICE_BIND_ERROR'));
    }
  };

  deviceUnbind = async () => {
    const {
      selectedEvent, t, deviceId, removeEvent, onDeselectedEvent,
    } = this.props;

    this.setState({ isUnbinding: true });
    const { status } = await api.unbindDeviceFromEvent(selectedEvent, { items: [deviceId] });
    this.setState({ isUnbinding: false });

    if (status === 204) {
      removeEvent(selectedEvent);
      onDeselectedEvent();
      await this.onSearchEvent();
      message.success(t('DEVICE_UNBIND_SUCCESS'));
    } else {
      message.error(t('DEVICE_UNBIND_ERROR'));
    }
  };

  onScrollEventSelector = (scroll) => {
    const {
      events, searchValue, isLoading, eventCount,
    } = this.state;

    const { target } = scroll
    const scrollTopMax = target.scrollHeight - target.offsetHeight;

    if (
      !isLoading
      && eventCount
      && eventCount > events.length
      && target.scrollTop === scrollTopMax
    ) {
      this.onSearchEvent(searchValue, events.length);
    }
  };

  onSearchEvent = async (value, offset = 0) => {
    const { isEditRight, deviceId } = this.props;
    const { events, searchValue } = this.state;

    const newValue = value === ''
      ? undefined
      : value;

    if (!isEditRight) {
      return undefined;
    }

    const isSearchValueChanged = newValue !== searchValue || (offset === 0 && value === undefined);

    this.setState({ isLoading: true });
    const eventData = await api.getEvents({
      search: newValue,
      view_mode: 'full',
      excluded_protection_device: deviceId,
      ordering: 'name',
      offset,
      limit: LIMIT,
    });
    this.setState({ isLoading: false });

    if (eventData.status === 200 && eventData.data.results) {
      this.setState({
        events: isSearchValueChanged ? eventData.data.results : unionBy(events, eventData.data.results, 'id'),
        eventCount: eventData.data.count,
        searchValue: newValue,
      });
    }
  };

  render() {
    const {
      t, selectedEvent, isEditRight,
    } = this.props;
    const {
      eventToBind, events, isLoading, isSending, isUnbinding, searchValue,
    } = this.state;

    const getContent = (x) => {
      const content = x.name.concat(
        x.subject && x.subject.name
          ? ' '.concat('(', x.subject.name, ')')
          : ''
      );
      return (
        <PopoverEllipsis content={content}>
          {content}
        </PopoverEllipsis>
      );
    };

    return (
      <>
        <Select
          size='small'
          value={searchValue}
          style={{ width: 500 }}
          suffixIcon={isLoading ? <LoadingOutlined /> : null}
          placeholder={t('SELECT_EVENT')}
          title={t('SEARCH_EVENT_BY_NAME_AND_OWNER')}
          disabled={isSending || !isEditRight || ((!searchValue || searchValue === '') && events && events.length === 0)}
          defaultActiveFirstOption={false}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={this.onChangeSearchEvent}
          listItemHeight={10}
          filterOption={false}
          showSearch
          onSearch={debounce(this.onSearchEvent, 800)}
          onDropdownVisibleChange={(x) => x && this.onSearchEvent()}
          onPopupScroll={this.onScrollEventSelector}
          onSelect={this.onSelectEvent}
        >
          {events.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {getContent(item)}
            </Select.Option>
          ))}
        </Select>
        <Button
          onClick={() => this.deviceBind()}
          disabled={!eventToBind || isUnbinding || isSending || !isEditRight}
          loading={isSending}
          className='control-button'
          size='small'
          icon={<PlusCircleOutlined />}
        >
          {t('RZA_BIND')}
        </Button>
        <Divider type="vertical" />
        <Button
          onClick={() => this.deviceUnbind()}
          disabled={!selectedEvent || isUnbinding || isSending || !isEditRight}
          loading={isUnbinding}
          className='control-button'
          size='small'
          icon={<MinusCircleOutlined />}
        >
          {t('RZA_UNBIND')}
        </Button>
      </>
    );
  }
}

export default withTranslation()(
  EventsRoute
);
