import React from 'react';
import debounce from 'lodash/debounce';
import {
  Input, List, Progress, Button, Popconfirm, Tooltip,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  RightOutlined,
  StopOutlined, WarningOutlined,
} from '@ant-design/icons';
import { getId } from '@app/helpers';
import api from '@services/api';
import { PopoverEllipsis, InfiniteScroll } from '@ui';

import { LEVEL_DC, LEVEL_DEVICE, LEVEL_EQUIPMENT_TYPE } from './constants';

import styles from './styles.module.less';

const { Search } = Input;

class Column extends React.Component {
  infiniteScroll = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      searchPatternState: '',
      pasteCount: 0,
      isSaving: false,
    };
  }

  componentDidMount() {
    const { levelName, user, searchPattern } = this.props;

    if (levelName === LEVEL_DC && user.dispatcher_center) {
      this.setCurrentElement(user.dispatcher_center);
    }

    this.setState({
      searchPatternState: searchPattern || '',
    });
  }

  componentDidUpdate(prevProps) {
    const {
      list, isEdit, levelName, searchPattern, activeElement, user,
    } = this.props;
    const { searchPatternState } = this.state;

    if (!isEqual(prevProps.user, user) && levelName === LEVEL_DC && user.dispatcher_center) {
      this.setCurrentElement(user.dispatcher_center);
    }

    if (prevProps.list && list && prevProps.list.length !== list.length) {
      this.updateState();
    }

    if (isEdit !== prevProps.isEdit && levelName === LEVEL_EQUIPMENT_TYPE) {
      this.onSearchPatternChangeDebounced(searchPatternState);
    }

    if (searchPattern !== prevProps.searchPattern && searchPattern !== searchPatternState) {
      this.setState({
        searchPatternState: searchPattern,
      });
    }

    if (prevProps.activeElement !== activeElement) {
      this.setState({
        activeItemId: activeElement ? activeElement.id : undefined,
        activeItem: activeElement,
        pasteCount: 0,
      });
    }
  }

  onChangeElement(element) {
    //Запрет выбора уже выбранного элемента
    // if (element.id === activeItemId) {
    //   return false;
    // }

    const { onElementActivate } = this.props;

    this.setState(
      {
        activeItemId: element.id,
        activeItem: element,
        pasteCount: 0,
      },
      () => onElementActivate && onElementActivate(element)
    );

    return undefined;
  }

  onSearch = (value) => {
    const { onSearchPatternChange, prevActiveElement } = this.props;

    this.infiniteScroll.current && this.infiniteScroll.current.scrollToTop();

    onSearchPatternChange && onSearchPatternChange(value, prevActiveElement);
  };

  onSearchPatternChange = (event) => {
    const {
      target: { value },
    } = event;
    this.onSearchPatternChangeDebounced(value);
    this.setState({ searchPatternState: value });
  };

  onSearchPatternChangeDebounced = debounce((value) => {
    const filteredValue = value;
    const { onSearchPatternChange, prevActiveElement } = this.props;

    this.infiniteScroll.current && this.infiniteScroll.current.scrollToTop();

    onSearchPatternChange && onSearchPatternChange(filteredValue, prevActiveElement);
  }, 800);

  setCurrentElement = (element) => {
    const { onSearchPatternChange, prevActiveElement } = this.props;

    if (element.name) {
      this.setState(
        {
          searchPatternState: element.name,
          pasteCount: 0,
        },
        () => {
          onSearchPatternChange && onSearchPatternChange(
            element.name,
            prevActiveElement,
            () => this.onChangeElement(element)
          );
        }
      );
    }
  };

  updateState = () => {
    this.setState({
      activeItem: undefined,
      activeItemId: undefined,
      pasteCount: 0,
    });
  };

  prepareList = () => {
    let { list } = this.props;
    const { searchPattern } = this.props;
    const { searchPatternState } = this.state;

    let searchString = searchPatternState || (searchPatternState === undefined && searchPattern);

    list = list || [];
    if (!searchString || !searchString.length) {
      return list || [];
    }

    searchString = searchString.toLowerCase();

    return list.filter(({ name }) => {
      if (searchString && searchString.length < 1) return true;
      return name.toLowerCase().indexOf(searchString) !== -1;
    });
  };

  addHandler = (e1) => {
    const { onAddItem, prevActiveElement } = this.props;
    const { searchPatternState } = this.state;

    e1.stopPropagation();
    e1.preventDefault();

    onAddItem(searchPatternState, prevActiveElement);
  }

  newItem = (parent) => {
    return {
      id: getId(5),
      name: '',
      parent,
      // children: [],
      isNewItem: true,
    };
  };

  onAddItem = (parent) => {
    const { levelName, onAddItem } = this.props;

    const item = (
      levelName === LEVEL_DC
        ? this.newItem(parent) // Заготовка на будущее для иерархии ДЦ
        : this.newItem()
    );
    if (onAddItem) {
      onAddItem(parent, item);
    }
  };

  onCopyItem = (element) => {
    const { levelName, onAddItem } = this.props;
    const { pasteCount } = this.state;

    if (element) {
      const item = (
        levelName === LEVEL_DC
          ? this.newItem(element.parent || element.id) // Заготовка на будущее для иерархии ДЦ
          : this.newItem()
      );
      item.name = element.name.concat(' (', pasteCount + 1, ')');
      this.setState({
        pasteCount: (pasteCount + 1),
      });

      if (onAddItem) {
        onAddItem(element.id, item);
      }
    }
  };

  onSaveItem = async () => {
    const {
      activeElement, onSaveItem, onUpdateItem, list,
    } = this.props;
    const { isSaving } = this.state;

    if (isSaving) {
      return;
    } else {
      this.setState({ isSaving: true });
    }

    const item = list.find((x) => x.id === (activeElement && activeElement.id));

    if (item && item.isNewItem) {
      onSaveItem && await onSaveItem();
    } else {
      onUpdateItem && await onUpdateItem();
    }

    this.setState({
      isSaving: false,
    });
  };

  onEditItem = (el) => {
    const { onStartEdit } = this.props;

    onStartEdit && onStartEdit(el.id, el.name);
  }

  render() {
    const {
      indexColumn, levelName, onLoadMore, isEdit,
      activeElement, prevActiveElement, title,
      isLoading = false, extendLoader,
      list, t, onChangeEdit, onCancelEdit, onDeleteItem, onViewPassport,
      isEditElement, edited, editedKey,
      total: totalProps, loaded: loadedProps,
    } = this.props;
    const { searchPatternState, activeItemId, isSaving } = this.state;

    const selected = activeItemId || (activeElement && activeElement.id);

    const listProps = {
      locale: {
        emptyText: (
          <>
            {t('TREE_EMPTY_ITEM')}
            <br />
            <br />
            {
              isEdit && levelName !== LEVEL_EQUIPMENT_TYPE && (levelName === LEVEL_DC || prevActiveElement)
                ? (
                  <Button
                    disabled={isEditElement}
                    icon={<PlusOutlined />}
                    className={styles.arrow}
                    title={t('ADD')}
                    onClick={() => (
                      isEditElement
                        ? false
                        : this.onAddItem(null)
                    )}
                  >
                    {t('ADD')}
                  </Button>
                )
                : ''
            }
          </>
        ),
      },
      bordered: false,
      style: { minHeight: '100%' },
    };

    const total = totalProps || 0;
    const loaded = loadedProps || 0;

    let percent = 0;
    if (total) {
      percent = (loaded / total) * 100;
      if (percent > 100) percent = 100;
      if (percent < 1) percent = 1;
      percent = Math.floor(percent);
    }

    const disableDelete = (el) => isEditElement || !el.is_can_delete;

    const currentEditLevelInfo = (
      isEdit
      && isEditElement
      && editedKey
      && list
      && list.length
      && list.find((x) => x.id === editedKey)
      && searchPatternState !== ''
    );

    const currentEditLevelWarning = (
      currentEditLevelInfo
      && edited
      && edited.indexOf(searchPatternState) === -1
    );

    const warningText = (
      <Tooltip
        title={currentEditLevelWarning ? t('TREE_WARNING_THEN_EDIT_WARNING') : t('TREE_WARNING_THEN_EDIT_INFO')}
        disabled={false}
      >
        <WarningOutlined style={currentEditLevelWarning ? { color: 'red' } : { color: 'gold' }} />
      </Tooltip>
    );

    return (
      <div className={styles.elementsColumn}>
        <div className={styles.columnSearch}>
          <Search
            key={`search_block_${indexColumn}`}
            placeholder={title}
            defaultValue={searchPatternState}
            onChange={this.onSearchPatternChange}
            onSearch={this.onSearch}
            size='large'
            allowClear
            disabled={isEditElement}
            className={
              currentEditLevelWarning
                ? styles.currentEditLevelWarning
                : (
                  currentEditLevelInfo
                    ? styles.currentEditLevelInfo
                    : undefined
                )
            }
            suffix={currentEditLevelInfo ? warningText : undefined}
          />
        </div>

        <InfiniteScroll
          ref={this.infiniteScroll}
          isUsingClientHeight
          scrollbarProps={{
            style: { height: `calc(100% - ${extendLoader && (percent < 100) && (total > loaded) ? 66 : 50}px)` },
            autoHide: true,
            renderView: (props) => (
              isEditElement
                ? (
                  <div
                    {...props}
                    style={{ ...props.style, overflow: 'hidden', marginRight: '0px' }}
                  />
                )
                : (
                  <div
                    {...props}
                  />
                )
            ),
          }}
          loadMoreEnabled={!isLoading && loadedProps !== undefined && (total > loaded)}
          loadMore={() => onLoadMore(indexColumn, searchPatternState)}
        >
          <List
            style={{ minHeight: '100%' }}
            {...listProps}
            dataSource={list}
            loading={isLoading || isSaving}
            renderItem={(el) => (
              <List.Item
                key={el.id}
                extra={
                  isEdit && levelName !== LEVEL_EQUIPMENT_TYPE && selected === el.id && el.id !== api.getNullUuid()
                    ? (
                      isEditElement && editedKey === el.id
                        ? (
                          <span style={{ marginLeft: '7px' }}>
                            <CheckOutlined
                              className={styles.arrow}
                              title={t('SAVE')}
                              onClick={this.onSaveItem}
                            />
                            <StopOutlined
                              className={styles.arrow}
                              title={t('CANCEL')}
                              onClick={() => onCancelEdit(el.isNewItem)}
                            />
                          </span>
                        )
                        : (
                          <span>
                            <PlusOutlined
                              className={styles.arrow}
                              title={t('ADD')}
                              onClick={() => (
                                isEditElement
                                  ? false
                                  : this.onAddItem(el.id)
                              )}
                            />
                            <CopyOutlined
                              className={styles.arrow}
                              title={t('MAKE_COPY')}
                              onClick={() => (
                                isEditElement
                                  ? false
                                  : this.onCopyItem(el)
                              )}
                            />
                            <EditOutlined
                              className={styles.arrow}
                              title={t('EDIT')}
                              onClick={() => (
                                isEditElement
                                  ? false
                                  : this.onEditItem(el)
                              )}
                            />
                            <Popconfirm
                              disabled={disableDelete(el)}
                              title={t('TREE_DELETE_TREE_ITEM_CONFIRM')}
                              onConfirm={() => onDeleteItem(el.id)}
                            >
                              <DeleteOutlined
                                disabled={disableDelete(el)}
                                className={disableDelete(el) ? styles.arrowDisabled : styles.arrow}
                                title={disableDelete(el) ? t('TREE_DELETE_TREE_ITEM_DISABLED') : t('DELETE')}
                              />
                            </Popconfirm>
                            {
                              levelName === LEVEL_DEVICE
                                ? (
                                  <InfoCircleOutlined
                                    className={styles.arrow}
                                    title={t('INFO')}
                                    onClick={() => (
                                      isEditElement
                                        ? false
                                        : onViewPassport(el)
                                    )}
                                  />
                                )
                                : ''
                            }
                          </span>
                        )
                    )
                    : <RightOutlined className={styles.arrow} />
                }
                className={[styles.columnItem, selected === el.id ? styles.columnItemSelected : '', el.is_archived ? 'isArchivedTableRow' : '']}
                onClick={() => (
                  isEditElement
                    ? false
                    : this.onChangeElement(el)
                )}
                onDoubleClick={() => (
                  !isEdit || isEditElement
                    ? false
                    : this.onEditItem(el)
                )}
              >
                {
                  isEdit && isEditElement && editedKey === el.id
                    ? (
                      <Input
                        type='text'
                        defaultValue={el.name}
                        value={edited}
                        size='small'
                        placeholder={title}
                        key={`input_${el.id}`}
                        onChange={(event) => onChangeEdit(event.target.value)}
                        onBlur={(event) => {
                          event && event.target && event.target.focus && event.target.focus();
                        }}
                        onPressEnter={this.onSaveItem}
                        onKeyDown={(e) => (
                          e.key === 'Escape'
                            ? onCancelEdit(el.isNewItem)
                            : true
                        )}
                        autoFocus={true}
                      />
                    )
                    : (
                      <PopoverEllipsis content={el.name} overlayStyle={{ zIndex: 8000 }}>
                        {el.name}
                      </PopoverEllipsis>
                    )
                }
              </List.Item>
            )}
          />
        </InfiniteScroll>
        {
          extendLoader && (percent < 100) && (total > loaded)
            ? (
              <Tooltip
                title={(
                  <div>
                    {t('TREE_PERCENT_1')}
                    <br />
                    {t('TREE_PERCENT_2')}
                    <br />
                    {t('TREE_PERCENT_3')}
                    {percent}%
                    ({loaded}/{total})
                  </div>
                )}
              >
                <Progress percent={percent} showInfo={false} />
              </Tooltip>
            )
            : ''
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
  bucketData: state.bucket.bucketData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Column
  )
);
