export const OPTIONS_CHECKBOX_GROUP = [
  {
    label: 'Необходимо проверить коэффициенты трансформации',
    value: 'check_transformation_coefficient',
  },
  {
    label: 'Необходимо направить в ДЦ подтверждение технической возможности реализации задания',
    value: 'deliver_feasibility_proof',
  },
  {
    label: 'Необходимо скорректировать принципиальные (полные) и (или) функционально-логические схемы устройств РЗА',
    value: 'adjust_power_system_protection_schemes',
  },
  {
    label: 'Необходимо скорректировать исполнительные схемы',
    value: 'adjust_post_completion_schemes',
  },
  {
    label: 'Необходимо предоставить исполнительные схемы',
    value: 'deliver_post_completion_schemes',
  },
  {
    label: 'Необходимо проверить алгоритм функционирования устройства ЛАПНУ',
    value: 'check_LAPNU_algorithm',
  },
];

export const TYPES_OF_CHECKBOX_GROUP = [
  'check_transformation_coefficient',
  'deliver_feasibility_proof',
  'adjust_power_system_protection_schemes',
  'adjust_post_completion_schemes',
  'deliver_post_completion_schemes',
  'check_LAPNU_algorithm',
];

export const ADDITIONAL_INSTRUCTIONS =
  'Информация об эксплуатационном состоянии устройства РЗА, отдельных функций и (или) нормальном эксплуатационном положении управляющих воздействий, сигналов и команд устройства РЗА в виде приложения к заданию диспетчерского центра по настройке устройства РЗА в случае, если разработка и утверждение инструкции по обслуживанию устройства РЗА в отношении соответствующего устройства РЗА диспетчерским центром не осуществляется.';