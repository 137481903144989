import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Select, Form } from 'antd';

import { SidebarLayout, CustomSelect } from '@ui';
import api from '@services/api';

class SetpointMapSidebar extends React.Component {
  form = React.createRef();

  sendData = () => {
    const { data } = this.props;
    const { onOk, complexes, deviceId } = data;

    this.form.current.validateFields().then(async (values) => {
      const newComplex = complexes.filter((x) => x.id === values.complex)[0];

      await api.patchProtectionDevice(deviceId, {
        id: deviceId,
        complexes: [
          ...complexes.filter((x) => x.protection_devices.some((y) => y.id === deviceId)).map((x) => x.id),
          values.complex,
        ],
      });

      onOk(newComplex);
    }).catch(({ errorFields }) => this.form.current.scrollToField(errorFields[0].name));
  };

  render() {
    const {
      data, t,
    } = this.props;
    const { complexes, deviceId } = data;

    const viewComplexes = complexes.filter(
      (x) => !x.protection_devices.some((device) => device.id === deviceId)
    );

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t('PASSPORT_SETPOINT_MAP_ADDING_COMPLEX')}</h4>}
        footer={(
          <Button
            block
            type='primary'
            onClick={this.sendData}
            disabled={viewComplexes.length === 0}
          >
            {t('PASSPORT_SETPOINT_MAP_BUTTON_SAVE')}
          </Button>
        )}
      >
        <Form name='setpointMapModal' ref={this.form}>
          <Form.Item name='complex' rules={[{ required: true, message: t('PASSPORT_SETPOINT_MAP_REQUIRED_FIELD') }]} label={t('PASSPORT_SETPOINT_MAP_COMPLEX')}>
            <CustomSelect
              autoClearSearchValue={true}
              showSearch={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder={t('PASSPORT_SETPOINT_MAP_CHOOSE_COMPLEX')}
              filterOption={(val, option) => {
                const value = option.children.props.children;
                return value.toString().toUpperCase().indexOf(val.toUpperCase()) >= 0;
              }}
              disabled={viewComplexes.length === 0}
              popoverPlacement='left'
            >
              {
                viewComplexes.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))
              }
            </CustomSelect>
          </Form.Item>
        </Form>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  SetpointMapSidebar
);
