import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars';
import {
  Radio, Spin, Table, Button, Menu,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import api from '@services/api';
import {
  MAP_RIGHTS_TO_PATHS,
  MODE_EDIT,
  MODE_READONLY,
  MODE_NO_ACCESS,
  MODE_EDIT_TIME,
  ROUTE_ROLES,
  TABLE_SIZE,
  RIGHT_PASSPORT_DOCS,
  RIGHT_PASSPORT_EVENTS,
  RIGHT_PASSPORT_FUNCTIONS,
  RIGHT_PASSPORT_INFO,
  RIGHT_PASSPORT_LETTERS,
  RIGHT_PASSPORT_NONPERFORMANCE,
  RIGHT_PASSPORT_SETPOINTS,
  RIGHT_PASSPORT_SETPOINT_MAP,
  RIGHT_PASSPORT_TASKS,
  RIGHT_ROUTE_CLASSIFIERS,
  RIGHT_ROUTE_EVENTS,
  RIGHT_ROUTE_EXTERNAL_SYSTEMS,
  RIGHT_ROUTE_FUNCTIONS,
  RIGHT_ROUTE_GLOBAL_SEARCH,
  RIGHT_ROUTE_JOURNAL,
  RIGHT_ROUTE_REPORTS,
  RIGHT_ROUTE_ROLES,
  RIGHT_ROUTE_STORAGES,
  RIGHT_ROUTE_SYSTEM_SETTINGS,
  RIGHT_ROUTE_TASK_DC_MONITORING,
  RIGHT_ROUTE_TEMPLATES,
  RIGHT_ROUTE_TREE,
  RIGHT_PASSPORT_OSCILLOGRAM,
  RIGHT_NEWS,
  RIGHT_MAINTENANCE,
  RIGHT_METATAGS_DOCS_ADD,
  RIGHT_NETWORK_MODELS,
  RIGHT_LIBRARY_DOCS,
  RIGHT_ROUTE_CHECK_SETPOINTS,
  RIGHT_ROUTE_RESERV,
  RIGHT_ROUTE_DISTRIBUTION
} from '@globalConstants';
import { CustomCard } from '@ui';
import {
  ADMIN_IA, ADMIN_ODU, ADMIN_RDU, ADMIN_TECH, TECH, GUEST,
} from '../../constants';

import styles from './styles.module.less';

class FunctionsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: RIGHT_ROUTE_GLOBAL_SEARCH,
      componentsToUp: {},
      componentsToSet: {},
    };
  }

  onChangeRight = async (event, record, role) => {
    const { rightsDataRaw } = this.props;
    const { componentsToUp, componentsToSet } = this.state;
    const newComponentsToUp = { ...componentsToUp };
    const newComponentsToSet = { ...componentsToSet };
    const componentRights = rightsDataRaw.find((item) => item.component_id === record.component);

    if (componentRights && !newComponentsToUp[componentRights.id]) {
      newComponentsToUp[componentRights.id] = {};
    }
    if (componentRights && !newComponentsToUp[componentRights.id][record.component]) {
      newComponentsToUp[componentRights.id][record.component] = {};
    }

    if (!componentRights) {
      if (!newComponentsToSet[record.component]) {
        newComponentsToSet[record.component] = {};
      }
      newComponentsToSet[record.component][`${role}`] = record.mode;
    } else if (event.target.value === 'null') {
      newComponentsToUp[componentRights.id][record.component][`${role}`] = MODE_NO_ACCESS;
    } else if (event.target.value === 'readonly') {
      newComponentsToUp[componentRights.id][record.component][`${role}`] = MODE_READONLY;
    } else if (event.target.value === 'edit') {
      newComponentsToUp[componentRights.id][record.component][`${role}`] = MODE_EDIT;
    } else if (event.target.value === 'edit_time') {
      newComponentsToUp[componentRights.id][record.component][`${role}`] = MODE_EDIT_TIME;
    }

    this.setState({
      componentsToUp: newComponentsToUp,
      componentsToSet: newComponentsToSet,
    });
  };

  setRights = async () => {
    const { componentsToSet } = this.state;

    const req = Object.keys(componentsToSet).map((compId) => {
      return api.setInterfaceRights({
        component_id: compId,
        ...componentsToSet[compId],
      });
    });

    await Promise.all(req);
  };

  updateRights = async () => {
    const { componentsToUp } = this.state;
    const req = [];

    Object.keys(componentsToUp).forEach((compId) => {
      Object.keys(componentsToUp[compId]).forEach((comp) => {
        req.push(api.patchInterfaceRights(compId, {
          component_id: comp,
          ...componentsToUp[compId][comp],
        }));
      });
    });

    await Promise.all(req);
  };

  saveChanges = async () => {
    const { getInterfaceRights } = this.props;

    this.setState({ isUpdating: true });
    await this.setRights();
    await this.updateRights();
    this.cancelChanges();

    await getInterfaceRights();
    this.setState({ isUpdating: false });
  };

  cancelChanges = () => {
    const { toggleFunctionsEdit } = this.props;

    this.setState({
      componentsToUp: {},
      componentsToSet: {},
    });
    toggleFunctionsEdit({
      preventDefault: () => {},
    });
  };

  renderRadio = (access_label, mode, component, noAccess, readonly, edit, editTime) => {
    const { isEdit } = this.props;

    const optionsValue = [
      {
        label: 'Нет доступа',
        value: 'null',
      },
      {
        label: 'Просмотр',
        value: 'readonly',
      },
      {
        label: 'Редактирование',
        value: 'edit',
      },
    ];

    const optionsValueNetworkModels = {
      label: 'Редактирование по времени',
      value: 'edit_time',
    };

    if (component === RIGHT_NETWORK_MODELS)
      optionsValue.push(optionsValueNetworkModels);

    return {
      title: access_label,
      dataIndex: component,
      key: component,
      render: (role, record) => {
        let selectedRight = 'null';

        if (readonly.includes(record.key)) selectedRight = 'readonly';
        if (edit.includes(record.key)) selectedRight = 'edit';
        if (editTime.includes(record.key)) selectedRight = 'edit_time';

        if (isEdit) {
          return (
            <>
              <Radio.Group
                options={optionsValue}
                onChange={(event) => this.onChangeRight(event, { component, mode }, record.key)}
                optionType="button"
                buttonStyle="solid"
                defaultValue={selectedRight}
                key={`edit_${record.key}_${selectedRight}`}
              />
            </>
          );
        } else {
          return (
            <>
              <Radio.Group
                options={optionsValue}
                defaultValue={selectedRight}
                optionType="button"
                buttonStyle="solid"
                disabled={true}
                key={`view_${record.key}_${selectedRight}`}
              />
            </>
          );
        }
      },
    };
  };

  changeMenuItem = ({ key }) => {
    this.setState({ selectedItem: key });
  };

  render() {
    const {
      rights, storeRights, toggleFunctionsEdit, t, isEdit, offsetTop,
    } = this.props;
    const { selectedItem, isUpdating } = this.state;

    const roles = [
      { key: ADMIN_IA, title: t('ADMIN_IA') },
      { key: ADMIN_ODU, title: t('ADMIN_ODU') },
      { key: ADMIN_RDU, title: t('ADMIN_RDU') },
      { key: ADMIN_TECH, title: t('ADMIN_TECH') },
      { key: TECH, title: t('TECH') },
      { key: GUEST, title: t('GUEST') },
    ];

    const columns = rights[selectedItem]
      ? [
        {
          dataIndex: 'filler',
          key: 'filler',
          width: 15,
        },
        {
          title: t('ROLE'),
          dataIndex: 'title',
          key: 'title',
        },
        ...rights[selectedItem].map(({ access_label, mode, component, noAccess, readonly, edit, editTime }) =>
          this.renderRadio(access_label, mode, component, noAccess, readonly, edit, editTime)
        ),
      ]
      : [];

    return (
      <CustomCard
        className={styles.rights}
        style={{ height: `calc(100vh - ${offsetTop - 10}px)`, marginBottom: 0 }}
        bodyStyle={{ height: 'calc(100% - 40px)' }}
        extra={(
          <>
            {storeRights[MAP_RIGHTS_TO_PATHS[ROUTE_ROLES]] === MODE_EDIT ? (
              <>
                {!isEdit && (
                  <Button
                    tabIndex={0}
                    size='small'
                    onClick={(e) => toggleFunctionsEdit(e)}
                    icon={<EditOutlined />}
                  >
                    {t('EDIT')}
                  </Button>
                )}
                {isEdit && (
                  <>
                    <Button
                      disabled={isUpdating}
                      tabIndex={0}
                      size='small'
                      onClick={this.cancelChanges}
                    >
                      {t('CANCEL')}
                    </Button>
                    &nbsp;
                    <Button
                      disabled={isUpdating}
                      loading={isUpdating}
                      tabIndex={0}
                      size='small'
                      type='primary'
                      onClick={this.saveChanges}
                    >
                      {t('SAVE')}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <span>{t('EDIT')}</span>
            )}
          </>
        )}
      >
        <div
          className={styles.scrollWrapper}
        >
          <Scrollbars style={{ height: `calc(100vh - ${offsetTop + 50}px)` }}>
            <Menu
              mode='vertical'
              defaultSelectedKeys={[RIGHT_ROUTE_GLOBAL_SEARCH]}
              onClick={this.changeMenuItem}
            >
              <Menu.Item key={RIGHT_ROUTE_GLOBAL_SEARCH}>{t('GLOBAL_SEARCH')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_TREE}>{t('TREE')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_TEMPLATES}>{t('TEMPLATES')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_FUNCTIONS}>{t('FUNCTIONS')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_CLASSIFIERS}>{t('CLASSIFICATORS')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_ROLES}>{t('ROLES')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_EXTERNAL_SYSTEMS}>{t('EXTERNAL_SYSTEMS')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_SYSTEM_SETTINGS} title={t('SYSTEM_SETTINGS_FUNC')}>{t('SYSTEM_SETTINGS_FUNC')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_JOURNAL}>{t('JOURNAL')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_STORAGES}>{t('STORAGES')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_EVENTS}>{t('EVENTS_CARD_TITLE')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_REPORTS}>{t('REPORTS')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_CHECK_SETPOINTS}>{t('LIST_URZA')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_RESERV}>{t('REDUNDANCY')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_DISTRIBUTION} title={t('FUNCTION_ALLOCATION_LISTS')}>{t('FUNCTION_ALLOCATION_LISTS')}</Menu.Item>
              <Menu.Item key={RIGHT_ROUTE_TASK_DC_MONITORING}>{t('LEFT_MENU_ITEM_TASK_DC_MONITORING')}</Menu.Item>
              <Menu.Divider />
              <Menu.Item key={RIGHT_NETWORK_MODELS}>{t('NETWORK_MODELS')}</Menu.Item>
              <Menu.Item key={RIGHT_LIBRARY_DOCS}>{t('RZA_LIBRARY')}</Menu.Item>
              <Menu.ItemGroup key='RIGHT_ROUTE_PASSPORT' title={t('PASSPORT')} />
              <Menu.Item key={RIGHT_PASSPORT_INFO}>{t('INFO')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_DOCS}>{t('DOCS')}</Menu.Item>
              <Menu.Item key={RIGHT_METATAGS_DOCS_ADD}>{t('METATAGS')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_SETPOINTS}>{t('SETPOINTS')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_SETPOINT_MAP}>{t('SETPOINT_MAP')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_FUNCTIONS}>{t('FUNCTIONS')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_TASKS}>{t('TASKS')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_LETTERS}>{t('LETTERS')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_NONPERFORMANCE}>{t('NONPERFORMANCE_TERMS')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_EVENTS}>{t('EVENTS_CARD_TITLE')}</Menu.Item>
              <Menu.Item key={RIGHT_PASSPORT_OSCILLOGRAM}>{t('OSCILLOGRAM')}</Menu.Item>
              <Menu.Item key={RIGHT_NEWS}>{t('NEWS')}</Menu.Item>
              <Menu.Item key={RIGHT_MAINTENANCE}>{t('MAINTENANCE')}</Menu.Item>
            </Menu>
          </Scrollbars>
        </div>
        {rights ? (
          <Table
            className={styles.table}
            size={TABLE_SIZE}
            columns={columns}
            dataSource={roles}
            pagination={false}
            scroll={
              { x: true }
            }
          />
        ) : (
          <Spin />
        )}
      </CustomCard>
    );
  }
}

export default withTranslation()(
  FunctionsTab
);
