export const getPopoverOptionValue = (value) => {
  switch (value) {
    case 'check_transformation_coefficient':
      return 'Задается при указании в задании параметров настройки во вторичных (относительных) величинах';
    case 'deliver_feasibility_proof':
      return (
        'Задается для обходных выключателей (в том числе совмещенных с\n' +
        'секционными или шиносоединительными), параметры настройки\n' +
        '(уставки) которых изменяются при замене выключателя присоединения\n' +
        'на обходной и не имеющих оперативных переключающих устройств,\n' +
        'предназначенных для изменения групп уставок'
      );
    case 'adjust_power_system_protection_schemes':
      return 'Задается при необходимости корректировки принципиальных (полных) и (или) функционально-логических схем устройств РЗА';
    case 'adjust_post_completion_schemes':
      return 'Задается при необходимости корректировки исполнительных схем';
    case 'deliver_post_completion_schemes':
      return 'Задается при необходимости предоставления исполнительных схем';
    case 'check_LAPNU_algorithm':
      return 'Задается при необходимости выполнения проверки алгоритма функционирования ЛАПНУ в задании ДЦ по настройке ЛАПНУ';
    default:
      return 'Содержимое для других значений';
  }
};