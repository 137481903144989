export const MODEL_DOCUMENT = 'Document';
export const MODEL_TEMPLATE = 'Template';
export const MODEL_PASSPORT = 'Passport';
export const MODEL_SETPOINT_VALUE = 'SetpointValue';
export const MODEL_SETPOINT = 'Setpoint';
export const MODEL_BLANK = 'Blank';
export const MODEL_BLANK_STATUS = 'BlankStatus';
export const MODEL_PRODUCER = 'Producer';
export const MODEL_PROTECTION_DEVICE = 'ProtectionDevice';
export const MODEL_TASK = 'TaskDispatcherCenter';
export const MODEL_OWNER = 'Owner';
export const MODEL_PROTECTION_DEVICE_COMPLEX = 'ProtectionDeviceComplex';
export const MODEL_VERSION = 'Version';
export const MODEL_IMPLEMENTATION_TYPE = 'ImplementationType';
export const MODEL_LETTER = 'Letter';
export const MODEL_TRADE_DEVICE = 'TradeDevice';
export const MODEL_TAG = 'Tag';
export const MODEL_DOCUMENT_STATUS = 'DocumentStatus';
export const MODEL_DOCUMENT_TYPE = 'DocumentType';
export const MODEL_EVENT = 'Event';
export const MODEL_STORAGE = 'Storage';
export const MODEL_SEARCH_REQUEST = 'SearchRequest';
export const MODEL_TERM = 'Term';
export const MODEL_DESCRIPTION = 'Description';

export const TAB_ACTIONS = 'TAB_ACTIONS';
export const TAB_MESSAGES = 'TAB_MESSAGES';

export const TEXT_PREFIX = 'JOURNAL_';
