import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  Button, Spin, Form, Select, message, Switch, Popover,
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import debounce from 'lodash/debounce';

import api from '@services/api';
import { closeSidebar } from '@state/sidebar/actions';
import { FormItem, SidebarLayout } from '@ui';
import { INPUT_TYPE_SELECT, INPUT_TYPE_TEXTAREA } from '@ui/formItem/constants';
import { LIBRARY_GLOBAL, LIBRARY_LOCAL } from '@globalConstants';
import { RootState } from '@state/store';
import { Storage, DispatcherCenterTree, User } from '@services/api-dts';
import { FormInstance } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import { SidebarData } from './types';

type InitialValues = {
  id?: string;
  url?: string;
  type?: string;
  is_active?: boolean;
  note?: string;
  dispatcher_center?: string;
};

interface Props extends WithTranslation {
  data: SidebarData;
  closeStorageForm: () => void;
  user: User;
}

interface State {
  loading: boolean;
  dispatcherCenters: DispatcherCenterTree[];
  initialValues: InitialValues | null;
  showGlobalStorageError: boolean;
}

class StorageSidebar extends Component<Props, State> {
  form = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
    this.onDCSearch = debounce(this.onDCSearch, 800);

    this.state = {
      loading: false,
      dispatcherCenters: [],
      initialValues: null,
      showGlobalStorageError: false,
    };
  }

  componentDidMount() {
    this.loadDispatcherCenters();
    this.setInitialValues();
  }

  loadStorages = async (params = {}) => {
    this.setState({ loading: true });

    const { data } = await api.getStorages(params);

    this.setState({ loading: false });

    return data.results;
  };

  setInitialValues = () => {
    const { data } = this.props;

    if (data.storage) {
      const storage: InitialValues = {
        ...data.storage,
        dispatcher_center: data.storage?.dispatcher_center?.id,
      };

      this.setState({
        initialValues: storage,
      }, () => this.form.current && this.form.current?.resetFields());
    } else {
      this.form.current && this.form.current.resetFields();
    }
  };

  loadDispatcherCenters = async (addParams = {}) => {
    const params = {};

    this.setState({ loading: true });
    const { status, data } = await api.getDispatcherCenters({ ...params, ...addParams });
    this.setState({ loading: false });

    if (status === 200) {
      this.setState({ dispatcherCenters: data.results });
    }
  };

  onDCSearch = (value: string) => {
    this.loadDispatcherCenters({ name: value });
  };

  onChangeDCSelect = (value: string | undefined) => {
    if (!value) this.loadDispatcherCenters();
  };

  createStorage = async (storageData: Store) => {
    const { t, data } = this.props;

    this.setState({ loading: true });

    const { status, data: response } = await api.createStorage(storageData);

    this.setState({ loading: false });

    if (status === 201) {
      message.success(t('CREATE_SUCCESS'));
      if (data.callback) data.callback(response);
    } else {
      message.error(t('CREATE_ERROR'));
    }
  };

  updateStorage = async (id: string | undefined, storageData: Store) => {
    const { t, data } = this.props;

    this.setState({ loading: true });

    const { status, data: response } = await api.updateStorage(id, storageData);

    this.setState({ loading: false });

    if (status === 200) {
      message.success(t('EDIT_SUCCESS'));
      if (data.callback) data.callback(response);
    } else {
      message.error(t('EDIT_ERROR'));
    }
  };

  formChangeHandle = () => {
    this.setState({ showGlobalStorageError: false });
  };

  handleSubmit = () => {
    const { t, data } = this.props;

    this.form.current && this.form.current.validateFields().then(async (values) => {
      // eslint-disable-next-line no-param-reassign
      if (!values.dispatcher_center) values.dispatcher_center = null;

      const dcStorages = await this.loadStorages({ dispatcher_center: values.dispatcher_center });
      let isStorageExists = false;

      dcStorages.forEach((storage: Storage) => {
        if (data.storage && data.storage.id === storage.id) {
          return;
        }
        const urls = [new URL(values.url), new URL(storage.url)];

        if (urls[0].origin === urls[1].origin && storage.type === values.type) {
          isStorageExists = true;
        }
      });

      if (isStorageExists) {
        message.error(t('STORAGE_EXISTS'));
        return;
      }

      if (values.type === LIBRARY_GLOBAL.toLowerCase() && values.is_active) {
        const globalStorages = await this.loadStorages({ type: 'global' });
        const activeStorage = globalStorages
          .filter((item: Storage) => (data.storage ? data.storage.id !== item.id : true))
          .find((item: Storage) => item.is_active);

        if (activeStorage) {
          this.setState({ showGlobalStorageError: true });
          return;
        } else {
          this.setState({ showGlobalStorageError: false });
        }
      }

      if (data.storage) {
        this.updateStorage(data.storage.id, values);
      } else {
        this.createStorage(values);
      }
    }).catch((err) => {
      console.error({ err });
      return (
        err
        && err.errorFields
        && err.errorFields[0]
        && err.errorFields[0].name
        && err.errorFields[0].name[0]
        && this.form.current
        && this.form.current?.scrollToField(err.errorFields[0].name[0])
      );
    });
  };

  resetFields = () => {
    this.setInitialValues();
  };

  render() {
    const {
      t, data, user, closeStorageForm,
    } = this.props;
    const {
      loading, dispatcherCenters, initialValues, showGlobalStorageError,
    } = this.state;
    const { storage } = data;
    const initialStorageType = (user.roles.find(role => role.name === t('ADMIN_IA')))
      ? LIBRARY_GLOBAL.toLowerCase()
      : LIBRARY_LOCAL.toLowerCase();

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{storage ? t('EDITING') : t('ADDING')} {t('STORAGES')}</h4>}
        footer={(
          <>
            <Button
              block
              onClick={closeStorageForm}
              className='bordered'
            >
              {t('CANCEL')}
            </Button>
            <Popover
              visible={showGlobalStorageError}
              content={[
                <ExclamationCircleFilled style={{ color: 'red' }} />,
                ' ',
                t('DISABLE_ACTIVE_GLOBAL_STORAGE'),
              ]}
              placement='topRight'
            >
              <Button
                block
                type='primary'
                onClick={this.handleSubmit}
              >
                {t('SAVE')}
              </Button>
            </Popover>
          </>
        )}
      >
        <Spin spinning={loading}>
          <Form
            ref={this.form}
            name='storages'
            initialValues={initialValues && Object.keys(initialValues).length ? initialValues : {
              type: initialStorageType,
              dispatcher_center: user && user.dispatcher_center.id,
              is_active: true,
            }}
            onValuesChange={this.formChangeHandle}
          >
            <FormItem
              id='url'
              label='URL'
              form={this.form.current}
              initialValue={storage && storage.url}
              placeholder='http(s)://<DNS-or-IP>/'
              rules={[
                {
                  type: 'url',
                  message: t('ERROR_TYPE_URL'),
                },
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
                {
                  max: 200,
                  message: t('ERROR_TYPE_URL_MAX_LENGTH_200'),
                },
              ]}
            />
            <FormItem
              id='type'
              initialValue={storage ? storage.type : initialStorageType}
              label={t('STORAGE_TYPE')}
              form={this.form.current}
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
              isReadOnly={!user.roles.some(role => role.name === t('ADMIN_IA'))}
              FormItemType={INPUT_TYPE_SELECT}
            >
              <Select.Option value={LIBRARY_LOCAL.toLowerCase()} key={1}>
                {t('LIBRARY_TYPE_LOCAL')}
              </Select.Option>
              <Select.Option value={LIBRARY_GLOBAL.toLowerCase()} key={2}>
                {t('LIBRARY_TYPE_GLOBAL')}
              </Select.Option>
            </FormItem>
            <Form.Item
              id='is_active'
              name='is_active'
              label={t('ACTIVITY')}
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>
            <FormItem
              id='note'
              label={t('COMMENT_DOC_TYPE')}
              form={this.form.current}
              FormItemType={INPUT_TYPE_TEXTAREA}
              rows={4}
              initialValue={storage && storage.note}
            />
            <FormItem
              id='dispatcher_center'
              label={t('DC')}
              placeholder={t('DC')}
              form={this.form.current}
              initialValue={
                (storage && storage.dispatcher_center && storage.dispatcher_center.id)
                || (user && user.dispatcher_center.id)
              }
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
              FormItemType={INPUT_TYPE_SELECT}
              showSearch
              filterOption={false}
              onSearch={(value: string) => this.onDCSearch(value)}
              onChange={(value: string) => this.onChangeDCSelect(value)}
            >
              {dispatcherCenters.map((item) => (
                <Select.Option value={item.id || ''} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </FormItem>
          </Form>
        </Spin>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user.info,
});

const mapDispatchToProps = {
  closeStorageForm: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    StorageSidebar
  )
);
