import React from 'react';

import Journal from '@common/journal';

const PassportJournal = (props) => {
  const { deviceId } = props;

  return (
    <div style={{ margin: '5px' }}>
      <Journal
        deviceId={deviceId}
        tableName='passport_tab_journal'
        passportMode
      />
    </div>
  );
};

export default PassportJournal;
