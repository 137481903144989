import cloneDeep from 'lodash/cloneDeep';
import { WS_RUN_ENDPOINT_MSG } from '@app/constants';
import { getId } from '@app/helpers';
import api from '@services/api';
import { WS_RUN_ENDPOINT } from '@state/websocket/types';

import { GET_TEMPLATES, GET_MORE_TEMPLATES, SAVE_TEMPLATES } from './types';

export const getTemplates = (component, params = {}) => {
  const { changePage, page: oldPageProps } = component.props;
  const { page: oldPageState } = component.state;

  return async (dispatch) => {
    dispatch({ type: `${GET_TEMPLATES}_START` });
    const { status, data, page: newPage } = await api.getTemplatesV3(params);

    if (status === 200) {
      dispatch({
        type: `${GET_TEMPLATES}_SUCCESS`,
        data: data,
      });
      if (changePage && newPage && oldPageProps !== newPage) {
        changePage(newPage);
      } else if (oldPageState && newPage && oldPageState !== newPage) {
        component.setState({ page: newPage });
      }
    } else {
      dispatch({ type: `${GET_TEMPLATES}_ERROR` });
    }
  };
};

export const getMoreTemplates = (params = {}) => {
  return async (dispatch) => {
    dispatch({ type: `${GET_MORE_TEMPLATES}_START` });
    const { status, data } = await api.getTemplatesV3(params);

    if (status === 200) {
      dispatch({
        type: `${GET_MORE_TEMPLATES}_SUCCESS`,
        data: data,
      });
    } else {
      dispatch({ type: `${GET_MORE_TEMPLATES}_ERROR` });
    }
  };
};

export const saveTemplates = (component, templateId, templateName, template, isDeleted = false) => {
  const {
    t, history, socket, isSocketConnected,
  } = component.props;

  return async (dispatch) => {
    if (socket && isSocketConnected) {
      const request = (
        templateId
          ? `/v1/templates-tree/${templateId}/?is_deleted=${isDeleted}`
          : `/v1/templates-tree/?is_deleted=${isDeleted}`
      );

      const method = (
        templateId
          ? 'patch'
          : 'post'
      );

      const frontId = getId(16);
      const title = `${t('CREATE_TEMPLATE_SAVE_SOCKET')}: ${templateName}`;

      await socket.send(JSON.stringify({
        message_type: WS_RUN_ENDPOINT_MSG,
        title: title,
        url: request,
        method: method,
        accept: 'application/json',
        front_id: `${SAVE_TEMPLATES}___${frontId}`,
        body: template,
      }));

      dispatch({
        type: `${SAVE_TEMPLATES}_START`,
        subType: SAVE_TEMPLATES,
        frontId,
        title,
        params: {
          frontId,
          title,
          templateId,
          oldState: component.state,
        },
      });

      dispatch({
        type: `${WS_RUN_ENDPOINT}_START`,
        subType: SAVE_TEMPLATES,
        frontId,
        title,
        dispatch,
      });

      component.setState({
        isLoading: false,
        isNewTemplate: false,
        isSaveAsNewTemplate: false,
        showButtons: false,
      });

      history.push('/templates/');
    }
  };
};

export const clearTemplatesError = (frontId) => {
  return async (dispatch) => {
    dispatch({
      type: `${SAVE_TEMPLATES}_ERROR_END`,
      frontId,
    });
  };
};

export const clearTemplatesSuccess = (frontId) => {
  return async (dispatch) => {
    dispatch({
      type: `${SAVE_TEMPLATES}_SUCCESS_END`,
      frontId,
    });
  };
};

// Selectors
export const templatesWithChildrenSelector = (state) => {
  return state.templates.templates.map((template) => {
    const nextTemplate = cloneDeep(template);

    if (template.family && template.family.length) {
      nextTemplate.children = [...template.family];
    }

    return nextTemplate;
  });
};
