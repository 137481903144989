import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setAppStage } from '@state/appStage/actions';
import { Spin } from 'antd';
import { refreshToken } from "@state/user/actions";

import styles from './styles.module.less';

class Initial extends Component {
  componentDidMount() {
    const { refreshToken } = this.props;
    refreshToken();
  }

  render() {
    return (
      <Spin spinning={true} className={styles.content} tip='Выполняется попытка автоматической авторизации пользователя...' />
    );
  }
}

const mapDispatchToProps = {
  setAppStageData: setAppStage,
  refreshToken: refreshToken,
};

export default connect(null, mapDispatchToProps)(
  Initial
);