import React, { Component } from 'react';
import { TreeSelect, Spin } from 'antd';

import { CustomSelect } from '@ui';

class SelectInfiniteScroll extends Component {
  handleUpdate = (el) => {
    const { loadMoreHandle, loading } = this.props;

    el.persist();
    const { target } = el;

    const scrollTop = Math.round(target.scrollTop);
    const offsetHeight = Math.round(target.offsetHeight);
    const scrollHeight = Math.round(target.scrollHeight);
    let nearbySumm = scrollTop + offsetHeight;

    if (scrollHeight - nearbySumm === 1) nearbySumm += 1;

    if (nearbySumm === scrollHeight) {
      !loading && loadMoreHandle && loadMoreHandle();
    }
  };

  render() {
    const {
      children, loading, loadMoreHandle, className, treeMode, ...restProps
    } = this.props;
    const SelectComponent = treeMode ? TreeSelect : CustomSelect;

    return (
      <SelectComponent
        className={className}
        onPopupScroll={!treeMode && ((e) => this.handleUpdate(e))}
        onScroll={treeMode && ((e) => this.handleUpdate(e))}
        dropdownRender={(menu) => (
          <Spin spinning={loading}>
            {menu}
          </Spin>
        )}
        loading={loading}
        {...restProps}
      >
        {children}
      </SelectComponent>
    );
  }
}

export default SelectInfiniteScroll;
