import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Tooltip } from 'antd';

import api from '@services/api';
import { ExportBottom } from '@common/exportPopconfirm';
import FixedYCommonTable from '@common/FixedYCommonTable';
import { PopoverEllipsis, PopoverLoadingTags } from '@ui';

import AbstractTab from '../../abstractTab';
import ResetTableBtn from '../../ResetTableBtn';
import { globalSearchTableNamePrefix } from '../../constants';

import styles from './styles.module.less';
import Text from 'antd/lib/typography/Text';

const prefix = 'TASKS';
const filters = [
  {
    name: 'TASKS.COL_NAME',
    field: 'name',
    queryFiled: 'name',
    type: 'STRING',
    width: '25%',
    fixed: 'left',
    initialWidth: 450,
    visible: true,
    customRender: (record, content, keyId) => {
      let link = '';

      if (record.passports?.length > 0) link = (
        <Button
          type='link'
          size='small'
          className='small-font'
          key={`link_${record.id}_${keyId}`}
        >
          <Link
            to={`/passport/${record.passports[0].protection_device.id}/tab/tasks/?id=${record.id}&name=${record.name}`}
          >
            {content}
          </Link>
        </Button>
      );
      else link = content;

      return (
        <PopoverEllipsis
          content={content}
          containerStyles={{ color: '#1890ff', wordBreak: 'normal' }}
        >
          {link}
        </PopoverEllipsis>
      )
    },
  },
  {
    name: 'TASKS.COL_DISPATCHER_CENTER',
    field: 'dispatcher_centers',
    queryFiled: 'dispatcher_center',
    type: 'CHECK_LIST',
    width: '10%',
    initialWidth: 200,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected, filterValuesList) => {
      const params = {
        name,
        selected,
        owner: filterValuesList['DEVICE.COL_OWNER'],
      };

      return api.getDataForFilter('/v1/dispatcher-centers/', params);
    },
  },
  {
    name: 'TASKS.COL_PASSPORTS',
    field: 'passports',
    queryFiled: 'passports',
    type: 'CHECK_LIST',
    width: '12%',
    initialWidth: 120,
    visible: true,
    extractIdAttr: 'passports',
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/protection-devices/short/', { name, selected });
    },
    render: (arr, record) => {
      if (!record?.passports) return null;
      const { passports } = record;

      if (record.passports.length === 1) {
        const { id, name } = passports[0].protection_device;
        return (<Button
          type='link'
          size='small'
          key={`link_${id}_${name}`}
          className={styles.passportLink}
        >
          <Link
            to={`/passport/${id}/tab/tasks/?id=${record.id}&name=${record.name}`}
          >
            {name}
          </Link>
        </Button>)
      }

      return <PopoverLoadingTags
        size={-1}
        title='TASKS.COL_PASSPORTS'
        className='link'
        passportsEntity={true}
        loadTags={() => {
          return passports;
        }}
        link={(passportId) => `/passport/${passportId}/tab/tasks/?id=${record.id}&name=${record.name}`}
      />
    },
  },
  {
    name: 'TASKS.COL_PLANNED_START_DATE',
    field: 'date_planned_start',
    type: 'ONLY_VIEW_COLUMN',
    width: '6%',
    initialWidth: 75,
    visible: true,
  },
  {
    name: 'TASKS.COL_ACTUAL_START_DATE',
    field: 'date_actual_start',
    type: 'ONLY_VIEW_COLUMN',
    width: '6%',
    initialWidth: 75,
    visible: true,
  },
  {
    name: 'TASKS.COL_PLANNED_COMPLETION_DATE',
    field: 'date_planned_completion',
    type: 'ONLY_VIEW_COLUMN',
    width: '6%',
    initialWidth: 75,
    visible: true,
    customRender: (record, content, keyId) => {
      const { timeframe } = record;
      if (timeframe) return <Tooltip title={timeframe}><Text type='secondary'>{timeframe}</Text></Tooltip>;
      return content;
    }
  },
  {
    name: 'TASKS.COL_ACTUAL_COMPLETION_DATE',
    field: 'date_actual_completion',
    type: 'ONLY_VIEW_COLUMN',
    width: '6%',
    initialWidth: 75,
    visible: true,
  },
  {
    name: 'TASKS.COL_EXECUTION_DATE',
    field: 'execution_date',
    queryFiled: 'execution_date',
    suffix: ['from', 'to'],
    type: 'DATE_RANGE',
    width: '6%',
    initialWidth: 75,
    visible: true,
  },
  {
    name: 'TASKS.COL_PERIOD',
    field: '',
    queryFiled: 'date',
    suffix: ['from', 'to'],
    type: 'DATE_RANGE',
    width: '8%',
    initialWidth: 80,
    visible: false,
  },
  {
    name: 'TASKS.COL_STATUS',
    field: 'status',
    queryFiled: 'status',
    type: 'CHECK_LIST',
    width: '8%',
    initialWidth: 200,
    visible: true,
    getPopupContainer: (triggerNode) => (triggerNode.closest('tr')),
    getList: (name, selected) => {
      return api.getDataForFilter('/v1/task-dc/statuses', { name, selected });
    },
    render: (status) => ((status && status.current_status && status.current_status.name) || ''),
  },
  {
    name: 'TASKS.COL_REASON',
    field: 'reason',
    type: 'ONLY_VIEW_COLUMN',
    width: '25%',
    initialWidth: 450,
    visible: true,
  },
];

class Tasks extends Component {
  tableName = `${globalSearchTableNamePrefix}${prefix.toLowerCase()}`;

  componentDidMount() {
    const { setTableName, setInitialFilters } = this.props;

    setTableName(this.tableName, () => {
      setInitialFilters();
    });
  }

  render() {
    const {
      t, data, count, isLoading, countFiltersWithValues, getListProps, searchValue,
      getColumns, onShowSizeChange, toggleFilter, filterValuesForExport, loadData, id,
      isActiveResetTableBtn, handleActivateResetTableBtn, handleResetTable,
    } = this.props;

    const listProps = getListProps();
    const columns = getColumns(prefix);

    let tableWidth = 0;

    columns.forEach((item) => {
      if (item.initialWidth) tableWidth += item.initialWidth;
    });

    const columnsWithWidth = columns.filter((item) => !!item.width);

    if (columnsWithWidth.length) {
      const hiddenColumns = filters.filter((filter) => !columnsWithWidth.find((column) => column.key === filter.field));

      if (hiddenColumns.length) {
        let hiddenWidth = 0;

        hiddenColumns.forEach((col) => {
          hiddenWidth += parseFloat(col.width);
        });

        columnsWithWidth.forEach((column) => {
          column.width = parseFloat(column.width) + hiddenWidth / columnsWithWidth.length;
        });
      }
    }

    return (
      <Card
        className={styles.card}
        extra={(
          <>
            <Badge offset={[-6, 3]} className={styles.badge} count={countFiltersWithValues} size='small'>
              <Button onClick={toggleFilter} size='small'>{t('FILTERS_AND_CONFIGURATIONS')}</Button>
            </Badge>
            <ResetTableBtn
              disabled={!isActiveResetTableBtn}
              onClick={handleResetTable}
            />
          </>
        )}
        bordered={false}
      >
        <FixedYCommonTable
          {...listProps}
          tableName={this.tableName}
          className={styles.table}
          columns={columns}
          dataSource={data}
          width={tableWidth}
          loading={isLoading}
          handleActivateResetTableBtn={handleActivateResetTableBtn}
          onShowSizeChange={
            (current, pageSize) => onShowSizeChange(current, pageSize, this.tableName, loadData)
          }
        />
        <ExportBottom
          count={count}
          WSData={{
            url: `/v1/${id}/`,
            params: { ...filterValuesForExport, search: searchValue },
            accept: 'application/xlsx',
          }}
        />
      </Card>
    );
  }
}

const Tab = AbstractTab(
  withTranslation()(
    Tasks
  )
);

export {
  Tab,
  filters,
  prefix,
};
