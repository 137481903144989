import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { cloneDeep, isEqual } from 'lodash';

import { RootState } from '@state/store';
import { InterfaceRightsConstants } from '@state/rights/types';
import { closeSidebar, openSidebar } from '@state/sidebar/actions';

import {
  Alert,
  Button, message, Popconfirm
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { TableRowSelection } from 'antd/lib/table/interface';

import { Column } from '@common/CommonTable/types';
import CommonTable from '@common/CommonTable';
import { ATTACH_TASK_TO_LETTER } from '@common/sidebarRoot/types';
import { MODE_EDIT, RIGHT_PASSPORT_LETTERS, TABLE_SIZE, RIGHT_PASSPORT_TASKS } from '@globalConstants';
import { getObjectFromUrl, textHighlighter } from '@globalHelpers';
import { withPagination, withColumnFilters, withRowSelection } from '@hoc';
import api from '@services/api';
import { Letter } from '@services/api-dts';
import { CustomCard } from '@ui';
import styles from './styles.module.less';

import { AttachTaskSidebarData } from './types';

import ModalSearchLetter from './ModalSearchLetter';
import LetterFiles from './LetterFiles';
import EntitiesAsTags from '@common/entitiesAsTags';

// TODO refactor types after refactoring HOC
interface Props extends WithTranslation, RouteComponentProps {
  selectedItem: Letter;
  onSelectItem: (item: Letter | null, callback?: Function) => void;
  openAttachTask: (type: string, data: AttachTaskSidebarData) => void;
  closeAttachTask: () => void;
  deviceId: string;
  passportId: string;
  page: number;
  limit: number;
  setTableName: (tableName: string|null, callback?: Function) => void;
  changePage: (page: number, callback?: () => void) => void;
  onShowSizeChange: (current: number, size: number, name?: string, callback?: Function) => void;
  getColumnSearchFilter: (
    dataIndex: string,
    requestParam: string,
    searchCallback: (() => void) | null,
    resetCallback: (() => void) | null,
  ) => {};
  filterValues: {
    name__icontains: string;
  };
  handleSearch: (
    selectedKeys: string[] | string,
    confirm: (() => void) | null,
    requestParam: string,
    callback?: (() => void) | null,
  ) => void;
  loadByPage: (
    tableName: string|null,
    apiFunctionName: string,
    mainParams: {},
    okStatuses: number[] | number,
    callbackOk?: ((status: number, data: { results: Letter[]; count: number }) => void) | null,
    callbackError?: (() => void) | null
  ) => void;
  rights: InterfaceRightsConstants;
  isArchived: boolean;
}

interface State {
  isLoading: boolean;
  addLetterVisibility: boolean;
  lettersData: Letter[];
  count: number;
  isDeleting: boolean;
}

class Letters extends Component<Props, State> {
  tableName = 'passport_tab_letters';

  state: State = {
    isLoading: false,
    addLetterVisibility: false,
    lettersData: [],
    count: 0,
    isDeleting: false,
  };

  componentDidMount = async () => {
    const { passportId, setTableName } = this.props;

    setTableName(this.tableName, async () => {
      if (passportId) {
        const res = await this.loadQueryParams();
        if (!res) {
          await this.loadLetters();
        }
      }
    });
  };

  componentDidUpdate = async (prevProps: Readonly<Props>) => {
    const { deviceId, passportId, location } = this.props;

    if (
      passportId &&
      (prevProps.passportId !== passportId ||
        (!isEqual(location, prevProps.location) &&
          location.search &&
          location.search.length &&
          prevProps.deviceId === deviceId))
    ) {
      const res = await this.loadQueryParams();
      if (!res) {
        await this.loadLetters();
      }
    }
  };

  loadQueryParams = async () => {
    const { location, passportId } = this.props;

    const letterFromUrl: { id?: string; name?: string } = getObjectFromUrl(location, ['id', 'name']);

    if (passportId && letterFromUrl.id) {
      await this.filterLettersByIdName(letterFromUrl.id);
      return true;
    }
    return false;
  };

  filterLettersByIdName = async (id: string) => {
    const { onSelectItem, changePage } = this.props;

    changePage(1, async () => {
      await this.loadLetters({ selected: id }, () => {
        const { lettersData } = this.state;
        onSelectItem(lettersData[0]);
      });
    });
  };

  loadLetters = async (addParams = {}, callback?: Function) => {
    const { passportId, filterValues, loadByPage } = this.props;

    this.setState({ isLoading: true });

    await loadByPage(
      this.tableName,
      'getLetters',
      { passport: passportId, ...filterValues, ...addParams },
      200,
      (status, data) => {
        this.setState(
          {
            lettersData: data.results,
            count: data.count,
          },
          () => {
            callback && callback();
          }
        );
      }
    );

    this.setState({ isLoading: false });
  };

  handleResetCallback = () => {
    const { onSelectItem, changePage } = this.props;

    onSelectItem(null);
    changePage(1);
  };

  getColumnLetters = (): Column[] => {
    const { t, deviceId, getColumnSearchFilter, filterValues, rights } = this.props;

    const isTasksAllowed = rights[RIGHT_PASSPORT_TASKS] || false;

    return [
      {
        title: t('NAME'),
        dataIndex: 'name',
        key: 'name',
        width: '12.5%',
        ...getColumnSearchFilter('name', 'name__icontains', null, this.handleResetCallback),
        render: (text: string) => textHighlighter(filterValues['name__icontains'], text),
      },
      {
        title: `${t('REG')}. №`,
        dataIndex: 'registration_number',
        key: 'registration_number',
        width: '12.5%',
      },
      {
        title: `${t('REG')}. ${t('DATE')}`,
        dataIndex: 'registration_date',
        key: 'registration_date',
        width: '12.5%',
      },
      {
        title: t('JOURNAL_TYPE'),
        dataIndex: 'letter_type',
        key: 'letter_type',
        width: '12.5%',
        render: (type: string) => t(`${type.toUpperCase()}_CORRESPONDENCE`, type),
      },
      {
        title: t('ADDRESSEE'),
        dataIndex: 'recipient',
        key: 'recipient',
        width: '12.5%',
      },
      {
        title: t('SHORT_DESCRIPTION'),
        dataIndex: 'commentary',
        key: 'commentary',
        width: '12.5%',
      },
      {
        title: t('TASK_DC'),
        dataIndex: 'task_dispatcher_centers',
        key: 'task_dispatcher_centers',
        width: '12.5%',
        render: (tasksDC: { id: string; name: string }[] | null, record) => {
          const link = ''.concat('/passport/#task_device_id/tab/tasks/', '?id=#task_id', '&name=#task_name', '');
          return (
            <EntitiesAsTags
              //@ts-ignore
              entityName={'tasks'}
              entityNameSingle={'task'}
              localizedEntityName={'Задания ДЦ'}
              entities={tasksDC}
              record={record}
              currentDeviceId={deviceId}
              link={link}
              isViewPassportsCount
              isAllowed={isTasksAllowed}
              sourceTab={'letters'}
            />
          );
        },
      },
      {
        title: t('USER_DC'),
        dataIndex: 'dispatcher_center',
        key: 'dispatcher_center',
        width: '12.5%',
        render: (DC: { id: string; name: string } | null) => DC?.name,
      },
    ];
  };

  showAddLetterModal = () => {
    this.setState({
      addLetterVisibility: true,
    });
  };

  hideAddLetterModal = () => {
    this.setState({
      addLetterVisibility: false,
    });
  };

  handleClickAttachTask = async () => {
    const { passportId, openAttachTask, closeAttachTask, selectedItem, t } = this.props;

    const selectedItemCopy = cloneDeep(selectedItem);

    await this.loadLetters();

    const letters = this.state.lettersData;
    const { id } = selectedItemCopy;

    const item = letters.find((letter) => letter.id === id);

    if (!item) {
      message.error(t('PASSPORT_LETTERS_TABLE_ATTACH_TASK_DC_ERROR'), 5);
      return;
    }

    openAttachTask(ATTACH_TASK_TO_LETTER, {
      passportId,
      letter: item,
      callback: async (response: Letter) => {
        await this.changeLetterListCallback(response);

        closeAttachTask();
      },
    });
  };

  changeLetterListCallback = async (response: Letter) => {
    const { onSelectItem, changePage } = this.props;

    changePage(1, async () => {
      await this.loadLetters({ selected: response.id }, () => {
        const { lettersData } = this.state;
        onSelectItem(lettersData[0]);
      });
    });
  };

  onDeleteLetter = async () => {
    const { t, selectedItem, onSelectItem, passportId } = this.props;

    this.setState({ isDeleting: true });

    const { status } = await api.deleteLetter(selectedItem.id, passportId);

    this.setState({ isDeleting: false });

    if (status === 204) {
      message.success(t('DELETE_SUCCESS'));
      await this.loadLetters();
      onSelectItem(null);
    } else {
      message.error(t('DELETE_ERROR'));
    }
  };

  isLetterHasTasksContainsOutsidePassport = (selectedLetter: Letter, currentDeviceId: string) => {
    if (!selectedLetter) return false;

    const dcs = selectedLetter?.task_dispatcher_centers;
    if (!dcs) return false;

    for (let i = 0; i < dcs.length; i++) {
      const task = dcs[i];

      if (!task?.protection_device) continue;
      const devices = task.protection_device;

      const index = devices.findIndex((device) => device.id !== currentDeviceId);
      if (index !== -1) return true;
    }

    return false;
  };

  renderDeletionPopconfirmTitle = (hasAttachedTask: boolean) => {
    const { t } = this.props;
    if (!hasAttachedTask) return t('DELETE_LETTER_MSG');
    return (
      <>
        {t('DELETE_LETTER_MSG')}
        <Alert
          type='warning'
          className={styles['popconfirm-alert']}
          message={t('DELETE_LETTER_MSG_HAS_TASK_ATTACHED')}
        />
      </>
    );
  };

  render() {
    const {
      t,
      passportId,
      selectedItem,
      onSelectItem,
      limit,
      page,
      changePage,
      onShowSizeChange,
      rights,
      isArchived,
      deviceId,
    } = this.props;
    const { isLoading, addLetterVisibility, lettersData, count, isDeleting } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_LETTERS] === MODE_EDIT && !isArchived;
    const hasAttachedTask = this.isLetterHasTasksContainsOutsidePassport(selectedItem, deviceId);
    const deletionPopconfirmTitle = this.renderDeletionPopconfirmTitle(hasAttachedTask);

    return (
      <>
        <CustomCard
          style={{ margin: '5px' }}
          title={t('LETTERS')}
          extra={
            <>
              <Button
                size='small'
                className='control-button'
                disabled={!Object.keys(selectedItem).length || !isEditRight}
                onClick={this.handleClickAttachTask}
              >
                {t('ATTACH_TASK_DC')}
              </Button>
              <Button size='small' className='control-button' onClick={this.showAddLetterModal} disabled={!isEditRight}>
                {t('ADD_LETTER')}
              </Button>
              <Popconfirm
                placement='topLeft'
                title={deletionPopconfirmTitle}
                okText={t('DELETE')}
                cancelText={t('CANCEL')}
                onConfirm={this.onDeleteLetter}
                disabled={!Object.keys(selectedItem).length || !isEditRight}
              >
                <Button
                  disabled={!Object.keys(selectedItem).length || !isEditRight}
                  loading={isDeleting}
                  className='control-button'
                  size='small'
                  icon={<DeleteOutlined />}
                >
                  {t('DELETE')}
                </Button>
              </Popconfirm>
            </>
          }
        >
          <CommonTable
            tableName={this.tableName}
            size={TABLE_SIZE}
            loading={isLoading}
            columns={this.getColumnLetters()}
            dataSource={lettersData}
            rowSelection={
              {
                type: 'radio',
                selectedRowKeys: selectedItem.id ? [selectedItem.id] : [''],
                onChange: (selectedRowKeys: Array<string>, selectedRows: Array<Letter>): void => {
                  onSelectItem(selectedRows[0] as Letter);
                },
              } as TableRowSelection<object>
            }
            pagination={{
              onChange: (newPage: number) => changePage(newPage, this.loadLetters),
              total: count,
              pageSize: limit,
              current: page,
            }}
            onShowSizeChange={(current: number, pageSize: number) =>
              onShowSizeChange(current, pageSize, this.tableName, this.loadLetters)
            }
          />
        </CustomCard>
        {selectedItem.id && <LetterFiles isArchived={isArchived} letter={selectedItem} passportId={passportId} />}
        <ModalSearchLetter
          passportId={passportId}
          addLetterVisibility={addLetterVisibility}
          hideAddLetterModal={this.hideAddLetterModal}
          changeLetterListCallback={this.changeLetterListCallback}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  rights: state.rights.rightsData,
});

const mapDispatchToProps = {
  openAttachTask: openSidebar,
  closeAttachTask: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withPagination(
      withColumnFilters(
        withRowSelection(
          withRouter(
            Letters
          )
        )
      )
    )
  )
);
