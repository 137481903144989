import React from 'react';
import {
  Button, Checkbox, Collapse, Form, Input, Select, Tag,
} from 'antd';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';

import api from '@services/api';
import { CustomSelect, SidebarLayout } from '@ui';

import styles from './styles.module.less';

class PassportFunctionSidebar extends React.Component {
  form = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      dcsOptions: [],
      functionsOptions: [],
      collapsed: {},
      selectedTags: {},
      dcsSearchValue: '',
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { selected } = data;

    if (selected) {
      const formValue = {
        function: selected.function.id,
        execute_calculations: selected.execute_calculations.map((x) => ({ key: x.id, label: x.name })),
        reconcile_calculations: selected.reconcile_calculations.map((x) => ({ key: x.id, label: x.name })),
        compose_task: selected.compose_task.map((x) => ({ key: x.id, label: x.name })),
        reconcile_task: selected.reconcile_task.map((x) => ({ key: x.id, label: x.name })),
        issue_task: selected.issue_task.map((x) => ({ key: x.id, label: x.name })),
        inefficiency: !!selected.inefficiency,
        reason: selected.reason,
      };

      this.setState({
        functionsOptions: [{ id: selected.function.id, name: selected.function.name }],
        dcsOptions: this.removeDuplicates([
          ...selected.execute_calculations,
          ...selected.reconcile_calculations,
          ...selected.compose_task,
          ...selected.reconcile_task,
          ...selected.issue_task,
        ], (x) => x.id),
        isActiveReason: formValue.inefficiency,
      }, () => {
        this.setDcs(selected, formValue, 'execute_calculations');
        this.setDcs(selected, formValue, 'reconcile_calculations');
        this.setDcs(selected, formValue, 'compose_task');
        this.setDcs(selected, formValue, 'reconcile_task');
        this.setDcs(selected, formValue, 'issue_task');
        this.setState({}, () => this.form.current.setFieldsValue(formValue));
      });
    }
  }

  handleDcsSearch = async (value, offset) => {
    this.setState({ dcsSearchValue: value });
    const data = await api.getDataForFilter('/v1/dispatcher-centers/', { name: value }, null, offset);
    if (data.status === 200) {
      this.setState((state) => ({
        dcsOptions: offset ? [...state.dcsOptions, ...data.data.results] : data.data.results,
      }));
    }
  };

  handleFunctionsSearch = async (value) => {
    const data = await api.getFunctionsForFilter(value);
    if (data.status === 200) {
      this.setState({ functionsOptions: data.data.results });
    }
  };

  clearDcsOptions = () => {
    this.setState({ dcsOptions: [] });
  }

  clearFunctionsOptions = () => {
    this.setState({ functionsOptions: [] });
  }

  getDcs = (form, field) => {
    return form[field].map(({ key }) => {
      const temporary = form[`${field}_is_temporary_${key}`];
      const comment = form[`${field}_comment_${key}`];

      return {
        [field]: key,
        temporary: temporary === undefined ? false : temporary,
        comment: comment === undefined ? null : comment,
      };
    });
  }

  setDcs = (data, form, field) => {
    form[field].forEach(({ key }) => {
      const item = data[field].find((x) => x.id === key);

      if (item) {
        // eslint-disable-next-line
        form[`${field}_is_temporary_${key}`] = item.temporary;
        // eslint-disable-next-line
        form[`${field}_comment_${key}`] = item.comment;
      }
    });
  }

  tagRender = (baseField) => ({ label, value, onClose }) => {
    const { selectedTags } = this.state;

    const selectField = () => (
      this.form.current
      && this.form.current.getFieldValue(`${baseField}_select`)
    );

    const isDisableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP !== undefined;

    return (
      <Tag
        className={`${(selectField()
          && selectedTags[baseField]
          && selectField().value === value)
          && styles.selectedTag} ${styles.selectTag}`}
        onClick={() => {
          const newValue = selectedTags[baseField] && selectField().value === value ? undefined : value;

          this.form.current && this.form.current.setFieldsValue({
            [`${baseField}_select`]: newValue ? { value, label, key: value } : {},
          });
          this.setState({ selectedTags: { ...selectedTags, [baseField]: newValue } });
        }}
        closable={!isDisableEditAIP}
        onClose={
          isDisableEditAIP
            ? (() => {})
            : ((e) => {
              if (selectField() && selectField().value === value) {
                this.form.current && this.form.current.setFieldsValue({
                  [`${baseField}_select`]: { key: 'none', label: '', value: 'none' },
                });
                this.setState({
                  selectedTags: {
                    ...selectedTags,
                    [baseField]: undefined,
                  },
                });
              }
              onClose(e);
            })
        }
      >
        {label}
      </Tag>
    );
  }

  sendData = () => {
    const { data } = this.props;

    this.form.current.validateFields().then(async (values) => {
      const resultValues = {
        execute_calculations: this.getDcs(values, 'execute_calculations'),
        reconcile_calculations: this.getDcs(values, 'reconcile_calculations'),
        compose_task: this.getDcs(values, 'compose_task'),
        reconcile_task: this.getDcs(values, 'reconcile_task'),
        issue_task: this.getDcs(values, 'issue_task'),
        inefficiency: !!values.inefficiency,
      };

      if (values.inefficiency) {
        resultValues.reason = values.reason;
      }

      this.setState({ isSending: true });
      await data.onOk(resultValues);
      this.setState({ isSending: false });
    }).catch((err) => this.form.current.scrollToField(err[0] && err[0].name));
  }

  noteFields = (commentaryField, temporaryField, baseField) => {
    const { t } = this.props;
    const { collapsed, selectedTags } = this.state;

    const baseValue = this.form.current && this.form.current.getFieldValue(baseField);
    let selectedKey = 0;

    if (this.form.current && this.form.current.getFieldValue(baseField)) {
      selectedKey = this.form.current.getFieldValue(baseField).findIndex(({ key }) => key === selectedTags[baseField]);
    }

    const selectField = get(
      this.form.current && this.form.current.getFieldValue(baseField), selectedKey >= 0 ? selectedKey : 0, {}
    );

    return (
      <Collapse
        activeKey={
          baseValue
          && baseValue.length > 0
          && collapsed[baseField]
          && collapsed[baseField].length > 0
          && selectedTags[baseField] !== undefined
            ? collapsed[baseField]
            : []
        }
        onChange={(x) => this.setState((state) => ({ collapsed: { ...state.collapsed, [baseField]: x } }))}
      >
        <Collapse.Panel
          key={baseField.key}
          forceRender={true}
          isActive={false}
          header={t('PASSPORT_FUNCTIONS_SIDEBAR_NOTE')}
          disabled={(baseValue && baseValue.length === 0) || selectedTags[baseField] === undefined}
        >
          {baseValue && (
            <>
              {[{ key: 'none', label: '' }, ...baseValue].map(({ key }, i) => (
                [
                  (
                    <Form.Item
                      // eslint-disable-next-line
                      key={`check_${i}`}
                      style={(selectField && selectField.key === key) || (!selectField && key === 'none') ? {} : { display: 'none' }}
                      label={t('PASSPORT_FUNCTIONS_SIDEBAR_IS_TEMPORARY')}
                      name={`${temporaryField}_${key}`}
                      valuePropName='checked'
                    >
                      <Checkbox disabled={!selectField || selectField.key === 'none'} />
                    </Form.Item>
                  ),
                  (
                    <Form.Item
                      // eslint-disable-next-line
                      key={`comment_${i}`}
                      style={(selectField && selectField.key === key) || (!selectField && key === 'none') ? {} : { display: 'none' }}
                      label={t('PASSPORT_FUNCTIONS_SIDEBAR_COMMENT')}
                      name={`${commentaryField}_${key}`}
                    >
                      <Input.TextArea disabled={!selectField || selectField.key === 'none'} rows={6} />
                    </Form.Item>
                  ),
                ]
              ))}
            </>
          )}
        </Collapse.Panel>
      </Collapse>
    );
  }

  handleSelectScroll = (scroll) => {
    const { dcsOptions, dcsSearchValue } = this.state;
    const needToLoad = scroll.nativeEvent.target.scrollTop - ((dcsOptions.length - 9) * 32 + 38) === 0;

    if (needToLoad) {
      this.handleDcsSearch(dcsSearchValue, dcsOptions.length);
    }
  }

  handleChangeUneffective = (e) => {
    const { target } = e;

    this.setState({ isActiveReason: target.checked });
  };

  validateReason = (rule, value) => {
    const { t } = this.props;

    if (!value) {
      throw new Error(t('REQUIRED_FIELD'));
    } else {
      return Promise.resolve();
    }
  };

  generateFormFieldForDC = (name) => {
    const { t } = this.props;
    const { dcsOptions } = this.state;

    const isDisableEditAIP = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP !== undefined;
    const options = dcsOptions.map((x) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>);

    return (
      <>
        <Form.Item
          label={t(`PASSPORT_FUNCTIONS_SIDEBAR_${name.toUpperCase()}`)}
          name={name}
        >
          {
            isDisableEditAIP
              ? (
                <CustomSelect
                  tagRender={this.tagRender(name)}
                  labelInValue={true}
                  mode='multiple'
                  disabled={true}
                  popoverPlacement='left'
                >
                  {options}
                </CustomSelect>
              )
              : (
                <CustomSelect
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  tagRender={this.tagRender(name)}
                  labelInValue={true}
                  onPopupScroll={(e) => this.handleSelectScroll(e)}
                  autoClearSearchValue={true}
                  mode='multiple'
                  onDropdownVisibleChange={(x) => x && this.handleDcsSearch('')}
                  showSearch={true}
                  filterOption={false}
                  placeholder={t('PASSPORT_FUNCTIONS_SIDEBAR_CHOOSE_SOME_DC')}
                  onSearch={this.handleDcsSearch}
                  onBlur={this.clearDcsOptions}
                  popoverPlacement='left'
                >
                  {options}
                </CustomSelect>
              )
          }
        </Form.Item>
        {this.noteFields(`${name}_comment`, `${name}_is_temporary`, name)}
      </>
    );
  }

  removeDuplicates(arr, comp) {
    return arr
      .map(comp)
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);
  }

  render() {
    const { data, t } = this.props;
    const { functionsOptions, isSending, isActiveReason } = this.state;
    const { selected } = data;

    return (
      <SidebarLayout
        withoutCard
        header={<h4>{t(selected ? 'PASSPORT_FUNCTIONS_SIDEBAR_EDIT_FUNCTION' : 'PASSPORT_FUNCTIONS_SIDEBAR_ADD_FUNCTION')}</h4>}
        footer={(
          <>
            <Button
              block
              type='primary'
              autoFocus
              onClick={this.sendData}
              loading={isSending}
              disabled={isSending}
            >
              {t('PASSPORT_FUNCTIONS_SIDEBAR_SAVE_FUNCTION')}
            </Button>
          </>
        )}
      >
        <Form className={styles.passportFunctionsSidebar} ref={this.form}>
          <Form.Item
            label={t('PASSPORT_FUNCTIONS_SIDEBAR_NAME')}
            name='name'
            initialValue={selected.name || t('ABSENT')}
            disabled={true}
          >
            <Input
              value={selected.name || t('ABSENT')}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label={t('FUNCTION_TYPE')}
            name='function'
            rules={[{ required: true, message: t('PASSPORT_FUNCTIONS_SIDEBAR_REQUIRED_FIELD') }]}
          >
            <CustomSelect
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              autoClearSearchValue={true}
              showSearch={true}
              disabled={!!selected}
              onDropdownVisibleChange={(x) => x && this.handleFunctionsSearch('')}
              filterOption={false}
              placeholder={t('PASSPORT_FUNCTIONS_SIDEBAR_CHOOSE_FUNCTION')}
              onSearch={this.handleFunctionsSearch}
              onBlur={this.clearFunctionsOptions}
              popoverPlacement='left'
            >
              {functionsOptions.map((x) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
            </CustomSelect>
          </Form.Item>
          {this.generateFormFieldForDC('execute_calculations')}
          {this.generateFormFieldForDC('reconcile_calculations')}
          {this.generateFormFieldForDC('compose_task')}
          {this.generateFormFieldForDC('reconcile_task')}
          {this.generateFormFieldForDC('issue_task')}
          <Form.Item
            label={t('NOT_EFFECTIVE')}
            name='inefficiency'
            valuePropName='checked'
          >
            <Checkbox
              onChange={this.handleChangeUneffective}
            />
          </Form.Item>
          {isActiveReason && (
            <Form.Item
              label={t('REASON')}
              name='reason'
              rules={[{ validator: this.validateReason }]}
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          )}
        </Form>
      </SidebarLayout>
    );
  }
}

export default withTranslation()(
  PassportFunctionSidebar
);
