import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Badge, Button, Checkbox, Input, message, Popconfirm, Select } from 'antd';
import {
  DownloadOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  EditOutlined,
} from '@ant-design/icons';

import api from '@services/api';
import CommonTable from '@common/CommonTable';
import { ExportBottom } from '@common/exportPopconfirm';
import { isEqual } from 'lodash';
import { CustomEmpty, CustomCard, PopoverEllipsis } from '@ui';
import { TABLE_SIZE } from '@globalConstants';

import { IS_ISSUED, IS_ISSUED_ARCHIVE, STATUS_COMPLETED } from '@routes/passport/tabs/tasks/constants';
import { getLinkForDocument, openAllDocumentLinks } from '@routes/passport/helpers';
import { deleteButtonTemplate } from './constants';

import styles from './styles.module.less';


class DocsTable extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();

    this.state = {
      isDocDownloading: false,
      selectedSystemDocuments: [],
      isEnabledMultipleSelectedFile: false,
      sorter: {},
      editFieldsData: null,
      docStatuses: [],
      docStatusesLoading: false,
    };
  }

  getDocsTypes = (name, selected) => {
    const filter = {
      name: name || undefined,
      selected: selected || undefined,
    };

    return api.getDocumentTypes(filter, api.getOptsForArray());
  };

  getDocsStatuses = (name, selected) => {
    const filter = {
      name: name || undefined,
      selected: selected || undefined,
    };

    return api.getDocumentStatuses(filter, api.getOptsForArray());
  };

  getDocTags = (name, selected) => {
    const filter = {
      name: name || undefined,
      selected: selected || undefined,
    };

    return api.getTags(filter, api.getOptsForArray());
  };

  getLibrary = (name, selected) => {
    const { t } = this.props;

    let results = [
      { id: 'Local', name: t('LIBRARY_LOCAL') },
      { id: 'Global', name: t('LIBRARY_GLOBAL') },
    ];
    if (name && name.length) {
      results = results.filter((x) => x.name.indexOf(name) !== -1);
    }
    if (name && name.length) {
      results = results.filter(
        (x) => (
          Array.isArray(selected)
            ? selected.includes(x.id)
            : x.id === selected
        )
      );
    }
    return {
      status: 200,
      data: { results },
    };
  };

  getFiltersForRequest = () => {
    const { filterValues } = this.props;
    const { sorter } = this.state;

    const ordering = (
      sorter && sorter.order === 'ascend'
        ? 'startup_date'
        : (sorter && sorter.order === 'descend'
          ? '-startup_date'
          : undefined
        )
    );

    return {
      document_type: filterValues['document_type'] && filterValues['document_type'].length
        ? filterValues['document_type']
        : undefined,
      document_status: filterValues['document_status'] && filterValues['document_status'].length
        ? filterValues['document_status']
        : undefined,
      tag: filterValues['tags'] && filterValues['tags'].length
        ? filterValues['tags']
        : undefined,
      library: filterValues['library'] && filterValues['library'].length
        ? filterValues['library']
        : undefined,
      ordering,
    };
  }

  reloadData = () => {
    const { changePage, loadData } = this.props;

    changePage(0, () => loadData(this.getFiltersForRequest()));
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { sorter: oldSorter } = this.state;
    if (!isEqual(oldSorter, sorter)) {
      this.setState({
        sorter,
      }, () => {
        this.reloadData();
      });
    }
  };

  downloadDocument = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { selectedItems } = this.props;

    for (const selected of selectedItems) {
      openAllDocumentLinks(selected, this);
    }
  };

  onRowHandle = (record, selected) => {
    const { selectedSystemDocuments, isEnabledMultipleSelectedFile } = this.state;
    const { onSelectItems, selectedItems } = this.props;

    const nextSelectedItems = isEnabledMultipleSelectedFile
      ? (
        selected
          ? [...selectedItems, record]
          : selectedItems.filter((x) => x.id !== record.id)
      )
      : [record];

    const nextSelectedSystemDocuments = (
      record.document_type && record.document_type.is_system
        ? (
          selected
            ? [...selectedSystemDocuments, record.id]
            : selectedSystemDocuments.filter((x) => x !== record.id)
        )
        : selectedSystemDocuments
    );

    onSelectItems(
      nextSelectedItems,
      () => {
        this.setState({ selectedSystemDocuments: nextSelectedSystemDocuments });
      }
    );
  };

  onSelectAllHandle = (selected, selectedRows, changeRows) => {
    const { selectedSystemDocuments } = this.state;
    const { onSelectItems, selectedItems } = this.props;

    const selectedItemsIds = selectedItems.map((x) => x.id);

    const deletedIds = (
      !selected
      && changeRows.map((x) => x.id)
    ) || [];
    const addRows = (
      selected
      && changeRows.filter((x) => !selectedItemsIds.includes(x.id))
    ) || [];

    const systemDeletedIds = (
      !selected
      && changeRows
        .filter((x) => x.document_type && x.document_type.is_system)
        .map((x) => x.id)
    ) || [];
    const systemAddRowsIds = (
      selected
      && changeRows
        .filter((x) => !selectedItemsIds.includes(x.id) && x.document_type && x.document_type.is_system)
        .map((x) => x.id)
    ) || [];

    const nextSelectedItems = selected
      ? [...selectedItems, ...addRows]
      : selectedItems.filter((x) => !deletedIds.includes(x.id));
    const nextSelectedSystemDocuments = selected
      ? [...selectedSystemDocuments, ...systemAddRowsIds]
      : selectedSystemDocuments.filter((x) => !systemDeletedIds.includes(x));

    onSelectItems(
      nextSelectedItems,
      () => {
        this.setState({ selectedSystemDocuments: nextSelectedSystemDocuments });
      }
    );
  };

  handleCheckMultiselect = (e) => {
    const { selectedItems } = this.props;
    const { target } = e;

    this.setState({
      isEnabledMultipleSelectedFile: !!target.checked,
    }, () => {
      const { isEnabledMultipleSelectedFile } = this.state;

      if (isEnabledMultipleSelectedFile === false && selectedItems && selectedItems.length > 1) {
        const record = selectedItems.pop();
        this.onRowHandle(record);
      }
    });
  }

  getDeleteButtonInfo = () => {
    const defaultType = 'delete';
    const deletionType = this.props.deletionType || defaultType;
    const template = deleteButtonTemplate;

    if (template[deletionType]) return template[deletionType];
    else return template[defaultType];
  }

  editHandler = async (fieldname, record) => {
    const { docStatuses } = this.state;
    if (fieldname === 'document_status' && docStatuses.length === 0) {
      await this.loadStatuses();
    }

    this.setState(
      {
        editFieldsData: {
          [fieldname]: {
            id: record.id,
            record,
          }
        }
      }
    );
  };

  handleBlur = () => {
    this.setState({
      editFieldsData: null
    });
  };

  loadStatuses = async () => {
    this.setState({ docStatusesLoading: true });

    const { status, data } = await this.getDocsStatuses();
    if (status === 200 && data?.results) {
      const preparedStatuses = data.results.map(item => { return {label: item.name, value: item.id} });
      this.setState({ docStatuses: preparedStatuses });
    } else {
      message.error('Fetching status error');
    }

    this.setState({ docStatusesLoading: false });
  }

  prepareStatusValueToEditConfirm = (value) => {
    if (!value) return null;
    const { docStatuses } = this.state;
    const status = docStatuses.find(status => value === status.value);
    return {
      id: status.value,
      name: status.label
    }
  }

  render() {
    const {
      loadData, columnsDocuments, dataSource, openDocumentsSidebar, deleteFile, loading, disabledUpload,
      isEditRight, t, taskId, passportId, bindDocumentsSidebar, collapsable, taskStatus, userInfo,
      count, onShowSizeChange, page, limit, filterValues, selectedItems, selectedDoc, loadWSParams,
      isAvailableDocs, getColumnSearchFilterList, changePage, tableName, isDefaultOpenPanel, titleExtra,
      isEditRightEditButton, deletionType
    } = this.props;
    const {
      isRemovingFile,
      isDocDownloading,
      // selectedSystemDocuments,
      isEnabledMultipleSelectedFile,
      editFieldsData,
      docStatuses,
      docStatusesLoading
    } = this.state;

    const deleteButtonInfo = this.getDeleteButtonInfo();

    //по-хорошему нужен switch-case, но здесь возникают ошибки, 
    //связанные с неинициализированной переменной colName
    const nextColumns = columnsDocuments.map((column) => {
      if (column.dataIndex === 'name') {
        const colName = column.dataIndex;

        //если не задан колбэк, выводим все как обычно
        if (!column?.editable?.onConfirm || !isEditRight) {
          return {
            ...column,
            render: (name, record) => getLinkForDocument(this, record, isEditRight, filterValues[colName])
          }
        }

        //если можно редактировать, показываем кнопку редактирования
        if (!editFieldsData || !editFieldsData[colName]) {
          return {
            ...column,
            render: (name, record) => <>
              <Button size='small' icon={<EditOutlined />} 
                title={t('EDIT')}
                onClick={(e) => {
                  this.editHandler(colName, record);
                  e.stopPropagation();
                }} />
              {getLinkForDocument(this, record, isEditRight, filterValues[colName])}
            </>
          }
        }

        //если редактирования происходит прямо сейчас
        return {
          ...column,
          render: (text, record) => {
            return editFieldsData[colName].id === record.id ?
              <Input
                autoFocus
                size='small'
                defaultValue={text}
                onPressEnter={(event) => {
                  column.editable.onConfirm(event, record, colName);
                  this.handleBlur();
                }}
                onBlur={this.handleBlur} />
              :
              getLinkForDocument(this, record, isEditRight, filterValues[colName])
          }
        }
      }


      if (column.dataIndex === 'document_type') {
        return {
          ...column,
          ...getColumnSearchFilterList(
            t,
            'document_type',
            this.getDocsTypes,
            this.reloadData
          ),
        };
      }

      if (column.dataIndex === 'document_status') {
        const colSearchFilter = getColumnSearchFilterList(t, 'document_status', this.getDocsStatuses, this.reloadData, null, false);

        const colName = column.dataIndex;

        //если не задан колбэк, выводим все как обычно
        if (!column?.editable?.onConfirm || !isEditRight) {
          return {
            ...column,
            ...colSearchFilter,
            render: (item, record) => item && (
              <PopoverEllipsis content={item && item.name}>
                <Badge status='success' text={item && item.name} />
              </PopoverEllipsis>
            )
          }
        }

        //если можно редактировать, показываем кнопку редактирования
        if (!editFieldsData || !editFieldsData[colName]) {
          return {
            ...column,
            ...colSearchFilter,
            render: (item, record) => item ? (
              <PopoverEllipsis content={item && item.name} >
                <Badge
                  status='success'
                  style={{ cursor: 'pointer' }}
                  text={item && item.name}
                  title={t('EDIT')}
                  onClick={(e) => {
                    this.editHandler(colName, record);
                    e.stopPropagation();
                  }} />
              </PopoverEllipsis>
            ) : (
              <Button
                size='small'
                icon={<EditOutlined />}
                title={t('EDIT')}
                onClick={(e) => {
                  this.editHandler(colName, record);
                  e.stopPropagation();
                }}>
                {t('EDIT')}
              </Button>
            )
          }
        }

        //если редактирования происходит прямо сейчас
        return {
          ...column,
          ...colSearchFilter,
          render: (item, record) => {
            return editFieldsData[colName].id === record.id ?
              (
                <Select size='small'
                  autoFocus
                  defaultOpen
                  style={{ width: '100%' }}
                  defaultValue={item?.id ? item.id : undefined}
                  options={docStatuses}
                  loading={docStatusesLoading}
                  onChange={(value) => {
                    column.editable.onConfirm(
                      this.prepareStatusValueToEditConfirm(value), 
                      record, colName);
                    this.handleBlur();
                  }}
                  onBlur={this.handleBlur}
                />
              ) : (
                item ?
                  (
                    <PopoverEllipsis content={item && item.name}>
                      <Badge status='success' text={item && item.name} />
                    </PopoverEllipsis>
                  )
                  : (
                    <Button
                      size='small'
                      icon={<EditOutlined />}
                      onClick={(e) => {
                        this.editHandler(colName, record);
                        e.stopPropagation();
                      }}>{t('EDIT')}</Button>
                  )
              )
          }
        }
      }

      if (column.dataIndex === 'tags') {
        return {
          ...column,
          ...getColumnSearchFilterList(
            t,
            'tags',
            this.getDocTags,
            this.reloadData,
            null,
            false
          ),
        };
      }

      if (column.dataIndex === 'startup_date') {
        return {
          ...column,
          sorter: true,
          showSorterTooltip: false,
        };
      }

      return column;
    });

    nextColumns.push(
      {
        title: t('PASSPORT_DOCS_TABLE_LIBRARY'),
        dataIndex: 'library',
        key: 'library',
        width: '10%',
        minWidth: 150,
        ...getColumnSearchFilterList(
          t,
          'library',
          this.getLibrary,
          this.reloadData,
          null,
          false
        ),
        render: (library) => t(`LIBRARY_${library.toUpperCase()}`),
      }
    );

    const listProps = {
      locale: {
        emptyText: <CustomEmpty description={t('PASSPORT_DOCS_TABLE_EMPTY_DOC_LIST')} />,
      },
    };

    const isSuperUser = userInfo && userInfo.is_superuser;
    const disableEditArchivedDocument = selectedItems
      && selectedItems.length
      && !isSuperUser
      && selectedItems[0]
      && selectedItems[0].is_archived;

    const disableEditButton = !selectedItems
      || selectedItems.length === 0
      || !selectedDoc
      || !isEditRightEditButton

    const disableRmButton = !selectedItems
      || selectedItems.length === 0
      || !isEditRight
      || isRemovingFile

    const disabledMultipleSelectedCheckbox = !(dataSource && dataSource.length > 1);
    const rowSelection = {
      type: isEnabledMultipleSelectedFile && !disabledMultipleSelectedCheckbox ? 'checkbox' : 'radio',
      onSelect: this.onRowHandle,
      selectedRowKeys: selectedItems.map((item) => item && item.id),
      onSelectAll: this.onSelectAllHandle,
    };

    const onRow = (record) => {
      return {
        onClick: () => this.onRowHandle(record),
      };
    };

    const params = { ...filterValues, passport: passportId };

    if (taskId) {
      params.task_dc = taskId;
    }

    const wsParams = { ...loadWSParams() };

    const isDispatcherCenterCda = userInfo.dispatcher_center?.name === t('CDA')

    return (
      <div className={styles.content} ref={this.cardRef}>
        <CustomCard
          collapsable={collapsable}
          noPadding={collapsable}
          noBottomMargin={collapsable}
          titleExtra={titleExtra}
          loading={loading}
          count={dataSource && dataSource.length}
          enableCollapsableHasNoData={!!taskId}
          isDefaultOpenPanel={isDefaultOpenPanel}
          title={t('PASSPORT_DOCS_TABLE_TITLE_DOC')}
          hasData={!!(dataSource && dataSource.length)}
          extra={(
            <>
              <Button
                icon={<EditOutlined />}
                size='small'
                className='control-button'
                // disabled={!selectedDoc || selectedDoc.document_type.is_system || !isEditRight}
                disabled={disableEditButton}
                onClick={() => openDocumentsSidebar(true)}
              >
                {t('EDIT')}
              </Button>
              <Button
                icon={<DownloadOutlined />}
                size='small'
                onClick={(e) => this.downloadDocument(e)}
                disabled={!selectedItems.length}
                className='control-button'
                loading={isDocDownloading}
              >
                {t('PASSPORT_DOCS_TABLE_DOWNLOAD')}
              </Button>
              {bindDocumentsSidebar && (
                <Button
                  size='small'
                  icon={<PlusCircleOutlined />}
                  onClick={bindDocumentsSidebar}
                  disabled={disabledUpload || !isEditRight || !isAvailableDocs}
                  className='control-button'
                >
                  {t('ADD')}
                </Button>
              )}
              <Button
                size='small'
                onClick={() => openDocumentsSidebar(false)}
                className='control-button'
                type='primary'
                disabled={
                  (disabledUpload ||
                    !isEditRight ||
                    selectedItems.length > 1) &&
                  !isDispatcherCenterCda || !isEditRightEditButton
                }
              >
                <UploadOutlined />
                {t('PASSPORT_DOCS_TABLE_IMPORT')}
              </Button>
              <Popconfirm
                title={t(deleteButtonInfo.confirmCaption)}
                onConfirm={() => deleteFile(selectedItems)}
                okText={t('PASSPORT_DOCS_TABLE_YES')}
                cancelText={t('PASSPORT_DOCS_TABLE_NO')}
                disabled={disableRmButton}
              >
                <Button
                  size='small'
                  icon={deleteButtonInfo.icon}
                  disabled={disableRmButton}
                  className='control-button'
                >
                  {t(deleteButtonInfo.caption)}
                </Button>
              </Popconfirm>
            </>
          )}
        >
          <Checkbox
            checked={isEnabledMultipleSelectedFile}
            onChange={this.handleCheckMultiselect}
            className={`${styles.multiple_select_checkbox} ${disabledMultipleSelectedCheckbox ? 'hidden' : ''}`}
          >
            {t('PASSPORT_DOCS_TABLE_MULTIPLE_SELECT')}
          </Checkbox>
          <CommonTable
            {...listProps}
            tableName={tableName}
            size={TABLE_SIZE}
            columns={nextColumns}
            dataSource={dataSource}
            loading={loading || isDocDownloading}
            pagination={{
              onChange: (newPage) => changePage(
                newPage,
                () => loadData(this.getFiltersForRequest())
              ),
              total: count,
              pageSize: limit,
              current: page,
            }}
            rowSelection={rowSelection}
            onRow={onRow}
            onChange={this.handleTableChange}
            onShowSizeChange={
              (current, pageSize) => onShowSizeChange(
                current,
                pageSize,
                tableName,
                () => loadData(this.getFiltersForRequest())
              )
            }
          />
          <ExportBottom
            size='small'
            count={dataSource && dataSource.length}
            WSData={wsParams}
          />
        </CustomCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  userInfo: state.user.info,
  wsState: state.websocket,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      DocsTable
    )
  )
);
