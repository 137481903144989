import { PassportBlank } from '@services/api-dts';

export const GET_BLANKS = 'GET_BLANKS';
export const CLEAR_BLANKS = 'CLEAR_BLANKS';
export const SET_ACTIVE_BLANK = 'SET_ACTIVE_BLANK';
export const UNSET_ACTIVE_BLANK = 'UNSET_ACTIVE_BLANK';
export const SET_BLANK_VERIFICATION = 'SET_BLANK_VERIFICATION';

export interface blanksState {
  blanks: PassportBlank[];
  isBlanksLoading: boolean;
  activeBlank: PassportBlank | null;
  count: number;
}

export interface GetBlanksAction {
  type: typeof GET_BLANKS;
  data: {
    results: PassportBlank[];
    count: number;
  };
  blank: PassportBlank;
}

export interface SetActiveBlankAction {
  type: typeof SET_ACTIVE_BLANK;
  blank: PassportBlank;
}

export interface UnsetActiveBlankAction {
  type: typeof UNSET_ACTIVE_BLANK;
}

export interface ClearBlanksAction {
  type: typeof CLEAR_BLANKS;
}

export interface SetBlankVerification {
  type: typeof SET_BLANK_VERIFICATION;
  blankId: string;
  value: boolean;
}

export type blanksActionTypes = GetBlanksAction | SetActiveBlankAction | UnsetActiveBlankAction | ClearBlanksAction | SetBlankVerification;
