import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import storage from '@services/storage';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Badge, Button, Card, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { InfiniteScroll, PopoverResizable, PopoverEllipsis } from '@ui';
import { decodePath, downloadDocExternal, openInNewTab, preventFocus } from '@globalHelpers';

import { WS_RESULT_COUNT, WS_RUN_ENDPOINT_MSG } from '@app/constants';
import { RootState } from '@state/store';
import { websocketState, wsMessageMainSubType, wsMessageType } from '@state/websocket/types';
import DocumentTrayDownloadArea from './downloadArea';

import topBarStyles from '../styles.module.less';
import styles from './styles.module.less';

export type DocumentTrayDataType = {
  [key: string]: DocumentTrayItemType;
};

export type DocumentTrayItemType = {
  url?: string | null;
  creationDate: string;
  expiry: number;
  desc?: string;
  title?: string;
  error?: string;
};

interface Props extends WithTranslation {
  wsMessage?: wsMessageType;
  wsState?: websocketState;
}

interface State {
  isPopoverVisible: boolean;
  count: number;
  documentTrayData: DocumentTrayDataType;
}

class DocumentTray extends Component<Props, State> {
  state: State = {
    isPopoverVisible: false,
    count: 0,
    documentTrayData: {},
  };

  componentDidMount() {
    this.getDocumentTrayData(true);
  }

  componentDidUpdate(prevProps: Props) {
    const { wsMessage } = this.props;

    const wsMainMessage: wsMessageMainSubType | undefined =
      wsMessage && wsMessage?.type !== WS_RESULT_COUNT && !wsMessage?.type?.includes(WS_RUN_ENDPOINT_MSG)
        ? (wsMessage as wsMessageMainSubType)
        : undefined;

    if (
      wsMainMessage &&
      wsMainMessage?.identity &&
      (prevProps.wsMessage?.type !== wsMainMessage?.type || prevProps.wsMessage?.identity !== wsMainMessage?.identity)
    ) {
      const documentTrayData = this.getDocumentTrayData(false);
      let nextDocumentTrayData: {
        [x: string]:
          | {
              url?: string | null | undefined;
              creationDate: string;
              expiry: number;
              desc?: string | undefined;
              title?: string | undefined;
              error?: string | undefined;
            }
          | {
              url: string | undefined;
              desc: string | undefined;
              title: string | undefined;
              creationDate: string;
              expiry: number;
              error?: string | undefined;
            }
          | {
              creationDate: string;
              expiry: number;
              desc: string | undefined;
              title: string | undefined;
              error?: string | undefined;
            };
      };

      if (documentTrayData && Object.keys(documentTrayData).includes(wsMainMessage?.identity)) {
        nextDocumentTrayData = {
          ...documentTrayData,
          [wsMainMessage?.identity]: {
            ...documentTrayData[wsMainMessage?.identity],
            url: wsMainMessage?.url,
            desc: wsMainMessage?.description,
            title: wsMainMessage?.title,
            error: wsMainMessage?.type?.includes('error') ? wsMainMessage?.message : undefined,
          },
        };
      } else {
        nextDocumentTrayData = {
          [wsMainMessage?.identity]: {
            creationDate: moment().format(),
            expiry: moment().add(1, 'd').valueOf(),
            desc: wsMainMessage?.description,
            title: wsMainMessage?.title,
          },
          ...documentTrayData,
        };
      }

      if (wsMainMessage?.properties?.downloadImmediately) {
        openInNewTab(decodePath(wsMainMessage?.url));
      }

      storage.set('documentTrayData', nextDocumentTrayData);

      this.setState({
        documentTrayData: nextDocumentTrayData,
        count: Object.keys(nextDocumentTrayData).length,
      });
    }
  }

  getDocumentTrayData = (updateDataInState = false): DocumentTrayDataType => {
    const data: DocumentTrayDataType = storage.get('documentTrayData');

    updateDataInState &&
      this.setState({
        documentTrayData: data,
        count: data ? Object.keys(data).length : 0,
      });

    return data;
  };

  togglePopover = (visibility: boolean) => {
    if (visibility) {
      const documentTrayData = this.getDocumentTrayData();
      const nextDocumentTrayData = { ...documentTrayData };

      documentTrayData &&
        Object.keys(documentTrayData).forEach((key) => {
          if (moment(documentTrayData[key].expiry).isBefore()) {
            delete nextDocumentTrayData[key];
          }
        });

      storage.set('documentTrayData', nextDocumentTrayData);

      this.setState({
        documentTrayData: nextDocumentTrayData,
        count: Object.keys(nextDocumentTrayData).length,
      });
    }

    this.setState({
      isPopoverVisible: visibility,
    });
  };

  render() {
    const { t, wsState } = this.props;
    const { isPopoverVisible, count, documentTrayData } = this.state;

    const desc = (key: string) =>
      documentTrayData && documentTrayData[key] && documentTrayData[key].title
        ? documentTrayData[key].title
        : documentTrayData && documentTrayData[key] && documentTrayData[key].desc
        ? decodeURI(documentTrayData[key].desc as string)
        : `${t('EXPORT')}...`;

    return (
      <PopoverResizable
        height={500}
        visible={isPopoverVisible}
        onVisibleChange={(visibility: boolean) => this.togglePopover(visibility)}
        content={
          <div className={styles.documentationTrayWrapper}>
            <div className={styles.trayTitle}>{t('ORDERED_DOCUMENTS')}</div>
            <div className={styles.documentationTray}>
              {/* @ts-ignore */}
              <InfiniteScroll
                scrollbarProps={{
                  style: { height: '100%' },
                  autoHide: true,
                }}
              >
                {documentTrayData &&
                  Object.keys(documentTrayData).map((key) => {
                    const document = documentTrayData[key];

                    return (
                      <Card key={key} className={styles.trayCard}>
                        <div className={styles.linkTitle}>
                          <PopoverEllipsis content={desc(key)}>{desc(key)}</PopoverEllipsis>
                        </div>
                        <DocumentTrayDownloadArea
                          document={document}
                          //@ts-ignore
                          onDocumentDownloadClick={() => downloadDocExternal(wsState, t, document.url)}
                        />
                        <p>{moment(documentTrayData[key].creationDate).format('DD.MM.YYYY HH:mm')}</p>
                      </Card>
                    );
                  })}
              </InfiniteScroll>
            </div>
          </div>
        }
      >
        <Tooltip title={t('ORDERED_DOCUMENTS')}>
          <Badge className={topBarStyles.badge} offset={[3, 3]} count={count}>
            <Button icon={<CloudDownloadOutlined />} onClick={(e) => preventFocus(e)} />
          </Badge>
        </Tooltip>
      </PopoverResizable>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  wsMessage: state.websocket.message,
  wsState: state.websocket,
});

export default connect(mapStateToProps)(withTranslation()(DocumentTray));
