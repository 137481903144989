import React, { Component } from 'react';

import { LIMIT } from '@app/constants';
import api from '@services/api';
import storage from '@services/storage';

const withPagination = (WrappedComponent) => {
  return class withPaginationHOC extends Component {
    state = {
      limit: LIMIT,
      page: 1,
      tableName: null,
    };

    setTableName = async (name, callback) => {
      if (name && name.length) {
        this.setState(
          {
            tableName: name,
            limit: LIMIT,
          },
          () => this.getLimit(name, true, callback)
        );
      }
    }

    getLimit = async (name, isSaveNewLimit = true, callback) => {
      const { limit, tableName } = this.state;

      const newName = name && name.length
        ? name
        : tableName;

      const newLimit = storage.get(`table_rows_size__${newName}`) || limit;

      if (isSaveNewLimit && newLimit && newLimit !== limit) {
        this.setState(
          {
            limit: newLimit,
          },
          () => {
            if (callback) {
              callback();
            } else {
              return newLimit;
            }
          }
        );
      } else if (callback) {
        callback();
      } else {
        return newLimit;
      }
    }

    changePage = (page, callback) => {
      this.setState({ page }, callback);
    };

    saveLimit = (size, name, callback) => {
      const { limit, tableName } = this.state;
      const newName = name || tableName;

      if (newName && size) {
        const storedTableRowsSizeName = `table_rows_size__${newName}`;

        const numberSize = typeof size === 'number'
          ? size
          : parseInt(size, 10);

        if (limit && numberSize && limit !== numberSize) {
          storage.set(storedTableRowsSizeName, numberSize);
          this.setState(
            {
              limit: numberSize,
            },
            () => callback && callback()
          );
        } else {
          callback && callback();
        }
      } else {
        callback && callback();
      }
    };

    onShowSizeChange = (current, pageSize, name, callback) => {
      this.saveLimit(pageSize, name, () => {
        this.changePage(1, callback);
      });
    };

    /**
     * Производит постраничную загрузку данных по указанной функции
     * @param {string} apiFunctionName Функция API
     * @param {any} mainParams основные параметры для запроса
     * @param {number[]|number} okStatuses Один или несколько статусов типа ОК
     * @param {any} callbackOk(status, data) функция (с параметрами (status, data)) выполняется если статус типа ОК
     * @param {any} callbackError(status, data) функция (с параметрами (status, data)) выполняется если статс НЕ ОК
     */
    loadByPage = async (
      tableName,
      apiFunctionName,
      mainParams = {},
      okStatuses = [200, 201, 203, 205],
      callbackOk = null,
      callbackError = null
    ) => {
      const { limit: stateLimit, page, tableName: stateTableName } = this.state;
      const limit = stateTableName && (!tableName || (tableName && tableName === stateTableName))
        ? stateLimit
        : await this.getLimit(tableName);
      const offset = (
        mainParams && mainParams.offset
          ? mainParams.offset
          : (
            page && limit
              ? (page - 1) * limit
              : 0
          )
      );
      const requestParams = {
        limit, page, offset, ...mainParams,
      };

      const { status, data, page: newPage } = await api[apiFunctionName](requestParams);
      const statusesList = Array.isArray(okStatuses)
        ? okStatuses
        : [okStatuses];

      if (statusesList.includes(status)) {
        if (newPage && newPage !== page) {
          this.setState({ page: newPage });
        }
        if (callbackOk) {
          return callbackOk(status, data);
        }
      } else if (callbackError) {
        return callbackError(status, data);
      }
      return { status, data };
    }

    render() {
      return (
        <WrappedComponent
          changePage={this.changePage}
          setTableName={this.setTableName}
          onShowSizeChange={this.onShowSizeChange}
          loadByPage={this.loadByPage}
          {...this.state}
          {...this.props}
        />
      );
    }
  };
};

export default withPagination;
