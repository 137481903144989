import api from '@services/api';
import { BadgeButton } from '@ui/BadgeButton';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import NewsModal from './newsModal';

export interface INewsButton extends WithTranslation {
  checkInterval?: number;
}

const NewsButton: FC<INewsButton> = ({ t, checkInterval = 5000 }) => {
  const [count, setCount] = useState(0);
  const [isModalvisible, setIsModalVisible] = useState(false);

  const getCount = useCallback(async () => {
    const { status, data } = await api.getNewsUnread();
    if (status === 200) {
      return data.count;
    } else return 0;
  }, []);

  useEffect(() => {
    const updateCount = async () => {
      const newCount = await getCount();
      setCount(newCount);
    };

    updateCount();

    //периодическая проверка - проще, чем вебсокеты,
    //достаточно, чтобы новости обновлялись во время работы
    const interval = setInterval(() => {
      updateCount();
    }, checkInterval);

    return () => clearInterval(interval);
  }, [getCount, checkInterval]);

  const showModal = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.currentTarget as HTMLButtonElement).blur();
    setCount(0);
    setIsModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <NewsModal visible={isModalvisible} closeModal={hideModal} />
      <BadgeButton label={t('NEWS')} count={count} onClick={(e) => showModal(e)} />
    </>
  );
};

export default withTranslation()(NewsButton);
