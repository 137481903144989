import { Popover, Tag, Divider } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EntityLinkTag from './entityLinkTag';
import { getRealPassportsCount } from './helpers';
import './styles.less';

const EntitiesAsTags = memo(
  ({ entities, record, currentDeviceId, t, link,
    isViewPassportsCount = false,
    isAllowed = true,
    entityName = 'blanks',
    entityNameSingle = 'blank',
    localizedEntityName = 'Бланки',
    sourceTab
  }) => {

    const [passportsCount, setPassportsCount] = useState(0);

    if ((!entities || !entities?.length) && (!record?.passports || !record?.passports?.length)) return '';


    const newContent = useMemo(() => {
      const data = [];

      let isProtectionDeviceArray = false;
      if (entities.length > 0 && Array.isArray(entities[0]?.protection_device))
        isProtectionDeviceArray = true;

      record.passports.forEach((passport) => {
        let localEntities = [];

        if (!isProtectionDeviceArray)
          entities.forEach((entity) => {
            if (entity?.protection_device?.id === passport?.protection_device?.id) {
              localEntities.push(entity);
            }
          });
        data.push({
          entities: localEntities,
          passport: passport,
        });
      });

      if (isProtectionDeviceArray) {
        entities.forEach((entity) => {
          entity.protection_device.forEach((pd) => {

            let localData = null;

            const passportListItem = data.find(item => item.passport?.protection_device?.id === pd?.id);

            if (!passportListItem) {
              localData = {
                passport: {
                  id: pd.id,
                  name: pd.name,
                  protection_device: pd
                }, entities: [entity]
              }
              data.push(localData);
            } else passportListItem.entities.push(entity);
          })
        })
      }

      const rows = data.map((record, index) => {
        const passportLink = <Link to={`/passport/${record.passport?.protection_device.id}/tab/${sourceTab}`}>{record.passport?.protection_device?.name}</Link>;
        const blankLinks = record.entities.map((entity) => (
          <EntityLinkTag
            entity={entity}
            entityName={entityName}
            entityNameSingle={entityNameSingle}
            key={`entity_${entity.id}`}
            link={link}
            currentDeviceId={currentDeviceId}
            isAllowed={isAllowed}
            record={record}
          />
        ));

        return (
          <div key={`pass_name_${record.passport.id}`}>
            <div className='entities-row'>
              <div className='passport' key={`pass_name_${record.passport.id}_link`}>
                Паспорт: {passportLink}
              </div>
              <div className='entities' key={`pass_name_${record.passport.id}_entities`}>
                <span>{localizedEntityName}</span>: {blankLinks?.length > 0 ? blankLinks : <span style={{ color: isProtectionDeviceArray && 'red' }}>{' '}не указаны</span>}
              </div>
            </div>
            {index !== data.length - 1 && <Divider className='divider' />}
          </div>
        );
      });

      setPassportsCount(getRealPassportsCount(data, currentDeviceId));

      return <div className='entities-content' key={`entites_${Math.random()}`}>{rows}</div>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities, record, currentDeviceId, entityName, entityNameSingle, isAllowed, link, localizedEntityName]);

    const passportText =
      isViewPassportsCount && passportsCount > 0 && `, ${t('COUNT_PASSPORTS')}: ${passportsCount}`

    return (
      <Popover content={newContent} placement='topRight'>
        <Tag className='link'>{`${t(`COUNT_${entityName.toUpperCase()}`)}: ${entities.length || ' нет'}${passportText || ''}`}</Tag>
      </Popover>
    );
  }
);

export default withTranslation()(EntitiesAsTags);
