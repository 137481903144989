import React, { Component } from 'react';
import { Button, Popconfirm } from 'antd';
import Qs from 'qs';
import { connect } from 'react-redux';
import Icon from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { ReactComponent as XlsxIcon } from '@ui/icons/xlsx.svg';

import styles from './styles.module.less';
import { isArchived } from '@app/helpers';

class ExportPopconfirm extends Component {
  state = {
    confirmVisibility: false,
    isExporting: false,
  };

  cancelConfirm = () => {
    this.setState({ confirmVisibility: false });
  };

  handleVisibleExportConfirm = (visible) => {
    const { count } = this.props;

    if (!visible) {
      this.setState({ confirmVisibility: visible });
      return;
    }

    if (count <= 200) {
      this.exportExcelHandler();
    } else {
      this.setState({ confirmVisibility: visible });
    }
  };

  exportExcelHandler = async () => {
    const { socket, isSocketConnected, WSData } = this.props;

    if (socket && isSocketConnected && WSData) {
      const isArchivedData = isArchived();
      const wsMessage = { ...WSData };
      wsMessage.message_type = 'export';

      if (WSData.params) {
        const nextParams = { ...WSData.params, is_archived: isArchivedData };

        Object.keys(nextParams).forEach((key) => {
          !nextParams[key] && delete nextParams[key];
        });

        wsMessage.url = `${WSData.url}?${Qs.stringify(nextParams, { arrayFormat: 'repeat' })}`;
        delete wsMessage.params;
      } else {
        wsMessage.url = `${WSData.url}?is_archived=isArchivedData`;
      }

      if (!wsMessage.title) {
        wsMessage.title = null;
      }

      this.setState({ isExporting: true });
      await socket.send(JSON.stringify(wsMessage));
      this.setState({ isExporting: false });

      return undefined;
    }
  };

  render() {
    const {
      t, count, size, className = '', titleButton = false, WSData,
    } = this.props;
    const { confirmVisibility, isExporting } = this.state;

    if (!count) return '';

    return (
      <Popconfirm
        title={t('EXPORT_MESSAGE_TOO_MANY')}
        visible={confirmVisibility}
        onVisibleChange={this.handleVisibleExportConfirm}
        onConfirm={this.exportExcelHandler}
        onCancel={this.cancelConfirm}
        okText={t('YES')}
        cancelText={t('NO')}
        disabled={!count || isExporting || !WSData}
      >
        {
          titleButton
            ? (
              <Icon
                component={XlsxIcon}
                className='titleButton'
                loading={isExporting}
                disabled={!count}
                size={size || 'default'}
                title={t('EXPORT_EXCEL')}
              />
            )
            : (
              <Button
                loading={isExporting}
                disabled={!count}
                className={className}
                size={size || 'default'}
              >
                <Icon component={XlsxIcon} />
                {t('EXPORT_EXCEL')}
              </Button>
            )
        }
      </Popconfirm>
    );
  }
}

const mapStateToProps = (state) => ({
  socket: state.websocket.instance,
  isSocketConnected: state.websocket.connected,
});

export const ExportBottom = connect(mapStateToProps)(
  withTranslation()(
    (props) => (
      <div className={props.count ? styles.exportBottom : styles.exportBottomNoCount}>
        <ExportPopconfirm {...props} />
      </div>
    )
  )
);

export default connect(mapStateToProps)(
  withTranslation()(
    ExportPopconfirm
  )
);
