import React from 'react';

import store from '@state/store';
import { closeSidebar } from '@state/sidebar/actions';

import TreeFilter from './treeFilter';

class Tree extends React.Component {
  componentDidMount() {
    store.dispatch(closeSidebar());
  }

  render() {
    return <TreeFilter />;
  }
}

export default Tree;
