import React, { Component } from 'react';
import { Table } from 'antd';

import { isEqual } from 'lodash';
import { PAGE_CHANGER_STEPS } from '@globalConstants';
import storage from '@services/storage';

import ResizeableTitle from './resizeableTitle';

import styles from './styles.module.less';

class TableResizeChanger extends Component {
  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      columnsLocal: [],
      columnsWidth: [],
      current: null,
      loadWidth: false,
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    const { columns } = this.props;
    const newColumns = this.checkIsSingleColumn(columns);
    const columnsWidth = this.getColumnsWidth(newColumns);

    this.loadColumnsWidthFromStorage(newColumns, columnsWidth);
  }

  componentDidUpdate(prevProps) {
    const { columns } = this.props;
    const { columnsWidth } = this.state;

    if (!isEqual(prevProps.columns, columns)) {
      const tempColumns = this.checkIsSingleColumn(columns);
      const newColumnsWidth = this.getColumnsWidth(tempColumns);
      const prevKeys = prevProps.columns.map((x) => `${x.key}_${x.dataIndex}`).join('^');
      const currKeys = tempColumns.map((x) => `${x.key}_${x.dataIndex}`).join('^');
      const isChangeList = (prevProps.columns.length !== tempColumns.length || prevKeys !== currKeys);

      const newColumns = tempColumns.map((item, index) => {
        const newItem = { ...item };
        if (!newItem.width && newColumnsWidth[index] && newColumnsWidth[index].width) {
          newItem.width = newColumnsWidth[index].width;
        }
        if (!isChangeList && !newItem.width && columnsWidth[index] && columnsWidth[index].width) {
          newItem.width = columnsWidth[index].width;
          newColumnsWidth[index] = columnsWidth[index];
        }
        return newItem;
      });

      if (isChangeList) {
        this.setState({
          loadWidth: false,
        }, () => {
          this.loadColumnsWidthFromStorage(newColumns, newColumnsWidth);
        });
      } else {
        this.loadColumnsWidthFromStorage(newColumns, newColumnsWidth);
      }
    }
  }

  getColumnsWidth(columns) {
    return columns.map((item, index) => ({
      id: index,
      width: item.width,
      minWidth: item.minWidth || 40,
      maxWidth: item.maxWidth,
      resizable: item.resizable !== false,
    }));
  }

  checkIsSingleColumn = (column) => {
    if (column && column.length === 1) {
      // eslint-disable-next-line no-param-reassign
      column[0].resizable = false;
    }
    return column;
  };

  loadColumnsWidthFromStorage = (columns, columnsWidth) => {
    const { tableName } = this.props;

    if (tableName) {
      const savedColumnsWidth = storage.get(`columns_width_${tableName}`);
      if (savedColumnsWidth && columns) {
        columns.forEach((x, idx) => {
          if (savedColumnsWidth[x.key] !== undefined) {
            const newWidth = this.checkMinMaxWidth(savedColumnsWidth[x.key], columns.minWidth, columns.maxWidth);
            // eslint-disable-next-line no-param-reassign
            columns[idx].width = newWidth;
            // eslint-disable-next-line no-param-reassign
            columnsWidth[idx].width = newWidth;
          }
        });
      }
    }

    this.setState({
      columnsLocal: columns,
      columnsWidth: columnsWidth,
    }, () => {
      const table = this.ref.current.getElementsByTagName('table')[0];
      this.setWidthForUndefinedWidthColumns(table);
    });
  };

  checkMinMaxWidth = (width, minWidth, maxWidth) => {
    let newWidth = width;

    if (minWidth && newWidth < minWidth) {
      newWidth = minWidth;
    }
    if (maxWidth && newWidth > maxWidth) {
      newWidth = maxWidth;
    }
    return newWidth;
  };

  saveColumnsWidthToStorage = (columns) => {
    const { tableName } = this.props;

    if (tableName) {
      const savedColumnsWidth = {};
      columns.forEach((x) => {
        savedColumnsWidth[x.key] = x.width;
      });
      storage.set(`columns_width_${tableName}`, savedColumnsWidth);
    }
  };

  handleResize = (index) => (e, data) => {
    const { columnsLocal, columnsWidth, current } = this.state;

    if (current === -1 || !current) return {};

    const { size } = data;

    if (e.which === 0) {
      this.stopResize('which');
    }

    if (
      (size.width >= columnsWidth[index].minWidth)
      && (
        !columnsWidth[index].maxWidth
        || (size.width <= columnsWidth[index].maxWidth)
      )
    ) {
      const newColumns = [...columnsLocal];
      const newColumnsWidth = [...columnsWidth];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      newColumnsWidth[index].width = size.width;

      this.setState({
        columnsLocal: newColumns,
        columnsWidth: newColumnsWidth,
      });

      this.saveColumnsWidthToStorage(newColumns);
    } else {
      this.stopResize('minWidth');
    }
    return {};
  };

  setWidthForUndefinedWidthColumns = (table = null, index = null) => {
    const { columnsLocal, columnsWidth, loadWidth } = this.state;

    const newColumns = [...columnsLocal];
    const newColumnsWidth = [...columnsWidth];

    let columnWithOutWidth = newColumns.some((x) => x.width === undefined);

    if (
      table
      && (
        columnWithOutWidth
        || (index && newColumnsWidth[index] && !newColumnsWidth[index].width)
      )
    ) {
      const cells = (table.tHead && table.tHead.rows && table.tHead.rows[0] && table.tHead.rows[0].cells) || [];
      const cellsWidth = [...cells].map((x) => x.clientWidth);
      const isFirstColumnIsSelectable = (
        cells.length
        && cells[0]
        && cells[0].className
        && cells[0].className.indexOf('ant-table-selection-column') !== -1
      );

      if (columnWithOutWidth) {
        newColumns.forEach((x, idx) => {
          if (x.width === undefined) {
            const newWidth = (
              cellsWidth
              && cellsWidth[isFirstColumnIsSelectable ? idx + 1 : idx]
              && this.checkMinMaxWidth(cellsWidth[isFirstColumnIsSelectable ? idx + 1 : idx], x.minWidth, x.maxWidth)
            );
            // eslint-disable-next-line no-param-reassign
            x.width = newWidth;
            if (newColumnsWidth[idx] && 'width' in newColumnsWidth[idx]) {
              newColumnsWidth[idx].width = newWidth;
            }
          }
        });

        this.setState({
          columnsLocal: newColumns,
          columnsWidth: newColumnsWidth,
        });
      }

      if (index !== null) {
        if (newColumnsWidth[index] && !newColumnsWidth[index].width) {
          newColumnsWidth[index].width = this.checkMinMaxWidth(
            cellsWidth[isFirstColumnIsSelectable ? index + 1 : index],
            newColumns[index].minWidth,
            newColumns[index].maxWidth
          );
        }
      }
    }

    if (!loadWidth) {
      columnWithOutWidth = newColumns.some((x) => x.width === undefined);
      if (!columnWithOutWidth) {
        this.setState({
          loadWidth: true,
        });
      }
    }

    return newColumnsWidth;
  };

  startResize = (index) => (e, data) => {
    const { current } = this.state;
    const { node } = data;

    if (!(current === -1 || current === null)) {
      return {};
    }

    const newColumnsWidth = this.setWidthForUndefinedWidthColumns(node.closest('table'), index);

    const newCurrent = {
      position: e.clientX,
      currentWidth: newColumnsWidth[index] && newColumnsWidth[index].width,
    };

    this.setState({
      current: newCurrent,
    });
    return {};
  };

  stopResize = () => {
    const { current } = this.state;
    if (current !== -1 && current !== null) {
      this.setState({
        current: -1,
      });
      setTimeout(
        () => {
          this.setState({
            current: null,
          });
        },
        1000
      );
    }
  };

  render() {
    const {
      columns,
      pagination,
      onShowSizeChange,
      className,
      rowSelection,
      style,
      ...restProps
    } = this.props;
    const { columnsLocal, loadWidth } = this.state;

    const columnsResizable = [
      ...columnsLocal.map(
        (col, index) => ({
          ...col,
          onHeaderCell: (column) => ({
            loadWidth: loadWidth,
            width: column.width,
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            resizeable: column.resizeable !== false,
            onResize: this.handleResize(index),
            onResizeStart: this.startResize(index, column),
            onResizeStop: () => this.stopResize('onResizeStop'),
            onMouseUp: () => this.stopResize('onMouseUp'),
          }),
        })
      ),
    ];
    let paginationChanger;

    if (pagination) {
      paginationChanger = { ...pagination };
      paginationChanger.showSizeChanger = true;
      paginationChanger.pageSizeOptions = PAGE_CHANGER_STEPS;
      paginationChanger.onShowSizeChange = onShowSizeChange;
    } else {
      paginationChanger = false;
    }

    const isTreeValueTable = className && className.indexOf('treeValue') !== -1;

    return (
      <div ref={this.ref}>
        <Table
          tableLayout='fixed'
          bordered={!isTreeValueTable}
          {...restProps}
          className={`${styles.table} ${className !== undefined ? className : ''}`}
          columns={columnsResizable}
          components={this.components}
          pagination={paginationChanger}
          rowSelection={rowSelection && { ...rowSelection, columnWidth: '50px' }}
        />
      </div>
    );
  }
}

export default TableResizeChanger;
