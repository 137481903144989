import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './styles.less';

import api from '@services/api';
import {
  Button, Empty, message,
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import {
  onDropTreeValueItemIntoNewTreeItem,
  onChangeTreeData,
  onSelectTreeData,
  onViewTree,
} from '@common/tree';
import TemplateTree from "@common/tree";
import TemplateTreeValues from "@common/treeValues";
import {
  onDragStartTreeValueItem,
  onDropTreeValueItemInside,
  onClearDragTreeValue,
  onChangeTreeValueData,
} from '@common/treeValues';
import { loopTree, withPrefix } from '@globalHelpers';
import { MODE_EDIT, RIGHT_ROUTE_FUNCTIONS } from '@app/constants';

import { CustomCard } from '@ui';
import { ActionsForTree } from './actions/actionsForTree';
import { ActionsForTreeValues } from './actions/actionsForTreeValues';

class FunctionsComponent extends Component {
  constructor(props) {
    super(props);
    this.classes = props.classes;
    const state = this.state || {};
    this.state = { ...state, ...this.getInitialState() };

    this.actionsForTree = new ActionsForTree(withPrefix('FUNCTIONS_', props.t));
    this.actionsForTreeValues = new ActionsForTreeValues(withPrefix('FUNCTIONS_', props.t));
  }

  getInitialState() {
    return {
      /** *************** state for Tree ************** */
      treeData: [],
      selectedTreeItem: undefined,
      isViewTree: true,
      isEditTreeItem: false,

      /** *************** state for Tree Values ************** */
      treeValueData: [],
      dragTreeValueItem: {},
      isEditTreeValueItem: false,

      /** *************** state for this component ************** */
      isLoading: false,
      setPointsBlockIsLoading: false,
      isHideEmpty: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { archived } = this.props;
    const { isHideEmpty, selectedItem } = this.state;

    if (prevState.isHideEmpty !== isHideEmpty || prevProps.archived !== archived) {
      this.loadData();
      this.loadTreeValuesData(selectedItem);
    }
  }

  loadData = async () => {
    const { isHideEmpty } = this.state;

    this.setState({ isLoading: true });

    const { status, data } = await api.getFunctionsTree(isHideEmpty, {}, true);

    if (status === 200) {
      const treeData = cloneDeep(data.results);
      loopTree(treeData, null, (item, parentId) => {
        // eslint-disable-next-line no-param-reassign
        item.parent = parentId;
      });
      this.setState({
        treeData,
      });
    }

    this.setState({
      isLoading: false,
    });
  };

  loadTreeValuesData = (parent) => {
    if (!parent) {
      this.setState({
        treeValueData: [],
        selectedItem: undefined,
      });

      return undefined;
    }

    this.setState({ setPointsBlockIsLoading: true });
    const request = new Promise((resolve) => resolve(api.getFunctionsListByTreeElement({
      parent: parent.id,
    })));
    request.then(
      (result) => {
        const { status, data } = result;

        if (status === 200) {
          this.setState({
            setPointsBlockIsLoading: false,
            selectedItem: parent,
            treeValueData: data.results.map((item) => ({
              ...item,
              category: {
                id: parent.id,
                name: parent.name,
              },
              protection_devices: { id: item.id, size: item.protection_devices },
            })),
          });
        } else {
          this.setState({ setPointsBlockIsLoading: false });
        }
      }
    );

    return undefined;
  };

  onAddTreeItem = (addItem) => {
    if (addItem) {
      this.setState({
        isEditTreeItem: true,
      });
    }
    return addItem;
  };

  onEditTreeItem = (editedKey, editedItem) => {
    this.setState({
      isEditTreeItem: true,
    });
    return editedItem;
  };

  onAddTreeValueItem = (addItem) => {
    this.setState({
      isEditTreeValueItem: true,
    });
    return this.actionsForTreeValues.onAddTreeValueItem(addItem);
  };

  onEditTreeValueData = (editedItem) => {
    this.setState({
      isEditTreeValueItem: true,
    });
    return this.actionsForTreeValues.onEditTreeValueData(editedItem);
  };

  onShowButtonsSaveCancel = () => {
    // Nothing
  };

  // проверка на "повтор" (для групп - в рамках одного родительского узла)
  findDuplicates = async (item) => {
    const { status, data } = await api.getFunctionsForFilter(item.name.trim(), item.is_accounting_group, 0, 9999);
    if (status === 200 && data && data.results) {
      let doubles;
      if (item.is_accounting_group) {
        doubles = data.results.filter(
          (el) => el.name.trim().toLowerCase() === item.name.trim().toLowerCase() && el.parent === item.parent
        );
      } else {
        doubles = data.results.filter((el) => el.name.trim().toLowerCase() === item.name.trim().toLowerCase());
      }
      if (doubles && doubles.length > 0) {
        console.warn({
          _f: 'duplicates for inserted item',
          inserted_item: item,
          duplicates: doubles,
        });
        return true;
      }
    }
    return false;
  };

  saveChanges = async (isGroup, data, operation, itemForOperation, component) => {
    const { t } = this.props;
    const { selectedItem } = this.state;
    const newData = cloneDeep(data);
    let isError = false;
    switch (operation) {
      case 'insert': {
        if (await this.findDuplicates(itemForOperation)) {
          message.warning(t('FUNCTIONS_MODAL_WARNING_DOUBLES_TEXT'), 4);
        }
        const lastId = itemForOperation.id;
        // eslint-disable-next-line no-param-reassign
        delete itemForOperation.id;
        const insertResult = isGroup
          ? await api.addFunctionsGroup(itemForOperation)
          : await api.addFunction(itemForOperation);
        if ([201].indexOf(insertResult.status) !== -1 && insertResult.data) {
          // заменяем данные по элементу на новые, которые пришли после сохранения
          loopTree(newData, null, (item, parentId, index, arr) => {
            if (item.id === lastId) {
              // eslint-disable-next-line no-param-reassign
              arr[index] = { ...arr[index], ...insertResult.data };
              if ('isNewItem' in arr[index]) {
                // eslint-disable-next-line no-param-reassign
                delete arr[index].isNewItem;
              }
            }
          });
        } else {
          isError = true;
          // выдача ошибки пользователю
          message.error(
            <>
              {t('FUNCTIONS_MODAL_ERROR_INSERT_TEXT')}
              <br />
              {insertResult.data.error || ''}
            </>,
            3
          );
          console.error({
            _f: 'onMyChangeTreeData--insert-error',
            insertResult,
          });
          // удаление "сбойного" элемента из дерева
          loopTree(newData, null, (item, parentId, index, arr) => {
            if (item.id === lastId) {
              arr.splice(index, 1);
            }
          });
        }
        break;
      }
      case 'delete': {
        const deleteResult = isGroup
          ? await api.deleteFunctionsGroup(itemForOperation.id)
          : await api.deleteFunction(itemForOperation.id);
        if ([204].indexOf(deleteResult.status) !== -1) {
          // удаление элемента из дерева, если он еще там есть ...
          loopTree(newData, null, (item, parentId, index, arr) => {
            if (item.id === itemForOperation.id) {
              arr.splice(index, 1);
            }
          });
        } else {
          isError = true;
          // выдача ошибки пользователю
          message.error(
            <>
              {t('FUNCTIONS_MODAL_ERROR_DELETE_TEXT')}
              <br />
              {deleteResult.data.error || ''}
            </>,
            3
          );
          console.error({
            _f: 'onMyChangeTreeData--delete-error',
            deleteResult,
          });
          // возврат в дерево "сбойного" удаленного элемента
          loopTree(newData, null, (item, parentId, index, arr) => {
            if (item.id === itemForOperation.parent) {
              // eslint-disable-next-line no-param-reassign
              arr[index].children = { ...arr[index].children, itemForOperation };
            }
          });
        }
        break;
      }
      case 'edit': {
        if (await this.findDuplicates(itemForOperation)) {
          message.warning(t('FUNCTIONS_MODAL_WARNING_DOUBLES_TEXT'), 4);
        }
        const sendValue = {
          name: itemForOperation.name || null,
          short_name: itemForOperation.short_name || null,
          ansi: itemForOperation.ansi || null,
          parent: itemForOperation.parent || null,
        };
        const editResult = isGroup
          ? await api.editFunctionsGroup(itemForOperation.id, sendValue)
          : await api.editFunction(itemForOperation.id, sendValue);
        if ([200].indexOf(editResult.status) === -1) {
          isError = true;
          // выдача ошибки пользователю
          message.error(
            <>
              {t('FUNCTIONS_MODAL_ERROR_EDIT_TEXT')}
              <br />
              {editResult.data.error || ''}
            </>,
            3
          );
          console.error({
            _f: 'onMyChangeTreeData--edit-error',
            editResult,
          });
          // возврат значения до изменений для "сбойного" редактируемого элемента
          let oldTreeItemValue;
          loopTree(component.state.treeData, null, (item) => {
            if (item.id === itemForOperation.id) {
              oldTreeItemValue = item;
            }
          });
          loopTree(newData, null, (item, parentId, index, arr) => {
            if (item.id === itemForOperation.id) {
              // eslint-disable-next-line no-param-reassign
              arr[index] = oldTreeItemValue;
            }
          });
        }
        break;
      }
      default:
    }
    if (isError) {
      this.loadTreeValuesData(selectedItem);
    }
    return newData;
  };

  onMyChangeTreeData = async (newTreeData, operation, itemForOperation, component) => {
    const lastTreeData = await this.saveChanges(true, newTreeData, operation, itemForOperation, component);
    onChangeTreeData(lastTreeData, operation, itemForOperation, component);
  };

  onMyChangeTreeValueData = async (newTreeValueData, operation, itemForOperation, component) => {
    if (JSON.stringify(newTreeValueData) !== JSON.stringify(component.state.treeValueData)) {
      if (itemForOperation.category) {
        // eslint-disable-next-line no-param-reassign
        itemForOperation.parent = 'id' in itemForOperation.category
          ? itemForOperation.category.id
          : itemForOperation.category;
        // eslint-disable-next-line no-param-reassign
        delete itemForOperation.category;
      }
      if ('isNewItem' in itemForOperation) {
        // eslint-disable-next-line no-param-reassign
        delete itemForOperation.isNewItem;
      }
      // eslint-disable-next-line no-param-reassign
      newTreeValueData = await this.saveChanges(false, newTreeValueData, operation, itemForOperation, component);
    }
    onChangeTreeValueData(newTreeValueData, operation, itemForOperation, component);
  };

  renderExtraButtons = (component) => {
    const { t } = this.props;

    return (
      <div className='template-tree__button-extra'>
        <Button
          size='small'
          icon={component.state.isHideEmpty ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          onClick={() => component.setState({
            isHideEmpty: !component.state.isHideEmpty,
          })}
        >
          {component.state.isHideEmpty ? t('FUNCTIONS_SHOW_EMPTY') : t('FUNCTIONS_HIDE_EMPTY')}
        </Button>
      </div>
    );
  };

  render() {
    const {
      /** *************** state for Tree ************** */
      treeData,
      selectedTreeItem,
      isViewTree,
      isEditTreeItem,

      /** *************** state for Tree Values ************** */
      treeValueData,
      dragTreeValueItem,
      isEditTreeValueItem,

      /** *************** state for this component ************** */
      isLoading,
      setPointsBlockIsLoading,
    } = this.state;
    const { offsetTop, t, rights } = this.props;

    const isEditRight = rights[RIGHT_ROUTE_FUNCTIONS] === MODE_EDIT;
    const editableFunctions = process.env.REACT_APP_DISABLE_EDIT_OBJECTS_FROM_AIP === undefined;

    const isSelectedTreeItemFirstLevel = selectedTreeItem && selectedTreeItem.parent === null;
    const isHasChildren = (
      (selectedTreeItem && selectedTreeItem.children && selectedTreeItem.children.length)
      || (treeValueData.some((x) => selectedTreeItem && x.parent === selectedTreeItem.id))
    );

    return (
      <CustomCard
        loading={isLoading}
        className='template__card template__card_body'
        style={{ height: `calc(100vh - ${offsetTop}px)` }}
      >
        {treeData ? (
          <div className='template-wrapper' style={{ height: `calc(100vh - ${offsetTop}px)` }}>
            <div className={`template-left ${!isViewTree && 'hidden'}`}>
              <TemplateTree
                /** **** Все поля являются обязательными для передачи ***** */
                t={withPrefix('FUNCTIONS_', t)}
                groups={treeData}
                isRootTreeItem={true}
                isEditTreeValueItem={isEditTreeValueItem}
                newItem={this.actionsForTree.newTreeItem}
                onAddItem={this.onAddTreeItem}
                onEditItem={this.onEditTreeItem}
                onSelectTreeData={(selected) => {
                  this.loadTreeValuesData(selected);
                  return onSelectTreeData(selected, this);
                }}
                onChangeTreeData={
                  (newTreeData, operation, item) => this.onMyChangeTreeData(
                    newTreeData,
                    operation,
                    item,
                    this
                  )
                }
                dragTreeValueItem={dragTreeValueItem}
                onDropTreeValueItemIntoNewTreeItem={
                  (dragObject, dropObject) => onDropTreeValueItemIntoNewTreeItem(
                    dragObject,
                    dropObject,
                    this.onMyChangeTreeValueData,
                    this
                  )
                }
                onClearDragTreeValue={() => onClearDragTreeValue(this)}
                onShowButtonsSaveCancel={this.onShowButtonsSaveCancel}
                isCanEdit={isEditRight && editableFunctions}
                isCanDragAndDrop={true}
                isShowButtonsPanel={true}
                /** **** (необязательно) ***** */
                /** **** Список стандартных кнопок, которые разрешены/могут быть активны ***** */
                /** **** ['add', 'delete']. Если все активны - можно не передавать ***** */
                enableStandardButtons={
                  selectedTreeItem === undefined
                    ? []
                    : (isSelectedTreeItemFirstLevel || isHasChildren
                      ? ['add']
                      : ['add', 'delete']
                    )
                }
                // visibleStandardButtons={['add', 'delete']}
                /** **** Использование дополнительных кнопок ***** */
                // onExpandTreeData={(expandedKeys) => this.setState({expandedTreeItem: expandedKeys})}
                /** **** Использование дополнительных кнопок дерева ***** */
                extraButtons={() => this.renderExtraButtons(this)}
                /** **** Переопределение отмеченных элементов дерева (при изменении дерева) ***** */
                // selectedKeys={this.selectedTreeItem}
                /** **** Переопределение открытых элементов дерева (при изменении дерева) ***** */
                // expandedKeys={this.expandedTreeItem}
              />
            </div>
            <div className={`template-right ${!isViewTree && 'full-size'}`}>
              <TemplateTreeValues
                /** **** Все поля являются обязательными для передачи ***** */
                originComponent={this}
                t={withPrefix('FUNCTIONS_', t)}
                isViewTree={isViewTree}
                isEditTreeItem={isEditTreeItem}
                onViewTree={(isShow) => onViewTree(this, isShow)}
                values={treeValueData}
                renderColumns={
                  (component, dataFiltered) => this.actionsForTreeValues.renderTreeValueColumns(
                    component,
                    editableFunctions,
                    dataFiltered
                  )
                }
                newItem={this.actionsForTreeValues.newTreeValueItem}
                onAddItem={this.onAddTreeValueItem}
                onEditItem={this.onEditTreeValueData}
                onSaveEditedData={this.actionsForTreeValues.onSaveEditedTreeValueData}
                selectedTreeItem={selectedTreeItem}
                onChangeTreeValueData={
                  (newTreeValueData, operation, item) => this.onMyChangeTreeValueData(
                    newTreeValueData,
                    operation,
                    item,
                    this
                  )
                }
                dragTreeValueItem={dragTreeValueItem}
                onDragStartTreeValueItem={(item) => onDragStartTreeValueItem(item, this)}
                onDropTreeValueItemInside={
                  (dragObject, dropAfterObject) => onDropTreeValueItemInside(
                    dragObject,
                    dropAfterObject,
                    this
                  )
                }
                onShowButtonsSaveCancel={this.onShowButtonsSaveCancel}
                isCanEdit={isEditRight && editableFunctions}
                isCanDragAndDrop={true}
                isShowButtonsPanel={true}
                /** **** необязательные параметры ***** */
                isLoading={setPointsBlockIsLoading}
                /** **** Название переменной для сохранения ширины колонок ***** */
                tableName='functions'
                /** **** Список стандартных кнопок, которые разрешены/могут быть активны ***** */
                /** **** ['tree','add','edit','delete','copy/paste','settings']. ***** */
                /** **** Если все активны - можно не передавать ***** */
                // enableStandardButtons={['tree', 'add', 'edit', 'delete', 'copy/paste', 'settings']}
                // visibleStandardButtons={['tree', 'add', 'edit', 'delete', 'copy/paste', 'settings']}
                // saveSettings={true}
                /** **** Загрузка и использование доп.переменных в state-е компонента "Значений" ***** */
                // additionalComponentDidMount={this.additionalComponentDidMount}
                // additionalComponentWillUnmount={this.additionalComponentWillUnmount}
                /** **** Использование дополнительных кнопок ***** */
                // extraButtons={ () => this.renderExtraButtons(this) }
                /** **** Выделение строки данных при определенных условиях (возвращается className) ***** */
                // checkErrorValueAndGetClassName={ (record) => this.checkErrorValueAndGetClassName(record) }
                /** **** Разрешение на редактирование конкретной записи ***** */
                // checkRecordForEdit={this.checkRecordForEdit}
                /** **** Дополнительная обработка элемента после копирования ***** */
                onCopyTreeValueData={this.actionsForTreeValues.onCopyTreeValueData}
                /** **** Настройки выделения строк в таблице ***** */
                // rowSelection={}
              />
            </div>
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>{t('FUNCTIONS_NOT_FOUND_BY_PARAMETERS')}</span>}
          />
        )}
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  archived: state.archived,
  rights: state.rights.rightsData,
});

export default connect(mapStateToProps)(
  withTranslation()(
    FunctionsComponent
  )
);
