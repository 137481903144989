import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import Nonperformance from '@common/nonperformance';
import { RIGHT_PASSPORT_TASKS, MODE_EDIT } from '@globalConstants';
import api from '@services/api';
import { clearBlanks } from '@state/blanks/actions';
import store from '@state/store';

import { STATUS_NEW, STATUS_CALCULATING } from './constants';
import DCTasks from './dcTasks';
import Documents from './documents';
import SetpointsTree from '../setpoints/setpointsTree';
import Blanks from '../setpoints/blanks';

import styles from './styles.module.less';

class Tasks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taskId: null,
      isBlocked: false,
      taskListMessages: [],
      blank: null,
      selectedTask: null,
      isUpdateDocuments: false,
      isUpdateTasks: false,
      isOpenBlankPanel: false,
      isOpenSetpointsPanel: false,
      staticRec: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { storeActiveBlank } = this.props;

    if (storeActiveBlank !== prevProps.storeActiveBlank) {
      this.setState({
        blank: storeActiveBlank,
      });
    }
  }

  componentWillUnmount() {
    store.dispatch(clearBlanks());
  }

  onChangeTask = (taskId, selectedTask, blank) => {
    const { selectedTask: oldSelectedTask, taskId: oldTaskId, isOpenBlankPanel } = this.state;
    this.setState({
      taskId,
      selectedTask,
      blank,
    }, () => {
      if (taskId !== oldTaskId || !isEqual(oldSelectedTask, selectedTask)) {
        this.updateDocuments(true);
      }
      if (oldSelectedTask && selectedTask && oldSelectedTask.id !== selectedTask.id) {
        const el = document.getElementById('passport-task-blanks');
        if (isOpenBlankPanel && el && (blank || (selectedTask && selectedTask.blanks && selectedTask.blanks.length))) {
          setTimeout(
            () => {
              el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500
          );
        }
      }
    });
  };

  onSaveValues = async (values, onSavedAllItems) => {
    const res = await api.patchAllChangedTaskSetpoints(values, values[0].calc_view_mode, values[0].static_rec);
    onSavedAllItems && await onSavedAllItems(res);
    const taskListMessages = [{ messageName: 'updateCurrentTaskStatus' }];
    this.setState({ taskListMessages });
  };

  onSPTBlock = () => {
    this.setState({ isBlocked: true });
  };

  onSPTUnBlock = () => {
    this.setState({ isBlocked: false });
  };

  onChangeStatus = () => {
    const taskListMessages = [{ messageName: 'updateCurrentTaskStatus' }];
    this.setState({ taskListMessages });
  };

  updateDocuments = (val) => this.setState({ isUpdateDocuments: val });

  updateTasks = (val) => this.setState({ isUpdateTasks: val });

  loadSetpointsData = (blank) => this.setState({ blank: blank });

  handleParentStateChange = () => {
    const { staticRec } = this.state;
    this.setState({ staticRec: !staticRec });
  };

  render() {
    const {
      passportId, rights, t, deviceId, currentDC, isArchived, getPassportData,
    } = this.props;
    const {
      taskId, isBlocked, taskListMessages, selectedTask, blank, isUpdateDocuments,
      isUpdateTasks, isOpenBlankPanel, isOpenSetpointsPanel, staticRec
    } = this.state;

    const isEditRight = rights[RIGHT_PASSPORT_TASKS] === MODE_EDIT && !isArchived;

    const titleExtra = `(${selectedTask && selectedTask.id
      ? `${t('COLLAPSABLE_TASKS')} "${selectedTask.name}"`
      : t('PASSPORT_TASKS_TASK_NOT_SELECTED')})`;

    const isEditBlocked = selectedTask
      && (
        (
          selectedTask.status
          && selectedTask.status.current_status
          && ![STATUS_NEW, STATUS_CALCULATING].includes(selectedTask.status.current_status.id)
        )
        || (
          selectedTask.source_blank
          && selectedTask.source_blank.is_doc === true
        )
      );
    const taskCurrentStatus = selectedTask
      && selectedTask.status
      && selectedTask.status.current_status
      && selectedTask.status.current_status.id;

    return (
      <div className={styles.dctasks}>
        <DCTasks
          isDefaultOpenPanel={true}
          titleExtra={titleExtra}
          isArchived={isArchived}
          isBlocked={isBlocked}
          isUpdateTasks={isUpdateTasks}
          passportId={passportId}
          deviceId={deviceId}
          onChangeTask={this.onChangeTask}
          messages={taskListMessages}
          updateDocuments={this.updateDocuments}
          updateTasks={this.updateTasks}
          setOpenBlankPanel={(value) => this.setState({ isOpenBlankPanel: !!value })}
          setOpenSetpointsPanel={(value) => this.setState({ isOpenSetpointsPanel: !!value })}
          blank={blank}
          staticRec={staticRec}
        />
        <Documents
          titleExtra={titleExtra}
          isArchived={isArchived}
          taskId={taskId}
          selectedTask={selectedTask}
          passportId={passportId}
          taskStatus={taskCurrentStatus}
          updateDocuments={this.updateDocuments}
          isUpdateDocuments={isUpdateDocuments}
        />
        <div id='passport-task-blanks'>
          {
            passportId
            && taskId
            && selectedTask
            && selectedTask.blanks
            && selectedTask.blanks.length > 1
              ? (
                <Blanks
                  titleExtra={titleExtra}
                  isDefaultOpenPanel={isOpenBlankPanel}
                  isArchived={isArchived}
                  getPassportData={getPassportData}
                  isBlocked={isBlocked}
                  deviceId={deviceId}
                  passportId={passportId}
                  loadSetpointsData={this.loadSetpointsData}
                  mode='task'
                  selectedTask={selectedTask}
                  onBlock={this.onSPTBlock}
                  onUnBlock={this.onSPTUnBlock}
                  updateTasks={this.updateTasks}
                />
              )
              : ''
          }
        </div>
        <SetpointsTree
          title={t('PASSPORT_TASKS_VIEW_OF_SETPOINTS')}
          titleExtra={titleExtra}
          isDefaultOpenPanel={isOpenSetpointsPanel}
          passportId={passportId}
          taskId={taskId}
          taskStatus={taskCurrentStatus}
          blank={blank}
          task={selectedTask}
          onSaveValues={this.onSaveValues}
          onBlock={this.onSPTBlock}
          onUnBlock={this.onSPTUnBlock}
          onChangeStatus={this.onChangeStatus}
          deviceId={deviceId}
          isEditRight={isEditRight}
          isEditBlocked={isEditBlocked}
          dispatcherCenter={currentDC && currentDC.id}
          mode='task'
          staticRec={staticRec}
          setStaticRec={this.handleParentStateChange}
        />
        <Nonperformance
          titleExtra={titleExtra}
          isArchived={isArchived}
          blank={blank}
          taskId={taskId}
          passportId={passportId}
          taskStatus={taskCurrentStatus}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  storeActiveBlank: state.blanks.activeBlank,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Tasks
  )
);
