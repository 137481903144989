import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

import styles from './styles.module.less';

const MenuGroup = ({
  caption, displayed, isMenuCollapsed, ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      { displayed && (
        isMenuCollapsed
          ? <div className={`${styles.menuGroup} ${styles.hr}`}><hr /></div>
          : (
            <Menu.Item
              key={caption}
              className={styles.menuGroup}
              {...props}
            >
              <span className={styles.caption}>{t(caption)}</span>
            </Menu.Item>
          )
      )}
    </>
  );
};

export default MenuGroup;
