import React, { Component } from 'react';
import {
  Form, Input, InputNumber, DatePicker, Checkbox,
} from 'antd';
import moment from 'moment';

import CustomSelect from '@ui/customSelect';

import {
  INPUT_TYPE_PASSWORD,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_SELECT,
  INPUT_TYPE_DATEPICKER,
  INPUT_TYPE_CHECKBOX,
} from './constants';

import styles from './styles.module.less';

const { Item } = Form;

class FormItem extends Component {
  mapTypesToComponents = {
    [INPUT_TYPE_PASSWORD]: Input.Password,
    [INPUT_TYPE_NUMBER]: InputNumber,
    [INPUT_TYPE_TEXTAREA]: Input.TextArea,
    [INPUT_TYPE_SELECT]: CustomSelect,
    [INPUT_TYPE_DATEPICKER]: DatePicker,
    [INPUT_TYPE_CHECKBOX]: Checkbox,
  };

  constructor(props) {
    super(props);

    this.state = {
      changedValue: props.initialValue,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const {
      id, form, initialValue, formChangeHandle, taskDocumentName, isLoading
    } = this.props;
    const { changedValue, hasError } = this.state;

    if (!prevProps.initialValue && initialValue && initialValue !== prevProps.initialValue) {
      this.setState(
        {
          changedValue: initialValue,
        },
        () => form && form.setFieldsValue(this.getFieldObject(id, initialValue))
      );
    }

    const currentFormFieldValue = form && form.getFieldValue(id);

    if (currentFormFieldValue && currentFormFieldValue !== changedValue) {
      this.setState({
        changedValue: currentFormFieldValue,
      });
    }

    if (hasError) { // в компоненте InputNumber возникает ошибка при длинных числах после запятой
      form && form.setFieldsValue(this.getFieldObject(id, initialValue));
      formChangeHandle && formChangeHandle();

      this.setState({
        hasError: false,
        changedValue: initialValue,
      });
    }

    if (taskDocumentName !== prevProps.taskDocumentName && !isLoading) {
      this.setState({ changedValue: taskDocumentName }, () => {
        form &&
        form.setFieldsValue(this.getFieldObject(id, taskDocumentName));
      });
    }
  }

  getFieldObject = (field, value) => {
    if (Array.isArray(field) && field.length === 1) {
      return { [field[0]]: value };
    } else if (Array.isArray(field)) {
      return { [field[0]]: this.getFieldObject(field.slice(1, field.length), value) };
    } else {
      return { [field]: value };
    }
  };

  onChangeHandle = (e) => {
    const {
      id, FormItemType, formChangeHandle, onChange,
    } = this.props;
    const changedValue = e && e.target
      ? FormItemType === INPUT_TYPE_CHECKBOX ? !!e.target.checked : e.target.value : e;

    this.setState({ changedValue });

    if (FormItemType === INPUT_TYPE_NUMBER) {
      formChangeHandle && formChangeHandle(this.getFieldObject(id, e));
    }

    onChange && onChange(changedValue);
  };

  render() {
    const {
      children, highlight, FormItemType, label, secondLabel, id,
      initialValue, isReadOnly, inputStyle, rules, type, taskDocumentName, isLoading, ...restProps
    } = this.props;
    const { changedValue } = this.state;
    const ComponentInput = this.mapTypesToComponents[FormItemType] || Input;

    let itemChanged = (!changedValue && !initialValue) ? false : changedValue !== initialValue;

    if (moment.isMoment(changedValue) && moment.isMoment(initialValue)) {
      itemChanged = !changedValue.isSame(initialValue, 'day');
    }

    const selectProps = (
      FormItemType === INPUT_TYPE_SELECT
        ? { popoverPlacement: 'left' }
        : {}
    );

    if (!highlight) {
      itemChanged = false;
    }

    return (
      <Item label={label} rules={rules}>
        <Item
          name={id}
          rules={rules}
          noStyle
          valuePropName={FormItemType === INPUT_TYPE_CHECKBOX ? 'checked' : 'value'}
        >
          <ComponentInput
            {...restProps}
            {...selectProps}
            checked={changedValue}
            disabled={isReadOnly}
            onChange={this.onChangeHandle}
            className={`${itemChanged ? styles.changed : ''}`}
            style={inputStyle && { ...inputStyle }}
            type={FormItemType === INPUT_TYPE_NUMBER ? 'number' : type}
          >
            {children}
          </ComponentInput>
        </Item>
        {secondLabel}
      </Item>
    );
  }
}

export default FormItem;
