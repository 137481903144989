import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

const MenuItem = ({
  caption, displayed, onClick, iconType, className, ...props
}) => {
  const { t } = useTranslation();

  const IconComponent = iconType;

  return (
    <>
      { displayed && (
        <Menu.Item
          key={caption}
          onClick={onClick}
          className={`menuItem ${className === undefined ? '' : className}`}
          {...props}
        >
          <IconComponent />
          <span>{t(caption)}</span>
        </Menu.Item>
      )}
    </>
  );
};

export default MenuItem;
