import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Button, Badge, Tooltip, Layout,
} from 'antd';
import moment from 'moment';
import Icon, {
  NotificationOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  SettingOutlined,
  HistoryOutlined,
  StarOutlined,
} from '@ant-design/icons';

import { ReactComponent as BucketIcon } from '@ui/icons/bucket.svg';

import api from '@services/api';
import { preventFocus } from '@globalHelpers';
import store from '@state/store';
import { getShortBucket } from '@state/bucket/actions';
import { getRights } from '@state/rights/actions';
import { getSettings } from '@state/settings/actions';
import { getNotifications } from '@state/notifications/actions';
import { getArchivedSettings, toggleArchivedSettings } from '@state/archived/actions';

import { DOCUMENTS_UPLOAD } from '@common/sidebarRoot/types';
import {
  RIGHT_ROUTE_TASK_DC_MONITORING,
  // ROLE_KEY_ADMIN_IA,
  // ROLE_KEY_ADMIN_ODU,
  // ROLE_KEY_ADMIN_RDU,
  ROUTE_TASK_DC_MONITORING,
} from '@globalConstants';
import { PopoverResizable } from '@ui';

import TopBarSearch from './search';
import AuthTopMenu from './userInfo';
import Bucket from './bucket';
import DocumentTray from './DocumentTray';
import Notifications from './notifications';
import NotificationsSettings from './notificationsSettings';

import styles from './styles.module.less';
import NewsButton from './news/newsButton';
import { NEWS_UNREAD_CHECK_INTERVAL } from './news/constants';
import MaintenanceButton from './maintenance/maintenanceButton';

const { Header } = Layout;

class TopBar extends Component {
  state = {
    selectedDevicesPopoverVisible: false,
    notificationsPopoverVisible: false,
    notificationsSettingsVisible: false,
    selectedDevices: [],
    dateTime: moment().format('DD.MM.YYYY HH:mm'),
    clientHeight: 300,
  };

  notificationsTimer = null;

  helpMappings = [
    { regexp: /^\/tree/, url: 'toc28594901.html' },
    { regexp: /^\/journal/, url: 'toc28594918.html' },
    { regexp: /^\/search/, url: '4_3_1----.html' },
    { regexp: /^\/chosen-devices/, url: '4-4----.html' },
    { regexp: /^\/monitoring/, url: '4_11---.html' },
    { regexp: /^\/events/, url: '4_12.html' },
    { regexp: /^\/reports/, url: '4_13.html' },
    { regexp: /^\/settings/, url: '4_16--.html' },
    { regexp: /^\/storages/, url: '4_17.html' },
    { regexp: /^\/classificators\/tab\/complexes/, url: 'toc28594915.html' },
    { regexp: /^\/classificators\/tab\/device/, url: 'toc28594916.html' },
    { regexp: /^\/classificators\/tab\/documents/, url: 'toc28594917.html' },
    { regexp: /^\/templates/, url: 'toc28594912.html' },
    { regexp: /^\/functions/, url: '4-6----.html' },
    { regexp: /^\/aip\/tab\/aip/, url: '4_14_1-------.html' },
    { regexp: /^\/aip\/tab\/snmp/, url: '4_14_2-------.html' },
    { regexp: /^\/aip\/tab\/landocs/, url: '4_15_3------landocs.html' },
    { regexp: /^\/aip\/tab\/smtp/, url: '4_15_4----.html' },
    { regexp: /^\/roles\/tab\/functions/, url: 'toc28594921.html' },
    { regexp: /^\/roles\/tab\/roles/, url: 'toc28594922.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/documents/, url: 'toc28594907.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/functions/, url: '4-5-4----.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/setpoints/, url: 'toc28594906.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/tasks/, url: 'toc28594909.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/setpoint_map/, url: 'toc28594910.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/journal/, url: '-4-5-7---.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/events/, url: '4_5_8-.html' },
    { regexp: /^\/passport\/[^/]+\/tab\/letters/, url: '4_5_9-.html' },
  ];

  componentDidMount() {
    store.dispatch(getShortBucket(true));
    store.dispatch(getRights());
    store.dispatch(getSettings());
    store.dispatch(getArchivedSettings());

    this.startDateTimeClock = setTimeout(() => {
      this.displayTime();
      this.dateTimeClock = setInterval(this.displayTime, 60 * 1000);
    }, (60 - moment().seconds()) * 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.notificationsTimer);
    clearTimeout(this.startDateTimeClock);
    clearInterval(this.dateTimeClock);
  }

  displayTime = () => {
    this.setState({
      dateTime: moment().format('DD.MM.YYYY HH:mm'),
    });
  };

  openDocumentsSidebar = async () => {
    this.setState(() => ({ notificationsPopoverVisible: false }));
  };

  toggleNotifications = async (visibility) => {
    if (!visibility) {
      await this.markAllNotificationsAsRead();
    }

    this.setState({ notificationsPopoverVisible: visibility });
  };

  toggleSettings = async () => {
    this.setState((prevState) => ({ notificationsSettingsVisible: !prevState.notificationsSettingsVisible }));
  };

  markAllNotificationsAsRead = async () => {
    await api.markNotifications(true);
  };

  cleanAllNotifications = async () => {
    const { status } = await api.cleanNotifications();

    if (status === 204) {
      await store.dispatch(getNotifications());
    }
  };

  changeLang = (value) => {
    const { i18n } = this.props;
    i18n.changeLanguage(value);
  };

  handleBucketVisibility = (visibility) => {
    const { sidebar } = this.props;

    if (sidebar.isOpen && sidebar.componentType === DOCUMENTS_UPLOAD) {
      this.setState({
        selectedDevicesPopoverVisible: true,
      });
    } else {
      this.setState({
        selectedDevicesPopoverVisible: visibility,
      });
    }
  };

  changeShowArchived = async () => {
    await store.dispatch(toggleArchivedSettings());
  }

  render() {
    const {
      t,
      appStage,
      deviceCount,
      notificationsCounter,
      notifications,
      location,
      // userInfo,
      rights,
      archived,
    } = this.props;
    const {
      selectedDevices,
      selectedDevicesPopoverVisible,
      notificationsPopoverVisible,
      notificationsSettingsVisible,
      dateTime,
      clientHeight,
    } = this.state;
    const helpPage = this.helpMappings.find((x) => location.pathname.match(x.regexp));
    // const isAdminNotAllowed = [
    //   ROLE_KEY_ADMIN_IA,
    //   ROLE_KEY_ADMIN_ODU,
    //   ROLE_KEY_ADMIN_RDU,
    // ].includes(userInfo.role.key);
    const rightToTaskDcMonitoring = rights[RIGHT_ROUTE_TASK_DC_MONITORING];
    const isRightToTaskDcMonitoring = !!rightToTaskDcMonitoring;

    return (
      <Header className={styles.topBar}>

        <div className={styles.inner}>
          <div className={styles.searchMenu}>

            <div className={styles.search}>
              <TopBarSearch />
            </div>
              <PopoverResizable
                // visible={isAdminNotAllowed ? null : selectedDevicesPopoverVisible} Временное решение для задачи DBRZA-2667
                visible={selectedDevicesPopoverVisible}
                onVisibleChange={this.handleBucketVisibility}
                onUpdateHeight={(height) => this.setState({ clientHeight: height })}
                content={(
                  <Bucket
                    clientHeight={clientHeight}
                    openDocumentsSidebar={this.openDocumentsSidebar}
                    selectedDevices={selectedDevices}
                    onSelectDevices={(selected) => this.setState({ selectedDevices: selected })}
                    onClearSelectedDevices={() => this.setState({ selectedDevices: [] })}
                    onClose={() => this.setState({ selectedDevices: [], selectedDevicesPopoverVisible: false })}
                  />
                )}
                title={(
                  <div>
                    {t('CHOSEN_DEVICES')}
                    <Button
                      type='link'
                      tabIndex={0}
                      style={{ float: 'right', marginLeft: '40px' }}
                      onClick={(e) => {
                        preventFocus(e);
                        this.setState({
                          selectedDevices: [],
                          selectedDevicesPopoverVisible: false,
                        })
                      }}
                    >
                      <Link to='/chosen-devices'>{t('ALL_DEVICES')}</Link>
                    </Button>
                  </div>
                )}
              >
                <Badge className={styles.badge} offset={[0, 3]} count={deviceCount}>
                  <Button
                    icon={<StarOutlined style={{ fontSize: 16 }} />}
                    // disabled={isAdminNotAllowed} Временное решение для задачи DBRZA-2667
                    onClick={(e) => preventFocus(e)}
                  >
                    <span>{t('CHOSEN_DEVICES')}</span>
                  </Button>
                </Badge>
              </PopoverResizable>
              <Link
                to={ROUTE_TASK_DC_MONITORING}
                disabled={!isRightToTaskDcMonitoring}
              >
                <Button
                  icon={<ScheduleOutlined />}
                  disabled={!isRightToTaskDcMonitoring}
                  onClick={(e) => preventFocus(e)}
                >
                  <span>
                    {t('TAB_TASKS')}
                  </span>
                </Button>
              </Link>
            <div className={styles.buttonShowArchived}>
              <Button
                icon={<HistoryOutlined />}
                className={archived.isArchivedData ? styles.isShowArchived : styles.isShowNotArchived}
                onClick={(e) => {
                  preventFocus(e)
                  this.changeShowArchived();
                }}
              >
                {t('ARCHIVED_DATA')}
              </Button>
            </div>
            <div className={styles.rightMenu}>
              <NewsButton checkInterval={NEWS_UNREAD_CHECK_INTERVAL} />
              <div className={styles.dateTime}>{dateTime}</div>
              <DocumentTray />
              <MaintenanceButton />
              <div className={styles.notificationMenuPopover}>
                <PopoverResizable
                  visible={notificationsPopoverVisible}
                  onVisibleChange={(visibility) => this.toggleNotifications(visibility)}
                  height={500}
                  placement='bottomRight'
                  content={(
                    <>
                      <NotificationsSettings visible={notificationsSettingsVisible} />
                      <Notifications notifications={notifications} disabled={notificationsSettingsVisible} />
                    </>
                  )}
                  title={(
                    <div className={styles.notificationTitle}>
                      <div>{t('NOTIFICATIONS')}</div>
                      <Button size='small' onClick={(e) => {
                        preventFocus(e);
                        this.toggleSettings();
                      }}>
                        <SettingOutlined />
                        {t('SETTINGS')}
                      </Button>
                      <Button size='small' onClick={(e) => {
                        preventFocus(e);
                        this.cleanAllNotifications();
                      }} disabled={!notifications.length}>
                        <Icon component={BucketIcon} className={!notifications.length && styles.svgIconDisabled} />
                        {t('READ_ALL')}
                      </Button>
                    </div>
                  )}
                >
                  <Tooltip title={t('NOTIFICATIONS')}>
                    <Badge className={styles.badge} offset={[3, 3]} count={notificationsCounter}>
                      <Button icon={<NotificationOutlined />} onClick={(e) => preventFocus(e)} />
                    </Badge>
                  </Tooltip>
                </PopoverResizable>
              </div>
                <Tooltip title={t('HELP')}>
                  <Button
                    href={`/help/HTML/index.html${helpPage ? `?${helpPage.url}` : ''}`}
                    target='_blank'
                    className={styles.helpButton}
                    icon={<QuestionCircleOutlined />}
                    onClick={(e) => preventFocus(e)}
                  />
                </Tooltip>
                <AuthTopMenu appStage={appStage} />
            </div>
          </div>
        </div>

      </Header>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.rights.rightsData,
  sidebar: state.sidebar,
  appStage: state.appStage,
  deviceCount: state.bucket.count,
  notificationsCounter: state.notifications.count_new,
  notifications: state.notifications.notificationsData,
  userInfo: state.user.info,
  archived: state.archived,
});

export default connect(mapStateToProps)(
  withTranslation()(
    withRouter(
      TopBar
    )
  )
);
