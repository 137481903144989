import { User } from '@services/api-dts';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_JWT_DATA = 'SET_JWT_DATA';
export const SET_USER_EXTRA_INFO = 'SET_USER_EXTRA_INFO';

export interface userState {
  name: string;
  user_id: string;
  token: string;
  info: User;
}

export interface TokenDto {
  exp: number;
  user_id: string;
  username: string;
  email: string;
  orig_iat: number;
}

export interface TokenHeaderDto {
  typ: string;
  alg: string;
}

interface setTokenAction {
  type: typeof SET_TOKEN;
  token: string;
}

interface jwtDataAction {
  type: typeof SET_JWT_DATA;
  data: TokenDto;
}

interface setUserExtraInfoAction {
  type: typeof SET_USER_EXTRA_INFO;
  data: User;
}

export type userActionTypes = setTokenAction | jwtDataAction | setUserExtraInfoAction;
