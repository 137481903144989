import React from 'react';
import RcCollapse from 'rc-collapse';
import { withTranslation } from 'react-i18next';
import {
  Card, Spin, Collapse, Badge,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

class CustomCard extends React.Component {
  collapseRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isActive: props.hasData !== undefined ? props.hasData && props.isDefaultOpenPanel : props.isDefaultOpenPanel,
    };
  }

  componentDidMount() {
    if (this.collapseRef.current) {
      const original = this.collapseRef.current.handleItemClick;
      this.collapseRef.current.handleItemClick = (e) => {
        const target = e && e.target;
        if (
          (target
            && target.tagName === 'svg'
            && target.getAttribute('data-icon') !== 'check-circle'
            && target.getAttribute('data-icon') !== 'file-excel'
          )
          || (target
            && !(target.className.indexOf && target.className.indexOf('ant-select') !== -1)
            && !(target.className.indexOf && target.className.indexOf('control-button') !== -1)
            && !(target.className.indexOf && target.className.indexOf('ant-empty') !== -1)
            && !(target.getAttribute('data-icon') === 'check-circle')
            && !(target.getAttribute('data-icon') === 'file-excel')
            && !(target.tagName === 'INPUT')
            && !(target.type === 'button')
          )
        ) {
          original();
        }
      };
    }
  }

  componentDidUpdate(prevProps) {
    const { hasData, isDefaultOpenPanel } = this.props;

    if (prevProps.hasData !== hasData || prevProps.isDefaultOpenPanel !== isDefaultOpenPanel) {
      this.setState({ isActive: hasData && isDefaultOpenPanel });
    }
  }

  componentWillUnmount() {
    this.setState({ isActive: false });
  }

  toggleActive = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };

  collapsableHeader(enableCollapsable) {
    const {
      extra, title, titleExtra, loading, count, hasData,
    } = this.props;
    const { isActive } = this.state;

    return (
      <div className={`ant-card-head ${styles.collapsableHead} ${!enableCollapsable && styles.disabledCollapsable}`}>
        <div className='ant-card-head-wrapper'>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className='ant-card-head-title' onClick={(enableCollapsable ? this.toggleActive : undefined)}>
            {title}
            {titleExtra && <div className={styles.titleExtra}>{titleExtra}</div>}
            {loading && <div className={styles.titleExtra}><Spin size="small" /></div>}
            {!loading && hasData && count > 0 && (
              <div className={styles.titleExtra}>
                <Badge className={styles.titleExtraCount} count={count} />
              </div>
            )}
          </div>
          {isActive && extra && <div className='ant-card-extra'>{extra}</div>}
        </div>
      </div>
    );
  }

  render() {
    const {
      title,
      className,
      loading,
      children,
      bodyStyle,
      collapsable,
      offsetTop,
      style,
      extra,
      tabList,
      activeTabKey,
      onTabChange,
      tabBarExtraContent,
      actions,
      hasData,
      headStyle,
      enableCollapsableHasNoData = false,
      noPadding = false,
      noBottomMargin = false
    } = this.props;
    const { isActive } = this.state;
    const noPaddingClass = noPadding && collapsable ? ` ${styles.noPadding}` : '';
    const noBottomMarginClass = noBottomMargin ? ` ${styles.noBottomMargin}` : '';
    const classes = `${className} ${styles.customCard}${noPaddingClass}${noBottomMarginClass}`;
    const enableCollapsable = (hasData || enableCollapsableHasNoData) ? undefined : false;

    return collapsable
      ? (
        <Collapse
          activeKey={isActive ? '0' : undefined}
          collapsible={enableCollapsable}
          expandIcon={() => (
            enableCollapsable === undefined
              ? (
                <DownOutlined
                  rotate={isActive ? 180 : 0}
                  onClick={this.toggleActive}
                  className={styles.arrowIcon}
                />
              )
              : ''
          )}
        >
          <RcCollapse.Panel
            key='0'
            ref={this.collapseRef}
            className={classes}
            prefixCls='ant-collapse'
            extra={undefined}
            bordered={false}
            header={this.collapsableHeader(enableCollapsable === undefined)}
            // collapsible={hasData ? undefined : false}
            bodyStyle={{ height: '100%', ...bodyStyle }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
            tabBarExtraContent={tabBarExtraContent}
            style={style}
            actions={actions}
            hasData={hasData}
            headStyle={headStyle}
          >
            {children}
          </RcCollapse.Panel>
        </Collapse>
      )
      : (
        <Spin spinning={!!loading}>
          <Card
            title={title}
            extra={extra}
            className={classes}
            bordered={false}
            bodyStyle={{ height: '100%', ...bodyStyle }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
            actions={actions}
            style={{ minHeight: `calc(100vh - ${offsetTop}px)`, ...style }}
            tabBarExtraContent={tabBarExtraContent}
            headStyle={headStyle}
          >
            {children}
          </Card>
        </Spin>
      );
  }
}

export default withTranslation()(
  CustomCard
);
